import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { sections } from "../../../handlers/enums";
import { getResumeSectionPositionFromTop } from "../../../handlers/utilityFunctions";
import useReviewFeedBack from "../../../hooks/use-ReviewFeedBack";

function ReviewCard({ reviewData, data, templateId }) {
	const useReviewFeedback = useSelector((state) => state.UseReviewFeedbackReducer);
	const dispatch = useDispatch();
	const { openCommentsModal } = useReviewFeedBack(reviewData);
	const [isHovered, setIsHovered] = React.useState(false);
	const [comment, setComment] = React.useState({
		section: "",
		rating: "",
		reasonsForRating: [],
		recommendations: [],
	});
	const element = document.querySelector(comment.section === "" ? "#expertReview" : "#" + comment.section);
	const positionFromTop = getResumeSectionPositionFromTop(element);
	const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
	const [isMoreExpanded, setIsMoreExpanded] = React.useState(false);
	const [isLessExpanded, setIsLessExpanded] = React.useState(true);
	const lessContentRef = useRef(null);
	const moreContentRef = useRef(null);

	const toggleExpansion = () => {
		setIsMoreExpanded((prev) => !prev);
		setIsLessExpanded((prev) => !prev);

		// Get the actual content height and set max-height accordingly
		const lessContentElement = lessContentRef.current;
		const moreContentElement = moreContentRef.current;

		if (lessContentElement && moreContentElement) {
			const lessHeight = isLessExpanded ? 0 : lessContentElement.scrollHeight + "px";
			const moreHeight = isMoreExpanded ? 0 : moreContentElement.scrollHeight + "px";
			lessContentElement.style.maxHeight = lessHeight;
			moreContentElement.style.maxHeight = moreHeight;
		}
	};

	React.useEffect(() => {
		setComment(data.review);
	}, [data]);

	React.useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [screenWidth]);

	return (
		<>
			{element && (
				<div
					className="review-card-wrapper mb-3"
					style={{
						...(screenWidth > 768 && {
							position: "absolute",
							zIndex: isMoreExpanded ? 1 : 0,
							top: positionFromTop,
							...(templateId === "template_1" &&
								(comment.section === sections.LINKS || comment.section === sections.LANGUAGES) && {
									right: "300%",
								}),
						}),
					}}
					onMouseEnter={() => {
						dispatch({
							type: "USE_REVIEW_FEEDBACK",
							payload: {
								...useReviewFeedback,
								section: { ...useReviewFeedback.section, section: comment.section },
							},
						});
						setIsHovered(true);
					}}
					onMouseLeave={() => {
						setIsHovered(false);
					}}
				>
					<div className="review-card-container">
						{comment.rating === "STRONG" && <img src="images/info-success.svg" />}
						{comment.rating === "MODERATE" && <img src="images/info-warning.svg" />}
						{comment.rating === "WEAK" && <img src="images/info-error.svg" />}
						<div style={{ width: "100%" }}>
							<div
								ref={lessContentRef}
								className={`${isLessExpanded ? "expanded-resume-section" : ""} feedback-content review-card-body`}
							>
								{comment.reasonsForRating.length > 0 && (
									<>
										{comment.reasonsForRating[0]}
										<span
											className="cursor-pointer"
											style={{ fontWeight: 500, color: "#019ce0" }}
											onClick={() => toggleExpansion()}
										>
											… more
										</span>
									</>
								)}
							</div>
							<div
								ref={moreContentRef}
								className={`${isMoreExpanded ? "expanded-resume-section" : ""} feedback-content review-card-body`}
							>
								<p className="font-medium">Reasons for Rating</p>
								<ul>
									{comment.reasonsForRating.map((reason, index) => {
										return (
											<li key={index} style={{ fontSize: "14px" }}>
												{reason}
											</li>
										);
									})}
								</ul>
								<p className="font-medium">Recommendations</p>
								<ul>
									{comment.recommendations.map((recommendation, index) => {
										return (
											<li key={index} style={{ fontSize: "14px" }}>
												{recommendation}
											</li>
										);
									})}
								</ul>
								<span
									className="cursor-pointer"
									style={{ fontWeight: 500, color: "#019ce0" }}
									onClick={() => toggleExpansion()}
								>
									less
								</span>
							</div>
						</div>
						{isHovered && (
							<>
								<img
									src="images/paper-pen.svg"
									className="cursor-pointer ml-auto"
									data-toggle="modal"
									data-target="#reviewFeedbackModal"
									onClick={() => openCommentsModal(comment.section)}
								/>
								<img src="images/delete.svg" className="cursor-pointer" />
							</>
						)}
					</div>
				</div>
			)}
		</>
	);
}

export default ReviewCard;
