import EducationInfoList from "../../seed/editorList";

const initalState = {
	referenceId: "",
	schoolName: "",
	schoolCity: "",
	schoolRegion: "",
	schoolCountry: "",
	educationLevel: "",
	fieldOfStudy: "",
	gMonth: "",
	gYear: "",
	description: "",
	eduInfoList: EducationInfoList(),
};

export default function EducationInfoReducer(eduInfo = initalState, action) {
	switch (action.type) {
		case "SUBMIT_INPUTS":
			return action.payload;

		case "TOGGLE_EDITOR_ITEMS":
			break;

		default:
			return eduInfo;
	}
}
