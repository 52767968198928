import "../../NewNavLayout/dashboardStyle.css";

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import { currencyFormat } from "../../../handlers/utilityFunctions";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import LoadingBars from "../../LoadingBars";
import ReferFriendModal from "../../Rewards/ReferFriendModal";
import ShareOpportunityModal from "../../Rewards/ShareOpportunityModal";

const RewardDashboard = () => {
	const apiService = new ResumeDataService();
	const [points, setPoints] = React.useState({
		totalRewards: 0,
		totalAvailable: 0,
		totalPending: 0,
		totalRedeemed: 0,
	});
	const [rewards, setRewards] = useState([]);
	const [loading, setLoading] = useState(true);
	const [success, setSuccess] = useState("");
	const [showReferralFriendDialog, setShowReferralFriendDialog] = useState(false);
	const [showShareOpportunityDialog, setShowShareOpportunityDialog] = useState(false);

	const getRewards = () => {
		apiService.getPaymentsRewards(
			(response) => {
				const _details = response.data.map((obj) => ({
					...obj,
				}));
				setRewards(_details);
				setLoading(false);
			},
			() => {
				toast.error("Something went wrong on Fetching rewards!");
				setLoading(false);
			},
		);
	};

	const handleCloseReferralFriendDialog = () => {
		setShowReferralFriendDialog(false);
	};

	const handleCloseShareOpportunityDialog = () => {
		setShowShareOpportunityDialog(false);
	};

	useEffect(() => {
		getRewards();
	}, []);

	useEffect(() => {
		if (success !== "") {
			toast.success(success);
		}
	}, [success]);

	useEffect(() => {
		let newPoints = {
			totalRewards: 0,
			totalAvailable: 0,
			totalPending: 0,
			totalRedeemed: 0,
		};
		rewards.forEach((reward) => {
			if (reward.rewardStatus === "CLAIMED") {
				newPoints.totalAvailable = newPoints.totalAvailable + reward.rewardPoints;
			}
			if (reward.rewardStatus === "PENDING") {
				newPoints.totalPending = newPoints.totalPending + reward.rewardPoints;
			}
			if (reward.rewardStatus === "REDEEMED") {
				newPoints.totalRedeemed = newPoints.totalRedeemed + reward.rewardPoints;
			}
		});
		newPoints.totalRewards = newPoints.totalAvailable + newPoints.totalPending + newPoints.totalRedeemed;
		setPoints(newPoints);
	}, [rewards]);

	return (
		<>
			{showReferralFriendDialog && (
				<ReferFriendModal
					showReferralFriendDialog={showReferralFriendDialog}
					handleCloseReferralFriendDialog={handleCloseReferralFriendDialog}
					getPaymentsRewards={getRewards}
					setSuccess={setSuccess}
				/>
			)}
			{showShareOpportunityDialog && (
				<ShareOpportunityModal
					showShareOpportunityDialog={showShareOpportunityDialog}
					handleCloseShareOpportunityDialog={handleCloseShareOpportunityDialog}
					rewards={rewards}
					setRewards={setRewards}
					setSuccess={setSuccess}
				/>
			)}
			<div className="segment-dashboard">
				{loading ? (
					<LoadingBars />
				) : rewards.length === 0 ? (
					<div className="d-flex p-4 align-center justify-content-center">
						<div className="float-right">
							<h2 style={{ fontFamily: "Varela Round", fontSize: "1.5rem" }}> No Rewards Found! </h2>
							<p>
								Start sharing job opportunities now to collect and redeem your<Link to="/rewards"> rewards </Link>
							</p>
						</div>
					</div>
				) : (
					<>
						<h2>Rewards</h2>
						<div className="d-flex p-2 mt-3 mt-lg-3" style={{ backgroundColor: "#e6f2ff" }}>
							<div className="pr-2">
								<div
									className="rounded-circle bg-light d-flex align-items-center justify-content-center"
									style={{ width: "20px", height: "20px" }}
								>
									<span className="bi bi-box2-heart-fill text-primary" />
								</div>
							</div>
							<div className="text-wrap">
								<p className="h6 font-weight-bold">
									Total Available: <strong>{currencyFormat(points.totalAvailable)}</strong>
								</p>
							</div>
						</div>

						<div className="d-flex p-2 mt-3 mt-lg-3" style={{ backgroundColor: "#e6f2ff" }}>
							<div className="pr-2">
								<div
									className="rounded-circle bg-light d-flex align-items-center justify-content-center"
									style={{ width: "20px", height: "20px" }}
								>
									<span className="bi bi-gift text-primary" />
								</div>
							</div>
							<div className="text-wrap">
								<p className="h6 font-weight-bold">
									Total Pending: <strong>{currencyFormat(points.totalPending)}</strong>
								</p>
							</div>
						</div>

						<div className="d-flex p-2 mt-3 mt-lg-3 mb-3" style={{ backgroundColor: "#e6f2ff" }}>
							<div className="pr-2">
								<div
									className="rounded-circle bg-light d-flex align-items-center justify-content-center"
									style={{ width: "20px", height: "20px" }}
								>
									<span className="bi bi-gift-fill text-primary" />
								</div>
							</div>
							<div className="text-wrap">
								<p className="h6 font-weight-bold">
									Total Redeemed: <strong>{currencyFormat(points.totalRedeemed)}</strong>
								</p>
							</div>
						</div>

						<div className="d-flex mt-3 mt-lg-3">
							<button
								style={{ width: "100%", padding: "8px 20px" }}
								className="btn btn-success rounded"
								onClick={() => setShowReferralFriendDialog(true)}
							>
								<span className="bi bi-person-add mr-2" />
								REFER FRIEND
							</button>
						</div>
						<div className="d-flex mt-lg-3 mb-3">
							<button
								style={{ width: "100%" }}
								className="btn btn-primary rounded"
								onClick={() => setShowShareOpportunityDialog(true)}
							>
								<span className="bi bi-share mr-2" />
								Share Opportunity
							</button>
						</div>

						<Link to="/rewards">
							<button className="view-all-button">More details</button>
						</Link>
					</>
				)}
			</div>
		</>
	);
};

export default RewardDashboard;
