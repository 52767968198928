import "react-quill/dist/quill.snow.css";
import "./TextEditor.css";

import React from "react";
import ReactQuill from "react-quill";

import EditorToolbar, { formats, modules } from "./EditorToolbar";

const TextEditor = (props) => {
	const onChangeHandler = (value) => {
		props.setValue(value);
	};
	const id = props.id ? props.id : "editor";
	return (
		<>
			<EditorToolbar toolbarId={`${id}`} toggleEditorVisibility={props.toggleEditorVisibility} />
			<ReactQuill
				theme="snow"
				value={props.value}
				onChange={onChangeHandler}
				placeholder={props.placeholder}
				modules={modules(`${id}`)}
				formats={formats}
				readOnly={props.readOnly ? props.readOnly : false}
				className={props.className ? props.className : ""}
			/>
		</>
	);
};

export default TextEditor;
