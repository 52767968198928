import "./Header.css";

import { googleLogout } from "@react-oauth/google";
import React from "react";
import { Link } from "react-router-dom";

import useIsLoggedIn from "../../../../hooks/use-IsLoggedIn";

const Header = () => {
	const isLoggedIn = useIsLoggedIn();
	const { firstName } = JSON.parse(localStorage.getItem("profileData"))
		? JSON.parse(localStorage.getItem("profileData"))
		: { firstName: "" };
	const { email } = JSON.parse(localStorage.getItem("profileData"))
		? JSON.parse(localStorage.getItem("profileData"))
		: { email: "" };

	const isDropzOpsUser = localStorage.getItem("isDropzOpsUser");

	return (
		<div className="header-container">
			<div className="left-side">
				<p>{firstName ? `Welcome, ${firstName}` : "Welcome, there's a lot to explore!"}</p>
			</div>

			<div className="right-side">
				<div className="dropdown tr-change-dropdown">
					<Link
						data-toggle="dropdown"
						className="img-holder text-dark d-flex align-items-center"
						to="#"
						aria-expanded="true"
					>
						<div className="right-side-box">
							<span className="fa fa-user mr-2 fs-14 text-primary" />
							<p>My Account</p>
							<span className="fa fa-angle-down" />
						</div>
					</Link>

					<ul className="dropdown-menu tr-change tr-list rounded p-1 custom-dropdown">
						<li className="dropdown-item d-flex align-items-center px-2">
							<i className="fa fa-user mr-2" />
							<div>
								<p className="m-0">{firstName ? `${firstName}` : "My Account "}</p>
								<h6 className="m-0">{email.substr(0, 14) + "..."}</h6>
							</div>
							<div className="dropDownleftArrow">
								<span className="fa fa-angle-right text-primary" />
							</div>
						</li>

						<Link to="/jobs">
							<li className="dropdown-item custom-dropdown-item d-flex align-items-center">
								<i className="fas fa-folder-open  dropDownIconStyle" />
								<a className="d-flex text-dark text-dark  align-items-center">Jobs</a>
								<div className="dropDownleftArrow">
									<span className="fa fa-angle-right text-primary" />
								</div>
							</li>
						</Link>

						{isLoggedIn ? (
							<>
								<li className="dropdown-item d-flex align-items-center custom-dropdown-item dropdown-li">
									<i className="fas fa-briefcase dropDownIconStyle" />
									<Link to="/jobs-preference" className="d-flex text-dark align-items-center">
										Job Preferences
									</Link>
								</li>
								{isDropzOpsUser && (
									<li className="dropdown-item d-flex align-items-center custom-dropdown-item dropdown-li">
										<i className="bi bi-person-fill-gear dropDownIconStyle" />
										<Link to="/job-market" className="d-flex text-dark align-items-center">
											Control Center
										</Link>
									</li>
								)}
								<li className="dropdown-item d-flex align-items-center custom-dropdown-item dropdown-li">
									<i className="fa fa-sign-out dropDownIconStyle" />
									<Link to="/signin" className="d-flex text-dark  align-items-center" onClick={googleLogout()}>
										Log Out
									</Link>
								</li>
							</>
						) : (
							<li>
								<Link to="/register">Register</Link>
							</li>
						)}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Header;
