import React, { useContext } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import tips from "../../../../assets/copy/tips";
import Loader from "../../../../components/Loader";
import SummaryBox from "../../../../components/ResumeBuilder/SummaryBox";
import Tips from "../../../../components/Tips/Tips";
import ResumeContext from "../../../../context/ResumeContext";
import { NavigationHandler } from "../../../../handlers/navigationHandler";

const WorkHistorySummary = () => {
	const navigate = useNavigate();

	const navigationHandler = new NavigationHandler();
	let nextLink;
	const link = useLocation().pathname.split("/")[2];

	const { resumeData } = useContext(ResumeContext);

	const { workHistory, journal } = resumeData;
	const [loading, setLoading] = React.useState(true);

	let resumeCurrentStatus = journal ? journal.currentStatus : false;
	let isComplete = resumeCurrentStatus == "COMPLETED";

	const nextTxt = "Continue";

	useEffect(() => {
		if (workHistory.length > 0 && workHistory[0].jobTitle === "") {
			return setLoading(true);
		}
		return setLoading(false);
	}, [workHistory]);

	function next(e) {
		e.preventDefault();

		let skipOne = 0;

		nextLink = navigationHandler.getNext(navigationHandler.template.navigationFlow, link, isComplete, skipOne).value
			.url;

		if (nextLink === "review") return navigate("/my-resumes");
		return navigate(`/resume/${nextLink}`);
	}

	const addAnotherEduHandler = () => {
		navigationHandler.getPrevious(navigationHandler.template.navigationFlow, link, isComplete, 0);

		return navigate("/resume/work-history");
	};

	const summaryBox = workHistory.map((summaryItem, index) => (
		<SummaryBox item={summaryItem} key={index} index={summaryItem.referenceId} number={index + 1} />
	));

	useEffect(() => {
		if (!loading && workHistory.length === 0) {
			navigate("/resume/work-history");
		}
	}, [workHistory]);

	return (
		<>
			<div className="container min-vh-100">
				<div className="section resume-summary-section">
					{loading ? (
						<Loader />
					) : (
						<div className="col-md-8">
							<div className="d-flex justify-content-end mb-2">
								<Tips content={tips.workHistory} />
							</div>
							{summaryBox}

							<span className="text-primary fs-14 cursor-pointer font-weight-semi" onClick={addAnotherEduHandler}>
								<span className="fa fa-plus mr-1" /> Add another position
							</span>
							<div className="d-flex align-items-center mt-4 justify-content-end">
								<Link to="/resume/education-information" className="btn btn-primary rounded-pill btn-lg" onClick={next}>
									{nextTxt}
								</Link>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default WorkHistorySummary;
