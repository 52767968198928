import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import tips from "../../../../assets/copy/tips";
import AutoSuggestion from "../../../../components/AutoSuggestion";
import Modal from "../../../../components/Modal/Modal";
import Tips from "../../../../components/Tips";
import InputField from "../../../../components/UIComponents/InputField";
import SelectField from "../../../../components/UIComponents/SelectField";
import ResumeContext from "../../../../context/ResumeContext";
import { FormValidation } from "../../../../handlers/formValidation";
import { NavigationHandler } from "../../../../handlers/navigationHandler";
import { getSanitizedHtml } from "../../../../handlers/utilityFunctions";
import options from "../../../../seed/dropDownOptions";
import mapping from "../../../../seed/metadata/filemapping";
import ResumeWorkDetails from "../ResumeWorkDetails/ResumeWorkDetails";

const ResumeWorkHistory = () => {
	const navigate = useNavigate();

	const navigationHandler = new NavigationHandler();
	const formValidation = new FormValidation();
	const link = useLocation().pathname.split("/")[2];

	const locationState = useLocation().state;
	const referenceId = locationState && locationState.referenceId;
	const locations = mapping["districtsRegions"][0].districtRegions;
	const [country, setCountry] = useState("Tanzania");
	const isTipsOpen = useSelector((state) => state.TipsReducer);

	const { resumeData } = useContext(ResumeContext);

	const { journal } = resumeData;
	let resumeCurrentStatus = journal ? journal.currentStatus : false;
	let isComplete = resumeCurrentStatus == "COMPLETED";

	//Declaring the form states
	const [workHistory, setWorkHistory] = useState({
		referenceId: "",
		jobTitle: "",
		employer: "",
		startDate: "",
		endDate: "",
		currentEmployer: "",
		relevantSkills: "",
	});

	const [skipStatus, setSkipStatus] = useState(false);
	const [isInputCompleted, setIsInputCompleted] = useState(false);
	const [workLocation, setWorkLocation] = useState("");

	//The form event handler
	const onChangeInput = (e) => {
		if (e.target.name === "workLocation") {
			setWorkLocation(e.target.value);
		} else if (e.target.name === "country") {
			setCountry(e.target.value);
		} else {
			setWorkHistory((prevState) => {
				const capitalized = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
				return {
					...prevState,
					[e.target.name]: capitalized,
				};
			});
		}
	};

	const [errors, setErrors] = useState({
		jobTitle: "",
		employer: "",
		address: "",
		startDate: "",
		endDate: "",
	});

	const [modalData, setModalData] = useState(null);
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	//form submission handler
	const addWorkHistory = (e) => {
		e.preventDefault();

		let dataToValidate = { ...workHistory, address: workLocation };

		dataToValidate = {
			...workHistory,
			address: `${dataToValidate.address}, ${country}`,
		};

		// Form validation
		let validations = formValidation.validateWorkHistoryInformation(errors, dataToValidate);

		if (validations.status) {
			setErrors(validations.errors);
		} else {
			return setErrors(validations.errors);
		}

		const address = dataToValidate.address.split(",");

		const workHistoryObject = {
			...workHistory,
			details: "",
			city: address[0],
			region: address[1],
			country: address[2],
		};

		//* This is just to that I can move on to the next page
		if (isInputCompleted) {
			setModalData(workHistoryObject);
			setShowDetailsModal(true);
		}
	};

	useEffect(() => {
		const { jobTitle, employer, city, country, startDate, endDate } = workHistory;

		const formData = { jobTitle, employer, city, country, startDate };

		if (formValidation.isFormComplete(formData) && (endDate !== "" || workHistory.currentEmployer)) {
			setIsInputCompleted(true);
		} else {
			setIsInputCompleted(false);
		}
	}, [workHistory]);

	useEffect(() => {
		if (resumeData.workHistory.length > 0 && resumeData.workHistory[0].jobTitle !== "" && referenceId) {
			setWorkHistory(() => {
				const data = resumeData.workHistory.filter((item) => item.referenceId === referenceId)[0];

				setWorkLocation(`${data.city},${data.region},${data.country}`);

				return {
					referenceId: data.referenceId && data.referenceId,
					jobTitle: data.jobTitle,
					employer: data.employer,
					city: data.city,
					region: data.region,
					country: data.country,
					startDate: data.startDate,
					endDate: data.endDate,
					currentEmployer: data.currentEmployer,
					relevantSkills: data.relevantSkills,
				};
			});
		}
	}, [resumeData]);

	useEffect(() => {
		if (skipStatus) {
			addWorkHistory();
		}
	}, [skipStatus]);

	// const skipSection = () => {
	//     setSkipStatus(true);
	//     setIsInputCompleted(true);
	// };

	const skipSection = () => {
		setSkipStatus(true);
		setIsInputCompleted(true);
		navigationHandler.getNext(navigationHandler.template.navigationFlow, link, isComplete);
		navigate("/resume/skills");
	};

	const checkboxRef = React.createRef();

	return (
		<>
			<div
				className="modal fade"
				id="skipSectionModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="skipSectionModalTitle"
				aria-hidden="false"
			>
				<Modal skip={skipSection} />
			</div>

			<div className={isTipsOpen ? "container open-tips min-vh-100" : "container min-vh-100"}>
				<div className="section">
					<div className="mb-5 text-meduim">
						<h2 className="tr-title-big mb-3">Tell us about your most recent job </h2>
						<p>
							We&#39;ll start there and work backward. <i>[No-Experience ? Click Next to Skip]</i>{" "}
						</p>
					</div>
					<div className="col-md-6">
						<div className="d-flex justify-content-end mb-2">
							<Tips content={tips.workHistory} />
						</div>
					</div>
					<div className="form-wrapper">
						<div className="row">
							<div className="col-md-6 m-0 p-0">
								<form action="">
									<div className="row m-0">
										<div className="col-12">
											<InputField
												name="jobTitle"
												type="text"
												label="Job Title"
												placeholder="Enter Job Title"
												value={workHistory.jobTitle}
												onChange={onChangeInput}
												error={errors.jobTitle}
											/>
										</div>
										<div className="col-12">
											<InputField
												type="text"
												label="Employer"
												name="employer"
												value={workHistory.employer}
												onChange={onChangeInput}
												error={errors.employer}
												placeholder="Enter Employer Name"
											/>
										</div>

										<div className="col-md-6">
											<SelectField
												name="country"
												value={country}
												onChange={onChangeInput}
												options={options.phoneCountryCodeOptions}
												label="Country"
											/>
										</div>

										<div className="col-md-6">
											{!(country === "Tanzania") && (
												<InputField
													type="text"
													label="Ex: City, Region"
													name="workLocation"
													value={workLocation}
													onChange={onChangeInput}
													placeholder="Enter Work Location"
													error={errors.address}
												/>
											)}
											{country === "Tanzania" && (
												<AutoSuggestion
													suggestions={locations}
													label="Eg: District, Region, Country"
													type="text"
													name="workLocation"
													userInput={workLocation}
													setUserInput={setWorkLocation}
													isForm={true}
													error={errors.address}
												/>
											)}
										</div>

										<div className="col-md-6">
											<div className="form-group">
												<label>Start Date</label>
												<input
													type="date"
													className="form-control"
													onChange={onChangeInput}
													name="startDate"
													value={workHistory.startDate}
												/>
												{errors.startDate && <div className="error">{getSanitizedHtml(errors.startDate)}</div>}
											</div>
										</div>
										<div className="col-md-6">
											{!workHistory.currentEmployer && (
												<div className="form-group">
													<label>End Date</label>
													<input
														type="date"
														className="form-control"
														disabled={workHistory.currentEmployer}
														onChange={onChangeInput}
														name="endDate"
														value={workHistory.endDate}
													/>
													{errors.endDate && <div className="error">{getSanitizedHtml(errors.endDate)}</div>}
												</div>
											)}
										</div>
										<div className="col-12">
											<label
												className="form-check d-flex align-items-center p-0 mb-3"
												htmlFor="legalNameToSAcknowledged"
											>
												<input
													type="checkbox"
													className="custom-control-input"
													id="customCheck"
													name="currentEmployer"
													ref={checkboxRef}
													value={workHistory.currentEmployer}
													checked={workHistory.currentEmployer}
													onChange={() => {
														setWorkHistory((prevState) => {
															return {
																...prevState,
																endDate: "",
																currentEmployer: !prevState.currentEmployer,
															};
														});
													}}
												/>
												<span
													className="checkmark"
													onClick={() => {
														checkboxRef.current.click();
													}}
												/>
												<div className="ml-4 pl-2">
													<p className="fs-14	font-weight-medium m-0 text-black">I still work here</p>
												</div>
											</label>
										</div>
										{/* TODO: Add this functionality */}
										{/* <div className="col-12">
											<button className="text-primary font-weight-semi pl-0 fs-14">
												<i className="fa fa-plus" /> Add position details 
											</button>
										</div> */}
									</div>
									<div className="col-12 action mt-4 d-flex align-items-center justify-content-end">
										<button
											className="font-weight-semi pl-0 fs-14 text-primary mr-3"
											onClick={() => navigate("/resumes/education-information")}
										>
											Skip for now
										</button>
										<button
											data-toggle="modal"
											data-target={!isInputCompleted ? "#skipSectionModal" : ""}
											onClick={addWorkHistory}
											className="btn btn-primary rounded-pill btn-lg"
										>
											Continue
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Details Modal */}
			{showDetailsModal && (
				<ResumeWorkDetails closeModal={() => setShowDetailsModal(false)} workHistoryObject={modalData} />
			)}
		</>
	);
};

export default ResumeWorkHistory;
