const certifications = {
	content: [
		{
			bp: false,
			value: "You should include certifications you hold as these will position you as more capable for the role",
		},
		{
			bp: true,
			value:
				"If your certification is prestigious and highly recognized in the industry, you should include it further up on the resume and draw attention as well as in its own designated section. For example you may want to lead with this in your Professional Summary.",
		},
		{
			bp: true,
			value: "No need of elaborating any further beyond listing the certification name and the year you received it.",
		},
		{
			bp: true,
			value: "Only include certifications that are relevant to the job you are applying for or to your field of study.",
		},
		{
			bp: true,
			value:
				"Include the full name of the certification, the certifying organization, and the date you received the certification.",
		},
		{
			bp: true,
			value:
				"If you received any awards or recognition related to your certification, be sure to include them in your CV.",
		},
		{
			bp: true,
			value:
				"List your certifications in order of relevance to the job you are applying for, with the most relevant certifications listed first.",
		},
		{
			bp: true,
			value:
				"If your certification has an expiration date, include this information in your CV and make sure to keep your certification current.",
		},
	],
};

export default certifications;
