import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { interactions } from "../../../../handlers/enums";
import { calculateDateToToday, dateObjToYMD } from "../../../../handlers/utilityFunctions";
import useIsLoggedIn from "../../../../hooks/use-IsLoggedIn";
import { ResumeDataService } from "../../../../services/create-resume/resume-data";
import JobPayWall from "../../../../Views/Jobs/JobPayWall";
import JobDescriptionModal from "../../JobDescriptionModal";
import JobScoreFit from "../../JobScoreFit";
import NotRelevantModal from "../../NotRelevantModal";
import SmartApplyConfirmationModal from "../../SmartApplyConfirmationModal";

const RecommendationDetails = ({ details }) => {
	const isLoggedIn = useIsLoggedIn();

	const [, setResumeList] = useState([]);
	const resumeDataService = new ResumeDataService();
	const [showLoginBox, setShowLoginBox] = useState(false);
	const [enablePaywall, setEnablePaywall] = useState(true);
	const [showResumeBuilderBox, setShowResumeBuilderBox] = useState(false);
	const [isSavedJob, setIsSavedJob] = useState(false);
	const [isApplied, setIsApplied] = useState(false);
	const [interactionStatus, setInteractionStatus] = useState("");

	function successCallBack(response) {
		const { interaction_status } = response.data;
		setInteractionStatus(interaction_status);
		toast.success(`${interaction_status} successfully`);
	}

	function errorCallBack() {
		toast.error("Something went wrong");
	}

	function setApplicationInteractions(reasons, interactionType) {
		const interactionData = {
			payload: {
				interactionType: interactionType,
				notes: reasons ?? [],
			},
			jobId: details._id,
		};

		resumeDataService.setInteraction("recommended-jobs/", interactionData, successCallBack, errorCallBack);
	}

	function setJobNotRelevant(reasons) {
		setApplicationInteractions(reasons, interactions.NOT_RELEVANT);
	}

	function onSaveJob() {
		if (!isSavedJob) {
			setIsSavedJob(true);
			setApplicationInteractions(null, interactions.SAVED_FOR_LATER);
		}
	}

	function onApplyJob() {
		if (!isApplied) {
			setIsApplied(true);
			setApplicationInteractions(null, interactions.APPLIED);
		}
	}

	useEffect(() => {
		//Check if the user is Logged In
		if (isLoggedIn) {
			//Get the user resumeList.
			const _resumeList = JSON.parse(localStorage.getItem("resumeList"));

			//Check if the user has a resume.
			if (_resumeList !== null) {
				//Check if resume is completed.
				if (_resumeList[0].journal.currentStatus === "COMPLETED") {
					const resumeListOptions = _resumeList.map((resume) => {
						return {
							id: resume.id,
							resumeTitle: resume.journal.resumeTitle ? resume.journal.resumeTitle : resume.heading.resumeTitle,
						};
					});
					setResumeList(resumeListOptions);
					setEnablePaywall(false);
					return;
				}
			}

			//Tell the user to create resume.
			setShowLoginBox(false);
			setShowResumeBuilderBox(true);
			return;
		}

		//tell the user to log or sign in
		setShowLoginBox(true);
		setShowResumeBuilderBox(false);
	}, []);

	useEffect(() => {
		setInteractionStatus(details.interaction_status);
	}, [details]);

	return (
		<>
			<div className="col-md-9">
				<div className="employee-dashboard-right">
					<div className="tab-content">
						{enablePaywall ? (
							<JobPayWall isLoggedOut={showLoginBox} isResumeNotCompleted={showResumeBuilderBox} />
						) : Object.keys(details).length === 0 ? (
							<div className="paywall">
								<h3 className="job-title" style={{ textAlign: "center" }}>
									<span>Select one of your jobs to View details.</span>
								</h3>
							</div>
						) : (
							<>
								<div role="tabpanel" className="tab-pane p-3 active" id="job-1">
									{/*jobs status info*/}
									<div className="job-status-info">
										<div className="job-status-info-header">
											<div className="left">
												<img className="img-fluid company-logo" src="/images/icons/default_company_logo.svg" alt="" />
												<div className="details">
													<p className="left">
														<h4 className="mr-2">{details.job.jobTitle}</h4>
														<span className="px-2 border rounded-pill bg-light-3 font-weight-normal">
															{interactionStatus}
														</span>
													</p>
													<p className="mb-0">
														<span className="px-1">{details.job.companyName}</span> |
														<span
															className="text-primary cursor-pointer ml-2"
															data-toggle="modal"
															data-target="#jobDescriptionModal"
														>
															View Job Description
														</span>
													</p>
													<div className="text-muted fs-12 mb-0">
														<span className="px-1">Posted {calculateDateToToday(details.job.lastUpdatedAt)} |</span>
														<span className="px-1">Deadline Date: {dateObjToYMD(details.job.deadlineDate)}</span>
													</div>
												</div>
											</div>
											{interactionStatus === "Applied" ? null : (
												<div className="right">
													{interactionStatus === "Saved" ? null : (
														<p className="cursor-pointer" onClick={onSaveJob}>
															<span className="job-save-icon active ml-2">
																<span className={`${isSavedJob ? "fas " : "far "}fa-bookmark text-primary`} />
															</span>
															<span className="text-primary ml-2">{isSavedJob ? "Saved Job" : "Save Job"}</span>
														</p>
													)}
													<p className="cursor-pointer">
														<span className="job-save-icon active ml-2">
															<span className={`fa ${isApplied ? "fa-check" : "fa-plus"} text-primary`} />
														</span>
														<span
															className="text-primary ml-2"
															data-toggle="modal"
															data-target="#confirmSmartApplyModal"
														>
															{isApplied ? "Applied Job" : "Apply Job"}
														</span>
													</p>
													<p className="cursor-pointer">
														<span className="job-save-icon active ml-2">
															<span className="fa fa-ban text-primary" />
														</span>
														<span className="text-primary ml-2" data-toggle="modal" data-target="#notRelevantModal">
															Not Relevant
														</span>
													</p>
												</div>
											)}
										</div>
									</div>
									{/*jobs status info ends*/}

									{/* Score Fit Details*/}
									{"education_matched_result" in details ? (
										<div className="job-desc py-3 border-top">
											<div className="container mt-3">
												<div className="row p-3">
													<div className="col-9">
														<div className="d-flex">
															<div className="col d-flex flex-column justify-content-center">
																<h3 className="mb-0">Fit Scoring Overview</h3>
																<p>
																	Dproz considers a lot of parameters inside 3 core areas of your job description. Check
																	how you feature on these parameters.
																</p>
															</div>
														</div>
													</div>
													<div className="col-1" />
													<div className="col-2">
														<span className="d-flex flex-end align-items-end">
															<h1
																className={`${
																	details.fit_score > 80
																		? "bg-success"
																		: details.fit_score < 50
																			? "bg-danger"
																			: "bg-warning"
																} text-white`}
																style={{
																	width: "80px",
																	height: "80px",
																	borderRadius: "50%",
																	display: "flex",
																	justifyContent: "center",
																	alignItems: "center",
																}}
															>
																{details.fit_score}
															</h1>
															<p className="fs-4"> /100</p>
														</span>
													</div>
												</div>
												<JobScoreFit details={details} />
											</div>
										</div>
									) : null}
								</div>
								<JobDescriptionModal details={details} />

								<SmartApplyConfirmationModal
									submitApplication={onApplyJob}
									title={details.job.jobTitle}
									fitScore={details.fit_score}
								/>
							</>
						)}
					</div>
				</div>
			</div>

			<NotRelevantModal setJobNotRelevant={setJobNotRelevant} />
		</>
	);
};

export default RecommendationDetails;
