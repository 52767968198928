import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

function CustomLink({ children, to, ...props }) {
	let resolved = useResolvedPath(to);
	let match = useMatch({ path: resolved.pathname, end: true });

	return (
		<div className={match ? "nav-bar-link mb-1 active" : "nav-bar-link mb-1"}>
			<Link to={to} {...props}>
				{children}
			</Link>
		</div>
	);
}

export default CustomLink;
