import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { useEffect, useState } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import ReactGA from "react-ga4";
import { Toaster } from "react-hot-toast";
import { decodeToken, isExpired } from "react-jwt";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import AuthContext from "./app/context/Auth/AuthContext";
import RouteHandler from "./app/router/RouteHandler";
import resume from "./app/seed/buildResume"; //we could use this to build a sample resume.
import { RouteHierachy } from "./app/seed/routes-breakdown";
import { ResumeDataService } from "./app/services/create-resume/resume-data";

const TRACKING_ID = "G-Y75YMD4HJM";
ReactGA.initialize(TRACKING_ID);

function App() {
	const routerHierachy = new RouteHierachy();
	const resumeDataService = new ResumeDataService();

	const dispatch = useDispatch();
	dispatch({ type: "SAMPLE_DATA", payload: resume.getResume() });
	dispatch({ type: "SET_ROUTES", payload: routerHierachy.getHierachy() });

	const storedProfile = localStorage.getItem("profileData");

	const [userData, setUserData] = useState(storedProfile ? JSON.parse(storedProfile) : null);

	const [cookies, _, removeCookie] = useCookies(["Authorization"]);

	const onSuccessSettings = (response) => {
		const token = cookies.Authorization.replaceAll("GO::", "").replaceAll("CO::", "");
		// const emptyState = {
		// 	firstName: "",
		// 	lastName: "",
		// 	email: "",
		// 	userID: "",
		// 	address: null,
		// 	phoneNumber: "",
		// };

		if (response.data.isDropzOpsUser) localStorage.setItem("isDropzOpsUser", true);

		const decodedToken = decodeToken(token);

		const data = {
			email: response.data.emailAddress,
			userID: response.data._id,
			lastName: decodedToken.family_name,
			firstName: decodedToken.given_name,
			picture: decodedToken.picture,
		};

		setUserData(data);
		localStorage.setItem("profileData", JSON.stringify(data));
	};

	const onErrorSettings = () => {};

	async function fetchUserProfile() {
		await resumeDataService.getUser(onSuccessSettings, onErrorSettings);
	}

	useEffect(() => {
		if (cookies.Authorization) {
			const token = cookies.Authorization.replaceAll("GO::", "").replaceAll("CO::", "");

			const isMyTokenExpired = isExpired(token);

			if (!isMyTokenExpired) {
				setUserData(JSON.parse(storedProfile));
			} else {
				removeCookie("Authorization");
				localStorage.removeItem("profileData");
			}
		} else {
			removeCookie("Authorization");
			localStorage.removeItem("profileData");
		}
	}, []);

	useEffect(() => {
		if (cookies.Authorization) {
			const token = cookies.Authorization.replaceAll("GO::", "").replaceAll("CO::", "");

			const isMyTokenExpired = isExpired(token);

			if (isMyTokenExpired) {
				setUserData(null);
				removeCookie("Authorization");
				localStorage.removeItem("profileData");
			} else {
				if (storedProfile) {
					storedProfile;
					fetchUserProfile();
				} else if (!storedProfile.userID) {
					fetchUserProfile();
				}
			}
		} else {
			setUserData(null);
			localStorage.removeItem("profileData");
		}
	}, [cookies.Authorization]);

	useEffect(() => {}, [userData]);

	return (
		<AuthContext.Provider value={{ userData, setUserData, fetchUserProfile }}>
			<GoogleOAuthProvider clientId="509917624249-5ociqc44vp23feb7i78ifgq1g6aiupel.apps.googleusercontent.com">
				<CookiesProvider>
					<BrowserRouter>
						<RouteHandler userData={userData} />
					</BrowserRouter>
					{/* <Footer /> */}
				</CookiesProvider>
				<Toaster position="top-right" />
			</GoogleOAuthProvider>
		</AuthContext.Provider>
	);
}

export default App;
