import React from "react";

function ExpertOrderCard() {
	return (
		<div className="expert-review-orders-card-container">
			<div className="expert-review-orders-card-header">
				<div className="expert-review-orders-card-header-leading-container">
					<div className="d-flex flex-column align-items-start p-0">
						<div
							style={{
								fontWeight: 500,
								fontSize: "16px",
								lineHeight: "24px",
								color: "#1A1A1A",
							}}
						>
							Marketing resume
						</div>
						<div
							style={{
								fontWeight: 400,
								fontSize: "14px",
								lineHeight: "20px",
								color: "#5D5D5D ",
							}}
						>
							James Smith
						</div>
					</div>
					<div
						className="d-flex align-items-center justify-content-center text-center"
						style={{
							padding: "6px 8px",
							gap: "8px",
							height: "28px",
							background: "#DFF7EA",
							border: "1px solid #C2E0D4",
							borderRadius: "6px",
						}}
					>
						<div
							style={{
								fontWeight: 500,
								fontSize: "13px",
								lineHeight: "16px",
								letterSpacing: "0.0015em",
								color: "#017336 ",
								width: "81px",
							}}
						>
							In Progress
						</div>
					</div>
				</div>
				<div
					className="d-flex align-items-center justify-content-center text-center p-0 cursor-pointer"
					style={{
						padding: "10px 12px",
						gap: "8px",
						width: "106px",
						height: "40px",
						background: "#0069CB",
						borderRadius: "6px",
					}}
				>
					<div
						style={{
							fontWeight: 500,
							fontSize: "14px",
							lineHeight: "20px",
							color: "#FFFFFF",
						}}
					>
						View Details
					</div>
				</div>
			</div>
			<div className="d-flex align-items-start" style={{ padding: "16px 0px", gap: "57px" }}>
				<div className="d-flex flex-column align-items-start p-0" style={{ gap: "8px" }}>
					<div className="d-flex align-items-start p-0" style={{ gap: "136px" }}>
						<div
							className="d-flex align-items-center p-0"
							style={{
								fontWeight: 400,
								fontSize: "14px",
								lineHeight: "20px",
								textAlign: "center",
								color: "#5D5D5D ",
							}}
						>
							Order ID
						</div>
						<div
							className="d-flex align-items-center p-0"
							style={{
								fontWeight: 500,
								fontSize: "14px",
								lineHeight: "20px",
								textAlign: "center",
								color: "#3D3D3D ",
							}}
						>
							#1234
						</div>
					</div>
					<div className="d-flex align-items-start p-0" style={{ gap: "135px" }}>
						<div
							className="d-flex align-items-center p-0"
							style={{
								fontWeight: 400,
								fontSize: "14px",
								lineHeight: "20px",
								textAlign: "center",
								color: "#5D5D5D ",
							}}
						>
							Package
						</div>
						<div
							className="d-flex align-items-center p-0"
							style={{
								fontWeight: 500,
								fontSize: "14px",
								lineHeight: "20px",
								textAlign: "center",
								color: "#3D3D3D ",
							}}
						>
							Career Boost
						</div>
					</div>
					<div className="d-flex align-items-start p-0" style={{ gap: "117px" }}>
						<div
							className="d-flex align-items-center p-0"
							style={{
								fontWeight: 400,
								fontSize: "14px",
								lineHeight: "20px",
								textAlign: "center",
								color: "#5D5D5D ",
							}}
						>
							Ordered on
						</div>
						<div
							className="d-flex align-items-center p-0"
							style={{
								fontWeight: 500,
								fontSize: "14px",
								lineHeight: "20px",
								textAlign: "center",
								color: "#3D3D3D ",
							}}
						>
							May 15, 2023
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ExpertOrderCard;
