import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Loader from "../../../components/Loader";
import Feedback from "../../../components/Reviews/FeedBack";
import sectionNamesFromRoutes from "../../../seed/sectionNamesFromRoutes";

function ResumeFeedbackModal({ feedbacks, isFeedbackOpen, toggleFeedbackModal, feedbackLoading }) {
	const location = useLocation();
	const [sectionName, setSectionName] = useState("");
	const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);

	function filterFeedbacks() {
		if (feedbacks.length > 0) {
			const _sectionName = sectionNamesFromRoutes(location.pathname);
			const _filteredFeedbacks = feedbacks.filter((feedback) => {
				return _sectionName === feedback.section;
			});

			setSectionName(_sectionName);
			setFilteredFeedbacks(_filteredFeedbacks);
		}
	}

	useEffect(() => {
		filterFeedbacks();
	}, [location, feedbacks]);

	return (
		<div className={isFeedbackOpen ? "right-sidebar open-right-sidebar" : "right-sidebar"}>
			<div className="modal-header">
				<h5 className="modal-title tr-title mb-0">
					{feedbackLoading
						? "Loading feedbacks ...."
						: filteredFeedbacks.length === 0 && isFeedbackOpen
							? "Resume Feedbacks."
							: `Resume Feedbacks for ${sectionName.toLowerCase()} section`}
				</h5>
				<button type="button" className="close" aria-label="Close" onClick={() => toggleFeedbackModal()}>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			{feedbackLoading ? (
				<div style={{ minHeight: "80vh" }}>
					<div className="container-fluid h-100">
						<div className="d-flex p-4 align-center h-75 justify-content-center center-modal-loader">
							<Loader />
						</div>
					</div>
				</div>
			) : filteredFeedbacks.length === 0 && isFeedbackOpen ? (
				<div style={{ minHeight: "80vh" }}>
					<div className="container-fluid h-100">
						<div
							className="d-flex p-4 ml-5 align-center h-75 justify-content-left center-modal-loader"
							style={{ right: "7%" }}
						>
							<h6> No Feedback Received for this section </h6>
						</div>
					</div>
				</div>
			) : (
				<div className="modal-body pb-5">
					{filteredFeedbacks.map((feedback) => (
						<Feedback
							key={feedback._id}
							feedback={feedback}
							id={`sectionHeading${feedback.section}`}
							navigateToSections={false}
						/>
					))}
				</div>
			)}
		</div>
	);
}

export default ResumeFeedbackModal;
