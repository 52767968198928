import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import tips from "../../../assets/copy/tips";
import AutoSuggestion from "../../../components/AutoSuggestion";
import ErrorToast from "../../../components/ErrorToast/";
import ResumePreview from "../../../components/ResumeBuilder/ResumePreview/ResumePreview";
import Spinner from "../../../components/Spinner";
import InputField from "../../../components/UIComponents/InputField/";
import ResumeContext from "../../../context/ResumeContext";
import { NavigationHandler } from "../../../handlers/navigationHandler";
import { PreviousButton } from "../../../handlers/valueTransformation";
import { StaticValue } from "../../../seed/constants";
import { getJobFunctions } from "../../../seed/getMetadata";
import mapping from "../../../seed/metadata/filemapping";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import { isValidEmail, isValidLocationInHeaderView, isValidPhoneNumber } from "./../../../handlers/validationFunctions";

const ResumeContactInfo = () => {
	const template = useSelector((state) => state.TemplateSelectionReducer);

	const selectedTemplate = template.selectedTemplate;
	const navigationHandler = new NavigationHandler();

	const locations = mapping["districtsRegions"][0].districtRegions;

	const { resumeData, setResumeData } = useContext(ResumeContext);
	let { education, journal } = resumeData;
	let resumeCurrentStatus = journal ? journal.currentStatus : false;
	let isComplete = resumeCurrentStatus == "COMPLETED";

	useEffect(() => {
		navigationHandler.template = template;
	}, [template]);

	const resumeDataService = new ResumeDataService();
	const staticValue = new StaticValue();
	const staticValues = staticValue.getDeafaulValues();
	let nextTxt = "Continue";
	let PreviousTxt = "Exit";

	const [cookies, setCookie] = useCookies(["resume"]);

	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	let { contactInfo } = resumeData;
	// this is used to check if context is not updated

	const {
		firstName,
		middleName,
		lastName,
		phoneNo,
		email,
		country,
		resumeTitle,
		resumeFunction,
		legalNameToSAcknowledged,
	} = contactInfo;

	const checkboxRef = React.createRef();

	const [address, setAddress] = useState("");
	useEffect(() => {
		if (!(contactInfo.city === "") && !(contactInfo.region === "")) {
			setAddress(`${contactInfo.city}, ${contactInfo.region}`);
		}
	}, [contactInfo]);

	//Declaring the form states
	const [formContactInfo, setContactInfo] = useState(contactInfo);
	useEffect(() => {
		setContactInfo(contactInfo);
	}, [contactInfo]);

	//The form event handler
	const onChangeInput = (e) => {
		setContactInfo((prevState) => {
			return {
				...prevState,
				[e.target.name]: e.target.value,
			};
		});

		setResumeData((prevState) => {
			const [city, region] = address.split(",").map((str) => str.trim());

			return {
				...prevState,
				contactInfo: {
					...prevState.contactInfo,
					[e.target.name]: e.target.value,
					address,
					city,
					region,
				},
			};
		});
	};

	const [errors, setErrors] = useState({
		firstName: "",
		middleName: "",
		lastName: "",
		email: "",
		confirmEmail: "",
		confirmPhone: "",

		phoneNo: "",
		resumeTitle: "",
		resumeFunction: "",
		city: "",
		address: "",
		country: "",
		region: "",
		legalNameToSAcknowledged: "",
	});

	//form submission handler
	function successCreateNewResume(data) {
		setLoading(false);

		// const { Authorization } = data.headers;

		let expireDate = new Date();
		if (!cookies[staticValues.localStorageResumeId]) {
			expireDate.setDate(expireDate.getDate() + 30);
			setCookie(staticValues.localStorageResumeId, data.data._id, {
				path: "/",
				expires: expireDate,
			});
		}
		let authHeader = data.data.journal.user._id;
		if (!cookies["Authorization"]) {
			//it looks like the data.data.journal.user._id does not come during patching, will confirm. This is to prevent setting undefine if we already have a cookie
			expireDate.setDate(expireDate.getDate() + 30);
			setCookie("Authorization", authHeader, {
				path: "/",
				expires: expireDate,
			});
		}

		let skipOne = 0;

		let tempLink = "education-information";

		skipOne = education.length > 0 ? 1 : 0;
		tempLink = education.length > 0 ? "education-summary" : "education-information";
		PreviousTxt = PreviousButton(true, PreviousTxt, navigationHandler, skipOne);

		return navigate(`/resume/${tempLink}`); //temporary link
	}

	function successPatchingContactInfo() {
		let { education } = resumeData;
		let tempLink = education.length > 0 ? "education-summary" : "education-information";

		if (isComplete) return navigate("/my-resumes");

		return navigate(`/resume/${tempLink}`);
	}

	function nextButton() {
		setServerErrors([]);
		let inputData = {
			data: {
				contactInfo: contactInfo,
				templateId: selectedTemplate.templateName ? selectedTemplate.templateName : "fresher_template",
				yearsOfExperience: selectedTemplate.yearsOfExperience,
				resumeTitle: formContactInfo.resumeTitle + "-" + formContactInfo.resumeFunction,
			},
		};

		setLoading(true);
		if (!cookies[staticValues.localStorageResumeId]) {
			//user not registered or tried to create a new resume. We will check if we can create new or already has resume

			resumeDataService.createResume(inputData, successCreateNewResume, errorCreateNewResume);
		} else {
			//user has account or already in resume progress

			updateContactInformation(inputData, successPatchingContactInfo, errorCreateNewResume);

			//localStorage.removeItem(staticValues.localStorageResumeId); //temporary
		}
	}

	function updateContactInformation(contactInfo, successPatchContactInfo, errorPatchContactInfo) {
		const resumeId = cookies[staticValues.localStorageResumeId];

		const section = "HEADING";
		resumeDataService.patchResumeLedger(resumeId, contactInfo, section, successPatchContactInfo, errorPatchContactInfo);
	}

	const [showLoginPopup, setShowLoginPopup] = useState(false);
	const [showBlockedPopup, setShowBlockedPopup] = useState(false);
	const [blockedPopupData, setBlockedPopupData] = useState("");

	const [serverErrors, setServerErrors] = useState([]);

	useEffect(() => {
		const values = Object.values(errors);
		const filteredValues = values.filter((item) => item !== "");
		if (filteredValues.length > 0) {
			setServerErrors((prev) => [...prev, "Please fill all the required fields correctly"]);
		}
	}, [errors]);

	function errorCreateNewResume(data) {
		setLoading(false);
		const serverResponse = data.response.data;

		Array.isArray(serverResponse.message)
			? setServerErrors((prev) => {
					return [...prev, ...serverResponse.message];
				})
			: setServerErrors((prev) => {
					return [...prev, serverResponse.message];
				});

		if (data.response) {
			let response = data.response.status;

			if (response === 409) {
				//resume exist. If account exists redirect them to login
				setShowLoginPopup(true);
			}
			if (response === 412) {
				setShowBlockedPopup(true);
				setServerErrors([]);
				setBlockedPopupData(data.response.data.message);
			}
		} else {
			//handle differently
			setErrors(data);
		}
	}

	const validateForm = () => {
		var formStatus = true;

		//validation
		if (!isValidLocationInHeaderView(address)) {
			setLoading(false);

			setErrors((prevState) => {
				return {
					...prevState,
					address: "Please enter a valid address eg District , Region ",
				};
			});
			formStatus = false;
		}

		//validation email
		if (!isValidEmail(email)) {
			setLoading(false);

			setErrors((prevState) => {
				return {
					...prevState,
					email: "Please enter a valid email address",
				};
			});
			formStatus = false;
		}
		//validation phone
		if (!isValidPhoneNumber(phoneNo)) {
			setLoading(false);

			setErrors((prevState) => {
				return {
					...prevState,
					phoneNo: "Please enter a valid phone number eg:  255712XXXXXX",
				};
			});

			formStatus = false;
		}

		if (formStatus === true) {
			return true;
		} else {
			return false;
		}
	};

	const addContactInfo = () => {
		setServerErrors([]);
		//	e.preventDefault();
		setErrors({
			firstName: "",
			middleName: "",
			lastName: "",
			email: "",
			confirmEmail: "",
			confirmPhone: "",
			phoneNo: "",
			resumeTitle: "",
			resumeFunction: "",
			city: "",
			address: "",
			country: "",
			region: "",
			legalNameToSAcknowledged: "",
		});

		// check fields if empty
		if (
			firstName === "" ||
			lastName === "" ||
			phoneNo === "" ||
			email === "" ||
			address === "" ||
			country === "" ||
			resumeTitle === "" ||
			resumeFunction === ""
		) {
			//* setContext state to incomplete
			setLoading(false);

			return setErrors({
				firstName: firstName === "" ? "First name is required" : "",
				lastName: lastName === "" ? "Last name is required" : "",
				email: email === "" ? "Email is required" : "",

				phoneNo: phoneNo === "" ? "Phone number is required" : "",
				address: address === "" ? "Address is required" : "",
				country: country === "" ? "Country is required" : "",
				resumeTitle: resumeTitle === "" ? "Resume title is required" : "",
				resumeFunction: resumeFunction === "" ? "Area of Expertise is required" : "",
			});
		}

		//validating inputs
		const validationStatus = validateForm();
		if (validationStatus === false) {
			return;
		}

		// maping address
		const [city, region] = address.split(",").map((str) => str.trim());

		contactInfo = {
			...contactInfo,
			city: city,
			region: region,
		};

		setResumeData((prevState) => {
			return {
				...prevState,
				contactInfo: {
					firstName,
					middleName,
					lastName,
					phoneNo,
					address,
					email,
					city,
					country,
					region,
					legalNameToSAcknowledged,
					resumeFunction,
					resumeTitle,
				},
			};
		});
		return nextButton();
	};

	useEffect(() => {}, [showLoginPopup]);

	return (
		<>
			{showLoginPopup && (
				<div
					className="modal-dialog modal-dialog-centered"
					role="document"
					style={{
						position: "absolute",
						zIndex: 2,
						backgroundColor: "#0000006e",
						margin: 0,
						width: "100vw",
						maxWidth: "inherit",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<div
						className="modal-content"
						style={{
							width: "fit-content",
						}}
					>
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLongTitle">
								In-progress Resume Exists, Login to Continue
							</h5>
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
								onClick={() => setShowLoginPopup(false)}
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							You already have an existing resume using this email address, to continue please log in or use a different
							email
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								data-dismiss="modal"
								onClick={() => setShowLoginPopup(false)}
							>
								Stay
							</button>
							<Link to="/signin">
								<button type="button" className="btn btn-primary" data-dismiss="modal">
									Log In
								</button>
							</Link>
						</div>
					</div>
				</div>
			)}
			{showBlockedPopup && (
				<div
					className="modal-dialog modal-dialog-centered"
					role="document"
					style={{
						position: "absolute",
						zIndex: 2,
						backgroundColor: "#0000006e",
						margin: 0,
						width: "100vw",
						maxWidth: "inherit",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<div
						className="modal-content"
						style={{
							width: "50%",
						}}
					>
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLongTitle">
								Closed Alpha
							</h5>
						</div>
						<div className="modal-body">
							<span
								dangerouslySetInnerHTML={{
									__html: blockedPopupData.includes(":") ? blockedPopupData.split(":")[0] : blockedPopupData,
								}}
							/>
						</div>
						<div className="modal-footer">
							<a
								href={
									blockedPopupData.includes(":") &&
									blockedPopupData.substring(blockedPopupData.indexOf(":") + 1).split(":", 2)[1]
								}
								target="_blank"
								rel="noreferrer"
							>
								<button type="button" className="btn btn-primary" data-dismiss="modal">
									Secure a Slot
								</button>
							</a>
						</div>
					</div>
				</div>
			)}

			<div className="container mb-5">
				<div className="mb-3 text-dark">
					<p>Enter your contact details to ensure recruiters can easily reach you.</p>
				</div>
				{serverErrors.map((item, index) => {
					return <ErrorToast error={item} key={index} />;
				})}

				<div className="row m-0">
					<div className="col-md-6 m-0 p-0">
						<form onSubmit={(e) => e.preventDefault()}>
							<div className="row">
								<div className="col-md-12 p-0">
									<div className="row m-0">
										<div className="col-md-6">
											<InputField
												name="firstName"
												label="First Name*"
												placeholder="First Name"
												value={firstName}
												onChange={onChangeInput}
												error={errors.firstName}
											/>
										</div>

										<div className="col-md-6">
											<InputField
												name="lastName"
												label="Last Name*"
												placeholder="Last Name"
												value={lastName}
												onChange={onChangeInput}
												error={errors.lastName}
												tips={tips.contactInformation.contact.name}
											/>
										</div>

										<div className="col-md-12">
											<InputField
												name="middleName"
												label="Middle Name"
												placeholder="Middle Name"
												value={middleName}
												onChange={onChangeInput}
												error={errors.middleName}
											/>
										</div>

										<div className="col-md-12">
											<div className="form-group">
												<label>Area of Expertise*</label>
												<select
													name="resumeFunction"
													className="form-control"
													id=""
													value={resumeFunction}
													onChange={onChangeInput}
												>
													<option value="">Select Area of Expertise</option>
													{[...getJobFunctions()].map((item, index) => {
														return (
															<option key={index} value={item}>
																{item}
															</option>
														);
													})}
												</select>
												{errors.resumeFunction !== "" && <p className="error">{errors.resumeFunction}</p>}
											</div>
										</div>

										<div className="col-md-12">
											<InputField
												name="resumeTitle"
												label="Professional Title*"
												placeholder="eg: English Teacher, Bank Teller"
												value={resumeTitle}
												onChange={onChangeInput}
												error={errors.resumeTitle}
											/>
										</div>

										<div className="col-md-12">
											<InputField
												name="email"
												label="Email*"
												disableCopyPaste={true}
												placeholder="Email"
												tips={tips.contactInformation.email}
												value={email}
												onChange={onChangeInput}
												error={errors.email}
											/>
										</div>

										<div className="col-md-12">
											<InputField
												name="phoneNo"
												label="Phone Number*"
												placeholder="Phone Number"
												value={phoneNo}
												disableCopyPaste={true}
												onChange={onChangeInput}
												error={errors.phoneNo}
											/>
										</div>

										<div className="col-md-6">
											<InputField
												name="country"
												label="Country*"
												placeholder="Country"
												value={country}
												disabled={true}
												onChange={onChangeInput}
												error={errors.country}
											/>
										</div>

										<div className="col-md-6 col-lg-6">
											{!(country === "Tanzania") && (
												<InputField
													type="text"
													label="Ex: City, Region"
													name="address"
													value={address}
													onChange={onChangeInput}
													placeholder="Enter Address "
													error={errors.address}
													tips={tips.contactInformation.address}
												/>
											)}
											{country === "Tanzania" && (
												<AutoSuggestion
													suggestions={locations}
													label="Eg: District, Region"
													type="text"
													name="address"
													userInput={address}
													setUserInput={setAddress}
													isForm={true}
													error={errors.address}
													tips={tips.contactInformation.address}
												/>
											)}
										</div>

										<div className="col-md-12">
											<label
												className="form-check d-flex align-items-center p-0 mb-3"
												htmlFor="legalNameToSAcknowledged"
											>
												<input
													type="checkbox"
													ref={checkboxRef}
													name="legalNameToSAcknowledged"
													value={legalNameToSAcknowledged}
													checked={legalNameToSAcknowledged}
													onChange={(e) => {
														setResumeData((prev) => {
															return {
																...prev,
																contactInfo: {
																	...prev.contactInfo,
																	legalNameToSAcknowledged: e.target.checked,
																},
															};
														});
													}}
												/>
												<span
													className="checkmark"
													onClick={() => {
														checkboxRef.current.click();
													}}
												/>
												<div className="ml-4 pl-2">
													<p className="fs-12 m-0 text-black">
														I confirm that I have entered my personal information correctly (as it appears of my
														educational documents.
													</p>
												</div>
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="d-flex justify-content-end">
								<button onClick={() => addContactInfo()} className="btn btn-primary">
									{loading ? (
										<>
											Loading <Spinner />
										</>
									) : (
										`${nextTxt}`
									)}
								</button>
							</div>
						</form>
					</div>

					{/* Resume Preview */}
					<div className="hide-on-mobile col-md-4 bg-light contact-resume-preview">
						<ResumePreview resumeData={resumeData} />
					</div>
					{/* Resume Preview */}
				</div>
			</div>
		</>
	);
};

export default ResumeContactInfo;
