import React from "react";

import Icon from "../Icon";

function AddNotes({ data, addNotesLabel, addNotesPlaceholder, addNotesActionButtons }) {
	return (
		<div
			className="d-flex flex-column align-items-start p-0"
			style={{ gap: 16, width: data?.candidateInfo ? "100%" : "75%" }}
		>
			<div className="d-flex flex-column align-items-start p-0" style={{ gap: "4px", width: "100%" }}>
				{addNotesLabel && (
					<div className="expert-review-orders-header-title" style={{ color: "#3E424E" }}>
						{addNotesLabel}
					</div>
				)}
				<div
					className="d-flex align-items-center"
					style={{
						padding: "8px 8px 8px 12px",
						gap: "12px",
						border: "1px solid #D7DAE0",
						borderRadius: "6px",
						width: "100%",
					}}
				>
					<textarea
						type="text"
						placeholder={addNotesPlaceholder}
						style={{ border: "none", width: data?.candidateInfo ? "100%" : "75%" }}
					/>
				</div>
			</div>
			<div className="d-flex align-items-center flex-wrap p-0" style={{ gap: "8px" }}>
				{addNotesActionButtons &&
					addNotesActionButtons.map((item, index) => {
						if (item?.type === "iconButton") {
							return (
								<div
									key={index}
									className="d-flex flex-column align-items-center justify-content-center p-0"
									style={{
										gap: "8px",
										width: "40px",
										height: "40px",
										border: "1px solid #EDEEF1",
										borderRadius: "6px",
									}}
								>
									<i className="bi bi-eye cursor-pointer" style={{ fontSize: "20px" }} />
								</div>
							);
						}
						return (
							<div
								key={index}
								className="d-flex align-items-center justify-content-center cursor-pointer p-0"
								style={{
									gap: "8px",
									border: "1px solid #EDEEF1",
									borderRadius: "6px",
									width: item?.width || 100,
									height: "41px",
									background: item?.background || "white",
								}}
							>
								{item?.icon && <Icon iconName={item?.icon} size={20} />}
								{item?.text && (
									<div className="mark-review-complete-text-button" style={{ color: item?.color || "black" }}>
										{item?.text}
									</div>
								)}
							</div>
						);
					})}
			</div>
		</div>
	);
}

export default AddNotes;
