import React from "react";

import Header from "../../StaticPages/Header";
import PaymentFlow from "./PaymentFlow";

function Payments() {
	return (
		<div
			style={{
				backgroundColor: "red",
				margin: "0 10%",
				padding: "0px",
				"@media (maxWidth: 768px)": {
					width: "100%",
					margin: "0 10%",
				},
			}}
		>
			<Header activeLink="plans" />
			{/* <div className="container-lg first-div my-resumes-section">
		</div> */}
			<PaymentFlow />
		</div>
	);
}

export default Payments;
