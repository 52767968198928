import React from "react";

function MarkReviewCompleteModal({ resumeData, setShowMarkCompleteButton }) {
	const [copyLinkText, setCopyLinkText] = React.useState("Copy link");
	const linkToShare = "https://dproz.com/signin";
	const shareString = `Hello ${resumeData.contactInfo.firstName}, see the reviews of your resume by click this link ${linkToShare}`;
	const email = resumeData.contactInfo.email;
	const subject = encodeURIComponent("Resume Feedback Reviews");
	const body = encodeURIComponent(shareString);
	const mailtoUrl = `mailto:${email}?subject=${subject}&body=${body}`;

	return (
		<>
			<div
				className="modal fade"
				id="markReviewCompleteModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="markReviewComplete"
				aria-hidden="true"
				data-backdrop="static"
				data-keyboard="false"
			>
				<div className="modal-dialog modal-dialog-centered mark-review-complete-modal" role="document">
					<div className="modal-content" style={{ padding: "20px 20px 24px" }}>
						<div>
							<div
								className="fa fa-close cursor-pointer close ml-auto"
								data-dismiss="modal"
								onClick={() => setShowMarkCompleteButton(true)}
							/>
							<div className="resume-feedback-modal-title">Thank you for your feedback.</div>
							<div className="resume-feedback-modal-sub-title">
								{resumeData.contactInfo.firstName} greatly appreciates your time for reviewing their resume.
							</div>
							<div className="mark-review-complete-modal-wrapper">
								<div className="mark-review-complete-modal-container">
									<div>
										<div className="grouped-skill-text-title">
											Who else in your network could benefit from your feedback?
										</div>
										<div className="top-text-on-skill-section" style={{ color: "#000000" }}>
											Send a message to people in your network.
										</div>
									</div>
									<div
										className="d-flex align-items-center justify-content-center"
										style={{ gap: "10px", width: "100%" }}
									>
										<a
											href={`https://wa.me/?text=${shareString}`}
											target="_blank"
											rel="noopener noreferrer"
											className="social-media-container"
										>
											<img src="/images/whatsapp.png" alt="Whatsapp" width={32} height={32} />
											<div className="mark-review-complete-text-button" style={{ color: "#30313d" }}>
												Share on WhatsApp
											</div>
										</a>
										<a
											href={`https://www.linkedin.com/sharing/share-offsite/?url=${linkToShare}`}
											target="_blank"
											rel="noopener noreferrer"
											className="social-media-container"
										>
											<img src="/images/linkedin.png" alt="Linkedin" width={32} height={32} />
											<div className="mark-review-complete-text-button" style={{ color: "#30313d" }}>
												Post on LinkedIn
											</div>
										</a>
										<a href={mailtoUrl} className="social-media-container">
											<img src="/images/email.png" alt="Email" width={32} height={32} />
											<div className="mark-review-complete-text-button" style={{ color: "#30313d" }}>
												Send Email
											</div>
										</a>
									</div>
									<div
										className="grouped-skill-text-title"
										style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6)", lineHeight: "20px" }}
									>
										OR
									</div>
									<div className="copy-link-container">
										<div
											className="mark-review-complete-text-button mr-auto"
											style={{ color: "#30313d", lineHeight: "20px" }}
										>
											{linkToShare}
										</div>
										<div className="copy-link-button">
											<div
												className="mark-review-complete-text-button"
												style={{ color: "#ffffff", lineHeight: "20px" }}
												onClick={() => {
													navigator.clipboard
														.writeText(linkToShare)
														.then(() => {
															setCopyLinkText("Copied");
															setTimeout(() => {
																setCopyLinkText("Copy link");
															}, 1500);
														})
														.catch(() => {});
												}}
											>
												{copyLinkText}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default MarkReviewCompleteModal;
