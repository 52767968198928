import React from "react";

import Header from "../../../components/Header";
import JobItem from "../../../components/Jobs/JobItem";
import jobData from "../../../seed/jobs";

function JobListing() {
	const jobs = jobData();

	const JobItemInfo = jobs.map((job, i) => (
		<div className="col-lg-6" key={i}>
			<JobItem key={job.id} job={job} />
		</div>
	));

	return (
		<>
			<Header />

			<div className="jobs-listing section-padding bg-transparent ">
				<div className="container">
					<div className="job-topbar">
						<div className="row align-items-center">
							<div className="col-md-3 col-6  order-2 order-md-1">
								<span>Filter jobs by </span>
							</div>
							<div className="col-md-6 col-12 order-1 order-md-2">
								<div className="input-group">
									<input type="text" className="form-control h-auto bg-white" placeholder="Search for Job, Company.." />
									<div className="input-group-append">
										<button className="btn btn-primary rounded-0" type="submit">
											<span className="fa fa-search" />
										</button>
									</div>
								</div>
							</div>
							<div className="col-md-3 col-6  order-3 order-md-3 text-right">
								<span>235 Total jobs </span>
							</div>
						</div>
					</div>
					<div className="list-menu listing-filters text-center clearfix">
						<ul className="tr-list">
							<li className="dropdown tr-change-dropdown d-inline-block">
								Category:
								<a data-toggle="dropdown" href="#" aria-expanded="false">
									<span className="change-text">Designer</span>
									<i className="fa fa-angle-down" />
								</a>
								<ul className="dropdown-menu tr-change">
									<li>
										<a href="#">Designer</a>
									</li>
									<li>
										<a href="#">Marketing</a>
									</li>
									<li>
										<a href="#">Developement</a>
									</li>
								</ul>
							</li>
							<li className="dropdown tr-change-dropdown d-inline-block">
								Level:
								<a data-toggle="dropdown" href="#" aria-expanded="false">
									<span className="change-text">Mid</span>
									<i className="fa fa-angle-down" />
								</a>
								<ul className="dropdown-menu tr-change">
									<li>
										<a href="#">Top Level</a>
									</li>
									<li>
										<a href="#">Mid Level</a>
									</li>
									<li>
										<a href="#">Entry Level</a>
									</li>
								</ul>
							</li>
							<li className="dropdown tr-change-dropdown d-inline-block">
								Org Type:
								<a data-toggle="dropdown" href="#" aria-expanded="false">
									<span className="change-text">Private</span>
									<i className="fa fa-angle-down" />
								</a>
								<ul className="dropdown-menu tr-change">
									<li>
										<a href="#">Private</a>
									</li>
									<li>
										<a href="#">public</a>
									</li>
								</ul>
							</li>
							<li className="dropdown tr-change-dropdown d-inline-block">
								Location:
								<a data-toggle="dropdown" href="#" aria-expanded="false">
									<span className="change-text">USA</span>
									<i className="fa fa-angle-down" />
								</a>
								<ul className="dropdown-menu tr-change">
									<li>
										<a href="#">USA</a>
									</li>
									<li>
										<a href="#">London</a>
									</li>
									<li>
										<a href="#">New York</a>
									</li>
								</ul>
							</li>
							<li className="dropdown tr-change-dropdown d-inline-block">
								Salary:
								<a data-toggle="dropdown" href="#" aria-expanded="false">
									<span className="change-text">0 - 50K</span>
									<i className="fa fa-angle-down" />
								</a>
								<ul className="dropdown-menu tr-change">
									<li>
										<a href="#">0 - 50K</a>
									</li>
									<li>
										<a href="#">50k - 70K</a>
									</li>
									<li>
										<a href="#">70k - 85K</a>
									</li>
								</ul>
							</li>
						</ul>
					</div>
					{/*/.list-menu*/}

					<div className="tab-content tr-job-posted">
						<div className="row ">{JobItemInfo}</div>
						{/*/.row*/}

						{/*pagination*/}
						<nav className="py-3">
							<ul className="pagination">
								<li className="page-item">
									<a className="page-link" href="#">
										Previous
									</a>
								</li>
								<li className="page-item active">
									<a className="page-link" href="#">
										1
									</a>
								</li>
								<li className="page-item">
									<a className="page-link" href="#">
										2
									</a>
								</li>
								<li className="page-item">
									<a className="page-link" href="#">
										3
									</a>
								</li>
								<li className="page-item">
									<a className="page-link" href="#">
										Next
									</a>
								</li>
							</ul>
						</nav>
						{/*pagination ends*/}
					</div>
					{/*/.tab-content*/}
				</div>
				{/*/.container*/}
			</div>
			{/*/.jobs-listing*/}
		</>
	);
}

export default JobListing;
