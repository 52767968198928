const initialState = { interactionStatus: "", resumeReferenceId: "" };

export default function JobsInteractionReducers(interactionState = initialState, action) {
	switch (action.type) {
		case "UPDATE_INTERACTIONS":
			return { ...action.payload };

		default:
			return interactionState;
	}
}
