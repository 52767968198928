import React from "react";

import { getSanitizedHtml } from "../../../handlers/utilityFunctions";

function SelectField({ onChange, value, id, disabled, name, error, label, options, onFocus = () => {} }) {
	const selectOptions = options.map((option, index) => (
		<option value={option.value} key={index}>
			{" "}
			{option.name}{" "}
		</option>
	));

	return (
		<div className="">
			<div className="form-group">
				<label className="mb-1">{label}</label>
				<select
					className="form-control"
					name={name}
					id={id}
					value={value}
					onChange={onChange}
					onFocus={onFocus}
					disabled={disabled}
				>
					{selectOptions}
				</select>
			</div>
			{error && <div className="error">{getSanitizedHtml(error)}</div>}
		</div>
	);
}

export default SelectField;
