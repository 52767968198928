import React from "react";

import FeedBackModal from "../../../../components/Reviews/FeedBackModal";
import { sections } from "../../../../handlers/enums";
import { convertDateToWords, removeHTTPAndDomainName } from "../../../../handlers/utilityFunctions";
import useReviewFeedBack from "../../../../hooks/use-ReviewFeedBack";

function ResumeOne({
	isReview,
	reviewData,
	resumeData,
	hasFeedback,
	activeSection,
	onSubmitFeedBack,
	loadingSubmission,
}) {
	const { useReviewFeedback, feedBackButton } = useReviewFeedBack(reviewData);

	return (
		<div className="bg-white final-resume-preview-wrapper shadow-sm">
			<div className="final-resume-preview bg-white mt-2 fresher-resume text-dark">
				<div className="resume-content ">
					<div className="top pt-2 pt-md-4 px-2 px-2 px-md-4 text-center ">
						<h1
							className="text-uppercase"
							style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
						>
							{resumeData.contactInfo.firstName} {resumeData.contactInfo.middleName} {resumeData.contactInfo.lastName}
						</h1>
						<address>
							{resumeData.contactInfo.city}, {resumeData.contactInfo.country}{" "}
						</address>
						<div className="bottom px-4">
							<div
								className={`review-section position-relative ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.HEADING
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.HEADING}
							>
								<ul className="resume-user-quick-info border-bottom pb-2 d-flex list-unstyled justify-content-center d-flex align-items-center mt-3">
									<li className="mr-2 mr-md-3 text-nowrap">
										<span className="span text-primary mr-1 mr-md-2 fa fa-phone" />
										{resumeData.contactInfo.phoneNo}
									</li>
									<li className="mr-2 mr-md-3 text-nowrap">
										<span className="span text-primary mr-1 mr-md-2 fa fa-envelope" />
										{resumeData.contactInfo.email}
									</li>
									{[...resumeData.extras.personalLinks].map((link, index) => {
										return link.type === "linkedIn" ? (
											<li className="mr-2 mr-md-3 text-nowrap" key={index}>
												<span className="span text-primary mr-1 mr-md-2 bi bi-linkedin" />
												<a href={link.value} target="_blank" rel="noreferrer" className="text-dark">
													{removeHTTPAndDomainName(link)}
												</a>
											</li>
										) : null;
									})}
									{[...resumeData.extras.personalLinks].map((link, index) => {
										return link.type === "other" ? (
											<li className="mr-2 mr-md-3 text-nowrap" key={index}>
												<span className="span text-primary mr-1 mr-md-2 fa fa-globe" />
												{link.value}
											</li>
										) : null;
									})}
								</ul>
								{isReview || activeSection === sections.HEADING ? feedBackButton(sections.HEADING, hasFeedback) : null}
							</div>
						</div>
					</div>
					<div className="bottom  px-2 px-2 px-md-4">
						{resumeData.summary.length > 0 && (
							<div
								className={`content-box position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.SUMMARY
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.SUMMARY}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.SUMMARY ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								<h3 className="py-2 bg-secondary bg-grey my-2 px-2 text-uppercase text-center">Summary</h3>
								{isReview || activeSection === sections.SUMMARY ? feedBackButton(sections.SUMMARY, hasFeedback) : null}
								<span
									dangerouslySetInnerHTML={{
										__html: resumeData.summary,
									}}
								/>
							</div>
						)}

						{resumeData.skills !== "" ? (
							<div
								className={`content-box skills position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.SKILLS
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.SKILLS}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.SKILLS ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								<h3 className="py-2 bg-secondary bg-grey my-2 px-2 text-uppercase text-center">SKILLS</h3>
								{isReview || activeSection === sections.SKILLS ? feedBackButton(sections.SKILLS, hasFeedback) : null}
								<div
									dangerouslySetInnerHTML={{
										__html: resumeData.skills,
									}}
								/>
							</div>
						) : null}

						{resumeData.extras.certifications.length > 0 ? (
							<div
								className={`content-box position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.CERTIFICATION
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.CERTIFICATION}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.CERTIFICATION ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								<h3 className="py-2 bg-secondary bg-grey my-2 px-2 text-uppercase text-center">CERTIFICATIONS</h3>
								{isReview || activeSection === sections.CERTIFICATION
									? feedBackButton(sections.CERTIFICATION, hasFeedback)
									: null}
								<div
									className="pl-0"
									dangerouslySetInnerHTML={{
										__html: resumeData.extras.certifications,
									}}
								/>
							</div>
						) : null}

						{resumeData.extras.accomplishments !== "" ? (
							<div
								className={`content-box position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.ACCOMPLISHMENTS
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.ACCOMPLISHMENTS}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.ACCOMPLISHMENTS ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								<h3 className="py-2 bg-secondary bg-grey my-2 px-2 text-uppercase text-center">ACCOMPLISHMENTS</h3>
								{isReview || activeSection === sections.ACCOMPLISHMENTS
									? feedBackButton(sections.ACCOMPLISHMENTS, hasFeedback)
									: null}
								<div
									dangerouslySetInnerHTML={{
										__html: resumeData.extras.accomplishments,
									}}
								/>
							</div>
						) : null}

						{resumeData.workHistory.length > 0 && resumeData.workHistory[0].jobTitle !== "" ? (
							<div
								className={`content-box position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.EXPERIENCE
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.EXPERIENCE}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.EXPERIENCE ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								<h3 className="py-2 bg-secondary bg-grey my-2 px-2 text-uppercase text-center">Work History</h3>
								{isReview || activeSection === sections.EXPERIENCE
									? feedBackButton(sections.EXPERIENCE, hasFeedback)
									: null}
								<div>
									{[...resumeData.workHistory].map((job, index) => (
										<div key={index} className="pl-0  mb-3">
											<div className="d-flex justify-content-between">
												<p className="mb-2">
													<strong>{job.employer}</strong> | <em>{job.jobTitle}</em> | {job.city}, {job.region},{" "}
													{job.country}
												</p>
												<p className="mb-0">
													<strong>
														{convertDateToWords(job.startDate)} -{" "}
														{job.endDate !== "" ? convertDateToWords(job.endDate) : "Present"}
													</strong>
												</p>
											</div>

											<div
												dangerouslySetInnerHTML={{
													__html: job.details,
												}}
											/>
										</div>
									))}
								</div>
							</div>
						) : null}

						{resumeData.education.length > 0 && (
							<div
								className={`content-box position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.EDUCATION
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.EDUCATION}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.EDUCATION ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								<h3 className="py-2 bg-secondary bg-grey my-2 px-2 text-uppercase text-center">Education</h3>
								{isReview || activeSection === sections.EDUCATION
									? feedBackButton(sections.EDUCATION, hasFeedback)
									: null}
								<div className="pl-0">
									{[...resumeData.education].map((educationHistory, index) => (
										<div key={index}>
											<div className="d-flex justify-content-between">
												<p>
													<strong>{educationHistory.schoolName}</strong>
													{" - "}
													{educationHistory.schoolCity}, <em>{educationHistory.fieldOfStudy}</em>
												</p>
												<p className="mr-4">
													<strong>{educationHistory.gYear}</strong>
												</p>
											</div>
											<div
												dangerouslySetInnerHTML={{
													__html: educationHistory.description,
												}}
											/>
										</div>
									))}
								</div>
							</div>
						)}

						{resumeData.extras.languages.length !== 0 ? (
							<div
								className={`content-box position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.LANGUAGES
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.LANGUAGES}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.LANGUAGES ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								<h3 className="py-2 bg-secondary bg-grey my-2 px-2 text-uppercase text-center">Languages</h3>
								{isReview || activeSection === sections.LANGUAGES
									? feedBackButton(sections.LANGUAGES, hasFeedback)
									: null}
								<ul className="pl-3">
									{resumeData.extras.languages.map((language, index) => (
										<li key={index}>
											<span className="mr-2">{language.language}</span>
											{" - "}
											<span className="mr-2">{language.proficiency}</span>
										</li>
									))}
								</ul>
							</div>
						) : null}

						{resumeData.extras.personalProfile.dateOfBirth && (
							<div
								className={`content-box position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.PERSONAL
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.PERSONAL}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.PERSONAL ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								<h3 className="py-2 bg-secondary bg-grey my-2 px-2 text-uppercase text-center">Personal Information</h3>
								{isReview || activeSection === sections.PERSONAL
									? feedBackButton(sections.PERSONAL, hasFeedback)
									: null}
								<div>
									<div>
										<strong>Date of Birth:</strong> {resumeData.extras.personalProfile.dateOfBirth}
									</div>
									<div>
										<strong>Gender:</strong> {resumeData.extras.personalProfile.gender}
									</div>
									<div>
										<strong>Marital Status:</strong> {resumeData.extras.personalProfile.maritalStatus}
									</div>
								</div>
							</div>
						)}

						{resumeData.extras.affiliations !== "" ? (
							<div
								className={`content-box position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.AFFILIATION
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.AFFILIATION}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.AFFILIATION ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								<h3 className="py-2 bg-secondary bg-grey my-2 px-2 text-uppercase text-center">AFFILIATIONS</h3>
								{isReview || activeSection === sections.AFFILIATION
									? feedBackButton(sections.AFFILIATION, hasFeedback)
									: null}
								<div
									dangerouslySetInnerHTML={{
										__html: resumeData.extras.affiliations,
									}}
								/>
							</div>
						) : null}

						{resumeData.extras.additionalInfo !== "" ? (
							<div
								className={`content-box position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.ADDITIONAL_INFO
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.ADDITIONAL_INFO}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.ADDITIONAL_INFO ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								<h3 className="py-2 bg-secondary bg-grey my-2 px-2 text-uppercase text-center">
									ADDITONAL INFORMATION
								</h3>
								{isReview || activeSection === sections.ADDITIONAL_INFO
									? feedBackButton(sections.ADDITIONAL_INFO, hasFeedback)
									: null}
								<div
									dangerouslySetInnerHTML={{
										__html: resumeData.extras.additionalInfo,
									}}
								/>
							</div>
						) : null}

						{[...resumeData.extras.personalLinks].length > 0 && (
							<div
								className={`content-box position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.LINKS
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.LINKS}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.LINKS ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								<h3 className="py-2 bg-secondary bg-grey my-2 px-2 text-uppercase text-center">
									website, portfolio, profiles
								</h3>
								{isReview || activeSection === sections.LINKS ? feedBackButton(sections.LINKS, hasFeedback) : null}
								<ul className="pl-3">
									{[...resumeData.extras.personalLinks].map((link, index) => (
										<li key={index}>
											<span className="text-capitalize">{link.type}</span>: {link.value}
										</li>
									))}
								</ul>
							</div>
						)}

						{resumeData.extras.references.length > 0 && (
							<div
								className={`content-box position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.REFERENCE
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.REFERENCE}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.REFERENCE ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								<h3 className="py-2 bg-secondary bg-grey my-2 px-2 text-uppercase text-center">References</h3>
								{isReview || activeSection === sections.REFERENCE
									? feedBackButton(sections.REFERENCE, hasFeedback)
									: null}
								<ul>
									{[...resumeData.extras.references].map((reference, index) => (
										<li className="" key={index}>
											<span>
												{reference.name}
												{" - "}
												{reference.designation}, {reference.workPlace}
											</span>
											<br />
											<span>
												{reference.email}, {reference.phoneNumber}
											</span>
										</li>
									))}
								</ul>
							</div>
						)}

						{resumeData.extras.customExtras.name.length > 0 ? (
							<div
								className={`content-box position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.CUSTOM
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.CUSTOM}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.CUSTOM ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								<h3 className="py-2 bg-secondary bg-grey my-2 px-2 text-uppercase text-center">
									{resumeData.extras.customExtras.name}
								</h3>
								{isReview || activeSection === sections.PERSONAL
									? feedBackButton(sections.PERSONAL, hasFeedback)
									: null}
								<div
									dangerouslySetInnerHTML={{
										__html: resumeData.extras.customExtras.data,
									}}
								/>
							</div>
						) : null}
					</div>
				</div>
			</div>
			{useReviewFeedback.showFeedBackModal && (
				<FeedBackModal
					resumeData={resumeData}
					details={useReviewFeedback.section}
					onSubmitFeedBack={onSubmitFeedBack}
					loadingSubmission={loadingSubmission}
				/>
			)}
		</div>
	);
}

export default ResumeOne;
