const initalState = {
	linkedIn: {
		url: "",
		isFilled: true,
	},
	twitter: {
		url: "erererer",
		isFilled: false,
	},
	github: {
		url: "",
		isFilled: false,
	},
	other: {
		url: "",
		isFilled: false,
	},
};

export default function PersonalLinkReducers(personalLinks = initalState, action) {
	switch (action.type) {
		case "AMEND_LINKS":
			return action.payload;

		default:
			return personalLinks;
	}
}
