import React from "react";

function TemplatePremiumSeven() {
	return (
		<div style={{ scale: "1.2", transformOrigin: "center" }}>
			<div className="small-resume-preview small-resume-preview-premium-template-5 small-resume-preview-premium-template-7">
				<div className="final-resume-preview bg-white resume-type-1 text-dark py-2">
					<div className="container">
						<div className="mb-2">
							<div className="info-wrapper px-3">
								<div>
									<h1 className="mb-1">JACQUILINE KHALFAN</h1>
									<h4 className="mb-2" style={{ color: "#48969b" }}>
										Executive Assistant
									</h4>
								</div>
							</div>
							<div>
								<p
									className="mb-0 p-2 text-white"
									style={{ backgroundColor: "#313c4e", borderTopRightRadius: "5px", borderTopLeftRadius: "5px" }}
								>
									Professional Business Developer with four year of experience in the business Development processes.
									Involved in product testing, management and development of new business opportunities.
								</p>
							</div>
							<div
								className="p-2 mb-3 d-flex align-items-center justify-content-between text-white flex-wrap"
								style={{ backgroundColor: "#449399", borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px" }}
							>
								<p className="mb-1">
									<i className="contact-icon mr-1 mr-md-2 fa fa-envelope" aria-hidden="true" />
									jacquiline.khalfan@dproz.tz
								</p>
								<p className="mb-1 contact-icon">
									<i className="mr-md-2 mr-1 fa fa-mobile" aria-hidden="true" />
									202-555-0166
								</p>
								<p className="mb-1 contact-icon">
									<i className="mr-md-2 mr-1 fa fa-map-marker" aria-hidden="true" />
									New York, USA
								</p>
								<p className="mb-1 contact-icon">
									<i className="mr-md-2 mr-1 fa fa-linkedin" aria-hidden="true" />
									linkedin.com/in/jacquiline.khalfan
								</p>
							</div>
						</div>
						<div className="mb-2  px-3 ">
							<div className="title-heading d-flex align-items-center mb-2">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 20px",
										height: "20px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "10px",
									}}
								>
									<i className="fa fa-cogs" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center mb-0 text-uppercase font-weight-bold mr-3 text-nowrap">
									skills and competencies
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "2px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 70%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>

							<ul className="d-flex align-items-center flex-wrap text-white pl-0 mb-0" style={{ listStyle: "none" }}>
								<li className="mb-2 px-1 py-1 rounded mr-1 skill-tag" style={{ backgroundColor: "#989da6" }}>
									SEO
								</li>
								<li className="mb-2 px-1 py-1 rounded mr-1 skill-tag" style={{ backgroundColor: "#989da6" }}>
									Negotiation
								</li>
								<li className="mb-2 px-1 py-1 rounded mr-1 skill-tag" style={{ backgroundColor: "#989da6" }}>
									Decission Making
								</li>
								<li className="mb-2 px-1 py-1 rounded mr-1 skill-tag" style={{ backgroundColor: "#989da6" }}>
									Emotional Intelligence
								</li>
								<li className="mb-2 px-1 py-1 rounded mr-1 skill-tag" style={{ backgroundColor: "#989da6" }}>
									Email Marketing
								</li>
								<li className="mb-2 px-1 py-1 rounded mr-1 skill-tag" style={{ backgroundColor: "#989da6" }}>
									Sales &amp; Marketing
								</li>
								<li className="mb-2 px-1 py-1 rounded mr-1 skill-tag" style={{ backgroundColor: "#989da6" }}>
									Public Speaking
								</li>
								<li className="mb-2 px-1 py-1 rounded mr-1 skill-tag" style={{ backgroundColor: "#989da6" }}>
									Teamwork
								</li>
								<li className="mb-2 px-1 py-1 rounded mr-1 skill-tag" style={{ backgroundColor: "#989da6" }}>
									Research and Strategy
								</li>
								<li className="mb-2 px-1 py-1 rounded mr-1 skill-tag" style={{ backgroundColor: "#989da6" }}>
									Outbound Marketingn
								</li>
								<li className="mb-2 px-1 py-1 rounded mr-1 skill-tag" style={{ backgroundColor: "#989da6" }}>
									Google Analytics
								</li>
								<li className="mb-2 px-1 py-1 rounded mr-1 skill-tag" style={{ backgroundColor: "#989da6" }}>
									Social Media Advertising
								</li>
							</ul>
						</div>

						<div className="mb-2 px-3">
							<div className="title-heading d-flex align-items-center mb-2">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 20px",
										height: "20px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "10px",
									}}
								>
									<i className="fa fa-briefcase" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									WORK EXPERIENCE{" "}
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "2px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 70%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>
							<ul className="pl-0 mb-0 pb-2" style={{ listStyle: "none" }}>
								<li className="position-relative">
									<h4 className="font-weight-bold mb-1 mt-2">Business Development Manager</h4>
									<h5 className="mb-1" style={{ fontWeight: 500 }}>
										AirState Solutions
									</h5>
									<h6
										className="d-flex align-items-center justify-content-between font-italic"
										style={{ color: "#4c4c4c", fontWeight: 500 }}
									>
										<p className="mb-0" style={{ color: "#449399" }}>
											09/2014 - Present
										</p>
									</h6>
									<ul className="px-3 list" style={{ listStyle: "disc" }}>
										<li className="mb-1">
											Successfully managed $2 - 3 million budget projects and successfully achieved the project
											scheduled goals.
										</li>
										<li className="mb-1">
											Developed and implemented new marketing and sales plans and defined the strategy for the next 5
											years.
										</li>
										<li className="mb-1">
											Reviewed constantly the customer feedback and then suggested ways to improve the processes and
											customer service levels which increased the satisfaction rate from 81% to 95%.
										</li>
										<li className="mb-1">
											Ensured that new clients will grow into a loyal customer base in a specialist niche market by
											implementing a new loyalty program.
										</li>
									</ul>
								</li>
							</ul>
						</div>

						<div className="mb-2 px-3">
							<div className="title-heading d-flex align-items-center mb-2">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 20px",
										height: "20px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "10px",
									}}
								>
									<i className="fa fa-graduation-cap" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									EDUCATION
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "2px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>
							<div className="row education-list">
								<div className="first col-6 pr-3">
									<h5 className="font-weight-bold mt-2 mb-0">MSc in Economics and Business Administration</h5>
									<h6 className="font-weight-500 mb-0" style={{ fontWeight: 500 }}>
										The University of Chicago
									</h6>
									<p className="font-italic" style={{ color: "#449399", fontWeight: 500 }}>
										09/2008 - 06/2010
									</p>
								</div>
								<div className="col-6 pl-3">
									<h5 className="font-weight-bold mt-2 mb-0">MSc in Economics and Business Administration</h5>
									<h6 className="font-weight-500 mb-0" style={{ fontWeight: 500 }}>
										The University of Chicago
									</h6>
									<p className="font-italic" style={{ color: "#449399", fontWeight: 500 }}>
										09/2008 - 06/2010
									</p>
								</div>
							</div>
						</div>

						<div className="mb-2 px-3">
							<div className="title-heading d-flex align-items-center mb-2">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 20px",
										height: "20px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "10px",
									}}
								>
									<i className="fa fa-leanpub" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									CONFERENCES &amp; COURSES
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "2px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>

							<div className="row">
								<div className="col-6">
									<h5 className="mb-0">Leading People and Teams Specialization (03/2016 - 10/2016)</h5>
									<h6 className="font-italic" style={{ color: "#8a8a8a" }}>
										Online course on Coursera.org
									</h6>
									<h5 className="mb-0">eMetrics Summit (09/2016)</h5>
									<h6 className="font-italic" style={{ color: "#8a8a8a" }}>
										Rising Media, Inc. and eMetrics Summit
									</h6>
								</div>
								<div className="col-6">
									<h5 className="mb-0">Project Management Principles and Practices (01/2015 - 09/2015)</h5>
									<h6 className="font-italic" style={{ color: "#8a8a8a" }}>
										Online course on Coursera.org
									</h6>
								</div>
							</div>
						</div>

						<div className="mb-2 px-3">
							<div className="title-heading d-flex align-items-center mb-2">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 20px",
										height: "20px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "10px",
									}}
								>
									<i className="fa fa-cog" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									Technical skills
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "2px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>

							<ul className="mt-0 mb-0 p-0 text-white">
								<li className="rounded px-1 mr-1 py-1 d-inline-block mb-1" style={{ backgroundColor: "#989da6" }}>
									Artificial Intelligence
								</li>
								<li className="rounded px-1 mr-1 py-1 d-inline-block mb-1" style={{ backgroundColor: "#989da6" }}>
									Economics
								</li>
								<li className="rounded px-1 mr-1 py-1 d-inline-block mb-1" style={{ backgroundColor: "#989da6" }}>
									History
								</li>
								<li className="rounded px-1 mr-1 py-1 d-inline-block mb-1" style={{ backgroundColor: "#989da6" }}>
									Neuromarketing
								</li>
								<li className="rounded px-1 mr-1 py-1 d-inline-block mb-1" style={{ backgroundColor: "#989da6" }}>
									Consumer
								</li>
								<li className="rounded px-1 mr-1 py-1 d-inline-block mb-2" style={{ backgroundColor: "#989da6" }}>
									Behaviour
								</li>
							</ul>
						</div>

						<div className="mb-2  px-3">
							<div className="title-heading d-flex mb-2 align-items-center">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 20px",
										height: "20px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "10px",
									}}
								>
									<i className="fa fa-users" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									ORGANIZATIONS{" "}
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "2px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>

							<div className="row" style={{ fontWeight: 500 }}>
								<div className="col-6">
									<h6 className="mb-0">American Management Association</h6>
									<h6 className="mb-1">(2015 - Present)</h6>
									<h6 className="mb-0">Association of Private Enterprise Education</h6>
									<h6 className="mb-1">(2014 - Present)</h6>
								</div>
								<div className="col-6">
									<h6 className="mb-0">eBusiness Association (eBA)</h6>
									<h6 className="mb-1">(2013 - Present)</h6>
								</div>
							</div>
						</div>

						<div className="mb-2 px-3">
							<div className="title-heading d-flex align-items-center mb-2">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 20px",
										height: "20px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "10px",
									}}
								>
									<i className="fa fa-trophy" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									HONOURS AND AWARDS
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "2px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>
							<div className="row">
								<div className="col-6">
									<h5 className="mb-0">
										Jury Member, Entrepreneurship Competition (2016)
										<span>
											<i className="fa fa-share-square-o" aria-hidden="true" style={{ color: "#449399" }} />
										</span>
									</h5>
									<h6 className="font-italic " style={{ color: "#8a8a8a" }}>
										Venture Cup USA
									</h6>
									<h5 className="mb-0">
										Sales Business Development Award (2015)
										<span>
											<i className="fa fa-share-square-o" aria-hidden="true" style={{ color: "#449399" }} />
										</span>
									</h5>
									<h6 className="font-italic" style={{ color: "#8a8a8a" }}>
										AirState Business Awards
									</h6>
								</div>
								<div className="col-6">
									<h5 className="mb-0">
										Excellence in Customer Partnering Award (2017)
										<span>
											<i className="fa fa-share-square-o" aria-hidden="true" style={{ color: "#449399" }} />
										</span>
									</h5>
									<h6 className="font-italic" style={{ color: "#8a8a8a" }}>
										IES - Institute of Excellence in Sales
									</h6>
								</div>
							</div>
						</div>

						<div className="mb-2 px-3">
							<div className="title-heading d-flex align-items-center mb-2">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 20px",
										height: "20px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "10px",
									}}
								>
									<i className="fa fa-briefcase" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									LANGUAGES
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "2px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>
							<div className="row no-gutters">
								<div className="col px-1">
									<h5 className="mt-2 mb-0">
										English
										<p className="font-italic mb-0" style={{ color: "#364152", fontWeight: 500 }}>
											Limited Working Proficiency
										</p>
									</h5>
								</div>
								<div className="col px-1">
									<h5 className="mt-2 mb-0">
										Spanish
										<p className="font-italic mb-0" style={{ color: "#364152", fontWeight: 500 }}>
											Limited Working Proficiency
										</p>
									</h5>
								</div>
								<div className="col px-1">
									<h5 className="mt-2 mb-0">
										French
										<p className="font-italic" style={{ color: "#364152", fontWeight: 500 }}>
											Limited Working Proficiency
										</p>
									</h5>
								</div>
							</div>
						</div>

						<div className="mb-3 px-3">
							<div className="title-heading d-flex align-items-center">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 20px",
										height: "20px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "10px",
									}}
								>
									<i className="fa fa-briefcase" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase my-3 font-weight-bold mr-3 text-nowrap">
									interests{" "}
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "2px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>
							<div className="row no-gutters">
								<div className="col px-1">
									<h5 className="mt-2 mb-0">Meditation</h5>
								</div>
								<div className="col px-1">
									<h5 className="mt-2 mb-0">Sailing</h5>
								</div>
								<div className="col px-1">
									<h5 className="mt-2 mb-0">Winter Sports</h5>
								</div>
								<div className="col px-1">
									<h5 className="mt-2 mb-0">Sustainability</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="preview text-center mt-4 mb-4">
				<button
					className="btn text-primary btn-sm btn-outline-primary"
					data-toggle="modal"
					data-target="#ResumePreview"
				>
					<span className="fa fa-eye" /> Preview
				</button>
			</div>
		</div>
	);
}

export default TemplatePremiumSeven;
