import React, { useEffect, useState } from "react";

import CustomLink from "../../../../components/CustomLink";
import Header from "../JobMarketHeader/Header";
import Sidebar from "../JobMarketSideNavigation/Sidebar";

const JobNavLayout = ({ children, header, subHeader, headerUrl, iconSubHeader }) => {
	const storedIsCollapsed = localStorage.getItem("isCollapsed");
	const [isCollapsed, setIsCollapsed] = useState(storedIsCollapsed === "true");

	const [hideSidebar, setHideSidebar] = useState(false);
	useEffect(() => {
		if (window.innerWidth < 768) {
			setIsCollapsed(true);
			setHideSidebar(true);
		} else {
			setIsCollapsed(true);
		}
	}, []);

	useEffect(() => {
		localStorage.setItem("isCollapsed", isCollapsed);
	}, [isCollapsed]);

	useEffect(() => {
		if (window.innerWidth < 768) {
			setIsCollapsed(true);
		}
	}, [window.location.href]);

	const handleSidebarToggle = () => {
		if (window.innerWidth > 768) {
			setIsCollapsed(!isCollapsed);
			return;
		}
	};

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 768) {
				setIsCollapsed(true);
				setHideSidebar(true);
			} else {
				setIsCollapsed(false);
				setHideSidebar(false);
			}
		};

		handleResize();

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div className="main-page">
			<div
				className={` ${isCollapsed ? "nav-bar-collapsed" : "nav-bar"} ${
					hideSidebar ? "nav-bar-collapsed" : "nav-bar"
				}}`}
			>
				<Sidebar
					hideSidebar={hideSidebar}
					isCollapsed={isCollapsed}
					page={header}
					subPage={subHeader}
					onToggle={handleSidebarToggle}
				/>
			</div>
			{/* header */}
			<div
				className={`${isCollapsed ? "header-collapsed" : "header "} ${hideSidebar ? "header-collapsed" : "header "}}`}
			>
				<Header />
			</div>
			{/* header */}

			{/* TOP info bar */}
			<div
				style={{
					backgroundColor: "white",
					marginTop: "80px",
					marginBottom: "3px",
					width: isCollapsed || hideSidebar ? "calc(98% - 60px)" : "calc(98% - 240px)",
					borderBottom: "1px solid #c1c1c1",
					...(isCollapsed || hideSidebar ? { marginLeft: "calc(1% + 60px)" } : { marginLeft: "calc(1% + 240px)" }), // Apply the condition here
				}}
			>
				{subHeader && headerUrl && (
					<div
						style={{
							// backgroundColor:"red",
							marginTop: "-40px",
							flexDirection: "column",
							display: "flex",
							justifyContent: "space-between",
							alignItems: "flex-start",
						}}
					>
						<CustomLink
							to={headerUrl}
							style={{
								display: "flex",
								width: "100%",
								alignItems: "center",
								marginLeft: "1%",
							}}
						>
							<span className="fa fa-angle-left text-primary" style={{ fontSize: "13px", marginTop: "3px" }} />
							<h1
								style={{
									marginLeft: "8px",
									color: "#4287f5",
									fontSize: "13px",
									marginTop: "10px",
									fontWeight: "normal",
								}}
							>
								Back to {header}
							</h1>
						</CustomLink>
					</div>
				)}
				<div
					style={{
						display: "flex",

						justifyContent: "flex-end",
						padding: "2px",
						marginRight: "2%",
						marginTop: !(subHeader && headerUrl) ? "-40px" : "0px", // Change marginTop based on the condition
					}}
				>
					<p
						style={{
							marginTop: "-10px",
							maxWidth: "300px",
							// minWidth: "100px",
							// backgroundColor: "red",
							fontWeight: "600",
							fontSize: "13px",
						}}
					>
						Manage Jobs and Companies
						<span
							style={{
								fontWeight: "300",
								fontSize: "13px",
							}}
						>
							{" "}
							add and view.
						</span>
					</p>
				</div>

				{subHeader && (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginLeft: "0px",
							//  backgroundColor:"green",
							marginTop: "-6px",
							marginBottom: "10px",
						}}
					>
						<p
							style={{
								marginLeft: "1.5%",
								fontWeight: "600",
								fontSize: "24px",
								display: "flex",
								color: "#606061",
								alignItems: "center",
							}}
						>
							<i
								className={iconSubHeader}
								style={{
									marginRight: "15px",
									fontSize: "20px",
									color: "#4287f5",
								}}
							/>
							{subHeader}
						</p>
					</div>
				)}
			</div>
			{/* TOP info bar */}

			{/* main child */}
			<div
				className={`${isCollapsed ? "main-box-collapsed" : "main-box "} ${
					hideSidebar ? "main-box-collapsed" : "main-box"
				}}`}
			>
				<div
					style={{
						marginTop: "40px",
						backgroundColor: "#ffffff",
						// backgroundColor: "blue",
						flex: 1,
						width: "104%",
						// height:"40vh",
						// flexDirection: "column",
						paddingLeft: "3%",
						// alignContent:"center",
						alignItems: "center",
						justifyContent: "center",

						padding: 0,
					}}
				>
					<div
						style={{
							marginLeft: window.innerWidth < 768 ? "0px" : isCollapsed ? "0px" : "0px",
							// marginTop: "-60px",
							// backgroundColor: "pink",
						}}
					>
						{children}
					</div>
				</div>
			</div>
		</div>
	);
};

export default JobNavLayout;
