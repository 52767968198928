import "./index.css";

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import resumeStore from "./app/store";
const container = document.getElementById("root");

const root = createRoot(container);
root.render(
	<React.StrictMode>
		<Provider store={resumeStore}>
			<App />
		</Provider>
	</React.StrictMode>,
);
