import dayjs from "dayjs";

import { convertDateTo2Digits, match } from "./utilityFunctions";
import { isValidURL, stripHTML } from "./validationFunctions";

export function wordToNumberExperience(word) {
	let experienceNumber = 0;

	switch (word) {
		case "1-5 Years":
			experienceNumber = 1;
			break;
		case "5-10 Years":
			experienceNumber = 5;
			break;
		case "10+ Years":
			experienceNumber = 10;
			break;
		default:
			experienceNumber = 0;
			break;
	}

	return experienceNumber;
}

export function convertDateObjectToString(date) {
	const { day, month, year } = date;
	return dayjs(`${year}-${month}-${day}`).format("DD MMMM, YYYY");
}

export function convertULToPlainText(text) {
	const lines = text.split("<li>");
	const lis = lines.map((line) => line.replace("</li>", ""));
	return stripHTML(lis.join("\n"));
}

export function returnDocumentMIMEType(fileName) {
	//we only support pdf, doc and docx
	const extension = fileName.split(".").pop();
	if (extension === "pdf") {
		return "application/pdf";
	}
	if (extension === "doc") {
		return "application/msword";
	}
	if (extension === "docx") {
		return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
	}
}

export function generateFileFromBase64(base64, fileName) {
	if (isValidURL(base64)) return base64;
	const file = new File([base64], fileName, { type: returnDocumentMIMEType(fileName) });
	return file;
}

export function NextButton(isComplete, defaultValue, navigationHandler, skip) {
	let nextTxt = defaultValue;
	let excludeFlow = ["work-history", "work-details", "education-information"];

	try {
		let flowValue = navigationHandler.template.navigationFlow.current;
		if (isComplete && !excludeFlow.includes(nextTxt)) {
			nextTxt = "Save & Close";
		} else {
			flowValue = flowValue.next;

			if (skip === 0) nextTxt = flowValue.value.description;
			if (skip === 1) nextTxt = flowValue.next.value.description;
			if (skip === 2) nextTxt = flowValue.next.next.value.description;

			nextTxt = `Next : ${nextTxt}`;
		}
	} catch (err) {
		nextTxt = defaultValue;
	}

	return nextTxt;
}

export function PreviousButton(isComplete, defaultValue, navigationHandler, skip) {
	let previousTxt = defaultValue;
	let excludeFlow = ["work-details"];

	try {
		let flowValue = navigationHandler.template.navigationFlow.current;
		if (isComplete && !excludeFlow.includes(previousTxt)) {
			previousTxt = "Exit";
		} else {
			flowValue = flowValue.previous;

			if (skip === 0) previousTxt = flowValue.value.description;
			if (skip === 1) previousTxt = flowValue.previous.value.description;
			if (skip === 2) previousTxt = flowValue.previous.previous.value.description;

			previousTxt = `Previous : ${previousTxt}`;
		}
	} catch (err) {
		previousTxt = defaultValue;
	}

	return previousTxt;
}

export function numberToWordExperience(num) {
	let experienceWord = "No Experience";

	switch (num) {
		case 1:
			experienceWord = "1-5 Years";
			break;
		case 5:
			experienceWord = "5-10 Years";
			break;
		case 10:
			experienceWord = "10+ Years";
			break;
		default:
			experienceWord = "No Experience";
			break;
	}

	return experienceWord;
}

export function base64ToAscii(base64) {
	//revist the decison to upgraded from atob as it's marked for deprication.
	//Make Buffer is the recommended path. see https://github.com/evanw/esbuild/issues/1626
	//return Buffer.from(base64, "base64").toString("ascii");
	return atob(base64);
}

export function phoneTo255(phone) {
	if (!phone) return "";
	let phoneWith255 = phone;
	if (phone.startsWith("0")) {
		phoneWith255 = phone.replace("0", "255");
	}
	if (phone.startsWith("+")) {
		phoneWith255 = phone.replace("+", "");
	}
	if (phoneWith255.startsWith("255")) {
		return phoneWith255;
	}
	return phone;
}

export function resumeObjectToContextFormat(resumeObject, setResumeData, dispatchAddExtras) {
	if (resumeObject) {
		let heading = resumeObject.heading;
		localStorage.removeItem("ExtrasReducers");
		let lastUpdatedOn = resumeObject.journal.lastUpdatedOn;

		//WORK EXPERIENCE
		let experience = resumeObject.experience ? resumeObject.experience : false;
		let workHistory = [];
		for (let i = 0; i < experience.length; i++) {
			let details = [];
			const detailsFromAPI = experience[i].reponsibilitiesAndAccomplishements;

			for (let i = 0; i < detailsFromAPI.length; i++) {
				if (detailsFromAPI[i].bp) {
					const item = `<li>${detailsFromAPI[i].value}</li>`;
					details.push(item);
				} else {
					const item = `${detailsFromAPI[i].value}`;
					details.push(item);
				}
			}

			const skillsFromAPI = experience[i].relevantSkills || [];

			let relevantSkills = [];
			for (let i = 0; i < skillsFromAPI.length; i++) {
				if (skillsFromAPI[i].bp) {
					const item = `<li>${skillsFromAPI[i].value}</li>`;
					relevantSkills.push(item);
				} else {
					const item = `${skillsFromAPI[i].value}`;
					relevantSkills.push(item);
				}
			}

			const item = {
				jobTitle: experience[i].jobtitle,
				employer: experience[i].employer,
				city: experience[i].employerLocation.city,
				region: experience[i].employerLocation.region,
				country: experience[i].employerLocation.country,
				startDate: dayjs(`${experience[i].startDate.year}-${experience[i].startDate.month}-01`).format("YYYY-MM-DD"),
				endDate: experience[i].endDate
					? dayjs(`${experience[i].endDate.year}-${experience[i].endDate.month}-01`).format("YYYY-MM-DD")
					: "",
				details: `<ul>${details.join("")}</ul>`,
				currentEmployer: experience[i].currentEmployer,
				referenceId: experience[i].referenceId,
				relevantSkills: `<ul>${relevantSkills.join("")}</ul>`,
			};
			workHistory.push(item);
		}

		//EDUCATION
		let education = resumeObject.education ? resumeObject.education : false;
		const educationList = [];
		for (let j = 0; j < education.length; j++) {
			const educationDetails = education[j].additionInfo;
			const additionalDetailsList = [];

			for (let i = 0; i < educationDetails.length; i++) {
				if (educationDetails[i].bp) {
					const item = `<li>${educationDetails[i].value}</li>`;
					additionalDetailsList.push(item);
				} else {
					const item = `${educationDetails[i].value}`;
					additionalDetailsList.push(item);
				}
			}

			const item = {
				schoolName: education[j].schoolName,
				schoolCity: `${education[j].schoolLocation.city},${education[j].schoolLocation.region},${education[j].schoolLocation.country}`,
				educationLevel: education[j].educationLevel,
				fieldOfStudy: education[j].fieldOfStudy,
				gMonth: education[j].graduation.month,
				gYear: education[j].graduation.year,
				referenceId: education[j].referenceId,
				fieldOfStudyCategory: education[j].fieldOfStudyCategory,
				description: `<ul>${additionalDetailsList.join("")}</ul>`,
			};
			educationList.push(item);
		}

		//SKILLS
		let skills = resumeObject.skills ? resumeObject.skills : false;
		const skillsList = [];

		for (let i = 0; i < skills.length; i++) {
			if (skills[i].bp) {
				const item = `<li>${skills[i].value}</li>`;
				skillsList.push(item);
			} else {
				const item = `${skills[i].value}`;
				skillsList.push(item);
			}
		}

		//SUMMARY
		let summary = resumeObject.professionalSummary ? resumeObject.professionalSummary : false;
		const summaryList = [];

		for (let i = 0; i < summary.length; i++) {
			if (summary[i].bp) {
				const item = `<li>${summary[i].value}</li>`;
				summaryList.push(item);
			} else {
				const item = `${summary[i].value}`;
				summaryList.push(item);
			}
		}

		//Languages
		const languages = resumeObject.languages ? resumeObject.languages : false;
		const languagesList = [];
		if (languages) {
			if (languages.applicable) {
				dispatchAddExtras({
					path: "/resume/languages",
					item: "languages",
					checkedType: true,
				});
				const languagesData = languages.data;

				for (let i = 0; i < languagesData.length; i++) {
					const item = {
						id: i,
						language: languagesData[i].language,
						proficiency: languagesData[i].proficiency,
					};
					languagesList.push(item);
				}
			}
		}

		// Accomplishments
		const accomplishments = resumeObject.accomplishments ? resumeObject.accomplishments : false;
		const accomplishmentsList = [];
		let accomplishmentIsList = false;
		if (accomplishments) {
			if (accomplishments.applicable) {
				dispatchAddExtras({
					path: "/resume/accomplishments",
					item: "accomplishments",
					checkedType: true,
				});
				const accomplishmentsData = accomplishments.data;
				for (let i = 0; i < accomplishmentsData.length; i++) {
					if (accomplishmentsData[i].bp) {
						accomplishmentIsList = true;
						const item = `<li>${accomplishmentsData[i].value}</li>`;
						accomplishmentsList.push(item);
					} else {
						const item = `${accomplishmentsData[i].value}`;
						accomplishmentsList.push(item);
					}
				}
			}
		}

		// Additional Information
		const additionalInfo = resumeObject.additionInfo ? resumeObject.additionInfo : false;
		const additionalInfoList = [];
		let additionalInfoIsList = false;
		if (additionalInfo) {
			if (additionalInfo.applicable) {
				dispatchAddExtras({
					path: "/resume/additional-information",
					item: "additionalInformation",
					checkedType: true,
				});
				const additionalInfoData = additionalInfo.data;
				for (let i = 0; i < additionalInfoData.length; i++) {
					if (additionalInfoData[i].bp) {
						additionalInfoIsList = true;
						const item = `<li>${additionalInfoData[i].value}</li>`;
						additionalInfoList.push(item);
					} else {
						const item = `${additionalInfoData[i].value}`;
						additionalInfoList.push(item);
					}
				}
			}
		}

		// personalProfile
		const personalProfile = resumeObject.personlPrivateInfo ? resumeObject.personlPrivateInfo : false;
		let profile = {};
		if (personalProfile) {
			if (personalProfile.applicable) {
				dispatchAddExtras({
					path: "/resume/personal-profile",
					item: "profile",
					checkedType: true,
				});
				let date = (
					personalProfile.dateOfBirth.year +
					"-" +
					convertDateTo2Digits(personalProfile.dateOfBirth.month) +
					"-" +
					convertDateTo2Digits(personalProfile.dateOfBirth.day)
				).toString();

				profile = {
					gender: personalProfile.gender,
					maritalStatus: personalProfile.maritalStatus,
					dateOfBirth: dayjs(date).format("YYYY-MM-DD"),
				};
			}
		}

		//AFFILIATIONS
		const affiliations = resumeObject.affiliations ? resumeObject.affiliations : false;
		const affiliationsList = [];
		let affiliationsIsList = false;
		if (affiliations) {
			if (affiliations.applicable) {
				dispatchAddExtras({
					path: "/resume/affiliations",
					item: "affiliations",
					checkedType: true,
				});
				const affiliationsData = affiliations.data;
				for (let i = 0; i < affiliationsData.length; i++) {
					if (affiliationsData[i].bp) {
						affiliationsIsList = true;
						const item = `<li>${affiliationsData[i].value}</li>`;
						affiliationsList.push(item);
					} else {
						const item = `${affiliationsData[i].value}`;
						affiliationsList.push(item);
					}
				}
			}
		}

		//certifications
		const certifications = resumeObject.certifications ? resumeObject.certifications : false;
		const certificationsList = [];
		let certificationsIsList = false;
		if (certifications) {
			if (certifications.applicable) {
				dispatchAddExtras({
					path: "/resume/certifications",
					item: "certifications",
					checkedType: true,
				});
				const certificationsData = certifications.data;
				for (let i = 0; i < certificationsData.length; i++) {
					if (certificationsData[i].bp) {
						certificationsIsList = true;
						const item = `<li>${certificationsData[i].value}</li>`;
						certificationsList.push(item);
					} else {
						const item = `${certificationsData[i].value}`;
						certificationsList.push(item);
					}
				}
			}
		}

		//references
		const references = resumeObject.reference ? resumeObject.reference : false;
		const referencesList = [];
		if (references) {
			if (references.applicable) {
				dispatchAddExtras({
					path: "/resume/reference-summary",
					item: "reference",
					checkedType: true,
				});
				const referencesData = references.data;
				for (let i = 0; i < referencesData.length; i++) {
					const data = {
						id: referencesData[i].referenceId,
						name: referencesData[i].fullName,
						designation: referencesData[i].designation,
						workPlace: referencesData[i].workPlace,
						address: `${referencesData[i].workplaceLocation.city}, ${referencesData[i].workplaceLocation.region}`,
						country: referencesData[i].workplaceLocation.country,
						phoneNumber: referencesData[i].phoneNumber,
						email: referencesData[i].emailAddress,
					};
					referencesList.push(data);
				}
			}
		}

		//personalLinks

		const personalLinks = resumeObject.personalLinks ? resumeObject.personalLinks : false;
		let personalLinksList = [];
		if (personalLinks) {
			if (personalLinks.applicable) {
				dispatchAddExtras({
					path: "/resume/personal-links",
					item: "websites",
					checkedType: true,
				});
				personalLinksList = [...personalLinks.data];
			}
		}

		const customDetails = resumeObject.custom ? resumeObject.custom : false;
		const customList = [];
		let customIsList = false;
		if (customDetails) {
			if (customDetails.applicable) {
				dispatchAddExtras({
					path: `/resume/custom-extra/${customDetails.alias}`,
					item: "customExtra",
					checkedType: `${customDetails.alias}`,
				});
				const customData = customDetails.data;
				for (let i = 0; i < customData.length; i++) {
					if (customData[i].bp) {
						customIsList = true;
						const item = `<li>${customData[i].value}</li>`;
						customList.push(item);
					} else {
						const item = `${customData[i].value}`;
						customList.push(item);
					}
				}
			}
		}

		const titleFunctionArray = heading.resumeTitle.split("-");

		const journal = resumeObject.journal; // we need journal.currentStatus

		setResumeData((prevState) => {
			return {
				...prevState,
				id: resumeObject.id,
				journal: journal, //added journal
				lastUpdatedOn: lastUpdatedOn,
				createdOn: resumeObject.journal.startedOn,
				resumeTitle: resumeObject.journal.resumeTitle
					? resumeObject.journal.resumeTitle
					: resumeObject.heading.resumeTitle,
				contactInfo: {
					...prevState.contactInfo,
					firstName: heading.firstName,
					middleName: heading.middleName,
					lastName: heading.lastName,
					phoneNo: heading.phoneNumber,
					email: heading.emailAddress,
					city: heading.address.city,
					region: heading.address.region,
					resumeTitle: titleFunctionArray[0],
					resumeFunction: titleFunctionArray[1],
					legalNameToSAcknowledged: heading.legalNameToSAcknowledged,
				},
				workHistory: workHistory,
				education: educationList,
				skills: `<ul>${skillsList.join("")}</ul>`,
				summary: `${summaryList.join("")}`,
				extras: {
					...prevState.extras,
					languages: languages.applicable ? languagesList : [],
					accomplishments: accomplishments.applicable
						? accomplishmentIsList
							? `<ul>${accomplishmentsList.join("")}</ul>`
							: accomplishmentsList.join("")
						: "",
					additionalInfo: additionalInfo.applicable
						? additionalInfoIsList
							? `<ul>${additionalInfoList.join("")}</ul>`
							: additionalInfoList.join("")
						: "",
					personalProfile: profile,
					affiliations: affiliations.applicable
						? affiliationsIsList
							? `<ul>${affiliationsList.join("")}</ul>`
							: affiliationsList.join("")
						: "",

					certifications: certifications.applicable
						? certificationsIsList
							? `<ul>${certificationsList.join("")}</ul>`
							: certificationsList.join("")
						: "",
					references: references.applicable ? referencesList : [],
					personalLinks: personalLinks.applicable ? personalLinksList : [],
					customExtras: customDetails.applicable
						? {
								name: customDetails.alias,
								data: customIsList ? `<ul>${customList.join("")}</ul>` : customList.join(""),
							}
						: {
								name: "",
								data: "",
							},
				},
				progressStatus: resumeObject.journal.progressStatus,
				reviewRequests: resumeObject.reviewRequests,
			};
		});
	}
}

export function sectionName(sectionEnum) {
	return match(sectionEnum, {
		HEADING: "Contacts",
		EXPERIENCE: "Work History",
		EDUCATION: "Education",
		SKILLS: "Skills",
		SUMMARY: "Professional Summary",
		REFERENCE: "References",
		CERTIFICATION: "Certifications",
		AFFILIATION: "Affiliations",
		ADDITIONAL_INFO: "Additional Information",
		LANGUAGES: "Languages",
		ACHIEVEMENTS: "Achievements",
		PERSONAL: "Personal Information",
		LINKS: "Links",
		CUSTOM: "Custom Section",
		ACCOMPLISHMENTS: "Accomplishments",
		default: "Invalid Section Name",
	});
}
