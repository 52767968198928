/* eslint-disable no-useless-escape */
import { GoogleLogin } from "@react-oauth/google";
import { CognitoUserAttribute, CognitoUserPool } from "amazon-cognito-identity-js";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { decodeToken } from "react-jwt";
import { Link, useNavigate } from "react-router-dom";

import poolData from "../../../../userpool";
import ErrorToast from "../../../components/ErrorToast";
import Spinner from "../../../components/Spinner";
import InputField from "../../../components/UIComponents/InputField/";
import AuthContext from "../../../context/Auth/AuthContext";
import ResumeContext from "../../../context/ResumeContext";
import { isValidEmail, isValidPassword } from "../../../handlers/validationFunctions";
import { StaticValue } from "../../../seed/constants";

const userPool = new CognitoUserPool(poolData);
const staticValue = new StaticValue();
const staticValues = staticValue.getDeafaulValues();

const referer = new URLSearchParams(window.location.search).get("ref");

function JobSeekerRegistrationForm() {
	const { setUserData } = useContext(AuthContext);
	const [passwordVisible, setPasswordVisible] = useState(false);

	const togglePasswordVisibility = (newVisibility) => {
		setPasswordVisible(newVisibility);
	};

	const context = useContext(ResumeContext);

	const navigate = useNavigate();

	const [registrationData, setRegistrationData] = useState({
		email: "",
		password: "",
		confirm_password: "",
	});

	const { email, password, confirm_password } = registrationData;

	const [errors, setErrors] = useState({
		email: "",
		confirm_password: "",
		password: "",
	});

	const [serverError, setServerError] = useState("");

	function onChange(event) {
		setRegistrationData({
			...registrationData,
			[event.target.name]: event.target.value,
		});
		setErrors({
			email: "",
			confirm_password: "",
			password: "",
		});
	}

	const [loading, setLoading] = useState(false);
	useEffect(() => {}, [loading]);

	function handleSubmit(event) {
		event.preventDefault();
		setErrors({
			email: "",
			confirm_password: "",
			password: "",
		});
		setServerError("");
		setLoading(true);

		if (email === "" || password === "" || confirm_password === "") {
			setErrors({
				email: email === "" ? "Email is required" : "",
				password: password === "" ? "Password required" : "",
				confirm_password: confirm_password === "" ? "Confirm password is required" : "",
			});
			setLoading(false);
			return;
		}
		if (!isValidPassword(password)) {
			setErrors((prevState) => {
				return {
					...prevState,
					password:
						"<ul><li>Password must have least 1 number</li><li>Password must have least 1 special character(^ $ *)</li><li>Password must have at least 1 uppercase letter</li><li>Password must have at least 1 lowercase letter</li></ul>",
				};
			});
			setLoading(false);
			return;
		}

		if (password !== confirm_password) {
			setErrors((prevState) => {
				return {
					...prevState,
					confirm_password: "Password and confirm password should match",
				};
			});
			setLoading(false);
			return;
		}

		if (email && !isValidEmail(email)) {
			setErrors((prevState) => {
				return {
					...prevState,
					email: "Please enter a valid email address",
				};
			});
			setLoading(false);
			return;
		}

		const attributeList = [];
		attributeList.push(
			new CognitoUserAttribute({
				Name: "email",
				Value: email,
			}),
		);
		userPool.signUp(email, password, attributeList, null, (err) => {
			if (err) {
				if ({ ...err }["code"] === "UsernameExistsException") {
					setServerError("Email already exists");
				}
			} else {
				const goTo = referer && referer.includes("expert-help") ? "/signin?ref=" + referer : "/signin";
				navigate(goTo, {
					state: {
						email: email,
						message: `Registered Successfully, please follow the link sent to your email {${email}} to activate your DProz account, once your account is activated, you can log on to DProz to continue your progress from any device.`,
					},
				});
			}
			setLoading(false);
		});
	}

	useEffect(() => {
		if (context) {
			let { contactInfo } = context.resumeData;
			setRegistrationData({
				...registrationData,
				email: contactInfo.email,
			});
		}
	}, [context]);

	const [passwordValidationState, setPasswordValidationState] = useState({
		length: false,
		uppercase: false,
		number: false,
		specialCharacter: false,
		match: false,
	});

	useEffect(() => {
		setPasswordValidationState({
			length: password.length >= 8,
			uppercase: /[A-Z]/.test(password),
			number: /\d/.test(password),
			specialCharacter: /[!@#$%^&*()_+\-=\[\]/{};&#39;:"\\|,.<>\/?]/.test(password),
			match: password.length > 0 && password === confirm_password,
		});
	}, [password, confirm_password]);

	const [, setCookie, removeCookie] = useCookies(["resume"]);

	function onGoogleSignIn(googleUser) {
		const credential = `GO::${googleUser.credential}`;

		const expireDate = new Date();
		expireDate.setDate(expireDate.getDate() + 30);
		setCookie("Authorization", credential, {
			path: "/",
			expires: expireDate,
		});

		if (googleUser.credential != null) {
			removeCookie(staticValues.localStorageResumeId);
			localStorage.removeItem("resumeList");
			setLoading(false);
			const decodedToken = decodeToken(googleUser.credential);

			const data = {
				email: decodedToken.email,
				firstName: decodedToken.given_name,
				lastName: decodedToken.family_name,
				picture: decodedToken.picture,
			};

			setUserData(data);

			navigate(referer && referer.includes("expert-help") ? "/expert-help/personal-info" : "/my-resumes");
		}
	}

	const [showEmailOptions, setShowEmailOptions] = useState(false);

	const buttonRef = useRef();

	const buttonWidth = useMemo(() => {
		if (buttonRef.current) {
			return buttonRef.current.offsetWidth;
		}
		return 0;
	}, [buttonRef.current]);

	return (
		<div className="account-content px-16">
			<div className="">
				<Link
					to="/"
					style={{
						display: window.innerWidth > 768 ? "none" : "block",
					}}
				>
					<img
						src="/images/logo.png"
						alt="DProz Logo"
						className="p-2 mb-4"
						style={{
							width: "180px",
						}}
					/>
				</Link>
				<h1 className="m-0">Elevate Your Job Search Process</h1>
				<p className="text-medium mb-4">Create an account to get started.</p>
				<div className="mb-4 py-2 flex items-center justify-center" ref={buttonRef}>
					<GoogleLogin
						onSuccess={onGoogleSignIn}
						onFailure={() => toast.error("Login Failed")}
						size="large"
						width={`${buttonWidth}px`}
						text="continue_with"
						useOneTap
					/>
				</div>
				<div className="mb-3 text-center">
					<p className="text-medium font-weight-medium">OR</p>
				</div>
				{serverError !== "" && <ErrorToast error={serverError} />}{" "}
				{showEmailOptions ? (
					<form action="#" className="tr-form">
						<div className="form-group text-left">
							<InputField
								type="email"
								className="form-control"
								placeholder="Enter Email"
								value={email}
								onChange={onChange}
								name="email"
								error={errors.email}
								label="Email"
								required
							/>
						</div>
						<div className="form-group text-left">
							<InputField
								type="password"
								className="form-control"
								placeholder="Password"
								onChange={onChange}
								value={password}
								disableCopyPaste={true}
								label="Password"
								name="password"
								error={errors.password}
								required
								initialPasswordVisibilityStatus={passwordVisible}
								onClick={togglePasswordVisibility}
							/>
						</div>
						{password.length > 0 && (
							<>
								<div className="form-group text-left">
									<InputField
										type="password"
										className="form-control"
										placeholder="Confirm Password"
										onChange={onChange}
										value={confirm_password}
										disableCopyPaste={true}
										name="confirm_password"
										label="Confirm Password"
										error={errors.confirm_password}
										required
										initialPasswordVisibilityStatus={passwordVisible}
										onClick={togglePasswordVisibility}
									/>
								</div>
								<div className="form-group text-left">
									<ul className="pl-4">
										<li className={passwordValidationState.length ? "text-success" : "text-danger"}>
											Password is at least 6 characters long.
										</li>
										<li className={passwordValidationState.number ? "text-success" : "text-danger"}>
											Password has at least one number
										</li>
										<li className={passwordValidationState.specialCharacter ? "text-success" : "text-danger"}>
											Password has at least one special character
										</li>
										<li className={passwordValidationState.uppercase ? "text-success" : "text-danger"}>
											Password has at least one uppercase letter
										</li>
										<li className={passwordValidationState.match ? "text-success" : "text-danger"}>
											Password and confirm password match
										</li>
									</ul>
								</div>
							</>
						)}
						<button type="button" className="btn btn-primary w-100" onClick={handleSubmit}>
							{loading ? <Spinner /> : "Sign Up"}
						</button>
					</form>
				) : (
					<>
						<button className="w-full border font-semibold btn text-dark" onClick={() => setShowEmailOptions(true)}>
							Continue with Email
						</button>
					</>
				)}
				<div className="new-user mt-4">
					<span>
						Already have an account?{" "}
						<Link to="/signin" className="font-weight-semi">
							Sign in
						</Link>
					</span>
				</div>
			</div>
		</div>
	);
}

export default JobSeekerRegistrationForm;
