import React from "react";

import FeedBackModal from "../../../../components/Reviews/FeedBackModal/FeedBackModal";
import { sections } from "../../../../handlers/enums";
import { convertDateToWords } from "../../../../handlers/utilityFunctions";
import useReviewFeedBack from "../../../../hooks/use-ReviewFeedBack";

function ResumeThree({
	isReview,
	reviewData,
	resumeData,
	hasFeedback,
	activeSection,
	onSubmitFeedBack,
	loadingSubmission,
}) {
	const { useReviewFeedback, feedBackButton } = useReviewFeedBack(reviewData);

	return (
		<div className="bg-white final-resume-preview-wrapper shadow-sm">
			<div className="final-resume-preview bg-white  text-dark">
				<div className="resume-content ">
					<div className="top">
						<div className="row no-gutters">
							<div
								className="col-4 py-2 py-md-4 d-flex align-items-center justify-content-center border-right"
								style={{ backgroundColor: "#f8f9fa" }}
							>
								<div className="resume-pic  d-flex align-items-center justify-content-center">
									<span className="font-weight-semi">
										{resumeData.contactInfo.firstName.charAt(0)}
										{resumeData.contactInfo.lastName.charAt(0)}
									</span>
								</div>
							</div>
							<div className="col-8 px-2 px-md-4">
								<div className="resume-user-name pt-2 pt-md-4">
									<h1
										className="text-uppercase"
										style={{
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
											margin: "0",
										}}
									>
										{resumeData.contactInfo.firstName} {resumeData.contactInfo.middleName}{" "}
										{resumeData.contactInfo.lastName}
									</h1>
									<span
										className="d-inline-block"
										style={{
											height: "5px",
											backgroundColor: "#03a9f4",
											width: "100px",
										}}
									/>
								</div>
								<ul className="resume-user-quick-info d-flex list-unstyled d-flex flex-wrap align-items-center mt-3">
									<li className="mr-2 mr-md-3 text-nowrap">
										<span className="span text-primary mr-1 mr-md-2 fa fa-map-marker" />
										{resumeData.contactInfo.city}, {resumeData.contactInfo.country}
									</li>
									<li className="mr-2 mr-md-3 text-nowrap">
										<span className="span text-primary mr-1 mr-md-2 fa fa-phone" />
										{resumeData.contactInfo.phoneNo}
									</li>
									<li className="mr-2 mr-md-3 text-nowrap">
										<span className="span text-primary mr-1 mr-md-2 fa fa-envelope" />
										{resumeData.contactInfo.email}
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="bottom">
						<div className="row no-gutters">
							<div className="col-4 border-right px-2 px-md-4" style={{ backgroundColor: "#f8f9fa" }}>
								{[...resumeData.extras.personalLinks].length > 0 && (
									<div
										className={`personal-links my-1 position-relative ${
											isReview
												? "rs-highlight"
												: hasFeedback
													? activeSection === sections.LINKS
														? "rs-feedback rs-feedback-active"
														: "rs-feedback"
													: ""
										}`}
										id={sections.LINKS}
										style={{
											backgroundColor:
												useReviewFeedback.section.section === sections.LINKS ? "rgba(27, 175, 245, 0.12)" : "",
										}}
									>
										<h3 className="text-uppercase">website, portfolio, profiles</h3>
										{isReview || activeSection === sections.LINKS ? feedBackButton(sections.LINKS, hasFeedback) : null}
										<ul className="pl-3">
											{[...resumeData.extras.personalLinks].map((link, index) => (
												<li key={index}>
													<span className="text-capitalize">{link.type}</span>: {link.value.replaceAll("www.", "")}
												</li>
											))}
										</ul>
									</div>
								)}

								{resumeData.extras.languages.length !== 0 ? (
									<div
										className={`languages-info my-1 position-relative ${
											isReview
												? "rs-highlight"
												: hasFeedback
													? activeSection === sections.LANGUAGES
														? "rs-feedback rs-feedback-active"
														: "rs-feedback"
													: ""
										}`}
										id={sections.LANGUAGES}
										style={{
											backgroundColor:
												useReviewFeedback.section.section === sections.LANGUAGES ? "rgba(27, 175, 245, 0.12)" : "",
										}}
									>
										<h3 className="text-uppercase">Languages</h3>
										{isReview || activeSection === sections.LANGUAGES
											? feedBackButton(sections.LANGUAGES, hasFeedback)
											: null}
										<ul className="pl-3">
											{resumeData.extras.languages.map((language, index) => (
												<li key={index}>
													<span className="mr-2">{language.language}</span>
													{" - "}
													<span className="mr-2">{language.proficiency}</span>
												</li>
											))}
										</ul>
									</div>
								) : null}
							</div>
							<div className="col-8 px-2 px-md-4">
								{resumeData.summary.length > 0 && (
									<div
										className={`skills-info mb-2 position-relative review-section ${
											isReview
												? "rs-highlight"
												: hasFeedback
													? activeSection === sections.SUMMARY
														? "rs-feedback rs-feedback-active"
														: "rs-feedback"
													: ""
										}`}
										id={sections.SUMMARY}
										style={{
											backgroundColor:
												useReviewFeedback.section.section === sections.SUMMARY ? "rgba(27, 175, 245, 0.12)" : "",
										}}
									>
										<h3 className="text-uppercase with-circle">Professional Summary</h3>
										{isReview || activeSection === sections.SUMMARY
											? feedBackButton(sections.SUMMARY, hasFeedback)
											: null}
										<span
											dangerouslySetInnerHTML={{
												__html: resumeData.summary,
											}}
										/>
									</div>
								)}

								{resumeData.skills !== "" ? (
									<div
										className={`skills-info mb-2 position-relative review-section ${
											isReview
												? "rs-highlight"
												: hasFeedback
													? activeSection === sections.SKILLS
														? "rs-feedback rs-feedback-active"
														: "rs-feedback"
													: ""
										}`}
										id={sections.SKILLS}
										style={{
											backgroundColor:
												useReviewFeedback.section.section === sections.SKILLS ? "rgba(27, 175, 245, 0.12)" : "",
										}}
									>
										<h3 className="text-uppercase  with-circle">Skills</h3>
										{isReview || activeSection === sections.SKILLS
											? feedBackButton(sections.SKILLS, hasFeedback)
											: null}
										<div
											className="pl-3"
											dangerouslySetInnerHTML={{
												__html: resumeData.skills,
											}}
										/>
									</div>
								) : null}

								{resumeData.extras.certifications !== "" ? (
									<div
										className={`certifications-info mb-2 position-relative review-section ${
											isReview
												? "rs-highlight"
												: hasFeedback
													? activeSection === sections.CERTIFICATION
														? "rs-feedback rs-feedback-active"
														: "rs-feedback"
													: ""
										}`}
										id={sections.CERTIFICATION}
										style={{
											backgroundColor:
												useReviewFeedback.section.section === sections.CERTIFICATION ? "rgba(27, 175, 245, 0.12)" : "",
										}}
									>
										<h3 className="text-uppercase  with-circle">Certifications</h3>
										{isReview || activeSection === sections.CERTIFICATION
											? feedBackButton(sections.CERTIFICATION, hasFeedback)
											: null}
										<div
											className="pl-3"
											dangerouslySetInnerHTML={{
												__html: resumeData.extras.certifications,
											}}
										/>
									</div>
								) : null}

								{resumeData.extras.accomplishments !== "" ? (
									<div
										className={`languages-info mb-2 position-relative review-section ${
											isReview
												? "rs-highlight"
												: hasFeedback
													? activeSection === sections.ACCOMPLISHMENTS
														? "rs-feedback rs-feedback-active"
														: "rs-feedback"
													: ""
										}`}
										id={sections.ACCOMPLISHMENTS}
										style={{
											backgroundColor:
												useReviewFeedback.section.section === sections.ACCOMPLISHMENTS
													? "rgba(27, 175, 245, 0.12)"
													: "",
										}}
									>
										<h3 className="text-uppercase  with-circle">Accomplishments</h3>
										{isReview || activeSection === sections.ACCOMPLISHMENTS
											? feedBackButton(sections.ACCOMPLISHMENTS, hasFeedback)
											: null}
										<div
											className="pl-3"
											dangerouslySetInnerHTML={{
												__html: resumeData.extras.accomplishments,
											}}
										/>
									</div>
								) : null}

								{resumeData.workHistory.length > 0 && resumeData.workHistory[0].jobTitle !== "" ? (
									<div
										className={`work-history-info mb-2 position-relative review-section ${
											isReview
												? "rs-highlight"
												: hasFeedback
													? activeSection === sections.EXPERIENCE
														? "rs-feedback rs-feedback-active"
														: "rs-feedback"
													: ""
										}`}
										id={sections.EXPERIENCE}
										style={{
											backgroundColor:
												useReviewFeedback.section.section === sections.EXPERIENCE ? "rgba(27, 175, 245, 0.12)" : "",
										}}
									>
										<h3 className="text-uppercase  with-circle">Work History</h3>
										{isReview || activeSection === sections.EXPERIENCE
											? feedBackButton(sections.EXPERIENCE, hasFeedback)
											: null}
										{[...resumeData.workHistory].map((job) => (
											<>
												<div className="d-flex justify-content-between">
													<p className="mb-2">
														<strong>{job.employer}</strong>
														{" - "}
														{job.city}, {job.region}, {job.country}
														<br />
														<em>{job.jobTitle}</em>
													</p>
													<p className="mb-0">
														<strong>
															{convertDateToWords(job.startDate)} -{" "}
															{job.endDate !== "" ? convertDateToWords(job.endDate) : "Present"}
														</strong>
													</p>
												</div>
												<div
													className="pl-3"
													dangerouslySetInnerHTML={{
														__html: job.details,
													}}
												/>
											</>
										))}
									</div>
								) : null}

								{resumeData.education.length > 0 ? (
									<div
										className={`education-info mb-2 position-relative review-section ${
											isReview
												? "rs-highlight"
												: hasFeedback
													? activeSection === sections.EDUCATION
														? "rs-feedback rs-feedback-active"
														: "rs-feedback"
													: ""
										}`}
										id={sections.EDUCATION}
										style={{
											backgroundColor:
												useReviewFeedback.section.section === sections.EDUCATION ? "rgba(27, 175, 245, 0.12)" : "",
										}}
									>
										<h3 className="text-uppercase with-circle">Education</h3>
										{isReview || activeSection === sections.EDUCATION
											? feedBackButton(sections.EDUCATION, hasFeedback)
											: null}
										{[...resumeData.education].map((educationHistory, index) => (
											<div key={index}>
												<div className="d-flex justify-content-between">
													<p>
														<strong>{educationHistory.schoolName}</strong>
														{" - "}
														{educationHistory.schoolCity}, <em>{educationHistory.fieldOfStudy}</em>
													</p>
													<p>
														<strong>{educationHistory.gYear}</strong>
													</p>
												</div>
												<div
													dangerouslySetInnerHTML={{
														__html: educationHistory.description,
													}}
												/>
											</div>
										))}
									</div>
								) : null}

								{resumeData.extras.personalProfile.dateOfBirth && (
									<div
										className={`profile-info mb-2 position-relative review-section ${
											isReview
												? "rs-highlight"
												: hasFeedback
													? activeSection === sections.PERSONAL
														? "rs-feedback rs-feedback-active"
														: "rs-feedback"
													: ""
										}`}
										id={sections.PERSONAL}
										style={{
											backgroundColor:
												useReviewFeedback.section.section === sections.PERSONAL ? "rgba(27, 175, 245, 0.12)" : "",
										}}
									>
										<h3 className="text-uppercase with-circle">Personal Information</h3>
										{isReview || activeSection === sections.PERSONAL
											? feedBackButton(sections.PERSONAL, hasFeedback)
											: null}
										<div>
											<div>
												<strong>Date of Birth:</strong> {resumeData.extras.personalProfile.dateOfBirth}
											</div>
											<div>
												<strong>Gender:</strong> {resumeData.extras.personalProfile.gender}
											</div>
											<div>
												<strong>Marital Status:</strong> {resumeData.extras.personalProfile.maritalStatus}
											</div>
										</div>
									</div>
								)}

								{resumeData.extras.affiliations !== "" ? (
									<div
										className={`affliations-info mb-2 position-relative review-section ${
											isReview
												? "rs-highlight"
												: hasFeedback
													? activeSection === sections.AFFILIATION
														? "rs-feedback rs-feedback-active"
														: "rs-feedback"
													: ""
										}`}
										id={sections.AFFILIATION}
										style={{
											backgroundColor:
												useReviewFeedback.section.section === sections.AFFILIATION ? "rgba(27, 175, 245, 0.12)" : "",
										}}
									>
										<h3 className="text-uppercase with-circle">Affiliations</h3>
										{isReview || activeSection === sections.AFFILIATION
											? feedBackButton(sections.AFFILIATION, hasFeedback)
											: null}
										<div
											dangerouslySetInnerHTML={{
												__html: resumeData.extras.affiliations,
											}}
										/>
									</div>
								) : null}

								{resumeData.extras.additionalInfo !== "" ? (
									<div
										className={`addtional-info mb-2 position-relative review-section ${
											isReview
												? "rs-highlight"
												: hasFeedback
													? activeSection === sections.ADDITIONAL_INFO
														? "rs-feedback rs-feedback-active"
														: "rs-feedback"
													: ""
										}`}
										id={sections.ADDITIONAL_INFO}
										style={{
											backgroundColor:
												useReviewFeedback.section.section === sections.ADDITIONAL_INFO
													? "rgba(27, 175, 245, 0.12)"
													: "",
										}}
									>
										<h3 className="text-uppercase with-circle">Additional Information</h3>
										{isReview || activeSection === sections.ADDITIONAL_INFO
											? feedBackButton(sections.ADDITIONAL_INFO, hasFeedback)
											: null}
										<div
											dangerouslySetInnerHTML={{
												__html: resumeData.extras.additionalInfo,
											}}
										/>
									</div>
								) : null}

								{resumeData.extras.references.length > 0 && (
									<div
										className={`reference-info mb-2 position-relative review-section ${
											isReview
												? "rs-highlight"
												: hasFeedback
													? activeSection === sections.REFERENCE
														? "rs-feedback rs-feedback-active"
														: "rs-feedback"
													: ""
										}`}
										id={sections.REFERENCE}
										style={{
											backgroundColor:
												useReviewFeedback.section.section === sections.REFERENCE ? "rgba(27, 175, 245, 0.12)" : "",
										}}
									>
										<h3 className="text-uppercase  with-circle">References</h3>
										{isReview || activeSection === sections.REFERENCE
											? feedBackButton(sections.REFERENCE, hasFeedback)
											: null}
										<ul>
											{[...resumeData.extras.references].map((reference, index) => (
												<li className="" key={index}>
													<span>
														{reference.name}
														{" - "}
														{reference.designation}, {reference.workPlace}
													</span>
													<br />
													<span>
														{reference.email}, {reference.phoneNumber}
													</span>
												</li>
											))}
										</ul>
									</div>
								)}

								{resumeData.extras.customExtras.name.length > 0 ? (
									<div
										className={`custom-info mb-2 position-relative review-section ${
											isReview
												? "rs-highlight"
												: hasFeedback
													? activeSection === sections.CUSTOM
														? "rs-feedback rs-feedback-active"
														: "rs-feedback"
													: ""
										}`}
										id={sections.CUSTOM}
										style={{
											backgroundColor:
												useReviewFeedback.section.section === sections.CUSTOM ? "rgba(27, 175, 245, 0.12)" : "",
										}}
									>
										<h3 className="text-uppercase with-circle">{resumeData.extras.customExtras.name}</h3>
										{isReview || activeSection === sections.PERSONAL
											? feedBackButton(sections.PERSONAL, hasFeedback)
											: null}
										<div
											dangerouslySetInnerHTML={{
												__html: resumeData.extras.customExtras.data,
											}}
										/>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
			{useReviewFeedback.showFeedBackModal && (
				<FeedBackModal
					resumeData={resumeData}
					details={useReviewFeedback.section}
					onSubmitFeedBack={onSubmitFeedBack}
					loadingSubmission={loadingSubmission}
				/>
			)}
		</div>
	);
}

export default ResumeThree;
