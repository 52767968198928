export default {
	selectedTemplate: {
		yearsOfExperience: "",
		templateName: "",
	},
	contactInfo: {},
	workHistory: [],
	activeWorkHistoryIndex: 0,
	education: [],
	activeEducationIndex: 0,
	skills: "",
	summary: "",
	extras: {
		accomplishments: "",
		affiliations: "",
		personalLinks: [],
		certifications: "",
		additionalInfo: "",
		languages: [],
		references: [],
		activeReferencesIndex: 0,
		personalProfile: {},
		customExtras: { name: "", data: "" },
	},
};
