import "../../NewNavLayout/dashboardStyle.css";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import ReviewsList from "../../../Views/ManageResumes/MyResumes/ReviewsList";

const FeedBackDashboard = ({ storedResumes }) => {
	let currentLocation = window.location.href;
	const [, setShowReviews] = useState(() => {
		return currentLocation.includes("feedback=active");
	});

	const [resumeList, setResumeList] = useState([]);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [screenWidth]);

	useEffect(() => {
		if (storedResumes) {
			setResumeList(JSON.parse(storedResumes));
		}
	}, []);

	return (
		<div className="segment-dashboard">
			{resumeList.length === 0 ? (
				<div className="d-flex p-4 align-center justify-content-center">
					<div className="float-right">
						<h2 style={{ fontFamily: "Varela Round", fontSize: "1.5rem" }}> No Resume Review Found! </h2>
						<p>
							Complete a <Link to="/my-resumes"> resume </Link>
							and create and share <Link to="/my-resumes?feedback=active"> feedback request </Link>
							for your resume reviews.
						</p>
					</div>
				</div>
			) : (
				<>
					<ReviewsList setShowReviews={setShowReviews} resumeList={resumeList} screenWidth={screenWidth} />
					<Link to="/my-resumes?feedback=active">
						<button className="learn-more-button">More details</button>
					</Link>
				</>
			)}
		</div>
	);
};

export default FeedBackDashboard;
