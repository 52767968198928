import { useCookies } from "react-cookie";

import { StaticValue } from "../seed/constants";

const useSetResumeIdCookie = () => {
	const staticValue = new StaticValue();
	const staticValues = staticValue.getDeafaulValues();
	const [cookie, setCookie] = useCookies(["resume"]);

	const setResumeIdCookie = (resumeId) => {
		let expireDate = new Date();
		expireDate.setDate(expireDate.getDate() + 7);
		setCookie(staticValues.localStorageResumeId, resumeId, {
			path: "/",
			expires: expireDate,
		});
	};

	return { cookie, setResumeIdCookie };
};

export default useSetResumeIdCookie;
