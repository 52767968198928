const professionalSummary = {
	content: [
		{
			bp: false,
			value:
				"Begin your resume with a strong overview of the qualifications, achievements, expertise, and other knowledge you that's unique, distinctive, and memorable.",
		},
		{
			bp: false,
			value:
				"Including a brief summary of your skills and qualifications can help the recruiter quickly assess your suitability for the role, this is your elevator pitch.",
		},
		{
			bp: true,
			value:
				"Paint a picture of your professional credentials that will make the recruiter eager to scan further down the page.",
		},
		{
			bp: true,
			value:
				"Shouldn't take up more than four or five lines; otherwise you risk losing the recruiter before they move to your professional experience.",
		},
		{
			bp: true,
			value:
				"It's important to tie this summary to the role you're specifically applying; Include keywords that ties to the job description.",
		},
		{
			bp: true,
			value:
				"Toughest section of resume to write because you've a few sentences in which to highlight the most important attributes you think will catch the recruiter's attention",
		},
		{
			bp: true,
			value:
				"DON'T include an objective in your resume: Recruiters know your objective is to be hired into the role you're applying for.",
		},
	],
};

export default professionalSummary;
