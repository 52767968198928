import React from "react";

function TemplatePremiumSix() {
	return (
		<div style={{ scale: "1.2", transformOrigin: "center" }}>
			<div className="small-resume-preview small-resume-preview-premium-template-10">
				<div className="final-resume-preview bg-white resume-type-1 text-dark">
					<div className="container" style={{ maxWidth: "1000px" }} id="printResume">
						<div
							className="resume-general-info mb-1"
							style={{ border: "1px solid #1d3344", borderRadius: "10px", marginTop: "15px" }}
						>
							<div className="row  resume-general-inner" style={{ marginTop: "-5px" }}>
								<div className="col-12">
									<div className="text-center">
										<h1 className="mb-0 d-inline-block px-4 bg-white" style={{ color: "#1d3344" }}>
											Jacquiline Khalfan
										</h1>
										<h4 className="mb-2 text-center font-weight-normal" style={{ color: "#f3b943" }}>
											Business Development Manager
										</h4>
									</div>
									<p className="mb-2 px-md-4">
										Professional Business Developer with four year of experience in the business Development processes.
										Involved in product testing, management and development of new business opportunities. Professional
										Business Developer with four year of experience in the business Development processes. Involved in
										product testing, management and development of new business opportunities.
									</p>
								</div>
							</div>
						</div>

						<div
							className="p-2 mb-2 resume-contact-sec"
							style={{
								color: "#ffffff",
								backgroundColor: "#1d3344",
								borderRadius: "5px",
								borderBottomLeftRadius: "20px",
								borderBottomRightRadius: "20px",
							}}
						>
							<div className="row ">
								<div className="col-6">
									<p className="mb-1">
										<span className="contact-icon mr-2">
											{" "}
											<i className=" fa fa-envelope " aria-hidden="true" />
										</span>
										jacquiline.khalfan@dproz.tz
									</p>
								</div>
								<div className="col-6">
									<p className="mb-1">
										<span className="contact-mobile mr-2">
											{" "}
											<i className=" fa fa-phone " aria-hidden="true" />
										</span>
										202-555-0166
									</p>
								</div>
								<div className="col-6">
									<p className="mb-1">
										<span className="contact-icon mr-2">
											{" "}
											<i className=" fa fa-map-marker " aria-hidden="true" />
										</span>
										New York, USA
									</p>
								</div>
								<div className="col-6">
									<p className="mb-1">
										<span className="contact-icon mr-2">
											{" "}
											<i className=" fa fa-linkedin " aria-hidden="true" />
										</span>
										linkedin.com/in/jacquiline.khalfan
									</p>
								</div>
								<div className="col-6">
									<p className="mb-1">
										<span className="contact-icon mr-2">
											{" "}
											<i className=" fa fa-twitter " aria-hidden="true" />
										</span>
										twitter.com/jacquiline.khalfan
									</p>
								</div>
								<div className="col-6">
									<p className="mb-1">
										<span className="contact-icon mr-2">
											{" "}
											<i className=" fa fa-quora " aria-hidden="true" />
										</span>
										quora.com/jacquiline.khalfan
									</p>
								</div>
							</div>
						</div>

						<div className="row mb-2">
							<div className="col-12">
								<div className="title d-flex align-items-center mb-2" style={{ color: "#1d3344" }}>
									<span
										className="title-icon"
										style={{
											width: "20px",
											height: "20px",
											border: "1px solid #1d3344",
											borderRadius: "3px",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "10px",
										}}
									>
										<span className="fa fa-cogs" />
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0  mr-3">areas of expertise</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span style={{ flexGrow: 1, height: "0.5px", backgroundColor: "#1d3344" }} />
										<span style={{ display: "inline-block", fontSize: "10px" }} className="fa fa-caret-left" />
									</span>
								</div>

								<ul className="mt-1 text-white mb-0 pl-0">
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#8e99a1" }}
									>
										SEO
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#8e99a1" }}
									>
										Public Speaking
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#8e99a1" }}
									>
										Negotiation
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#8e99a1" }}
									>
										Teamwork
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#8e99a1" }}
									>
										Decission Making
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#8e99a1" }}
									>
										Research and Strategy
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#8e99a1" }}
									>
										Emotional Intelligence
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#8e99a1" }}
									>
										Outbound Marketing
									</li>
								</ul>
							</div>
						</div>

						<div className="row mb-2">
							<div className="col-12">
								<div
									className="title d-flex align-items-center mb-2"
									style={{
										color: "#1d3344",
									}}
								>
									<span
										className="title-icon"
										style={{
											width: "20px",
											height: "20px",
											border: "1px solid #1d3344",
											borderRadius: "3px",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "10px",
										}}
									>
										<span className="fa fa-briefcase" />
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0  mr-3">Work experience</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span style={{ flexGrow: 1, height: "0.5px", backgroundColor: "#1d3344" }} />
										<span style={{ display: "inline-block", fontSize: "10px" }} className="fa fa-caret-left" />
									</span>
								</div>

								<div className="mb-2">
									<h4 className="font-weight-bold mb-1">Business Analyst</h4>
									<h5 className="mb-1">Proxi Solutions</h5>
									<div
										className="d-flex align-items-center justify-content-between font-italic"
										style={{ color: "#f3b943" }}
									>
										<p className="mb-1">09/2017 - Present</p>
										<p className="mb-1">New York, USA</p>
									</div>

									<ul className="p-0 mb-0" style={{ listStyle: "none" }}>
										<li className="d-flex align-items-start">
											<span
												style={{
													fontWeight: 500,
													flex: "0 0 15px",
													fontSize: "5px",
													marginTop: "5px",
													color: "#f3b943;",
												}}
											>
												<span className="fa fa-circle" />
											</span>
											Successfully managed $2 - 3 million budget projects and successfully achieved the project
											scheduled goals.
										</li>
										<li className="d-flex align-items-start">
											<span
												style={{
													fontWeight: 500,
													flex: "0 0 15px",
													fontSize: "5px",
													marginTop: "5px",
													color: "#f3b943;",
												}}
											>
												<span className="fa fa-circle" />
											</span>
											Developed and implemented new marketing and sales plans and defined the strategy for the next 5
											years.
										</li>
										<li className="d-flex align-content-center">
											<span
												style={{
													fontWeight: 500,
													flex: "0 0 15px",
													fontSize: "5px",
													marginTop: "5px",
													color: "#f3b943;",
												}}
											>
												<span className="fa fa-circle" />
											</span>
											Reviewed constantly the customer feedback and then suggested ways to improve the processes and
											customer service levels which increased the satisfaction rate from 81% to 95%.
										</li>
										<li className="d-flex align-content-center">
											<span
												style={{
													fontWeight: 500,
													flex: "0 0 15px",
													fontSize: "5px",
													marginTop: "5px",
													color: "#f3b943;",
												}}
											>
												<span className="fa fa-circle" />
											</span>
											Ensured that new clients will grow into a loyal customer base in a specialist niche market by
											implementing a new loyalty program.
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="row mb-2">
							<div className="col-12">
								<div
									className="title d-flex align-items-center mb-2"
									style={{
										color: "#1d3344",
									}}
								>
									<span
										className="title-icon"
										style={{
											width: "20px",
											height: "20px",
											border: "1px solid #1d3344",
											borderRadius: "3px",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "10px",
										}}
									>
										<span className="fa fa-graduation-cap" />
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0  mr-3">Education</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span style={{ flexGrow: 1, height: "0.5px", backgroundColor: "#1d3344" }} />
										<span style={{ display: "inline-block", fontSize: "10px" }} className="fa fa-caret-left" />
									</span>
								</div>

								<div className="row">
									<div className="col-12">
										<h4 className="mb-1">
											Bachelor of Science Information Technology, Major in Software Systems Engineering
										</h4>
										<h5 className="mb-1">The University of Chicago</h5>
										<p style={{ color: "#f3b943" }}>
											<em>09/2008 - 06/2010</em>
										</p>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-2">
							<div className="col-12">
								<div
									className="title d-flex align-items-center mb-2"
									style={{
										color: "#1d3344",
									}}
								>
									<span
										className="title-icon"
										style={{
											width: "20px",
											height: "20px",
											border: "1px solid #1d3344",
											borderRadius: "3px",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "10px",
										}}
									>
										<span className="fa fa-book" />
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0 mr-3">CONFERENCES &amp; COURSES</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span style={{ flexGrow: 1, height: "0.5px", backgroundColor: "#1d3344" }} />
										<span style={{ display: "inline-block", fontSize: "10px" }} className="fa fa-caret-left" />
									</span>
								</div>
								<div className="row">
									<div className="col-6">
										<div className="mb-2">
											<h6> Leading People and Teams Specialization (03/2016 - 10/2016)</h6>
											<p style={{ color: "#cccccc" }}>
												<em>Online course on Coursera.org</em>
											</p>
										</div>
									</div>
									<div className="col-6">
										<div className="mb-2">
											<h6> Project Management Principles and Practices (01/2015 - 09/2015)</h6>
											<p style={{ color: "#cccccc" }}>
												<em>Online course on Coursera.org</em>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-2">
							<div className="col-12">
								<div
									className="title d-flex align-items-center  mb-2"
									style={{
										color: "#1d3344",
									}}
								>
									<span
										className="title-icon"
										style={{
											width: "20px",
											height: "20px",
											border: "1px solid #1d3344",
											borderRadius: "3px",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "10px",
										}}
									>
										<span className="fa fa-certificate" />
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0  mr-3">certificates</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span style={{ flexGrow: 1, height: "1px", backgroundColor: "#1d3344" }} />
										<span style={{ display: "inline-block", fontSize: "10px" }} className="fa fa-caret-left" />
									</span>
								</div>

								<div className="row">
									<div className="col-6">
										<div className="mb-1">
											<h6> Microsoft Certified Solutions Developer</h6>
											<p style={{ color: "#cccccc" }}>
												<em>ABCD International Academy</em>
											</p>
										</div>
									</div>
									<div className="col-6">
										<div className="mb-1">
											<h6> AWS Certified SysOps Admin - Associate</h6>
											<p style={{ color: "#cccccc" }}>
												<em>ABCD International Academy</em>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-3">
							<div className="col-12">
								<div
									className="title d-flex align-items-center mb-2"
									style={{
										color: "#1d3344",
									}}
								>
									<span
										className="title-icon"
										style={{
											width: "20px",
											height: "20px",
											border: "1px solid #1d3344",
											borderRadius: "3px",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "10px",
										}}
									>
										<span className="fa fa-cube" />
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0 mr-3">personal projects</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span style={{ flexGrow: 1, height: "0.5px", backgroundColor: "#1d3344" }} />
										<span style={{ display: "inline-block", fontSize: "10px" }} className="fa fa-caret-left" />
									</span>
								</div>

								<div className="row">
									<div className="col-12">
										<div className="mb-1">
											<h6> Voluteer @Habitat for Humanity (2018 - Present) </h6>
											<ul className="p-0 mb-0" style={{ listStyle: "none" }}>
												<li className="d-flex align-items-center">
													<span
														style={{
															fontWeight: 500,
															flex: "0 0 15px",
															fontSize: "5px",
															marginTop: "0px",
															color: "#f3b943",
														}}
													>
														<span className="fa fa-circle" />
													</span>
													Developed and implemented new marketing and sales plans and defined the strategy for the next
													5 years.
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-2">
							<div className="col-12">
								<div
									className="title d-flex align-items-center mb-2"
									style={{
										color: "#1d3344",
									}}
								>
									<span
										className="title-icon"
										style={{
											width: "20px",
											height: "20px",
											border: "1px solid #1d3344",
											borderRadius: "3px",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "10px",
										}}
									>
										<span className="fa fa-trophy" />
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0  mr-3">HONOURS AND AWARDS</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span style={{ flexGrow: 1, height: "0.5px", backgroundColor: "#1d3344" }} />
										<span style={{ display: "inline-block", fontSize: "10px" }} className="fa fa-caret-left" />
									</span>
								</div>

								<div className="row">
									<div className="col-12">
										<div className="mb-2 ">
											<h6> Jury Member, Venture Cup Entrepreneurship Competition (2016) </h6>
											<p style={{ color: "#cccccc" }}>
												<em>Venture Cup USA</em>
											</p>
										</div>
									</div>
									<div className="col-12">
										<div className="mb-2 ">
											<h6> Sales Individual &amp; Business Development Award (2015)</h6>
											<p style={{ color: "#cccccc" }}>
												<em>AirState Business Awards</em>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-2">
							<div className="col-12">
								<div
									className="title d-flex align-items-center mb-2"
									style={{
										color: "#1d3344",
									}}
								>
									<span
										className="title-icon"
										style={{
											width: "20px",
											height: "20px",
											border: "1px solid #1d3344",
											borderRadius: "3px",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "10px",
										}}
									>
										<span className="fa fa-users" />
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0 mr-3">organizations</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span style={{ flexGrow: 1, height: "0.5px", backgroundColor: "#1d3344" }} />
										<span style={{ display: "inline-block", fontSize: "10px" }} className="fa fa-caret-left" />
									</span>
								</div>

								<div className="row">
									<div className="col-6">
										<div className="mb-2">
											<h6> Microsoft Certified Solutions Developer</h6>
											<p style={{ color: "#cccccc" }}>
												<em>ABCD International Academy</em>
											</p>
										</div>
									</div>
									<div className="col-6">
										<div className="mb-2">
											<h6> AWS Certified SysOps Admin - Associate</h6>
											<p style={{ color: "#cccccc" }}>
												<em>ABCD International Academy</em>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-2">
							<div className="col-12">
								<div
									className="title d-flex align-items-center mb-2"
									style={{
										color: "#1d3344",
									}}
								>
									<span
										className="title-icon"
										style={{
											width: "20px",
											height: "20px",
											border: "1px solid #1d3344",
											borderRadius: "3px",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "10px",
										}}
									>
										<span className="fa fa-language" />
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0  mr-3">Languages</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span style={{ flexGrow: 1, height: "0.5px", backgroundColor: "#1d3344" }} />
										<span style={{ display: "inline-block", fontSize: "10px" }} className="fa fa-caret-left" />
									</span>
								</div>

								<div className="row no-gutters">
									<div className="col px-1">
										<div className="mb-2">
											<h6> English</h6>
											<p className="mb-0" style={{ color: "#f3b943;" }}>
												<em>Native or Bilingual Proficiency</em>
											</p>
										</div>
									</div>
									<div className="col px-1">
										<div className="mb-2">
											<h6> Spanish</h6>
											<p className="mb-0" style={{ color: "#f3b943;" }}>
												<em>Native or Bilingual Proficiency</em>
											</p>
										</div>
									</div>
									<div className="col px-1">
										<div className="mb-2">
											<h6> Portuguese</h6>
											<p className="mb-0" style={{ color: "#f3b943;" }}>
												<em>Native or Bilingual Proficiency</em>
											</p>
										</div>
									</div>
									<div className="col px-1">
										<div className="mb-2">
											<h6> French</h6>
											<p className="mb-0" style={{ color: "#f3b943;" }}>
												<em>Native or Bilingual Proficiency</em>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-3">
							<div className="col-12">
								<div
									className="title d-flex align-items-center  mb-2"
									style={{
										color: "#1d3344",
									}}
								>
									<span
										className="title-icon"
										style={{
											width: "20px",
											height: "20px",
											border: "1px solid #1d3344",
											borderRadius: "3px",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "10px",
										}}
									>
										<span className="fa fa-superpowers" />
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0  mr-3">interests</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span style={{ flexGrow: 1, height: "0.5px", backgroundColor: "#1d3344" }} />
										<span style={{ display: "inline-block", fontSize: "10px" }} className="fa fa-caret-left" />
									</span>
								</div>

								<div className="row no-gutters">
									<div className="col px-1">
										<div className="mb-2 mb-md-3">
											<h6> Artificial Intelligence</h6>
										</div>
									</div>
									<div className="col px-1">
										<div className="mb-2 mb-md-4">
											<h6> Driving</h6>
										</div>
									</div>
									<div className="col px-1">
										<div className="mb-2 mb-md-4">
											<h6> Video Games</h6>
										</div>
									</div>
									<div className="col px-1">
										<div className="mb-2 mb-md-4">
											<h6> Sustainability</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="preview text-center mt-4 mb-4">
				<button
					className="btn text-primary btn-sm btn-outline-primary"
					data-toggle="modal"
					data-target="#ResumePreview"
				>
					<span className="fa fa-eye" /> Preview
				</button>
			</div>
		</div>
	);
}

export default TemplatePremiumSix;
