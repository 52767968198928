export default [
	{
		section: "EXPERIENCE",
		rating: "MODERATE",
		status: "COMPLETED",
		reasonsForRating: ["Reason 1", "Reason 2"],
		recommendations: ["Recommendation 3", "Recommendation 7"],
		lastUpdatedOn: "",
		reviewerInfo: {},
	},
	{
		section: "EDUCATION",
		rating: "",
		status: "IN_COMPLETED",
		reasonsForRating: [],
		recommendations: [],
		lastUpdatedOn: "",
		reviewerInfo: {},
	},
	{
		section: "SKILLS",
		rating: "STRONG",
		status: "COMPLETED",
		reasonsForRating: ["Reason 1", "Reason 2"],
		recommendations: ["Recommendation 1", "Recommendation 2"],
		lastUpdatedOn: "",
		reviewerInfo: {},
	},
	{
		section: "SUMMARY",
		rating: "WEAK",
		status: "IN_COMPLETED",
		reasonsForRating: ["Reason 1: This is a very long reason ohh my god...it is very long i can tell you!", "Reason 7"],
		recommendations: [],
		lastUpdatedOn: "",
		reviewerInfo: {},
	},
	{
		section: "LINKS",
		rating: "",
		status: "IN_COMPLETED",
		reasonsForRating: [],
		recommendations: [],
		lastUpdatedOn: "",
		reviewerInfo: {},
	},
	{
		section: "ACCOMPLISHMENTS",
		rating: "",
		status: "IN_COMPLETED",
		reasonsForRating: [],
		recommendations: [],
		lastUpdatedOn: "",
		reviewerInfo: {},
	},
	{
		section: "AFFILIATION",
		rating: "",
		status: "IN_COMPLETED",
		reasonsForRating: [],
		recommendations: [],
		lastUpdatedOn: "",
		reviewerInfo: {},
	},
	{
		section: "CERTIFICATION",
		rating: "",
		status: "IN_COMPLETED",
		reasonsForRating: [],
		recommendations: [],
		lastUpdatedOn: "",
		reviewerInfo: {},
	},
	{
		section: "ADDITIONAL_INFO",
		rating: "",
		status: "IN_COMPLETED",
		reasonsForRating: [],
		recommendations: [],
		lastUpdatedOn: "",
		reviewerInfo: {},
	},
	{
		section: "LANGUAGES",
		rating: "",
		status: "IN_COMPLETED",
		reasonsForRating: [],
		recommendations: [],
		lastUpdatedOn: "",
		reviewerInfo: {},
	},
	{
		section: "REFERENCE",
		rating: "",
		status: "IN_COMPLETED",
		reasonsForRating: [],
		recommendations: [],
		lastUpdatedOn: "",
		reviewerInfo: {},
	},
	{
		section: "PERSONAL",
		rating: "",
		status: "IN_COMPLETED",
		reasonsForRating: [],
		recommendations: [],
		lastUpdatedOn: "",
		reviewerInfo: {},
	},
	{
		section: "CUSTOM",
		rating: "",
		status: "IN_COMPLETED",
		reasonsForRating: [],
		recommendations: [],
		lastUpdatedOn: "",
		reviewerInfo: {},
	},
];
