export default function (section, rating) {
	let matched;

	switch (section) {
		case "HEADING":
			matched = match("heading", rating, options);
			break;

		case "SUMMARY":
			matched = match("summary", lowerCaseRating(rating), options);
			break;

		case "EXPERIENCE":
			matched = match("experience", lowerCaseRating(rating), options);
			break;

		case "SKILLS":
			matched = match("skills", lowerCaseRating(rating), options);
			break;

		case "CERTIFICATION":
			matched = match("certification", lowerCaseRating(rating), options);
			break;

		case "EDUCATION":
			matched = match("education", lowerCaseRating(rating), options);
			break;

		case "ACCOMPLISHMENTS":
			matched = match("accomplishments", lowerCaseRating(rating), options);
			break;

		default:
			matched = { reasons: [], recommendations: [] };
	}

	return matched;
}

const match = (section, rating, options) => {
	return options[section][rating] || options?.default;
};

const lowerCaseRating = (_rating) => {
	return _rating === "STRONG" ? "strong" : _rating === "MODERATE" ? "moderate" : _rating === "WEAK" ? "weak" : "";
};

const options = {
	heading: {
		weak: {
			reasons: [
				{ key: "Incomplete information like address." },
				{ key: "Missing contact info email, phone." },
				{ key: "Unprofessional Email." },
			],
			recommendations: [
				{
					key: "If applicable, include complete and up-to-date contact information, including your full name, professional title, email address, phone number, and physical address.",
				},
				{
					key: "Use a professional email address that's appropriate for the workplace. Avoid using email addresses that include nicknames or personal information.",
				},
				{
					key: "Update your contact information regularly, and ensure it's correct and consistent across all your job application materials.",
				},
			],
		},
		moderate: {
			reasons: [{ key: "Basic Information." }, { key: "Generic Professional Title." }],
			recommendations: [
				{
					key: "Consider adding professional information like GitHub links, LinkedIn links, Portfolio Websites, and addresses.",
				},
				{
					key: "Use a more specific and unique professional title that highlights your skills and experience, such as 'Digital Marketing Manager' or 'Creative Marketing Strategist.'",
				},
			],
		},
		strong: {
			reasons: [
				{ key: "Clear and Concise Information." },
				{ key: "Specific and unique Professional Title." },
				{ key: "Well-Formatted Design." },
			],
			recommendations: [
				{
					key: "Make sure the included information is accessible in case you have links that reflect you. Like the full name, professional title, Social-LinkedIn or Personal website, and contact information.",
				},
				{ key: "Make sure your professional title is tailored to reflect your skills, experience, and expertise." },
			],
		},
	},
	summary: {
		weak: {
			reasons: [
				{ key: "Generic language makes this section appear weak and unconvincing." },
				{ key: "No specificity to details makes this section appear weak and lacking in substance." },
				{ key: "No alignment with job requirements." },
			],
			recommendations: [
				{ key: "Use specific language that highlights your unique qualifications and experience." },
				{ key: "Provide specific examples of how you've used your skills in past experiences." },
				{
					key: "Align your professional summary with the job requirements by highlighting your relevant skills and experience.",
				},
			],
		},
		moderate: {
			reasons: [
				{ key: "Your professional summary is not as long as it could be." },
				{ key: "No Industry-Specific Knowledge" },
				{ key: "Your professional summary is too long as it could be." },
			],
			recommendations: [
				{
					key: "Improve your professional summary to define you as a professional and cover those areas most relevant to the job that you seek like.",
				},
				{ key: "Include industry-specific knowledge or experience that's relevant to the job you're applying for." },
				{ key: "Keep your professional summary focused to reflect and cover job title and experiences." },
			],
		},
		strong: {
			reasons: [
				{ key: "Clear and Concise." },
				{ key: "Alignment with job requirements" },
				{ key: "Demonstrated impact." },
			],
			recommendations: [
				{ key: "Highlights your unique qualifications and experience." },
				{ key: "Align with the job requirements by highlighting your relevant skills and experience." },
				{ key: "Include specific examples of how you've made an impact in past experiences, such as.." },
			],
		},
	},
	experience: {
		weak: {
			reasons: [
				{ key: "Vague Descriptions" },
				{ key: "Irrelevant Information: The included experiences are not relevant to the job you're applying for" },
				{ key: "Lack of Accomplishments: You're failing to highlight your accomplishments and achievements" },
			],
			recommendations: [
				{ key: "Be specific on details about your experience in a clear and concise manner." },
				{
					key: "Focus on including only relevant experience that aligns with the job you're applying for, and leave out any irrelevant or unnecessary information.",
				},
				{
					key: "Highlight your accomplishments and achievements, using specific metrics and data where possible to demonstrate your impact in previous roles.",
				},
				{ key: "Show that your  energetic." },
			],
		},
		moderate: {
			reasons: [
				{ key: "Based on how the experiences are phrased, you come across as a 'doer,' as opposed to an 'achiever.'" },
				{
					key: "Too many of your sentences are task-based and not results-based. This means that they tell you what you did, instead of what you achieved",
				},
				{
					key: "I  did not find the kind of active language that would bring your work to life. Instead, I saw passive words and non-action verbs",
				},
				{
					key: "Phrases like “Managed” and “Assisted with” are monotonous, overused, and add little value to your resume.",
				},
				{ key: "Your list of responsibilities is too long" },
			],
			recommendations: [
				{
					key: "Employers want to know about your previous contributions and specifically how you've helped make a difference. More significantly, they want to know how you are going to make a difference at their company.",
				},
				{
					key: "Strong action verbs, used with engaging language to outline outstanding achievements, are essential parts of a soundly-built resume. Here is an example: Passive language / Doing: “Negotiated contracts with vendors”  Action language / Achieving: “Slashed payroll/benefits administration costs 30% by negotiating pricing and fees while ensuring the continuation and enhancements of services.”",
				},
				{ key: "Try to make a list of responsibilities per experience a  maximum of up to 6 no otherwise" },
			],
		},
		strong: {
			reasons: [
				{ key: "Detailed and Specific Information your experience section appears strong and impressive" },
				{
					key: "Relevant and Targeted Information that aligns with the job you're applying for makes your experience section appear strong and focused.",
				},
				{ key: "Emphasis on Accomplishments" },
			],
			recommendations: [
				{
					key: "Use detailed and specific language to describe your experience, including information about the industries you've worked in, the software and tools you've used, and the size and scope of projects you've worked on.",
				},
				{
					key: "Target your experience section to the job you're applying for, highlighting only relevant information that demonstrates your suitability for the role.",
				},
				{
					key: "Emphasize your accomplishments and achievements, using specific metrics and data to demonstrate your impact in previous roles.",
				},
				{ key: "Your resume needs to include more achievements" },
			],
		},
	},
	skills: {
		weak: {
			reasons: [
				{ key: "Missing critical skills like...." },
				{ key: "Fewer skills" },
				{ key: "Typos" },
				{ key: "Included Skills don’t align with your professional title" },
				{
					key: "No Specificity: Failing to provide specific details or examples to support your skills makes your skills section appear weak and lacking in substance.",
				},
			],
			recommendations: [
				{ key: "Add more skills like…" },
				{ key: "Fix types instead of … say …" },
				{
					key: "Your Skill section needs to include more keywords and skills to help position you as a stronger candidate like …",
				},
				{ key: "Remove all unrelated skills to your professional title like …" },
				{ key: "Focus on skills that are relevant to the job you're applying for." },
			],
		},
		moderate: {
			reasons: [
				{
					key: "Lack of Detail: Providing only a list of skills without any additional information or context makes your skills section appear moderate and undifferentiated.",
				},
				{
					key: "No Industry-Specific Skills: Failing to include industry-specific skills or knowledge makes your skills section appear moderate and lacking in expertise.",
				},
			],
			recommendations: [
				{ key: "Provide additional information or context to give more detail about each skill like…" },
				{
					key: "Include industry-specific skills or knowledge that are relevant to the job you're applying for like …",
				},
			],
		},
		strong: {
			reasons: [
				{ key: "Relevant and Specific Skills" },
				{ key: "Demonstrated Proficiency" },
				{ key: "Quantifiable Achievements" },
			],
			recommendations: [
				{ key: "Your resume needs to include more keywords and skills to help position you as a stronger candidate." },
				{ key: "Focus on skills that are directly relevant to the job you're applying for." },
				{ key: "Provide specific examples of how you've used your skills in past experiences." },
			],
		},
	},
	certification: {
		weak: {
			reasons: [
				{
					key: "Lack of Relevance: Including irrelevant or outdated awards and certifications makes your awards & certifications section appear weak and unimportant.",
				},
				{
					key: "Incomplete Information: Failing to provide complete information about the award or certification makes your awards & certifications section appear weak and lacking in substance.",
				},
				{
					key: "No Context: Failing to provide context for the award or certification makes your awards & certifications section appear weak and confusing.",
				},
			],
			recommendations: [
				{
					key: "Only include relevant and recent awards and certifications that demonstrate your skills and qualifications.",
				},
				{
					key: "Provide complete information about the award or certification, including the issuing organization, date, and any relevant details.",
				},
				{
					key: "Provide context for the award or certification, such as why it's relevant to the job you're applying for.",
				},
			],
		},
		moderate: {
			reasons: [
				{
					key: "Including general information about your awards and certifications without providing specific details or examples makes this section appear moderate and undifferentiated.",
				},
				{
					key: "Failing to provide additional information or context about the award or certification makes this section appear moderate and lacking in depth.",
				},
				{
					key: "Including only a few awards or certifications without providing a broader range of qualifications makes this section appear moderate and limited.",
				},
			],
			recommendations: [
				{
					key: "Provide specific details or examples about your awards and certifications, such as how they demonstrate your skills and qualifications.",
				},
				{
					key: "Provide additional information or context about the award or certification, such as how you earned it and what it means.",
				},
				{ key: "Include a broad range of awards and certifications that demonstrate your skills and expertise." },
			],
		},
		strong: {
			reasons: [
				{ key: "Relevance and Significance" },
				{ key: "Depth and Breadth" },
				{ key: "Clarity and Organization" },
			],
			recommendations: [
				{
					key: "Only include relevant and significant awards and certifications that demonstrate your skills and expertise.",
				},
				{
					key: "Provide detailed information about each award or certification, including how you earned it and what it means.",
				},
				{
					key: "Organize your awards & certifications section in a clear and organized way, such as by category or date.",
				},
			],
		},
	},
	education: {
		weak: {
			reasons: [
				{ key: "Lack of Relevant Information such as ..." },
				{ key: "Unclear Details like ..." },
				{
					key: "Omitting important educational credentials, such as your degree or certifications makes this section look weak.",
				},
			],
			recommendations: [
				{
					key: "Include relevant information about your education, such as the degree you earned, the name of the institution you attended, and your graduation date.",
				},
				{
					key: "Provide specific details about your educational experience, such as coursework, research projects, and extracurricular activities that demonstrate your skills and qualifications.",
				},
				{
					key: "Include all important educational credentials, such as degrees, leadership certifications, and licenses.",
				},
			],
		},
		moderate: {
			reasons: [
				{ key: "Lack of Details." },
				{ key: "Failing to include honors or awards received." },
				{ key: "No Relevant Coursework." },
			],
			recommendations: [
				{
					key: "Provide additional details about your educational experience, such as specific courses taken, research projects, and relevant extracurricular activities.",
				},
				{
					key: "Include any honors or awards received during your educational experiences, such as Dean's List or Honor Society membership, etc.",
				},
				{
					key: "Include relevant coursework that aligns with the job you're applying for, highlighting any coursework that demonstrates your skills and qualifications.",
				},
			],
		},
		strong: {
			reasons: [
				{ key: "Relevant and Specific Information" },
				{ key: "Honors and Awards" },
				{ key: "Relevant Coursework" },
			],
			recommendations: [
				{
					key: "Provide specific details about your educational experience, including coursework, research projects, and extracurricular activities that demonstrate your skills and qualifications.",
				},
				{ key: "Highlight relevant coursework that aligns with the job you're applying for" },
			],
		},
	},
	accomplishments: {
		weak: {
			reasons: [
				{ key: "Vague Descriptions" },
				{ key: "Lack of Accomplishments: You're failing to highlight your accomplishments and achievements" },
				{ key: "Irrelevant Information: The included experiences are not relevant to the job you're applying for" },
			],
			recommendations: [
				{ key: "Be specific on details about your experience in a clear and concise manner." },
				{
					key: "Focus on including only relevant experience that aligns with the job you're applying for, and leave out any irrelevant or unnecessary information.",
				},
				{
					key: "Highlight your accomplishments and achievements, using specific metrics and data where possible to demonstrate your impact in previous roles.",
				},
				{ key: "Show that your  energetic." },
			],
		},
		moderate: {
			reasons: [
				{ key: "Based on how the experiences are phrased, you come across as a 'doer,' as opposed to an 'achiever.'" },
				{
					key: "Too many of your sentences are task-based and not results-based. This means that they tell you what you did, instead of what you achieved",
				},
				{
					key: "I  did not find the kind of active language that would bring your work to life. Instead, I saw passive words and non-action verbs",
				},
				{
					key: "Phrases like “Managed” and “Assisted with” are monotonous, overused, and add little value to your resume.",
				},
				{ key: "Your list of responsibilities is too long" },
			],
			recommendations: [
				{
					key: "Employers want to know about your previous contributions and specifically how you've helped make a difference. More significantly, they want to know how you are going to make a difference at their company.",
				},
				{
					key: "Strong action verbs, used with engaging language to outline outstanding achievements, are essential parts of a soundly-built resume. Here is an example: Passive language / Doing: “Negotiated contracts with vendors”  Action language / Achieving: “Slashed payroll/benefits administration costs 30% by negotiating pricing and fees while ensuring the continuation and enhancements of services.”",
				},
				{ key: "Try to make a list of responsibilities per experience a  maximum of up to 6 no otherwise" },
			],
		},
		strong: {
			reasons: [
				{ key: "Detailed and Specific Information your experience section appears strong and impressive" },
				{
					key: "Relevant and Targeted Information that aligns with the job you're applying for makes your experience section appear strong and focused.",
				},
				{ key: "Emphasis on Accomplishments" },
			],
			recommendations: [
				{
					key: "Use detailed and specific language to describe your experience, including information about the industries you've worked in, the software and tools you've used, and the size and scope of projects you've worked on.",
				},
				{
					key: "Target your experience section to the job you're applying for, highlighting only relevant information that demonstrates your suitability for the role.",
				},
				{
					key: "Emphasize your accomplishments and achievements, using specific metrics and data to demonstrate your impact in previous roles.",
				},
				{ key: "Your resume needs to include more achievements" },
			],
		},
	},
	default: {
		reasons: [],
		recommendations: [],
	},
};
