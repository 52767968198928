import { convertUlToArray } from "../../handlers/utilityFunctions";

export class Accomplishment {
	constructor(accomplishments) {
		this.accomplishments = accomplishments;
	}

	getAccomplishmentRequest() {
		const textAreaItems = convertUlToArray(this.accomplishments);

		return {
			data: textAreaItems,
		};
	}
}
