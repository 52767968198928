import { wordToNumberExperience } from "../../handlers/valueTransformation";
import { Address } from "./address";

export class NewRequest {
	constructor(contactInformation) {
		this.templateId = contactInformation.templateId;
		this.emailAddress = contactInformation.email;
		this.firstName = contactInformation.firstName;
		this.middleName = contactInformation.middleName;
		this.lastName = contactInformation.lastName;
		this.yearsOfExperience = contactInformation.yearsOfExperience;
		this.resumeTitle = contactInformation.resumeTitle;
		this.phoneNo = contactInformation.phoneNo;
		this.legalNameToSAcknowledged = contactInformation.legalNameToSAcknowledged;
		this.address = new Address(contactInformation.city, contactInformation.region, contactInformation.country);
	}

	getRequestParam() {
		let experienceNumber = wordToNumberExperience(this.yearsOfExperience);
		//use
		//replace templateId with alphanumeric
		let templateName = this.templateId.replaceAll("_", "x");

		return {
			templateId: `${experienceNumber}q${templateName}`,
			emailAddress: this.emailAddress,
			firstName: this.firstName,
			middleName: this.middleName,
			lastName: this.lastName,
			yearsOfExperience: experienceNumber,
			resumeTitle: this.resumeTitle,
			address: this.address,
			phoneNumber: this.phoneNo,
			legalNameToSAcknowledged: this.legalNameToSAcknowledged,
		};
	}
}
