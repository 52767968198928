import { useContext } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import ResumeContext from "../context/ResumeContext/index";

const useExtrasnav = () => {
	const location = useLocation();
	const extras = useSelector((state) => state.ExtrasReducers);
	const { resumeData } = useContext(ResumeContext);
	const { references } = resumeData.extras;
	if (extras.extrasItems.length === 0) {
		return "/resume/review";
	} else {
		let pathName = location.pathname;

		if (pathName === "/resume/reference-summary") {
			pathName = references.length === 0 ? "/resume/reference-information" : "/resume/reference-summary";
		}

		const presentExtraItemIndex = extras.extrasItems.findIndex((extraItem) => extraItem === pathName);
		const isLastItem = extras.extrasItems.length - 1 === presentExtraItemIndex;
		if (isLastItem) {
			return "/resume/review";
		} else {
			const nextExtrasItemIndex = presentExtraItemIndex + 1;

			if (extras.extrasItems[nextExtrasItemIndex] === "/resume/custom-extra/") {
				return `/resume/custom-extra/${extras.extrasList.customExtra}`;
			}

			return extras.extrasItems[nextExtrasItemIndex];
		}
	}
};

export default useExtrasnav;
