import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

function CircularProgressWithLabel(props) {
	return (
		<Box sx={{ position: "relative", display: "inline-flex" }}>
			<Box position="relative" display="inline-flex">
				<CircularProgress
					variant="determinate"
					value={100}
					size={80}
					sx={{
						color: (theme) => theme.palette.grey[300],
					}}
				/>
				<CircularProgress
					variant="determinate"
					{...props}
					size={80}
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						zIndex: 2,
						strokeLinecap: "round",
					}}
				/>
			</Box>
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: "absolute",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",
				}}
			>
				<Typography className="fs-18" variant="caption" component="div" color="text.secondary">
					{`${props.value}%`}
				</Typography>
				<Typography className="fs-8" component="div" color="text.secondary">
					COMPLETED
				</Typography>
			</Box>
		</Box>
	);
}

export default CircularProgressWithLabel;
