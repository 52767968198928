import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { interactions } from "../../../handlers/enums";
import {
	calculateDateToToday,
	calculateDayRemainsToDate,
	dateObjToYMD,
	formatAPIDate,
	urlify,
} from "../../../handlers/utilityFunctions";
import { resumeObjectToContextFormat } from "../../../handlers/valueTransformation";
import useIsLoggedIn from "../../../hooks/use-IsLoggedIn";
import resume_data from "../../../router/resumedata";
import messages from "../../../seed/messages";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import JobPayWall from "../../../Views/Jobs/JobPayWall";
import Resume from "../../../Views/ResumeBuilder/Review/Resume";
import Loader from "../../Loader";
import ShareJobs from "../../SocialShare/ShareJobs";
import JobScoreFit from "../JobScoreFit";

const JobDetails = ({ details, showUpSale, reSetShowUpSale }) => {
	let qualifications = [];
	let responsibilities = [];
	let daysSincePosted = "-";
	let deadline = "-";
	let lookingToHire = "-";
	const navigate = useNavigate();
	const isLoggedIn = useIsLoggedIn();
	const dispatch = useDispatch();
	const [hasFreeTrial, setHasFreeTrial] = useState(false);
	const [resumeList, setResumeList] = useState([]);
	const resumeDataService = new ResumeDataService();
	const [showLoginBox, setShowLoginBox] = useState(false);
	const [enablePaywall, setEnablePaywall] = useState(true);
	const [scoreFitDetails, setScoreFitDetails] = useState({});
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("ERROR");
	const [scoreFitUsedDate, setScoreFitUsedDate] = useState("-/-/-");
	const [showResumeBuilderBox, setShowResumeBuilderBox] = useState(false);
	const interactionsState = useSelector((state) => state.JobsInteractionReducers);
	const [selectedResume, setSelectedResume] = useState(interactionsState.resumeReferenceId);
	const [resumeData, setResumeData] = useState(resume_data);
	const [isReviewResumeLoaded, setIsReviewResumeLoaded] = useState(false);
	const [loadSpinner, setLoadSpinner] = useState(false);

	if (Object.keys(details).length !== 0 && Object.hasOwn(details, "jobDescription")) {
		responsibilities = details.jobDescription.responsibilities.map((responsibility, index) => (
			<li key={index} dangerouslySetInnerHTML={{ __html: responsibility.value }} />
		));

		qualifications = details.jobDescription.qualifications.map((qualification, index) => (
			<li key={index} dangerouslySetInnerHTML={{ __html: qualification.value }} />
		));

		lookingToHire = details.jobDescription.lookingToHire;

		daysSincePosted = calculateDateToToday(details.lastUpdatedAt);
		deadline = `${dateObjToYMD(details.deadlineDate)} (${calculateDayRemainsToDate(details.deadlineDate)})`;
	}

	function successCallBack(response) {
		const { interaction_status } = response.data;
		const jobDetails = {
			interactionStatus: interaction_status,
			resumeReferenceId: "application" in response.data ? response.data.application.resumeReferenceId : "",
		};
		dispatch({ type: "UPDATE_INTERACTIONS", payload: jobDetails });

		if (interaction_status === interactions.APPLIED) {
			toast.success(`${interaction_status} successfully`);
		}
	}

	function errorCallBack(error) {
		const message = error.response.data?.message !== undefined ? error.response.data?.message : "Something went wrong!";
		toast.error(message);
	}

	function setApplicationInteractions(resumeReferenceId, interactionType) {
		const interactionData =
			resumeReferenceId === null
				? {
						payload: { interactionType: interactionType },
						jobId: details._id,
					}
				: {
						payload: {
							interactionType: interactionType,
							jobapplication: {
								notes: [],
								resumeReferenceId: resumeReferenceId,
							},
						},
						jobId: details._id,
					};
		resumeDataService.setInteraction("jobs/", interactionData, successCallBack, errorCallBack);
	}

	function onSelectResume(event) {
		setSelectedResume(event.target.value);
		setPreviewResume(event.target.value);
		setApplicationInteractions(event.target.value, interactions.APPLYING);
	}

	function onSubmitApplication() {
		setApplicationInteractions(selectedResume, interactions.APPLIED);
	}

	function successFreeTrialCallBack(response) {
		if (!hasFreeTrial) {
			setHasFreeTrial(true);
			setScoreFitDetails(response.data);
		}
		setLoading(false);
	}

	function errorFreeTrialCallBack(error) {
		if (error.response.status === 429) {
			const date = formatAPIDate("M-D-Y-T", error.response.data.scored_at);
			setScoreFitUsedDate(date);
			setErrorMessage("EXHAUSTED_TRAILS");
		}

		if (error.response.status === 400) {
			setErrorMessage("MISSING_JOB_PREFERENCE");
		}

		setLoading(false);
	}

	function getFreeTrialsRecommendedJob() {
		reSetShowUpSale(false);
		setLoading(true);

		const requestParam = { jobReferenceId: details._id };
		resumeDataService.getFreeTrialsRecommendedJob(requestParam, successFreeTrialCallBack, errorFreeTrialCallBack);
	}

	//set the preview application resume
	function setPreviewResume(resumeId) {
		//set the loader
		setLoadSpinner(true);
		setIsReviewResumeLoaded(false);
		resumeDataService.getResumeLedgerById(resumeId, successPreviewApplication, errorPreviewApplication);
	}

	function successPreviewApplication(response) {
		const resumeObject = response.data;
		resumeObjectToContextFormat(resumeObject, setResumeData, dispatchAddExtras);

		//set the preview link to active
		setIsReviewResumeLoaded(true);

		//set the loader to false
		setLoadSpinner(false);
	}

	function errorPreviewApplication() {
		//error msg toaster
		setIsReviewResumeLoaded(false);
		toast.error("Resume for review could not be Fetched");

		//set the loader to false
		setLoadSpinner(false);
	}

	const dispatchAddExtras = (payload) => {
		dispatch({ type: "ADD_EXTRAS", payload: payload });
	};

	useEffect(() => {
		//Check if the user is Logged In
		if (isLoggedIn) {
			//Get the user resumeList.
			const _resumeList = JSON.parse(localStorage.getItem("resumeList"));

			//Check if the user has a resume.
			if (_resumeList !== null) {
				//Check if resume is completed.
				if (_resumeList[0].journal.currentStatus === "COMPLETED") {
					const resumeListOptions = _resumeList.map((resume) => {
						return {
							id: resume.id,
							resumeTitle: resume.journal.resumeTitle ? resume.journal.resumeTitle : resume.heading.resumeTitle,
						};
					});

					setResumeList(resumeListOptions);
					setEnablePaywall(false);
					return;
				}
			}

			//Tell the user to create resume.
			setShowLoginBox(false);
			setShowResumeBuilderBox(true);
			return;
		}

		//tell the user to log or sign in
		setShowLoginBox(true);
		setShowResumeBuilderBox(false);
	}, []);

	return (
		<>
			<div className="col-md-8">
				<div className="employee-dashboard-right">
					<div className="tab-content">
						{enablePaywall ? (
							<JobPayWall isLoggedOut={showLoginBox} isResumeNotCompleted={showResumeBuilderBox} />
						) : Object.keys(details).length === 0 ? (
							<div className="paywall">
								<h3 className="job-title" style={{ textAlign: "center" }}>
									<span>Select one of your jobs to View details.</span>
								</h3>
							</div>
						) : (
							<div role="tabpanel" className="tab-pane p-3 active" id="job-1">
								{/* Up Sale Modal*/}
								{showUpSale ? (
									<div className="up-sale">
										<div className="job-quick-info-col">
											<h3>Free Score Fit Trial.</h3>
											<hr style={{ marginTop: "20px", marginBottom: "40px" }} />
										</div>
										<p className="job-title" style={{ textAlign: "center" }}>
											<span>
												{messages("UP_SALE", {
													title: details.jobTitle,
													company: details.companyName,
												})}
											</span>
										</p>
										<div className="d-flex justify-content-between" style={{ marginTop: "20px", width: "35%" }}>
											<button className="btn btn-secondary text-uppercase" onClick={() => reSetShowUpSale(false)}>
												{" "}
												Close
											</button>
											<button
												className="btn btn-primary"
												data-toggle="modal"
												data-target="#scoreFitModal"
												onClick={() => getFreeTrialsRecommendedJob()}
											>
												{" "}
												See Score
											</button>
										</div>
									</div>
								) : null}

								{/*jobs status info*/}
								<div className="job-status-info">
									<div className="job-status-info-header">
										<div className="left">
											<img className="img-fluid company-logo" src="/images/icons/default_company_logo.svg" alt="" />
											<div className="details">
												<h4>
													{details.jobTitle}({details.numberOfOpening}{" "}
													{details.numberOfOpening > 1 ? "Positions" : "Position"})
												</h4>
												<p className="mb-0">
													<span className="px-1">{details.companyName}</span> |
													<span className="px-1">
														{`${details.jobLocation.city}, ${details.jobLocation.region}, ${details.jobLocation.country}`}
													</span>
												</p>
												<div className="text-muted fs-12 mb-0">
													<span className="px-1">Posted {daysSincePosted}</span> |
													<strong className="px-1 text-danger">Deadline {deadline}</strong>|
													<span className="px-1">{details.employmentType}</span>
													{details.salary.maxPay > 1 && (
														<span className="px-1">
															| Estimated Salary:
															{` ${details.salary.payCurrency} ${details.salary.maxPay}/${details.salary.payFrequency}`}
														</span>
													)}
												</div>
											</div>
										</div>
										<div className="">
											<ShareJobs
												applicationDeadline={`${dayjs(dateObjToYMD(details.deadlineDate)).diff(dayjs(), "day")} days`}
												title={details.jobTitle}
												company={details.companyName}
												linkToShare={window.location.origin + "/jobs?jobId=" + details._id}
												location={`${details.jobLocation.region}`}
											/>
										</div>
									</div>
								</div>
								{/*jobs status info ends*/}

								{/*middle part*/}
								{details.jobProcessingType === "SMART_APPLY_AND_SCREEN" ? (
									<div className="job-middle-part py-3 border-top">
										<div className="row align-items-center">
											<div className="col-md-6">
												<div className="pl-3 fs-12">
													{isReviewResumeLoaded ? (
														<p className="mb-1">
															<span
																className="pl-2 text-primary cursor-pointer"
																data-toggle="modal"
																data-target="#applicationResume"
															>
																Preview Resume
															</span>
														</p>
													) : (
														<p className="mb-1">
															<span className="pl-2" />
														</p>
													)}
													<div className="row">
														<div className="col-lg-9 mb-1">
															<span className="text-primary pr-2 cursor-pointer">
																<select
																	className="form-control"
																	name="resumeOptions"
																	value={selectedResume}
																	onChange={onSelectResume}
																>
																	<option value="" disabled={true}>
																		Select Resume
																	</option>
																	{resumeList.map((resume, index) => (
																		<option value={resume.id} key={index}>
																			{resume.resumeTitle}
																		</option>
																	))}
																</select>
															</span>
														</div>
														<div className="col-lg-3">
															{loadSpinner ? (
																<div className="left">
																	<div className="spinner-border" role="status">
																		<span className="sr-only">Loading</span>
																	</div>
																</div>
															) : null}
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="d-flex align-items-center justify-content-lg-end">
													<button
														type="button"
														className="btn btn-secondary ml-3"
														data-toggle="modal"
														data-target="#smartApplyModal"
														disabled={!isReviewResumeLoaded}
													>
														Smart Apply
													</button>
												</div>
											</div>
										</div>
									</div>
								) : null}
								{/*middle part*/}

								{/* Job Details*/}
								<div className="job-desc py-3 border-top">
									{/* Job Descriptions and Responsibilities*/}
									<h5 className="font-weight-bold">Job Purpose</h5>
									<div dangerouslySetInnerHTML={{ __html: lookingToHire }} />

									<h5 className="font-weight-bold mt-4">Responsibilities</h5>
									<ul className="ml-5"> {responsibilities} </ul>

									{/* Job Qualifications*/}
									<h5 className="font-weight-bold mt-4">
										Knowledge, Skills, Qualifications and Experiences Required for The Role
									</h5>
									<ul className="ml-5"> {qualifications} </ul>

									{/* Additional Information*/}
									{details.additionalInformation !== "" && (
										<>
											<h5 className="font-weight-bold mt-4">Additional Information</h5>
											<div dangerouslySetInnerHTML={{ __html: urlify(details?.additionalInformation || "") }} />
										</>
									)}

									{/* Company Info*/}
									<div className="widget company-info">
										<h5 className="widget_title mt-4">Company Info</h5>

										<span> {details.companyName} </span>
										<ul className="tr-list">
											<li>
												<span>Why work with Us: </span>
												{details.company.whyWorkAtCompany}
											</li>
											<li>
												<span>Phone:</span> {details.company.phoneNumber}{" "}
											</li>
											<li>
												<span>Website:</span>
												<a href={`https://${details.company.companyWebsite}`}>{details.company.companyWebsite}</a>
											</li>
											<li>
												<span />
											</li>
										</ul>
									</div>
								</div>
								{/*Job Details Ends*/}
								<ShareJobs
									applicationDeadline={`${dayjs(dateObjToYMD(details.deadlineDate)).diff(dayjs(), "day")} days`}
									title={details.jobTitle}
									company={details.companyName}
									linkToShare={window.location.origin + "/jobs?jobId=" + details._id}
									location={`${details.jobLocation.region}`}
								/>
							</div>
						)}
					</div>
				</div>
			</div>

			{/*Preview Modal*/}
			<div className="modal fade" id="applicationResume" tabIndex="-1" role="dialog" aria-hidden="true">
				<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header py-1 border-0">
							<button type="button" className="close text-danger" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body pb-5 resume-preview">
							<Resume resumeData={resumeData} />
						</div>
					</div>
				</div>
			</div>

			{/* Smart Apply Modal */}
			<div
				className="modal fade"
				id="smartApplyModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="smartApplyModalTitle"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title" id="smartApplyModal">
								Smart Apply
							</h3>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="p-3">
								<h5 className="mb-4">Screening Questions</h5>

								<div className="border p-3 rounded mb-3">
									<h6>1. Are you ready to relocate</h6>
									<div className="d-flex flex-wrap">
										<div className="custom-control custom-radio mr-5">
											<input
												type="radio"
												className="custom-control-input"
												id="customRadio1"
												name="relocation "
												value="Yes"
											/>
											<label className="custom-control-label" htmlFor="customRadio1">
												Yes
											</label>
										</div>
										<div className="custom-control custom-radio mr-5">
											<input
												type="radio"
												className="custom-control-input"
												id="customRadio2"
												name="relocation "
												value="No"
											/>
											<label className="custom-control-label" htmlFor="customRadio2">
												No
											</label>
										</div>
									</div>
								</div>
								<div className="border p-3 rounded mb-3">
									<h6>2. Which languages do you know ?</h6>
									<div className="d-flex flex-wrap">
										<div className="custom-control custom-checkbox mr-5">
											<input
												type="checkbox"
												className="custom-control-input"
												id="customCheck1"
												name="language "
												value="English"
											/>
											<label className="custom-control-label" htmlFor="customCheck1">
												English
											</label>
										</div>
										<div className="custom-control custom-checkbox mr-5">
											<input
												type="checkbox"
												className="custom-control-input"
												id="customCheck2"
												name="language "
												value="German"
											/>
											<label className="custom-control-label" htmlFor="customCheck2">
												German
											</label>
										</div>
										<div className="custom-control custom-checkbox mr-5">
											<input
												type="checkbox"
												className="custom-control-input"
												id="customCheck3"
												name="language "
												value="Spanish"
											/>
											<label className="custom-control-label" htmlFor="customCheck3">
												Spanish
											</label>
										</div>
									</div>
								</div>
								<div className="border p-3 rounded mb-3">
									<h6>3. Are you comfortable with the hike given </h6>
									<div className="d-flex flex-wrap">
										<div className="custom-control custom-radio mr-5">
											<input type="radio" className="custom-control-input" id="customRadio4" name="hike " value="Yes" />
											<label className="custom-control-label" htmlFor="customRadio4">
												Yes
											</label>
										</div>
										<div className="custom-control custom-radio mr-5">
											<input type="radio" className="custom-control-input" id="customRadio5" name="hike" value="No" />
											<label className="custom-control-label" htmlFor="customRadio5">
												No
											</label>
										</div>
									</div>
								</div>

								<div className="border-top mt-4 py-3">
									<div className="custom-control custom-checkbox mr-5">
										<input
											type="checkbox"
											className="custom-control-input"
											id="finalcheck"
											name="finalcheck "
											value="Yes"
										/>
										<label className="custom-control-label" htmlFor="finalcheck">
											By Clicking, You cannot edit after Submitting Application
										</label>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal">
								Cancel
							</button>
							<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={onSubmitApplication}>
								Submit
							</button>
						</div>
					</div>
				</div>
			</div>

			<div
				className="modal fade"
				id="scoreFitModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="scoreFitModalTitle"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{loading ? (
								<div className="" style={{ height: "200px" }}>
									<div className="d-flex p-4 align-center h-75 justify-content-center">
										<div className="position-relative" style={{ top: "35%" }}>
											<Loader />
										</div>
									</div>
								</div>
							) : hasFreeTrial ? (
								<>
									<div className="row p-3">
										<div className="col-9">
											<div className="d-flex">
												<div className="col d-flex flex-column justify-content-center">
													<h3 className="mb-0">Fit Scoring Overview</h3>
													<p>
														Dproz considers a lot of parameters inside 3 core areas of your job description. Check how
														you feature on these parameters.
													</p>
												</div>
											</div>
										</div>
										<div className="col-2">
											<span className="d-flex flex-end align-items-end">
												<h1
													className={`${
														scoreFitDetails.fit_score > 80
															? "bg-success"
															: scoreFitDetails.fit_score < 50
																? "bg-danger"
																: "bg-warning"
													} text-white`}
													style={{
														width: "80px",
														height: "80px",
														borderRadius: "50%",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
													}}
												>
													{scoreFitDetails.fit_score}
												</h1>
												<p className="fs-4"> /100</p>
											</span>
										</div>
									</div>
									<JobScoreFit details={scoreFitDetails} />
								</>
							) : (
								<div className="paywall">
									<div className="job-title">
										<h3>
											{errorMessage === "EXHAUSTED_TRAILS"
												? "Free trial Exhausted!"
												: errorMessage === "MISSING_JOB_PREFERENCE"
													? "Missing a Job-Preference!"
													: "Failed to load!"}
										</h3>
										<hr style={{ marginTop: "20px", marginBottom: "40px" }} />
									</div>
									<p className="job-quick-info-col" style={{ textAlign: "center" }}>
										<span>{messages(errorMessage, scoreFitUsedDate)}</span>
									</p>
									<div className="d-flex justify-content-between" style={{ marginTop: "20px", width: "35%" }}>
										{errorMessage === "EXHAUSTED_TRAILS" ? (
											<button
												className="btn btn-primary"
												data-dismiss="modal"
												onClick={() => navigate("/subscription")}
											>
												Subscribe
											</button>
										) : errorMessage === "MISSING_JOB_PREFERENCE" ? (
											<button
												className="btn btn-primary"
												data-dismiss="modal"
												onClick={() => navigate("/jobs-preference")}
											>
												Complete
											</button>
										) : null}
									</div>
								</div>
							)}
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal">
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default JobDetails;
