import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import { LevelsOfExperience } from "../../models/resume/template";

function SelectExperience() {
	const levelsObject = new LevelsOfExperience();
	const listOfExperience = levelsObject.listOfExperience;
	const dispatch = useDispatch();
	const template = useSelector((state) => state.TemplateSelectionReducer);
	const navigationFlow = template.navigationFlow;

	function onSelectionClick(levelName) {
		localStorage.setItem("yearsOfExperience", levelName);

		dispatch({
			type: "",
			payload: {
				selectedTemplate: {
					yearsOfExperience: levelName,
					templateName: "",
				},
				navigationFlow: navigationFlow,
			},
		});

		window.location.replace("/resume/select-template");
	}

	const [showTemplates, setShowTemplates] = useState(false);
	return (
		<>
			<section className="bg-white pt-5">
				<div className="bg-white resume-select-options-section">
					<div
						className="container"
						style={{
							minHeight: "40vh",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						}}
					>
						<div className="section">
							<div className="mb-5 text-meduim">
								<h2 className="tr-title-big mb-3">How long have you been working ?</h2>
								<p>
									We will find the best templates for your experience level.{" "}
									<span
										data-tooltip-id="my-nice-tooltip"
										data-tooltip-content="Click to learn More about our professionally crafted templates."
										className="cursor-pointer"
										onClick={() => setShowTemplates(true)}
									>
										Learn More <span className="text-primary bi bi-info-circle" />.
									</span>
								</p>
							</div>
							<div className="create-resume-experience-options mt-4 mb-4 px-4">
								<div className="row">
									{listOfExperience
										.filter((ex) => ex.showInList === true)
										.map((experience) => (
											<React.Fragment key={experience.name}>
												<div onClick={() => onSelectionClick(experience.name)} className="col-md-3 mb-4 mb-md-0">
													<Link
														to="/resume/select-template"
														className="d-block text-dark font-weight-semi w-100 text-center p-5  shadow select-experience-level"
													>
														{experience.name}
													</Link>
												</div>
											</React.Fragment>
										))}
								</div>
							</div>
						</div>
					</div>
					{showTemplates && (
						<div className="p-5">
							<div className="resume-career-path">
								<div className="container">
									<div className="text-center mb-md-5 mb-4">
										<h2 className="mb-3">Resume Templates for Every Career Path.</h2>
										<div className="row justify-content-center">
											<div className="col-md-10">
												<p>
													Choose from our professionally designed templates and build your effective resume instantly.
													Our templates are suitable for any professions and industries. Highlights key skills,
													qualifications, and present your credentials in a professionally appealing way. <br />
													Helping you to make a great first impression, even if you&#39;re new to resume writing!
												</p>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4 mb-4 mb-md-0">
											<div className="career-resume-box  ">
												<div className="img p-2 bg-white mb-4 rounded overflow-hidden">
													<img src="/images/resume-template/template-1.png" className="w-100 img-thumbnail" alt="" />
												</div>
												<h4>College Resume Template</h4>
												<p>No experience? No problem! We&#39;ll guide you.</p>
											</div>
										</div>
										<div className="col-md-4 mb-4 mb-md-0">
											<div className="career-resume-box  ">
												<div className="img p-2 bg-white mb-4 rounded overflow-hidden">
													<img src="/images/resume-template/template-9.png" className="w-100 img-thumbnail" alt="" />
												</div>
												<h4>Professional Resume Template</h4>
												<p>Communicate your value confidently!</p>
											</div>
										</div>
										<div className="col-md-4">
											<div className="career-resume-box  ">
												<div className="img p-2 bg-white mb-4 rounded overflow-hidden">
													<img src="/images/resume-template/template-10.png" className="w-100 img-thumbnail" alt="" />
												</div>
												<h4>Expertly Crafted Template</h4>
												<p>Say more with less!</p>
											</div>
										</div>
									</div>
									<div className="mt-4 text-center">
										<a href="/resume/select-template" className="btn btn-primary">
											Discover More Resume Templates
										</a>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</section>
			<Tooltip
				id="my-nice-tooltip"
				style={{
					zIndex: 1000,
					position: "fixed",
				}}
			/>
		</>
	);
}

export default SelectExperience;
