import { Heading, ResumeVersion } from "../models/resume/heading";
import { Resume } from "../models/resume/resume";

const vers = new ResumeVersion("12", "Our Candidate"); //resume-meta
const heading = new Heading("Our very own", "", "Candidate", "671-333-0012");
const resume = new Resume(vers, heading);

resume.setEducationList();

// resume.setEducationList()
// resume.addEducation(new Education(props));
// resume.addEducation(new Education(props));

// resume.addJob(new Education(props));
// resume.addJob(new Education(props));

export default resume;
