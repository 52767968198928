import "crypto-js/lib-typedarrays";

import React from "react";
import { Link } from "react-router-dom";

import SigninForm from "./SigninForm";

function SigninScreen() {
	return (
		<section className="auth-screen">
			<style>
				{`	html, body{
				max-height: 100vh;
				overflow: hidden;
			}`}
			</style>
			<div className="left hide-on-mobile">
				<div className="">
					<div
						style={{
							maxWidth: "70%",
						}}
					>
						<Link to="/">
							<img
								src="/images/logo.png"
								alt="DProz Logo"
								className="p-2 mb-4"
								style={{
									width: "180px",
								}}
							/>
						</Link>
						<div className="fs-16 mb-4">
							<p className="font-weight-semi">Here&#39;s Your Path to Career Success</p>
							<p>
								With Dproz, you’re in control of your success story. We enable you to reclaim your time, make a
								memorable impact, and accelerate your career.
							</p>
						</div>
						<div className="fs-16 mb-4">
							<p className="font-weight-semi">Let’s start by Creating your Resume.</p>
							<p>
								Finding the right career path begins with an exceptional resume and creating one has never been more
								convenient. With Dproz, you create, update, download and share your resume in an instant.
							</p>
						</div>
					</div>
				</div>
				<div className="">
					<div className="mockup">
						<img src="/images/mockup.png" alt="Resume Folder" />
					</div>
				</div>
			</div>
			<div className="right">
				<div className="">
					<div className="account-content">
						<div className="px-5">
							<Link
								to="/"
								style={{
									display: window.innerWidth > 768 ? "none" : "block",
								}}
							>
								<img
									src="/images/logo.png"
									alt="DProz Logo"
									className="p-2 mb-4"
									style={{
										width: "180px",
									}}
								/>
							</Link>
							<h1 className="m-0">
								Elevate Chances of Success <br />
								in Your Job Search
							</h1>
							<p className="text-medium mb-4">Create an account to get started.</p>
							<SigninForm />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default SigninScreen;
