const EducationSuggestions = () => {
	return [
		{
			title: "Relevant coursework: {Enter a list of subjects relevant to the job ou are applying for}",
			id: "001",
			added: false,
		},
		{
			title:
				"Relevant Experience: {Highlight any school related experience that is relevant to the role you are applying for}",
			id: "002",
			added: false,
		},
		{
			title:
				"Clubs and Memberships: {Name clubs and organizations you were a member of, eg: Sports and other extra curricular clubs}.",
			id: "003",
			added: false,
		},
		{
			title:
				"Leadership: {Position, Organization, Time Served}. {Highlight leadership positions you held and any related activities you managed or organized}",
			id: "004",
			added: false,
		},
		{
			title: "Awards: {Highlight scholarships and recognition awards you received}",
			id: "005",
			added: false,
		},
	];
};

export default EducationSuggestions;
