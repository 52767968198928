import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Loader from "../../../components/Loader";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import JobNavLayout from "../JobMarketLayout/JobMarketNavLayout/Layout";
import Preview from "../Preview";

function JobDetails() {
	const [job, setJob] = useState({});
	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState("");
	const { id } = useParams();

	const jobService = new ResumeDataService();

	function successCallback(data) {
		setJob(data.data);
		setLoading(false);
	}

	function errorCallback(err) {
		setErrors(err.response.data.message);
	}

	useEffect(() => {
		jobService.getJobById(id, successCallback, errorCallback);
	}, [id]);

	useEffect(() => {}, [job]);

	return (
		<JobNavLayout header={job.title}>
			<div className="my-resumes-section">
				<div className="container border rounded shadow">
					{loading ? (
						<Loader />
					) : (
						<>
							{errors ? (
								<div className="alert alert-danger">{errors}</div>
							) : (
								<div className="container mb-3">
									<Preview job={job} />
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</JobNavLayout>
	);
}

export default JobDetails;
