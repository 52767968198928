import React from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";

import { getSanitizedHtml } from "../../handlers/utilityFunctions";
import { isValidURL } from "../../handlers/validationFunctions";
import { ResumeDataService } from "../../services/create-resume/resume-data";
import Spinner from "../Spinner";
import InputField from "../UIComponents/InputField";

const candidateFormFields = {
	jobTitle: "Job Title",
	jobLink: "Job Link",
};

function ShareOpportunityModal({
	showShareOpportunityDialog,
	handleCloseShareOpportunityDialog,
	rewards,
	setRewards,
	setSuccess,
}) {
	const [candidate, setCandidate] = React.useState({
		jobCompanyReferenceId: "",
		companyName: "",
		jobTitle: "",
		jobLink: "",
	});
	const [candidateErrors, setCandidateErrors] = React.useState({
		jobCompanyReferenceId: "",
		jobTitle: "",
		jobLink: "",
		upload: "",
	});
	const jobService = new ResumeDataService();
	const [errors, setErrors] = React.useState("");
	const [jobTitleExist, setJobTitleExist] = React.useState(true);
	const [loading, setLoading] = React.useState(true);
	const [submitting, setSubmitting] = React.useState(false);
	const [companyName, setCompanyName] = React.useState("");
	const [companies, setCompanies] = React.useState([]);
	const [companyOptions, setCompanyOptions] = React.useState([]);

	function getCompanies(companyName) {
		setLoading(true);
		jobService.getCompanies(
			companyName,
			"",
			(response) => {
				setCompanies(response.data);
				setLoading(false);
			},
			(error) => {
				setLoading(false);
				setErrors((prev) => {
					return [...prev, ...error.response.data.message];
				});
			},
		);
	}

	const jobMarketCheckExistance = (id) => {
		setSubmitting(true);
		jobService.jobMarketCheckExistance(
			{
				companyReferenceId: id,
				jobTitle: candidate.jobTitle,
			},
			(data) => {
				setErrors("Job titled " + data.data.jobTitle + " for company " + data.data.company + " already shared.");
				setJobTitleExist(true);
				setSubmitting(false);
			},
			() => {
				setJobTitleExist(false);
				setSubmitting(false);
			},
		);
	};

	const createPaymentReward = () => {
		setSubmitting(true);
		setSuccess("");
		jobService.createPaymentReward(
			{
				activityType: "JOB_LEAD",
				jobCompanyReferenceId: candidate.jobCompanyReferenceId,
				jobTitle: candidate.jobTitle,
				jobLink: candidate.jobLink,
			},
			(data) => {
				setRewards([data.data, ...rewards]);
				setSuccess("Thanks for sharing job opportunity with the Dproz community. We'll review it and act accordingly");
				setSubmitting(false);
				handleCloseShareOpportunityDialog();
			},
			(e) => {
				const error = e.response.data.message;
				setErrors(error);
				toast.error(error);
				setSubmitting(false);
			},
		);
	};

	React.useEffect(() => {
		if (companyName.length > 2) {
			getCompanies(companyName);
		}
	}, [companyName]);

	React.useEffect(() => {
		if (companies.length > 0) {
			const data = companies.map((company) => {
				return {
					value: company._id,
					label: company.companyName,
				};
			});

			setCompanyOptions(data);
		}
	}, [companies]);

	React.useEffect(() => {
		let validationErrors = {
			jobCompanyReferenceId: "",
			jobTitle: "",
			jobLink: "",
		};

		if (candidate.jobCompanyReferenceId === "") {
			validationErrors.jobCompanyReferenceId = "Company is required";
		}
		if (candidate.jobTitle.length < 5) {
			validationErrors.jobTitle = candidate.jobTitle === "" ? "Job Title is required" : "Provide Valid Job Title";
		}
		if (!isValidURL(candidate.jobLink)) {
			validationErrors.jobLink = candidate.jobLink === "" ? "Job Link is required" : "Provide Valid Job Link";
		}

		setCandidateErrors((prev) => {
			return {
				...prev,
				...validationErrors,
			};
		});
	}, [candidate]);

	return (
		<Modal
			show={showShareOpportunityDialog}
			onHide={handleCloseShareOpportunityDialog}
			centered
			size="lg"
			backdrop="static"
		>
			<Modal.Header>
				<Modal.Title>Share Opportunity</Modal.Title>
				<button
					type="button"
					className="btn btn-secondary text-center"
					data-dismiss="modal"
					aria-label="Close"
					onClick={handleCloseShareOpportunityDialog}
				>
					<i className="fa fa-close ml-2" />
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					{Object.entries(candidateFormFields).map(([key, value]) => {
						if (key === "jobLink" && jobTitleExist) {
							return null;
						}
						return (
							<div key={key} className={`col-xl-12 mb-2 ${key === "jobTitle" ? "order-1" : "order-3"}`}>
								<label htmlFor="" className="form-label mx-2">
									{key === "jobTitle"
										? "What's the job title you want to share with the community?"
										: "What's the link to learn more about the job description for this title?"}
								</label>
								<div className="form-group">
									<InputField
										type="text"
										className="form-control"
										placeholder={value}
										label={value}
										value={candidate?.[key]}
										required
										onChange={(event) => {
											if (key === "jobTitle") {
												setJobTitleExist(true);
												setCandidate({
													...candidate,
													jobCompanyReferenceId: "",
												});
												setErrors("");
											}
											setCandidate((prevState) => {
												return {
													...prevState,
													[key]: event.target.value,
												};
											});
										}}
										confirmedStatus={(candidateErrors?.[key] || "") === ""}
										error={candidateErrors?.[key] || ""}
									/>
								</div>
							</div>
						);
					})}
					<div className="col-12 mb-3 mx-2 order-2">
						<label htmlFor="jobCompanyReferenceId" className="form-label">
							Which company is hiring for this job title?
						</label>
						{candidate.jobCompanyReferenceId === "" ? (
							<Select
								className="basic-single "
								classNamePrefix="select"
								isLoading={loading}
								isSearchable={true}
								name="jobCompanyReferenceId"
								onInputChange={(e) => setCompanyName(e)}
								placeholder="Type to Select Company"
								options={companyOptions}
								onChange={(e) => {
									setCandidate({
										...candidate,
										jobCompanyReferenceId: e.value,
										companyName: e.label,
									});
									jobMarketCheckExistance(e.value);
								}}
							/>
						) : (
							<select
								className={`${
									!(candidate.jobCompanyReferenceId.length > 0) && false && "select-box-not-selected-error"
								} form-select form-control `}
								value={candidate.jobCompanyReferenceId}
								onChange={(e) => {
									setCandidate({
										...candidate,
										jobCompanyReferenceId: e.target.value,
									});
									setJobTitleExist(true);
								}}
							>
								<option value="">Change Company</option>
								<option value={candidate.jobCompanyReferenceId}>{candidate.companyName}</option>
							</select>
						)}
						{candidateErrors.jobCompanyReferenceId && (
							<div className="error">{getSanitizedHtml(candidateErrors.jobCompanyReferenceId)}</div>
						)}
					</div>
				</div>
				{errors && (
					<div className="alert alert-danger" role="alert">
						{errors}
					</div>
				)}
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex justify-content-end">
					<button
						type="button"
						className="btn btn-secondary mx-2"
						data-dismiss="modal"
						onClick={handleCloseShareOpportunityDialog}
					>
						Cancel
					</button>

					<button
						type="button"
						className="btn btn-primary"
						onClick={() => {
							createPaymentReward();
						}}
						disabled={
							!(
								candidateErrors.jobCompanyReferenceId === "" &&
								candidateErrors.jobTitle === "" &&
								candidateErrors.jobLink === ""
							) || submitting
						}
					>
						{submitting ? (
							<>
								Loading <Spinner />
							</>
						) : (
							"Share Opportunity"
						)}
					</button>
				</div>
			</Modal.Footer>
		</Modal>
	);
}

export default ShareOpportunityModal;
