import React from "react";
import { Link } from "react-router-dom";

import ResumeReviewsHeader from "../../../components/Header/ResumeReviewsHeader";

function ResumeReviewDashboard() {
	return (
		<>
			<ResumeReviewsHeader />
			<div className="my-resumes-section reviewer-dashboard py-5">
				<div className="container">
					<nav className="custom-tabs">
						<div className="nav nav-tabs" id="nav-tab" role="tablist">
							<button
								className="nav-link active"
								id="nav-toReview-tab"
								data-toggle="tab"
								data-target="#ToReview"
								type="button"
								role="tab"
								aria-controls="ToReview"
								aria-selected="true"
							>
								To Review (10)
							</button>
							<button
								className="nav-link"
								id="nav-new-tab"
								data-toggle="tab"
								data-target="#NewResume"
								type="button"
								role="tab"
								aria-controls="NewResume"
								aria-selected="false"
							>
								New(20)
							</button>
							<button
								className="nav-link"
								id="nav-in-progress-tab"
								data-toggle="tab"
								data-target="#InProgress"
								type="button"
								role="tab"
								aria-controls="InProgress"
								aria-selected="false"
							>
								In Progress(5)
							</button>
							<button
								className="nav-link"
								id="nav-completed-tab"
								data-toggle="tab"
								data-target="#CompletedResume"
								type="button"
								role="tab"
								aria-controls="CompletedResume"
								aria-selected="false"
							>
								Completed(5)
							</button>
						</div>
					</nav>
					<div className="tab-content" id="nav-tabContent">
						<div className="tab-pane fade show active" id="ToReview" role="tabpanel" aria-labelledby="nav-toReview-tab">
							<div className="created-resumes-list mt-4">
								<div className="created-resume p-4 font-weight-bold text-dark">
									<div className="row px-n1">
										<div className="col-3 px-1">
											<p className="mb-0 font-weight-bold">Name</p>
										</div>
										<div className="col-2 px-1">
											<p className=" px-2 mb-0">Modified</p>
										</div>
										<div className="col-2 px-1">
											<p className=" px-2 mb-0">Created</p>
										</div>
										<div className="col-2 px-1">
											<p className="mb-0">In Progress</p>
										</div>
										<div className="col-3 px-1 text-center">
											<p className="mb-0">Actions</p>
										</div>
									</div>
								</div>

								<div className="created-resume bg-white p-3 px-4 border mb-3">
									<div className="row px-n1 align-items-center">
										<div className="col-3 px-1">
											<p className="mb-0 font-weight-bold">JacquilineKhalfanResume</p>
										</div>
										<div className="col-2 px-1">
											<p className="text-muted px-2 mb-0">Modified: 1/11/2022</p>
										</div>
										<div className="col-2 px-1">
											<p className="text-muted px-2 mb-0">Created: 1/11/2022</p>
										</div>
										<div className="col-2 px-1">
											<span className="ml-1 py-1 px-3 rounded-pill text-white bg-primary fs-12">82%</span>
										</div>
										<div className="col-3 px-1">
											<div className="d-flex justify-content-md-end">
												<Link
													to="/expert-review"
													className="text-medium d-flex mb-0 px-2 flex-column  justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 mb-2 fa fa-eye" />
													<span>View</span>
												</Link>
												<p
													data-toggle="modal"
													data-target="#AcceptReviewModal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 fa mb-2 fa-check-circle" />
													<span>Accept</span>
												</p>
												<p
													data-toggle="modal"
													data-target="#RejectReviewModal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 fa mb-2 fa-times-circle" />
													<span>Reject</span>
												</p>
											</div>
										</div>
									</div>
								</div>

								<div className="created-resume bg-white p-3 px-4 border mb-3">
									<div className="row px-n1 align-items-center">
										<div className="col-3 px-1">
											<p className="mb-0 font-weight-bold">JacquilineKhalfanResume</p>
										</div>
										<div className="col-2 px-1">
											<p className="text-muted px-2 mb-0">Modified: 1/11/2022</p>
										</div>
										<div className="col-2 px-1">
											<p className="text-muted px-2 mb-0">Created: 1/11/2022</p>
										</div>
										<div className="col-2 px-1">
											<span className="ml-1 py-1 px-3 rounded-pill text-white bg-primary fs-12">82%</span>
										</div>
										<div className="col-3 px-1">
											<div className="d-flex justify-content-md-end">
												<Link
													to="/expert-review"
													className="text-medium d-flex mb-0 px-2 flex-column  justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 mb-2 fa fa-eye" />
													<span>View</span>
												</Link>
												<p
													data-toggle="modal"
													data-target="#AcceptReviewModal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 fa mb-2 fa-check-circle" />
													<span>Accept</span>
												</p>
												<p
													data-toggle="modal"
													data-target="#RejectReviewModal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 fa mb-2 fa-times-circle" />
													<span>Reject</span>
												</p>
											</div>
										</div>
									</div>
								</div>

								<div className="created-resume bg-white p-3 px-4 border mb-3">
									<div className="row px-n1 align-items-center">
										<div className="col-3 px-1">
											<p className="mb-0 font-weight-bold">JacquilineKhalfanResume</p>
										</div>
										<div className="col-2 px-1">
											<p className="text-muted px-2 mb-0">Modified: 1/11/2022</p>
										</div>
										<div className="col-2 px-1">
											<p className="text-muted px-2 mb-0">Created: 1/11/2022</p>
										</div>
										<div className="col-2 px-1">
											<span className="ml-1 py-1 px-3 rounded-pill text-white bg-primary fs-12">82%</span>
										</div>
										<div className="col-3 px-1">
											<div className="d-flex justify-content-md-end">
												<Link
													to="/expert-review"
													className="text-medium d-flex mb-0 px-2 flex-column  justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 mb-2 fa fa-eye" />
													<span>View</span>
												</Link>
												<p
													data-toggle="modal"
													data-target="#AcceptReviewModal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 fa mb-2 fa-check-circle" />
													<span>Accept</span>
												</p>
												<p
													data-toggle="modal"
													data-target="#RejectReviewModal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 fa mb-2 fa-times-circle" />
													<span>Reject</span>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="NewResume" role="tabpanel" aria-labelledby="nav-new-tab">
							<div className="created-resumes-list mt-4">
								<div className="created-resume p-4 font-weight-bold text-dark">
									<div className="row px-n1">
										<div className="col-3 px-1">
											<p className="mb-0 font-weight-bold">Name</p>
										</div>
										<div className="col-2 px-1">
											<p className=" px-2 mb-0">Modified</p>
										</div>
										<div className="col-2 px-1">
											<p className=" px-2 mb-0">Created</p>
										</div>
										<div className="col-2 px-1">
											<p className="mb-0">In Progress</p>
										</div>
										<div className="col-3 px-1 text-center">
											<p className="mb-0">Actions</p>
										</div>
									</div>
								</div>

								<div className="created-resume bg-white p-3 px-4 border mb-3">
									<div className="row px-n1 align-items-center">
										<div className="col-3 px-1">
											<p className="mb-0 font-weight-bold">JacquilineKhalfanResume</p>
										</div>
										<div className="col-2 px-1">
											<p className="text-muted px-2 mb-0">Modified: 1/11/2022</p>
										</div>
										<div className="col-2 px-1">
											<p className="text-muted px-2 mb-0">Created: 1/11/2022</p>
										</div>
										<div className="col-2 px-1">
											<span className="ml-1 py-1 px-3 rounded-pill text-white bg-primary fs-12">82%</span>
										</div>
										<div className="col-3 px-1">
											<div className="d-flex justify-content-md-end">
												<a
													href="review-resume "
													className="text-medium d-flex mb-0 px-2 flex-column  justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 mb-2 fa fa-eye" />
													<span>View</span>
												</a>
												<p
													data-toggle="modal"
													data-target="#AcceptReviewModal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 fa mb-2 fa-check-circle" />
													<span>Accept</span>
												</p>
												<p
													data-toggle="modal"
													data-target="#RejectReviewModal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 fa mb-2 fa-times-circle" />
													<span>Reject</span>
												</p>
											</div>
										</div>
									</div>
								</div>

								<div className="created-resume bg-white p-3 px-4 border mb-3">
									<div className="row px-n1 align-items-center">
										<div className="col-3 px-1">
											<p className="mb-0 font-weight-bold">JacquilineKhalfanResume</p>
										</div>
										<div className="col-2 px-1">
											<p className="text-muted px-2 mb-0">Modified: 1/11/2022</p>
										</div>
										<div className="col-2 px-1">
											<p className="text-muted px-2 mb-0">Created: 1/11/2022</p>
										</div>
										<div className="col-2 px-1">
											<span className="ml-1 py-1 px-3 rounded-pill text-white bg-primary fs-12">82%</span>
										</div>
										<div className="col-3 px-1">
											<div className="d-flex justify-content-md-end">
												<a
													href="review-resume "
													className="text-medium d-flex mb-0 px-2 flex-column  justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 mb-2 fa fa-eye" />
													<span>View</span>
												</a>
												<p
													data-toggle="modal"
													data-target="#AcceptReviewModal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 fa mb-2 fa-check-circle" />
													<span>Accept</span>
												</p>
												<p
													data-toggle="modal"
													data-target="#RejectReviewModal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 fa mb-2 fa-times-circle" />
													<span>Reject</span>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="InProgress" role="tabpanel" aria-labelledby="nav-in-progress-tab">
							<div className="created-resumes-list mt-4">
								<div className="created-resume p-4 font-weight-bold text-dark">
									<div className="row px-n1">
										<div className="col-3 px-1">
											<p className="mb-0 font-weight-bold">Name</p>
										</div>
										<div className="col-2 px-1">
											<p className=" px-2 mb-0">Modified</p>
										</div>
										<div className="col-2 px-1">
											<p className=" px-2 mb-0">Created</p>
										</div>
										<div className="col-2 px-1">
											<p className="mb-0">In Progress</p>
										</div>
										<div className="col-3 px-1 text-center">
											<p className="mb-0">Actions</p>
										</div>
									</div>
								</div>

								<div className="created-resume bg-white p-3 px-4 border mb-3">
									<div className="row px-n1 align-items-center">
										<div className="col-3 px-1">
											<p className="mb-0 font-weight-bold">JacquilineKhalfanResume</p>
										</div>
										<div className="col-2 px-1">
											<p className="text-muted px-2 mb-0">Modified: 1/11/2022</p>
										</div>
										<div className="col-2 px-1">
											<p className="text-muted px-2 mb-0">Created: 1/11/2022</p>
										</div>
										<div className="col-2 px-1">
											<span className="ml-1 py-1 px-3 rounded-pill text-white bg-primary fs-12">82%</span>
										</div>
										<div className="col-3 px-1">
											<div className="d-flex justify-content-md-end">
												<a
													href="review-resume "
													className="text-medium d-flex mb-0 px-2 flex-column  justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 mb-2 fa fa-eye" />
													<span>View</span>
												</a>
												<p
													data-toggle="modal"
													data-target="#AcceptReviewModal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 fa mb-2 fa-check-circle" />
													<span>Accept</span>
												</p>
												<p
													data-toggle="modal"
													data-target="#RejectReviewModal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 fa mb-2 fa-times-circle" />
													<span>Reject</span>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="CompletedResume" role="tabpanel" aria-labelledby="nav-completed-tab">
							<div className="created-resumes-list mt-4">
								<div className="created-resume p-4 font-weight-bold text-dark">
									<div className="row px-n1">
										<div className="col-3 px-1">
											<p className="mb-0 font-weight-bold">Name</p>
										</div>
										<div className="col-2 px-1">
											<p className=" px-2 mb-0">Modified</p>
										</div>
										<div className="col-2 px-1">
											<p className=" px-2 mb-0">Created</p>
										</div>
										<div className="col-2 px-1">
											<p className="mb-0">In Progress</p>
										</div>
										<div className="col-3 px-1 text-center">
											<p className="mb-0">Actions</p>
										</div>
									</div>
								</div>

								<div className="created-resume bg-white p-3 px-4 border mb-3">
									<div className="row px-n1 align-items-center">
										<div className="col-3 px-1">
											<p className="mb-0 font-weight-bold">JacquilineKhalfanResume</p>
										</div>
										<div className="col-2 px-1">
											<p className="text-muted px-2 mb-0">Modified: 1/11/2022</p>
										</div>
										<div className="col-2 px-1">
											<p className="text-muted px-2 mb-0">Created: 1/11/2022</p>
										</div>
										<div className="col-2 px-1">
											<span className="ml-1 py-1 px-3 rounded-pill text-white bg-primary fs-12">82%</span>
										</div>
										<div className="col-3 px-1">
											<div className="d-flex justify-content-md-end">
												<a
													href="review-resume "
													className="text-medium d-flex mb-0 px-2 flex-column  justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 mb-2 fa fa-eye" />
													<span>View</span>
												</a>
												<p
													data-toggle="modal"
													data-target="#AcceptReviewModal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 fa mb-2 fa-check-circle" />
													<span>Accept</span>
												</p>
												<p
													data-toggle="modal"
													data-target="#RejectReviewModal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 fa mb-2 fa-times-circle" />
													<span>Reject</span>
												</p>
											</div>
										</div>
									</div>
								</div>

								<div className="created-resume bg-white p-3 px-4 border mb-3">
									<div className="row px-n1 align-items-center">
										<div className="col-3 px-1">
											<p className="mb-0 font-weight-bold">JacquilineKhalfanResume</p>
										</div>
										<div className="col-2 px-1">
											<p className="text-muted px-2 mb-0">Modified: 1/11/2022</p>
										</div>
										<div className="col-2 px-1">
											<p className="text-muted px-2 mb-0">Created: 1/11/2022</p>
										</div>
										<div className="col-2 px-1">
											<span className="ml-1 py-1 px-3 rounded-pill text-white bg-primary fs-12">82%</span>
										</div>
										<div className="col-3 px-1">
											<div className="d-flex justify-content-md-end">
												<a
													href="review-resume "
													className="text-medium d-flex mb-0 px-2 flex-column  justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 mb-2 fa fa-eye" />
													<span>View</span>
												</a>
												<p
													data-toggle="modal"
													data-target="#AcceptReviewModal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 fa mb-2 fa-check-circle" />
													<span>Accept</span>
												</p>
												<p
													data-toggle="modal"
													data-target="#RejectReviewModal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 fa mb-2 fa-times-circle" />
													<span>Reject</span>
												</p>
											</div>
										</div>
									</div>
								</div>

								<div className="created-resume bg-white p-3 px-4 border mb-3">
									<div className="row px-n1 align-items-center">
										<div className="col-3 px-1">
											<p className="mb-0 font-weight-bold">JacquilineKhalfanResume</p>
										</div>
										<div className="col-2 px-1">
											<p className="text-muted px-2 mb-0">Modified: 1/11/2022</p>
										</div>
										<div className="col-2 px-1">
											<p className="text-muted px-2 mb-0">Created: 1/11/2022</p>
										</div>
										<div className="col-2 px-1">
											<span className="ml-1 py-1 px-3 rounded-pill text-white bg-primary fs-12">82%</span>
										</div>
										<div className="col-3 px-1">
											<div className="d-flex justify-content-md-end">
												<a
													href="review-resume "
													className="text-medium d-flex mb-0 px-2 flex-column  justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 mb-2 fa fa-eye" />
													<span>View</span>
												</a>
												<p
													data-toggle="modal"
													data-target="#AcceptReviewModal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 fa mb-2 fa-check-circle" />
													<span>Accept</span>
												</p>
												<p
													data-toggle="modal"
													data-target="#RejectReviewModal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span className="text-primary fs-20 fa mb-2 fa-times-circle" />
													<span>Reject</span>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					className="modal fade"
					id="RejectReviewModal"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="DownloadResumeModal "
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 400 + "px" }}>
						<div className="modal-content px-4">
							<div className="modal-header border-bottom-0">
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>

							<div className="modal-body text-center py-0">
								<div className="icon mb-3">
									<img src="images/icons/information.png" alt="" style={{ maxWidth: 100 + "px" }} />
								</div>
								<h4 className="mb-3 text-dark ">Are you sure you want to reject this Resume ?</h4>
								<div className="form-group mb-2">
									<p>This action cannot be undone </p>
								</div>
							</div>
							<div className="modal-footer border-top-0 d-flex justify-content-between ">
								<button type="button" className="btn btn-dark flex-grow-1" data-dismiss="modal">
									Cancel
								</button>
								<button type="button" className="btn btn-primary flex-grow-1">
									Reject
								</button>
							</div>
						</div>
					</div>
				</div>

				<div
					className="modal fade"
					id="AcceptReviewModal"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="DownloadResumeModal "
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 400 + "px" }}>
						<div className="modal-content px-4">
							<div className="modal-header border-bottom-0">
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>

							<div className="modal-body text-center py-0">
								<div className="icon mb-3">
									<img src="images/icons/information.png" alt="" style={{ maxWidth: 100 + "px" }} />
								</div>
								<h4 className="mb-3 text-dark ">Are you sure you want to Accept this Resume ?</h4>
								<div className="form-group mb-2">
									<p>This action cannot be undone </p>
								</div>
							</div>
							<div className="modal-footer border-top-0 d-flex justify-content-between ">
								<button type="button" className="btn btn-dark flex-grow-1" data-dismiss="modal">
									Cancel
								</button>
								<button type="button" className="btn btn-primary flex-grow-1">
									Accept
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ResumeReviewDashboard;
