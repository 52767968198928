import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import sectionRoutes from "../../../seed/sectionRoutes";

const FeedBack = ({ feedback, sectionNavigate, navigateToSections }) => {
	const useReviewFeedback = useSelector((state) => state.UseReviewFeedbackReducer);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isMoreExpanded, setIsMoreExpanded] = useState(false);
	const [isLessExpanded, setIsLessExpanded] = useState(true);
	const lessContentRef = useRef(null);
	const moreContentRef = useRef(null);
	const toggleExpansion = () => {
		setIsMoreExpanded((prev) => !prev);
		setIsLessExpanded((prev) => !prev);

		// Get the actual content height and set max-height accordingly
		const lessContentElement = lessContentRef.current;
		const moreContentElement = moreContentRef.current;

		if (lessContentElement && moreContentElement) {
			const lessHeight = isLessExpanded ? 0 : lessContentElement.scrollHeight + "px";
			const moreHeight = isMoreExpanded ? 0 : moreContentElement.scrollHeight + "px";
			lessContentElement.style.maxHeight = lessHeight;
			moreContentElement.style.maxHeight = moreHeight;
		}
	};

	const handleSectionNavigation = () => {
		navigate(sectionRoutes(feedback.section));
	};

	return (
		<div className="review-item">
			{navigateToSections ? (
				<div
					className="cursor-pointer"
					onClick={() => {
						sectionNavigate(feedback.section);
						dispatch({
							type: "USE_REVIEW_FEEDBACK",
							payload: {
								...useReviewFeedback,
								section: { ...useReviewFeedback.section, section: feedback.section },
							},
						});
					}}
				>
					<i
						className={`
                bi bi-info-square-fill fs-16
                ${
									feedback.review.rating === "STRONG"
										? "text-success"
										: feedback.review.rating === "MODERATE"
											? "text-warning"
											: "text-danger"
								}
                `}
					/>
				</div>
			) : (
				<div className="cursor-pointer">
					<i
						className={`
                bi bi-info-square-fill fs-16
                ${
									feedback.review.rating === "STRONG"
										? "text-success"
										: feedback.review.rating === "MODERATE"
											? "text-warning"
											: "text-danger"
								}
                `}
					/>
				</div>
			)}

			<div className="">
				{navigateToSections ? (
					<p className="font-weight-semi cursor-pointer" onClick={() => sectionNavigate(feedback.section)}>
						{`Improve ${feedback.section.toLowerCase()}`}
					</p>
				) : (
					<p className="font-weight-semi cursor-pointer">{`Improve ${feedback.section.toLowerCase()}`}</p>
				)}
				<p
					ref={lessContentRef}
					className={`${isLessExpanded ? "expanded-feedback" : ""} feedback-content text-secondary mb-2`}
				>
					{`${feedback?.review?.reasonsForRating[0]?.substring(0, 87) || ""}...`}
					<span className="text-primary font-weight-semi cursor-pointer" onClick={() => toggleExpansion()}>
						more
					</span>
				</p>

				<div
					ref={moreContentRef}
					className={`${isMoreExpanded ? "expanded-feedback" : ""} feedback-content text-secondary mb-2`}
				>
					<p className="font-medium">Reasons for Rating</p>
					<ul>
						{feedback.review.reasonsForRating.map((reason, index) => {
							return (
								<li key={index} style={{ fontSize: "14px" }}>
									{reason}
								</li>
							);
						})}
					</ul>
					<p className="font-medium">Recommendations</p>
					<ul>
						{feedback.review.recommendations.map((recommendation, index) => {
							return (
								<li key={index} style={{ fontSize: "14px" }}>
									{recommendation}
								</li>
							);
						})}
					</ul>
					<span className="text-primary font-weight-semi cursor-pointer" onClick={() => toggleExpansion()}>
						...less
					</span>
				</div>

				{navigateToSections ? (
					<div className="d-flex justify-content-between align-items-center flex-wrap">
						<button
							className="border border-secondary shadow-sm bg-transparent font-medium fs-14 lh-1 rounded-sm font-inter"
							onClick={() => handleSectionNavigation()}
						>
							Make Changes
						</button>
						<p className="text-secondary m-0 fs-12">{feedback?.reviewerDetail?.reviewerEmail}</p>
					</div>
				) : (
					<div className="d-flex justify-content-end align-items-center">
						<p className="text-secondary m-0 fs-12">{feedback?.reviewerDetail?.reviewerEmail}</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default FeedBack;
