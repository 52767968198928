import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Loader from "../../../components/Loader";
import StatusToast from "../../../components/StatusToast";
import useSetResumeIdCookie from "../../../hooks/use-SetResumeIdCookie";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import ResumeReviewModal from "../ResumeListItem/ResumeReviewModal";

function ReviewsList({ setShowReviews, resumeList, userData }) {
	const navigate = useNavigate();
	const resumeDataService = new ResumeDataService();
	const { setResumeIdCookie } = useSetResumeIdCookie();
	const [reviews, setReviews] = useState([]);
	const [loading, setLoading] = useState(true);

	const [errors, setErrors] = useState([]);

	function successGetResumeReviews(data) {
		setLoading(false);
		localStorage.removeItem("reviewsList");
		localStorage.setItem("reviewsList", JSON.stringify(data.data));
		setReviewData(data.data);
	}

	function errorGetResumeReviews(data) {
		setErrors(data.response.data.message);
		setLoading(false);
	}

	function getResumeName(resumeId) {
		const resume = resumeList.find((resume) => resume._id === resumeId);
		return resume.heading.resumeTitle || "Untitled Resume";
	}

	function fetchReviews() {
		setErrors([]);
		setLoading(true);
		resumeDataService.getResumeReview(successGetResumeReviews, errorGetResumeReviews);
	}

	function setReviewData(data) {
		setReviews(data);
	}

	useEffect(() => {
		if (localStorage.getItem("reviewsList")) {
			const _data = JSON.parse(localStorage.getItem("reviewsList"));
			setReviewData(_data);
			setLoading(false);
		} else {
			fetchReviews();
		}
	}, []);

	useEffect(() => {}, [reviews]);

	const [showReviewModal, setShowReviewModal] = useState({
		show: false,
		review: null,
	});
	const [successMessage, setSuccessMessage] = useState();

	function successDeleteResumeReview() {
		setSuccessMessage("Review deleted successfully");
		toast.success("Review deleted successfully");
		fetchReviews();
	}

	function errorDeleteResumeReview(data) {
		setErrors(data.response.data.message);
		toast.error(data.response.data.message);
	}

	function deleteResumeReview(id) {
		setErrors([]);
		resumeDataService.deleteResumeReview(id, successDeleteResumeReview, errorDeleteResumeReview);
	}

	const [userReviewers, setUserReviewers] = useState([]);
	function successCallBack(data) {
		setUserReviewers(data.data);
	}

	function errorCallBack(data) {
		setErrors(data.response.data.message);
	}

	function fetchReviewers() {
		resumeDataService.getReviewers(successCallBack, errorCallBack);
	}

	useEffect(() => {
		fetchReviewers();
	}, []);

	function getReviewerName(email) {
		const reviewer = userReviewers.find((reviewer) => reviewer.emailAddress === email);
		return reviewer && reviewer.fullName.split(" ")[0];
	}

	function getReviewerPhone(email) {
		const reviewer = userReviewers.find((reviewer) => reviewer.emailAddress === email);
		const phoneNumber = reviewer && reviewer.phoneNumber;

		if (phoneNumber && phoneNumber.startsWith("0")) {
			return phoneNumber.replace("0", "255");
		}
		return phoneNumber;
	}

	function getMessage(reviewerEmail, hasStarted, daysLeft) {
		const { firstName } = userData;

		if (daysLeft < 0) {
			return;
		}

		if (hasStarted) {
			return `Hello ${getReviewerName(
				reviewerEmail,
			)}, this is a quick reminder to complete the review for my resume, I sent a request to your email ${reviewerEmail}, the subject is "Value Your Feedback!". I would appreciate it if you could complete the review as soon as possible, the link expires in ${daysLeft} days. Thanks you! ${firstName}`;
		}
		return `Hello ${getReviewerName(
			reviewerEmail,
		)}, I would appreciate it if you could review my resume. I sent a request to your email ${reviewerEmail}, the subject is "Value Your Feedback!" and it's valid for ${daysLeft} days. Thanks you! ${firstName}`;
	}

	function reviewDetails(reviewerReviewersDetail, review) {
		const { lastUpdatedOn } = review;

		const expireDate = dayjs(lastUpdatedOn).add(14, "day").format("YYYY-MM-DD");
		const today = dayjs().format("YYYY-MM-DD");
		const daysLeft = dayjs(expireDate).diff(today, "day");

		return (
			<div className="card card-body bg-white">
				{reviewerReviewersDetail.map((reviewer, index) => (
					<div
						key={reviewer._id}
						style={{
							borderBottom: index === reviewerReviewersDetail.length - 1 ? "none" : "1px solid #f1f1f1",
							paddingBottom: index === reviewerReviewersDetail.length - 1 ? "0" : "10px",
							paddingTop: index === 0 ? "0" : "10px",
						}}
					>
						<h6 className="m-0">
							{getReviewerName(reviewer.reviewerEmail)} - {reviewer.reviewerEmail}
						</h6>

						<p className="m-0">
							Status: {daysLeft < 0 && reviewer.reviewLog.length === 0 ? "Expired" : reviewer.reviewerStatus}
						</p>
						{daysLeft < 0 ? (
							<p className="my-1 badge text-white bg-danger">Review period has expired</p>
						) : (
							<p className="my-1">
								Progress Activity:{" "}
								<a
									href={
										"https://wa.me/" +
										getReviewerPhone(reviewer.reviewerEmail) +
										"?text=" +
										getMessage(reviewer.reviewerEmail, reviewer.reviewLog.length > 0, daysLeft)
									}
									target="_blank"
									rel="noreferrer"
								>
									<i className="text-success fa-brands fa-whatsapp" /> Remind {getReviewerName(reviewer.reviewerEmail)}
								</a>
							</p>
						)}
						{reviewer.reviewLog.length > 0 ? (
							<ul>
								{reviewer.reviewLog.map((review, i) => (
									<li key={i}>
										{dayjs(review.updatedAt).format("DD MMM YYYY")} - {review.section} - {review.status}
									</li>
								))}
							</ul>
						) : (
							<p>{daysLeft < 0 ? "Review period has expired, create a new review request" : "Not Started yet"}</p>
						)}
					</div>
				))}
			</div>
		);
	}

	const [showReviewerDetails, setShowReviewerDetails] = useState();

	return (
		<>
			{loading ? (
				<div className="d-flex p-4 align-center justify-content-center">
					<Loader />
				</div>
			) : (
				<>
					{successMessage && <StatusToast message={successMessage} timeout={5000} />}
					<div>
						{errors.length > 0 && (
							<>
								<div className="alert alert-danger">{errors}</div>
							</>
						)}
						{reviews.length > 0 ? (
							<>
								<div className="row font-weight-bold px-1 p-4">
									<div className="col-md-4 px-1">Requested Feedback for (Resume Name)</div>
									<div className="col px-1">Status</div>
									<div className="col-md-5 px-1">Reviewers</div>
									<div
										className="col-md-2 px-1"
										style={{
											textAlign: "center",
										}}
									>
										Actions
									</div>
								</div>
								{reviews.map((review, i) => (
									<div className="created-resume bg-white p-3 px-4 border mb-3" key={i}>
										<div className="row px-1 align-items-center cursor-pointer position-relative">
											<div
												className="col-md-4 px-1"
												onClick={() => {
													if (review.status === "DRAFT") {
														setShowReviewModal({
															show: true,
															review,
														});
													}
												}}
											>
												<p className="font-weight-bold">{getResumeName(review.resumeLedgerReferenceId)}</p>
												<p>Created On {dayjs(review.createdOn).format("DD MMM YYYY, HH:mm")}</p>
												<p>Last Updated On {dayjs(review.lastUpdatedOn).format("DD MMM YYYY, HH:mm")}</p>
											</div>
											<div className="col px-1">
												<div className={`badge ${review.status === "DRAFT" ? "badge-warning" : "badge-success"}`}>
													{review.status}
												</div>
											</div>
											{review.status === "DRAFT" ? (
												<div className="col-md-5 px-1">
													<ul className="m-0 py-2">
														{review.reviewerEmails.map((reviewer, i) => (
															<li key={i} className="m-0">
																{reviewer}
															</li>
														))}
													</ul>
												</div>
											) : (
												<div className="col-md-5 px-1">
													<ul
														className="mt-2"
														onMouseEnter={() => setShowReviewerDetails(review._id)}
														onMouseLeave={() => setShowReviewerDetails()}
														onFocusCapture={() => setShowReviewerDetails(review._id)}
														style={{
															paddingLeft: "0",
														}}
													>
														<div
															style={{
																position: "absolute",
																top: "-40px",
																right: "0px",
																zIndex: "1000",
																display: showReviewerDetails === review._id ? "block" : "none",
															}}
														>
															{reviewDetails(review.reviewerReviewersDetail, review)}
														</div>
														{review.reviewerReviewersDetail.map((reviewer, index) => (
															<>
																{reviewer.reviewLog.length === 0 ? (
																	<li key={index}>
																		{reviewer.reviewerEmail} <br />
																		{reviewer.reviewLog.length > 0 && (
																			<>
																				Last updated:{" "}
																				<span className="badge badge-success">
																					{dayjs(reviewer.reviewLog[reviewer.reviewLog.length - 1].updatedAt).format(
																						"DD MMM YYYY, HH:mm",
																					)}
																				</span>
																			</>
																		)}
																	</li>
																) : (
																	<span key={reviewer._id}>
																		{getReviewerName(reviewer.reviewerEmail)} -{" "}
																		{reviewer.reviewLog.length > 0 && (
																			<>
																				<span className="badge badge-success mr-1">{reviewer.reviewerStatus}</span>
																				Last updated:{" "}
																				<span className="badge badge-success">
																					{dayjs(reviewer.reviewLog[reviewer.reviewLog.length - 1].updatedAt).format(
																						"DD MMM YY, HH:mm",
																					)}
																				</span>
																			</>
																		)}
																	</span>
																)}
															</>
														))}
													</ul>
												</div>
											)}
											<div
												className="col-md-2 col-sm-12 px-1 d-flex"
												style={{
													textAlign: "center",
												}}
											>
												{review.status === "DRAFT" && (
													<span
														onClick={() =>
															setShowReviewModal({
																show: true,
																review,
															})
														}
														className="text-medium d-flex mb-0 px-2 flex-column  justify-content-center cursor-pointer align-items-center"
													>
														<i className="fa-solid fa-eye text-primary fs-20 mb-2" />
														View
													</span>
												)}
												{review.reviewerReviewersDetail.length > 0 && (
													<span
														onClick={() => {
															setResumeIdCookie(review.resumeLedgerReferenceId);

															window.location.replace("/resume/review?feedback=active");
														}}
														className="text-medium d-flex mb-0 px-2 flex-column  justify-content-center cursor-pointer align-items-center"
													>
														<i className="fs-20 mb-2 fa fa-comment text-primary mb-2" />
														View Feedback
													</span>
												)}
												{review.status === "DRAFT" && (
													<span
														onClick={() => deleteResumeReview(review._id)}
														className="text-medium d-flex mb-0 px-2 flex-column  justify-content-center cursor-pointer align-items-center"
													>
														<i className="fa-solid fa-trash text-danger fs-20 mb-2" />
														Delete
													</span>
												)}
											</div>
										</div>
									</div>
								))}
							</>
						) : (
							<div className="empty-wrapper">
								<div className="content text-center">
									<h4>You have no reviews yet. </h4>
									<button
										onClick={() => {
											navigate("/my-resumes");
											setShowReviews(false);
										}}
										className="bg-primary text-white border-0 btn-lg"
									>
										Request a review
									</button>
								</div>
							</div>
						)}
					</div>
				</>
			)}
			{showReviewModal.show && (
				<ResumeReviewModal
					setShowReviewModal={setShowReviewModal}
					resumeID={showReviewModal.review.resumeLedgerReferenceId}
					reviewData={showReviewModal.review}
					fetchReviews={fetchReviews}
					resumeTitle={getResumeName(showReviewModal.review.resumeLedgerReferenceId)}
				/>
			)}
		</>
	);
}

export default ReviewsList;
