import dayjs from "dayjs";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import useIsLoggedIn from "../../../hooks/use-IsLoggedIn";
const preventingRecruiters = [
	{
		tip: "Using a cluttered or visually unappealing layout",
		top_header: "",
		secondary_header: "",
		content: "",
		points: [
			{
				point: "Tables",
			},
			{
				point: "Distracting or inappropriate graphics",
			},
		],
		image: "/images/21_things/bad_cv.png",
	},
	{
		tip: " Lack of a clear and concise summary",
		top_header: "",
		secondary_header: "",
		content: "Failing to provide a clear and concise summary or professional profile at the beginning of a resume.",
		image: "/images/21_things/bad_cv.png",
	},
	{
		tip: "Poor section naming",
		top_header: "",
		secondary_header: "",
		content: `Make your resume/CV represent all your amazing accomplishments,
		the skills you&#39;ve acquired, and the vast experiences you&#39;ve
		gained. This will help you capitalize on the right
		opportunities, fitting your strengths, skills, and evolved
		experiences.`,
		points: [
			{
				point: "Numbering in section: Part I or Number",
			},
			{
				point: "Using full sentences for sections",
			},
			{
				point:
					"Choosing unclear or unprofessional section headings on your resume, can confuse employers or fail to convey your qualifications effectively.",
				subPoints: [
					{
						subPoint:
							"Instead of using “Stuff I Did” as a section heading, use “Professional Experience” or “Work History” to clearly indicate your work history.",
					},
					{
						subPoint:
							"Instead of using “Tech Stack” as a section heading, use “Skills” to clearly indicate your skills.",
					},
				],
			},
		],
		image: "/images/21_things/bad_cv.png",
	},
	{
		tip: " Excessive Length",
		top_header: "",
		secondary_header: "",
		content: `Make your resume/CV represent all your amazing accomplishments,
		the skills you&#39;ve acquired, and the vast experiences you&#39;ve
		gained. This will help you capitalize on the right
		opportunities, fitting your strengths, skills, and evolved
		experiences.`,
		image: "/images/21_things/bad_cv.png",
	},
	{
		tip: "Poor formatting and inconsistent styling",
		top_header: "",
		secondary_header: "",
		content: `Make your resume/CV represent all your amazing accomplishments,
		the skills you&#39;ve acquired, and the vast experiences you&#39;ve
		gained. This will help you capitalize on the right
		opportunities, fitting your strengths, skills, and evolved
		experiences.`,
		image: "/images/21_things/bad_cv.png",
	},
	{
		tip: "Overemphasis on job duties instead of accomplishments    ",
		top_header: "",
		secondary_header: "",
		content: `Make your resume/CV represent all your amazing accomplishments,
		the skills you&#39;ve acquired, and the vast experiences you&#39;ve
		gained. This will help you capitalize on the right
		opportunities, fitting your strengths, skills, and evolved
		experiences.`,
		image: "/images/21_things/bad_cv.png",
	},
	{
		tip: "Weak and run-on sentences on accomplishments    ",
		top_header: "",
		secondary_header: "",
		content: `Make your resume/CV represent all your amazing accomplishments,
		the skills you&#39;ve acquired, and the vast experiences you&#39;ve
		gained. This will help you capitalize on the right
		opportunities, fitting your strengths, skills, and evolved
		experiences.`,
		image: "/images/21_things/bad_cv.png",
	},
	{
		tip: "Including Irrelevant Information    ",
		top_header: "",
		secondary_header: "",
		content: `Make your resume/CV represent all your amazing accomplishments,
		the skills you&#39;ve acquired, and the vast experiences you&#39;ve
		gained. This will help you capitalize on the right
		opportunities, fitting your strengths, skills, and evolved
		experiences.`,
		image: "/images/21_things/bad_cv.png",
	},
	{
		tip: " Poor Presentation(Cluttered)    ",
		top_header: "",
		secondary_header: "",
		content: `Make your resume/CV represent all your amazing accomplishments,
		the skills you&#39;ve acquired, and the vast experiences you&#39;ve
		gained. This will help you capitalize on the right
		opportunities, fitting your strengths, skills, and evolved
		experiences.`,
		image: "/images/21_things/bad_cv.png",
	},
	{
		tip: " Lack of clear structure     ",
		top_header: "",
		secondary_header: "",
		content: `Make your resume/CV represent all your amazing accomplishments,
		the skills you&#39;ve acquired, and the vast experiences you&#39;ve
		gained. This will help you capitalize on the right
		opportunities, fitting your strengths, skills, and evolved
		experiences.`,
		image: "/images/21_things/bad_cv.png",
	},
	{
		tip: " Neglecting to customize the resume for each application:    ",
		top_header: "",
		secondary_header: "",
		content: `Make your resume/CV represent all your amazing accomplishments,
		the skills you&#39;ve acquired, and the vast experiences you&#39;ve
		gained. This will help you capitalize on the right
		opportunities, fitting your strengths, skills, and evolved
		experiences.`,
		image: "/images/21_things/bad_cv.png",
	},
	{
		tip: "Submitting a Resume in an Outdated File Format    ",
		top_header: "",
		secondary_header: "",
		content: `Make your resume/CV represent all your amazing accomplishments,
		the skills you&#39;ve acquired, and the vast experiences you&#39;ve
		gained. This will help you capitalize on the right
		opportunities, fitting your strengths, skills, and evolved
		experiences.`,
		image: "/images/21_things/bad_cv.png",
	},
	{
		tip: "Inappropriate Images   ",
		top_header: "",
		secondary_header: "",
		content: `Make your resume/CV represent all your amazing accomplishments,
		the skills you&#39;ve acquired, and the vast experiences you&#39;ve
		gained. This will help you capitalize on the right
		opportunities, fitting your strengths, skills, and evolved
		experiences.`,
		image: "/images/21_things/bad_cv.png",
	},
	{
		tip: " Excessive Section Dividers    ",
		top_header: "",
		secondary_header: "",
		content: `Make your resume/CV represent all your amazing accomplishments,
		the skills you&#39;ve acquired, and the vast experiences you&#39;ve
		gained. This will help you capitalize on the right
		opportunities, fitting your strengths, skills, and evolved
		experiences.`,
		image: "/images/21_things/bad_cv.png",
	},
	{
		tip: " Generic or Outdated Resume Template    ",
		top_header: "",
		secondary_header: "",
		content: `Make your resume/CV represent all your amazing accomplishments,
		the skills you&#39;ve acquired, and the vast experiences you&#39;ve
		gained. This will help you capitalize on the right
		opportunities, fitting your strengths, skills, and evolved
		experiences.`,
		image: "/images/21_things/bad_cv.png",
	},
	{
		// eslint-disable-next-line quotes
		tip: 'Including References or "References Available Upon Request"',
		top_header: "",
		secondary_header: "",
		content: `Make your resume/CV represent all your amazing accomplishments,
		the skills you&#39;ve acquired, and the vast experiences you&#39;ve
		gained. This will help you capitalize on the right
		opportunities, fitting your strengths, skills, and evolved
		experiences.`,
		image: "/images/21_things/bad_cv.png",
	},
	{
		tip: " Mentioning High School Achievements (Unless a Recent Graduate)    ",
		top_header: "",
		secondary_header: "",
		content: `Make your resume/CV represent all your amazing accomplishments,
		the skills you&#39;ve acquired, and the vast experiences you&#39;ve
		gained. This will help you capitalize on the right
		opportunities, fitting your strengths, skills, and evolved
		experiences.`,
		image: "/images/21_things/bad_cv.png",
	},
	{
		tip: " Neglecting to Highlight Relevant Certifications or Licenses    ",
		top_header: "",
		secondary_header: "",
		content: `Make your resume/CV represent all your amazing accomplishments,
		the skills you&#39;ve acquired, and the vast experiences you&#39;ve
		gained. This will help you capitalize on the right
		opportunities, fitting your strengths, skills, and evolved
		experiences.`,
		image: "/images/21_things/bad_cv.png",
	},
	{
		tip: " Including High School Achievements (Unless a Recent Graduate)      ",
		top_header: "",
		secondary_header: "",
		content: `Make your resume/CV represent all your amazing accomplishments,
		the skills you&#39;ve acquired, and the vast experiences you&#39;ve
		gained. This will help you capitalize on the right
		opportunities, fitting your strengths, skills, and evolved
		experiences.`,
		image: "/images/21_things/bad_cv.png",
	},
	{
		tip: " Neglecting to Showcase Leadership and Teamwork Experiences   ",
		top_header: "",
		secondary_header: "",
		content: `Make your resume/CV represent all your amazing accomplishments,
		the skills you&#39;ve acquired, and the vast experiences you&#39;ve
		gained. This will help you capitalize on the right
		opportunities, fitting your strengths, skills, and evolved
		experiences.`,
		image: "/images/21_things/bad_cv.png",
	},
];
function JobLandPage() {
	const drawBacks = [
		{ tip: "Lengthy resume", content: "", image: "/images/21_things/bad_cv.png" },
		{ tip: "Lack of clear structure", content: "", image: "/images/21_things/bad_cv.png" },
		{ tip: "Irrelevant information", content: "", image: "/images/21_things/bad_cv.png" },
		{ tip: "Spelling and grammatical errors", content: "", image: "/images/21_things/bad_cv.png" },
		{ tip: "Vague or generic language", content: "", image: "/images/21_things/bad_cv.png" },
		{ tip: "Overuse of jargon", content: "", image: "/images/21_things/bad_cv.png" },
		{ tip: "Too much personal information", content: "", image: "/images/21_things/bad_cv.png" },
		{ tip: "Missing contact information", content: "", image: "/images/21_things/bad_cv.png" },
		{ tip: "Poor formatting", content: "", image: "/images/21_things/bad_cv.png" },
		{ tip: "Inconsistent font styles", content: "", image: "/images/21_things/bad_cv.png" },
		{ tip: "Unprofessional email address", content: "", image: "/images/21_things/bad_cv.png" },
		{ tip: "Unexplained gaps in employment", content: "", image: "/images/21_things/bad_cv.png" },
		{ tip: "Listing irrelevant hobbies", content: "", image: "/images/21_things/bad_cv.png" },
		{ tip: "Including outdated skills", content: "", image: "/images/21_things/bad_cv.png" },
		{ tip: "Using a one-size-fits-all approach", content: "", image: "/images/21_things/bad_cv.png" },
		{
			tip: "Focusing on job duties instead of accomplishments",
			content: "",
			image: "/images/21_things/bad_cv.png",
		},
		{ tip: "Neglecting quantifiable achievements", content: "", image: "/images/21_things/bad_cv.png" },
		{ tip: "Omitting keywords relevant to the job", content: "", image: "/images/21_things/bad_cv.png" },
		{ tip: "Ignoring industry-specific norms", content: "", image: "/images/21_things/bad_cv.png" },
		{ tip: "Overly detailed descriptions of past roles", content: "", image: "/images/21_things/bad_cv.png" },
		{
			tip: "Failing to tailor the resume for each job application",
			content: "",
			image: "/images/21_things/bad_cv.png",
		},
	];

	const goodPractices = [
		{
			tip: "Good Formating",
			top_header: "FOR STUDENTS",
			secondary_header: "Let us know your Strength by Transfer in your Resume",
			content: `Make your resume/CV represent all your amazing accomplishments,
			the skills you&#39;ve acquired, and the vast experiences you&#39;ve
			gained. This will help you capitalize on the right
			opportunities, fitting your strengths, skills, and evolved
			experiences.`,
			image: "/images/21_things/bad_cv.png",
		},
		{
			tip: "Light Texts",
			top_header: "FOR STUDENTS",
			secondary_header: "Let us know your Strength by Transfer in your Resume",
			content: `Make your resume/CV represent all your amazing accomplishments,
			the skills you&#39;ve acquired, and the vast experiences you&#39;ve
			gained. This will help you capitalize on the right
			opportunities, fitting your strengths, skills, and evolved
			experiences.`,
			image: "/images/21_things/bad_cv.png",
		},
	];
	const whatToDo = [
		{
			tip: "Format",
			top_header: "FOR STUDENTS",
			secondary_header: "Let us know your Strength by Transfer in your Resume",
			content: `Make your resume/CV represent all your amazing accomplishments,
			the skills you&#39;ve acquired, and the vast experiences you&#39;ve
			gained. This will help you capitalize on the right
			opportunities, fitting your strengths, skills, and evolved
			experiences.`,
			image: "/images/21_things/bad_cv.png",
		},
		{
			tip: "Simplify",
			top_header: "FOR STUDENTS",
			secondary_header: "Let us know your Strength by Transfer in your Resume",
			content: `Make your resume/CV represent all your amazing accomplishments,
			the skills you&#39;ve acquired, and the vast experiences you&#39;ve
			gained. This will help you capitalize on the right
			opportunities, fitting your strengths, skills, and evolved
			experiences.`,
			image: "/images/21_things/bad_cv.png",
		},
	];

	const userLoggedIn = useIsLoggedIn();

	const [activeIndex, setActiveIndex] = useState(null);

	const toggleAnswer = (index) => {
		setActiveIndex(index === activeIndex ? null : index);
	};
	const [activeDropDown, setActiveDropDown] = useState("DRAWBACKS");

	return (
		<>
			<nav className="container d-flex justify-content-between align-items-center">
				<div>
					<Link className="navbar-brand" to="/">
						<img className="img-fluid" src="/images/logo.png" alt="Logo" />
					</Link>
				</div>
				<div className="">
					<ul className="nav navbar-nav d-flex flex-row">
						<Link className="mx-3 font-weight-bold text-primary border-bottom border-primary" to="/">
							<li>Home</li>
						</Link>
						<Link className="px-3 font-weight-bold text-dark" to="/jobs">
							<li>Jobs</li>
						</Link>
						<Link className="px-3 font-weight-bold text-dark" to="https://hiring.dproz.com/">
							<li>For Employers</li>
						</Link>
						<Link className="px-3 font-weight-bold text-dark" to="/">
							<li>About us</li>
						</Link>
					</ul>
				</div>
				<div>
					<ul className="nav navbar-nav d-flex flex-row">
						{userLoggedIn ? (
							<Link to="/jobs">
								<li className="btn px-3 py-2 rounded text-capitalize btn-primary text-white mr-3">Jobs</li>
							</Link>
						) : (
							<Link to="/resume/select-experience">
								<li className="btn px-3 py-2 rounded text-capitalize btn-primary text-white mr-3">Create Resume</li>
							</Link>
						)}
						<Link to="">
							<li>
								<Dropdown>
									<Dropdown.Toggle
										variant="outline-secondary"
										id="dropdown-basic"
										className="text-dark rounded px-3 py-2"
									>
										Dashboard
									</Dropdown.Toggle>
									<Dropdown.Menu>
										{userLoggedIn ? (
											<>
												<Dropdown.Item>
													<Link to="/my-resumes">My Resumes</Link>
												</Dropdown.Item>
												<Dropdown.Item>
													<Link to="/dashboard">Dashboard</Link>
												</Dropdown.Item>
											</>
										) : (
											<>
												<Dropdown.Item>
													<Link to="/signin">Sign in</Link>
												</Dropdown.Item>
												<Dropdown.Item>
													<Link to="/register">Register</Link>
												</Dropdown.Item>
											</>
										)}
									</Dropdown.Menu>
								</Dropdown>
							</li>
						</Link>
					</ul>
				</div>
			</nav>
			<section className="bg-light">
				<section className="container section-content py-5">
					<div className="column  center-content">
						<div className="col-md-8">
							<img src="/images/21_things/top.png" alt="Lady on a computer" />
						</div>
						<div className="col-md-10 d-flex flex-column justify-content-center center-content">
							<h1 className="font-weight-bold text-black display-4">
								21 RESUME MISTAKES THAT HINDER EMPLOYERS READING YOUR RESUME
							</h1>
							<p className="mb-5">
								<strong>
									Studies have shown that employers/recruiters spend only 6 seconds reviewing a resume. That&#39;s how
									long [you] have to communicate key information they&#39;re looking for & make an impression.
								</strong>
							</p>
						</div>
					</div>
				</section>
			</section>

			{/*  */}
			<section className="bg-light">
				<div className="container section-content  ">
					<div
						className="col-md-12 bg-gray  p-3"
						style={{
							backgroundColor: "white",
							borderRadius: 10,
						}}
					>
						<h3 className="text-black mt-5 mb-1">21 things preventing recruiters from reading your CV</h3>
						<p className="mb-3">Need to group these into: Presentation, Impact, and Competencies</p>

						{preventingRecruiters.map((item, index) => (
							<>
								<div
									className="col-md-12   p-3"
									style={{
										alignSelf: "flex-start",
										//backgroundColor:"pink",
										//borderRadius:10,
									}}
								>
									<div key={index} className="faq-item">
										<div className="faq-question" onClick={() => toggleAnswer(index)}>
											<i
												className={`fa${activeIndex === index ? " fa-angle-down" : " fa-angle-right"} grey-arrow mr-3`}
											/>
											<strong>
												{"  "}
												{index + 1}
												{".  "}
												{item.tip}
											</strong>
										</div>
										{activeIndex === index && (
											<div className="container section-content py-5">
												<div className="row">
													<div className="col-md-6 d-flex flex-column justify-content-center">
														{/* <p className="text-secondary font-weight-bold text-uppercase">
																{item.top_header}
															</p> */}
														{/* <h3 className="text-black mb-3">{item.secondary_header}</h3> */}
														<p className="">{item.content}</p>
														<ul>
															{item.points &&
																item.points.map((point, index) => (
																	<li key={index}>
																		{point.point}
																		{point.subPoints && point.subPoints.length > 0 && (
																			<ul>
																				{point.subPoints &&
																					point.subPoints.map((subPoint, subIndex) => (
																						<li key={subIndex}>{subPoint.subPoint}</li>
																					))}
																			</ul>
																		)}
																	</li>
																))}
														</ul>
													</div>
													{/* <div className="col-md-6">
														<img src={item.image} alt="" />
													</div> */}
												</div>
											</div>
										)}
									</div>
								</div>
							</>
						))}
					</div>
				</div>
			</section>
			{/*  */}
			<section className="bg-light">
				<section className="container section-content py-5">
					<div className="column  center-content">
						<div className="col-md-10 d-flex flex-column justify-content-center center-content">
							<div className="col-md-8 bg-light  d-flex justify-content-between p-3">
								<Link
									onClick={() => setActiveDropDown("DRAWBACKS")}
									className={`col-md-4 btn px-6 py-3 rounded
								${activeDropDown === "DRAWBACKS" ? "btn btn-primary" : "text-black"} text-capitalize   font-weight-bold`}
								>
									Drawbacks
								</Link>
								<Link
									onClick={() => setActiveDropDown("GOOD_PRACTICES")}
									className={`col-md-4 btn px-6 py-3 rounded
								${activeDropDown === "GOOD_PRACTICES" ? "btn btn-primary" : "text-black"} text-capitalize   font-weight-bold`}
								>
									Good Practices
								</Link>
								<Link
									onClick={() => setActiveDropDown("WHAT_TO_DO")}
									className={`col-md-4 btn px-6 py-3 rounded
								${activeDropDown === "WHAT_TO_DO" ? "btn btn-primary" : "text-black"} text-capitalize   font-weight-bold`}
								>
									What to Do
								</Link>
							</div>
						</div>
					</div>
				</section>
			</section>
			<section className="bg-light">
				<div className="container section-content  ">
					<div
						className="col-md-12 bg-gray  p-3"
						style={{
							backgroundColor: "white",
							borderRadius: 10,
						}}
					>
						{activeDropDown === "DRAWBACKS" &&
							drawBacks.map((item, index) => (
								<>
									<div
										className="col-md-12   p-3"
										style={{
											alignSelf: "flex-start",
											//backgroundColor:"pink",
											//borderRadius:10,
										}}
									>
										<div key={index} className="faq-item">
											<div className="faq-question" onClick={() => toggleAnswer(index)}>
												<i
													className={`fa${
														activeIndex === index ? " fa-angle-down" : " fa-angle-right"
													} grey-arrow mr-3`}
												/>
												<strong>
													{"  "}
													{index + 1}
													{".  "}
													{item.tip}
												</strong>
											</div>
											{activeIndex === index && (
												<div className="container section-content py-5">
													<div className="row">
														<div className="col-md-6 d-flex flex-column justify-content-center">
															{/* <p className="text-secondary font-weight-bold text-uppercase">
																{item.top_header}
															</p> */}
															{/* <h3 className="text-black mb-3">{item.secondary_header}</h3> */}
															<p className="mb-3">{item.content}</p>
														</div>
														<div className="col-md-6">
															<img src={item.image} alt="" />
														</div>
													</div>
												</div>
											)}
										</div>
									</div>
								</>
							))}
						{activeDropDown === "GOOD_PRACTICES" &&
							goodPractices.map((item, index) => (
								<div
									key={index}
									className="col-md-12   p-3"
									style={{
										alignSelf: "flex-start",
										//backgroundColor:"pink",
										//borderRadius:10,
									}}
								>
									<div key={index} className="faq-item">
										<div className="faq-question" onClick={() => toggleAnswer(index)}>
											<i
												className={`fa${activeIndex === index ? " fa-angle-down" : " fa-angle-right"} grey-arrow mr-3`}
											/>
											<strong>{item.tip}</strong>
										</div>
										{activeIndex === index && (
											<div className="container section-content py-5">
												<div className="row">
													<div className="col-md-6 d-flex flex-column justify-content-center">
														{/* <p className="text-secondary font-weight-bold text-uppercase">
															{item.top_header}
														</p> */}
														{/* <h3 className="text-black mb-3">{item.secondary_header}</h3> */}
														{/* <p className="mb-3">{item.content}</p> */}
													</div>
													<div className="col-md-6">
														<img src={item.image} alt="" />
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							))}
						{activeDropDown === "WHAT_TO_DO" &&
							whatToDo.map((item, index) => (
								<div
									className="col-md-12   p-3"
									style={{
										alignSelf: "flex-start",
									}}
									key={index}
								>
									<div key={index} className="faq-item">
										<div className="faq-question" onClick={() => toggleAnswer(index)}>
											<i
												className={`fa${activeIndex === index ? " fa-angle-down" : " fa-angle-right"} grey-arrow mr-3`}
											/>
											<strong>{item.tip}</strong>
										</div>
										{activeIndex === index && (
											<div className="container section-content py-5">
												<div className="row">
													<div className="col-md-6 d-flex flex-column justify-content-center">
														{/* <p className="text-secondary font-weight-bold text-uppercase">
															{item.top_header}
														</p> */}
														{/* <h3 className="text-black mb-3">{item.secondary_header}</h3> */}
														{/* <p className="mb-3">{item.content}</p> */}
													</div>
													<div className="col-md-6">
														<img src={item.image} alt="" />
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							))}
					</div>
				</div>
			</section>

			<section className="bg-light">
				<section className="container section-content py-5">
					<div className="row">
						<div className="col-md-8 d-flex flex-column justify-content-center">
							<h3 className="text-black mb-3">
								Get CV Templates that Follow Standards and good practices of writting CV
							</h3>

							<Link
								to="/resume/select-experience"
								className="btn px-3 py-2 mb-4 rounded text-capitalize btn-primary text-white font-weight-bold"
							>
								Download Template
							</Link>
						</div>
						<div className="col-md-4">
							<img
								src="/images/21_things/cv.png"
								alt=""
								style={{
									alignSelf: "flex-end",
									backgroundPosition: "center",
									backgroundSize: "cover",
								}}
							/>
						</div>
					</div>
				</section>
			</section>
			<div className="container section-content py-5">
				<div className="row">
					<div className="col-md-6">
						<img src="/images/home/professionals.png" alt="" />
					</div>
					<div className="col-md-6 d-flex flex-column justify-content-center">
						{/* <p className="text-secondary font-weight-bold text-uppercase">FOR STUDENTS</p> */}
						<h3 className="text-black mb-3">
							Transfer in your Resume,Allow us to assist you in creating a standout CV
						</h3>
						{/* <p className="mb-3">
							Make your resume/CV represent all your amazing accomplishments, the skills you&#39;ve acquired,
							and the vast experiences you&#39;ve gained. This will help you capitalize on the right
							opportunities, fitting your strengths, skills, and evolved experiences.
						</p> */}
						<Link
							to="/resume/select-experience"
							className="btn px-3 py-2 mb-4 rounded text-capitalize btn-primary text-white font-weight-bold"
						>
							Upload Your Resume
						</Link>
					</div>
				</div>
			</div>

			<section className="bg-dark text-white">
				<div className="container section-content">
					<div className="row">
						<div className="col-md-2">
							<h4 className="text-white">Company</h4>
							<div>
								<Link to="/about-us">About us</Link>
							</div>
						</div>
						<div className="col-md-2">
							<h4 className="text-white">Resources</h4>
							<div className="mb-3">
								<Link to="">Blog</Link>
							</div>
							<div className="mb-3">
								<Link to="/faq">FAQs</Link>
							</div>
						</div>
						<div className="col-md-2">
							<h4 className="text-white">Legal</h4>
							<div className="mb-3">
								<Link to="/terms-and-conditions">Privacy</Link>
							</div>
							<div className="mb-3">
								<Link to="/privacy-policy">Terms of Service</Link>
							</div>
						</div>
						<div className="col-md-2">
							<h4 className="text-white">Contact</h4>
							<div className="mb-3">
								<Link to="">Contact us</Link>
							</div>
						</div>
						<div className="col-md-4">
							<p>@ {dayjs().format("YYYY")} TE-VENTURES INC LTD</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default JobLandPage;
