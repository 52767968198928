const certificationListSuggestions = [
	{
		id: 1,
		title: "Project Management Professional (PMP)",
		added: false,
	},
	{
		id: 2,
		title: "Certified Public Accountant (CPA)",
		added: false,
	},
	{
		id: 3,
		title: "Certified Financial Planner (CFP)",
		added: false,
	},
	{
		id: 4,
		title: "Certified Information Systems Security Professional (CISSP)",
		added: false,
	},
	{
		id: 5,
		title: "Six Sigma Green Belt",
		added: false,
	},
	{
		id: 6,
		title: "Six Sigma Black Belt",
		added: false,
	},
	{
		id: 7,
		title: "Certified ScrumMaster (CSM)",
		added: false,
	},
	{
		id: 8,
		title: "Certified Scrum Product Owner (CSPO)",
		added: false,
	},
	{
		id: 9,
		title: "Microsoft Certified Solutions Expert (MCSE)",
		added: false,
	},
	{
		id: 10,
		title: "Cisco Certified Network Associate (CCNA)",
		added: false,
	},
	{
		id: 11,
		title: "Certified Ethical Hacker (CEH)",
		added: false,
	},
	{
		id: 12,
		title: "Certified Information Systems Auditor (CISA)",
		added: false,
	},
	{
		id: 13,
		title: "AWS Certified Solutions Architect",
		added: false,
	},
	{
		id: 14,
		title: "Salesforce Certified Administrator",
		added: false,
	},
	{
		id: 15,
		title: "Google Analytics Certification",
		added: false,
	},
	{
		id: 16,
		title: "Google Ads Certification",
		added: false,
	},
	{
		id: 17,
		title: "HubSpot Inbound Marketing Certification",
		added: false,
	},
	{
		id: 18,
		title: "HubSpot Content Marketing Certification",
		added: false,
	},
	{
		id: 19,
		title: "HubSpot Social Media Certification",
		added: false,
	},
	{
		id: 20,
		title: "CompTIA A+",
		added: false,
	},
	{
		id: 21,
		title: "CompTIA Network+",
		added: false,
	},
	{
		id: 22,
		title: "CompTIA Security+",
		added: false,
	},
	{
		id: 23,
		title: "Certified Internal Auditor (CIA)",
		added: false,
	},
	{
		id: 24,
		title: "Certified Management Accountant (CMA)",
		added: false,
	},
	{
		id: 25,
		title: "Certified Fraud Examiner (CFE)",
		added: false,
	},
	{
		id: 26,
		title: "Certified in Risk and Information Systems Control (CRISC)",
		added: false,
	},
	{
		id: 27,
		title: "Certified Information Privacy Professional (CIPP)",
		added: false,
	},
	{
		id: 28,
		title: "Certified Human Resources Professional (CHRP)",
		added: false,
	},
	{
		id: 29,
		title: "Certified Supply Chain Professional (CSCP)",
		added: false,
	},
	{
		id: 30,
		title: "Certified in Production and Inventory Management (CPIM)",
		added: false,
	},
	{
		id: 31,
		title: "Professional in Human Resources (PHR)",
		added: false,
	},
	{
		id: 32,
		title: "Senior Professional in Human Resources (SPHR)",
		added: false,
	},
	{
		id: 33,
		title: "Certified Professional in Learning and Performance (CPLP)",
		added: false,
	},
	{
		id: 34,
		title: "Certified Professional in Healthcare Quality (CPHQ)",
		added: false,
	},
	{
		id: 35,
		title: "Certified Occupational Therapy Assistant (COTA)",
		added: false,
	},
	{
		id: 36,
		title: "Registered Nurse (RN)",
		added: false,
	},
	{
		id: 37,
		title: "Certified Personal Trainer (CPT)",
		added: false,
	},
	{
		id: 38,
		title: "Yoga Alliance Registered Yoga Teacher (RYT)",
		added: false,
	},
	{
		id: 39,
		title: "Certified Pilates Instructor",
		added: false,
	},
	{
		id: 40,
		title: "Certified Nutritionist",
		added: false,
	},
	{
		id: 41,
		title: "Certified Financial Analyst (CFA)",
		added: false,
	},
	{
		id: 42,
		title: "Certified Investment Management Analyst (CIMA)",
		added: false,
	},
	{
		id: 43,
		title: "Certified Financial Services Auditor (CFSA)",
		added: false,
	},
	{
		id: 44,
		title: "Certified Public Manager (CPM)",
		added: false,
	},
	{
		id: 45,
		title: "Professional Engineer (PE)",
		added: false,
	},
	{
		id: 46,
		title: "Certified Safety Professional (CSP)",
		added: false,
	},
	{
		id: 47,
		title: "Certified Professional Ergonomist (CPE)",
		added: false,
	},
	{
		id: 48,
		title: "Certified Industrial Hygienist (CIH)",
		added: false,
	},
	{
		id: 49,
		title: "Certified Fire Protection Specialist (CFPS)",
		added: false,
	},
	{
		id: 50,
		title: "Certified Environmental Professional (CEP)",
		added: false,
	},
];

export default certificationListSuggestions;
