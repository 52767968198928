import AccountingAndFinance from "./Accounting&FinanceSkills.json";
import Agriculture from "./AgricultureSkills.json";
import DesignAndArchitecture from "./Design&ArchitectureSkills.json";
import Education from "./EducationSkills.json";
import Engineering from "./EngineeringSkills.json";
import HealthcareAndMedical from "./Healthcare&Medicalskills.json";
import HospitalityAndTourism from "./Hospitality&TourismSkills.json";
import HumanResourcesAndRecruitment from "./HumanResourceSkills.json";
import InformationTechnology from "./InformationTechnologySkills.json";
import Insurance from "./InsuranceSkills.json";
import Legal from "./LegalSkills.json";
import MarketingAndCommunications from "./Marketing&CommunicationsSkills.json";
import RealEstateAndProperty from "./RealEstate&PropertySkills.json";
import SportsAndRecreation from "./Sports&RecreationSkills.json";

//Banking And Finance	Accounting & Finance Skills
// Accounting	Accounting & Finance Skills
// Marketing And Communications	Marketing & Communications Skills
// Insurance	Insurance Skills
// Information Technology	Information Technology Skills
// Agriculture	Agriculture Skills
// Education	Education Skills
// Engineering	Engineering Skills
// Healthcare And Medical	Healthcare & Medical skills
// Human Resources & Recruitment	Human Resource Skills
// Operations	Education Skills
// Sales	Marketing & Communications Skills
// Administration & Office Support	Education Skills
// Advertising Arts and Design	Marketing & Communications Skills
// Business Development	Marketing & Communications Skills
// Community Services & Development	Education Skills
// Consulting and Strategy	Marketing & Communications Skills
// Customer Service	Marketing & Communications Skills
// Design And Architecture	Design & Architecture Skills
// Hospitality And Tourism	Hospitality & Tourism Skills
// Legal	Legal Skills
// Real Estate And Property	Real Estate & Property Skills
// Retail & Consumer	Marketing & Communications Skills
// Sports And Recreation	Sports & Recreation Skills
// Government And Defense	Education Skills

export const JOB_FUNCTION_MAPPING = {
	["banking and finance"]: AccountingAndFinance,
	["accounting"]: AccountingAndFinance,
	["marketing and communications"]: MarketingAndCommunications,
	["insurance"]: Insurance,
	["information technology"]: InformationTechnology,
	["agriculture"]: Agriculture,
	["education"]: Education,
	["engineering"]: Engineering,
	["healthcare and medical"]: HealthcareAndMedical,
	["human resources & recruitment"]: HumanResourcesAndRecruitment,
	["operations"]: Education,
	["sales"]: MarketingAndCommunications,
	["administration & office support"]: Education,
	["advertising arts and design"]: MarketingAndCommunications,
	["business development"]: MarketingAndCommunications,
	["community services & development"]: Education,
	["Consulting and Strategy"]: MarketingAndCommunications,
	["customer service"]: MarketingAndCommunications,
	["design and architecture"]: DesignAndArchitecture,
	["hospitality and tourism"]: HospitalityAndTourism,
	["legal"]: Legal,
	["real estate and property"]: RealEstateAndProperty,
	["retail & consumer"]: MarketingAndCommunications,
	["sports and recreation"]: SportsAndRecreation,
	["government and defense"]: Education,
};
