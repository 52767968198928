import React, { useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import CloseButton from "../components/CloseButton";

const onboardingRoutes = [
	"/expert-help/login",
	"/expert-help/personal-info",
	"/expert-help/summary",
	"/expert-help/upload-resume",
];

function ExpertViewLayout() {
	const navigate = useNavigate();

	const currentIndex = useMemo(() => {
		if (window.location.pathname === "/expert-help/select-resume") return 5;
		return onboardingRoutes.findIndex((route) => route === window.location.pathname);
	}, [window.location.pathname]);

	return (
		<div className="p-2">
			<div className="d-flex justify-content-between">
				<div className="">
					<CloseButton action={() => navigate("/")} />
				</div>
				<div className="d-flex flex-column align-items-center justify-content-start w-100 pt-5">
					<div
						style={{
							width: "544px",
							maxWidth: "100%",
						}}
					>
						<div className="d-flex mb-5">
							{Array.from({ length: onboardingRoutes.length }).map((_, index) => (
								<div
									className={
										index === currentIndex || currentIndex === 5
											? "dot active"
											: currentIndex > index
												? "dot bg-primary"
												: "dot"
									}
									key={index}
								/>
							))}
						</div>
						<Outlet />
					</div>
				</div>
				<div
					className="rounded text-white"
					style={{
						backgroundImage: "url(/images/onboarding.jpeg)",
						backgroundSize: "cover",
						backgroundPosition: "center",
						height: "calc(100vh - 1rem)",
						maxWidth: "40%",
						overflow: "hidden",
					}}
				>
					<div
						className=""
						style={{
							padding: 48,
							height: "100%",
							width: "100%",
							backgroundColor: "rgba(0, 0, 0, 0.5)",
						}}
					>
						<h1 style={{ fontSize: 40, marginBottom: 28, lineHeight: 1 }}>
							Expert crafted resumes to help you stand out in the industry and increase your chances of landing a job.
						</h1>
						<ul className="fs-16 ul-check">
							<li className="mb-3 font-weight medium">Tailored resume crafted to your profile</li>
							<li className="mb-3 font-weight medium">Professionally written by experienced resume writers</li>
							<li className="mb-3 font-weight medium">Backed by a satisfaction guarantee</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ExpertViewLayout;
