import React from "react";

// Modules object for setting up the Quill editor
export const modules = (props) => ({
	toolbar: {
		container: "#" + props,
		handlers: {},
	},
	history: {
		delay: 500,
		maxStack: 100,
		userOnly: true,
	},
});

// Formats objects for setting up the Quill editor
export const formats = ["header", "font", "size", "bold", "italic", "underline", "list", "bullet"];

// Quill Toolbar component
export const QuillToolbar = (props) => {
	return (
		<>
			{props.toolbarId !== undefined && (
				<div id={props.toolbarId} className="d-flex">
					<span className="ql-formats">
						<button className="ql-bold" />
						<button className="ql-italic" />
						<button className="ql-underline" />
						<button className="ql-list" value="bullet" />
					</span>
					{props.toggleEditorVisibility && (
						<i className="fa fa-close text-black ml-auto p-2 cursor-pointer" onClick={props.toggleEditorVisibility} />
					)}
				</div>
			)}
		</>
	);
};
export default QuillToolbar;
