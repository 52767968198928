import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import AutoSuggestion from "../../../../components/AutoSuggestion";
import ErrorToast from "../../../../components/ErrorToast";
import EditorListItems from "../../../../components/ResumeBuilder/EditorListItems";
import SkillsEditorListItems from "../../../../components/ResumeBuilder/SkillsEditorListItems";
import Spinner from "../../../../components/Spinner";
import TextEditor from "../../../../components/TextEditor";
import ResumeContext from "../../../../context/ResumeContext";
import { FormValidation } from "../../../../handlers/formValidation";
import { NavigationHandler } from "../../../../handlers/navigationHandler";
import { resumeObjectToContextFormat } from "../../../../handlers/valueTransformation";
import useHasChange from "../../../../hooks/use-HasChange";
import { StaticValue } from "../../../../seed/constants";
import { getJobFunctionFromTitle } from "../../../../seed/getMetadata";
import suggestionsData from "../../../../seed/metadata/filemapping";
import { JOB_FUNCTION_MAPPING } from "../../../../seed/skills/skillsMapping";
import Titles from "../../../../seed/titles";
import { ResumeDataService } from "../../../../services/create-resume/resume-data";
import GroupedSkillsItem from "../../Skills/GroupedSkillsItem";
import {
	convertArrayToUl,
	convertDateToWords,
	convertSplittedSkillsToNormalSkillsArray,
	convertUlToArray,
	dateToMYObject,
	getFirstWordSeparedByFullColon,
} from "./../../../../handlers/utilityFunctions";

const ResumeWorkDetails = ({ closeModal, workHistoryObject }) => {
	const [cookies] = useCookies(["resume"]);
	const navigate = useNavigate();
	const navigationHandler = new NavigationHandler();
	const link = useLocation().pathname.split("/")[2];
	const formValidation = new FormValidation();
	const dispatch = useDispatch();
	const { hasChanges } = useHasChange();

	const staticValue = new StaticValue();
	const staticValues = staticValue.getDeafaulValues();

	function previous() {
		navigationHandler.getPrevious(navigationHandler.template.navigationFlow, link, isComplete);
		navigate(-1);
	}

	const [workDetailList, setWorkDetailList] = useState([]);

	const [userInput, setUserInput] = useState(workHistoryObject.jobTitle);

	const [loading, setLoading] = useState(false);

	const { resumeData, setResumeData } = useContext(ResumeContext);
	const [serverErrors, setServerErrors] = useState([]);

	const { journal } = resumeData;

	let resumeCurrentStatus = journal ? journal.currentStatus : false;
	let isComplete = resumeCurrentStatus == "COMPLETED";

	const [workDetails, setWorkDetails] = useState("");
	useEffect(() => {
		if (resumeData.workHistory.length > 0 && workHistoryObject.referenceId) {
			setWorkDetails(() => {
				const data = resumeData.workHistory.filter((item) => item.referenceId === workHistoryObject.referenceId)[0];

				return data.details;
			});
		}
	}, [resumeData]);

	const [errors] = useState("");
	let [isInputCompleted, setIsInputCompleted] = useState(false);

	const resumeDataService = new ResumeDataService();

	let resumeId = cookies[staticValues.localStorageResumeId];

	const dispatchAddExtras = (payload) => {
		dispatch({ type: "ADD_EXTRAS", payload: payload });
	};

	function onSuccess(data) {
		setLoading(false);
		const response = data.data;
		resumeObjectToContextFormat(response, setResumeData, dispatchAddExtras);
		dispatch({ type: "RESUME_DATA", payload: response });
		skipSection();
	}

	function onError(data) {
		setLoading(false);
		const serverResponse = data.response.data;
		Array.isArray(serverResponse.message)
			? setServerErrors((prev) => {
					return [...prev, ...serverResponse.message];
				})
			: setServerErrors((prev) => {
					return [...prev, serverResponse.message];
				});
	}

	function addSuggestionsToList() {
		const data = getJobFunctionFromTitle(userInput).toString().toLowerCase();

		const jobFunction = data === "" ? resumeData.contactInfo.resumeFunction.toString().toLowerCase() : data;

		const suggestions = suggestionsData[jobFunction.split(",")[0]] || [];
		setWorkDetailList([]);

		if (suggestions && suggestions.length > 0) {
			let list = [];
			[...suggestions].map((suggestion) => {
				const data = [...suggestion.responsibilities];
				return list.push(data);
			});
			//Remove duplicates
			list = [...new Set(list.flat())];

			list.map((item, index) => {
				if (item !== "") {
					const value = {
						id: index,
						title: item,
						added: false,
					};

					setWorkDetailList((prev) => {
						return [...prev, value];
					});
				}
				return setWorkDetailList((prev) => {
					//remove empty values
					return [...prev].filter((n) => n.title.length !== 0);
				});
			});
		}
	}

	useEffect(() => {
		if (userInput.length > 0) {
			addSuggestionsToList();
		}
	}, [userInput]);

	const [relevantSkills, setRelevantSkills] = useState(workHistoryObject.relevantSkills || "");

	const addWorkDetails = () => {
		const formattedSkillsData = convertSplittedSkillsToNormalSkillsArray(groupedSkills);
		const customSkillsData = convertUlToArray(customSkills);
		const skillsData = formattedSkillsData.concat(customSkillsData);
		const formattedUlSkillsData = convertArrayToUl(formattedSkillsData);
		const ulSkillsData = formattedUlSkillsData.concat(customSkills);
		const updatedWorkDetails = {
			...workHistoryObject,
			details: workDetails,
			relevantSkills: ulSkillsData,
		};

		let validations = formValidation.valdiateWorkInformation(errors, updatedWorkDetails);

		isInputCompleted = validations.status;

		if (validations.status) {
			const responsibilitiesAndAccomplishments = convertUlToArray(updatedWorkDetails.details);

			const data = {
				referenceId: updatedWorkDetails.referenceId,
				jobtitle: updatedWorkDetails.jobTitle,
				employer: updatedWorkDetails.employer,
				employerLocation: {
					city: updatedWorkDetails.city,
					region: updatedWorkDetails.region,
					country: updatedWorkDetails.country,
				},
				startDate: dateToMYObject(updatedWorkDetails.startDate),
				endDate: updatedWorkDetails.endDate === "" ? null : dateToMYObject(updatedWorkDetails.endDate),
				currentEmployer: updatedWorkDetails.endDate === "" ? true : false,
				reponsibilitiesAndAccomplishements: responsibilitiesAndAccomplishments,
				relevantSkills: skillsData,
			};

			if (data.referenceId === "" || hasChanges("EXPERIENCE", data)) {
				data.section = "EXPERIENCE";
				setLoading(true);
				resumeDataService.patchResumeLedger(resumeId, data, "EXPERIENCE", onSuccess, onError);
			} else {
				skipSection();
			}
		}
	};

	const [skillList, setSkillList] = useState([]);
	const [showRelevantSkillsInput, setShowRelevantSkillsInput] = useState(workHistoryObject.relevantSkills.length > 10);

	useEffect(() => {
		setUserInput(resumeData.contactInfo.resumeTitle);
		addSuggestionsSkillsSuggestionsToList();
	}, [resumeData]);

	function addSuggestionsSkillsSuggestionsToList() {
		const jobFunction = resumeData.contactInfo.resumeFunction.toString().toLowerCase();

		const suggestions = JOB_FUNCTION_MAPPING[jobFunction.split(",")[0]] || [];
		setSkillList([]);

		if (suggestions && suggestions.length > 0) {
			let list = [...suggestions];

			list.map((item, index) => {
				if (item !== "") {
					const value = {
						id: index,
						title: item,
						added: false,
					};
					setSkillList((prev) => {
						return [...prev, value];
					});
				}
				return setSkillList((prev) => {
					//remove empty values
					return [...prev].filter((n) => n.title.length !== 0);
				});
			});
		}
	}
	useEffect(() => {
		addSuggestionsSkillsSuggestionsToList();
	}, [workHistoryObject.jobTitle]);

	const [filteredSkillList, setFilteredSkillList] = useState([]);

	useEffect(() => {}, []);
	useEffect(() => {
		setFilteredSkillList(skillList);
	}, [skillList]);

	useEffect(() => {
		const validation = formValidation.valdiateWorkInformation(errors, {
			details: workDetails,
		});
		if (!validation.status) {
			setIsInputCompleted(false);
		}
		if (validation.status) {
			setIsInputCompleted(true);
		}
	}, [workDetails]);

	const skipSection = () => {
		navigationHandler.getNext(navigationHandler.template.navigationFlow, link);
		return navigate("/resume/work-history-summary");
	};

	const [addCustomCategory, setAddCustomCategory] = useState(false);
	const [groupedSkills, setGroupedSkills] = useState([]);
	const [customSkills, setCustomSkills] = useState([]);

	function getGroupedAndCustomSkills(data) {
		let newCustomSkills = [];
		let skillsInGroup = convertUlToArray(data)
			.map((skillObject) => {
				const splitArray = skillObject.value.split(/:(.+)/);
				let wordBeforeColon = splitArray[0].trim();
				if (splitArray.length > 1) {
					let groupedSkills = [];
					groupedSkills = groupedSkills.concat(
						splitArray[1]
							.trim()
							.split(",")
							.map((skillItem) => {
								return { added: true, skill: skillItem };
							}),
					);
					return { title: wordBeforeColon, skills: groupedSkills, collapsed: true };
				}
				newCustomSkills.push({ value: wordBeforeColon });
			})
			.filter((item) => item !== undefined);
		setGroupedSkills(skillsInGroup);
		setCustomSkills(convertArrayToUl(newCustomSkills));
		newCustomSkills.length > 0 && setAddCustomCategory(true);
	}

	const collapseGroupedSkills = (groupedSkillIndex) => {
		let newGroupedSkills = [
			...groupedSkills.map((skillGroup) => ({
				...skillGroup,
				collapsed: skillGroup === groupedSkills[groupedSkillIndex] ? !skillGroup.collapsed : skillGroup.collapsed,
			})),
		];
		setGroupedSkills(newGroupedSkills);
	};

	const removeGroupedSkills = (skillTitle) => {
		let newGroupedSkills = groupedSkills;
		newGroupedSkills = newGroupedSkills.filter((skillItem) => skillItem.title !== skillTitle);
		setGroupedSkills(newGroupedSkills);

		let newFilteredSkillList = filteredSkillList;
		const indexChanged = newFilteredSkillList.findIndex(
			(changed) => getFirstWordSeparedByFullColon(changed.title) === skillTitle,
		);
		if (indexChanged !== -1) {
			newFilteredSkillList[indexChanged].added = false;
			setFilteredSkillList(newFilteredSkillList);
		}
	};

	const onSelectSingleSkill = (groupedSkillIndex, singleSkillIndex) => {
		let newGroupedSkills = groupedSkills.map((group) => ({
			...group,
			skills: group.skills.map((skill) => ({ ...skill })),
		}));

		newGroupedSkills[groupedSkillIndex].skills[singleSkillIndex].added =
			!newGroupedSkills[groupedSkillIndex].skills[singleSkillIndex].added;

		setGroupedSkills(newGroupedSkills);
	};

	const onAddCustomSkill = (groupedSkillIndex) => {
		let newGroupedSkills = groupedSkills.map((group) => ({
			...group,
			skills: group.skills.map((skill) => ({ ...skill })),
		}));

		newGroupedSkills[groupedSkillIndex].skills = [
			...newGroupedSkills[groupedSkillIndex].skills,
			{ added: true, skill: "" },
		];

		setGroupedSkills(newGroupedSkills);
	};

	const onSaveCustomSkillAdded = (groupedSkillIndex, singleSkillIndex, skillAdded) => {
		let newGroupedSkills = groupedSkills.map((group) => ({
			...group,
			skills: group.skills.map((skill) => ({ ...skill })),
		}));

		newGroupedSkills[groupedSkillIndex].skills[singleSkillIndex].skill = skillAdded;

		setGroupedSkills(newGroupedSkills);
	};

	const toggleEditorVisibility = () => {
		let newGroupedSkills = groupedSkills.map((group) => ({
			...group,
			skills: group.skills.map((skill) => ({ ...skill })),
			collapsed: !addCustomCategory,
		}));
		setGroupedSkills(newGroupedSkills);
		setAddCustomCategory(!addCustomCategory);
	};

	useEffect(() => {
		getGroupedAndCustomSkills(workHistoryObject.relevantSkills || "");
	}, []);

	return (
		<>
			<div
				className="modal"
				style={{
					zIndex: 2000,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					backgroundColor: "rgba(0, 0, 0, 0.5)",
				}}
			>
				<div
					className="modal-content"
					style={{
						maxWidth: "80vw",
						maxHeight: "80vh",
						overflowY: "auto",
					}}
				>
					<div className="px-4">
						<div className="pt-3 mb-2 d-flex align-items-center justify-content-between">
							<p className="font-weight-bold">
								Use 3-5 bullet points to highlight your key achievements and responsibilities.
							</p>

							<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
								<span className="bi bi-x" />
							</button>
						</div>
						{serverErrors.map((item, index) => {
							return <ErrorToast error={item} key={index} />;
						})}
						<div className="row m-0 common-recommendations-section">
							<div className="col-md-6 m-0 mb-4 p-0 pr-md-4">
								<div className="left">
									<div>
										<p className="text-dark mb-0 text-light">
											<span>{workHistoryObject.jobTitle}</span>, <span>{workHistoryObject.employer}</span>
										</p>
										<p className="text-light mb-3">
											{workHistoryObject.city}, {workHistoryObject.region}, {workHistoryObject.country} •{" "}
											{convertDateToWords(workHistoryObject.startDate)} -{" "}
											{workHistoryObject.endDate ? convertDateToWords(workHistoryObject.endDate) : "Present"}
										</p>
									</div>
									<div className="search mb-4">
										<div className="form-group">
											<AutoSuggestion
												suggestions={Titles}
												userInput={userInput}
												setUserInput={setUserInput}
												isForm={false}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											{/* recommendations topics  */}
											<div className="recommendation-topics">
												<EditorListItems
													editorListItems={workDetailList}
													editorContent={workDetails}
													setEditorContent={setWorkDetails}
													setEditorListItems={setWorkDetailList}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6 mb-4 p-0 d-flex flex-column justify-content-end">
								<div className="editor-side">
									<div>
										<TextEditor
											id="workDetails"
											value={workDetails}
											setValue={setWorkDetails}
											placeholder="Type your achievements and responsibilities here."
										/>
									</div>
								</div>
							</div>
							<div className="mb-3 mt-5">
								<button
									data-toggle="collapse"
									className="bg-transparent border-0 p-0 cursor-pointer font-weight-semi"
									data-target="#addDescription"
									onClick={() => setShowRelevantSkillsInput(!showRelevantSkillsInput)}
								>
									<span className="fa fa-plus mr-2" /> Highlight relevant skills you applied as a{" "}
									<span className="font-weight-semi text-primary">{workHistoryObject.jobTitle}</span> at{" "}
									<span className="font-weight-semi text-primary">{workHistoryObject.employer}</span>
								</button>
							</div>
							{showRelevantSkillsInput && (
								<div className="p-3">
									<div className="common-recommendations-section skills-recommendations-section row">
										<div className="col-md-6 m-0 mb-4 p-0 pr-md-4">
											<div className="search mb-0">
												<div className="form-group">
													<div className="input-group">
														<input
															type="text"
															onChange={(e) => {
																const input = e.target.value;
																if (input.trim() !== "") {
																	setFilteredSkillList((prev) =>
																		prev.filter((skill) => skill.title.toLowerCase().includes(input.toLowerCase())),
																	);
																} else {
																	setFilteredSkillList(skillList);
																}
															}}
															placeholder="Search"
															className="form-control"
														/>
														<div className="input-group-append">
															<button className="btn btn-primary px-md-5" type="button">
																<span className="fa fa-search" />
															</button>
														</div>
													</div>
												</div>
											</div>
											<div className="left">
												<div className="row">
													<div className="col-md-12">
														<div className="recommendation-topics">
															<SkillsEditorListItems
																editorListItems={filteredSkillList}
																editorContent={relevantSkills}
																setEditorContent={setRelevantSkills}
																setEditorListItems={setFilteredSkillList}
																groupedSkills={groupedSkills}
																setGroupedSkills={setGroupedSkills}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-6 d-flex p-0 mb-4 flex-column justify-content-end">
											{groupedSkills.map((item, groupedSkillIndex) => {
												if (item.title === "") {
													return null;
												}

												return (
													<GroupedSkillsItem
														key={groupedSkillIndex}
														item={item}
														collapseGroupedSkills={collapseGroupedSkills}
														groupedSkillIndex={groupedSkillIndex}
														removeGroupedSkills={removeGroupedSkills}
														onSaveCustomSkillAdded={onSaveCustomSkillAdded}
														onSelectSingleSkill={onSelectSingleSkill}
														onAddCustomSkill={onAddCustomSkill}
													/>
												);
											})}

											{addCustomCategory && (
												<div className="editor-wrapper">
													<TextEditor
														value={customSkills}
														setValue={setCustomSkills}
														placeholder="Add custom skills..."
														toggleEditorVisibility={toggleEditorVisibility}
													/>
												</div>
											)}
											{!addCustomCategory && (
												<div className="cursor-pointer" onClick={toggleEditorVisibility}>
													<span className="fa fa-plus mr-2" />
													<span className="add-custom-skills-category-button-text">Add custom category</span>
												</div>
											)}
										</div>
									</div>
								</div>
							)}
							<div className="col-12 mb-3">
								<div className="row">
									<div className="action d-flex align-items-center justify-content-end col-12">
										<button onClick={() => previous()} className="btn text-primary font-weight-semi mr-2">
											Cancel
										</button>
										<button
											data-toggle="modal"
											data-target={!isInputCompleted ? "#skipSectionModal" : ""}
											onClick={addWorkDetails}
											className="btn btn-primary mr-2"
										>
											{loading ? (
												<>
													Loading <Spinner />
												</>
											) : (
												"Save and Continue"
											)}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ResumeWorkDetails;
