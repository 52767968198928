import React, { useEffect } from "react";

const ResumePreview = ({ resumeData }) => {
	useEffect(() => {}, [resumeData]);
	return (
		<div style={{ scale: "1.2", transformOrigin: "center" }}>
			<div className="small-resume-preview">
				<div className="final-resume-preview bg-white  text-dark">
					<div className="resume-content ">
						<div className="top">
							<div className="row no-gutters no-wrap">
								<div
									className="col-4 py-2 border-right"
									style={{
										backgroundColor: "rgb(248, 249, 250)",
									}}
								>
									<div className="resume-pic mx-auto d-flex align-items-center justify-content-center">
										<p className="font-weight-semi m-0">
											{resumeData.contactInfo.firstName.charAt(0)}
											{resumeData.contactInfo.lastName.charAt(0)}
										</p>
									</div>
								</div>
								<div className="col-8 px-2 py-2">
									<div className="resume-user-name">
										<h1
											className="text-uppercase"
											style={{
												whiteSpace: "nowrap",
												overflow: "hidden",
												textOverflow: "ellipsis",
												margin: "0",
											}}
										>
											{resumeData.contactInfo.firstName} {resumeData.contactInfo.middleName}{" "}
											{resumeData.contactInfo.lastName}
										</h1>
										<span
											className="d-inline-block"
											style={{
												height: "2px",
												backgroundColor: "#03a9f4",
												width: "100px",
											}}
										/>
									</div>
									<ul
										style={{ fontSize: "4.5px" }}
										className="resume-user-quick-info d-flex list-unstyled d-flex align-items-center mb-0"
									>
										<li className="mr-1 text-nowrap">
											<span className="span text-primary mr-1 fa fa-map-marker" /> {resumeData.contactInfo.city},{" "}
											{resumeData.contactInfo.country}
										</li>
										<li className="mr-1 text-nowrap">
											<span className="span text-primary mr-1 fa fa-phone" />
											{resumeData.contactInfo.phoneNo}
										</li>
										<li className="mr-1 text-nowrap">
											<span className="span text-primary mr-1 fa fa-envelope" />
											{resumeData.contactInfo.email}
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="bottom">
							<div className="row no-gutters">
								<div className="col-4 border-right px-2" style={{ backgroundColor: "#f8f9fa" }}>
									<div className="contact-info my-3">
										<h3 className="text-uppercase">CONTACT</h3>
										<ul className="list-unstyled">
											<li>Dar es Salaam, Tanzania </li>
											<li>
												<span className="font-weight-bold">Mobile:</span> 9999999999
											</li>
											<li>
												<span className="font-weight-bold">Email</span>
												test@email.com
											</li>
										</ul>
									</div>
									<div className="personal-links my-3">
										<h3 className="text-uppercase">website, portfolio, profiles</h3>
										<ul className="pl-3 mb-1">
											<li>Dar es Salaam, Tanzania </li>
											<li>
												<span className="font-weight-bold">Mobile:</span> 9999999999
											</li>
											<li>
												<span className="font-weight-bold">Email</span>
												test@email.com
											</li>
										</ul>
									</div>
									<div className="education-info my-3">
										<h3 className="text-uppercase">Education</h3>
										<ul className="pl-3 mb-1">
											<li>January, 2020</li>
											<li>Bachelor of Science: Computer Applications</li>
											<li>
												<span className="font-weight-bold">Central School</span>Dar es Salaam
											</li>
										</ul>
									</div>
									<div className="certifications-info my-3">
										<h3 className="text-uppercase">Certifications</h3>
										<ul className="pl-3 mb-1">
											<li>Certified Associate Webmaster</li>
											<li>MCPD - Microsoft Certified Professional Developer</li>
										</ul>
									</div>
									<div className="languages-info my-3">
										<h3 className="text-uppercase">Languages</h3>
										<ul className="list-unstyled">
											<li>Dar es Salaam, Tanzania </li>
											<li>
												<span className="font-weight-bold">Mobile:</span> 9999999999
											</li>
											<li>
												<span className="font-weight-bold">Email</span>
												test@email.com
											</li>
										</ul>
									</div>
								</div>
								<div className="col-8 px-2">
									<div className="professional-summary-info my-1">
										<h3 className="text-uppercase with-circle">Professional Summary</h3>
										<p>
											Tech-savvy Web Developer contributes specialty in search engine optimization, marketing, social
											media management and audio/video integration to launch custom-tailored code for website
										</p>
									</div>
									<div className="languages-info my-1">
										<h3 className="text-uppercase  with-circle">Accomplishments</h3>
										<ul className="pl-3 mb-1">
											<li>
												Spearheaded a large-scale client project as a result of skill set and education and completed
												the project two weeks ahead of schedule.
											</li>
										</ul>
									</div>
									<div className="skills-info my-1">
										<h3 className="text-uppercase  with-circle">Skills</h3>
										<ul className="pl-3 mb-1">
											<li>Version Control Systems: Git, Github</li>
											<li>Complex Problem Solving</li>
											<li>Communication and Interpersonal Skills</li>
										</ul>
									</div>
									<div className="work-history-info my-1">
										<h3 className="text-uppercase  with-circle" />
										<p className="mb-0">August, 2019 - May, 2021</p>
										<p className="mb-2">
											<strong>Web Developer</strong>, <em>Kellton Tech</em>, Dar es Salaam, Tanzania{" "}
										</p>
										<ul className="pl-3 mb-1">
											<li>
												Planned website development, converting mockups into usable web presence with HTML, JavaScript,
												AJAX and JSON coding.
											</li>
										</ul>
									</div>
									<div className="affliations-info my-1">
										<h3 className="text-uppercase with-circle">Affiliations</h3>
										<ul className="pl-3 mb-1">
											<li> Association for Computing Machinery</li>
											<li>Freemason</li>
											<li>Toastmasters</li>
										</ul>
									</div>
									<div className="addtional-info my-1">
										<h3 className="text-uppercase with-circle">Additonal Information</h3>
										<p>
											Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
											the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley
											.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResumePreview;
