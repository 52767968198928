import React from "react";

import { actions } from "../../Views/TodayILearned/TodayILearned";

function TILCard({ tilData, setShowCreateTILModal, setShowDeleteModal, setSelectedTILData, setAction }) {
	const [comment, setComment] = React.useState("");
	const [showComments, setShowComments] = React.useState(false);

	return (
		<div className="bg-white w-100 rounded p-4">
			<div className="d-flex align-items-center" style={{ gap: "10px" }}>
				<div className="px-2 rounded font-weight-bold" style={{ backgroundColor: "#CDE2D1" }}>
					Wiki
				</div>
				{tilData.title !== "" && <div className="text-primary font-weight-bold fs-20">{tilData.title}</div>}
				{tilData.isTILPrivate && <i className="bi bi-lock fs-20" />}
				<i className="bi bi-bookmark fs-20 ml-auto cursor-pointer" />
				{!tilData.isCreator && <i className="bi bi-files fs-20 cursor-pointer" />}
				{tilData.isCreator && (
					<div className="dropdown">
						<button
							className="border-0 bg-transparent d-flex flex-column align-items-center"
							type="button"
							data-toggle="dropdown"
							aria-expanded="false"
						>
							<i className="bi bi-three-dots fs-20" />
						</button>
						<div className="dropdown-menu rounded p-1 border-0 cursor-pointer dropdown-menu-right">
							<span
								data-toggle="modal"
								data-target="#createTILModal"
								className="dropdown-item fs-16 px-2"
								onClick={() => {
									setSelectedTILData(tilData);
									setAction(actions[1]);
									setShowCreateTILModal(true);
								}}
							>
								<i className="bi bi-pen mr-2" />
								Edit
							</span>
							<span className="dropdown-item fs-16 px-2">
								<i className="bi bi-clipboard mr-2" />
								Copy TIL URL
							</span>
							<span
								data-toggle="modal"
								data-target="#createTILModal"
								className="dropdown-item fs-16 px-2"
								onClick={() => setShowCreateTILModal(true)}
							>
								<i className="bi bi-unlock mr-2" />
								Make Public
							</span>
							<span
								data-toggle="modal"
								data-target="#alertConfirmationModal"
								className="dropdown-item fs-16 px-2"
								onClick={() => setShowDeleteModal(true)}
							>
								<i className="bi bi-trash text-danger mr-2" />
								Delete
							</span>
						</div>
					</div>
				)}
			</div>
			<div className="d-flex align-items-center mt-3" style={{ gap: "10px" }}>
				<div
					className="d-flex flex-column align-items-center justify-content-center p-0 avatar-image-container"
					style={{ width: 50, height: 50 }}
				>
					<img alt="Profile" src={tilData.profile} />
				</div>
				<div className="fs-18">
					<span className="text-primary">{tilData.creator}</span> learned{" "}
					{tilData.learnedFrom !== "" ? "from" : "something new"}{" "}
					{tilData.learnedFrom !== "" && <span className="text-primary">{tilData.learnedFrom}</span>} 8 minutes ago
				</div>
			</div>
			<div className="font-weight-bold fs-16 my-3">{tilData.skillsLearned}</div>
			<div className="d-flex align-items-center" style={{ gap: "16px" }}>
				<i className="bi bi-tags fs-20" />
				{tilData.tags && tilData.tags.length === 0 && <div className="font-weight-semi fs-16">No tags</div>}
				{tilData.tags && tilData.tags.length > 0 && (
					<div className="d-flex align-items-center p-0" style={{ gap: 16 }}>
						{tilData.tags.map((item) => (
							<div
								key={item}
								className="p-2 font-weight-bold rounded"
								style={{ backgroundColor: "#D4E4FC", color: "#34506E" }}
							>
								{item}
							</div>
						))}
					</div>
				)}
				<div
					className="d-flex align-items-center cursor-pointer ml-auto"
					style={{ gap: "16px" }}
					onClick={() => {
						setShowComments(!showComments);
					}}
				>
					<i className="bi bi-caret-down-fill fs-16" />
					<div className="font-weight-semi fs-16">Comments</div>
				</div>
			</div>
			{showComments && (
				<>
					<div className="d-flex align-items-start mt-3" style={{ gap: "16px" }}>
						<div
							className="d-flex flex-column align-items-center justify-content-center p-0 avatar-image-container"
							style={{ width: 50, height: 50 }}
						>
							<img alt="Profile" src={tilData.profile} />
						</div>
						<div className="d-flex flex-fill align-items-center border rounded p-3" style={{ gap: "16px" }}>
							<input
								value={comment}
								className="border-0 ml-4 flex-fill"
								placeholder="Leave a comment..."
								style={{ minWidth: 50 }}
								onChange={(event) => setComment(event.target.value)}
							/>
							<i className="bi bi-code-slash fs-20 ml-auto cursor-pointer" />
							<i className="fa fa-m fs-14 border rounded p-1 cursor-pointer" />
							<i className="bi bi-sticky fs-20 cursor-pointer" />
						</div>
						<div
							className="bg-primary d-flex align-items-center justify-content-center rounded cursor-pointer"
							style={{ width: 50, height: 50, ...(comment.length === 0 && { opacity: 0.5, cursor: "not-allowed" }) }}
						>
							<i className="bi bi-send-fill fs-20 text-white" />
						</div>
					</div>
					<div className="my-3" style={{ height: 2, backgroundColor: "#474747" }} />
					{tilData.comments && tilData.comments.length === 0 && (
						<div className="font-weight-semi fs-16 text-center">No comments</div>
					)}
					{tilData.comments &&
						tilData.comments.length > 0 &&
						tilData.comments.map((item, index) => {
							return (
								<div key={index} className="d-flex align-items-center" style={{ gap: 16 }}>
									<div
										className="d-flex flex-column align-items-center justify-content-center p-0 avatar-image-container"
										style={{ width: 25, height: 25 }}
									>
										<img alt="Profile" src={item.profile} />
									</div>
									<div className="flex-fill">
										<div className="flex-fill p-2 rounded" style={{ backgroundColor: "#F2F2F2" }}>
											<div className="font-weight-bold">{item.fullName}</div>
											<div>{item.comment}</div>
										</div>
										<div className="d-flex align-items-center" style={{ gap: 1 }}>
											<div>Just now</div>
											<i className="bi bi-dot fs-20" />
											<div className="cursor-pointer">Like</div>
											<i className="bi bi-dot fs-20" />
											<div className="cursor-pointer">Reply</div>
											<i className="bi bi-dot fs-20" />
											<div className="cursor-pointer">Delete</div>
											<i className="bi bi-dot fs-20" />
											<div className="cursor-pointer">Edit</div>
										</div>
									</div>
								</div>
							);
						})}
				</>
			)}
		</div>
	);
}

export default TILCard;
