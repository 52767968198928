import { Cookies } from "react-cookie";

import x_api_key from "../../../src/keyfile"; //create this file, add to gitignore and export that value

export class StaticValue {
	constructor() {}

	getDeafaulValues() {
		return {
			noResumeId: "new-resume",
			apiUrl: "https://pxhwd7ngq1.execute-api.us-east-1.amazonaws.com/dev/",
			resumeDownloadApi: "https://eq4oew62qi.execute-api.us-east-1.amazonaws.com/dev/",
			localStorageResumeId: "resume_id",
			commonHeader: {
				"Content-Type": "application/json",
				"x-api-key": x_api_key,
				Authorization: new Cookies().get("Authorization") ? new Cookies().get("Authorization") : "",
				Accept: "application/json",
			},
		};
	}
}
