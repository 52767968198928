import React from "react";

import FeedBackModal from "../../../../components/Reviews/FeedBackModal/FeedBackModal";
import { sections } from "../../../../handlers/enums";
import { convertDateToWords, convertUlToArray, removeHTTPAndDomainName } from "../../../../handlers/utilityFunctions";
import useReviewFeedBack from "../../../../hooks/use-ReviewFeedBack";

function ResumePremiumEight(props) {
	const { isReview, reviewData, resumeData, hasFeedback, activeSection, onSubmitFeedBack, loadingSubmission } = props;
	const { useReviewFeedback, feedBackButton } = useReviewFeedBack(reviewData);

	return (
		<div className="final-resume-preview-wrapper final-premium-template-5 final-premium-template-8 final-resume-preview-new shadow-sm">
			<div className=" text-dark py-3">
				<div className="container">
					{resumeData.contactInfo.firstName !== "" && (
						<div className="mb-3">
							<div className="info-wrapper">
								<div>
									<h1>
										{resumeData.contactInfo.firstName} {resumeData.contactInfo.lastName}
									</h1>
									<h5 className="mb-2">{resumeData.contactInfo.resumeTitle}</h5>
									<div
										className={`position-relative review-section ${
											isReview
												? "rs-highlight"
												: hasFeedback
													? activeSection === sections.SUMMARY
														? "rs-feedback rs-feedback-active"
														: "rs-feedback"
													: ""
										}`}
										id={sections.SUMMARY}
										style={{
											backgroundColor:
												useReviewFeedback.section.section === sections.SUMMARY ? "rgba(27, 175, 245, 0.12)" : "",
										}}
									>
										{isReview || activeSection === sections.SUMMARY
											? feedBackButton(sections.SUMMARY, hasFeedback)
											: null}
										<p
											className="mb-2"
											dangerouslySetInnerHTML={{
												__html: resumeData.summary,
											}}
										/>
									</div>
								</div>
							</div>
							<div
								className="row p-2 p-md-3 mx-0 my-2 my-md-4 text-white flex-wrap"
								style={{ backgroundColor: "#282828", borderRadius: "10px" }}
							>
								<div className="left col-6">
									<p className="mb-2">
										<i className="contact-icon mr-2 fa fa-envelope" aria-hidden="true" />
										{resumeData.contactInfo.email}
									</p>
									<p className="mb-2 contact-icon">
										<i className="mr-2 fa fa-map-marker" aria-hidden="true" />
										{resumeData.contactInfo.city}, {resumeData.contactInfo.country}
									</p>
									{[...resumeData.extras.personalLinks].map((link, index) => {
										return link.type === "linkedIn" ? (
											<p key={index} className="mb-2 contact-icon">
												<i className="mr-2 bi bi-linkedin" aria-hidden="true" />
												<a href={link.value} target="_blank" rel="noreferrer" style={{ color: "#ffffff" }}>
													{removeHTTPAndDomainName(link)}
												</a>
											</p>
										) : null;
									})}
								</div>
								<div className="right col-6">
									<p className="mb-2 contact-icon">
										<i className="mr-2 fa fa-mobile" aria-hidden="true" />
										{resumeData.contactInfo.phoneNo}
									</p>
									{[...resumeData.extras.personalLinks].map((link, index) => {
										return link.type !== "linkedIn" && link.type !== "otherLink" ? (
											<p key={index} className="mb-2 contact-icon">
												<i
													className={`mr-2 ${
														link.type === "twitter"
															? "bi bi-twitter"
															: link.type === "github"
																? "bi bi-github"
																: "fa fa-link"
													}`}
													aria-hidden="true"
												/>
												<a href={link.value} target="_blank" rel="noreferrer" style={{ color: "#ffffff" }}>
													{removeHTTPAndDomainName(link)}
												</a>
											</p>
										) : null;
									})}
								</div>
							</div>
						</div>
					)}

					{resumeData.skills !== "" && convertUlToArray(resumeData.skills) ? (
						<div className="mb-3 mb-md-4">
							<h4 className="d-flex align-items-center text-uppercase my-2 my-md-3 font-weight-bold">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#191919",
										flex: "0 0 48px",
										height: "48px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "20px",
									}}
								>
									<i className="fa fa-cogs" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								skills
							</h4>

							<ul
								className={`p-0 mb-0 position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.SKILLS
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.SKILLS}
								style={{
									listStyle: "none",
									backgroundColor:
										useReviewFeedback.section.section === sections.SKILLS ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.SKILLS ? feedBackButton(sections.SKILLS, hasFeedback) : null}
								{convertUlToArray(resumeData.skills).map((item, index) => {
									const splitArray = item.value.split(/:(.+)/);
									let wordBeforeColon = splitArray[0].trim();
									wordBeforeColon = splitArray.length > 1 ? <strong>{wordBeforeColon}</strong> : wordBeforeColon;
									const wordAfterColon = splitArray.length > 1 ? ": " + splitArray[1].trim() : "";

									return (
										<li key={index} className="mb-2">
											{wordBeforeColon}
											{wordAfterColon}
										</li>
									);
								})}
							</ul>
						</div>
					) : null}

					{resumeData.extras.certifications !== "" ? (
						<>
							<div className="separator" style={{ backgroundColor: "#8c8c8c", width: "100%", borderRadius: "100px" }}>
								<div style={{ backgroundColor: "#191919", width: "70%", borderRadius: "100px", padding: "4px 0" }} />
							</div>
							<div className="mb-3 mb-md-4">
								<h4 className="d-flex align-items-center text-uppercase my-2 my-md-3 font-weight-bold">
									<span
										className="title-icon rounded-circle mr-2"
										style={{
											backgroundColor: "#191919",
											flex: "0 0 48px",
											height: "48px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "20px",
										}}
									>
										<i className="fa fa-certificate" aria-hidden="true" style={{ color: "#fff" }} />
									</span>
									certificates
								</h4>

								<div
									className={`row position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.CERTIFICATION
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.CERTIFICATION}
									style={{
										backgroundColor:
											useReviewFeedback.section.section === sections.CERTIFICATION ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.CERTIFICATION
										? feedBackButton(sections.CERTIFICATION, hasFeedback)
										: null}
									{convertUlToArray(resumeData.extras.certifications).map((item, index) => {
										return (
											<p key={index} className="col-6 mb-2 pr-2">
												{item.value}
											</p>
										);
									})}
								</div>
							</div>
						</>
					) : null}

					{resumeData.extras.accomplishments !== "" ? (
						<>
							<div className="separator" style={{ backgroundColor: "#8c8c8c", width: "100%", borderRadius: "100px" }}>
								<div style={{ backgroundColor: "#191919", width: "70%", borderRadius: "100px", padding: "4px 0" }} />
							</div>
							<div className="mb-3 mb-md-4">
								<h4 className="d-flex align-items-center text-uppercase my-2 my-md-3 font-weight-bold">
									<span
										className="title-icon rounded-circle mr-2"
										style={{
											backgroundColor: "#191919",
											flex: "0 0 48px",
											height: "48px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "20px",
										}}
									>
										<i className="fa fa-certificate" aria-hidden="true" style={{ color: "#fff" }} />
									</span>
									accomplishments
								</h4>

								<ul
									className={`p-0 mb-0 position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.ACCOMPLISHMENTS
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.ACCOMPLISHMENTS}
									style={{
										listStyle: "none",
										backgroundColor:
											useReviewFeedback.section.section === sections.ACCOMPLISHMENTS ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.ACCOMPLISHMENTS
										? feedBackButton(sections.ACCOMPLISHMENTS, hasFeedback)
										: null}
									{convertUlToArray(resumeData.extras.accomplishments).map((item, index) => {
										return (
											<li key={index} className="mb-2">
												{item.value}
											</li>
										);
									})}
								</ul>
							</div>
						</>
					) : null}

					{resumeData.workHistory.length > 0 && resumeData.workHistory[0].jobTitle !== "" ? (
						<>
							<div className="separator" style={{ backgroundColor: "#8c8c8c", width: "100%", borderRadius: "100px" }}>
								<div style={{ backgroundColor: "#191919", width: "70%", borderRadius: "100px", padding: "4px 0" }} />
							</div>
							<div className="mb-3 mb-md-4">
								<h4 className="d-flex align-items-center mt-2 mt-md-3 font-weight-bold mb-0">
									<span
										className="title-icon rounded-circle mr-2"
										style={{
											backgroundColor: "#191919",
											flex: "0 0 48px",
											height: "48px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "20px",
										}}
									>
										<i className="fa fa-briefcase" aria-hidden="true" style={{ color: "#fff" }} />
									</span>
									WORK EXPERIENCE
								</h4>
								<div
									className={`position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.EXPERIENCE
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.EXPERIENCE}
									style={{
										listStyle: "none",
										backgroundColor:
											useReviewFeedback.section.section === sections.EXPERIENCE ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.EXPERIENCE
										? feedBackButton(sections.EXPERIENCE, hasFeedback)
										: null}
									{[...resumeData.workHistory].map((job, index) => (
										<React.Fragment key={index}>
											<div className="d-flex">
												<div
													className="left-border"
													style={{
														width: "2px",
														background: "#191919",
														margin: "0 23px",
													}}
												/>
												<div className="content">
													<div className="d-flex justify-content-between">
														<p className="mb-2">
															<strong>{job.employer}</strong>
															{" - "}
															{job.city}, {job.country}
															<br />
															<em>{job.jobTitle}</em>
														</p>
														<p className="mb-0" style={{ color: "#449399" }}>
															<strong>
																{convertDateToWords(job.startDate)} -{" "}
																{job.endDate !== "" ? convertDateToWords(job.endDate) : "Present"}
															</strong>
														</p>
													</div>
													<ul className="px-3 mb-0 mb-md-3" style={{ listStyle: "disc" }}>
														{convertUlToArray(job.details).map((item, index) => {
															return (
																<li key={index} className="mb-2">
																	{item.value}
																</li>
															);
														})}
													</ul>
												</div>
											</div>
										</React.Fragment>
									))}
								</div>
							</div>
						</>
					) : null}

					{resumeData.education.length > 0 && (
						<>
							<div className="separator" style={{ backgroundColor: "#8c8c8c", width: "100%", borderRadius: "100px" }}>
								<div style={{ backgroundColor: "#191919", width: "70%", borderRadius: "100px", padding: "4px 0" }} />
							</div>
							<div className="mb-2 mb-md-3">
								<h4 className="text-uppercase mt-2 mt-md-3 mb-0 font-weight-bold d-flex align-items-center">
									<span
										className="title-icon rounded-circle mr-2"
										style={{
											backgroundColor: "#191919",
											flex: "0 0 48px",
											height: "48px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "20px",
										}}
									>
										<i className="fa fa-graduation-cap" aria-hidden="true" style={{ color: "#fff" }} />
									</span>
									EDUCATION
								</h4>

								<div className="row education-list">
									<div
										className={`col-12 position-relative review-section ${
											isReview
												? "rs-highlight"
												: hasFeedback
													? activeSection === sections.EDUCATION
														? "rs-feedback rs-feedback-active"
														: "rs-feedback"
													: ""
										}`}
										id={sections.EDUCATION}
										style={{
											backgroundColor:
												useReviewFeedback.section.section === sections.EDUCATION ? "rgba(27, 175, 245, 0.12)" : "",
										}}
									>
										{isReview || activeSection === sections.EDUCATION
											? feedBackButton(sections.EDUCATION, hasFeedback)
											: null}
										{[...resumeData.education].map((educationHistory, index) => (
											<div key={index} className="mb-3">
												<div className="d-flex justify-content-between">
													<p>
														<strong>{educationHistory.schoolName}</strong>
														{" - "}
														{educationHistory.schoolCity}, <br />
														<em>{educationHistory.fieldOfStudy}</em>
													</p>
													<p className="mr-4" style={{ color: "#449399" }}>
														<strong>{educationHistory.gYear}</strong>
													</p>
												</div>
												<ul className="px-3 mb-0 mb-md-3" style={{ listStyle: "disc" }}>
													{convertUlToArray(educationHistory.description).map((item, index) => {
														if (item.value === "") {
															return null;
														}
														return (
															<li key={index} className="mb-2">
																{item.value}
															</li>
														);
													})}
												</ul>
											</div>
										))}
									</div>
								</div>
							</div>
						</>
					)}

					{resumeData.extras.languages.length !== 0 ? (
						<>
							<div className="separator" style={{ backgroundColor: "#8c8c8c", width: "100%", borderRadius: "100px" }}>
								<div style={{ backgroundColor: "#191919", width: "70%", borderRadius: "100px", padding: "4px 0" }} />
							</div>
							<div className="mb-3">
								<h4 className="text-uppercase mt-2 mt-md-3 mb-0 font-weight-bold d-flex align-items-center">
									<span
										className="title-icon rounded-circle mr-2"
										style={{
											backgroundColor: "#191919",
											flex: "0 0 48px",
											height: "48px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "20px",
										}}
									>
										<i className="fa fa-language" aria-hidden="true" style={{ color: "#fff" }} />
									</span>
									LANGUAGES
								</h4>
								<div
									className={`row no-gutters position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.LANGUAGES
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.LANGUAGES}
									style={{
										backgroundColor:
											useReviewFeedback.section.section === sections.LANGUAGES ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.LANGUAGES
										? feedBackButton(sections.LANGUAGES, hasFeedback)
										: null}
									{resumeData.extras.languages.map((language, index) => (
										<div key={index} className="col-6">
											<h6 className="mt-2 mb-0 font-weight-bold">
												{language.language}
												<p className="font-italic mb-0" style={{ color: "#777777", fontWeight: 500 }}>
													{language.proficiency}
												</p>
											</h6>
										</div>
									))}
								</div>
							</div>
						</>
					) : null}

					{resumeData.extras.personalProfile.dateOfBirth && (
						<>
							<div className="separator" style={{ backgroundColor: "#8c8c8c", width: "100%", borderRadius: "100px" }}>
								<div style={{ backgroundColor: "#191919", width: "70%", borderRadius: "100px", padding: "4px 0" }} />
							</div>
							<div className="mb-2 mb-md-3">
								<h4 className="text-uppercase mt-2 mt-md-3 font-weight-bold d-flex align-items-center">
									<span
										className="title-icon rounded-circle mr-2"
										style={{
											backgroundColor: "#191919",
											flex: "0 0 48px",
											height: "48px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "20px",
										}}
									>
										<i className="fa fa-user" aria-hidden="true" style={{ color: "#fff" }} />
									</span>
									Personal Information
								</h4>
								<div
									className={`row position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.PERSONAL
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.PERSONAL}
									style={{
										backgroundColor:
											useReviewFeedback.section.section === sections.PERSONAL ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.PERSONAL
										? feedBackButton(sections.PERSONAL, hasFeedback)
										: null}
									<div className="col-6">
										<div className="mb-2 mb-md-3">
											<strong>Date of Birth:</strong>&nbsp;
											{resumeData.extras.personalProfile.dateOfBirth}
										</div>
									</div>
									<div className="col-6">
										<div className="mb-2 mb-md-3">
											<strong>Gender:</strong>&nbsp;
											{resumeData.extras.personalProfile.gender}
										</div>
									</div>
									<div className="col-6">
										<div className="mb-2 mb-md-3">
											<strong>Marital Status:</strong>&nbsp;
											{resumeData.extras.personalProfile.maritalStatus}
										</div>
									</div>
								</div>
							</div>
						</>
					)}

					{resumeData.extras.affiliations !== "" ? (
						<>
							<div className="separator position-relative" style={{ height: "2px" }}>
								<div
									className="py-1 position-absolute"
									style={{ backgroundColor: "#191919", width: "70%", borderRadius: "100px" }}
								/>
								<div className="py-1" style={{ backgroundColor: "#8c8c8c", width: "100%", borderRadius: "100px" }} />
							</div>
							<div className="mb-2 mb-md-3">
								<h4 className="text-uppercase mt-2 mt-md-3 font-weight-bold d-flex align-items-center">
									<span
										className="title-icon rounded-circle mr-2"
										style={{
											backgroundColor: "#191919",
											flex: "0 0 48px",
											height: "48px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "20px",
										}}
									>
										<i className="fa fa-handshake" aria-hidden="true" style={{ color: "#fff" }} />
									</span>
									affiliations
								</h4>
								<div
									className={`row position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.AFFILIATION
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.AFFILIATION}
									style={{
										backgroundColor:
											useReviewFeedback.section.section === sections.AFFILIATION ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.AFFILIATION
										? feedBackButton(sections.AFFILIATION, hasFeedback)
										: null}
									{convertUlToArray(resumeData.extras.affiliations).map((item, index) => {
										return (
											<p key={index} className="col-6 mb-2 pr-2">
												{item.value}
											</p>
										);
									})}
								</div>
							</div>
						</>
					) : null}

					{resumeData.extras.additionalInfo !== "" ? (
						<>
							<div className="separator" style={{ backgroundColor: "#8c8c8c", width: "100%", borderRadius: "100px" }}>
								<div style={{ backgroundColor: "#191919", width: "70%", borderRadius: "100px", padding: "4px 0" }} />
							</div>
							<div className="mb-2 mb-md-3">
								<h4 className="text-uppercase mt-2 mt-md-3 font-weight-bold d-flex align-items-center">
									<span
										className="title-icon rounded-circle mr-2"
										style={{
											backgroundColor: "#191919",
											flex: "0 0 48px",
											height: "48px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "20px",
										}}
									>
										<i className="fa fa-info" aria-hidden="true" style={{ color: "#fff" }} />
									</span>
									additional information
								</h4>

								<div
									className={`row position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.ADDITIONAL_INFO
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.ADDITIONAL_INFO}
									style={{
										backgroundColor:
											useReviewFeedback.section.section === sections.ADDITIONAL_INFO ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.ADDITIONAL_INFO
										? feedBackButton(sections.ADDITIONAL_INFO, hasFeedback)
										: null}
									{convertUlToArray(resumeData.extras.additionalInfo).map((item, index) => {
										return (
											<p key={index} className="col-6 mb-2 pr-2">
												{item.value}
											</p>
										);
									})}
								</div>
							</div>
						</>
					) : null}

					{[...resumeData.extras.personalLinks].some((link) => link.type === "otherLink") && (
						<>
							<div className="separator" style={{ backgroundColor: "#8c8c8c", width: "100%", borderRadius: "100px" }}>
								<div style={{ backgroundColor: "#191919", width: "70%", borderRadius: "100px", padding: "4px 0" }} />
							</div>
							<div className="mb-3">
								<h4 className="text-uppercase mt-2 mt-md-3 font-weight-bold d-flex align-items-center">
									<span
										className="title-icon rounded-circle mr-2"
										style={{
											backgroundColor: "#191919",
											flex: "0 0 48px",
											height: "48px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "20px",
										}}
									>
										<i className="fa fa-link" aria-hidden="true" style={{ color: "#fff" }} />
									</span>
									website, portfolio, profiles
								</h4>
								<ul
									className={`p-0 mb-0 position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.LINKS
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.LINKS}
									style={{
										listStyle: "none",
										backgroundColor:
											useReviewFeedback.section.section === sections.LINKS ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.LINKS ? feedBackButton(sections.LINKS, hasFeedback) : null}
									{[...resumeData.extras.personalLinks].map((link, index) => {
										return link.type === "otherLink" ? (
											<li key={index} className="mb-2">
												{link.value}
											</li>
										) : null;
									})}
								</ul>
							</div>
						</>
					)}

					{resumeData.extras.references.length > 0 ? (
						<>
							<div className="separator" style={{ backgroundColor: "#8c8c8c", width: "100%", borderRadius: "100px" }}>
								<div style={{ backgroundColor: "#191919", width: "70%", borderRadius: "100px", padding: "4px 0" }} />
							</div>
							<div className="mb-3">
								<h4 className="text-uppercase mt-2 mt-md-3 font-weight-bold d-flex align-items-center">
									<span
										className="title-icon rounded-circle mr-2"
										style={{
											backgroundColor: "#191919",
											flex: "0 0 48px",
											height: "48px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "20px",
										}}
									>
										<i className="fa fa-asterisk" aria-hidden="true" style={{ color: "#fff" }} />
									</span>
									references
								</h4>
								<div
									className={`row position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.REFERENCE
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.REFERENCE}
									style={{
										backgroundColor:
											useReviewFeedback.section.section === sections.REFERENCE ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.REFERENCE
										? feedBackButton(sections.REFERENCE, hasFeedback)
										: null}
									{[...resumeData.extras.references].map((reference, index) => (
										<div key={index} className="col-6 d-flex mb-3">
											<div>
												<strong>{reference.name}</strong>
												{" - "}
												<em>{reference.designation}, </em>
												{reference.workPlace}
												<br />
												<p style={{ color: "#bcbcbc" }}>
													{reference.email}, {reference.phoneNumber}
												</p>
											</div>
										</div>
									))}
								</div>
							</div>
						</>
					) : null}

					{resumeData.extras.customExtras.name.length > 0 ? (
						<>
							<div className="separator" style={{ backgroundColor: "#8c8c8c", width: "100%", borderRadius: "100px" }}>
								<div style={{ backgroundColor: "#191919", width: "70%", borderRadius: "100px", padding: "4px 0" }} />
							</div>
							<div className="mb-3">
								<h4 className="text-uppercase mt-2 mt-md-3 font-weight-bold d-flex align-items-center">
									<span
										className="title-icon rounded-circle mr-2"
										style={{
											backgroundColor: "#191919",
											flex: "0 0 48px",
											height: "48px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "20px",
										}}
									>
										<i className="bi bi-journal-text" aria-hidden="true" style={{ color: "#fff" }} />
									</span>
									{resumeData.extras.customExtras.name}
								</h4>
								<div
									className={`row position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.CUSTOM
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.CUSTOM}
									style={{
										backgroundColor:
											useReviewFeedback.section.section === sections.CUSTOM ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.CUSTOM ? feedBackButton(sections.CUSTOM, hasFeedback) : null}
									{convertUlToArray(resumeData.extras.customExtras.data).map((item, index) => {
										return (
											<p key={index} className="col-6 mb-2 pr-2">
												{item.value}
											</p>
										);
									})}
								</div>
							</div>
						</>
					) : null}
				</div>
			</div>
			{useReviewFeedback.showFeedBackModal && (
				<FeedBackModal
					resumeData={resumeData}
					details={useReviewFeedback.section}
					onSubmitFeedBack={onSubmitFeedBack}
					loadingSubmission={loadingSubmission}
				/>
			)}
		</div>
	);
}

export default ResumePremiumEight;
