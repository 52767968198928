const skills = {
	content: [
		{
			bp: false,
			value:
				"A few keywords bullet points( up to 12, three columns) or a brief listing of your most notable skills, education, and experiences as they relate to your target opportunities.",
		},
		{
			bp: false,
			value:
				"Highlighting particular skills or specialized training you possess can provide readers with additional information about your capabilities.",
		},
		{
			bp: true,
			value:
				"Only include skills that are both relevant to the role and those recruiters won't reasonably assume you already have.",
		},
		{
			bp: true,
			value:
				"If you possess anything more than basic proficiency with a common piece of software, you should highlight it to differentiate your skillset",
		},
		{
			bp: true,
			value:
				"Use following designation to indicate your skill level with a certain piece of software in order of increasing proficiency:<ul><li>Intermediate (top 50% expertise)</li><li>Advanced (top 75% expertise)</li><li>Expert (top 95% expertise)</li><li>Ex. Adobe photoshop, Salesforce, Excel (Advanced)</li></ul>",
		},
		{
			bp: true,
			value:
				"Also a place of highlighting any languages you may have proficiency in<ul><li>Languages: Spanish (Business), Arabic (Fluent)</li></ul>",
		},
	],
};

export default skills;
