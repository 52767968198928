import React from "react";

function SubscriptionOptions({ setSelectedPackage, usePoints, setUsePoints }) {
	return (
		<section className="subscription-wrapper bg-white py-4">
			<div>
				<h3 className="text-center ">Dproz Plans: Stand Out, Earn Rewards!</h3>
				<p className="lead fs-20 mb-5">
					Unlock success with our affordable plans! <em>Start FREE</em> with Bronze, Earn points as you enhance your
					profile. Upgrade to Silver, Gold, or Platinum for premium features at a lower cost.&nbsp; Use your reward
					points to pay for most of it. Dare to stand out with Dproz – plans designed for success.
				</p>
				<div
					className="row bg-white shadow"
					style={{ borderTopWidth: "5px", borderTopColor: "#4DA7EE", borderTopStyle: "solid" }}
				>
					<div className="col-md-3 border d-flex flex-column justify-content-between">
						<div className="mt-4 package-container d-flex flex-column justify-content-between">
							<div>
								<h4>Bronze</h4>
								<p>The quickest and easiest way to try DPROZ</p>
							</div>
							<div>
								<h4>Tsh 0.00/=</h4>
								<div className="text-center mb-4">
									<button className="btn border-light-blue btn-block" disabled>
										INCLUDED
									</button>
								</div>
							</div>
						</div>
						<div className="mb-auto">
							<ul className="pl-3 fs-13 mb-4 flex-grow-1 mx-2 pt-4" style={{ listStyleType: "none" }}>
								<li className="mb-1" style={{ position: "relative" }}>
									<span style={{ position: "absolute", left: "-20px" }}>&#x2713;</span>
									Build 1 resume with step by step guidance
								</li>
								<li className="mb-1" style={{ position: "relative" }}>
									<span style={{ position: "absolute", left: "-20px" }}>&#x2713;</span>
									Access to standard resume templates
								</li>
								<li className="mb-1" style={{ position: "relative" }}>
									<span style={{ position: "absolute", left: "-20px" }}>&#x2713;</span>
									Systematic completeness check
								</li>
								<li className="mb-1" style={{ position: "relative" }}>
									<span style={{ position: "absolute", left: "-20px" }}>&#x2713;</span>
									Expertly crafted contents
								</li>
							</ul>
						</div>
					</div>

					<div className="col-md-3 d-flex flex-column justify-content-between">
						<div className="mt-4 package-container d-flex flex-column justify-content-between">
							<div>
								<h4>Silver</h4>
								<p>14 Days full Access</p>
								<div className="form-check fs-14">
									<input
										checked={usePoints}
										className="form-check-input"
										type="checkbox"
										value=""
										id="silverPackage"
										onClick={() => setUsePoints(!usePoints)}
										onChange={() => {}}
									/>
									<label className="form-check-label text-danger" htmlFor="platinumPackage">
										Save with Reward Points up to <strong>&#54;&#49;&#189;&#37;</strong>
									</label>
								</div>
							</div>
							<div>
								<div className="d-flex align-items-center flex-wrap justify-content-around">
									{usePoints && (
										<p className="text-muted fs-16">
											<del>Tsh 1,950.00/=</del>
										</p>
									)}
									<h5>{usePoints ? "Tsh 750.00/=" : "Tsh 1,950.00/="}</h5>
								</div>
								<div className="text-center mb-4">
									<button
										className="btn text-uppercase btn-success btn-block"
										onClick={() => setSelectedPackage("silver")}
									>
										Select Package
									</button>
								</div>
							</div>
						</div>
						<div className="mb-auto">
							<h6>All the goodness of Bronze and:</h6>
							<ul className="pl-3 fs-13 mb-4 flex-grow-1 mx-2 pt-4" style={{ listStyleType: "none" }}>
								<li className="mb-1" style={{ position: "relative" }}>
									<span style={{ position: "absolute", left: "-20px" }}>&#x2713;</span>
									Build 1 additional resume tailored to specific job (2 resumes)
								</li>
								<li className="mb-1" style={{ position: "relative" }}>
									<span style={{ position: "absolute", left: "-20px" }}>&#x2713;</span>
									Unlimited resume downloads & share
								</li>
							</ul>
						</div>
					</div>

					<div className="col-md-3 border d-flex flex-column justify-content-between">
						<div className="mt-4 package-container d-flex flex-column justify-content-between">
							<div>
								<div className="d-flex justify-content-between">
									<h4 className="mr-auto">Gold</h4>
									<div>
										<span className="badge badge-pill badge-warning">Save 69%</span>
									</div>
								</div>
								<div className="d-flex justify-content-between">
									<p>60 Days full Access</p>
									<p className="text-muted">
										<del>Tsh 8,500.00/=</del>
									</p>
								</div>
								<div className="form-check fs-14">
									<input
										checked={usePoints}
										className="form-check-input"
										type="checkbox"
										value=""
										id="goldPackage"
										onClick={() => setUsePoints(!usePoints)}
										onChange={() => {}}
									/>
									<label className="form-check-label text-danger" htmlFor="platinumPackage">
										Save with Reward Points up to <strong>&#54;&#49;&#189;&#37;</strong>
									</label>
								</div>
							</div>
							<div>
								<div className="d-flex align-items-center flex-wrap justify-content-around">
									{usePoints && (
										<p className="text-muted fs-16">
											<del>Tsh 5,850.00/=</del>
										</p>
									)}
									<h5>{usePoints ? "Tsh 2,250.00/=" : "Tsh 5,850.00/="}</h5>
								</div>
								<div className="text-center mb-4">
									<button
										className="btn text-uppercase btn-success btn-block"
										onClick={() => setSelectedPackage("gold")}
									>
										Select Package
									</button>
								</div>
							</div>
						</div>
						<div className="mb-auto">
							<h6>All the goodness of Silver and:</h6>
							<ul className="pl-3 fs-13 mb-4 flex-grow-1 mx-2 pt-4" style={{ listStyleType: "none" }}>
								<li className="mb-1" style={{ position: "relative" }}>
									<span style={{ position: "absolute", left: "-20px" }}>&#x2713;</span>
									Build 2 additional resumes tailored to specific jobs (4 resumes)
								</li>
								<li className="mb-1" style={{ position: "relative" }}>
									<span style={{ position: "absolute", left: "-20px" }}>&#x2713;</span>
									Easily request & receive unlimited resume improvement suggestions from your network of trusted
									individuals
								</li>
								<li className="mb-1" style={{ position: "relative" }}>
									<span style={{ position: "absolute", left: "-20px" }}>&#x2713;</span>
									Unlimited Access to premium resume templates
								</li>
							</ul>
						</div>
					</div>

					<div className="col-md-3 d-flex flex-column justify-content-between">
						<div className="mt-4 package-container d-flex flex-column justify-content-between">
							<div>
								<h4>Platinum</h4>
								<p>12 Months full Access</p>
								<div className="form-check fs-14">
									<input
										checked={usePoints}
										className="form-check-input"
										type="checkbox"
										value=""
										id="platinumPackage"
										onClick={() => setUsePoints(!usePoints)}
										onChange={() => {}}
									/>
									<label className="form-check-label text-danger" htmlFor="platinumPackage">
										Save with Reward Points up to <strong>&#54;&#49;&#189;&#37;</strong>
									</label>
								</div>
							</div>
							<div>
								<div className="d-flex align-items-center flex-wrap justify-content-around">
									{usePoints && (
										<p className="text-muted fs-16">
											<del>Tsh 51,500.00/=</del>
										</p>
									)}
									<h5>{usePoints ? "Tsh 19,828.00/=" : "Tsh 51,500.00/="}</h5>
								</div>
								<div className="text-center mb-4">
									<button
										className="btn text-uppercase btn-success btn-block"
										onClick={() => setSelectedPackage("platinum")}
									>
										Select Package
									</button>
								</div>
							</div>
						</div>
						<div className="mb-auto">
							<h6>All the greatness of Gold and:</h6>
							<ul className="pl-3 fs-13 mb-4 flex-grow-1 mx-2 pt-4" style={{ listStyleType: "none" }}>
								<li className="mb-1" style={{ position: "relative" }}>
									<span style={{ position: "absolute", left: "-20px" }}>&#x2713;</span>
									Build 3 additional resumes tailored to specific jobs (7 resumes)
								</li>
								<li className="mb-1" style={{ position: "relative" }}>
									<span style={{ position: "absolute", left: "-20px" }}>&#x2713;</span>2 rounds of async resume review
									by 2 Dproz experts
								</li>
								<li className="mb-1" style={{ position: "relative" }}>
									<span style={{ position: "absolute", left: "-20px" }}>&#x2713;</span>
									Unlimited Access to premium resume templates
								</li>
								<li className="mb-1" style={{ position: "relative" }}>
									<span style={{ position: "absolute", left: "-20px" }}>&#x2713;</span>1 round (45 minutes) of career
									conversations with (QA, Interview Prep) Dproz Expert
								</li>
							</ul>
						</div>
					</div>
				</div>
				<p className="py-4 text-center fs-12">You may cancel by contacting support.</p>

				<p className="py-2 h1 text-center">Compare Plans</p>

				<div className="table-responsive">
					<table className="table text-center">
						<thead>
							<tr>
								<th style={{ width: "32%" }} />
								<th style={{ width: "17%" }}>Bronze</th>
								<th style={{ width: "17%" }}>Silver</th>
								<th className="dotted-border-top" style={{ width: "17%", borderTop: "2px dotted #FFD700" }}>
									Gold
								</th>
								<th style={{ width: "17%" }}>Platinum</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th scope="row" className="text-left">
									Step by step Guidance
								</th>
								<td>&#x2713;</td>
								<td>&#x2713;</td>
								<td className="border-horizontal">&#x2713;</td>
								<td>&#x2713;</td>
							</tr>
							<tr>
								<th scope="row" className="text-left">
									Expertly Crafted Contents
								</th>
								<td>&#x2713;</td>
								<td>&#x2713;</td>
								<td className="border-horizontal">&#x2713;</td>
								<td>&#x2713;</td>
							</tr>
							<tr>
								<th scope="row" className="text-left">
									Completeness check
								</th>
								<td>&#x2713;</td>
								<td>&#x2713;</td>
								<td className="border-horizontal">&#x2713;</td>
								<td>&#x2713;</td>
							</tr>
							<tr>
								<th scope="row" className="text-left">
									Standard Professional Template
								</th>
								<td>&#x2713;</td>
								<td>&#x2713;</td>
								<td className="border-horizontal">&#x2713;</td>
								<td>&#x2713;</td>
							</tr>
							<tr>
								<th scope="row" className="text-left">
									Unlimited Edits
								</th>
								<td>&#x2713;</td>
								<td>&#x2713;</td>
								<td className="border-horizontal">&#x2713;</td>
								<td>&#x2713;</td>
							</tr>
							<tr>
								<th scope="row" className="text-left">
									Build targeted Resumes
								</th>
								<td>1</td>
								<td>2</td>
								<td className="border-horizontal">4</td>
								<td>7</td>
							</tr>
							<tr>
								<th scope="row" className="text-left">
									Premium Professional Template
								</th>
								<td />
								<td />
								<td className="border-horizontal">&#x2713;</td>
								<td>&#x2713;</td>
							</tr>
							<tr>
								<th scope="row" className="text-left">
									PDF Downloads & Shares
								</th>
								<td />
								<td>&#x2713;</td>
								<td className="border-horizontal">&#x2713;</td>
								<td>&#x2713;</td>
							</tr>
							<tr>
								<th scope="row" className="text-left">
									Resume Review - Receive Improvement Suggestions
								</th>
								<td />
								<td />
								<td className="border-horizontal">&#x2713;</td>
								<td>&#x2713;</td>
							</tr>
							<tr>
								<th scope="row" className="text-left">
									Async Reviews by Dproz Experts
								</th>
								<td />
								<td />
								<td className="border-horizontal" />
								<td>2 rounds, 2 experts</td>
							</tr>
							<tr>
								<th scope="row" className="text-left">
									Career Conversations with Dproz Experts
								</th>
								<td />
								<td />
								<td className="border-horizontal" />
								<td>1 round</td>
							</tr>
							<tr>
								<th scope="row" className="text-left">
									Full Access
								</th>
								<td />
								<td>14 days</td>
								<td className="border-horizontal">60 days</td>
								<td>365 days</td>
							</tr>
							<tr>
								<th scope="row" className="text-left">
									Cost (TZS)
								</th>
								<td>FREE</td>
								<td>1,950</td>
								<td className="border-horizontal">5,850</td>
								<td>51,500</td>
							</tr>
							<tr>
								<th scope="row" className="text-left">
									Cost Per Day (TZS)
								</th>
								<td>0</td>
								<td>{Math.floor(1950 / 14)}</td>
								<td className="border-horizontal">{Math.floor(5850 / 60)}</td>
								<td>{Math.floor(51500 / 365)}</td>
							</tr>
							<tr>
								<th scope="row" className="text-left" />
								<td />
								<td />
								<td className="dotted-border-bottom font-weight-bold">Best Value</td>
								<td />
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</section>
	);
}

export default SubscriptionOptions;
