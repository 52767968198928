import dayjs from "dayjs";
import React from "react";

import { convertArrayToUl } from "../../../handlers/utilityFunctions";
import ListItem from "./ListItem";

function EducationItem({ data }) {
	return (
		<div>
			{data.match_result.map((education, index) => (
				<ListItem pct={education.category_match_pct * 100} key={index}>
					<div className="ml-2">
						<p className="m-0 fw-bold">{education.major}</p>
						<p className="m-0">
							{education.school_name} (
							{dayjs(education.graduation.year + "-" + education.graduation.month + "-27").format("YYYY")})
						</p>
						<p className="m-0" />

						<span
							dangerouslySetInnerHTML={{
								__html: convertArrayToUl(education.relevant_coursework),
							}}
						/>
					</div>
				</ListItem>
			))}
		</div>
	);
}

export default EducationItem;
