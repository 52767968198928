import React from "react";

function TemplatePremiumTen() {
	return (
		<div style={{ scale: "1.2", transformOrigin: "center" }}>
			<div className="small-resume-preview small-resume-preview-premium-template-5  small-resume-preview-premium-template-10">
				<div className="final-resume-preview bg-white resume-type-1 text-dark py-2">
					<div className="container">
						<div className="row mb-2">
							<div className="col-12">
								<h1 className="mb-0" style={{ color: "#479099" }}>
									Jacquiline Khalfan
								</h1>
								<h4 className="mb-2 font-weight-normal" style={{ color: "#d8a65d" }}>
									Business Development Manager
								</h4>
								<p className="mb-1">
									Professional Business Developer with four year of experience in the business Development processes.
									Involved in product testing, management and development of new business opportunities. Professional
									Business Developer with four year of experience in the business Development processes. Involved in
									product testing, management and development of new business opportunities.
								</p>
							</div>
						</div>

						<div className="row mb-2">
							<div className="col-6">
								<p className="mb-1">
									<span className="contact-icon mr-2">
										{" "}
										<i className=" fa fa-envelope " aria-hidden="true" style={{ color: "#d8a65d" }} />
									</span>
									jacquiline.khalfan@dproz.tz
								</p>
							</div>
							<div className="col-6">
								<p className="mb-1">
									<span className="contact-mobile mr-2">
										{" "}
										<i className=" fa fa-phone " aria-hidden="true" style={{ color: "#d8a65d" }} />
									</span>
									202-555-0166
								</p>
							</div>
							<div className="col-6">
								<p className="mb-1">
									<span className="contact-icon mr-2">
										{" "}
										<i className=" fa fa-map-marker " aria-hidden="true" style={{ color: "#d8a65d" }} />
									</span>
									New York, USA
								</p>
							</div>
							<div className="col-6">
								<p className="mb-1">
									<span className="contact-icon mr-2">
										{" "}
										<i className=" fa fa-linkedin " aria-hidden="true" style={{ color: "#d8a65d" }} />
									</span>
									linkedin.com/in/jacquiline.khalfan
								</p>
							</div>
							<div className="col-6">
								<p className="mb-1">
									<span className="contact-icon mr-2">
										{" "}
										<i className=" fa fa-twitter " aria-hidden="true" style={{ color: "#d8a65d" }} />
									</span>
									twitter.com/jacquiline.khalfan
								</p>
							</div>
							<div className="col-6">
								<p className="mb-1">
									<span className="contact-icon mr-2">
										{" "}
										<i className=" fa fa-quora " aria-hidden="true" style={{ color: "#d8a65d" }} />
									</span>
									quora.com/jacquiline.khalfan
								</p>
							</div>
						</div>
						<div className="row mb-2">
							<div className="col-12">
								<div
									className="title d-flex align-items-center mb-2"
									style={{
										color: "#479099",
									}}
								>
									<span
										className="title-icon"
										style={{
											width: "10px",
											height: "10px",
											border: "1px solid #479099",
											borderRadius: "3px",
											transform: "rotate(45deg)",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<span
											style={{
												height: "5px",
												width: "5px",
												display: "inline-block",
												background: "#479099",
												borderRadius: "2px",
											}}
										/>
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0  mr-3">skills</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span
											style={{
												display: "inline-block",
												background: "#81bbc2",
												height: "4px",
												width: "4px",
												borderRadius: "1px",
												transform: "rotate(45deg)",
											}}
										/>
										<span style={{ flexGrow: 1, height: "0.5px", backgroundColor: "#81bbc2" }} />
										<span
											style={{
												display: "inline-block",
												background: "#81bbc2",
												height: "4px",
												width: "4px",
												borderRadius: "1px",
												transform: "rotate(45deg)",
											}}
										/>
									</span>
								</div>

								<ul className="text-white mt-1 mb-0 pl-0">
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-2 skill-tag"
										style={{ backgroundColor: "#479099" }}
									>
										SEO
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-2 skill-tag"
										style={{ backgroundColor: "#479099" }}
									>
										Public Speaking
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-2 skill-tag"
										style={{ backgroundColor: "#479099" }}
									>
										Negotiation
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-2 skill-tag"
										style={{ backgroundColor: "#479099" }}
									>
										Teamwork
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-2 skill-tag"
										style={{ backgroundColor: "#479099" }}
									>
										Decission Making
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-2 skill-tag"
										style={{ backgroundColor: "#479099" }}
									>
										Research and Strategy
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-2 skill-tag"
										style={{ backgroundColor: "#479099" }}
									>
										Emotional Intelligence
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-2 skill-tag"
										style={{ backgroundColor: "#479099" }}
									>
										Outbound Marketingn
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-2 skill-tag"
										style={{ backgroundColor: "#479099" }}
									>
										Email Marketing
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-2 skill-tag"
										style={{ backgroundColor: "#479099" }}
									>
										Google Analytics
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-2 skill-tag"
										style={{ backgroundColor: "#479099" }}
									>
										Sales &amp; Marketing
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-2 skill-tag"
										style={{ backgroundColor: "#479099" }}
									>
										Social Media Advertising
									</li>
								</ul>
							</div>
						</div>

						<div className="row mb-2">
							<div className="col-12">
								<div
									className="title d-flex align-items-center mb-2"
									style={{
										color: "#479099",
									}}
								>
									<span
										className="title-icon"
										style={{
											width: "10px",
											height: "10px",
											border: "1px solid #479099",
											borderRadius: "3px",
											transform: "rotate(45deg)",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<span
											style={{
												height: "5px",
												width: "5px",
												display: "inline-block",
												background: "#479099",
												borderRadius: "2px",
											}}
										/>
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0  mr-3">Work Experience</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span
											style={{
												display: "inline-block",
												background: "#81bbc2",
												height: "4px",
												width: "4px",
												borderRadius: "1px",
												transform: "rotate(45deg)",
											}}
										/>
										<span style={{ flexGrow: 1, height: "0.5px", backgroundColor: "#81bbc2" }} />
										<span
											style={{
												display: "inline-block",
												background: "#81bbc2",
												height: "4px",
												width: "4px",
												borderRadius: "1px",
												transform: "rotate(45deg)",
											}}
										/>
									</span>
								</div>

								<div className="mb-2">
									<h4 className="font-weight-bold mb-1">Business Analyst</h4>
									<h5 className="mb-1">
										Proxi Solutions
										<i className="fa fa-share-square-o ml-2" aria-hidden="true" style={{ color: "#479099" }} />
									</h5>
									<div
										className="d-flex align-items-center justify-content-between font-italic"
										style={{ color: "#d8a65d" }}
									>
										<p className="mb-1">09/2017 - Present</p>
										<p className="mb-1">New York, USA</p>
									</div>
									<div
										className="d-flex align-items-center justify-content-between font-italic"
										style={{ color: "#d8a65d" }}
									>
										<p className="mb-2">Achievements</p>
									</div>
									<ul className="p-0 mb-0" style={{ listStyle: "none" }}>
										<li className="d-flex">
											<span style={{ fontWeight: 500, width: "20px" }}>-</span> Successfully managed $2 - 3 million
											budget projects and successfully achieved the project scheduled goals.
										</li>
										<li className="d-flex">
											<span style={{ fontWeight: 500, width: "20px" }}>-</span> Developed and implemented new marketing
											and sales plans and defined the strategy for the next 5 years.
										</li>
										<li className="d-flex">
											<span className="mr-1" style={{ fontWeight: 500, width: "20px" }}>
												-
											</span>
											Reviewed constantly the customer feedback and then suggested ways to improve the processes and
											customer service levels which increased the satisfaction rate from 81% to 95%.
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="row mb-2">
							<div className="col-12">
								<div
									className="title d-flex align-items-center mb-2"
									style={{
										color: "#479099",
									}}
								>
									<span
										className="title-icon"
										style={{
											width: "10px",
											height: "10px",
											border: "1px solid #479099",
											borderRadius: "3px",
											transform: "rotate(45deg)",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<span
											style={{
												height: "5px",
												width: "5px",
												display: "inline-block",
												background: "#479099",
												borderRadius: "2px",
											}}
										/>
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0  mr-3">Education</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span
											style={{
												display: "inline-block",
												background: "#81bbc2",
												height: "4px",
												width: "4px",
												borderRadius: "1px",
												transform: "rotate(45deg)",
											}}
										/>
										<span style={{ flexGrow: 1, height: "0.5px", backgroundColor: "#81bbc2" }} />
										<span
											style={{
												display: "inline-block",
												background: "#81bbc2",
												height: "4px",
												width: "4px",
												borderRadius: "1px",
												transform: "rotate(45deg)",
											}}
										/>
									</span>
								</div>

								<div className="row">
									<div className="col-6">
										<h4>MSc in Business Administration</h4>
										<h5>The University of Chicago</h5>
										<p style={{ color: "#d8a65d" }}>
											<em>09/2008 - 06/2010</em>
										</p>
									</div>
									<div className="col-6">
										<h4>Bsc in Business Administration</h4>
										<h5>The University of Chicago</h5>
										<p style={{ color: "#d8a65d" }}>
											<em>09/2006 - 06/2008</em>
										</p>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-2">
							<div className="col-12">
								<div
									className="title d-flex align-items-center mb-2"
									style={{
										color: "#479099",
									}}
								>
									<span
										className="title-icon"
										style={{
											width: "10px",
											height: "10px",
											border: "1px solid #479099",
											borderRadius: "3px",
											transform: "rotate(45deg)",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<span
											style={{
												height: "5px",
												width: "5px",
												display: "inline-block",
												background: "#479099",
												borderRadius: "2px",
											}}
										/>
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0  mr-3">certificates &amp; training</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span
											style={{
												display: "inline-block",
												background: "#81bbc2",
												height: "4px",
												width: "4px",
												borderRadius: "1px",
												transform: "rotate(45deg)",
											}}
										/>
										<span style={{ flexGrow: 1, height: "0.5px", backgroundColor: "#81bbc2" }} />
										<span
											style={{
												display: "inline-block",
												background: "#81bbc2",
												height: "4px",
												width: "4px",
												borderRadius: "1px",
												transform: "rotate(45deg)",
											}}
										/>
									</span>
								</div>

								<div className="row">
									<div className="col-6">
										<div className="mb-2">
											<h6>
												{" "}
												Microsoft Certified Solutions Developer
												<a
													className="fa fa-share-square-o ml-2"
													aria-hidden="true"
													target="_blank"
													style={{ color: "#d8a65d" }}
												/>
											</h6>
											<p style={{ color: "#cccccc" }}>
												<em>ABCD International Academy</em>
											</p>
										</div>
									</div>
									<div className="col-6">
										<div className="mb-2">
											<h6>
												{" "}
												AWS Certified SysOps Admin - Associate
												<a
													className="fa fa-share-square-o ml-2"
													aria-hidden="true"
													target="_blank"
													style={{ color: "#d8a65d" }}
												/>
											</h6>
											<p style={{ color: "#cccccc" }}>
												<em>ABCD International Academy</em>
											</p>
										</div>
									</div>
									<div className="col-6">
										<div className="mb-2">
											<h6>
												{" "}
												Course in Advanced Operations on AWS
												<a
													className="fa fa-share-square-o ml-2"
													aria-hidden="true"
													target="_blank"
													style={{ color: "#d8a65d" }}
												/>
											</h6>
											<p style={{ color: "#cccccc" }}>
												<em>ABCD International Academy</em>
											</p>
										</div>
									</div>
									<div className="col-6">
										<div className="mb-2">
											<h6>
												Course in MySQL for Database Administrators
												<a
													className="fa fa-share-square-o ml-2"
													aria-hidden="true"
													target="_blank"
													style={{ color: "#d8a65d" }}
												/>
											</h6>
											<p style={{ color: "#cccccc" }}>
												<em>ABCD International Academy</em>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-2">
							<div className="col-12">
								<div
									className="title d-flex align-items-center mb-2"
									style={{
										color: "#479099",
									}}
								>
									<span
										className="title-icon"
										style={{
											width: "10px",
											height: "10px",
											border: "1px solid #479099",
											borderRadius: "3px",
											transform: "rotate(45deg)",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<span
											style={{
												height: "5px",
												width: "5px",
												display: "inline-block",
												background: "#479099",
												borderRadius: "2px",
											}}
										/>
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0  mr-3">technical skills</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span
											style={{
												display: "inline-block",
												background: "#81bbc2",
												height: "4px",
												width: "4px",
												borderRadius: "1px",
												transform: "rotate(45deg)",
											}}
										/>
										<span style={{ flexGrow: 1, height: "0.5px", backgroundColor: "#81bbc2" }} />
										<span
											style={{
												display: "inline-block",
												background: "#81bbc2",
												height: "4px",
												width: "4px",
												borderRadius: "1px",
												transform: "rotate(45deg)",
											}}
										/>
									</span>
								</div>

								<ul className="text-white mt-1 mb-0 pl-0">
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-2 skill-tag"
										style={{ backgroundColor: "#479099" }}
									>
										Decission Making
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-2 skill-tag"
										style={{ backgroundColor: "#479099" }}
									>
										Research and Strategy
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-2 skill-tag"
										style={{ backgroundColor: "#479099" }}
									>
										Emotional Intelligence
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-2 skill-tag"
										style={{ backgroundColor: "#479099" }}
									>
										Outbound Marketingn
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-2 skill-tag"
										style={{ backgroundColor: "#479099" }}
									>
										Email Marketing
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-2 skill-tag"
										style={{ backgroundColor: "#479099" }}
									>
										Google Analytics
									</li>
								</ul>
							</div>
						</div>

						<div className="row mb-2">
							<div className="col-12">
								<div
									className="title d-flex align-items-center mb-2"
									style={{
										color: "#479099",
									}}
								>
									<span
										className="title-icon"
										style={{
											width: "10px",
											height: "10px",
											border: "1px solid #479099",
											borderRadius: "3px",
											transform: "rotate(45deg)",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<span
											style={{
												height: "5px",
												width: "5px",
												display: "inline-block",
												background: "#479099",
												borderRadius: "2px",
											}}
										/>
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0  mr-3">honor awards</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span
											style={{
												display: "inline-block",
												background: "#81bbc2",
												height: "4px",
												width: "4px",
												borderRadius: "1px",
												transform: "rotate(45deg)",
											}}
										/>
										<span style={{ flexGrow: 1, height: "0.5px", backgroundColor: "#81bbc2" }} />
										<span
											style={{
												display: "inline-block",
												background: "#81bbc2",
												height: "4px",
												width: "4px",
												borderRadius: "1px",
												transform: "rotate(45deg)",
											}}
										/>
									</span>
								</div>

								<div className="row">
									<div className="col-6">
										<div className="mb-2">
											<h6> Jury Member, Venture Cup Entrepreneurship Competition (2016) </h6>
											<p style={{ color: "#cccccc" }}>
												<em>Venture Cup USA</em>
											</p>
										</div>
									</div>
									<div className="col-6">
										<div className="mb-2">
											<h6> Sales Individual &amp; Business Development Award (2015)</h6>
											<p style={{ color: "#cccccc" }}>
												<em>AirState Business Awards</em>
											</p>
										</div>
									</div>
									<div className="col-6">
										<div className="mb-2">
											<h6> Excellence in Customer Partnering Award</h6>
											<p style={{ color: "#cccccc" }}>
												<em>IES - Institute of Excellence in Sales</em>
											</p>
										</div>
									</div>
									<div className="col-6">
										<div className="mb-2">
											<h6> Sales Individual &amp; Business Development Award (2015) </h6>
											<p style={{ color: "#cccccc" }}>
												<em>AirState Business Awards</em>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-2">
							<div className="col-12">
								<div
									className="title d-flex align-items-center mb-2"
									style={{
										color: "#479099",
									}}
								>
									<span
										className="title-icon"
										style={{
											width: "10px",
											height: "10px",
											border: "1px solid #479099",
											borderRadius: "3px",
											transform: "rotate(45deg)",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<span
											style={{
												height: "5px",
												width: "5px",
												display: "inline-block",
												background: "#479099",
												borderRadius: "2px",
											}}
										/>
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0  mr-3">
										Professional memberships &amp; affliations
									</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span
											style={{
												display: "inline-block",
												background: "#81bbc2",
												height: "4px",
												width: "4px",
												borderRadius: "1px",
												transform: "rotate(45deg)",
											}}
										/>
										<span style={{ flexGrow: 1, height: "0.5px", backgroundColor: "#81bbc2" }} />
										<span
											style={{
												display: "inline-block",
												background: "#81bbc2",
												height: "4px",
												width: "4px",
												borderRadius: "1px",
												transform: "rotate(45deg)",
											}}
										/>
									</span>
								</div>

								<div className="row">
									<div className="col-6">
										<div className="mb-2">
											<h6> The Business Analyst Club of New York (2018 - Present) </h6>
											<p style={{ color: "#cccccc" }}>
												<em>Member</em>
											</p>
										</div>
									</div>
									<div className="col-6">
										<div className="mb-2">
											<h6> The Association of Work Process Improvement (2016- Present)</h6>
											<p style={{ color: "#cccccc" }}>
												<em>Member</em>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-2">
							<div className="col-12">
								<div
									className="title d-flex align-items-center mb-2"
									style={{
										color: "#479099",
									}}
								>
									<span
										className="title-icon"
										style={{
											width: "10px",
											height: "10px",
											border: "1px solid #479099",
											borderRadius: "3px",
											transform: "rotate(45deg)",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<span
											style={{
												height: "5px",
												width: "5px",
												display: "inline-block",
												background: "#479099",
												borderRadius: "2px",
											}}
										/>
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0  mr-3">
										Volunteer &amp; Personal Projects
									</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span
											style={{
												display: "inline-block",
												background: "#81bbc2",
												height: "4px",
												width: "4px",
												borderRadius: "1px",
												transform: "rotate(45deg)",
											}}
										/>
										<span style={{ flexGrow: 1, height: "0.5px", backgroundColor: "#81bbc2" }} />
										<span
											style={{
												display: "inline-block",
												background: "#81bbc2",
												height: "4px",
												width: "4px",
												borderRadius: "1px",
												transform: "rotate(45deg)",
											}}
										/>
									</span>
								</div>

								<div className="row">
									<div className="col-6">
										<div className="mb-2">
											<h6> Voluteer @Habitat for Humanity (2018 - Present) </h6>
										</div>
									</div>
									<div className="col-6">
										<div className="mb-2">
											<h6> Voluteer @YMCA (2012 - Present) </h6>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-2">
							<div className="col-12">
								<div
									className="title d-flex align-items-center mb-2"
									style={{
										color: "#479099",
									}}
								>
									<span
										className="title-icon"
										style={{
											width: "10px",
											height: "10px",
											border: "1px solid #479099",
											borderRadius: "3px",
											transform: "rotate(45deg)",
											marginRight: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<span
											style={{
												height: "5px",
												width: "5px",
												display: "inline-block",
												background: "#479099",
												borderRadius: "2px",
											}}
										/>
									</span>
									<h3 className="text-uppercase center font-weight-bold mb-0  mr-3">Languages</h3>
									<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
										<span
											style={{
												display: "inline-block",
												background: "#81bbc2",
												height: "4px",
												width: "4px",
												borderRadius: "1px",
												transform: "rotate(45deg)",
											}}
										/>
										<span style={{ flexGrow: 1, height: "0.5px", backgroundColor: "#81bbc2" }} />
										<span
											style={{
												display: "inline-block",
												background: "#81bbc2",
												height: "4px",
												width: "4px",
												borderRadius: "1px",
												transform: "rotate(45deg)",
											}}
										/>
									</span>
								</div>

								<div className="row no-gutters mx-n1">
									<div className="col px-1">
										<div className="mb-2">
											<h6> English</h6>
											<p className="mb-0" style={{ color: "#d8a65d" }}>
												<em>Native or Bilingual Proficiency</em>
											</p>
										</div>
									</div>
									<div className="col px-1">
										<div className="mb-2">
											<h6> Spanish</h6>
											<p className="mb-0" style={{ color: "#d8a65d" }}>
												<em>Native or Bilingual Proficiency</em>
											</p>
										</div>
									</div>
									<div className="col px-1">
										<div className="mb-2">
											<h6> Portuguese</h6>
											<p className="mb-0" style={{ color: "#d8a65d" }}>
												<em>Native or Bilingual Proficiency</em>
											</p>
										</div>
									</div>
									<div className="col px-1">
										<div className="mb-2">
											<h6> French</h6>
											<p className="mb-0" style={{ color: "#d8a65d" }}>
												<em>Native or Bilingual Proficiency</em>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="preview text-center mt-4 mb-4">
				<button
					className="btn text-primary btn-sm btn-outline-primary"
					data-toggle="modal"
					data-target="#ResumePreview"
				>
					<span className="fa fa-eye" /> Preview
				</button>
			</div>
		</div>
	);
}

export default TemplatePremiumTen;
