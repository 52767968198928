import React, { useEffect } from "react";

const TemplateTwo = ({ resumeData }) => {
	useEffect(() => {}, [resumeData]);
	return (
		<div style={{ scale: "1.4", transformOrigin: "center" }}>
			<div className="small-resume-preview">
				<div className="final-resume-preview bg-white resume-type-1 text-dark">
					<div className="resume-content ">
						<div className="top py-2 py-md-4">
							<div className="resume-user-name text-center">
								<h1
									className="text-uppercase"
									style={{
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
										margin: "0",
									}}
								>
									<span>
										{resumeData.contactInfo.firstName} {resumeData.contactInfo.middleName}{" "}
										{resumeData.contactInfo.lastName}
									</span>
								</h1>

								<address className="mb-0">
									Address: {resumeData.contactInfo.city}, {resumeData.contactInfo.country}
								</address>
							</div>
							<ul
								style={{ lineHeight: 1 }}
								className="d-flex justify-content-center list-unstyled d-flex align-items-center "
							>
								<li className="px-2 border-right text-nowrap">Phone: {resumeData.contactInfo.phoneNo}</li>
								<li className="px-2 mr-md-3 text-nowrap">Email: {resumeData.contactInfo.email}</li>
							</ul>

							<div className="bottom px-4">
								<div className="professional-summary-info">
									<h3 className="text-uppercase">
										<span className="border-bottom">Professional Summary</span>
									</h3>
									<p>
										Tech-savvy Web Developer contributes specialty in search engine optimization, marketing, social
										media management and audio/video integration to launch custom-tailored code for website presence.
									</p>
								</div>
								<div className="professional-summary-info">
									<h3 className="text-uppercase">
										<span className="border-bottom">Education</span>
									</h3>
									<ul className="pl-3 mb-0">
										<li>Post Graduation in Computer Sciences from ABC University, Dar es Salaam, Tanzania in 2021</li>
									</ul>
								</div>
								<div className="skills-info">
									<h3 className="text-uppercase">
										<span className="border-bottom">Skills</span>
									</h3>
									<ul className="pl-3 mb-0">
										<li>Version Control Systems: Git, Github</li>
										<li>Complex Problem Solving</li>
									</ul>
								</div>
								<div className="work-history-info ">
									<h3 className="text-uppercase">
										<span className="border-bottom">Work History</span>
									</h3>
									<p className="font-weight-bold">Kellton Tech | Web Developer | August, 2019 - May, 2021</p>

									<ul className="pl-3 mb-0">
										<li>
											Planned website development, converting mockups into usable web presence with HTML, JavaScript,
											AJAX and JSON coding.
										</li>
									</ul>
								</div>
								<div className="languages-info">
									<h3 className="text-uppercase">
										<span className="border-bottom">Accomplishments</span>
									</h3>
									<ul className="pl-3 mb-0">
										<li>
											Spearheaded a large-scale client project as a result of skill set and education and completed the
											project two weeks ahead of schedule.
										</li>
									</ul>
								</div>
								<div className="affliations-info">
									<h3 className="text-uppercase">
										<span className="border-bottom">Affiliations</span>
									</h3>
									<ul className="pl-3 mb-0">
										<li> Association for Computing Machinery</li>
										<li>Freemason</li>
									</ul>
								</div>
								<div className="addtional-info">
									<h3 className="text-uppercase">
										<span className="border-bottom">Additonal Information</span>
									</h3>
									<p>
										Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
										industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of
										type and scrambled it to make a type specimen book. It has survived not only five centuries
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TemplateTwo;
