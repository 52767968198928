import React from "react";

import { cn } from "../../../../handlers/cn.utils";

function LabeledList({ content, type, variant = "icon" }) {
	return (
		<div
			className={cn("rounded-xl p-3 !mb-5", {
				"bg-success-light": type === "do",
				"bg-error-light": type === "dont",
			})}
		>
			<div
				className={cn("rounded-sm w-full flex items-center justify-center text-white font-medium py-1", {
					"bg-error-default": type === "dont",
					"bg-success-default": type === "do",
				})}
			>
				{type === "do" ? "Do" : "Don't"}
			</div>
			<ul className="mt-2">
				{content.map((item, index) => (
					<li
						key={index}
						className={cn("text-gray-700 flex items-center text-md py-[10px]", {
							"border-b border-[#B5BAC44D]": index !== content.length - 1,
						})}
					>
						{variant === "icon" ? (
							<>
								{type === "dont" ? (
									<div className="fa fa-times text-base text-error-default mr-2" />
								) : (
									<div className="fa text-base fa-check text-success-default mr-2" />
								)}
							</>
						) : (
							<p
								className={cn("font-medium text-sm mr-2", {
									"text-error-default": type === "dont",
									"text-success-default": type === "do",
								})}
							>
								{"0" + (index + 1)}
							</p>
						)}
						<p>{item}</p>
					</li>
				))}
			</ul>
		</div>
	);
}

export default LabeledList;
