import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import tips from "../../../assets/copy/tips";
import ErrorToast from "../../../components/ErrorToast";
import Modal from "../../../components/Modal/Modal";
import SkillsEditorListItems from "../../../components/ResumeBuilder/SkillsEditorListItems";
import Spinner from "../../../components/Spinner";
import TextEditor from "../../../components/TextEditor";
import Tips from "../../../components/Tips";
import ResumeContext from "../../../context/ResumeContext";
import { FormValidation } from "../../../handlers/formValidation";
import { NavigationHandler } from "../../../handlers/navigationHandler";
import { NextButton, PreviousButton } from "../../../handlers/valueTransformation";
import useHasChange from "../../../hooks/use-HasChange";
import { StaticValue } from "../../../seed/constants";
import { JOB_FUNCTION_MAPPING } from "../../../seed/skills/skillsMapping";
import {
	convertArrayToUl,
	convertSplittedSkillsToNormalSkillsArray,
	convertUlToArray,
	getFirstWordSeparedByFullColon,
} from "./../../../handlers/utilityFunctions";
import { ResumeDataService } from "./../../../services/create-resume/resume-data";
import GroupedSkillsItem from "./GroupedSkillsItem";

const ResumeSkills = () => {
	const [cookies] = useCookies(["resume"]);
	const navigate = useNavigate();
	const navigationHandler = new NavigationHandler();
	const link = useLocation().pathname.split("/")[2];
	const dispatch = useDispatch();
	const { hasChanges } = useHasChange();
	const formValidation = new FormValidation();

	const [loading, setLoading] = useState(false);
	const { resumeData, setResumeData } = useContext(ResumeContext);

	let { journal } = resumeData;
	let resumeCurrentStatus = journal ? journal.currentStatus : false;

	let isComplete = resumeCurrentStatus == "COMPLETED";

	const previousTxt = PreviousButton(isComplete, "Summary", navigationHandler, 0);
	const nextTxt = NextButton(isComplete, "Work History Summary", navigationHandler, 0);

	function previous() {
		navigationHandler.getPrevious(navigationHandler.template.navigationFlow, link, isComplete);

		navigate(-1);
	}

	const [skillList, setSkillList] = useState([]);
	const [userInput, setUserInput] = useState(resumeData.contactInfo.resumeTitle);

	useEffect(() => {
		setUserInput(resumeData.contactInfo.resumeTitle);
		addSuggestionsToList();
	}, [resumeData]);

	function addSuggestionsToList() {
		const jobFunction = resumeData.contactInfo.resumeFunction.toString().toLowerCase();

		const suggestions = JOB_FUNCTION_MAPPING[jobFunction.split(",")[0]] || [];
		setSkillList([]);

		if (suggestions && suggestions.length > 0) {
			let list = [...suggestions];

			list.map((item, index) => {
				if (item !== "") {
					const value = {
						id: index,
						title: item,
						added: false,
					};
					setSkillList((prev) => {
						return [...prev, value];
					});
				}
				return setSkillList((prev) => {
					//remove empty values
					return [...prev].filter((n) => n.title.length !== 0);
				});
			});
		}
	}

	useEffect(() => {
		addSuggestionsToList();
	}, [userInput]);

	//Declaring the skills state
	const [skills, setSkills] = useState(resumeData.skills);

	const [filteredSkillList, setFilteredSkillList] = useState([]);

	useEffect(() => {
		setFilteredSkillList(skillList);
	}, [skillList]);

	const [errors] = useState([]);
	const [isInputCompleted, setIsInputCompleted] = useState(false);

	const staticValue = new StaticValue();
	const staticValues = staticValue.getDeafaulValues();
	const [serverErrors, setServerErrors] = useState([]);

	const resumeDataService = new ResumeDataService();

	let resumeId = cookies[staticValues.localStorageResumeId];

	function onSuccess(data) {
		setLoading(false);
		const response = data.data;
		dispatch({ type: "RESUME_DATA", payload: response });

		navigationHandler.getNext(navigationHandler.template.navigationFlow, link, isComplete);
		navigate("/resume/brief-summary");
	}

	function onError(error) {
		setLoading(false);
		const serverResponse = error.response.data;
		Array.isArray(serverResponse.message)
			? setServerErrors((prev) => {
					return [...prev, ...serverResponse.message];
				})
			: setServerErrors((prev) => {
					return [...prev, serverResponse.message];
				});
	}

	//submission handler
	const addSkills = () => {
		const formattedSkillsData = convertSplittedSkillsToNormalSkillsArray(groupedSkills);
		const customSkillsData = convertUlToArray(customSkills);
		const skillsData = formattedSkillsData.concat(customSkillsData);
		const formattedUlSkillsData = convertArrayToUl(formattedSkillsData);
		const ulSkillsData = formattedUlSkillsData.concat(customSkills);

		if (isInputCompleted) {
			setResumeData((prevState) => {
				return {
					...prevState,
					skills: ulSkillsData,
				};
			});

			if (hasChanges("SKILLS", skillsData)) {
				setLoading(true);
				resumeDataService.patchResumeLedger(resumeId, { data: skillsData }, "SKILLS", onSuccess, onError);
			} else {
				skipSection();
			}
		}
	};

	useEffect(() => {
		const validation = formValidation.validateSkillsDetails(errors, skills);
		if (!validation.status) {
			setIsInputCompleted(false);
		} else {
			setIsInputCompleted(true);
		}
	}, [skills]);

	useEffect(() => {
		setSkills(resumeData.skills);
	}, [resumeData]);

	const skipSection = () => {
		navigationHandler.getNext(navigationHandler.template.navigationFlow, link, isComplete);
		navigate("/resume/brief-summary");
	};

	const [addCustomCategory, setAddCustomCategory] = useState(false);
	const [groupedSkills, setGroupedSkills] = useState([]);
	const [customSkills, setCustomSkills] = useState([]);

	function getGroupedAndCustomSkills(data) {
		let newCustomSkills = [];
		let skillsInGroup = convertUlToArray(data)
			.map((skillObject) => {
				const splitArray = skillObject.value.split(/:(.+)/);
				let wordBeforeColon = splitArray[0].trim();
				if (splitArray.length > 1) {
					let groupedSkills = [];
					groupedSkills = groupedSkills.concat(
						splitArray[1]
							.trim()
							.split(",")
							.map((skillItem) => {
								return { added: true, skill: skillItem };
							}),
					);
					return { title: wordBeforeColon, skills: groupedSkills, collapsed: true };
				}
				newCustomSkills.push({ value: wordBeforeColon });
			})
			.filter((item) => item !== undefined);
		setGroupedSkills(skillsInGroup);
		setCustomSkills(convertArrayToUl(newCustomSkills));
		newCustomSkills.length > 0 && setAddCustomCategory(true);
	}

	const collapseGroupedSkills = (groupedSkillIndex) => {
		let newGroupedSkills = [
			...groupedSkills.map((skillGroup) => ({
				...skillGroup,
				collapsed: skillGroup === groupedSkills[groupedSkillIndex] ? !skillGroup.collapsed : skillGroup.collapsed,
			})),
		];
		setGroupedSkills(newGroupedSkills);
	};

	const removeGroupedSkills = (skillTitle) => {
		let newGroupedSkills = groupedSkills;
		newGroupedSkills = newGroupedSkills.filter((skillItem) => skillItem.title !== skillTitle);
		setGroupedSkills(newGroupedSkills);

		let newFilteredSkillList = filteredSkillList;
		const indexChanged = newFilteredSkillList.findIndex(
			(changed) => getFirstWordSeparedByFullColon(changed.title) === skillTitle,
		);
		if (indexChanged !== -1) {
			newFilteredSkillList[indexChanged].added = false;
			setFilteredSkillList(newFilteredSkillList);
		}
	};

	const onSelectSingleSkill = (groupedSkillIndex, singleSkillIndex) => {
		let newGroupedSkills = groupedSkills.map((group) => ({
			...group,
			skills: group.skills.map((skill) => ({ ...skill })),
		}));

		newGroupedSkills[groupedSkillIndex].skills[singleSkillIndex].added =
			!newGroupedSkills[groupedSkillIndex].skills[singleSkillIndex].added;

		setGroupedSkills(newGroupedSkills);
	};

	const onAddCustomSkill = (groupedSkillIndex) => {
		let newGroupedSkills = groupedSkills.map((group) => ({
			...group,
			skills: group.skills.map((skill) => ({ ...skill })),
		}));

		newGroupedSkills[groupedSkillIndex].skills = [
			...newGroupedSkills[groupedSkillIndex].skills,
			{ added: true, skill: "" },
		];

		setGroupedSkills(newGroupedSkills);
	};

	const onSaveCustomSkillAdded = (groupedSkillIndex, singleSkillIndex, skillAdded) => {
		let newGroupedSkills = groupedSkills.map((group) => ({
			...group,
			skills: group.skills.map((skill) => ({ ...skill })),
		}));

		newGroupedSkills[groupedSkillIndex].skills[singleSkillIndex].skill = skillAdded;

		setGroupedSkills(newGroupedSkills);
	};

	const toggleEditorVisibility = () => {
		let newGroupedSkills = groupedSkills.map((group) => ({
			...group,
			skills: group.skills.map((skill) => ({ ...skill })),
			collapsed: !addCustomCategory,
		}));
		setGroupedSkills(newGroupedSkills);
		setAddCustomCategory(!addCustomCategory);
	};

	useEffect(() => {
		getGroupedAndCustomSkills(resumeData.skills);
	}, [resumeData]);

	return (
		<>
			<div
				className="modal fade"
				id="skipSectionModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="skipSectionModalTitle"
				aria-hidden="false"
			>
				<Modal skip={skipSection} />
			</div>

			<div className="container min-vh-100">
				<div className="section">
					{serverErrors.map((item, index) => {
						return <ErrorToast error={item} key={index} />;
					})}
					<div className="common-recommendations-section skills-recommendations-section">
						<div className="row mt-3">
							<div className="col-md-6 search mb-3">
								<div className="d-flex justify-content-end mb-2">
									<Tips content={tips.skills} />
								</div>
								<div className="form-group">
									<div className="top-text-on-skill-section mb-3">
										Highlight your key competencies and technical proficiencies.
									</div>
									<div className="input-group">
										<input
											type="text"
											placeholder={userInput}
											onChange={(e) => {
												const input = e.target.value;
												if (input.trim() !== "") {
													setFilteredSkillList((prev) =>
														prev.filter((skill) => skill.title.toLowerCase().includes(input.toLowerCase())),
													);
												} else {
													setFilteredSkillList(skillList);
												}
											}}
											className="form-control"
										/>
										<div className="input-group-append">
											<button className="btn btn-primary px-md-5" type="button">
												<span className="fa fa-search" />
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6 mb-3">
								<div className="left">
									<div className="row">
										<div className="col-md-12">
											{/* recommendations topics */}
											<div className="recommendation-topics skills-recommendation-container">
												<SkillsEditorListItems
													editorListItems={filteredSkillList}
													editorContent={skills}
													setEditorContent={setSkills}
													setEditorListItems={setFilteredSkillList}
													groupedSkills={groupedSkills}
													setGroupedSkills={setGroupedSkills}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								{groupedSkills.map((item, groupedSkillIndex) => {
									if (item.title === "") {
										return null;
									}

									return (
										<GroupedSkillsItem
											key={groupedSkillIndex}
											item={item}
											collapseGroupedSkills={collapseGroupedSkills}
											groupedSkillIndex={groupedSkillIndex}
											removeGroupedSkills={removeGroupedSkills}
											onSaveCustomSkillAdded={onSaveCustomSkillAdded}
											onSelectSingleSkill={onSelectSingleSkill}
											onAddCustomSkill={onAddCustomSkill}
										/>
									);
								})}

								{addCustomCategory && (
									<div className="editor-wrapper" style={{ maxWidth: "508px" }}>
										<TextEditor
											value={customSkills}
											setValue={setCustomSkills}
											placeholder="Add custom skills..."
											toggleEditorVisibility={toggleEditorVisibility}
										/>
									</div>
								)}
								{!addCustomCategory && (
									<div className="cursor-pointer" onClick={toggleEditorVisibility}>
										<span className="fa fa-plus mr-2" />
										<span className="add-custom-skills-category-button-text">Add custom category</span>
									</div>
								)}
								<div className="d-flex justify-content-end mt-2" style={{ maxWidth: "508px" }}>
									<div className="action d-flex align-items-center mt-4 justify-content-between">
										<button onClick={() => previous()} className="btn btn-dark rounded-pill btn-lg mr-2">
											{previousTxt}
										</button>
									</div>
									<div
										style={{ float: "right" }}
										className="action mt-4 d-flex align-items-center justify-content-between"
									>
										{/* <button
											onClick={() => previous()}
											className="btn btn-dark rounded-pill btn-lg mr-2"
										>
											{previousTxt}
										</button> */}
										<button
											data-toggle="modal"
											data-target={!isInputCompleted ? "#skipSectionModal" : ""}
											onClick={addSkills}
											className="btn btn-primary mr-2"
										>
											{loading ? (
												<>
													Loading <Spinner />
												</>
											) : (
												nextTxt
											)}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ResumeSkills;
