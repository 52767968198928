import React from "react";

function Modal(props) {
	const skipHandler = () => {
		props.skip();
	};

	return (
		<div className="modal-dialog modal-dialog-centered" role="document">
			<div className="modal-content">
				<div className="modal-header">
					<h5 className="modal-title" id="exampleModalLongTitle">
						Section Not Completed
					</h5>
					<button type="button" className="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="modal-body">You have not completed this section, Do you want to skip or stay to complete?</div>
				<div className="modal-footer">
					<button type="button" className="btn btn-secondary" data-dismiss="modal">
						Stay
					</button>
					<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={skipHandler}>
						Skip
					</button>
				</div>
			</div>
		</div>
	);
}

export default Modal;
