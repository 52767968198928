import React, { useEffect } from "react";

import AutoSuggestion from "../../../../../components/AutoSuggestion";
import ProficiencyRange from "./ProficiencyRange";

function LanguageItem({ languages, index, updateLanguagesData, item, deleteLanguage }) {
	const [language, setLanguage] = React.useState("");
	const [proficiency, setProficiency] = React.useState("Elementary Proficiency");

	const onSetProficiency = (level) => {
		setProficiency(level);
	};

	useEffect(() => {
		setLanguage(item.language);
		setProficiency(item.proficiency);
	}, [item]);

	useEffect(() => {
		if (language === "") {
			return;
		}
		if (proficiency === "") {
			return;
		}
		updateLanguagesData(index, { language, proficiency });
	}, [language, proficiency]);

	return (
		<div className="mb-3 language-item">
			<div className="row">
				<div className="col-12">
					<div className="row m-0">
						<div className={`${languages.length > 0 ? "col-11" : "col-12"} p-0 pr-2`}>
							<AutoSuggestion
								suggestions={languages}
								label="Type language"
								type="text"
								name="newSelectedLanguage"
								userInput={language}
								setUserInput={setLanguage}
								isForm={true}
							/>
						</div>
						{languages.length > 0 && (
							<div className="col p-0 align-self-center mt-2">
								<button onClick={() => deleteLanguage(index)} className="button-secondary text-danger">
									<i className="bi bi-trash3 fs-16 lh-1" />
								</button>
							</div>
						)}
					</div>
				</div>
				<div className="col-12 mb-3">
					<ProficiencyRange setLevel={onSetProficiency} value={proficiency} />
				</div>
			</div>
		</div>
	);
}

export default LanguageItem;
