const educationHistory = {
	content: [
		{
			bp: false,
			value:
				"List your degree, college/university, location, distinguishing academic honors, and other distinguishing information along with relevant training, certification and other professional credentials.",
		},
		{
			bp: false,
			value:
				"Your education should only appear at the bottom of your resume(one page) because that's where recruiters will be looking for it. One exception is if you're applying for your first job out of college, then it's acceptable to include education information at the top of your resume.",
		},
		{
			bp: true,
			value:
				"Include every degree you've obtained in order of most advanced to least advanced.<ul><li>School name</li><li>Degree (B.A., B.S., etc)</li><li>Major</li><li>Minor</li><li>Graduation year</li><li>Honors or distinctions (Less than 5 years since graduation)</li><li>GPA (Less than 5 years since graduation)</li><li>Course taken ( Applying for first job after graduation)</li><li>Scholarships (less than 5 years since graduation)</li></ul>",
		},
	],
};

export default educationHistory;
