import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { read, utils } from "xlsx";

import Loader from "../../../components/Loader";
import EllipsisPagination from "../../../components/UIComponents/EllipsisPagination";
import { getColumnName, getSanitizedHtml } from "../../../handlers/utilityFunctions";
import { isValidPhoneNumber, isValidURL } from "../../../handlers/validationFunctions";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import JobNavLayout from "../JobMarketLayout/JobMarketNavLayout/Layout";
import CreateModal from "./CreateModal";
import DeleteModal from "./DeleteModal";
import MappingColumnModal from "./MappingColumnModal";
import UpdateModal from "./UpdateModal";

function Companies() {
	const location = useLocation();
	const jobService = new ResumeDataService();
	const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [selectedRows, setSelectedRows] = React.useState([]);
	const [selectedCompany, setSelectedCompany] = React.useState({
		id: "",
		companyName: "",
		companyIndustry: "",
		companySize: "",
		whyWorkAtCompany: "",
		companyDescription: "",
		phoneNumber: "",
		companyWebsite: "",
		companyLocation: {
			city: "",
			region: "",
			country: "",
		},
	});
	const [, setEditedIndex] = React.useState(0);
	const [searchTerm] = React.useState("");
	const [showColumnMappingDialog, setShowColumnMappingDialog] = React.useState(false);
	const [showEditDialog, setShowEditDialog] = React.useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
	const [showCreateCompanyDialog, setShowCreateCompanyDialog] = React.useState(false);
	const [submitPhase, setSubmitPhase] = useState(false);
	const [loading, setLoading] = useState(false);
	const [hadFetch, setHadFetch] = useState(false);
	const [companies, setCompanies] = useState([]);
	const [errors, setErrors] = useState([]);
	let totalPages = Math.ceil(companies?.length / rowsPerPage);
	const { id } = useParams();
	const [companyName, setCompanyName] = useState(location?.state?.companyName || "");
	const [companyDescription, setCompanyContactInfo] = useState("");
	const [whyWorkHere, setWhyWorkHere] = useState("");
	const [companyDetails, setCompanyDetails] = useState({
		companyName: "",
		companyIndustry: "",
		companySize: "",
		whyWorkAtCompany: "",
		companyDescription: "",
		phoneNumber: "",
		companyWebsite: "",
		companyLocation: {
			city: "",
			region: "",
			country: "",
		},
	});
	const country = "Tanzania";
	const [address, setAddress] = useState("");
	const [companiesData, setCompaniesData] = React.useState({
		rows: [],
		originalColumns: [],
		mappedColumns: [],
		file: null,
	});

	const createMapping = (columns) => {
		const mapping = [
			{
				supportedColumn: "companyName",
				originalColumn: getColumnName(columns, "COMPANY NAME"),
			},
			{
				supportedColumn: "companyIndustry",
				originalColumn: getColumnName(columns, "COMPANY INDUSTRY"),
			},
			{
				supportedColumn: "location",
				originalColumn: getColumnName(columns, "CONPANY LOCATION"),
			},
			{
				supportedColumn: "companyWebsite",
				originalColumn: getColumnName(columns, "COMPANY WEBSITE"),
			},
			{
				supportedColumn: "companySize",
				originalColumn: getColumnName(columns, "COMPANY SIZE"),
			},
			{
				supportedColumn: "phoneNumber",
				originalColumn: getColumnName(columns, "PHONE NUMBER"),
			},
			{
				supportedColumn: "whyWorkAtCompany",
				originalColumn: getColumnName(columns, "WHY WORK AT OUR COMPANY"),
			},
			{
				supportedColumn: "companyDescription",
				originalColumn: getColumnName(columns, "COMPANY DESCRIPTION"),
			},
		];

		return mapping;
	};

	const processFile = (file) => {
		const reader = new FileReader();
		reader.onload = (e) => {
			const wb = read(e.target.result);
			const sheets = wb.SheetNames;

			if (sheets.length) {
				const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
				if (rows.length === 0) {
					return;
				}
				const columns = Object.keys(rows[0]);
				const filterEmptyRows = rows.filter((row) => {
					const rowKeys = Object.keys(row);
					return columns.every((column) => rowKeys.includes(column) && row[column] !== undefined && row[column] !== "");
				});
				const newRowsWithId = filterEmptyRows.map((row, index) => ({ ...row, id: index + 1 }));
				const mappedColumns = createMapping(columns);
				const newOrdersData = {
					...companiesData,
					rows: newRowsWithId,
					originalColumns: columns,
					mappedColumns: mappedColumns,
					file: file,
				};
				setCompaniesData(newOrdersData);
			}
		};
		reader.readAsArrayBuffer(file);
	};

	const handleCloseColumnMappingDialog = () => {
		setShowColumnMappingDialog(false);
	};

	const handleCloseEditDialog = () => {
		setShowEditDialog(false);
	};

	const handleCloseDeleteDialog = () => {
		setShowDeleteDialog(false);
	};

	const handleCloseCreateCompanyDialog = () => {
		setShowCreateCompanyDialog(false);
	};

	function getCompanies(companyName) {
		setLoading(true);
		jobService.getCompanies(
			companyName,
			"",
			(response) => {
				setCompanies(response.data);
				setLoading(false);
				setHadFetch(true);
			},
			(error) => {
				setLoading(false);
				setErrors((prev) => {
					return [...prev, ...error.response.data.message];
				});
			},
		);
	}

	useEffect(() => {
		if (showEditDialog) {
			if (selectedCompany && selectedCompany.location) {
				setCompanyDetails({
					companyName: selectedCompany.companyName,
					companyDescription: selectedCompany.companyDescription,
					whyWorkAtCompany: selectedCompany.whyWorkAtCompany,
					companyIndustry: selectedCompany.companyIndustry,
					companySize: selectedCompany.companySize,
					phoneNumber: selectedCompany.phoneNumber,
					companyWebsite: selectedCompany.companyWebsite,
					companyLocation: {
						city: selectedCompany.location.city,
						region: selectedCompany.location.region,
						country: selectedCompany.location.country,
					},
				});
			}
		}
		if (showCreateCompanyDialog) {
			setCompanyDetails({
				companyName: location?.state?.companyName || "",
				companyDescription: "",
				whyWorkAtCompany: "",
				companySize: "",
				companyIndustry: "",
				phoneNumber: "",
				companyWebsite: "",
				companyLocation: {
					city: "",
					region: "",
					country: "",
				},
			});
		}
	}, [showEditDialog, showCreateCompanyDialog]);

	function handleDeleteSelectedCompany() {
		setLoading(true);

		if (id && showEditDialog) {
			setCompanyDetails({
				...companyDetails,
				companyName: "",
				companyDescription: "",
				whyWorkAtCompany: "",
				companySize: "",
				phoneNumber: "",
				companyWebsite: "",
				companyLocation: {
					...companyDetails.companyLocation,
					city: "",
					region: "",
					country: "",
				},
			});
		}
		setLoading(false);
		handleCloseDeleteDialog();
	}

	function onSubmit(e) {
		e.preventDefault();
		setSubmitPhase(true);

		const [city, region] = address.split(",").map((str) => str.trim());

		const updatedCompanyDetails = {
			...companyDetails,
			whyWorkAtCompany: whyWorkHere,
			companyDescription: companyDescription,
			companyLocation: {
				...companyDetails.companyLocation,
				country,
				city,
				region,
			},
		};

		setCompanyDetails(updatedCompanyDetails);

		const newCompanyDetails = {
			companyName: updatedCompanyDetails.companyName,
			companyIndustry: updatedCompanyDetails.companyIndustry,
			// companySize: "",
			companyDescription: updatedCompanyDetails.companyDescription,
			whyWorkAtCompany: updatedCompanyDetails.whyWorkAtCompany,
			phoneNumber: updatedCompanyDetails.phoneNumber,
			companyWebsite: updatedCompanyDetails.companyWebsite,
			location: {
				city: updatedCompanyDetails.companyLocation.city,
				country: updatedCompanyDetails.companyLocation.country,
				region: updatedCompanyDetails.companyLocation.region,
			},
		};

		if (showEditDialog && !showCreateCompanyDialog) {
			setLoading(true);

			jobService.updateCompany(companyDetails.id, newCompanyDetails, onSuccess, onError);
		}
		if (showCreateCompanyDialog && !showEditDialog) {
			setLoading(true);

			jobService.createCompany(newCompanyDetails, onSuccess, onError);
		}
	}

	const onSuccess = () => {
		if (showEditDialog) {
			setCompanyDetails({
				...companyDetails,
				companyName: "",
				companyDescription: "",
				whyWorkAtCompany: "",
				companySize: "",
				phoneNumber: "",
				companyWebsite: "",
				companyLocation: {
					...companyDetails.companyLocation,
					city: "",
					region: "",
					country: "",
				},
			});
			setLoading(false);
			setSubmitPhase(false);
			handleCloseEditDialog();
		} else {
			setCompanyDetails({
				...companyDetails,
				companyName: "",
				companyDescription: "",
				whyWorkAtCompany: "",
				companySize: "",
				phoneNumber: "",
				companyWebsite: "",
				companyLocation: {
					...companyDetails.companyLocation,
					city: "",
					region: "",
					country: "",
				},
			});
			setLoading(false);
			setSubmitPhase(false);
			handleCloseCreateCompanyDialog();
		}
	};

	const onError = (error) => {
		setLoading(false);
		setSubmitPhase(true);
		setErrors(error.response.data.message);
	};

	const handlePageChange = (pageNumber) => {
		setPage(pageNumber);
	};

	const handleSelectedRow = (event, company) => {
		if (event.target.checked) {
			const formatedCompanyData = {
				id: company._id || "",
				companyName: company.companyName || "",
				companyIndustry: company.companyIndustry || "",
				companySize: company.companySize || "",
				whyWorkAtCompany: company.whyWorkAtCompany || "",
				companyDescription: company.companyDescription || "",
				phoneNumber: company.phoneNumber || "",
				companyWebsite: company.companyWebsite || "",
				companyLocation: {
					city: company.location.city,
					region: company.location.region,
					country: company.location.country,
				},
			};
			setAddress(`${company.location.city + " , " || ""} ${company.location.region || ""}`);
			setCompanyContactInfo(company.companyDescription || "");
			setWhyWorkHere(company.whyWorkAtCompany || "");
			setSelectedCompany(formatedCompanyData);
			setCompanyDetails(formatedCompanyData);

			setSelectedRows([...selectedRows, company._id]);
		} else {
			setSelectedRows(selectedRows.filter((rowId) => rowId !== company._id));
			setSelectedCompany(null);
		}
	};

	const handleSelectedAllRows = (event) => {
		if (event.target.checked) {
			const allSelectedRows = companies.map((item) => item._id);
			setSelectedRows(allSelectedRows);
		} else {
			setSelectedRows([]);
		}
	};

	useEffect(() => {
		if (companyName.length > 2) {
			getCompanies(companyName);
		}
	}, [companyName]);

	React.useEffect(() => {
		let validationErrors = {
			companyName: "",
			companyIndustry: "",
			companySize: "",
			companyDescription: "",
			whyWorkAtCompany: "",
			phoneNumber: "",
			companyWebsite: "",
			address: "",
			country: "",
			region: "",
		};

		if (companyDetails.companyName.length < 5) {
			validationErrors.companyName =
				companyDetails.companyName === "" ? "Company name is required" : "Provide Valid Company name";
		}
		if (companyDetails.companyIndustry === "") {
			validationErrors.companyIndustry = "Company Industry is required";
		}
		// if (companyDetails.companySize === "") {
		// 	validationErrors.companySize = "Company size is required";
		// }
		// if (companyDescription === "") {
		// 	validationErrors.companyDescription = "Company Description is required";
		// }
		// if (whyWorkHere === "") {
		// 	validationErrors.whyWorkAtCompany = "Company Description is required";
		// }
		if (companyDetails.phoneNumber !== "" && !isValidPhoneNumber(companyDetails.phoneNumber)) {
			validationErrors.phoneNumber =
				companyDetails.phoneNumber === "" ? "Company Phone Number is required" : "Provide Valid Company Phone Number";
		}
		if (!isValidURL(companyDetails.companyWebsite)) {
			validationErrors.companyWebsite =
				companyDetails.companyWebsite === "" ? "Company Website is required" : "Provide Valid Company Website";
		}
		if (address === "") {
			validationErrors.address = "Company Address  is required";
		}
		if (country === "") {
			validationErrors.country = "Company Country is required";
		}

		setErrors((prev) => {
			return {
				...prev,
				...validationErrors,
			};
		});
	}, [companyDetails, companyDescription, whyWorkHere, address, country]);

	return (
		<JobNavLayout header="">
			<MappingColumnModal
				showColumnMappingDialog={showColumnMappingDialog}
				handleCloseColumnMappingDialog={handleCloseColumnMappingDialog}
				companiesData={companiesData}
				setCompaniesData={setCompaniesData}
			/>
			<CreateModal
				showCreateCompanyDialog={showCreateCompanyDialog}
				handleCloseCreateCompanyDialog={handleCloseCreateCompanyDialog}
				setShowCreateCompanyDialog={setShowCreateCompanyDialog}
				errors={errors}
				setCompanyDetails={setCompanyDetails}
				companyDetails={companyDetails}
				submitPhase={submitPhase}
				address={address}
				whyWorkHere={whyWorkHere}
				setAddress={setAddress}
				setWhyWorkHere={setWhyWorkHere}
				companyDescription={companyDescription}
				setCompanyContactInfo={setCompanyContactInfo}
				setErrors={setErrors}
				onSubmit={onSubmit}
			/>
			<UpdateModal
				showEditDialog={showEditDialog}
				handleCloseEditDialog={handleCloseEditDialog}
				setShowEditDialog={setShowEditDialog}
				errors={errors}
				companyDetails={companyDetails}
				setCompanyDetails={setCompanyDetails}
				submitPhase={submitPhase}
				address={address}
				setAddress={setAddress}
				whyWorkHere={whyWorkHere}
				setWhyWorkHere={setWhyWorkHere}
				companyDescription={companyDescription}
				setCompanyContactInfo={setCompanyContactInfo}
				setErrors={setErrors}
				onSubmit={onSubmit}
			/>
			<DeleteModal
				showDeleteDialog={showDeleteDialog}
				handleCloseDeleteDialog={handleCloseDeleteDialog}
				setShowDeleteDialog={setShowDeleteDialog}
				selectedCompany={selectedCompany}
				setErrors={setErrors}
				handleDeleteSelectedCompany={handleDeleteSelectedCompany}
			/>
			<div className="my-resumes-section">
				<div className="container">
					<h1
						style={{
							marginTop: -70,
						}}
					>
						Companies
					</h1>
					<div className="border-light">
						<input
							type="text"
							placeholder="Search by job title"
							className="form-control gray-border bg-white shadow border p-2 mb-5 "
							value={companyName}
							onChange={(e) => setCompanyName(e.target.value)}
						/>
					</div>

					<div className="bg-white shadow border p-3 mb-5">
						<div className="d-flex align-items-center flex-wrap mb-2">
							<button
								className="btn btn-sm btn-primary rounded m-1"
								onClick={() => {
									setEditedIndex(0);
									setShowCreateCompanyDialog(true);
								}}
							>
								Add Company
							</button>
							<div className="dropdown mx-2">
								<button type="button" className="btn btn-primary rounded dropdown-toggle" data-toggle="dropdown">
									Data Feed
								</button>
								<div className="dropdown-menu">
									<div className="dropdown-item">
										<a className="dropdown-item" href="/documents/dproz_companies_template.xlsx">
											Download Template
										</a>
									</div>
									<div className="dropdown-item">
										<label className="dropdown-item cursor-pointer" htmlFor="uploadFile">
											Upload
											<input
												type="file"
												accept=".csv, .tsv, .xlsx, .xml"
												className="d-none"
												id="uploadFile"
												onChange={(e) => {
													e.preventDefault();
													if (e.target.files) {
														const file = e.target.files[0];
														processFile(file);
														setShowColumnMappingDialog(true);
													}
												}}
											/>
										</label>
									</div>
								</div>
							</div>
							<button
								className="btn btn-light rounded m-1 text-primary"
								disabled={selectedRows.length === 0}
								onClick={() => {
									setEditedIndex(0);
									setShowEditDialog(true);
								}}
							>
								<span className="fas fa-pen mx-2" />
								Edit {`Compan${selectedRows.length > 1 ? "ies" : "y"}`}
							</button>
							<button
								className="btn btn-light rounded m-1 text-primary"
								disabled={selectedRows.length === 0}
								onClick={() => {
									setShowDeleteDialog(true);
								}}
							>
								<span className="fas fa-trash mx-2" />
								Remove {`Compan${selectedRows.length > 1 ? "ies" : "y"}`}
							</button>
						</div>
						{loading ? (
							<Loader />
						) : (
							<div className="row px-3" style={{ padding: "10px" }}>
								{companies.length > 0 ? (
									<>
										<div className="table-responsive">
											<table className="table table-hover">
												<thead className="bg-light">
													<tr>
														<th>
															<input
																type="checkbox"
																checked={companies.length > 0 && companies.length === selectedRows.length}
																onChange={handleSelectedAllRows}
															/>
														</th>
														<th scope="col">S/N</th>
														<th scope="col">
															<div className="d-flex">
																<p className="my-auto">Company Name</p>
															</div>
														</th>

														<th scope="col">
															<div className="d-flex">
																<p className="my-auto">Company industry</p>
															</div>
														</th>

														<th scope="col">
															<div className="d-flex">
																<p className="my-auto">company Size</p>
															</div>
														</th>

														<th scope="col">
															<div className="d-flex">
																<p className="my-auto">Company Description</p>
															</div>
														</th>

														<th scope="col">
															<div className="d-flex">
																<p className="my-auto">Location</p>
															</div>
														</th>

														<th scope="col">
															<div className="d-flex">
																<p className="my-auto">website</p>
															</div>
														</th>
													</tr>
												</thead>
												<tbody>
													{companies
														.filter((item) =>
															Object.values(item).some((value) =>
																value.toString().toLowerCase().includes(searchTerm.toLowerCase()),
															),
														)
														.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
														.map((company, index) => {
															return (
																<tr key={index}>
																	<td>
																		<input
																			type="checkbox"
																			checked={selectedRows.includes(company._id)}
																			onChange={(event) => {
																				handleSelectedRow(event, company);
																			}}
																		/>
																	</td>
																	<th scope="row">{index + 1 + rowsPerPage * (page - 1)}</th>

																	<td
																		style={{
																			whiteSpace: "nowrap",
																			fontSize: "14px",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																			maxWidth: "100px" /* Adjust the max width as needed */,
																		}}
																	>
																		{company.companyName}
																	</td>
																	<td
																		style={{
																			whiteSpace: "nowrap",
																			fontSize: "14px",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																			maxWidth: "100px" /* Adjust the max width as needed */,
																		}}
																	>
																		{company.companyIndustry}
																	</td>
																	<td
																		style={{
																			whiteSpace: "nowrap",
																			fontSize: "14px",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																			maxWidth: "100px" /* Adjust the max width as needed */,
																		}}
																	>
																		{company.companySize}
																	</td>
																	<td
																		style={{
																			whiteSpace: "nowrap",
																			fontSize: "14px",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																			maxWidth: "100px" /* Adjust the max width as needed */,
																		}}
																	>
																		{getSanitizedHtml(company.companyDescription)}
																	</td>
																	<td
																		style={{
																			whiteSpace: "nowrap",
																			fontSize: "14px",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																			maxWidth: "100px" /* Adjust the max width as needed */,
																		}}
																	>
																		{company.location.city} {company.location.region}
																	</td>
																	<td
																		style={{
																			whiteSpace: "nowrap",
																			fontSize: "14px",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																			maxWidth: "100px" /* Adjust the max width as needed */,
																		}}
																	>
																		{company.website}
																	</td>
																</tr>
															);
														})}
												</tbody>
											</table>
										</div>
										<div className="w-100">
											<EllipsisPagination
												totalPages={totalPages}
												currentPage={page}
												onPageChange={handlePageChange}
												rowsPerPage={rowsPerPage}
												setRowsPerPage={setRowsPerPage}
												activePage={page}
												length={companies.length}
												dataName="Companies"
											/>
										</div>
									</>
								) : (
									<center>{hadFetch ? <h3>No Companies found</h3> : <h3> Search for a Company here</h3>}</center>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</JobNavLayout>
	);
}

export default Companies;
