import React from "react";

import FeedBackModal from "../../../../components/Reviews/FeedBackModal";
import { sections } from "../../../../handlers/enums";
import { convertDateToWords, convertUlToArray, removeHTTPAndDomainName } from "../../../../handlers/utilityFunctions";
import useReviewFeedBack from "../../../../hooks/use-ReviewFeedBack";

function ResumePremiumFour(props) {
	const { isReview, reviewData, resumeData, hasFeedback, activeSection, onSubmitFeedBack, loadingSubmission } = props;
	const { useReviewFeedback, feedBackButton } = useReviewFeedBack(reviewData);

	return (
		<div className="final-resume-preview-wrapper final-premium-template-4 final-resume-preview-new shadow-sm">
			<div className="py-3">
				{resumeData.contactInfo.firstName !== "" && (
					<div className="mb-4">
						<div className="container">
							<div className="d-flex flex-nowrap justify-content-between flex-wrap align-items-start mb-3">
								<div className="info-wrapper" style={{ flex: "0 0 66.66%" }}>
									<div className="d-flex">
										<div>
											<h3 className="mb-0">
												{resumeData.contactInfo.firstName} {resumeData.contactInfo.middleName}{" "}
												{resumeData.contactInfo.lastName}
											</h3>
											<h3 style={{ color: "#174777", fontWeight: "normal" }}>{resumeData.contactInfo.resumeTitle}</h3>
										</div>
									</div>
									<div
										className={`position-relative review-section ${
											isReview
												? "rs-highlight"
												: hasFeedback
													? activeSection === sections.SUMMARY
														? "rs-feedback rs-feedback-active"
														: "rs-feedback"
													: ""
										}`}
										id={sections.SUMMARY}
										style={{
											backgroundColor:
												useReviewFeedback.section.section === sections.SUMMARY ? "rgba(27, 175, 245, 0.12)" : "",
										}}
									>
										{isReview || activeSection === sections.SUMMARY
											? feedBackButton(sections.SUMMARY, hasFeedback)
											: null}
										<p
											className="mb-2"
											style={{ flexGrow: 1 }}
											dangerouslySetInnerHTML={{
												__html: resumeData.summary,
											}}
										/>
									</div>
								</div>
								<div>
									<p className="mb-0 mb-md-3 text-right">
										{resumeData.contactInfo.email}
										<i className="ml-2 fa fa-envelope" aria-hidden="true" style={{ color: "#174777" }} />
									</p>
									<p className="mb-0 mb-md-3 text-right">
										{resumeData.contactInfo.phoneNo}
										<i className="ml-2 fa fa-mobile" style={{ color: "#174777" }} aria-hidden="true" />
									</p>
									<p className="mb-0 mb-md-3 text-right">
										{resumeData.contactInfo.city}, {resumeData.contactInfo.country}
										<i className="ml-2 fa fa-map-marker" style={{ color: "#174777" }} aria-hidden="true" />
									</p>
									{[...resumeData.extras.personalLinks].map((link, index) => {
										return link.type !== "otherLink" ? (
											<p key={index} className="mb-0 mb-md-3 text-right">
												<a href={link.value} target="_blank" rel="noreferrer" className="text-dark">
													{removeHTTPAndDomainName(link)}
												</a>
												<i
													className={`ml-2 ${
														link.type === "linkedIn"
															? "bi bi-linkedin"
															: link.type === "twitter"
																? "bi bi-twitter"
																: link.type === "github"
																	? "bi bi-github"
																	: "fa fa-link"
													}`}
													style={{ color: "#174777" }}
													aria-hidden="true"
												/>
											</p>
										) : null;
									})}
								</div>
							</div>
						</div>
					</div>
				)}

				{resumeData.skills !== "" && convertUlToArray(resumeData.skills) ? (
					<div className="mb-4">
						<div className="container">
							<h2 style={{ borderBottom: "2px solid #174777" }} className="mb-3">
								SKILLS
							</h2>
							<div
								className={`d-flex flex-wrap position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.SKILLS
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.SKILLS}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.SKILLS ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.SKILLS ? feedBackButton(sections.SKILLS, hasFeedback) : null}
								{convertUlToArray(resumeData.skills).map((item, index) => {
									const splitArray = item.value.split(/:(.+)/);
									let wordBeforeColon = splitArray[0].trim();
									wordBeforeColon = splitArray.length > 1 ? <strong>{wordBeforeColon}</strong> : wordBeforeColon;
									const wordAfterColon = splitArray.length > 1 ? ": " + splitArray[1].trim() : "";

									return (
										<div key={index} style={{ width: "100%" }} className="d-flex mb-0 mb-md-3 align-items-center">
											<div>
												{wordBeforeColon}
												{wordAfterColon}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				) : null}

				{resumeData.extras.certifications !== "" ? (
					<div className="mb-4">
						<div className="container">
							<h2 style={{ borderBottom: "2px solid #174777" }} className="mb-3">
								CERTIFICATIONS
							</h2>
							<div
								className={`d-flex flex-wrap position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.CERTIFICATION
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.CERTIFICATION}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.CERTIFICATION ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.CERTIFICATION
									? feedBackButton(sections.CERTIFICATION, hasFeedback)
									: null}
								{convertUlToArray(resumeData.extras.certifications).map((item, index) => {
									return (
										<div key={index} style={{ width: "50%" }} className="d-flex mb-0 mb-md-3 align-items-center">
											<span className="mr-2">{item.value}</span>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				) : null}

				{resumeData.extras.accomplishments !== "" ? (
					<div className="mb-4">
						<div className="container">
							<h2 style={{ borderBottom: "2px solid #174777" }} className="mb-3">
								ACCOMPLISHMENTS
							</h2>
							<div
								className={`d-flex flex-wrap position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.ACCOMPLISHMENTS
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.ACCOMPLISHMENTS}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.ACCOMPLISHMENTS ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.ACCOMPLISHMENTS
									? feedBackButton(sections.ACCOMPLISHMENTS, hasFeedback)
									: null}
								{convertUlToArray(resumeData.extras.accomplishments).map((item, index) => {
									return (
										<div key={index} style={{ width: "50%" }} className="d-flex mb-0 mb-md-3 align-items-center">
											<span className="mr-2">{item.value}</span>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				) : null}

				{resumeData.workHistory.length > 0 && resumeData.workHistory[0].jobTitle !== "" ? (
					<div className="mb-4">
						<div className="container">
							<h2 style={{ borderBottom: "2px solid #174777" }} className="mb-3">
								WORK EXPERIENCE
							</h2>
							<div
								className={`position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.EXPERIENCE
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.EXPERIENCE}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.EXPERIENCE ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.EXPERIENCE
									? feedBackButton(sections.EXPERIENCE, hasFeedback)
									: null}
								{[...resumeData.workHistory].map((job, i) => (
									<div className="d-flex mb-4" key={i}>
										<div
											style={{
												flex: "0 0 35px",
												height: "35px",
												color: "#174777",
												marginTop: "2px",
											}}
										>
											<span className="bi bi-circle" />
										</div>
										<div style={{ flexGrow: 1 }}>
											<div className="d-flex justify-content-between">
												<p className="mb-2">
													<strong>{job.employer}</strong>
													{" - "}
													{job.city}, {job.country}
													<br />
													<em>{job.jobTitle}</em>
												</p>
												<p className="mb-0">
													<strong>
														{convertDateToWords(job.startDate)} -{" "}
														{job.endDate !== "" ? convertDateToWords(job.endDate) : "Present"}
													</strong>
												</p>
											</div>
											<div className="d-flex">
												<span
													style={{
														flex: "0 0 10px",
														border: "1px solid #174777",
														borderRight: 0,
													}}
												/>
												<div style={{ flexGrow: 1 }}>
													{convertUlToArray(job.details).map((item, index) => {
														return (
															<div key={index} className="d-flex" style={{ marginLeft: "-5px", marginBottom: "10px" }}>
																<span style={{ color: "#174777", marginRight: "10px" }}>-</span>
																<span className="w-100">{item.value}</span>
															</div>
														);
													})}
												</div>
												<span
													style={{
														flex: "0 0 10px",
														border: "1px solid #174777",
														borderLeft: 0,
													}}
												/>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				) : null}

				{resumeData.education.length > 0 && (
					<div className="mb-4">
						<div className="container">
							<h2 style={{ borderBottom: "2px solid #174777" }} className="mb-3">
								EDUCATION
							</h2>
							<div
								className={`professional-summary-info mt-2 position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.EDUCATION
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.EDUCATION}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.EDUCATION ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.EDUCATION
									? feedBackButton(sections.EDUCATION, hasFeedback)
									: null}
								{[...resumeData.education].map((educationHistory, index) => (
									<div key={index} className="d-flex mb-4">
										<div
											style={{
												flex: "0 0 35px",
												height: "35px",
												color: "#174777",
												marginTop: "2px",
											}}
										>
											<span className="bi bi-circle" />
										</div>
										<div style={{ flexGrow: 1 }}>
											<div className="d-flex justify-content-between">
												<p>
													<strong>{educationHistory.schoolName}</strong>
													{" - "}
													{educationHistory.schoolCity}, <br />
													<em>{educationHistory.fieldOfStudy}</em>
												</p>
												<p className="mr-4">
													<strong>{educationHistory.gYear}</strong>
												</p>
											</div>
											{convertUlToArray(educationHistory.description).length > 0 &&
												convertUlToArray(educationHistory.description)?.[0]?.value !== "" && (
													<div className="d-flex">
														<span
															style={{
																flex: "0 0 10px",
																border: "1px solid #174777",
																borderRight: 0,
															}}
														/>
														<div style={{ flexGrow: 1 }}>
															{convertUlToArray(educationHistory.description).map((item, index) => {
																if (item.value === "") {
																	return null;
																}
																return (
																	<div
																		key={index}
																		className="d-flex"
																		style={{ marginLeft: "-5px", marginBottom: "10px" }}
																	>
																		<span style={{ color: "#174777", marginRight: "10px" }}>-</span>
																		<span className="w-100">{item.value}</span>
																	</div>
																);
															})}
														</div>
														<span
															style={{
																flex: "0 0 10px",
																border: "1px solid #174777",
																borderLeft: 0,
															}}
														/>
													</div>
												)}
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				)}

				{resumeData.extras.languages.length !== 0 ? (
					<div className="mb-4">
						<div className="container">
							<h2 style={{ borderBottom: "2px solid #174777" }} className="mb-3">
								LANGUAGES
							</h2>
							<div
								className={`d-flex flex-wrap position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.LANGUAGES
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.LANGUAGES}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.LANGUAGES ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.LANGUAGES
									? feedBackButton(sections.LANGUAGES, hasFeedback)
									: null}
								{resumeData.extras.languages.map((language, index) => (
									<div key={index} style={{ width: "50%" }} className="d-flex flex-wrap mb-3 align-items-center">
										<div>
											<span className="mr-2">{language.language}</span>
											{" - "}
											<span className="mr-2">{language.proficiency}</span>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				) : null}

				{resumeData.extras.personalProfile.dateOfBirth && (
					<div className="mb-4">
						<div className="container">
							<h2 style={{ borderBottom: "2px solid #174777" }} className="mb-3">
								PERSONAL INFORMATION
							</h2>
							<div
								className={`d-flex flex-wrap professional-summary-info mt-2 position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.PERSONAL
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.PERSONAL}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.PERSONAL ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.PERSONAL
									? feedBackButton(sections.PERSONAL, hasFeedback)
									: null}
								<div style={{ width: "50%" }} className="d-flex mb-0 mb-md-3 align-items-center">
									<strong>Date of Birth:</strong>&nbsp;
									{resumeData.extras.personalProfile.dateOfBirth}
								</div>
								<div style={{ width: "50%" }} className="d-flex mb-0 mb-md-3 align-items-center">
									<strong>Gender:</strong>&nbsp;
									{resumeData.extras.personalProfile.gender}
								</div>
								<div style={{ width: "50%" }} className="d-flex mb-0 mb-md-3 align-items-center">
									<strong>Marital Status:</strong>&nbsp;
									{resumeData.extras.personalProfile.maritalStatus}
								</div>
							</div>
						</div>
					</div>
				)}

				{resumeData.extras.affiliations !== "" ? (
					<div className="mb-4">
						<div className="container">
							<h2 style={{ borderBottom: "2px solid #174777" }} className="mb-3">
								AFFILIATIONS
							</h2>
							<div
								className={`d-flex flex-wrap position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.AFFILIATION
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.AFFILIATION}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.AFFILIATION ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.AFFILIATION
									? feedBackButton(sections.AFFILIATION, hasFeedback)
									: null}
								{convertUlToArray(resumeData.extras.affiliations).map((item, index) => (
									<div key={index} style={{ width: "50%" }} className="d-flex mb-0 mb-md-3 align-items-center">
										<span className="mr-2">{item.value}</span>
									</div>
								))}
							</div>
						</div>
					</div>
				) : null}

				{resumeData.extras.additionalInfo !== "" ? (
					<div className="mb-4">
						<div className="container">
							<h2 style={{ borderBottom: "2px solid #174777" }} className="mb-3">
								ADDITIONAL INFORMATION
							</h2>
							<div
								className={`d-flex flex-wrap position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.ADDITIONAL_INFO
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.ADDITIONAL_INFO}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.ADDITIONAL_INFO ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.ADDITIONAL_INFO
									? feedBackButton(sections.ADDITIONAL_INFO, hasFeedback)
									: null}
								{convertUlToArray(resumeData.extras.additionalInfo).map((item, index) => (
									<div key={index} style={{ width: "50%" }} className="d-flex mb-0 mb-md-3 align-items-center">
										<span className="mr-2">{item.value}</span>
									</div>
								))}
							</div>
						</div>
					</div>
				) : null}

				{[...resumeData.extras.personalLinks].some((link) => link.type === "otherLink") && (
					<div className="mb-4">
						<div className="container">
							<h2 style={{ borderBottom: "2px solid #174777" }} className="mb-3">
								WEBSITE, PORTFOLIO, PROFILES
							</h2>
							<div
								className={`d-flex flex-wrap position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.LINKS
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.LINKS}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.LINKS ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.LINKS ? feedBackButton(sections.LINKS, hasFeedback) : null}
								{[...resumeData.extras.personalLinks].map((link, index) => {
									return link.type === "otherLink" ? (
										<div key={index} className="d-flex mb-0 mb-md-3 align-items-center">
											<span className="text-capitalize">{link.type}</span>: {link.value}
										</div>
									) : null;
								})}
							</div>
						</div>
					</div>
				)}

				{resumeData.extras.references.length > 0 ? (
					<div className="mb-4">
						<div className="container">
							<h2 style={{ borderBottom: "2px solid #174777" }} className="mb-3">
								REFERENCES
							</h2>
							<div
								className={`d-flex flex-wrap position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.REFERENCE
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.REFERENCE}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.REFERENCE ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.REFERENCE
									? feedBackButton(sections.REFERENCE, hasFeedback)
									: null}
								{[...resumeData.extras.references].map((reference, index) => (
									<div key={index} style={{ width: "50%" }} className="d-flex mb-0 mb-md-3">
										<div>
											<strong>{reference.name}</strong>
											{" - "}
											<em>{reference.designation}, </em>
											{reference.workPlace}
											<br />
											<p style={{ color: "#bcbcbc" }}>
												{reference.email}, {reference.phoneNumber}
											</p>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				) : null}

				{resumeData.extras.customExtras.name.length > 0 ? (
					<div className="mb-4">
						<div className="container">
							<h2 style={{ borderBottom: "2px solid #174777" }} className="mb-3">
								{resumeData.extras.customExtras.name}
							</h2>
							<div
								className={`d-flex flex-wrap position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.CUSTOM
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.CUSTOM}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.CUSTOM ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.CUSTOM ? feedBackButton(sections.CUSTOM, hasFeedback) : null}
								{convertUlToArray(resumeData.extras.customExtras.data).map((item, index) => (
									<div key={index} style={{ width: "50%" }} className="d-flex mb-0 mb-md-3 align-items-center">
										<span className="mr-2">{item.value}</span>
									</div>
								))}
							</div>
						</div>
					</div>
				) : null}
			</div>
			{useReviewFeedback.showFeedBackModal && (
				<FeedBackModal
					resumeData={resumeData}
					details={useReviewFeedback.section}
					onSubmitFeedBack={onSubmitFeedBack}
					loadingSubmission={loadingSubmission}
				/>
			)}
		</div>
	);
}

export default ResumePremiumFour;
