import "../../components/NewNavLayout/dashboardStyle.css";

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import FeedBackDashboard from "../../components/Dashboard/FeedBackDashboard";
import JobPreferenceDashboard from "../../components/Dashboard/JobPreferenceDashboard";
import RecommendedDashboard from "../../components/Dashboard/RecommendedDashboard";
import ResumeStatusDashboard from "../../components/Dashboard/ResumeStatusDashboard";
import RewardDashboard from "../../components/Dashboard/RewardDashboard";
import ExpertResumesLayout from "../../components/ExpertResumesLayout/ExpertResumesLayout";
import { getMyResumesRoutes } from "../../handlers/navigationItems";

const CandidateHomeDashboard = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const storedResumes = localStorage.getItem("resumeList");
	const storedJobPreferences = localStorage.getItem("jobPreferences");

	useEffect(() => {
		if (localStorage.getItem("resumeList") === null || localStorage.getItem("jobPreferences") === null) {
			navigate("/my-resumes");
		}
	});

	return (
		<ExpertResumesLayout navItems={getMyResumesRoutes(dispatch)} headingTitle="Dashboard" showExpertDetails>
			<div
				style={{
					paddingTop: "10px",
					marginTop: "-50px",
					overflow: "hidden",
				}}
			>
				<div className="container-dashboard">
					<div className="left-column">
						<RecommendedDashboard />

						<FeedBackDashboard storedResumes={storedResumes} />
					</div>

					<div className="right-column">
						<ResumeStatusDashboard storedResumes={storedResumes} />

						<JobPreferenceDashboard storedJobPreferences={storedJobPreferences} />

						<RewardDashboard />
					</div>
				</div>
			</div>
		</ExpertResumesLayout>
	);
};

export default CandidateHomeDashboard;
