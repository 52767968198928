import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const EducationSummaryBox = ({ item, index, deleteEduSummary }) => {
	const navigate = useNavigate();

	const eduInfoHandler = () => {
		navigate("/resume/education-information", {
			state: {
				referenceId: index,
			},
		});
	};
	const monthNames = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	const [showDeleteWarning, setShowDeleteWarning] = useState(false);

	return (
		<>
			<div className="summary-box font-inter">
				<div className="col-12 p-0">
					<div className="row">
						<div className="col-md-9">
							<div className="mb-3">
								<h5 className="font-weight-semi fs-16 m-0">
									{item.educationLevel}, {item.fieldOfStudy}
								</h5>
								<p className="fs-14">
									{item.schoolName} • {monthNames[item.gMonth - 1]} {item.gYear}
								</p>
							</div>
							<div className="bottom">
								<div
									dangerouslySetInnerHTML={{
										__html:
											item.description.length > 350 ? item.description.slice(0, 300).trim() + "..." : item.description,
									}}
								/>
							</div>
						</div>

						<div className="col-md-3">
							<div className="d-flex justify-content-end align-items-center">
								<span onClick={eduInfoHandler} className="border light-button mr-2 cursor-pointer">
									<i className="fa-regular pre-text fa-pen-to-square" /> Edit
								</span>
								<span onClick={() => setShowDeleteWarning(true)} className="btn border rounded fs-16">
									<span className="bi bi-trash text-danger" />
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showDeleteWarning && (
				<div
					className="modal fade"
					style={{
						display: "grid",
						opacity: "1",
						backgroundColor: "rgb(0 0 0 / 60%)",
						overflow: "visible",
						placeItems: "center",
					}}
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title tr-title mb-0">You are about to delete this item</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
									onClick={() => setShowDeleteWarning(false)}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body pb-5">
								<p>Are you sure you want to delete this item?</p>

								<div className="d-flex justify-content-end">
									<button
										type="button"
										className="btn btn-secondary mr-2"
										data-dismiss="modal"
										onClick={() => setShowDeleteWarning(false)}
									>
										Cancel
									</button>

									<button
										type="button"
										className="btn btn-primary"
										onClick={() => {
											deleteEduSummary(item.referenceId);
											setShowDeleteWarning(false);
										}}
									>
										Delete
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default EducationSummaryBox;
