import React from "react";

function ListItem({ pct, children }) {
	return (
		<>
			{pct > 80 && (
				<li className="d-flex justify-content-between py-3 border-bottom">
					<div className="d-flex align-items-center">
						<span className="icon h2 m-0 p-1 me-2">
							<i className="text-success b m-0i bi-check-circle-fill" />
						</span>
						{children}
					</div>
					<div className="d-flex align-items-center">
						<span className="badge text-bg-light text-dark">High fit</span>
					</div>
				</li>
			)}
			{pct >= 50 && pct < 80 && (
				<li className="d-flex justify-content-between py-3 border-bottom">
					<div className="d-flex align-items-center">
						<span className="icon h2 m-0 p-1 me-2">
							<i className="text-warning b m-0i bi-clock-fill" />
						</span>
						{children}
					</div>
					<div className="d-flex align-items-center">
						<span className="badge text-bg-light text-dark">Moderate fit</span>
					</div>
				</li>
			)}

			{pct < 50 && (
				<li className="d-flex justify-content-between py-3 border-bottom">
					<div className="d-flex align-items-center">
						<span className="icon h2 m-0 p-1 me-2">
							<i className="text-danger bi m-0 bi-info-circle-fill" />
						</span>
						{children}
					</div>
					<div className="d-flex align-items-center">
						<span className="badge text-bg-light text-dark">Low fit</span>
					</div>
				</li>
			)}
		</>
	);
}

export default ListItem;
