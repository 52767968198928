import React from "react";

function TemplatePremiumTwo() {
	return (
		<div style={{ scale: "1.2", transformOrigin: "center" }}>
			<div className="small-resume-preview small-resume-preview-premium-template-2" style={{ maxWidth: "400px" }}>
				<div className="final-resume-preview bg-white resume-type-1 text-dark">
					<div className="p-3">
						<div className="d-flex justify-content-between align-items-center mb-1">
							<div className="info-wrapper" style={{ flex: "0 0 55%" }}>
								<div className="d-flex">
									<span className="mr-2 d-inline-block mt-1 border-icon" style={{ backgroundColor: "#000" }} />
									<div>
										<h1 className="mb-1" style={{ color: "#003366" }}>
											JACQUILINE KHALFAN
										</h1>
										<h3
											className="mb-1"
											style={{
												color: "#9b9b9b",
												fontWeight: 500,
											}}
										>
											Business Development Manager
										</h3>
										<p className="mb-1">
											Professional Business Developer with four year of experience in the business Development
											processes. Involved in product testing, management and development of new business opportunities.
										</p>
									</div>
								</div>
							</div>
							<div>
								<p className="mb-1 text-right">
									jacquiline.khalfan@dproz.tz
									<i className="ml-1 fa fa-envelope" aria-hidden="true" style={{ color: "#003366" }} />
								</p>
								<p className="mb-1 text-right">
									202-555-0166
									<i className="ml-1 fa fa-mobile" aria-hidden="true" />
								</p>
								<p className="mb-2 text-right">
									New York, USA
									<i className="ml-2 fa fa-map-marker" aria-hidden="true" />
								</p>
								<p className="mb-1 text-right">
									linkedin.com/in/jacquiline.khalfan
									<i className="ml-1 fa fa-linkedin" aria-hidden="true" />
								</p>
								<p className="mb-1 text-right">
									jacquiline.khalfan
									<i className="ml-1 fa fa-skype" aria-hidden="true" />
								</p>
							</div>
						</div>
						<div className="row" style={{ borderTop: "1px solid #ababab" }}>
							<div className="mb-3 col-6">
								<div className="d-flex">
									<span className="mr-2 d-inline-block mt-2 border-icon" style={{ backgroundColor: "#8e8e8e" }} />
									<div>
										<h4 className="text-uppercase mt-2 font-weight-bold">WORK EXPERIENCE</h4>
										<h5 className="font-weight-bold mb-0">Business Development Manager</h5>
										<h6 className="mb-0" style={{ fontWeight: "500" }}>
											AirState Solutions
											<i className="fa fa-share-square-o ml-2" aria-hidden="true" style={{ color: "#003366" }} />
										</h6>
										<div
											className="d-flex align-items-center justify-content-between font-italic"
											style={{
												color: "#bababa",
												fontWeight: 500,
											}}
										>
											<p className="mb-0">09/2014 - 06/2017</p>
											<p className="mb-0">New York, USA</p>
										</div>
										<ul className="pl-2">
											<li className="mb-1">
												Successfully managed $2 - 3 million budget projects and successfully achieved the project
												scheduled goals.
											</li>
											<li className="mb-1">
												Developed and implemented new marketing and sales plans and defined the strategy for the next 5
												years.
											</li>
										</ul>
									</div>
								</div>
								<div className="d-flex">
									<span style={{ backgroundColor: "#8e8e8e" }} className="mr-3 d-inline-block mt-1 border-icon" />
									<div>
										<h5 className="font-weight-bold mt-1 mb-0">Business Development Assistant</h5>
										<h6 className="mb-0" style={{ fontWeight: 500 }}>
											AirState Solutions
											<i className="fa fa-share-square-o ml-2" aria-hidden="true" style={{ color: "#003366" }} />
										</h6>
										<div
											className="d-flex align-items-center justify-content-between font-italic"
											style={{ color: "#bababa", fontWeight: 500 }}
										>
											<p className="mb-0">08/2012 - 09/2014</p>
											<p className="mb-0">Chicago, USA</p>
										</div>
										<ul className="pl-2">
											<li className="mb-1">
												Increased the customer satisfaction rate by 25% by improving the customer service.
											</li>
											<li className="mb-1">
												Planned, supervised, and coordinated daily activity of 3 junior business analysts.
											</li>
										</ul>
									</div>
								</div>

								<div className="mb-2">
									<div className="d-flex">
										<span className="mr-2 d-inline-block mt-2 border-icon" style={{ backgroundColor: "#8e8e8e" }} />
										<div>
											<h4 className="text-uppercase mt-2 mb-0 font-weight-bold">EDUCATION</h4>
											<h5 className="font-weight-bold mt-2 mb-0">MSc in Economics and Business Administration</h5>
											<h6 className="font-weight-500 mb-0" style={{ fontWeight: 500 }}>
												The University of Chicago
											</h6>
											<p
												className="font-italic"
												style={{
													color: "#bababa",
													fontWeight: 500,
												}}
											>
												09/2008 - 06/2010
											</p>
										</div>
									</div>
								</div>

								<div className="mb-2 pl-4">
									<h4 className="text-uppercase mt-3 mb-0 font-weight-bold">LANGUAGES</h4>
									<div className="row" style={{ fontWeight: 500 }}>
										<div className="col-6">
											<p className="mt-2 mb-0">English</p>
											<p className="font-italic" style={{ color: "#bababa" }}>
												Limited Working Proficiency
											</p>
											<p />
										</div>
										<div className="col-6">
											<p className="mt-2 mb-0">Spanish</p>
											<p className="font-italic" style={{ color: "#bababa" }}>
												Limited Working Proficiency
											</p>
											<p />
										</div>
										<div className="col-6">
											<p className="mt-2 mb-0">French</p>
											<p className="font-italic" style={{ color: "#bababa" }}>
												Limited Working Proficiency
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="mb-2 col-6 pl-3">
								<h4 className="text-uppercase mt-3 font-weight-bold">skills</h4>
								<div className="mt-1 mb-1 p-0 row m-0">
									<div className="col-6 pl-0">
										<ul className="px-0" style={{ listStyle: "none" }}>
											<li className="mb-1">SEO</li>
											<li className="mb-1">Negotiation</li>
											<li className="mb-1">Decission Making</li>
											<li className="mb-1">Emotional Intelligence</li>
										</ul>
									</div>

									<div className="col-6 pl-0">
										<ul className="px-0" style={{ listStyle: "none" }}>
											<li className="mb-1">Public Speaking</li>
											<li className="mb-1">Teamwork</li>
											<li className="mb-1">Research and Strategy</li>
											<li className="mb-1">Outbound Marketingn</li>
										</ul>
									</div>

									<div className="mb-2">
										<h4 className="text-uppercase  font-weight-bold">ORGANIZATIONS</h4>

										<h6 style={{ fontWeight: 500 }}>
											<p className="mb-0">American Management Association</p>
											<p className="mb-2">(2015 - Present)</p>
											<p className="mb-0">Association of Private Enterprise Education</p>
											<p className="mb-2">(2014 - Present)</p>
										</h6>
									</div>

									<div className="mb-2">
										<h4 className="text-uppercase mt-1 font-weight-bold">HONOURS AND AWARDS</h4>
										<h6 className="mb-0" style={{ fontWeight: 500 }}>
											Jury Member, Venture Cup Entrepreneurship Competition (2016)
										</h6>
										<p
											className="font-italic"
											style={{
												color: "#9c9c9c",
												fontWeight: 500,
											}}
										>
											Venture Cup USA
										</p>
										<h6 className="mb-0" style={{ fontWeight: 500 }}>
											Sales Individual &amp; Business Development Award (2015)
										</h6>
										<p
											className="font-italic"
											style={{
												color: "#9c9c9c",
												fontWeight: 500,
											}}
										>
											AirState Business Awards
										</p>
										<h6 className="mb-0" style={{ fontWeight: 500 }}>
											Excellence in Customer Partnering Award
										</h6>
										<p className="font-italic" style={{ color: "#9c9c9c", fontWeight: 500 }}>
											IES - Institute of Excellence in Sales
										</p>
									</div>

									<div className="mb-2">
										<h4 className="text-uppercase mt-1 font-weight-bold">CONFERENCES &amp; COURSES</h4>
										<h6 className="mb-0" style={{ fontWeight: 500 }}>
											Leading People and Teams Specialization (03/2016 - 10/2016)
										</h6>
										<p
											className="font-italic"
											style={{
												color: "#9c9c9c",
												fontWeight: 500,
											}}
										>
											Online course on Coursera.org
										</p>
										<h6 className="mb-0" style={{ fontWeight: 500 }}>
											eMetrics Summit (09/2016)
										</h6>
										<p
											className="font-italic"
											style={{
												color: "#9c9c9c",
												fontWeight: 500,
											}}
										>
											Rising Media, Inc. and eMetrics Summit
										</p>
										<h6 className="mb-0" style={{ fontWeight: 500 }}>
											Project Management Principles and Practices (01/2015 - 09/2015)
										</h6>
										<p className="font-italic" style={{ color: "#9c9c9c", fontWeight: 500 }}>
											Online course on Coursera.org
										</p>
									</div>

									<div className="mb-0">
										<h4 className="text-uppercase mt-3 font-weight-bold">INTERESTS</h4>
										<ul className="mt-1 mb-0 p-0">
											<li
												className="rounded-lg  d-inline-block mb-2"
												style={{
													border: "1px solid #bfbfbf",
													fontWeight: 500,
													padding: "1px 2px;",
												}}
											>
												Artificial Intelligence
											</li>
											<li
												className="rounded-lg d-inline-block mb-2"
												style={{
													border: "1px solid #bfbfbf",
													fontWeight: 500,
													padding: "1px 2px;",
												}}
											>
												Economics
											</li>
											<li
												className="rounded-lg d-inline-block mb-2"
												style={{
													border: "1px solid #bfbfbf",
													fontWeight: 500,
													padding: "1px 2px;",
												}}
											>
												History
											</li>
											<li
												className="rounded-lg d-inline-block mb-2"
												style={{
													border: "1px solid #bfbfbf",
													fontWeight: 500,
													padding: "1px 2px;",
												}}
											>
												Neuromarketing
											</li>
											<li
												className="rounded-lg d-inline-block mb-2"
												style={{
													border: "1px solid #bfbfbf",
													fontWeight: 500,
													padding: "1px 2px;",
												}}
											>
												Consumer
											</li>
											<li
												className="rounded-lg d-inline-block mb-2"
												style={{
													border: "1px solid #bfbfbf",
													fontWeight: 500,
													padding: "1px 2px;",
												}}
											>
												Behaviour
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="preview text-center mt-4 mb-4">
				<button
					className="btn text-primary btn-sm btn-outline-primary"
					data-toggle="modal"
					data-target="#ResumePreview"
				>
					<span className="fa fa-eye" /> Preview
				</button>
			</div>
		</div>
	);
}

export default TemplatePremiumTwo;
