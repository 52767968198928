import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import Loader from "../../../components/Loader";
import Spinner from "../../../components/Spinner";
import InputField from "../../../components/UIComponents/InputField";
import { calculateMaximumRedeemablePoints, currencyFormat, formatAmount } from "../../../handlers/utilityFunctions";
import { phoneTo255 } from "../../../handlers/valueTransformation";
import { ResumeDataService } from "../../../services/create-resume/resume-data";

function Checkout({
	setCurrentStep,
	checkout,
	setPaymentDetails,
	paymentDetails,
	loading,
	serverErrors,
	selectedPackage,
}) {
	const apiService = new ResumeDataService();
	const [loadingRewards, setLoadingRewards] = React.useState(true);
	const [usePoints, setUsePoints] = useState(false);
	const [rewards, setRewards] = React.useState({
		amountPaidByPoints: 0,
		amount: 0,
		originalAmount: 0,
		rewards: [],
		totalAvailablePoints: 0,
		redeemableRewardIds: [],
	});

	const getPaymentsRewards = () => {
		apiService.getPaymentsRewards(
			(data) => {
				const availablePoints = data.data.filter((item) => item.rewardStatus === "CLAIMED" && item.rewardPoints > 0);
				const payWithPointsDetails = calculateMaximumRedeemablePoints(selectedPackage, availablePoints);
				setRewards(payWithPointsDetails);
				setLoadingRewards(false);
			},
			() => {
				toast.error("Something went wrong!");
				setLoadingRewards(false);
			},
		);
	};

	React.useEffect(() => {
		getPaymentsRewards();
	}, []);

	const onUsePoints = () => {
		if (!usePoints) {
			setPaymentDetails((prev) => {
				return {
					...prev,
					modeOfPayment: "POINTS_AND_CASH",
					originalAmount: rewards.originalAmount,
					amount: rewards.amount,
					amountPaidByPoints: rewards.amountPaidByPoints,
					redeemableRewardIds: rewards.redeemableRewardIds,
				};
			});
		}
		if (usePoints) {
			let newPaymentDetails = paymentDetails;
			delete newPaymentDetails.modeOfPayment;
			delete newPaymentDetails.originalAmount;
			delete newPaymentDetails.amountPaidByPoints;
			delete newPaymentDetails.redeemableRewardIds;
			setPaymentDetails({
				...newPaymentDetails,
				amount: rewards.originalAmount,
			});
		}
		setUsePoints(!usePoints);
	};

	function getStoredData() {
		if (paymentDetails.fullName === "" && paymentDetails.phoneNumber === "" && paymentDetails.emailAddress === "") {
			const storedData = JSON.parse(localStorage.getItem("profileData"));
			if (storedData) {
				const { firstName, lastName, phoneNumber, email } = storedData;
				setPaymentDetails((prev) => {
					return {
						...prev,
						fullName: `${firstName} ${lastName}`,
						phoneNumber: phoneTo255(phoneNumber),
						emailAddress: email,
					};
				});
			}
		}
	}

	useEffect(() => {
		getStoredData();
	}, []);

	function handleChange(e) {
		const { name, value } = e.target;
		setPaymentDetails({ ...paymentDetails, [name]: value });
	}

	const [errors, setErrors] = useState([...serverErrors]);

	useEffect(() => {
		setErrors([...serverErrors]);
	}, [serverErrors]);

	function handleSubmit(e) {
		e.preventDefault();
		const { fullName, phoneNumber } = paymentDetails;

		//validation
		const errors = [];
		if (!fullName) {
			errors.push("Your name is required");
		}

		if (!phoneNumber) {
			errors.push("Phone number is required");
		}

		if (!paymentDetails.amount || !paymentDetails.packageDetail) {
			setCurrentStep(1);
		}

		if (errors.length > 0) {
			setErrors(errors);
			return;
		}

		checkout(paymentDetails);
	}

	return (
		<>
			{loadingRewards ? (
				<Loader />
			) : (
				<section className="bg-white py-4">
					<div className="container" style={{ maxWidth: "1000px" }}>
						<h1 className="text-center">Payment Details</h1>
						<p className="lead text-center">
							Enter your payment details below. You&#39;ll receive a message on the entered phone number with a payment
							link or a push to complete the payment.
						</p>
						<p className="lead mb-3 text-center text-danger">
							Amount due {"Tsh " + currencyFormat(paymentDetails.amount) + "/="}
						</p>
						<form method="post">
							{errors.length > 0 && (
								<div className="alert alert-danger">
									{errors.map((error, index) => (
										<li key={index}>{error}</li>
									))}
								</div>
							)}

							{rewards.totalAvailablePoints > 0 && (
								<div className="card mb-3 mx-2" onClick={() => {}}>
									<div className="card-body d-flex align-items-center">
										<div className="mr-3">
											<input type="checkbox" checked={usePoints} onClick={onUsePoints} onChange={() => {}} />
										</div>
										<div className="ml-3">
											<p className="card-title m-0">
												Use {rewards.amountPaidByPoints} out of {formatAmount(rewards.totalAvailablePoints)} reward
												points to unlock savings!
											</p>
											<div className="d-flex">
												<p className="card-text m-0">
													<span>Only Pay TZS </span>
													<span className="h5">{formatAmount(rewards.amount)}</span>
													/=
												</p>
												<p className="mb-0">
													<span
														className="bg-success ml-2 py-1 px-3 text-white rounded-pill"
														style={{
															fontSize: "0.9rem",
														}}
													>
														You save TZS {formatAmount(rewards.amountPaidByPoints)}
														/=
													</span>
												</p>
											</div>
										</div>
									</div>
								</div>
							)}

							<InputField
								type="text"
								className="form-control"
								name="phoneNumber"
								placeholder="Phone Number"
								value={paymentDetails.phoneNumber}
								onChange={handleChange}
								label="Phone Number"
								id="phoneNumber"
							/>
							<InputField
								type="text"
								className="form-control"
								name="fullName"
								placeholder="Full Name"
								value={paymentDetails.fullName}
								onChange={handleChange}
								label="Full Name"
								id="fullName"
							/>
							<InputField
								type="email"
								className="form-control"
								name="emailAddress"
								placeholder="Full Name"
								value={paymentDetails.emailAddress}
								onChange={handleChange}
								label="Email Address"
								id="emailAddress"
							/>
							<div className="text-right">
								<button className="btn btn-secondary mr-2" onClick={() => setCurrentStep(1)}>
									Back
								</button>

								<button className="btn btn-primary" onClick={handleSubmit} disabled={loading}>
									{loading ? <Spinner /> : "Make Payment"}
								</button>
							</div>
						</form>
					</div>
				</section>
			)}
		</>
	);
}

export default Checkout;
