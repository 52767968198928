import "../../NewNavLayout/dashboardStyle.css";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import useSetResumeIdCookie from "../../../hooks/use-SetResumeIdCookie";

const ResumeStatusDashboard = ({ storedResumes }) => {
	const { setResumeIdCookie } = useSetResumeIdCookie();
	const [resumeList, setResumeList] = useState([]);

	useEffect(() => {
		if (storedResumes) {
			const _resumeList = [];
			const _storedResume = JSON.parse(storedResumes);
			for (let x = 0; x <= 2; x++) {
				_resumeList.push(_storedResume[x]);
			}
			setResumeList(_resumeList);
		}
	}, []);

	return (
		<div className="segment-dashboard">
			{resumeList.length === 0 ? (
				<div className="d-flex p-4 align-center justify-content-center">
					<div className="float-right">
						<h2 style={{ fontFamily: "Varela Round", fontSize: "1.5rem" }}> No Resumes Found! </h2>
						<p>
							Start <Link to="/resume/select-experience"> building </Link>
							your resume now and benefit from reviews, job recommendation and much more.
						</p>
					</div>
				</div>
			) : (
				<>
					<h2>Resume Status</h2>
					<ul className="top-segment-dashboard-left-list">
						{resumeList.map((item, index) => {
							if (item?.journal) {
								return (
									<li key={index}>
										<div className="top-segment-dashboard-left-container">
											<div className="top-segment-dashboard-left-container-head">
												<div className="top-segment-dashboard-left-image">
													<i className="fa fa-info-circle mr-2 text-info" aria-hidden="true" />
												</div>
												<div className="top-segment-dashboard-left-name">
													<h3>{item.journal.resumeTitle ? item.journal.resumeTitle : item.heading.resumeTitle}</h3>
												</div>
												<div
													className="top-segment-dashboard-left-link"
													onClick={() => {
														setResumeIdCookie(item.id);
														window.location.replace("/resume/review");
													}}
												>
													<i className="fa fa-arrow-right mr-2 text-primary" />
												</div>{" "}
											</div>

											<div className="ml-4 top-segment-dashboard-left-details">
												<p>
													Status:
													<span
														className={`ml-3 ${
															item.journal.currentStatus === "COMPLETED" ? "bg-success" : "bg-danger"
														} badge badge-pill text-white`}
													>
														{item.journal.currentStatus}
													</span>
												</p>
											</div>
										</div>
									</li>
								);
							}
						})}
					</ul>

					<Link to="/my-resumes">
						<button className="view-all-button">More details</button>
					</Link>
				</>
			)}
		</div>
	);
};

export default ResumeStatusDashboard;
