import React from "react";

import Icon from "../Icon";
import AvatarChip from "./AvatarChip";

export const rates = [1, 2, 3, 4, 5];

function OrderRatings({ data, showExpertProfileOnRating, enableRating }) {
	const [ratings, setRatings] = React.useState(data?.ratings?.data || []);

	const onClickRating = (ratingValue, index) => {
		setRatings((prev) => {
			let newRatings = [...prev];
			newRatings[index].value = ratingValue;
			return newRatings;
		});
	};

	return (
		<div
			className="d-flex flex-column align-items-start"
			style={{ padding: "20px", gap: "12px", background: "#F7F8F8", borderRadius: "12px", width: "100%" }}
		>
			{data?.ratings?.title && (
				<div className="grouped-skill-text-title" style={{ fontSize: "16px", lineHeight: "24px" }}>
					{data?.ratings?.title}
				</div>
			)}
			{showExpertProfileOnRating && (
				<AvatarChip
					fullName={data?.ratings?.expertDetails?.fullName}
					userProfileUrl={data?.ratings?.expertDetails?.profileUrl || null}
				/>
			)}
			<div className="d-flex flex-column align-items-start p-0" style={{ gap: 12 }}>
				{ratings.map((item, index) => {
					return (
						<div
							key={item.title}
							className="d-flex align-items-center justify-content-between"
							style={{ width: "364px" }}
						>
							<div className="expert-review-navitem-text" style={{ color: "#3E424E" }}>
								{item.title}
							</div>
							<div className="d-flex align-items-center p-0">
								{rates.map((ratingItem) => {
									if (item.value < ratingItem) {
										return (
											<div
												key={ratingItem}
												style={{ ...(enableRating && { cursor: "pointer" }) }}
												onClick={() => enableRating && onClickRating(ratingItem, index)}
											>
												<Icon iconName="star" size={32} />
											</div>
										);
									}

									return (
										<div
											key={ratingItem}
											style={{ ...(enableRating && { cursor: "pointer" }) }}
											onClick={() => enableRating && onClickRating(ratingItem, index)}
										>
											<Icon key={ratingItem} iconName="filled-star" size={32} />
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default OrderRatings;
