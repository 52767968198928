import mapping from "../../seed/metadata/filemapping";

class ResumeTemplate {
	constructor(templateName, templatePath, templateIcon) {
		this.templateName = templateName;
		this.templatePath = templatePath;
		this.templateIcon = templateIcon;
	}
}

class LevelOfExperience {
	constructor(name, description, templateList, premiumTemplateList, doesItShow = true) {
		this.name = name;
		this.description = description;
		this.templateList = templateList;
		this.premiumTemplateList = premiumTemplateList;
		this.showInList = doesItShow;
	}
}

export class LevelsOfExperience {
	constructor() {
		this.listOfExperience = [];
		this.initializeTemplatesGroupedByYrsOfExperienceCategory();
	}

	addYearsOfExperienceCategory(name, description, templateList, premiumTemplateList, doesItShow = true) {
		let levelOfExperince = new LevelOfExperience(name, description, templateList, premiumTemplateList, doesItShow);
		this.listOfExperience.push(levelOfExperince);
	}

	initializeTemplatesGroupedByYrsOfExperienceCategory() {
		const resumeTempates = new ResumeTempates();
		this.addYearsOfExperienceCategory(
			"General Template",
			"General",
			resumeTempates.genericTemplates,
			resumeTempates.premiumTemplates,
			false,
		);
		this.addYearsOfExperienceCategory(
			"No Experience",
			"No Experience",
			resumeTempates.noExperienceCategoryRecommendedTemplates,
			resumeTempates.premiumTemplates,
		);
		this.addYearsOfExperienceCategory(
			"1-5 Years",
			"1-5 Years",
			resumeTempates.oneYearExperienceCategoryRecommendedTemplates,
			resumeTempates.premiumTemplates,
		);
		this.addYearsOfExperienceCategory(
			"5-10 Years",
			"5-10 Years",
			resumeTempates.fiveYearExperienceCategoryRecommendedTemplates,
			resumeTempates.premiumTemplates,
		);
		this.addYearsOfExperienceCategory(
			"10+ Years",
			"10+ Years",
			resumeTempates.tenYearExperienceCategoryRecommendedTemplates,
			resumeTempates.premiumTemplates,
		);
	}
}

export default class ResumeTempates {
	constructor() {
		this.genericTemplates = [];
		this.noExperienceCategoryRecommendedTemplates = [];
		this.oneYearExperienceCategoryRecommendedTemplates = [];
		this.fiveYearExperienceCategoryRecommendedTemplates = [];
		this.tenYearExperienceCategoryRecommendedTemplates = [];
		this.premiumTemplates = [];
		this.initializeSupportedtemplates();
	}

	/**
	 * Usage
	 * #1- Initialize a new template
	 * #2- Add new template to either generic templates or one of the recommended category bucket
	 */
	initializeSupportedtemplates() {
		const no_experience_template_1 = new ResumeTemplate(
			"fresher_template_1",
			"fresher-template-1.png",
			"fresher-template-1.png",
		);

		const early_mid_career_template_1 = new ResumeTemplate(
			"fresher_template",
			"fresher-template.png",
			"fresher-template.png",
		);
		const early_mid_career_template_2 = new ResumeTemplate("template_1", "template-1.png", "template-1.png");

		const premium_template_1 = new ResumeTemplate(
			"premium_template_1",
			"premium-template-1.png",
			"premium-template-1.png",
		);
		const premium_template_2 = new ResumeTemplate(
			"premium_template_2",
			"premium-template-2.png",
			"premium-template-2.png",
		);
		const premium_template_3 = new ResumeTemplate(
			"premium_template_3",
			"premium-template-3.png",
			"premium-template-3.png",
		);
		const premium_template_4 = new ResumeTemplate(
			"premium_template_4",
			"premium-template-4.png",
			"premium-template-4.png",
		);
		const premium_template_5 = new ResumeTemplate(
			"premium_template_5",
			"premium-template-5.png",
			"premium-template-5.png",
		);
		const premium_template_6 = new ResumeTemplate(
			"premium_template_6",
			"premium-template-6.png",
			"premium-template-6.png",
		);
		const premium_template_7 = new ResumeTemplate(
			"premium_template_7",
			"premium-template-7.png",
			"premium-template-7.png",
		);
		const premium_template_8 = new ResumeTemplate(
			"premium_template_8",
			"premium-template-8.png",
			"premium-template-8.png",
		);
		const premium_template_9 = new ResumeTemplate(
			"premium_template_9",
			"premium-template-9.png",
			"premium-template-9.png",
		);
		const premium_template_10 = new ResumeTemplate(
			"premium_template_10",
			"premium-template-10.png",
			"premium-template-10.png",
		);

		//No Experience Templates
		this.noExperienceCategoryRecommendedTemplates.push(no_experience_template_1);

		//1+ Year of Experience Templates
		this.oneYearExperienceCategoryRecommendedTemplates.push(early_mid_career_template_1);

		//5+ Year of Experience Templates
		this.fiveYearExperienceCategoryRecommendedTemplates.push(early_mid_career_template_1, early_mid_career_template_2);

		//10+ Year of Experience Templates
		this.tenYearExperienceCategoryRecommendedTemplates.push(early_mid_career_template_1, early_mid_career_template_2);

		//General Template
		//this.genericTemplates.push(midplus_career_template_1);

		//Premium Template
		const templates = [
			{ name: "premium_template_1", template: premium_template_1 },
			{ name: "premium_template_2", template: premium_template_2 },
			{ name: "premium_template_3", template: premium_template_3 },
			{ name: "premium_template_4", template: premium_template_4 },
			{ name: "premium_template_5", template: premium_template_5 },
			{ name: "premium_template_6", template: premium_template_6 },
			{ name: "premium_template_7", template: premium_template_7 },
			{ name: "premium_template_8", template: premium_template_8 },
			{ name: "premium_template_9", template: premium_template_9 },
			{ name: "premium_template_10", template: premium_template_10 },
		];
		const premiumTemplatesSettings = mapping["Premium Templates Configurations"][0].premiumTemplates;
		for (let index = 0; index < templates.length; index++) {
			const template = templates[index];
			if (premiumTemplatesSettings[template.name]) this.premiumTemplates.push(template.template);
		}
	}
}
