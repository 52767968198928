import "../css/global.css";
import "../css/home.css";
import "../css/responsive.css";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import dropDownOptions from "../../../seed/dropDownOptions";
import Footer from "../Footer";
import Header from "../Header";

function HomePage() {
	const [showVideo, setShowVideo] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 500);
	}, []);

	return (
		<>
			<Header activeLink="home" />
			{loading && (
				<div id="aa-preloader-area">
					<div className="pulse" />
				</div>
			)}
			{/*SCROLL TOP BUTTON*/}
			{/*<a className="scrollToTop" href="#"><i className="fa fa-angle-double-up"></i></a>*/}
			{/*END SCROLL TOP BUTTON*/}
			{/*header*/}
			{/*header ends*/}
			{/*home-banner*/}
			<section className="home-banner px-3">
				<div className="container home-container">
					<div className="row">
						<div className="col-md-5 pt-5">
							<div className="home-banner-content">
								{/*<h6>Resume crafted, Preferences set, Success assured</h6>*/}
								<h2>
									{/*Guiding You to the <span className="text-primary">Job You've Dreamed About</span>.*/}
									Elevate Your Job Search <span className="text-primary">with Dproz</span>
								</h2>
								<p>Craft Tailored Resumes, Get Shortlisted, Land Your Dream Job. Act Now to Transform Your Career!</p>

								<div className="my-4 d-flex rounded home-banner-dropdown">
									<div className="play-action font-weight-bold">
										<button
											className="btn home-btn  btn-outline-dark btn-sm"
											type="button"
											style={{ color: "#000000" }}
											onClick={() => setShowVideo(true)}
										>
											Watch Video
										</button>
									</div>
									<div className="dropdown user-area-dropdown  ml-auto ml-lg-3">
										<button
											className="btn home-btn  home-btn-primary btn-sm dropdown-toggle"
											type="button"
											data-toggle="dropdown"
											aria-expanded="false"
										>
											Start Now
										</button>
										<div className="dropdown-menu">
											<Link className="dropdown-item" to="/jobs">
												I have a resume on Dproz
											</Link>
											<Link className="dropdown-item" to="/resume/select-experience">
												I don&apos;t have a resume on Dproz
											</Link>
										</div>
									</div>
								</div>
								{/*<p className="mb-3">More DProz insights</p>*/}
								{/*<div className="play-action font-weight-bold">*/}
								{/*	<button className="play-btn border-0 bg-transparent" onClick={() => setShowVideo(true)}>*/}
								{/*		<img src="/images/home/icons/PlayBtn.svg" alt="playbtn" />*/}
								{/*	</button>*/}
								{/*	Play Video*/}
								{/*</div>*/}
							</div>
						</div>
						<div className="col-md-7">
							<div className="home-banner-img">
								<img src="/images/home/banner-img.png" alt="home-banner" />
							</div>
						</div>
					</div>
				</div>
			</section>
			{/*home-banner ends*/}
			<section className="why-us px-3 py-5 bg-light">
				<div className="container home-container">
					<h2 className="text-black mb-3">
						What’s My <br />
						Investment<span className="text-primary"> Worth?</span>
					</h2>
					<div className="row align-items-center">
						<div className="col-lg-4 mb-4 mb-md-0">
							<img src="/images/home/what-makes.png" alt="what-makes" />
						</div>
						<div className="col-lg-8">
							<div className="row">
								<div className="col-md-6 mb-4">
									<div className="why-us-box">
										<span className="icon d-inline-block mb-2">
											<i className="fa-regular fa-clock text-primary fs-30" />
										</span>
										<h5 className="mb-2">1. Lost Time, Vanished Hopes</h5>
										<p>
											You’ve watched hours and days slip away on fruitless job applications, and your hopes for a
											brighter future are fading.
										</p>
										<p>
											Dproz is your path to reclaiming that lost time. We guide your efforts to reduce wasted time on
											irrelevant job applications. Craft tailored resumes that boost your chances of landing
											opportunities and reignite your hope for a brighter future.
										</p>
									</div>
								</div>
								<div className="col-md-6 mb-4">
									<div className="why-us-box">
										<span className="icon d-inline-block mb-2">
											<i className="fa fa-deaf text-primary fs-30" />
										</span>
										<h5 className="mb-2">2. Deafening Silence, Stifled Dreams</h5>
										<p>
											The deafening silence of rejection leaves you feeling lost, with dreams of career progression and
											personal growth stifled by inaction.
										</p>
										<p>
											Don’t let silence hold you back. Dproz is your beacon of hope. We provide you with valuable
											feedback and actionable insights on rejections. Unlock your potential to stand out and achieve
											your dreams as you craft professional, eye-catching resumes.
										</p>
									</div>
								</div>
								<div className="col-md-12 mb-4">
									<div className="why-us-box">
										<span className="icon d-inline-block mb-2">
											<i className="fa-regular fa-eye-slash text-primary fs-30" />
										</span>
										<h5 className="mb-2">3. Stagnation’s Stranglehold, Unseen Potential</h5>
										<p>
											Stagnation’s stranglehold keeps you from progressing, and your untapped potential for a thriving
											career remains unseen.
										</p>
										<p>
											Break free from stagnation with Dproz. We’re your key to seizing opportunity. We’ll connect you
											with the right job opportunities and help you discover and harness your untapped potential for
											career growth and personal fulfillment. Your future is waiting, and we’ll help you reach it.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="home-common-section px-3 py-5">
				<div className="container home-container">
					<div className="row align-items-center">
						<div className="col-md-6  order-2 order-md-1">
							<div className="content">
								<h6 className="text-muted mb-3">FOR JOB SEEKERS</h6>
								<h3 className="mb-4">Here&apos;s Your Path to Career Success</h3>
								<p className="mb-4">
									With Dproz, you’re in control of your success story. We enable you to reclaim your time, make a
									memorable impact, and accelerate your career. This is achieved through personalized resume creation,
									insightful job fit feedback, and precise job connections – all tailored for your journey to success.
								</p>
								<div className="pt-3">
									{/*<Link to={"/resume/select-experience"} className="btn btn-primary">Upload Your Resume</Link>*/}
									<div className="dropdown user-area-dropdown  ml-auto">
										<button
											className="btn home-btn  home-btn-primary btn-sm dropdown-toggle"
											type="button"
											data-toggle="dropdown"
											aria-expanded="false"
										>
											Start Here
										</button>
										<div className="dropdown-menu">
											<Link className="dropdown-item" to="/jobs">
												I have a resume on Dproz
											</Link>
											<Link className="dropdown-item" to="/resume/select-experience">
												I don&apos;t have a resume on Dproz
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6  order-1 order-md-2 mb-md-0 mb-4">
							<img src="/images/home/professional-img.png" alt="" />
						</div>
					</div>
				</div>
			</section>
			<section className="stats bg-primary text-white px-3 py-5">
				<div className="container home-container py-lg-4">
					<div className="col-lg-9 px-0">
						<h3 className="mb-4">
							With 70+ years of combined industry experience, we&apos;ve been job seekers and hiring managers ourselves.
						</h3>
						<ul className="d-flex flex-wrap stats-list">
							<li>
								<h5 className="font-weight-normal">
									We understand the power of a tailored resume and have incorporated recruiter-recommended best
									practices into Dproz to help you craft a professional resume in minutes. Plus, we&apos;re partnering
									with employers to connect you with the right opportunities in Tanzania.
								</h5>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="home-evaluation-process px-3 pb-5 pt-5 bg-light">
				<div className="container home-container">
					<h2 className="text-center mb-5">How It Works?</h2>
					<div className="row">
						<div className="col-md-8">
							<div className="mb-5 text-center">
								<p className="font-weight-bold fs-12 text-primary">
									Rest assured, we are here to assist you at every step
								</p>
								<img src="/images/home/icons/dashed-line.svg" alt="dashed-line" />
							</div>
							<div className="row">
								<div className="col-md-6 mb-5 mb-md-0">
									<div className="evaluation-box bg-white h-100 ">
										<span className="sl-no text-muted-3 font-weight-bold d-inline-block mb-5">01.</span>
										<h3 className="mb-3">Create Resume</h3>
										<p className="fs-15 text-medium">
											<span className="font-weight-bold">Build</span> your resume on Dproz for a standout professional
											profile. This helps you create a powerful first impression on potential employers, showcasing your
											skills and experience effectively.
										</p>
									</div>
								</div>
								<div className="col-md-6 mb-5 mb-md-0">
									<div className="evaluation-box bg-white h-100 ">
										<span className="sl-no text-muted-3 font-weight-bold d-inline-block mb-5">02.</span>
										<h3 className="mb-3">Set Preferences</h3>
										<p className="fs-15 text-medium">
											<span className="font-weight-bold">Tailor</span> your job preferences to receive personalized
											opportunities. This step ensures that you only see job listings that align with your career goals
											and interests, saving you time and effort.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4 mb-5 mb-md-0">
							<div className="evaluation-box bg-white h-100 ">
								<Link to="/resume/select-experience" className="btn home-btn btn-block home-btn-primary mb-5">
									Start Now
								</Link>
								<span className="sl-no text-primary font-weight-bold d-inline-block mb-5">03.</span>
								<h3 className="mb-3">Smart Apply</h3>
								<p className="fs-15 text-medium">
									<span className="font-weight-bold">Discover</span> and
									<span className="font-weight-bold"> Apply</span> to recommended jobs by Dproz with fit analysis for an
									efficient job search. This streamlines your job search, making it more effective and convenient.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="job-category bg-light px-3 py-5">
				<div className="container home-container">
					<h2 className="mb-5">
						Browse Jobs <br />
						by Categories
					</h2>

					<div className="row">
						<div className="col-md-4 col-lg-3 col-sm-6 mb-4">
							<Link to="/jobs" state={{ jobExpertise: dropDownOptions.jobExpertiseOptions[11] }}>
								<div className="job-category-box bg-white rounded h-100 p-4">
									<span className="icon d-inline-block mb-3">
										<img src="/images/home/icons/analytic-icon.svg" alt="analytic-icon" />
									</span>
									<h5 className="mb-3">
										Business <br />
										Development
									</h5>
									<p className="text-muted">24k Job Vacancy</p>
								</div>
							</Link>
						</div>
						<div className="col-md-4 col-lg-3 col-sm-6 mb-4">
							<Link to="/jobs" state={{ jobExpertise: dropDownOptions.jobExpertiseOptions[8] }}>
								<div className="job-category-box bg-white rounded h-100 p-4">
									<span className="icon d-inline-block mb-3">
										<img src="/images/home/icons/customer-support-icon.svg" alt="customer-support-icon" />
									</span>
									<h5 className="mb-3">
										Customer <br />
										Support
									</h5>
									<p className="text-muted">19k Job Vacancy</p>
								</div>
							</Link>
						</div>
						<div className="col-md-4 col-lg-3 col-sm-6 mb-4">
							<Link to="/jobs" state={{ jobExpertise: dropDownOptions.jobExpertiseOptions[17] }}>
								<div className="job-category-box bg-white rounded h-100 p-4">
									<span className="icon d-inline-block mb-3">
										<img src="/images/home/icons/marketing-icon.svg" alt="marketing-icon" />
									</span>
									<h5 className="mb-3">
										Marketing & <br />
										Communication
									</h5>
									<p className="text-muted">19k Job Vacancy</p>
								</div>
							</Link>
						</div>
						<div className="col-md-4 col-lg-3 col-sm-6 mb-4">
							<Link to="/jobs" state={{ jobExpertise: dropDownOptions.jobExpertiseOptions[21] }}>
								<div className="job-category-box bg-white rounded h-100 p-4">
									<span className="icon d-inline-block mb-3">
										<img src="/images/home/icons/product-management-icon.svg" alt="product-management-icon" />
									</span>
									<h5 className="mb-3">
										Product <br />
										Management
									</h5>
									<p className="text-muted">19k Job Vacancy</p>
								</div>
							</Link>
						</div>
						<div className="col-md-4 col-lg-3 col-sm-6 mb-4">
							<Link to="/jobs" state={{ jobExpertise: dropDownOptions.jobExpertiseOptions[15] }}>
								<div className="job-category-box bg-white rounded h-100 p-4">
									<span className="icon d-inline-block mb-3">
										<img src="/images/home/icons/computing-icon.svg" alt="computing-icon" />
									</span>
									<h5 className="mb-3">
										Computing &
										<br />
										ICT
									</h5>
									<p className="text-muted">24k Job Vacancy</p>
								</div>
							</Link>
						</div>
						<div className="col-md-4 col-lg-3 col-sm-6 mb-4">
							<Link to="/jobs" state={{ jobExpertise: dropDownOptions.jobExpertiseOptions[10] }}>
								<div className="job-category-box bg-white rounded h-100 p-4">
									<span className="icon d-inline-block mb-3">
										<img src="/images/home/icons/education-icon.svg" alt="education-icon" />
									</span>
									<h5 className="mb-3">
										Education & <br />
										Training
									</h5>
									<p className="text-muted">19k Job Vacancy</p>
								</div>
							</Link>
						</div>
						<div className="col-md-4 col-lg-3 col-sm-6 mb-4">
							<Link to="/jobs" state={{ jobExpertise: dropDownOptions.jobExpertiseOptions[13] }}>
								<div className="job-category-box bg-white rounded h-100 p-4">
									<span className="icon d-inline-block mb-3">
										<img src="/images/home/icons/location-icon.svg" alt="location-icon" />
									</span>
									<h5 className="mb-3">
										Hospitality, <br />
										Catering & Tourism
									</h5>
									<p className="text-muted">19k Job Vacancy</p>
								</div>
							</Link>
						</div>
						<div className="col-md-4 col-lg-3 col-sm-6 mb-4">
							<div className="job-category-box d-flex align-items-center justify-content-center h-100 p-4">
								<Link
									to="/jobs"
									className="btn home-btn home-btn-primary bg-primary-light text-primary py-3 px-4 border-0 h5 mb-0"
								>
									See All
									<span className="fa fa-arrow-right ml-2" />
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="home-common-section px-3 pt-2 pb-5">
				<div className="container home-container">
					<div className="row align-items-center justify-content-between">
						<div className="col-md-6 order-2 order-md-1 mb-md-0 mb-4">
							<div className="content">
								<h6 className="text-muted mb-3">FOR STUDENTS</h6>
								<h3 className="mb-4">
									Let’s start by Creating <br />
									your Resume.
								</h3>
								<p className="mb-4">
									Finding the right career path begins with an exceptional resume and creating one has never been more
									convenient. With Dproz, you create, update, download and share your resume in an instant.
								</p>
								<div className="pt-3">
									<Link to="/resume/select-experience" className="btn home-btn home-btn-primary">
										Create Resume
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-5 col-md-6 order-1 order-md-2">
							<img src="/images/home/student-img.png" alt="student-img" />
						</div>
					</div>
				</div>
			</section>
			<section className="affliated-universities px-3 py-5 bg-light border-top border-bottom">
				<div className="container home-container">
					<h3 className="text-center mb-5">Join Other Graduates of Many Collages on Dproz</h3>
					<ul className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between no-list-style">
						<li className="px-3 px-md-4">
							<img src="/images/home/university-logo-1.svg" alt="universities" />
						</li>
						<li className="px-3 px-md-4">
							<img src="/images/home/university-logo-2.svg" alt="universities" />
						</li>
						<li className="px-3 px-md-4">
							<img src="/images/home/university-logo-3.svg" alt="universities" />
						</li>
						<li className="px-3 px-md-4">
							<img src="/images/home/university-logo-4.svg" alt="universities" />
						</li>
						<li className="px-3 px-md-4">
							<img src="/images/home/university-logo-5.svg" alt="universities" />
						</li>
					</ul>
				</div>
			</section>
			<section className="home-common-section px-3 py-5">
				<div className="container home-container">
					<div className="row align-items-center">
						<div className="col-md-6">
							<img src="/images/home/graduate-student-img.png" alt="graduate-student-img" />
						</div>
						<div className="col-md-6">
							<div className="content">
								<h6 className="text-muted mb-3">FOR GRADUATES</h6>
								<h3 className="mb-4">Elevate Your Job Search with Dproz</h3>
								<span className="mb-4">
									<p className="p-custom">
										At Dproz, we understand you want to become a successful, fulfilled professional, you want a
										rewarding career that matches your aspirations. But, the complexity of job searching and the
										frustration of rejections are holding you back. That’s where we come in – we’re your guide to
										overcoming these hurdles.
									</p>
									<p className="p-custom">
										Building on years of industry experience, we offer a unique blend of expertise and have a simple
										plan for you: Build a standout resume, Tailor your job preferences for personalized opportunities,
										and Apply directly or Discover recommended jobs with a fit analysis for an efficient job search.
									</p>
									<p className="p-custom">
										Take action now to avoid wasting more time and missing out on your dream career. Cast a vision for
										your life where you’re thriving in a career that truly fulfills your potential.
									</p>
								</span>
								<div className="pt-3">
									<Link to="/resume/select-experience" className="btn home-btn home-btn-primary">
										Act Now
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="employers-universities px-3 py-5 bg-light border-top border-bottom">
				<div className="container home-container">
					<h3 className="text-center mb-5">Prestigious Employers of Our Candidates.</h3>
					<ul className="d-flex flex-wrap align-items-center justify-content-center no-list-style">
						<li className="px-3 px-md-4">
							<img src="/images/home/vodacom.svg" alt="company-logo" />
						</li>
						<li className="px-3 px-md-4">
							<img src="/images/home/nmb.svg" alt="company-logo" />
						</li>
						<li className="px-3 px-md-4">
							<img src="/images/home/sanlam.svg" alt="company-logo" />
						</li>
						<li className="px-3 px-md-4">
							<img src="/images/home/jubiliee.svg" alt="company-logo" />
						</li>
						<li className="px-3 px-md-4">
							<img src="/images/home/deloitte.svg" alt="company-logo" />
						</li>
					</ul>
				</div>
			</section>
			{/*<section className="home-common-section py-5">*/}
			{/*	<div className="container home-container">*/}
			{/*		<div className="row align-items-center">*/}
			{/*			<div className="col-md-6  order-2 order-md-1">*/}
			{/*				<div className="content">*/}
			{/*					<h6 className="text-muted mb-3">FOR PROFESSIONAL</h6>*/}
			{/*					<h3 className="mb-4">The next step in your career is a click away.</h3>*/}
			{/*					<p className="mb-4">*/}
			{/*						Never miss a career changing opportunity just because you didn’t carry an updated resume. One-click jump-on*/}
			{/*						to access, update, download & share. Dproz is a convenient, time-saving and a one stop-shop platform.*/}
			{/*					</p>*/}
			{/*					<div className="pt-3">*/}
			{/*						<Link to={"/resume/select-experience"} className="btn btn-primary">Upload Your Resume</Link>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="col-md-6  order-1 order-md-2 mb-md-0 mb-4">*/}
			{/*				<img src="/images/home/professional-img.png" alt="" />*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</section>*/}
			<section className="stats bg-primary text-white px-3 py-5">
				<div className="container home-container py-lg-4">
					<div className="col-lg px-0">
						<h2 className="mb-4">Addressing Your Concerns</h2>
						<div className="row">
							<div className="col-6">
								<h4>1. The product is too expensive</h4>
								<h6 className="font-weight-normal">
									At Dproz, we understand that cost is a concern for many job seekers. However, we’re committed to
									providing a cost-effective solution that can significantly impact your career. Our unique blend of
									expertise and years of industry experience ensures you’re investing wisely in your future.
								</h6>
							</div>
							<div className="col-6">
								<h4>2. I doubt it will work for me</h4>
								<h6 className="font-weight-normal">
									We understand your skepticism, but we’ve helped countless job seekers find success. With our proven
									plan and personalized approach, we’re confident Dproz can work for you too. Don’t let doubt hold you
									back from your potential.
								</h6>
							</div>
							<div className="col-6">
								<h4>3. What if it doesn’t work for me.</h4>
								<h6 className="font-weight-normal">
									We’re dedicated to your success. In the unlikely event that Dproz doesn’t meet your expectations, we
									have a support system in place. You can rely on our expertise and customer support to guide you on
									your journey.
								</h6>
							</div>
							<div className="col-6">
								<h4>4. I doubt the quality is as good as they say it is.</h4>
								<h6 className="font-weight-normal">
									We pride ourselves on delivering high-quality solutions. Our years of industry experience have
									equipped us to provide a product that not only meets but exceeds your expectations. You can trust in
									the quality of Dproz to elevate your career.
								</h6>
							</div>
							<div className="col-6">
								<h4>5. The process could take too long, and I already have a resume. Why start over?</h4>
								<h6 className="font-weight-normal">
									We understand your time is valuable. Dproz is designed to streamline the process, allowing you to
									create a standout resume quickly. By tailoring your existing resume, you’re building upon what you
									already have, making the transition seamless and efficient.
								</h6>
							</div>
							<div className="col-6">
								<h4>6. I have tried something like this before, and it didn’t work.</h4>
								<h6 className="font-weight-normal">
									We’ve learned from the experiences of job seekers like you and continually improved our platform.
									Dproz is built on proven strategies and a wealth of industry expertise, ensuring a better chance at
									success. Don’t let past experiences deter you from a solution that can truly make a difference in your
									job search.
								</h6>
							</div>
							<div className="col-6">
								<h4>7. I won’t know how to use it once I start.</h4>
								<h6 className="font-weight-normal">
									We’ve designed Dproz to be user-friendly. With our guidance and user support, you’ll quickly become
									adept at using our platform to your advantage. You don’t have to navigate this journey alone; we’re
									here to help you every step of the way.
								</h6>
							</div>
							<div className="col-12 mt-3">
								<h5 className="font-weight-normal">
									When you join Dproz, remember that our user support team is dedicated to making your experience a
									positive and successful one. You’re never alone on this journey, and we’re here to assist whenever you
									need guidance or have any questions.
								</h5>
							</div>
						</div>
						<div className="d-flex justify-content-center">
							<Link to="/resume/select-experience" className="btn home-btn home-btn-white px-5 mt-3">
								Want to Stand out? Get Started
							</Link>
						</div>
					</div>
				</div>
			</section>
			<section className="bg-light px-3 py-5 text-center home-features-section">
				<div className="container home-container">
					<h2 className="mb-4">Dproz: Elevate, Differentiate, Succeed</h2>

					<p className="mb-5">Unlock Your Full Potential with a Dproz Resume.</p>

					<div className="row">
						<div className="col-md-4 col-sm-6 mb-4 mb-md-5">
							<div className="feature-box">
								<div className="benefit mb-4">
									<img src="/images/home/icons/ready-templates-icon.svg" alt="icon" />
									<h5>Professional Templates</h5>
								</div>
								<p>
									Using ready templates, expertly crafted jobs-specific contents to communicate your value confidently.
								</p>
							</div>
						</div>
						<div className="col-md-4 mb-4 mb-md-5 col-sm-6">
							<div className="feature-box">
								<div className="benefit mb-4">
									<img src="/images/home/icons/professional-guidance-icon.svg" alt="icon" />
									<h5>Expert Guidance</h5>
								</div>
								<p>Eliminating guesswork by incorporating best practices and guiding you step-by-step.</p>
							</div>
						</div>
						<div className="col-md-4 mb-4 mb-md-5 col-sm-6">
							<div className="feature-box">
								<div className="benefit mb-4">
									<img src="/images/home/icons/job-specific-icon.svg" alt="icon" />
									<h5>Time Saving</h5>
								</div>
								<p>One click duplicate and tailor CV to a job profile you want in minutes.</p>
							</div>
						</div>
						<div className="col-md-4 mb-4 mb-md-5 col-sm-6">
							<div className="feature-box">
								<div className="benefit mb-4">
									<img src="/images/home/icons/easy-update-icon.svg" alt="icon" />
									<h5>Easily Update</h5>
								</div>
								<p>As you accomplish things & gain new skills, just one section at a time.</p>
							</div>
						</div>
						<div className="col-md-4 mb-4 mb-md-5 col-sm-6">
							<div className="feature-box">
								<div className="benefit mb-4">
									<img src="/images/home/icons/access-anywhere-icon.svg" alt="icon" />
									<h5>Access Anywhere</h5>
								</div>
								<p>
									Never miss a great opportunity for not carrying an up-to-date CV. Jump-on to access, update, download
									& share.
								</p>
							</div>
						</div>
						<div className="col-md-4 mb-4 mb-md-5 col-sm-6">
							<div className="feature-box">
								<div className="benefit mb-4">
									<img src="/images/home/icons/employer-icon.svg" alt="icon" />
									<h5>Smart Profile</h5>
								</div>
								<p>Searching jobs and connecting you with relevant opportunities & employers.</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/*<section className="home-testimonials py-5">*/}
			{/*	<div className="container home-container">*/}
			{/*		<div className="row">*/}
			{/*			<div className="col-md-4 mb-md-0 mb-4">*/}
			{/*				<h2 className="mb-4 pb-lg-3">Hear from Our Happy Users</h2>*/}
			{/*				<p>*/}
			{/*					Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam*/}
			{/*					rem aperiam, eaque ipsa quae ab illo inventore{" "}*/}
			{/*				</p>*/}
			{/*			</div>*/}
			{/*			<div className="col-md-4 mb-md-0 mb-4">*/}
			{/*				<div className="home-testimonial-box position-relative">*/}
			{/*					<img src="/images/home/client-img.png" alt="client-img" className="client-img" />*/}
			{/*					<div className="client-info bg-primary text-white fs-12 position-absolute px-2 py-1">*/}
			{/*						<p className="font-weight-bold mb-0">Octavia Jonas, Product Manager</p>*/}
			{/*						<p className="mb-0">SAYONA Group Ltd</p>*/}
			{/*					</div>*/}
			{/*					<button className="btn-play bg-transparent border-0 position-absolute">*/}
			{/*						<img src="/images/home/icons/PlayBtn-2.png" alt="playbtn" />*/}
			{/*					</button>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="col-md-4">*/}
			{/*				<div className="home-testimonial-box position-relative">*/}
			{/*					<img src="/images/home/client-img-2.png" alt="client-img" className="client-img" />*/}
			{/*					<div className="client-info bg-primary text-white fs-12 position-absolute px-2 py-1">*/}
			{/*						<p className="font-weight-bold mb-0">Rashid Kunambi, CFO</p>*/}
			{/*						<p className="mb-0">Nabaki Africa Ltd</p>*/}
			{/*					</div>*/}
			{/*					<button className="btn-play bg-transparent border-0 position-absolute">*/}
			{/*						<img src="/images/home/icons/PlayBtn-2.png" alt="playbtn" />*/}
			{/*					</button>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</section>*/}
			<Footer />
			{showVideo && (
				<div
					className="modal"
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						position: "absolute",
						overflow: "hidden",
						zIndex: 9999,
						backgroundColor: "rgba(0,0,0,0.5)",
						height: "100vh",
						width: "100vw",
					}}
				>
					<div
						className="modal-content p-3"
						style={{
							backgroundColor: "transparent",
							border: "none",
							width: "100%",
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<div className="row mb-3 w-100">
							<div className="col-12 text-right">
								<button className="close text-white" onClick={() => setShowVideo(false)}>
									<i className="bi bi-x" />
								</button>
							</div>
						</div>
						<div
							className="player-wrapper"
							style={{
								width: "90%",
								overflow: "hidden",
								aspectRatio: "16/9",
							}}
						>
							<iframe
								width="100%"
								height="100%"
								src="https://www.youtube.com/embed/c9L1ZEWcJkY"
								title="YouTube video player"
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
								style={{
									radius: "10px",
									overflow: "hidden",
								}}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default HomePage;
