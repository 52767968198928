import React, { useState } from "react";
import toast from "react-hot-toast";

import Spinner from "../../components/Spinner";
import { ResumeDataService } from "../../services/create-resume/resume-data";

function LeadReviewModal({ sharedLeadID, setData }) {
	const [reviewDecision, setReviewDecision] = useState({
		reviewDecision: "", // ["Good Lead. Posted", "Not Appropriate", "Not Covered", "Lead Already Exist"]
		jobMarketReferenceId: "", // required only if "reviewDecision": "Good Lead. Posted"
	});

	const [loading, setLoading] = useState(false);

	const apiService = new ResumeDataService();

	function onSubmit(e) {
		e.preventDefault();
		if (reviewDecision.reviewDecision === "Good Lead. Posted" && reviewDecision.jobMarketReferenceId === "") {
			toast.error("Job Market Reference Id is required");
			return;
		}

		if (reviewDecision.reviewDecision === "") {
			toast.error("Review Decision is required");
			return;
		}
		setLoading(true);

		apiService.closeSharedLead(
			{
				id: sharedLeadID,
				...reviewDecision,
			},
			() => {
				toast.success("Lead reviewed successfully");
				setData(null);
				setLoading(false);
				window.location.reload();
			},
			(e) => {
				const error = e.response.data.message;
				toast.error(error);
				setLoading(false);
			},
		);
	}

	return (
		<div
			className="modal"
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: "rgba(0,0,0,0.5)",
				height: "100vh",
				width: "100vw",
			}}
		>
			<div style={{ maxWidth: "90vw", zIndex: 2000 }}>
				<form className="bg-white card card-body" style={{ width: "600px", maxWidth: "90vw" }} onSubmit={onSubmit}>
					<div className="d-flex justify-content-between align-items-center">
						<h4>Review Lead</h4>
						<button type="button" className="btn p-0 m-0" aria-label="Close" onClick={() => setData(null)}>
							<i className="fa-solid fa-times text-dark" />
						</button>
					</div>
					<hr className="w-100" />
					<div className="mb-3">
						<label htmlFor="reviewDecision" className="form-label">
							Review Decision
						</label>
						{["Good Lead. Posted", "Not Appropriate", "Not Covered", "Lead Already Exist"].map((decision, index) => (
							<div className="mb-2" key={index}>
								<div className="form-check">
									<input
										className="form-check-input"
										type="checkbox"
										name="reviewDecision"
										id={decision.replaceAll(" ", "")}
										value={decision}
										onChange={(e) => setReviewDecision({ ...reviewDecision, reviewDecision: e.target.value })}
										checked={reviewDecision.reviewDecision === decision}
									/>
									<label className="form-check-label" htmlFor="goodLeadPosted">
										{decision}
									</label>
								</div>
							</div>
						))}
					</div>
					{reviewDecision.reviewDecision === "Good Lead. Posted" && (
						<div className="mb-3">
							<label htmlFor="jobMarketReferenceId" className="form-label">
								Job Market Reference Id
							</label>
							<input
								type="text"
								className="form-control"
								id="jobMarketReferenceId"
								value={reviewDecision.jobMarketReferenceId}
								onChange={(e) => setReviewDecision({ ...reviewDecision, jobMarketReferenceId: e.target.value })}
							/>
						</div>
					)}
					<div className="mb-3">
						<button className="btn btn-primary" onClick={onSubmit}>
							{loading ? <Spinner /> : "Close Lead"}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default LeadReviewModal;
