import React from "react";

function StatusToast({ message, timeout = 500000 }) {
	const [show, setShow] = React.useState(true);

	React.useEffect(() => {
		setTimeout(() => {
			setShow(false);
		}, timeout);
	}, [timeout]);

	return (
		<>
			{show && (
				<div
					className="toast text-white"
					role="alert"
					aria-live="assertive"
					aria-atomic="true"
					style={{
						opacity: 1,
						position: "fixed",
						top: "180px",
						zIndex: 9999,
						right: "5%",
						backgroundColor: "#2a9d8f",
						border: "none",
						borderRadius: "5px",
					}}
				>
					<div className="toast-body">{message}</div>
				</div>
			)}
		</>
	);
}

export default StatusToast;
