import React from "react";

import Icon from "../Icon";
import AvatarChip from "./AvatarChip";

function OrderListTile({
	data,
	leadingIcon,
	leadingIconSize,
	showSeeMoreText,
	trailingButtons,
	expandCard,
	setExpandCard,
}) {
	return (
		<div
			className="d-flex align-items-center justify-content-between flex-wrap p-0"
			style={{ gap: "8px", width: "100%" }}
		>
			<div className="d-flex align-items-center p-0" style={{ gap: "20px" }}>
				{leadingIcon && <Icon iconName={leadingIcon} size={leadingIconSize} />}
				<div className="d-flex flex-column align-items-start p-0" style={{ gap: "8px" }}>
					{data?.title && (
						<div className="resume-review-welcome-text" style={{ fontSize: "18px", color: "#121316" }}>
							{data?.title}
						</div>
					)}
					{data?.subTitle ? (
						<>
							{!expandCard && (
								<div className="expert-review-footer-text" style={{ color: "#3E424E" }}>
									{data?.subTitle}
									{showSeeMoreText && (
										<span
											className="expert-review-top-nav-avatar-text cursor-pointer"
											style={{ color: "#0069CB" }}
											onClick={() => setExpandCard((prev) => !prev)}
										>
											{" "}
											See more
										</span>
									)}
								</div>
							)}
							{expandCard && (
								<div className="d-flex flex-column align-items-start p-0" style={{ gap: 16 }}>
									{data?.moreDetails?.title && (
										<div className="expert-review-orders-header-title" style={{ color: "#3E424E" }}>
											{data?.moreDetails?.title}
										</div>
									)}
									<div className="d-flex flex-column align-items-start p-0" style={{ gap: 16 }}>
										{data?.moreDetails &&
											data?.moreDetails?.map((item, index) => {
												return (
													<div key={index}>
														<div className="expert-review-footer-text" style={{ color: "#3E424E" }}>
															{item.parent}
														</div>
														{item?.children &&
															item?.children.map((child) => {
																return (
																	<li
																		key={child}
																		className="expert-review-footer-text"
																		style={{ paddingLeft: 8, color: "#3E424E" }}
																	>
																		{child}
																	</li>
																);
															})}
													</div>
												);
											})}
									</div>
								</div>
							)}
						</>
					) : (
						data?.coordinator && (
							<AvatarChip fullName={data?.coordinator} userProfileUrl={data?.coordinatorProfileUrl || null} />
						)
					)}
				</div>
			</div>
			<div className="d-flex align-items-center flex-wrap p-0" style={{ gap: "8px" }}>
				{trailingButtons &&
					trailingButtons.map((item, index) => {
						if (item?.type === "iconButton") {
							return (
								<div
									key={index}
									className="d-flex flex-column align-items-center justify-content-center p-0"
									style={{
										gap: "8px",
										width: "40px",
										height: "40px",
										border: "1px solid #EDEEF1",
										borderRadius: "6px",
									}}
								>
									<i className="bi bi-eye cursor-pointer" style={{ fontSize: "20px" }} />
								</div>
							);
						}
						return (
							<div
								key={index}
								className="d-flex align-items-center justify-content-center cursor-pointer p-0"
								style={{
									gap: "8px",
									border: "1px solid #EDEEF1",
									borderRadius: "6px",
									width: item?.width || 100,
									height: "41px",
									background: item?.background || "white",
								}}
							>
								{item?.icon && <Icon iconName={item?.icon} size={20} />}
								{item?.text && (
									<div className="mark-review-complete-text-button" style={{ color: item?.color || "black" }}>
										{item?.text}
									</div>
								)}
							</div>
						);
					})}
			</div>
		</div>
	);
}

export default OrderListTile;
