import React from "react";
import { Link } from "react-router-dom";

const JobItem = ({ job }) => {
	function shorten(str, maxLen, separator = " ") {
		if (str.length <= maxLen) return str;
		const short = str.substr(0, str.lastIndexOf(separator, maxLen));
		return short.concat(" ...");
	}

	const description = shorten(job.description, 150);

	return (
		<>
			<div className="job-item">
				<div className="job-info">
					<div className="job-info-top">
						<div className="company-logo">
							<img
								src="https://www.apple.com/ac/structured-data/images/knowledge_graph_logo.png?202007151520"
								alt="images"
								className="img-fluid"
							/>
						</div>
						<div className="job-info-top-content">
							<h4>
								<Link to={`/job-details/${job.id}`}>{job.title}</Link>
							</h4>
							<div className="job-info-top-content-bottom">
								<p className="company-name">
									<span className="fa fa-building-o" />
									<span>{job.name}</span>
								</p>
								<p className="company-location">
									<span className="fa fa-map-marker" />
									<span>{job.location}</span>
								</p>
							</div>
						</div>
						<div className="job-actions">
							<Link to={`/job-application/${job.id}`}>
								<button className="btn btn-dropdown text-primary bg-white px-4">Apply</button>
							</Link>
							<a href="#" className="bookmark">
								<span className="fa fa-star" />
							</a>
						</div>
					</div>
					<div className="job-info-top" style={{ paddingLeft: 50 + "px" }}>
						<div className="job-info-top-content">
							<div className="job-info-top-content">
								<div className="job-info-top-content-bottom">
									<p className="company-name">
										<span className="fa fa-briefcase" />
										<span>{job.workExperience}</span>
									</p>
									<p className="company-location">
										<span className="fa fa-money" />
										<span>{job.compensation}</span>
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="job-desc">
						<p>{description}</p>
					</div>
					<div className="time">
						<a href="#">
							<span>Full Time</span>
						</a>
						<span className="float-right">Posted 23 hours ago</span>
					</div>
				</div>
			</div>
		</>
	);
};

export default JobItem;
