import { match } from "../handlers/utilityFunctions";

export const jobSearchStatusOptions = [
	{
		value: "Actively Looking",
		description: "Show recruiters and others that you’re Open to work",
		classIcon: "fa fa-briefcase text-primary",
	},
	{
		value: "Open for Reach outs",
		description:
			"You’re actively looking for new work and ready to interview. Your job profile will be visible to companies",
		classIcon: "fa fa-briefcase text-success",
	},
	{
		value: "Not Looking",
		description:
			"You’re not looking and don’t want to hear about new opportunites. Your job profile will be hidden to companies",
		classIcon: "fa fa-briefcase text-danger",
	},
];

export const waysToHelpOptions = [
	{
		value: "Show me the right Jobs to Apply",
		description: "Recommend jobs related to your career",
		classIcon: "fa fa-user text-primary",
	},
	{
		value: "Recommend me to Employers",
		description: "Suggests to employers that you fit to particular jobs",
		classIcon: "fa fa-user text-success",
	},
	{
		value: "Strengthen my CVs",
		description: "Improving the quality and proffesion of your CVs",
		classIcon: "fa fa-user text-danger",
	},
];

export const locationTypes = [
	{
		value: "Open to Work Anywhere",
		description: "Jobs in all country regions will be recommended",
		classIcon: "fa fa-map-marker text-primary",
	},
	{
		value: "Open to Work in select Regions",
		description: "Only jobs selected to particular regions will be recommended",
		classIcon: "fa fa-map-marker text-success",
	},
];

export const regions = [
	"Arusha",
	"Dar Es Salaam",
	"Dodoma",
	"Geita",
	"Iringa",
	"Kagera",
	"Katavi",
	"Kigoma",
	"Kilimanjaro",
	"Lindi",
	"Manyara",
	"Mara",
	"Mbeya",
	"Mjini Magharibi",
	"Morogoro",
	"Mtwara",
	"Mwanza",
	"Njombe",
	"Pemba North",
	"Pemba South",
	"Pwani",
	"Rukwa",
	"Ruvuma",
	"Shinyanga",
	"Simiyu",
	"Singida",
	"Songwe",
	"Tabora",
	"Tanga",
	"Unguja North",
	"Unguja South",
];

export const jobTypes = ["Full-Time", "Part-Time", "Contractor", "Temporary", "Internship", "Other"];

export const problemsForYou = ["Finding companies who are interested in me", "Finding companies that I want to join"];

export const roleAttributes = [
	"Antonomy",
	"Salary",
	"Mentorship",
	"Equity",
	"Impressive Team Members",
	"Highly Collaborative",
	"Company mission",
	"Opportunities for Growth",
	"To wear many hats",
];

export const companyPreferences = [
	"Small startups",
	"Growing team",
	"Medium Startups",
	"Big Startups",
	"Large companies",
];

export const seniorityLevels = [
	{
		value: "Entry-level(0-1 yr)",
		classIcon: "fa fa-cogs text-primary",
	},
	{
		value: "Junior-level(1-2 yrs)",
		classIcon: "fa fa-cogs text-warning",
	},
	{
		value: "Mid-level(3-5 yrs)",
		classIcon: "fa fa-cogs text-danger",
	},
	{
		value: "Senior-level(6+ yrs)",
		classIcon: "fa fa-cogs text-success",
	},
];

export function jobPreferencesTitles(key) {
	return match(key, {
		locationsPreferred: "Location Preferences",
		currentJobsearchStatus: "Current Job Status",
		waysToHelpme: "Ways To Help",
		primaryResumeReferenceId: "Select Primary Resume",
		openForWorkTypes: "Job Types",
		seniorityLevel: "Seniority Level",
		areasOfExpertise: "Areas Of Expertise",
		locationPreferenceType: "Preferred Work Location",
		default: key,
	});
}
