import React from "react";

import faqs from "../../seed/Faqs";

function Faqs() {
	const questionItem = faqs().map((faq, index) => (
		<div className="col-md-6 mb-4" key={index}>
			<h4 className="text-left font-weight-bold">
				<span className="badge badge-pill badge-primary font-weight-bold">?</span> {faq.question}
			</h4>
			<p className="lead text-left">{faq.answer}</p>
		</div>
	));

	return (
		<section className="choose-your-templates bg-white text-center py-5">
			<div className="container">
				<h1 className="text-center mb-5">Frequently asked questions (FAQ)</h1>
				<div className="row">{questionItem}</div>
			</div>
		</section>
	);
}

export default Faqs;
