const personalLinks = {
	content: [
		{
			bp: false,
			value:
				"Adding website, portfolio, or professional profiles to your CV can help showcase your skills, experience, and accomplishments in a more comprehensive way.",
		},
		{
			bp: true,
			value:
				"Include links that are relevant to your career or field of study. This can include your personal website, LinkedIn profile, online portfolio, or professional social media profiles.",
		},
		{
			bp: true,
			value:
				"Use a clear format for displaying links, such as under a 'Professional Profiles' or 'Online Presence' section. Make sure the links are easy to find and follow.",
		},
		{
			bp: true,
			value:
				"Use the same name or handle across all your professional profiles to maintain consistency and make it easy for employers to find you.",
		},
		{
			bp: true,
			value:
				"Briefly highlight key information on your website or portfolio that relates to your career or field, such as relevant projects, skills, or achievements.",
		},
		{
			bp: true,
			value:
				"Make sure your website, portfolio, and professional profiles are up-to-date with your latest work and accomplishments.",
		},
	],
};

export default personalLinks;
