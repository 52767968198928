import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import sectionRoutes from "../seed/sectionRoutes";
import { useReviewFeedbackInitalState } from "../store/reducers/use-review-feedback-reducers";

const useReviewFeedBack = (reviewData) => {
	const useReviewFeedback = useSelector((state) => state.UseReviewFeedbackReducer);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const feedBackButton = (sectionName, hasFeedback) => {
		if (hasFeedback) {
			return (
				<div
					className="col px-3 px-md-0 d-flex justify-content-end position-absolute review-edit-btn"
					onClick={() => navigate(sectionRoutes(sectionName))}
				>
					<div className="shadow-sm">
						<i className="fa-solid fa-pen-to-square fa-lg text-white" />
					</div>
				</div>
			);
		}

		return (
			<div
				className="col px-3 px-md-0 d-flex justify-content-end position-absolute comment-trigger"
				data-toggle="modal"
				data-target="#reviewFeedbackModal"
				onClick={() => openCommentsModal(sectionName)}
			>
				<div className="shadow-sm">
					<i className="fa-solid fa-pen-to-square fa-lg text-white" />
				</div>
			</div>
		);
	};

	const openCommentsModal = (sectionName) => {
		let _section = { ...useReviewFeedback.section, section: sectionName };

		if (reviewData.length > 0) {
			const foundSection = reviewData.find((item) => item.section === sectionName);
			_section =
				foundSection !== undefined
					? foundSection.review
					: { ...useReviewFeedbackInitalState.section, section: sectionName };
		}

		dispatch({
			type: "USE_REVIEW_FEEDBACK",
			payload: {
				...useReviewFeedback,
				section: _section,
				showFeedBackModal: true,
			},
		});
	};

	return { useReviewFeedback, feedBackButton, openCommentsModal };
};

export default useReviewFeedBack;
