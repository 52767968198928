import React from "react";

function EmbededVideo({ videoLink }) {
	const videoId = videoLink.includes("youtu.be") ? videoLink.split("/").pop() : videoLink.split("?v=").pop();
	return (
		<div className="card card-body bg-primary p-2">
			<iframe
				style={{ width: "100%", aspectRatio: "16/9", border: 0, borderRadius: "5px" }}
				src={`https://www.youtube.com/embed/${videoId}`}
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowfullscreen
			/>
		</div>
	);
}

export default EmbededVideo;
