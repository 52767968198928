import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import CustomRadioField from "../../components/UIComponents/CustomRadioField/CustomRadioField";
import SelectField from "../../components/UIComponents/SelectField";

function ExpertHelpExistingUserUpload() {
	const navigate = useNavigate();

	const [selectedOption, setSelectedOption] = useState("existing"); // new | existing
	const [resumeFile, setResumeFile] = useState(null);

	// TODO:
	// 1. get user resumes and update select field
	// 2. handle form submission

	function onSubmit() {
		// handle form submission

		navigate("/expert-help/upload-resume");
	}

	return (
		<div>
			<h1 className="fs-28 text-gray-950 font-weight-bold">Upload Resume (Optional)</h1>
			<p className="text-gray-700 font-weight-semi mb-5">
				If you have an existing resume, you can upload it here to give our experts a starting point.
			</p>

			<div className="form-group">
				<label htmlFor="">Do you want to update your existing resume on Dproz, or create a brand new one?</label>
				<div className="d-flex">
					<CustomRadioField
						label="Improve existing resume on Dproz"
						checked={selectedOption === "existing"}
						onChange={() => setSelectedOption("existing")}
						name="existing"
						value="existing"
					/>
					<CustomRadioField
						label="Create brand new"
						checked={selectedOption === "new"}
						onChange={() => setSelectedOption("new")}
						name="new"
						value="new"
					/>
				</div>
				{selectedOption === "existing" && (
					<div className="form-group">
						<SelectField
							options={[]}
							value={resumeFile}
							name="resume"
							onChange={(e) => setResumeFile(e.target.value)}
							label="Choose Resume"
						/>
					</div>
				)}
				<button className="btn btn-blue mt-2" onClick={onSubmit}>
					Continue
				</button>
			</div>
		</div>
	);
}

export default ExpertHelpExistingUserUpload;
