import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Icon from "../../components/Icon";
import Spinner from "../../components/Spinner";
import FileUpload from "../../components/UIComponents/FileUpload";
import InputField from "../../components/UIComponents/InputField";
import { isValidURL } from "../../handlers/validationFunctions";

function ExpertHelpUploadResume() {
	const navigate = useNavigate();

	const [resumeFile, setResumeFile] = useState(null);
	const [resumeLink, setResumeLink] = useState("");

	const [error, setError] = useState("");

	function onSubmit(e) {
		e.preventDefault();

		if (!resumeFile && !resumeLink) {
			navigate("/expert-help/pricing");
		}

		if (resumeLink && !isValidURL(resumeLink)) {
			setError("Invalid URL");
		} else {
			navigate("/expert-help/pricing");
		}
	}

	return (
		<div>
			<h1 className="fs-28 text-gray-950 font-weight-bold">Upload Resume (Optional)</h1>
			<p className="text-gray-700 font-weight-semi mb-5">
				If you have an existing resume, you can upload it here to give our experts a starting point.
			</p>

			<form className="container p-0">
				<div className="form-group">
					<label htmlFor="upload">Upload Resume</label>
					<FileUpload onDrop={setResumeFile} accept={[".pdf", ".doc", ".docx"]}>
						<div className="d-flex justify-content-center align-items-center">
							<div className="">
								<div className="d-flex justify-content-center align-items-center">
									<div
										className="circle bg-primary-50 mb-3"
										style={{
											height: 56,
											width: 56,
										}}
									/>
								</div>
								<p className="text-center text-gray-950 font-weight-medium lh-1-5 mb-3">
									Drag and drop <br />
									or click to browse files
								</p>
								<p className="text-gray-400 fs-12">Accepted file formats: PDF, DOC, DOCX. Maximum file size: 5MB.</p>
							</div>
						</div>
					</FileUpload>
					{error.resumeFile && <div className="error">{error.resumeFile}</div>}

					{resumeFile && (
						<div className="d-flex text-gray-700 font-weight-medium align-items-center mt-2">
							<Icon iconName="document" size={16} className="mr-2" /> {resumeFile[0].name}
							<span className="ml-3">
								<Spinner />
							</span>
						</div>
					)}
					<div className="text-center my-5">
						<p className="text-gray-500 fs-16 font-weight medium">OR</p>
					</div>
					<div className="">
						<p className="font-weight-semi fs-14 text-gray-950 mb-1">Share resume link</p>
						<p className="fs-14 text-gray-800">
							Provide a link to your resume using Google Drive, Dropbox, or similar platforms.
						</p>
						<div className="d-flex mt-4 mb-3 justify-content-center">
							<div className="bg-gray-50 rounded-sm py-2 px-4 mr-3">
								<Icon size={30} iconName="google-drive" />
							</div>
							<div className="bg-gray-50 rounded-sm py-2 px-4 mr-3">
								<Icon size={30} iconName="dropbox-logo" />
							</div>
							<div className="bg-gray-50 rounded-sm py-2 px-4 mr-3">
								<Icon size={30} iconName="box-logo" />
							</div>
						</div>
						<div className="form-group">
							<InputField
								placeholder="Add link..."
								iconLeft="link"
								name="resumeLink"
								onChange={(e) => {
									setResumeLink(e.target.value);
									setError("");
								}}
								value={resumeLink}
								error={error}
							/>
						</div>
						<p className="fs-12 mt-2 mb-3 font-weight-medium text-gray-800">
							Ensure the link is accessible to our experts.
						</p>
						<button className="btn btn-blue" onClick={onSubmit}>
							{!resumeFile && !resumeLink ? "Skip" : "Continue"}
						</button>
					</div>
				</div>
			</form>
		</div>
	);
}

export default ExpertHelpUploadResume;
