import React from "react";

function TemplatePremiumFive() {
	return (
		<div style={{ scale: "1.2", transformOrigin: "center" }}>
			<div className="small-resume-preview small-resume-preview-premium-template-5">
				<div className="final-resume-preview bg-white resume-type-1 text-dark">
					<div className="container" style={{ maxWidth: "1000px" }} id="printResume">
						<div className="d-flex justify-content-between align-items-center mt-1">
							<div className="info-wrapper" style={{ flex: "0 0 50%" }}>
								<h1 className="mb-0" style={{ color: "#003366" }}>
									JACQUILINE KHALFAN
								</h1>
								<p className="mb-2" style={{ color: "#9b9b9b", fontWeight: 500 }}>
									Business Development Manager
								</p>
								<p className="mb-2">
									Professional Business Developer with four year of experience in the business Development processes.
									Involved in product testing, management and development of new business opportunities.
								</p>
							</div>
							<div>
								<p className="mb-2 mt-4 text-right">
									jacquiline.khalfan@dproz.tz
									<i className="ml-2 fa fa-envelope" aria-hidden="true" style={{ color: "#003366" }} />
								</p>
								<p className="mb-2 text-right">
									202-555-0166
									<i className="ml-2 fa fa-mobile" aria-hidden="true" />
								</p>
								<p className="mb-2 text-right">
									New York, USA
									<i className="ml-2 fa fa-map-marker" aria-hidden="true" />
								</p>
								<p className="mb-2 text-right">
									linkedin.com/in/jacquiline.khalfan
									<i className="ml-2 fa fa-linkedin" aria-hidden="true" />
								</p>
								<p className="mb-2 text-right">
									jacquiline.khalfan
									<i className="ml-2 fa fa-skype" aria-hidden="true" />
								</p>
							</div>
						</div>

						<div className="mb-2">
							<h4
								className="text-uppercase text-center py-1 my-1 font-weight-bold"
								style={{
									borderTop: "1px solid #313131",
									color: "#003366",
									borderBottom: "1px solid #313131",
								}}
							>
								skills
							</h4>
							<ul className="text-white mt-1 mb-2 pl-0">
								<li
									className="rounded-lg px-2 py-1 d-inline-block font-weight-medium mb-1"
									style={{ backgroundColor: "#003366" }}
								>
									SEO
								</li>
								<li
									className="rounded-lg px-2 py-1 d-inline-block font-weight-medium mb-1"
									style={{ backgroundColor: "#003366" }}
								>
									Public Speaking
								</li>
								<li
									className="rounded-lg px-2 py-1 d-inline-block font-weight-medium mb-1"
									style={{ backgroundColor: "#003366" }}
								>
									Negotiation
								</li>
								<li
									className="rounded-lg px-2 py-1 d-inline-block font-weight-medium mb-1"
									style={{ backgroundColor: "#003366" }}
								>
									Teamwork
								</li>
								<li
									className="rounded-lg px-2 py-1 d-inline-block font-weight-medium mb-1"
									style={{ backgroundColor: "#003366" }}
								>
									Decission Making
								</li>
								<li
									className="rounded-lg px-2 py-1 d-inline-block font-weight-medium mb-1"
									style={{ backgroundColor: "#003366" }}
								>
									Research and Strategy
								</li>
								<li
									className="rounded-lg px-2 py-1 d-inline-block font-weight-medium mb-1"
									style={{ backgroundColor: "#003366" }}
								>
									Emotional Intelligence
								</li>
								<li
									className="rounded-lg px-2 py-1 d-inline-block font-weight-medium mb-1"
									style={{ backgroundColor: "#003366" }}
								>
									Outbound Marketingn
								</li>
								<li
									className="rounded-lg px-2 py-1 d-inline-block font-weight-medium mb-1"
									style={{ backgroundColor: "#003366" }}
								>
									Email Marketing
								</li>
								<li
									className="rounded-lg px-2 py-1 d-inline-block font-weight-medium mb-1"
									style={{ backgroundColor: "#003366" }}
								>
									Google Analytics
								</li>
								<li
									className="rounded-lg px-2 py-1 d-inline-block font-weight-medium mb-1"
									style={{ backgroundColor: "#003366" }}
								>
									Sales &amp; Marketing
								</li>
								<li
									className="rounded-lg px-2 py-1 d-inline-block font-weight-medium mb-1"
									style={{ backgroundColor: "#003366" }}
								>
									Social Media Advertising
								</li>
							</ul>
						</div>

						<div className="mb-2">
							<h4
								className="text-uppercase text-center py-1 my-2 font-weight-bold"
								style={{
									borderTop: "1px solid #313131",
									color: "#003366",
									borderBottom: "1px solid #313131",
								}}
							>
								WORK EXPERIENCE
							</h4>
							<h5 className="font-weight-bold mb-0">Business Development Manager</h5>
							<p className="mb-0" style={{ fontWeight: 500 }}>
								AirState Solutions
								<i className="fa fa-share-square-o ml-2" aria-hidden="true" style={{ color: "#003366" }} />
							</p>
							<div
								className="d-flex align-items-center justify-content-between font-italic"
								style={{ color: "#7f7f7f" }}
							>
								<p className="mb-2">09/2014 - 06/2017</p>
								<p className="mb-2">New York, USA</p>
							</div>
							<ul className="p-0" style={{ listStyle: "none" }}>
								<li className="d-flex">
									<span style={{ fontWeight: 500, width: "20px" }}>-</span>
									Successfully managed $2 - 3 million budget projects and successfully achieved the project scheduled
									goals.
								</li>
								<li className="d-flex">
									<span style={{ fontWeight: 500, width: "20px" }}>-</span>
									Developed and implemented new marketing and sales plans and defined the strategy for the next 5 years.
								</li>
							</ul>

							<h5 className="font-weight-bold mb-0">Business Development Assistant</h5>
							<p className="mb-0" style={{ fontWeight: 500 }}>
								AirState Solutions
								<i className="fa fa-share-square-o ml-2" aria-hidden="true" style={{ color: "#003366" }} />
							</p>
							<div
								className="d-flex align-items-center justify-content-between font-italic"
								style={{ color: "#7f7f7f" }}
							>
								<p className="mb-2">08/2012 - 09/2014</p>
								<p className="mb-2">Chicago, USA</p>
							</div>
							<ul className="p-0" style={{ listStyle: "none" }}>
								<li className="d-flex">
									<span style={{ fontWeight: 500, width: "20px" }}>-</span>
									Increased the customer satisfaction rate by 25% by improving the customer service.
								</li>
								<li className="d-flex">
									<span style={{ fontWeight: 500, width: "20px" }}>-</span>
									Planned, supervised, and coordinated daily activity of 3 junior business analysts.
								</li>
							</ul>
						</div>

						<div className="mb-2">
							<h4
								className="text-uppercase text-center py-1 my-2 font-weight-bold"
								style={{
									borderTop: "1px solid #313131",
									color: "#003366",
									borderBottom: "1px solid #313131",
								}}
							>
								EDUCATION
							</h4>
							<p className="font-weight-bold mt-2 mb-0">MSc in Economics and Business Administration</p>
							<p className="font-weight-500 mb-0" style={{ fontWeight: 500 }}>
								The University of Chicago
							</p>
							<p className="font-italic" style={{ color: "#7f7f7f" }}>
								09/2008 - 06/2010
							</p>
						</div>

						<div className="mb-2">
							<h4
								className="text-uppercase text-center py-1 my-2 font-weight-bold"
								style={{
									borderTop: "1px solid #313131",
									color: "#003366",
									borderBottom: "1px solid #313131",
								}}
							>
								ORGANIZATIONS
							</h4>

							<div className="row">
								<div className="col-6">
									<p className="mb-0">American Management Association</p>
									<p className="mb-2">(2015 - Present)</p>
								</div>
								<div className="col-6">
									<p className="mb-0">Association of Private Enterprise Education</p>
									<p className="mb-2">(2014 - Present)</p>
								</div>
								<div className="col-6">
									<p className="mb-0">eBusiness Association (eBA)</p>
									<p className="mb-2">(2013 - Present)</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TemplatePremiumFive;
