import React from "react";
import { useDropzone } from "react-dropzone";

function FileUpload({ children, onDrop, disabled, accept, multiple = false }) {
	const { getRootProps, getInputProps, ...dropzoneProps } = useDropzone({
		onDrop,
		accept,
	});

	const containerProps = React.useMemo(() => getRootProps(), [getRootProps]);

	const inputProps = React.useMemo(() => getInputProps({ multiple }), [getInputProps, multiple]);

	return (
		<div {...containerProps}>
			<input {...inputProps} disabled={disabled} />
			<div {...dropzoneProps} className="drop-file-upload">
				{children}
			</div>
		</div>
	);
}

export default FileUpload;
