import React from "react";

import EducationItem from "./EducationScoreItem";
import ExperienceScoreItem from "./ExperienceScoreItem";
import SkillScoreItem from "./SkillScoreItem";

function CandidateFitnessItem({ data, type }) {
	return (
		<div className="bottom m-3">
			<div className="accordion reviewed-section-accordion" id="ScoreSection">
				<div className="card accordion-modal-card">
					<div className="card-header" id={`${type}Heading`}>
						<h5
							className="accordion-header collapsed text-capitalize"
							data-toggle="collapse"
							data-target={`#${type}Section`}
							aria-expanded="true"
							aria-controls={`${type}Section`}
						>
							{type} Score -
							<span
								className={`${
									data.match_pct > 80 ? "text-success" : data.match_pct < 50 ? "text-danger" : "text-warning"
								} fw-bold`}
							>
								{` ${data.match_pct}`}
							</span>
							<span className="h5">/100</span>
						</h5>
					</div>
					<div
						id={`${type}Section`}
						className="collapse pl-3 pt-3 pr-3 collapsed-content"
						aria-labelledby={`${type}Heading`}
						data-parent="#ScoreSection"
					>
						<ul className="list-unstyled">
							{/* TODO: Convert these list items into a component
								and use the fit status as a prop to determine
								the color of the badge */}
							{type === "skills" && <SkillScoreItem data={data} />}
							{type === "experience" && <ExperienceScoreItem data={data} />}
							{type === "education" && <EducationItem data={data} />}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CandidateFitnessItem;
