import React from "react";

import Header from "../components/Header";

function EmployerLandingPage() {
	return (
		<div>
			<Header />
			<section className="custom-banner full-height">
				<div className="container">
					<div className="row align-items-center">
						<div className="banner-content">
							<h1 className="text-white">Employers: Coming soon…</h1>
							<p className="text-white text-larger mb-3">
								Our mission is to connect professionals with the right opportunities and to build a more inclusive
								job-market in Tanzania.
							</p>
							<p className="text-white text-larger mb-3">
								We help job-seekers prepare and confidently communicate strengths, uniqueness, and value they can bring
								to the marketplace.
							</p>
							<p className="text-white text-larger mb-3">
								We streamline the hiring process for employers to increase efficiency, expand reach, and improve the
								quality of hire at a fraction of time and cost.
							</p>
							<p className="text-white text-larger mb-3">
								And to do that we would love to gain insights from your first hand hiring experience, filling open
								position(s) at your company to help us better understand your pain points to inform how we can add value
								for You.
							</p>
							<p className="text-white text-larger mb-3">
								Complete Survey to reserve your spot in the coming employer-side alpha{" "}
							</p>
							<a
								href="https://forms.gle/Cop63Ny1ByVSrEYp8"
								className="btn btn-primary btn-lg rounded-pill"
								target="_blank"
								rel="noreferrer"
							>
								Take Survey
							</a>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default EmployerLandingPage;
