import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import CloseButton from "../../components/CloseButton";

function ResumeCreationOptions() {
	const navigate = useNavigate();

	const containerRef = React.useRef(null);

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.style.height = window.innerHeight - containerRef.current.offsetTop + "px";
		}
	}, []);

	return (
		<section className="p-2">
			<CloseButton action={() => navigate("/")} />
			<div className="d-flex align-items-center justify-content-center flex-column" ref={containerRef}>
				<div>
					<p className="font-weight-semi text-gray-700 fs-16 mb-2">Welcome aboard!</p>
					<h1 className="font-weight-bold fs-28 text-gray-950">How do you want to start?</h1>
					<div className="d-flex mt-4">
						<Link to="/resume/select-experience" className="border-light rounded onboarding-card mr-5">
							<div className="card-img bg-light-blue" />
							<div className="p-3">
								<h4 className="text-gray-950 fs-22 font-weight-bold">Create Resume Yourself</h4>
								<p className="fs-16 text-gray-700">Build your resume from scratch using the Dproz interface.</p>
							</div>
						</Link>
						<Link to="/expert-help/login" className="border-light rounded onboarding-card">
							<div className="card-img bg-light-pink" />
							<div className="p-3">
								<h4 className="text-gray-950 fs-22 font-weight-bold">Seek Expert Help</h4>
								<p className="fs-16 text-gray-700">
									Our professional resume writers can help you take your resume to the next level.
								</p>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ResumeCreationOptions;
