import "crypto-js/lib-typedarrays";

import { GoogleLogin } from "@react-oauth/google";
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { decodeToken } from "react-jwt";
import { Link, useLocation, useNavigate } from "react-router-dom";

import poolData from "../../../../userpool";
import ErrorToast from "../../../components/ErrorToast";
import Spinner from "../../../components/Spinner";
import InputField from "../../../components/UIComponents/InputField/";
import AuthContext from "../../../context/Auth/AuthContext";
import { StaticValue } from "../../../seed/constants";

const userPool = new CognitoUserPool(poolData);
const staticValue = new StaticValue();
const staticValues = staticValue.getDeafaulValues();

const HOME_PAGES = ["/", "/signin"];
const RESEME_CENTER = "/my-resumes";

const referrer = document.referrer ? new URL(document.referrer) : null;
const goToOptions = referrer ? `${referrer.pathname}${referrer.search}` : RESEME_CENTER;
const goTo = HOME_PAGES.includes(goToOptions) ? RESEME_CENTER : goToOptions;

const referer = new URLSearchParams(window.location.search).get("ref");
const redirectTo =
	(referer && referer.includes("expert-help")) || window.location.pathname.includes("expert-help")
		? "/expert-help/personal-info"
		: goTo;

function SigninForm() {
	const [passwordVisible, setPasswordVisible] = useState(false);

	const togglePasswordVisibility = (newVisibility) => {
		setPasswordVisible(newVisibility);
	};

	const checkboxRef = React.createRef();

	const navigate = useNavigate();
	const location = useLocation();
	const [authData, setAuthData] = useState({
		email: "",
		password: "",
		remember_me: false,
	});

	useEffect(() => {
		const remember_me = JSON.parse(localStorage.getItem("remember_me"));

		if (remember_me) {
			setAuthData({
				...authData,
				email: remember_me.email,
				remember_me: remember_me.remember_me,
			});
		}
	}, []);

	const { email, password, remember_me } = authData;
	const [_, setCookie, removeCookie] = useCookies(["Authorization"]);
	const [error, setError] = useState({ email: "", password: "", serverErrors: "" });

	const { setUserData } = useContext(AuthContext);

	const [isLoggedIn, setIsLoggedIn] = useState(false);

	if (!isLoggedIn) {
		localStorage.removeItem("resumeList");
		localStorage.removeItem("reviewsList");
		localStorage.removeItem("profileData");
		localStorage.removeItem("orderID");
		localStorage.removeItem("isSubscribed");
		localStorage.removeItem("setLastUpdatedResume");
		removeCookie("Authorization");
		removeCookie(staticValues.localStorageResumeId);
	}

	function onChange(event) {
		setAuthData({
			...authData,
			[event.target.name]: event.target.value,
		});
	}
	const [loading, setLoading] = useState(false);
	useEffect(() => {}, [loading]);

	function handleSubmit(event) {
		event.preventDefault();
		setError({
			email: "",
			password: "",
			serverErrors: "",
		});
		setLoading(true);

		if (email.length === 0) {
			setError({ ...error, email: "Email is required" });
			setLoading(false);
			return;
		}

		if (password.length === 0) {
			setError({ ...error, password: "Password is required" });
			setLoading(false);
			return;
		}

		if (remember_me) {
			localStorage.setItem("remember_me", JSON.stringify({ email, remember_me }));
		} else {
			localStorage.removeItem("remember_me");
		}

		let authenticationData = {
			Username: email,
			Password: password,
		};

		let authenticationDetails = new AuthenticationDetails(authenticationData);

		let userData = {
			Username: email,
			Pool: userPool,
		};

		let cognitoUser = new CognitoUser(userData);

		cognitoUser.authenticateUser(authenticationDetails, {
			onSuccess: function (result) {
				let idToken = `CO::${result.getIdToken().getJwtToken()}`;
				const expireDate = new Date();
				expireDate.setDate(expireDate.getDate() + 30);
				setCookie("Authorization", idToken, {
					path: "/",
					expires: expireDate,
				});

				setIsLoggedIn(true);

				let cognitoUser = userPool.getCurrentUser();

				if (cognitoUser != null) {
					cognitoUser.getSession(function (err, result) {
						if (result) {
							removeCookie(staticValues.localStorageResumeId);
							localStorage.removeItem("resumeList");
							setLoading(false);
							const decodedToken = decodeToken(result.getIdToken().getJwtToken());

							const data = {
								email: decodedToken.email,
								lastName: decodedToken.family_name,
								firstName: decodedToken.given_name,
								picture: decodedToken.picture,
							};

							localStorage.setItem("profileData", JSON.stringify(data));
							setUserData(data);

							navigate(redirectTo);
						}
					});
				}
			},
			onFailure: function (err) {
				if (err.code === "NotAuthorizedException") {
					setError({ ...error, serverErrors: "Incorrect username or password" });
				} else {
					setError({ ...error, serverErrors: "Unable to sign in, Check your email to activate your account." });
				}
				setLoading(false);
			},
		});
	}

	useEffect(() => {}, [error]);

	function onGoogleSignIn(googleUser) {
		if (googleUser.credential) {
			const credential = `GO::${googleUser.credential}`;

			const expireDate = new Date();
			expireDate.setDate(expireDate.getDate() + 30);
			setCookie("Authorization", credential, {
				path: "/",
				expires: expireDate,
			});

			setIsLoggedIn(true);

			removeCookie(staticValues.localStorageResumeId);
			localStorage.removeItem("resumeList");
			setLoading(false);
			const decodedToken = decodeToken(googleUser.credential);

			const data = {
				email: decodedToken.email,
				firstName: decodedToken.given_name,
				lastName: decodedToken.family_name,
				picture: decodedToken.picture,
			};

			localStorage.setItem("profileData", JSON.stringify(data));
			setUserData(data);

			navigate(redirectTo);
		}
	}

	const [showEmailOptions, setShowEmailOptions] = useState(false);

	const buttonRef = useRef();

	const buttonWidth = useMemo(() => {
		if (buttonRef.current) {
			return buttonRef.current.offsetWidth;
		}
		return 0;
	}, [buttonRef.current]);

	return (
		<>
			<div className="mb-4" ref={buttonRef}>
				<div className="py-2 mx-auto flex items-center justify-center">
					<GoogleLogin
						onSuccess={onGoogleSignIn}
						onFailure={() => toast.error("Login Failed")}
						size="large"
						width={`${buttonWidth}px`}
						text="continue_with"
						useOneTap
					/>
				</div>
			</div>
			<div className="mb-3 text-center">
				<p className="text-medium font-weight-medium">OR</p>
			</div>
			<div className="">
				{error.serverErrors !== "" && <ErrorToast error={error.serverErrors} />}
				{location.state !== null && (
					<div className="">
						<div className="alert alert-success text-left mb-2" role="alert">
							<p>{location.state.message}</p>
						</div>
						<div className="alert text-left alert-secondary">
							<p>
								If you haven&#39;t received the email? Make sure to check your spam folder, sometimes there&#39;s a
								slight delay, you&#39;ll receive the email.
							</p>
							<p>
								For the time being, you can continue to build your resume by clicking this link.{" "}
								<Link to="/my-resumes">Continue building your resume</Link>
							</p>
						</div>
					</div>
				)}
			</div>
			{showEmailOptions ? (
				<form onSubmit={handleSubmit} className="tr-form">
					<div className="form-group text-left">
						<InputField
							type="email"
							className="form-control"
							placeholder="Enter Email"
							value={email}
							onChange={onChange}
							name="email"
							error={error.email}
							label="Email"
							required
						/>
					</div>
					<div className="form-group text-left">
						<InputField
							type="password"
							className="form-control"
							placeholder="Password"
							onChange={onChange}
							value={password}
							disableCopyPaste={true}
							label="Password"
							name="password"
							error={error.password}
							required
							initialPasswordVisibilityStatus={passwordVisible}
							onClick={togglePasswordVisibility}
						/>
					</div>
					<div className="user-option d-flex justify-content-between">
						<label className="form-check d-flex align-items-center p-0" htmlFor="legalNameToSAcknowledged">
							<input
								type="checkbox"
								ref={checkboxRef}
								name="remember_me"
								value={remember_me}
								checked={remember_me}
								onChange={onChange}
							/>
							<span
								className="checkmark"
								onClick={() => {
									checkboxRef.current.click();
								}}
							/>
							<div className="ml-4 pl-2">
								<p className="fs-14 font-weight-medium m-0 text-black">Remember me</p>
							</div>
						</label>
						<div className="forgot-password">
							<Link to="/forgot-password" className="font-weight-medium">
								I forgot password
							</Link>
						</div>
					</div>

					<button className="btn btn-primary w-100" onClick={handleSubmit}>
						{loading ? <Spinner /> : "Login"}
					</button>
				</form>
			) : (
				<>
					<button className="w-full border font-semibold btn text-dark" onClick={() => setShowEmailOptions(true)}>
						Continue with Email
					</button>
				</>
			)}
			<div className="new-user mt-4">
				<span>
					Can&#39;t log in?{" "}
					<Link to={"/register?ref=" + window.location.pathname} className="font-weight-semi">
						Create a New Account
					</Link>
				</span>
			</div>
		</>
	);
}

export default SigninForm;
