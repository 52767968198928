import React, { useEffect, useState } from "react";

import Loader from "../../../components/Loader";
import { base64ToAscii } from "../../../handlers/valueTransformation";
import { ResumeDataService } from "../../../services/create-resume/resume-data";

function PaymentComplete({ orderID, loading, setLoading, orderData, setOrderData, setCurrentStep }) {
	const resumeDataService = new ResumeDataService();

	const [paymentStatus, setPaymentStatus] = useState(""); // CANCELED, PENDING or COMPLETED

	function onSuccess(response) {
		setLoading(false);
		const { paymentStatus, paymentLink, _id } = response.data;

		setOrderData({
			orderID: _id,
			paymentLink,
		});

		if (paymentStatus === "COMPLETED") {
			setCurrentStep(5);

			return setPaymentStatus(paymentStatus);
		}
		if (paymentStatus === "PENDING" || paymentStatus === "INPROGRESS") {
			return setPaymentStatus("PENDING");
		}
		if (paymentStatus === "CANCELLED" || paymentStatus === "REJECTED" || paymentStatus === "USERCANCELLED") {
			return setPaymentStatus("CANCELLED");
		}
	}

	//localStorage.removeItem("orderID");
	//TODO: Remove orderID from localStorage when payment is completed

	const [errors, setErrors] = useState([]);

	function onError(error) {
		setLoading(false);
		setErrors([error.message]);
	}

	async function getPaymentStatus() {
		setLoading(true);
		await resumeDataService.getOrderStatus(orderID, onSuccess, onError);
	}

	useEffect(() => {
		getPaymentStatus();
	}, []);

	return (
		<div>
			{loading ? (
				<div className="container">
					<div className="text-center">
						<Loader />
						<p>Please wait while we are verifying your payment</p>
					</div>
				</div>
			) : (
				<>
					{errors.length > 0 ? (
						<>
							<div className="container">
								<div className="text-center">
									<div className="display-1 mb-3">
										<i className="fa-solid fa-circle-x text-danger" />
									</div>
									<div className="alert alert-danger">
										{errors.map((error, index) => (
											<li key={index}>{error}</li>
										))}
									</div>
									<div className="text-right">
										<button
											className="btn btn-primary"
											onClick={() => {
												localStorage.removeItem("orderID");
												window.location.href = "/";
											}}
										>
											Go to Home
										</button>
									</div>
								</div>
							</div>
						</>
					) : (
						<div className="container">
							{paymentStatus === "COMPLETED" && (
								<div className="text-center">
									<div
										className=""
										style={{
											height: "300px",
											paddingTop: "50px",
										}}
									>
										<div className="display-1 mb-3">
											<i className="fa-solid fa-circle-check text-success" />
										</div>
										<p>Your payment has been successfully completed.</p>
									</div>
									<div className="text-right">
										<button
											className="btn btn-primary"
											onClick={() => {
												localStorage.removeItem("orderID");
												window.location.href = "/my-resumes";
											}}
										>
											Go to My Resumes
										</button>
									</div>
								</div>
							)}
							{paymentStatus === "PENDING" && (
								<div className="text-center">
									<div
										className=""
										style={{
											height: "300px",
											paddingTop: "50px",
										}}
									>
										<div className="display-1 mb-3">
											<i className="fa-solid fa-circle-exclamation text-warning" />
										</div>
										<p>Your payment is pending. Please complete your payment to access all features</p>
									</div>
									<div className="text-right">
										<a
											className="btn btn-primary"
											href={base64ToAscii(orderData.paymentLink)}
											target="_blank"
											rel="noopener noreferrer"
										>
											Make Payment
										</a>
									</div>
								</div>
							)}
							{paymentStatus === "CANCELLED" && (
								<div className="text-center">
									<div
										className=""
										style={{
											height: "300px",
											paddingTop: "50px",
										}}
									>
										<div className="display-1 mb-3">
											<i className="fa-solid fa-circle-xmark text-danger" />
										</div>
										<p>Your payment has been canceled. Please complete your payment to access all features</p>
									</div>
									<div className="text-right">
										<button
											className="btn btn-secondary mr-2"
											onClick={() => {
												localStorage.removeItem("orderID");
												window.location.href = "/my-resumes";
											}}
										>
											Cancel
										</button>
										<button
											className="btn btn-primary"
											onClick={() => {
												localStorage.removeItem("orderID");
												window.location.href = "/payments";
											}}
										>
											Make Payment
										</button>
									</div>
								</div>
							)}
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default PaymentComplete;
