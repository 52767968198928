import React from "react";

function OrderTips({ tipsIntroduction, tipsTitle, tips }) {
	return (
		<div
			className="d-flex flex-column align-items-start"
			style={{ padding: "16px", gap: "8px", background: "#F0F7FF", borderRadius: "8px", width: "100%" }}
		>
			<div>
				{tipsIntroduction && (
					<>
						<div className="expert-review-footer-text" style={{ color: "#3E424E" }}>
							{tipsIntroduction}
						</div>
						<br />
					</>
				)}
				{tipsTitle && (
					<div className="expert-review-orders-header-title" style={{ color: "#3E424E" }}>
						{tipsTitle}
					</div>
				)}
			</div>
			{tips &&
				tips.map((item) => {
					return (
						<div key={item} className="d-flex align-items-center p-0" style={{ gap: "8px" }}>
							<i className="bi bi-check" style={{ fontSize: "20px" }} />
							<div>{item}</div>
						</div>
					);
				})}
		</div>
	);
}

export default OrderTips;
