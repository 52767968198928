import React from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ExpertResumesLayout from "../../components/ExpertResumesLayout/ExpertResumesLayout";
import Loader from "../../components/Loader";
import CircularProgressWithLabel from "../../components/Rewards/CircularProgressWithLabel";
import ReferFriendModal from "../../components/Rewards/ReferFriendModal";
import RewardsTableBody from "../../components/Rewards/RewardsTableBody";
import ShareOpportunityModal from "../../components/Rewards/ShareOpportunityModal";
import EllipsisPagination from "../../components/UIComponents/EllipsisPagination";
import { getMyResumesRoutes } from "../../handlers/navigationItems";
import { capitalizeFirstLetterOfWords, currencyFormat, getRewardPointTextColor } from "../../handlers/utilityFunctions";
import { ResumeDataService } from "../../services/create-resume/resume-data";

function Rewards() {
	const apiService = new ResumeDataService();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showReferralFriendDialog, setShowReferralFriendDialog] = React.useState(false);
	const [showShareOpportunityDialog, setShowShareOpportunityDialog] = React.useState(false);
	const [rewards, setRewards] = React.useState([]);
	const [points, setPoints] = React.useState({
		totalRewards: 0,
		totalAvailable: 0,
		totalPending: 0,
		totalRedeemed: 0,
	});
	const [loading, setLoading] = React.useState(true);
	const [submitting, setSubmitting] = React.useState(false);
	const [errors, setErrors] = React.useState("");
	const [success, setSuccess] = React.useState("");
	const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [searchTerm, setSearchTerm] = React.useState("");
	const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
	const baseUrl = window.location.protocol + "//" + window.location.host + "/resume/select-experience";
	const [percentageFilter, setPercentageFilter] = React.useState(0);
	const [rewardStatus, setRewardStatus] = React.useState({
		PENDING: false,
		CLAIMED: false,
		REDEEMED: false,
	});
	const [activityType, setActivityType] = React.useState({
		REFERRAL: false,
		["RESUME COMPLETION"]: false,
		["JOB LEAD"]: false,
	});

	const shareString = (item) => {
		return `Unlock 300 rewards points! that will be shared between us complete your resume current at [${item?.resumeCompletionPct}%] on Dproz by visit ${baseUrl}`;
	};

	React.useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [screenWidth]);

	const handleFilter = (item) => {
		const isSearching =
			Object.values(item).some((value) => value?.toString()?.toLowerCase()?.includes(searchTerm?.toLowerCase())) &&
			item?.resumeCompletionPct >= percentageFilter;
		const isPending = item?.rewardStatus === "PENDING";
		const isClaimed = item?.rewardStatus === "CLAIMED";
		const isRedeemed = item?.rewardStatus === "REDEEMED";
		const isReferral = item?.activityType === "REFERRAL";
		const isResumeCompletion = item?.activityType === "RESUME_COMPLETION";
		const isJobLead = item?.activityType === "JOB_LEAD";

		return rewardStatus.PENDING && rewardStatus.CLAIMED && rewardStatus.REDEEMED
			? (isSearching && isPending) || isClaimed || isRedeemed
			: rewardStatus.CLAIMED && rewardStatus.REDEEMED
				? (isSearching && isClaimed) || isRedeemed
				: rewardStatus.CLAIMED && rewardStatus.PENDING
					? (isSearching && isClaimed) || isPending
					: rewardStatus.REDEEMED && rewardStatus.PENDING
						? (isSearching && isRedeemed) || isPending
						: rewardStatus.CLAIMED
							? isSearching && isClaimed
							: rewardStatus.REDEEMED
								? isSearching && isRedeemed
								: rewardStatus.PENDING
									? isSearching && isPending
									: activityType.REFERRAL && activityType["RESUME COMPLETION"] && activityType["JOB LEAD"]
										? (isSearching && isReferral) || isResumeCompletion || isJobLead
										: activityType.REFERRAL && activityType["RESUME COMPLETION"]
											? (isSearching && isReferral) || isResumeCompletion
											: activityType.REFERRAL && activityType["JOB LEAD"]
												? (isSearching && isReferral) || isJobLead
												: activityType["RESUME COMPLETION"] && activityType["JOB LEAD"]
													? (isSearching && isResumeCompletion) || isJobLead
													: activityType.REFERRAL
														? isSearching && isReferral
														: activityType["RESUME COMPLETION"]
															? isSearching && isResumeCompletion
															: activityType["JOB LEAD"]
																? isSearching && isJobLead
																: isSearching;
	};
	const totalPages = Math.ceil(rewards?.filter(handleFilter)?.length / rowsPerPage);

	const handlePageChange = (pageNumber) => {
		setPage(pageNumber);
	};

	const handleCloseReferralFriendDialog = () => {
		setShowReferralFriendDialog(false);
	};

	const handleCloseShareOpportunityDialog = () => {
		setShowShareOpportunityDialog(false);
	};

	const getPaymentsRewards = () => {
		apiService.getPaymentsRewards(
			(data) => {
				const newArray = data.data.map((obj) => ({
					...obj,
				}));
				setRewards(newArray);
				setLoading(false);
			},
			(e) => {
				const error = e.response.data.message;
				setErrors(error);
				toast.error("Something went wrong!");
				setLoading(false);
			},
		);
	};

	const claimPaymentReward = (paymentRewardID) => {
		setSubmitting(true);
		setErrors("");
		setSuccess("");
		apiService.claimPaymentReward(
			paymentRewardID,
			(data) => {
				getPaymentsRewards();
				setSuccess(
					"Congratulations! You've earned " +
						data?.data?.rewardPoints +
						" extra points, ready to unlock premium features on Dproz",
				);
				setSubmitting(false);
			},
			(e) => {
				const error = e.response.data.message;
				setErrors(error);
				toast.error("Something went wrong!");
				setSubmitting(false);
			},
		);
	};

	React.useEffect(() => {
		getPaymentsRewards();
	}, []);

	React.useEffect(() => {
		let newPoints = {
			totalRewards: 0,
			totalAvailable: 0,
			totalPending: 0,
			totalRedeemed: 0,
		};
		rewards.forEach((item) => {
			if (item.rewardStatus === "CLAIMED") {
				newPoints.totalAvailable = newPoints.totalAvailable + item.rewardPoints;
			}
			if (item.rewardStatus === "PENDING") {
				newPoints.totalPending = newPoints.totalPending + item.rewardPoints;
			}
			if (item.rewardStatus === "REDEEMED") {
				newPoints.totalRedeemed = newPoints.totalRedeemed + item.rewardPoints;
			}
		});
		newPoints.totalRewards = newPoints.totalAvailable + newPoints.totalPending + newPoints.totalRedeemed;
		setPoints(newPoints);
	}, [rewards]);

	return (
		<ExpertResumesLayout
			navItems={getMyResumesRoutes(dispatch)}
			header="My Resumes"
			headingTitle="Rewards"
			showExpertDetails
		>
			{showReferralFriendDialog && (
				<ReferFriendModal
					showReferralFriendDialog={showReferralFriendDialog}
					handleCloseReferralFriendDialog={handleCloseReferralFriendDialog}
					getPaymentsRewards={getPaymentsRewards}
					setSuccess={setSuccess}
				/>
			)}
			{showShareOpportunityDialog && (
				<ShareOpportunityModal
					showShareOpportunityDialog={showShareOpportunityDialog}
					handleCloseShareOpportunityDialog={handleCloseShareOpportunityDialog}
					rewards={rewards}
					setRewards={setRewards}
					setSuccess={setSuccess}
				/>
			)}
			<div className="container my-5">
				{loading ? (
					<Loader />
				) : (
					<>
						<div className="d-flex flex-wrap align-items-center mb-3">
							<button
								style={{ padding: "8px 20px" }}
								className="btn btn-success rounded mr-3 mb-2"
								onClick={() => setShowReferralFriendDialog(true)}
							>
								<span className="bi bi-person-add mr-2" />
								REFER FRIEND
							</button>
							<button
								className="btn btn-primary rounded mr-auto mb-2"
								onClick={() => setShowShareOpportunityDialog(true)}
							>
								<span className="bi bi-share mr-2" />
								Share Opportunity
							</button>
							<p className="h4 mb-2">
								<span className="bi bi-box2-heart text-primary mr-2" />
								Total Rewards: <strong>{currencyFormat(points.totalRewards)}</strong>
							</p>
						</div>
						<div className="d-flex flex-wrap align-items-center mb-3">
							{rewards.length > 0 && (
								<div className="d-flex border rounded mr-auto  mb-2 order-lg-1 order-2">
									<i className="fas fa-search my-auto p-3" />
									<input
										type="text"
										className="form-control border-white"
										id="search"
										autoComplete="off"
										placeholder="Filter by Eg: Pending, Name..."
										value={searchTerm}
										onChange={(event) => {
											setSearchTerm(event.target.value);
										}}
										style={{
											width: "calc(100vw - 170px)",
											maxWidth: "450px",
											borderColor: "#e8e6df",
										}}
									/>
								</div>
							)}
							<div className="d-flex flex-wrap mb-2 order-lg-2 order-1">
								<p className="h6 mr-3 mr-lg-3">
									<span className="bi bi-box2-heart-fill text-primary mr-2" />
									Total Available: <strong>{currencyFormat(points.totalAvailable)}</strong>
								</p>
								<p className="h6 mr-3 mr-lg-3">
									<span className="bi bi-gift text-primary mr-2" />
									Total Pending: <strong>{currencyFormat(points.totalPending)}</strong>
								</p>
								<p className="h6 mr-0 mr-lg-3">
									<span className="bi bi-gift-fill text-primary mr-2" />
									Total Redeemed: <strong>{currencyFormat(points.totalRedeemed)}</strong>
								</p>
							</div>
						</div>
						{errors && (
							<div className="alert alert-danger" role="alert">
								{errors}
							</div>
						)}
						{success && (
							<div className="alert alert-success" role="alert">
								{success}
							</div>
						)}
						{screenWidth > 768 ? (
							<div className="bg-white rounded-sm border mb-5">
								<div className="table-responsive">
									<table className="table table-hover">
										<thead>
											<tr>
												<th className="border-0" colSpan={2} scope="col">
													Reward Activity
												</th>
												<th className="border-0" scope="col">
													Status
												</th>
												<th className="border-0" colSpan={2} scope="col">
													Reward Points
												</th>
											</tr>
										</thead>
										<RewardsTableBody
											rewards={rewards}
											percentageFilter={percentageFilter}
											setPercentageFilter={setPercentageFilter}
											rewardStatus={rewardStatus}
											setRewardStatus={setRewardStatus}
											activityType={activityType}
											setActivityType={setActivityType}
											handleFilter={handleFilter}
											page={page}
											rowsPerPage={rowsPerPage}
											claimPaymentReward={claimPaymentReward}
											submitting={submitting}
											shareString={shareString}
										/>
									</table>
								</div>
								{rewards.length > 0 && (
									<div className="w-100 px-2">
										<EllipsisPagination
											totalPages={totalPages}
											currentPage={page}
											onPageChange={handlePageChange}
											rowsPerPage={rowsPerPage}
											setRowsPerPage={setRowsPerPage}
											activePage={page}
											length={rewards.filter(handleFilter).length}
											dataName="Rewards"
										/>
									</div>
								)}
							</div>
						) : (
							<>
								{rewards.length === 0 ? (
									<div>
										<p className="h4 text-center">No Rewards!</p>
									</div>
								) : (
									<>
										{rewards
											.filter(handleFilter)
											.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
											.map((item, index) => {
												return (
													<div key={index} className="card w-100 mb-3">
														<div className="card-body">
															<div className="row no-gutters">
																<div className="d-flex col-4">
																	<p className="mr-1 mb-auto">{index + 1}</p>
																	<CircularProgressWithLabel
																		value={item?.resumeCompletionPct == undefined ? 0 : item?.resumeCompletionPct}
																		rewardPoints={item.rewardPoints}
																	/>
																</div>
																{item.activityType === "REFERRAL" && (
																	<div className="col-8 pl-3 my-auto">
																		<p className="fs-10">
																			<strong>Referred - </strong>
																			<span>
																				{capitalizeFirstLetterOfWords(item?.referredFullName)},
																				<br />
																			</span>
																			{`${item?.referredEmailAddress}${item?.referredPhoneNumber ? "," : ""} ${
																				item?.referredPhoneNumber ? item?.referredPhoneNumber : ""
																			}`}
																		</p>
																	</div>
																)}
																{item.activityType === "JOB_LEAD" && (
																	<div className="col-8 pl-3 my-auto">
																		<p className="fs-10">
																			<strong>Shared - </strong>
																			{capitalizeFirstLetterOfWords(item?.jobTitle)}
																			<strong> at - </strong> {item?.jobCompanyName}
																		</p>
																	</div>
																)}
																{item.activityType === "RESUME_COMPLETION" && (
																	<div className="col-8 pl-3 my-auto">
																		<p className="fs-10">
																			<strong>Resume - </strong> Completion <br />
																			{`${item?.activityDetail}`}
																		</p>
																	</div>
																)}
															</div>

															{item.rewardStatus !== "REDEEMED" && (
																<>
																	<div className="d-flex align-items-center">
																		<p className="mr-2 ml-4 mt-3">
																			<span className={`h3 ${getRewardPointTextColor(item.rewardStatus)}`}>
																				{currencyFormat(item.rewardPoints)}
																			</span>
																			<br />
																			<span>
																				Reward <br /> Points
																			</span>
																		</p>
																		<span
																			className="text-medium d-flex px-2 flex-column text-center justify-content-center cursor-pointer align-items-center"
																			onClick={() => {
																				if (item.rewardStatus === "PENDING" && !submitting) {
																					claimPaymentReward(item._id);
																				}
																			}}
																		>
																			<span
																				className={`${
																					item.rewardStatus === "PENDING" ? "text-primary" : "text-muted"
																				} fs-28 bi bi-collection`}
																			/>
																			Collect
																		</span>
																		<span
																			onClick={() => {
																				if (item?.resumeCompletionPct >= 80) {
																					navigate("/payments");
																				}
																			}}
																			data-toggle="modal"
																			className="d-flex px-2 flex-column justify-content-center cursor-pointer align-items-center"
																		>
																			<span
																				className={`${
																					item.rewardStatus === "CLAIMED" ? "text-primary" : "text-muted"
																				} fs-28 bi bi-wallet2`}
																			/>
																			<span>Redeem</span>
																		</span>
																		{item.activityType === "REFERRAL" && (
																			<a
																				href={`https://wa.me/${item?.referredPhoneNumber}?text=${shareString(item)}`}
																				target="_blank"
																				rel="noopener noreferrer"
																			>
																				<span
																					data-toggle="modal"
																					className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
																				>
																					<span className="text-success fs-24 bi mb-2 bi-whatsapp" />
																					<span className="text-dark">Remind</span>
																				</span>
																			</a>
																		)}
																	</div>
																</>
															)}
														</div>
													</div>
												);
											})}
										{rewards.length > 0 && (
											<div className="w-100 px-2">
												<EllipsisPagination
													totalPages={totalPages}
													currentPage={page}
													onPageChange={handlePageChange}
													rowsPerPage={rowsPerPage}
													setRowsPerPage={setRowsPerPage}
													activePage={page}
													length={rewards.filter(handleFilter).length}
													dataName="Rewards"
												/>
											</div>
										)}
									</>
								)}
							</>
						)}
					</>
				)}
			</div>
		</ExpertResumesLayout>
	);
}

export default Rewards;
