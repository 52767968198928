const initalState = {
	referenceId: "",
	fullName: "",
	designation: "",
	workPlace: "",
	workplaceLocation: "",
	phoneNumber: "",
	emailAddress: "",
};

export default function ReferenceInfoReducer(reference = initalState, action) {
	switch (action.type) {
		case "SUBMIT_INPUTS":
			return action.payload;

		default:
			return reference;
	}
}
