import React from "react";

function CandidateNotes({ candidateNotes }) {
	return (
		<div className="d-flex flex-column align-items-start p-0" style={{ gap: 16 }}>
			{candidateNotes?.title && (
				<div className="expert-review-orders-header-title" style={{ color: "#3E424E" }}>
					{candidateNotes?.title}
				</div>
			)}
			<div className="d-flex flex-column align-items-start p-0">
				{candidateNotes.data &&
					candidateNotes.data.map((item, index) => {
						return (
							<div key={index}>
								<div className="expert-review-footer-text" style={{ color: "#3E424E" }}>
									{index + 1}. {item.parent}
								</div>
								{item?.children &&
									item?.children.map((child) => {
										return (
											<li
												key={child}
												className="expert-review-footer-text"
												style={{ paddingLeft: 16, color: "#3E424E" }}
											>
												{child}
											</li>
										);
									})}
							</div>
						);
					})}
			</div>
		</div>
	);
}

export default CandidateNotes;
