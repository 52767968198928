import React from "react";

import FeedBackModal from "../../../../components/Reviews/FeedBackModal/FeedBackModal";
import { sections } from "../../../../handlers/enums";
import { convertDateToWords, convertUlToArray, removeHTTPAndDomainName } from "../../../../handlers/utilityFunctions";
import useReviewFeedBack from "../../../../hooks/use-ReviewFeedBack";

function ResumePremiumNine(props) {
	const { isReview, reviewData, resumeData, hasFeedback, activeSection, onSubmitFeedBack, loadingSubmission } = props;
	const { useReviewFeedback, feedBackButton } = useReviewFeedBack(reviewData);

	return (
		<div className="final-resume-preview-wrapper final-premium-template-9 final-premium-template-5 final-resume-preview-new shadow-sm">
			<div className=" text-dark py-3">
				<div className="container ">
					{resumeData.contactInfo.firstName !== "" && (
						<div className="d-flex mb-3 mb-lg-4">
							<div
								className="border-block"
								style={{
									backgroundColor: "#183042",
									height: "70px",
									flex: "0 0 50px",
									marginRight: "15px",
									marginTop: "10px",
								}}
							>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<div className="row no-gutters">
									<div className="col-8 pr-3">
										<h3 className="mb-0 text-capitalize">
											{resumeData.contactInfo.firstName} {resumeData.contactInfo.lastName}
										</h3>
										<h4 className="mb-2" style={{ color: "#458b67", fontWeight: 500 }}>
											{resumeData.contactInfo.resumeTitle}
										</h4>
										<div
											className={`position-relative review-section ${
												isReview
													? "rs-highlight"
													: hasFeedback
														? activeSection === sections.SUMMARY
															? "rs-feedback rs-feedback-active"
															: "rs-feedback"
														: ""
											}`}
											id={sections.SUMMARY}
											style={{
												backgroundColor:
													useReviewFeedback.section.section === sections.SUMMARY ? "rgba(27, 175, 245, 0.12)" : "",
											}}
										>
											{isReview || activeSection === sections.SUMMARY
												? feedBackButton(sections.SUMMARY, hasFeedback)
												: null}
											<p
												className="mb-2"
												dangerouslySetInnerHTML={{
													__html: resumeData.summary,
												}}
											/>
										</div>
									</div>
									<div className="col-4">
										<div>
											<p className="mb-1 mb-md-2 text-right">
												{resumeData.contactInfo.email}
												<i
													className="ml-2 fa fa-envelope contact-icon"
													style={{ color: "#183042" }}
													aria-hidden="true"
												/>
											</p>
											<p className="mb-1 mb-md-2 text-right">
												{resumeData.contactInfo.phoneNo}{" "}
												<i className="ml-2 fa fa-mobile contact-icon" style={{ color: "#183042" }} aria-hidden="true" />
											</p>
											<p className="mb-1 mb-md-2 text-right">
												{resumeData.contactInfo.city}, {resumeData.contactInfo.country}
												<i
													className="ml-2 fa fa-map-marker contact-icon"
													style={{ color: "#183042" }}
													aria-hidden="true"
												/>
											</p>
											{[...resumeData.extras.personalLinks].map((link, index) => {
												return link.type !== "otherLink" ? (
													<p key={index} className="mb-1 mb-md-2 text-right">
														<a href={link.value} target="_blank" rel="noreferrer" className="text-dark">
															{removeHTTPAndDomainName(link)}
														</a>
														<i
															className={`ml-2 contact-icon ${
																link.type === "linkedIn"
																	? "bi bi-linkedin"
																	: link.type === "twitter"
																		? "bi bi-twitter"
																		: link.type === "github"
																			? "bi bi-github"
																			: "fa fa-link"
															}`}
															style={{ color: "#183042" }}
															aria-hidden="true"
														/>
													</p>
												) : null;
											})}
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

					<hr className="mb-3 mb-md-4 border-line w-100" style={{ borderTop: "2px solid #458b67" }} />

					{resumeData.skills !== "" && convertUlToArray(resumeData.skills) ? (
						<div className="d-flex mb-3 mb-lg-4">
							<div
								className="border-block"
								style={{ height: "70px", flex: "0 0 50px", marginRight: "15px", marginTop: "10px" }}
							>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<h3 className="text-uppercase">skills</h3>
								<ul
									className={`p-0 mb-0 position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.SKILLS
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.SKILLS}
									style={{
										listStyle: "none",
										backgroundColor:
											useReviewFeedback.section.section === sections.SKILLS ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.SKILLS ? feedBackButton(sections.SKILLS, hasFeedback) : null}
									{convertUlToArray(resumeData.skills).map((item, index) => {
										const splitArray = item.value.split(/:(.+)/);
										let wordBeforeColon = splitArray[0].trim();
										wordBeforeColon = splitArray.length > 1 ? <strong>{wordBeforeColon}</strong> : wordBeforeColon;
										const wordAfterColon = splitArray.length > 1 ? ": " + splitArray[1].trim() : "";

										return (
											<li key={index} className="d-flex align-items-center mb-2">
												{wordBeforeColon}
												{wordAfterColon}
											</li>
										);
									})}
								</ul>
							</div>
						</div>
					) : null}

					{resumeData.extras.certifications !== "" ? (
						<div className="d-flex mb-3 mb-lg-4">
							<div
								className="border-block"
								style={{ height: "70px", flex: "0 0 50px", marginRight: "15px", marginTop: "10px" }}
							>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<h3 className="text-uppercase">CERTIFICATES</h3>
								<div
									className={`row position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.CERTIFICATION
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.CERTIFICATION}
									style={{
										backgroundColor:
											useReviewFeedback.section.section === sections.CERTIFICATION ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.CERTIFICATION
										? feedBackButton(sections.CERTIFICATION, hasFeedback)
										: null}
									{convertUlToArray(resumeData.extras.certifications).map((item, index) => {
										return (
											<div key={index} className="col-6 mb-2 pr-2">
												<p className="d-flex align-items-center">
													<span>{item.value}</span>
												</p>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					) : null}

					{resumeData.extras.accomplishments !== "" ? (
						<div className="d-flex mb-3 mb-lg-4">
							<div
								className="border-block"
								style={{ height: "70px", flex: "0 0 50px", marginRight: "15px", marginTop: "10px" }}
							>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<h3 className="text-uppercase">accomplishments</h3>
								<ul
									className={`p-0 mb-0 position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.ACCOMPLISHMENTS
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.ACCOMPLISHMENTS}
									style={{
										listStyle: "none",
										backgroundColor:
											useReviewFeedback.section.section === sections.ACCOMPLISHMENTS ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.ACCOMPLISHMENTS
										? feedBackButton(sections.ACCOMPLISHMENTS, hasFeedback)
										: null}
									{convertUlToArray(resumeData.extras.accomplishments).map((item, index) => {
										return (
											<li key={index} className="d-flex align-items-center mb-2">
												{item.value}
											</li>
										);
									})}
								</ul>
							</div>
						</div>
					) : null}

					{resumeData.workHistory.length > 0 && resumeData.workHistory[0].jobTitle !== "" ? (
						<div className=" mb-3 mb-lg-4">
							<div className="d-flex">
								<div
									className="border-block-space"
									style={{ flex: "0 0 50px", marginRight: "15px", marginTop: "10px" }}
								>
									&nbsp;
								</div>
								<div style={{ flexGrow: 1 }}>
									<h3 className="text-uppercase">WORK EXPERIENCE</h3>
								</div>
							</div>
							<div className="d-flex">
								<div
									className="border-block"
									style={{
										backgroundColor: "#458b67",
										height: "70px",
										flex: "0 0 50px",
										marginRight: "15px",
										marginTop: "10px",
									}}
								>
									&nbsp;
								</div>
								<div
									className={`position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.EXPERIENCE
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.EXPERIENCE}
									style={{
										flexGrow: 1,
										backgroundColor:
											useReviewFeedback.section.section === sections.EXPERIENCE ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.EXPERIENCE
										? feedBackButton(sections.EXPERIENCE, hasFeedback)
										: null}
									{[...resumeData.workHistory].map((job, index) => (
										<React.Fragment key={index}>
											<div className="mb-3 mb-md-4">
												<div className="d-flex justify-content-between">
													<p className="mb-2">
														<strong>{job.employer}</strong>
														{" - "}
														{job.city}, {job.country}
														<br />
														<em>{job.jobTitle}</em>
													</p>
													<p className="mb-0" style={{ color: "#458b67" }}>
														<strong>
															{convertDateToWords(job.startDate)} -{" "}
															{job.endDate !== "" ? convertDateToWords(job.endDate) : "Present"}
														</strong>
													</p>
												</div>
												<div
													className="d-flex align-items-center justify-content-between font-italic"
													style={{ color: "#458b67" }}
												>
													<p className="mb-2">Achievements</p>
												</div>
												<ul className="p-0 mb-0" style={{ listStyle: "none" }}>
													{convertUlToArray(job.details).map((item, index) => {
														return (
															<li key={index} className="d-flex align-items-center">
																<span
																	style={{
																		fontWeight: 500,
																		flex: "0 0 20px",
																		fontSize: "5px",
																		color: "#458b67",
																	}}
																>
																	<span className="fa fa-circle" />
																</span>
																<span className="w-100">{item.value}</span>
															</li>
														);
													})}
												</ul>
											</div>
										</React.Fragment>
									))}
								</div>
							</div>
						</div>
					) : null}

					{resumeData.education.length > 0 && (
						<div className=" mb-3 mb-lg-4">
							<div className="d-flex">
								<div
									className="border-block-space"
									style={{ flex: "0 0 50px", marginRight: "15px", marginTop: "10px" }}
								>
									&nbsp;
								</div>
								<div style={{ flexGrow: 1 }}>
									<h3 className="text-uppercase">EDUCATION</h3>
								</div>
							</div>
							<div className="d-flex">
								<div
									className="border-block"
									style={{
										backgroundColor: "#458b67",
										height: "70px",
										flex: "0 0 50px",
										marginRight: "15px",
										marginTop: "10px",
									}}
								>
									&nbsp;
								</div>
								<div
									className={`position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.EDUCATION
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.EDUCATION}
									style={{
										flexGrow: 1,
										backgroundColor:
											useReviewFeedback.section.section === sections.EDUCATION ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.EDUCATION
										? feedBackButton(sections.EDUCATION, hasFeedback)
										: null}
									{[...resumeData.education].map((educationHistory, index) => (
										<div key={index} className="mb-3">
											<div className="d-flex justify-content-between">
												<p>
													<strong>{educationHistory.schoolName}</strong>
													{" - "}
													{educationHistory.schoolCity}, <br />
													<em>{educationHistory.fieldOfStudy}</em>
												</p>
												<p className="mr-4" style={{ color: "#458b67" }}>
													<strong>{educationHistory.gYear}</strong>
												</p>
											</div>
											<ul className="p-0 mb-0" style={{ listStyle: "none" }}>
												{convertUlToArray(educationHistory.description).map((item, index) => {
													if (item.value === "") {
														return null;
													}
													return (
														<li key={index} className="d-flex align-items-center">
															<span
																style={{
																	fontWeight: 500,
																	flex: "0 0 20px",
																	fontSize: "5px",
																	color: "#458b67",
																}}
															>
																<span className="fa fa-circle" />
															</span>
															<span className="w-100">{item.value}</span>
														</li>
													);
												})}
											</ul>
										</div>
									))}
								</div>
							</div>
						</div>
					)}

					{resumeData.extras.languages.length !== 0 ? (
						<div className="d-flex mb-3 mb-lg-4">
							<div
								className="border-block"
								style={{ height: "70px", flex: "0 0 50px", marginRight: "15px", marginTop: "10px" }}
							>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<h3 className="text-uppercase">LANGUAGES</h3>
								<div
									className={`row position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.LANGUAGES
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.LANGUAGES}
									style={{
										backgroundColor:
											useReviewFeedback.section.section === sections.LANGUAGES ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.LANGUAGES
										? feedBackButton(sections.LANGUAGES, hasFeedback)
										: null}
									{resumeData.extras.languages.map((language, index) => (
										<div key={index} className="col-6">
											<h6 className="mt-2 mb-2 font-weight-bold">
												{language.language}
												<p className="font-italic mb-0" style={{ color: "#777777", fontWeight: 500 }}>
													{language.proficiency}
												</p>
											</h6>
										</div>
									))}
								</div>
							</div>
						</div>
					) : null}

					{resumeData.extras.personalProfile.dateOfBirth && (
						<div className="d-flex mb-3 mb-lg-4">
							<div
								className="border-block"
								style={{ height: "70px", flex: "0 0 50px", marginRight: "15px", marginTop: "10px" }}
							>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<h3 className="text-uppercase">Personal Information</h3>
								<div
									className={`row position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.PERSONAL
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.PERSONAL}
									style={{
										backgroundColor:
											useReviewFeedback.section.section === sections.PERSONAL ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.PERSONAL
										? feedBackButton(sections.PERSONAL, hasFeedback)
										: null}
									<div className="col-6">
										<div className="mb-2 mb-md-3">
											<strong>Date of Birth:</strong>&nbsp;
											{resumeData.extras.personalProfile.dateOfBirth}
										</div>
									</div>
									<div className="col-6">
										<div className="mb-2 mb-md-3">
											<strong>Gender:</strong>&nbsp;
											{resumeData.extras.personalProfile.gender}
										</div>
									</div>
									<div className="col-6">
										<div className="mb-2 mb-md-3">
											<strong>Marital Status:</strong>&nbsp;
											{resumeData.extras.personalProfile.maritalStatus}
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

					{resumeData.extras.affiliations !== "" ? (
						<div className="d-flex mb-3 mb-lg-4">
							<div
								className="border-block"
								style={{ height: "70px", flex: "0 0 50px", marginRight: "15px", marginTop: "10px" }}
							>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<h3 className="text-uppercase">affiliations</h3>
								<div
									className={`row position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.AFFILIATION
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.AFFILIATION}
									style={{
										backgroundColor:
											useReviewFeedback.section.section === sections.AFFILIATION ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.AFFILIATION
										? feedBackButton(sections.AFFILIATION, hasFeedback)
										: null}
									{convertUlToArray(resumeData.extras.affiliations).map((item, index) => {
										return (
											<div key={index} className="col-6 mb-2 pr-2">
												<p className="d-flex align-items-center">
													<span>{item.value}</span>
												</p>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					) : null}

					{resumeData.extras.additionalInfo !== "" ? (
						<div className="d-flex mb-3 mb-lg-4">
							<div
								className="border-block"
								style={{ height: "70px", flex: "0 0 50px", marginRight: "15px", marginTop: "10px" }}
							>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<h3 className="text-uppercase">additional information</h3>
								<div
									className={`row position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.ADDITIONAL_INFO
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.ADDITIONAL_INFO}
									style={{
										backgroundColor:
											useReviewFeedback.section.section === sections.ADDITIONAL_INFO ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.ADDITIONAL_INFO
										? feedBackButton(sections.ADDITIONAL_INFO, hasFeedback)
										: null}
									{convertUlToArray(resumeData.extras.additionalInfo).map((item, index) => {
										return (
											<div key={index} className="col-6 mb-2 pr-2">
												<p className="d-flex align-items-center">
													<span>{item.value}</span>
												</p>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					) : null}

					{[...resumeData.extras.personalLinks].some((link) => link.type === "otherLink") && (
						<div className="d-flex mb-3 mb-lg-4">
							<div
								className="border-block"
								style={{ height: "70px", flex: "0 0 50px", marginRight: "15px", marginTop: "10px" }}
							>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<h3 className="text-uppercase">website, portfolio, profiles</h3>
								<ul
									className={`p-0 mb-0 position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.LINKS
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.LINKS}
									style={{
										listStyle: "none",
										backgroundColor:
											useReviewFeedback.section.section === sections.LINKS ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.LINKS ? feedBackButton(sections.LINKS, hasFeedback) : null}
									{[...resumeData.extras.personalLinks].map((link, index) => {
										return link.type === "otherLink" ? (
											<li key={index} className="d-flex align-items-center mb-2">
												{link.value}
											</li>
										) : null;
									})}
								</ul>
							</div>
						</div>
					)}

					{resumeData.extras.references.length > 0 ? (
						<div className="d-flex mb-3 mb-lg-4">
							<div
								className="border-block"
								style={{ height: "70px", flex: "0 0 50px", marginRight: "15px", marginTop: "10px" }}
							>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<h3 className="text-uppercase">references</h3>
								<div
									className={`row position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.REFERENCE
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.REFERENCE}
									style={{
										backgroundColor:
											useReviewFeedback.section.section === sections.REFERENCE ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.REFERENCE
										? feedBackButton(sections.REFERENCE, hasFeedback)
										: null}
									{[...resumeData.extras.references].map((reference, index) => (
										<div key={index} className="col-6 d-flex mb-3">
											<div>
												<strong>{reference.name}</strong>
												{" - "}
												<em>{reference.designation}, </em>
												{reference.workPlace}
												<br />
												<p style={{ color: "#bcbcbc" }}>
													{reference.email}, {reference.phoneNumber}
												</p>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					) : null}

					{resumeData.extras.customExtras.name.length > 0 ? (
						<div className="d-flex mb-3 mb-lg-4">
							<div
								className="border-block"
								style={{ height: "70px", flex: "0 0 50px", marginRight: "15px", marginTop: "10px" }}
							>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<h3 className="text-uppercase">{resumeData.extras.customExtras.name}</h3>
								<div
									className={`row position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.CUSTOM
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.CUSTOM}
									style={{
										backgroundColor:
											useReviewFeedback.section.section === sections.CUSTOM ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.CUSTOM ? feedBackButton(sections.CUSTOM, hasFeedback) : null}
									{convertUlToArray(resumeData.extras.customExtras.data).map((item, index) => {
										return (
											<div key={index} className="col-6 mb-2 pr-2">
												<p className="d-flex align-items-center">
													<span>{item.value}</span>
												</p>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>
			{useReviewFeedback.showFeedBackModal && (
				<FeedBackModal
					resumeData={resumeData}
					details={useReviewFeedback.section}
					onSubmitFeedBack={onSubmitFeedBack}
					loadingSubmission={loadingSubmission}
				/>
			)}
		</div>
	);
}

export default ResumePremiumNine;
