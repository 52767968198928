/*
 * @deprecated
 * This file is deprecated and should not be used. It is only here for reference purposes.
 */
import React from "react";

import EmployerLandingPage from "../Views/EmployerLandingPage";
import FAQ from "../Views/FAQ";
import SubscriptionPage from "../Views/ManageResumes/SubscriptionPage";
import PrivacyPolicy from "../Views/PrivacyPolicy";
import AboutUs from "../Views/StaticPages/AboutUs";
import DrawBacks from "../Views/StaticPages/ResumeTips";
import TermsAndConditions from "../Views/TermsAndConditions/";

const CreateResumeOptions = React.lazy(() => import("../Views/CreateResumeOptions"));
const LandingPage = React.lazy(() => import("../Views/LandingPage"));
const SelectExperience = React.lazy(() => import("../Views/SelectExperience/SelectExperience"));
const SelectResumeTemplate = React.lazy(() => import("../Views/SelectResumeTemplate/SelectResumeTemplate"));
const ResumeContactInfo = React.lazy(() => import("../Views/ResumeBuilder/Heading/ResumeContactInfo"));
const ResumeWorkHistory = React.lazy(() => import("../Views/ResumeBuilder/Experience/ResumeWorkHistory"));
const ResumeWorkDetails = React.lazy(() => import("../Views/ResumeBuilder/Experience/ResumeWorkDetails"));
const WorkHistorySummary = React.lazy(() => import("../Views/ResumeBuilder/Experience/WorkHistorySummary"));
const ResumerBuilderLayout = React.lazy(() => import("../components/ResumeBuilder/ResumeBuilderLayout"));
const ResumeEducationInformation = React.lazy(
	() => import("../Views/ResumeBuilder/Education/ResumeEducationInformation"),
);
const ResumeEducationSummary = React.lazy(() => import("../Views/ResumeBuilder/Education/ResumeEducationSummary"));
const ResumeSkills = React.lazy(() => import("../Views/ResumeBuilder/Skills"));
const ResumeBriefSummary = React.lazy(() => import("../Views/ResumeBuilder/Summary"));
const Extras = React.lazy(() => import("../Views/ResumeBuilder/ResumeExtras/Extras"));
const Accomplishments = React.lazy(() => import("../Views/ResumeBuilder/ResumeExtras/Accomplishments"));
const Affiliations = React.lazy(() => import("../Views/ResumeBuilder/ResumeExtras/Affiliations"));
const PersonalLinks = React.lazy(() => import("../Views/ResumeBuilder/ResumeExtras/PersonalLinks"));
const Certifications = React.lazy(() => import("../Views/ResumeBuilder/ResumeExtras/Certifications"));
const AdditionalInformation = React.lazy(() => import("../Views/ResumeBuilder/ResumeExtras/AdditionalInforamtion"));
const Languages = React.lazy(() => import("../Views/ResumeBuilder/ResumeExtras/Languages"));

const Review = React.lazy(() => import("./../Views/ResumeBuilder/Review/index"));
const ViewPdf = React.lazy(() => import("../Views/ResumeBuilder/Review/viewPdf"));

const PersonalProfile = React.lazy(() => import("../Views/ResumeBuilder/ResumeExtras/PersonalProfile/PersonalProfile"));
const ReferenceInformation = React.lazy(
	() => import("../Views/ResumeBuilder/ResumeExtras/Reference/ReferenceInformation"),
);
const ReferenceSummary = React.lazy(() => import("../Views/ResumeBuilder/ResumeExtras/Reference/ReferenceSummary"));
const CustomExtras = React.lazy(() => import("../Views/ResumeBuilder/ResumeExtras/CustomExtras"));

const RegisterScreen = React.lazy(() => import("./../Views/Auth/RegisterScreen/"));
const SigninScreen = React.lazy(() => import("./../Views/Auth/SigninScreen/"));
const ForgotPasswordScreen = React.lazy(() => import("./../Views/Auth/ForgotPasswordScreen/"));

const MyResumes = React.lazy(() => import("./../Views/ManageResumes/MyResumes/"));

const JobIndex = React.lazy(() => import("./../Views/Jobs/JobIndex"));
const JobListing = React.lazy(() => import("./../Views/Jobs/JobListing"));
// const JobApplication = React.lazy(() => import("./../Views/Jobs/JobApplication"));
const JobPreference = React.lazy(() => import("./../Views/Jobs/JobPreferences"));
const Settings = React.lazy(() => import("./../Views/ManageResumes/Settings"));
const Payments = React.lazy(() => import("./../Views/ManageResumes/Payments"));
const Payment2 = React.lazy(() => import("./../Views/ManageResumes/Payment-2"));
const PendingPayments = React.lazy(() => import("./../Views/ManageResumes/PendingPayments"));
const ExpertReview = React.lazy(() => import("../Views/Reviews/ExpertReview"));
const SharedJobDescription = React.lazy(() => import("../Views/Jobs/JobIndex"));
const CandidateReview = React.lazy(() => import("../Views/Reviews/CandidateReview"));
const ReviewDashboard = React.lazy(() => import("../Views/Reviews/Dashboard"));
const CandidateHomeDashboard = React.lazy(() => import("../Views/Dashboard/CandidateHomeDashboard"));

export class RouteHierachy {
	constructor() {
		this.routes = [];
		this.createHierachy();
	}

	createHierachy() {
		//parents
		//
		const landingPage = new RouteObject("landing Page", LandingPage, "landing Page", "", ["anonynous"]);
		const aboutUs = new RouteObject("about us", AboutUs, "about us", "about-us", ["anonynous"]);
		const drawBacks = new RouteObject("draw back", DrawBacks, "draw back", "drawbacks", ["anonynous"]);
		const FAQs = new RouteObject("faq", FAQ, "faq", "faq", ["anonynous"]);
		const employerLanding = new RouteObject(
			"employer landing",
			EmployerLandingPage,
			"employer landing",
			"employer-landing",
			["anonynous"],
		);

		const termsAndConditions = new RouteObject(
			"terms and conditions",
			TermsAndConditions,
			"terms and conditions",
			"terms-and-conditions",
			["anonynous"],
		);
		const privacyPolicy = new RouteObject("privacy policy", PrivacyPolicy, "privacy policy", "privacy-policy", [
			"anonynous",
		]);
		const subscription = new RouteObject("subscription", SubscriptionPage, "subscription", "subscription", [
			"anonynous",
		]);

		const viewPdfPage = new RouteObject("view pdf", ViewPdf, "view pdf", "view-pdf", ["anonynous"]);

		const reviewDashboard = new RouteObject(
			"Review Dashboard",
			ReviewDashboard,
			"Review Dashboard",
			"review-dashboard",
			["anonynous"],
		);

		const candidateHomeDashboard = new RouteObject(
			"Candidate Home Dashboard",
			CandidateHomeDashboard,
			"Candidate Home Dashboard",
			"dashboard",
			["anonynous"],
		);

		const expertReview = new RouteObject("Expert Review", ExpertReview, "Expert Review", "review-resume", [
			"anonynous",
		]);

		const candidateReview = new RouteObject(
			"Candidate Review",
			CandidateReview,
			"Candidate Review",
			"candidate-review",
			["anonynous"],
		);

		const jobListing = new RouteObject("Job Listing Page", JobListing, "Job Listing Page", "jobs-listing", [
			"anonynous",
		]);
		const jobIndex = new RouteObject("Job Index Page", JobIndex, "Job Index Page", "jobs", ["anonynous"]);
		const sharedJobDescription = new RouteObject(
			"Job Index Page",
			SharedJobDescription,
			"Job Index Page",
			"jobs/shared",
			["anonynous"],
		);
		const jobPreference = new RouteObject(
			"Job Preference Page",
			JobPreference,
			"Job Preference Page",
			"jobs-preference",
			["anonynous"],
		);
		const resume = new RouteObject("Resume", ResumerBuilderLayout, "resume", "resume", ["anonynous"]);
		const register = new RouteObject("RegisterScreen", RegisterScreen, "register", "register", ["anonynous"]);
		const signin = new RouteObject("SigninScreen", SigninScreen, "SigninScreen", "signin", ["anonynous"]);
		const forgotpassword = new RouteObject(
			"ForgotPasswordScreen",
			ForgotPasswordScreen,
			"ForgotPasswordScreen",
			"forgot-password",
			["anonynous"],
		);
		const settings = new RouteObject("settings", Settings, "settings", "settings", ["anonynous"]);
		const payments = new RouteObject("payments-old", Payments, "payments-old", "payments-old", ["anonynous"]);
		const payment2 = new RouteObject("payments", Payment2, "payments", "payments", ["anonynous"]);
		const pendingPayments = new RouteObject(
			"pending-payments",
			PendingPayments,
			"pending-payments",
			"pending-payments",
			["anonynous"],
		);
		const myresumes = new RouteObject("my-resumes", MyResumes, "my-resumes", "my-resumes", ["anonynous"]);
		const resumeReview = new RouteObject("resume/review", Review, "resume/review", "resume/review", ["anonynous"]);

		//Resume Build
		resume.addChild(
			new RouteObject("Select Template", SelectResumeTemplate, "Select Template", "select-template", ["anonynous"]),
		);
		resume.addChild(
			new RouteObject("Create resume options", CreateResumeOptions, "Create resume options", "create-resume-options", [
				"anonynous",
			]),
		);
		resume.addChild(new RouteObject("", SelectExperience, "", "select-experience", ["anonynous"]));
		resume.addChild(new RouteObject("", ResumeContactInfo, "", "contact-information", ["anonynous"]));
		resume.addChild(new RouteObject("", ResumeWorkHistory, "", "work-history", ["anonynous"]));
		resume.addChild(new RouteObject("", ResumeWorkDetails, "", "work-details", ["anonynous"]));
		resume.addChild(new RouteObject("", WorkHistorySummary, "", "work-history-summary", ["anonynous"]));
		resume.addChild(new RouteObject("", ResumeEducationInformation, "", "education-information", ["anonynous"]));
		resume.addChild(new RouteObject("", ResumeEducationSummary, "", "education-summary", ["anonynous"]));
		resume.addChild(new RouteObject("", ResumeSkills, "", "skills", ["anonynous"]));
		resume.addChild(new RouteObject("", ResumeBriefSummary, "", "brief-summary", ["anonynous"]));
		resume.addChild(new RouteObject("", Extras, "", "extras", ["anonynous"]));
		resume.addChild(new RouteObject("", Accomplishments, "", "accomplishments", ["anonynous"]));
		resume.addChild(new RouteObject("", Affiliations, "", "affiliations", ["anonynous"]));
		resume.addChild(new RouteObject("", PersonalLinks, "", "personal-links", ["anonynous"]));
		resume.addChild(new RouteObject("", Certifications, "", "certifications", ["anonynous"]));
		resume.addChild(new RouteObject("", AdditionalInformation, "", "additional-information", ["anonynous"]));
		resume.addChild(new RouteObject("", Languages, "", "languages", ["anonynous"]));
		resume.addChild(new RouteObject("", PersonalProfile, "", "personal-profile", ["anonynous"]));
		resume.addChild(new RouteObject("", ReferenceInformation, "", "reference-information", ["anonynous"]));
		resume.addChild(new RouteObject("", ReferenceSummary, "", "reference-summary", ["anonynous"]));

		resume.addChild(
			new RouteObject("CustomExtras", CustomExtras, "CustomExtras", "custom-extra/:customExtra", ["anonynous"]),
		);

		this.routes.push(landingPage);
		this.routes.push(aboutUs);
		this.routes.push(drawBacks);
		this.routes.push(termsAndConditions);
		this.routes.push(privacyPolicy);
		this.routes.push(subscription);
		this.routes.push(FAQs);
		this.routes.push(employerLanding);
		this.routes.push(jobListing);
		// this.routes.push(jobApplication);
		this.routes.push(jobPreference);
		this.routes.push(register);
		this.routes.push(signin);
		this.routes.push(jobIndex);
		this.routes.push(sharedJobDescription);
		this.routes.push(reviewDashboard);
		this.routes.push(candidateHomeDashboard);
		this.routes.push(expertReview);
		this.routes.push(candidateReview);
		this.routes.push(settings);
		this.routes.push(payments);
		this.routes.push(payment2);
		this.routes.push(pendingPayments);
		this.routes.push(myresumes);
		this.routes.push(resumeReview);
		this.routes.push(resume);
		this.routes.push(forgotpassword);
		this.routes.push(viewPdfPage);
	}

	getHierachy() {
		return this.routes;
	}
}

export class RouteObject {
	constructor(name, element, description, url, securityRoles) {
		this.name = name;
		this.element = element;
		this.description = description;
		this.url = url;
		this.securityRoles = securityRoles;
		this.children = [];
	}

	addChild(childroute) {
		this.children.push(childroute);
	}
}
