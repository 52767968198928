import React from "react";

function TemplatePremiumOne() {
	return (
		<div style={{ scale: "1.2", transformOrigin: "center", maxHeight: "400px", overflowY: "auto" }}>
			<div className="small-resume-preview small-resume-preview-premium-template-1">
				<div className="p-1">
					<table
						className="w-100 mx-auto"
						style={{
							maxWidth: "1000px",
							tableLayout: "fixed",
							width: "100%",
							border: 0,
							cellspacing: 0,
							cellpadding: 0,
							// width: "1000px",
						}}
					>
						<tbody>
							<tr>
								<td className="pb-3">
									<div className="d-flex">
										<div style={{ width: "60%" }} className="d-inline-block">
											<div className="d-flex" style={{ height: "100%" }}>
												<span className="border-line" style={{ backgroundColor: "#397ea8" }} />
												<div
													className="ml-1"
													style={{
														backgroundColor: "#ebebeb",
														borderRadius: "10px",
														padding: "5px",
														height: "100%",
													}}
												>
													<h4 style={{ color: "#397ea8", fontSize: "12px" }}>JACQUILINE KHALFAN</h4>
													<h4 style={{ color: "#bcbcbc" }}>Full Stack Developer</h4>
													<p>
														Solution-driven Full-Stack Developer with over 6+ years of work experience bui ding
														consumer-focused online products and services.Designed and developed web apps across
														multiple APls, third-party integrations,and databases.
													</p>
												</div>
											</div>
										</div>
										<div
											style={{
												width: "40%",
												backgroundColor: "#397ea8",
												color: "#bcbcbc",
												borderRadius: "10px",
												padding: "5px",
											}}
											className="d-inline-block ml-1"
										>
											<div className="d-flex align-items-center mb-1">
												<div
													style={{
														border: "1px solid #bcbcbc",
														borderRadius: "50%",
														textAlign: "center",
													}}
													className="contact-icon"
												>
													<span className="fa fa-envelope" />
												</div>
												<span>jacquiline.khalfan@dproz.tz</span>
											</div>
											<div className="d-flex align-items-center mb-1">
												<div
													style={{
														border: "1px solid #bcbcbc",
														borderRadius: "50%",
														textAlign: "center",
													}}
													className="contact-icon"
												>
													<span className="fa fa-mobile" />
												</div>
												<span>123456789</span>
											</div>
											<div className="d-flex align-items-center mb-1">
												<div
													style={{
														border: "1px solid #bcbcbc",
														borderRadius: "50%",
														textAlign: "center",
													}}
													className="contact-icon"
												>
													<span className="fa fa-map-marker" />
												</div>
												<span>Oslo, Ireland</span>
											</div>
											<div className="d-flex align-items-center mb-1">
												<div
													style={{
														border: "1px solid #bcbcbc",
														borderRadius: "50%",
														textAlign: "center",
													}}
													className="contact-icon"
												>
													<span className="fa fa-linkedin" />
												</div>
												<span>linkedin.com/in/johan.smith</span>
											</div>
											<div className="d-flex align-items-center mb-1">
												<div
													style={{
														border: "1px solid #bcbcbc",
														borderRadius: "50%",
														textAlign: "center",
													}}
													className="contact-icon"
												>
													<span className="fa fa-github" />
												</div>
												<span>github.com/johan.smith</span>
											</div>

											<div className="d-flex align-items-center mb-1">
												<div
													style={{
														border: "1px solid #bcbcbc",
														borderRadius: "50%",
														textAlign: "center",
													}}
													className="contact-icon"
												>
													<span className="fa fa-stack-overflow" />
												</div>
												<span>Johan.smith</span>
											</div>
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<td className="pb-2">
									<div className="d-flex" style={{ height: "100%" }}>
										<span className="border-line" style={{ backgroundColor: "#bcbcbc" }} />
										<div className="ml-2" style={{ flexGrow: 1 }}>
											<h3 style={{ color: "#397ea8" }} className="mb-2">
												<span
													style={{
														border: "1px solid #397ea8",
														textAlign: "center",
														marginRight: "5px",
														borderRadius: "50%",
														color: "#397ea8",
														display: "inline-block",
													}}
													className="title-icon"
												>
													<span className="fa fa-cogs" />
												</span>
												SKILLS
											</h3>
											<div className="d-flex flex-wrap">
												<div style={{ width: "50%" }} className="d-flex mb-1">
													<div style={{ width: "30%" }} className="mr-2">
														<strong>Front-end Technology</strong>
													</div>
													<div style={{ width: "70%" }}>
														<p>HTML5, CSS3, JavaScript, jQuery, Angular and ReactJS</p>
													</div>
												</div>
												<div style={{ width: "50%" }} className="d-flex mb-1">
													<div style={{ width: "30%" }} className="mr-2">
														<strong>Development Languages</strong>
													</div>
													<div style={{ width: "70%" }}>
														<p>Java,JavaScript, C#, C, C++,Python, Ruby and .Net</p>
													</div>
												</div>
												<div style={{ width: "50%" }} className="d-flex mb-1">
													<div style={{ width: "30%" }} className="mr-2">
														<strong>Database and Cache</strong>
													</div>
													<div style={{ width: "70%" }}>
														<p>MySQL, MongoDB,Oracle, SQLServer, Memcached and Redis.</p>
													</div>
												</div>
												<div style={{ width: "50%" }} className="d-flex mb-1">
													<div style={{ width: "30%" }} className="mr-2">
														<strong>Soft Skills</strong>
													</div>
													<div style={{ width: "70%" }}>
														<p>Teamwork, Time Management, Adaptability and Work Ethic.</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<td className="pb-2">
									<div className="d-flex" style={{ height: "100%" }}>
										<span className="border-line" style={{ backgroundColor: "#397ea8" }} />
										<div className="ml-2" style={{ flexGrow: 1 }}>
											<h3 style={{ color: "#397ea8" }} className="mb-2">
												<span
													style={{
														border: "1px solid #397ea8",
														textAlign: "center",
														marginRight: "5px",
														borderRadius: "50%",
														color: "#397ea8",
														display: "inline-block",
													}}
													className="title-icon"
												>
													<span className="fa fa-briefcase" />
												</span>
												WORK EXPERIENCE
											</h3>
											<div className="mb-4">
												<div className="d-flex">
													<div style={{ flexGrow: 1 }}>
														<h4>Full-Stack Developer Resume</h4>
														<h5>Pear Computers</h5>
														<p style={{ color: "#bcbcbc" }}>02/2017 - 11/2021</p>
														<p style={{ color: "#bcbcbc" }}>Achievements</p>
													</div>
													<div className="text-right" style={{ alignSelf: "self-end" }}>
														<p style={{ color: "#bcbcbc" }}>Oslo</p>
													</div>
												</div>
												<div className="d-flex mt-1 align-items-center">
													<span
														className="fa fa-circle"
														style={{
															fontSize: "5px",
															marginRight: "10px",
															color: "#bcbcbc",
														}}
													/>
													Developed backend services using Spring boot framework and front end using Angular as well as
													AngularJS.
												</div>

												<div className="d-flex mt-1 align-items-center">
													<span
														className="fa fa-circle"
														style={{
															fontSize: "5px",
															marginRight: "10px",
															color: "#bcbcbc",
														}}
													/>
													Coordinated and deployed 3 complex projects on an EC2 instance running Ubuntu and Nginx.
												</div>
												<div className="d-flex mt-1 align-items-center">
													<span
														className="fa fa-circle"
														style={{
															fontSize: "5px",
															marginRight: "10px",
															color: "#bcbcbc",
														}}
													/>
													Interviewed over 50 prospective job applicants and mentored 10junior software developers in
													the last 3 years and successfully scaled the team.
												</div>
											</div>
											<div className="mb-4">
												<div className="d-flex">
													<div style={{ flexGrow: 1 }}>
														<h4>Front End Developer</h4>
														<h5>Caulfield Technologies</h5>
														<p style={{ color: "#bcbcbc" }}>02/2017 - 11/2021</p>
														<p style={{ color: "#bcbcbc" }}>Achievements</p>
													</div>
													<div className="text-right" style={{ alignSelf: "self-end" }}>
														<p style={{ color: "#bcbcbc" }}>Copenhagen</p>
													</div>
												</div>
												<div className="d-flex mt-1 align-items-center">
													<span
														className="fa fa-circle"
														style={{
															fontSize: "5px",
															marginRight: "10px",
															color: "#bcbcbc",
														}}
													/>
													Developed backend services using Spring boot framework and front end using Angular as well as
													AngularJS.
												</div>
											</div>
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<td className="pb-2">
									<div className="d-flex" style={{ height: "100%" }}>
										<span className="border-line" style={{ backgroundColor: "#bcbcbc" }} />
										<div className="ml-2" style={{ flexGrow: 1 }}>
											<h3 style={{ color: "#397ea8" }} className="mb-2">
												<span
													style={{
														border: "1px solid #397ea8",
														textAlign: "center",
														marginRight: "5px",
														borderRadius: "50%",
														color: "#397ea8",
														display: "inline-block",
													}}
													className="title-icon"
												>
													<span className="fa fa-graduation-cap" />
												</span>
												EDUCATION
											</h3>

											<div className="mb-2">
												<h4>Master of Science in Computer Science</h4>
												<h5>IT University of Copenhagen</h5>
												<p style={{ color: "#bcbcbc" }}>2013 - 2015</p>
											</div>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

export default TemplatePremiumOne;
