import React from "react";

function ConnectOnWhatsApp() {
	return (
		<div
			className="d-flex align-items-center justify-content-center cursor-pointer"
			style={{ gap: "8px", background: "#25D366", borderRadius: "6px", padding: "10px 12px" }}
		>
			<i className="bi bi-whatsapp" style={{ color: "#FFFFFF", fontSize: "20px" }} />
			<div className="mark-review-complete-text-button" style={{ color: "#FFFFFF" }}>
				Connect on WhatsApp
			</div>
		</div>
	);
}

export default ConnectOnWhatsApp;
