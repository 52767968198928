import React, { useEffect } from "react";
import ReactGA from "react-ga4";

const RouteChangeTracker = ({ history }) => {
	useEffect(() => {
		ReactGA.set({ page: window.location.pathname });
	}, [history]);

	return <></>;
};

export default RouteChangeTracker;
