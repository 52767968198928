import React from "react";

function DownloadResumeModal({ resume }) {
	//TODO use resume ID to download resume

	const [, setSelectedFormat] = React.useState("pdf");

	const changeFormat = (event) => {
		setSelectedFormat(event.target.value);
	};

	const downloadResume = () => {
		//TODO download resume
	};

	return (
		<div
			className="modal fade"
			id="DownloadResumeModal"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="DownloadResumeModal"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg" role="document" style={{ maxWidth: 650 + "px" }}>
				<div className="modal-content px-4">
					<div className="modal-header border-bottom-0">
						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<form action="#">
						<div className="modal-body py-0">
							<h2 className="mb-4 text-primary font-weight-bold">Download Resume</h2>
							<div className="form-group">
								<input type="text" className="form-control" value={resume.name} />
							</div>
							<p>
								<em>
									<span className="fa fa-info-circle mr-2 text-primary" /> Don&#39;t use numbers when naming your file
								</em>
							</p>
							<h5 className="mt-4 mb-4">Choose your document format </h5>
							<form>
								<ul className="d-flex align-items-center flex-wrap list-unstyled fs-14">
									<li className="mr-2">
										<div className="checkbox">
											<label htmlFor="PDF">
												<input type="checkbox" name="logged" value="PDF" onChange={changeFormat} />
												Adobe PDF (.pdf)
											</label>
										</div>
									</li>
									<li className="mr-2">
										<div className="checkbox">
											<label htmlFor="MSworld">
												<input type="checkbox" name="logged" value="MSworld" onChange={changeFormat} />
												Microsoft Word(.docx)
											</label>
										</div>
									</li>
									<li>
										<div className="checkbox">
											<label htmlFor="Txt">
												<input type="checkbox" name="logged" value="Txt" onChange={changeFormat} />
												Plain Text (.txt)
											</label>
										</div>
									</li>
								</ul>
							</form>
						</div>
						<div className="modal-footer border-top-0 d-flex justify-content-between mt-4">
							<button type="button" className="btn btn-dark" data-dismiss="modal">
								Cancel
							</button>
							<button type="button" className="btn btn-primary" onClick={downloadResume}>
								Continue
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default DownloadResumeModal;
