import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { packageNames } from "../../../handlers/utilityFunctions";
import { base64ToAscii } from "../../../handlers/valueTransformation";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import Checkout from "./Checkout";
import PaymentComplete from "./PaymentComplete";
import SubscriptionOptions from "./SubscriptionOptions";

function PaymentFlow() {
	const location = useLocation();
	const [currentStep, setCurrentStep] = useState(1);
	const [usePoints, setUsePoints] = useState(false);

	const [paymentLink, setPaymentLink] = useState("");
	const [orderID, setOrderID] = useState("");
	const [orderData, setOrderData] = useState(null);
	const [loading, setLoading] = useState(false);

	const [searchParams] = useSearchParams();

	const URLOrderID = searchParams.get("order");

	useEffect(() => {
		if (URLOrderID) {
			const storedData = localStorage.getItem("orderID");
			if (storedData) {
				setOrderData(JSON.parse(storedData));
			}
			setOrderID(URLOrderID);
			setCurrentStep(4);
		}
	}, [URLOrderID]);

	const [selectedPackage, setSelectedPackage] = useState("");

	const [paymentDetails, setPaymentDetails] = useState({
		fullName: "",
		phoneNumber: "",
		amount: 0,
		packageDetail: "",
		emailAddress: "",
	});

	function getAmount() {
		const dprozPackage = packageNames[selectedPackage];
		setPaymentDetails((prev) => {
			return {
				...prev,
				amount: dprozPackage["originalAmount"],
				packageDetail: dprozPackage["packageDetail"],
			};
		});
	}

	useEffect(() => {
		if (selectedPackage) {
			setCurrentStep(2);
			getAmount();
		}
	}, [selectedPackage]);

	useEffect(() => {
		if (location.state) {
			const amountString = location.state.amount;
			const amount = amountString.match(/(\d[\d,]*)/)?.[0].replace(/,/g, "");
			const days = location.state.selectedPackage === "GOLD" ? 60 : 365;
			setCurrentStep(2);
			setPaymentDetails((prev) => {
				return {
					...prev,
					amount: amount,
					packageDetail: `Upgrade to Dproz ${location.state.selectedPackage} package. ${days} days full-access`,
				};
			});
		}
	}, [location]);

	const resumeDataService = new ResumeDataService();

	function restoreOrderID() {
		if (!URLOrderID) {
			const storedOrderID = localStorage.getItem("orderID");
			if (storedOrderID) {
				const { orderID, paymentLink } = JSON.parse(storedOrderID);
				setOrderID(orderID);
				setPaymentLink(base64ToAscii(paymentLink));
				setCurrentStep(3);
			}
		}
	}

	useEffect(() => {
		restoreOrderID();
	}, []);

	function onSuccess(response) {
		setLoading(false);
		const { paymentLink, _id } = response.data;

		setOrderID(_id);
		localStorage.setItem(
			"orderID",
			JSON.stringify({
				orderID: _id,
				paymentLink: paymentLink,
			}),
		);

		const link = base64ToAscii(paymentLink);

		setPaymentLink(link);

		setCurrentStep(3);
	}
	const [errors, setErrors] = useState([]);

	function onError(error) {
		setLoading(false);
		const newError = error.message;
		Array.isArray(newError) ? setErrors(newError) : setErrors([newError]);
	}

	async function checkout(paymentDetails) {
		setLoading(true);
		await resumeDataService.createPaymentOrder(paymentDetails, onSuccess, onError);
	}

	return (
		<div className="row mt-5">
			<div className="col py-4 rounded-lg bg-white">
				{currentStep === 1 && (
					<SubscriptionOptions
						setSelectedPackage={setSelectedPackage}
						usePoints={usePoints}
						setUsePoints={setUsePoints}
					/>
				)}
				{currentStep === 2 && (
					<Checkout
						setCurrentStep={setCurrentStep}
						checkout={checkout}
						setPaymentDetails={setPaymentDetails}
						paymentDetails={paymentDetails}
						loading={loading}
						serverErrors={errors}
						selectedPackage={selectedPackage}
					/>
				)}
				{currentStep === 3 && (
					<div className="subscription">
						<h3 className="mb-3">Complete Payment to Activate Subscription</h3>
						<a
							href={paymentLink}
							target="_blank"
							rel="noopener noreferrer"
							className="card text-dark px-3 py-2 card-body border-secondary mb-3 d-flex flex-row align-items-center justify-content-between"
						>
							<div className="p-0 m-0 w-75">
								<p className="font-weight-bold mb-1">Complete Payment</p>
								<p>
									When you press &#34;Complete Payment&#34; you will be redirected to a payment gateway to complete your
									payment.
								</p>
							</div>
							<i className="fa-solid fa-chevron-right" />
						</a>
						<div className="card card-body px-3 py-2 border-secondary mb-3 d-flex flex-row align-items-center justify-content-between">
							<div className="m-0 p-0 w-75">
								<p className="font-weight-bold mb-1">Activate Subscription</p>
								<p>
									Once you have completed your payment, you will be redirected back to this page to verify your
									subscription.
								</p>
							</div>
							<i className="fa-solid fa-chevron-right" />
						</div>

						<p className="font-weight-bold">
							When you activate your subscription you can:
							<ul className="list-unstyled mt-3 font-weight-normal">
								<li>
									<div
										className="bg-light p-2 rounded mb-2"
										style={{
											width: "fit-content",
										}}
									>
										<i className="fa-solid fa-circle-check text-success mr-2" />
										<span>Download and share your resume.</span>
									</div>
								</li>
								<li>
									<div
										className="bg-light p-2 rounded mb-2"
										style={{
											width: "fit-content",
										}}
									>
										<i className="fa-solid fa-circle-check text-success mr-2" />
										Easily duplicate your resume to tailor it to the job you are applying for
									</div>
								</li>
								<li>
									<div
										className="bg-light p-2 rounded mb-2"
										style={{
											width: "fit-content",
										}}
									>
										<i className="fa-solid fa-circle-check text-success mr-2" />
										Get Systematic Completeness checks as you are building your resume.
									</div>
								</li>
							</ul>
						</p>

						<div className="text-right mt-4">
							<a className="btn btn-primary" href={paymentLink} target="_blank" rel="noopener noreferrer">
								Complete Payment
							</a>
						</div>
					</div>
				)}
				{currentStep >= 4 && (
					<PaymentComplete
						orderID={orderID}
						loading={loading}
						setLoading={setLoading}
						orderData={orderData}
						setOrderData={setOrderData}
						setCurrentStep={setCurrentStep}
					/>
				)}
			</div>
		</div>
	);
}

export default PaymentFlow;
