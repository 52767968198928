import React from "react";
import { Modal } from "react-bootstrap";

function DeleteModal({
	showDeleteDialog,
	handleCloseDeleteDialog,
	setShowDeleteDialog,
	selectedCompany,
	setErrors,
	handleDeleteSelectedCompany,
}) {
	return (
		<Modal show={showDeleteDialog} onHide={handleCloseDeleteDialog} centered size="md" backdrop="static">
			<Modal.Header>
				<Modal.Title>Delete Alert</Modal.Title>
				<button
					type="button"
					className="btn btn-secondary text-center"
					data-dismiss="modal"
					aria-label="Close"
					onClick={() => setShowDeleteDialog(false)}
				>
					<i className="fa fa-close ml-2" />
				</button>
			</Modal.Header>
			<Modal.Body>
				Are you sure you want to delete <strong>{selectedCompany?.companyName}</strong>
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex justify-content-end">
					<button
						type="button"
						className="btn btn-secondary mx-2"
						data-dismiss="modal"
						onClick={() => {
							setShowDeleteDialog(false);
							setErrors([]);
						}}
					>
						Cancel
					</button>

					<button
						type="button"
						className="btn btn-primary"
						onClick={() => {
							handleDeleteSelectedCompany();
							setShowDeleteDialog(false);
						}}
					>
						Delete
					</button>
				</div>
			</Modal.Footer>
		</Modal>
	);
}

export default DeleteModal;
