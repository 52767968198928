import "crypto-js/lib-typedarrays";

import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import poolData from "../../../../userpool";
import ErrorToast from "../../../components/ErrorToast";

const userPool = new CognitoUserPool(poolData);

function ForgotPasswordScreen() {
	const [userData, setUserData] = useState({
		email: "",
	});

	const navigate = useNavigate();

	const { email } = userData;

	function onChange(event) {
		setUserData({
			...userData,
			[event.target.name]: event.target.value,
		});
	}

	const [error, setError] = useState("");
	useEffect(() => {}, [error]);

	const [loading, setLoading] = useState(false);
	useEffect(() => {}, [loading]);

	function handleForgotPassword(event) {
		event.preventDefault();
		setError("");
		setLoading(true);

		if (email.length === 0) {
			setError("Please enter your email address");
			setLoading(false);
			return;
		}

		let userData = {
			Username: email,
			Pool: userPool,
		};

		let cognitoUser = new CognitoUser(userData);

		cognitoUser.forgotPassword({
			onSuccess: function () {
				toast.success("Password reset code sent to your email address");
			},
			onFailure: function (err) {
				if ({ ...err }["code"] === "UserNotFoundException") {
					setError("User not found");
				}
			},
			inputVerificationCode: function () {
				const verificationCode = prompt("Please input verification code: ", "");
				const newPassword = prompt("New password: ", "");
				cognitoUser.confirmPassword(verificationCode, newPassword, {
					onSuccess: function () {
						navigate("/signin");
					},
					onFailure: function (error) {
						setError(error.message);
					},
				});
			},
		});
		setLoading(false);
	}
	return (
		<div className="tr-account section-padding">
			<div className="container text-center">
				<div className="user-account">
					<div className="account-content">
						<img
							src="/images/logo.png"
							alt="DProz Logo"
							className="p-2 mb-4"
							style={{
								width: "240px",
							}}
						/>
						{error !== "" && <ErrorToast error={error} />}
						<form action="#" className="tr-form">
							<div className="form-group">
								<input
									type="email"
									className="form-control"
									placeholder="Please Enter Your Email"
									name="email"
									onChange={onChange}
									value={email}
								/>
							</div>
							<button className="btn btn-primary tex-white" onClick={handleForgotPassword}>
								Confirm Email
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ForgotPasswordScreen;
