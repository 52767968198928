import additionalInformation from "./additionalInformation";
import affiliations from "./affiliations";
import certifications from "./certifications";
import contactInformation from "./contactInformation";
import educationHistory from "./educationHistory";
import extras from "./extras";
import languages from "./languages";
import personalLinks from "./personalLinks";
import personalProfile from "./personalProfile";
import professionalSummary from "./professionalSummary";
import referees from "./referees";
import skills from "./skills";
import workHistory from "./workHistory";

export default {
	contactInformation,
	professionalSummary,
	certifications,
	languages,
	personalLinks,
	affiliations,
	additionalInformation,
	referees,
	skills,
	personalProfile,
	extras,
	workHistory,
	educationHistory,
};
