import React, { createRef, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Icon from "../../components/Icon";

export function PricingDetailsModal({ setShowDetailsModal }) {
	const modalDetailsRef = createRef();

	useEffect(() => {
		function handleClickOutside(event) {
			if (modalDetailsRef.current && !modalDetailsRef.current.contains(event.target)) {
				setShowDetailsModal(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [modalDetailsRef]);

	const showAlt = useMemo(() => {
		return !window.location.pathname.includes("expert-help");
	}, [window.location.pathname]);

	return (
		<div className="modal d-flex align-items-center justify-content-center">
			<div
				className="p-4 bg-white rounded-lg"
				style={{
					maxWidth: "640px",
				}}
				ref={modalDetailsRef}
			>
				<div className="mb-4 d-flex justify-content-between">
					{showAlt ? (
						<div>
							<h3 className="fs-28 text-gray-950">How does this work?</h3>
						</div>
					) : (
						<div>
							<h3 className="fs-28 text-gray-950">We’ll take it from here!</h3>
							<p className="text-gray-700 fs-16 font-weight-semi">Here’s what happens next.</p>
						</div>
					)}
					<button onClick={() => setShowDetailsModal(false)}>
						<Icon iconName="close" size={20} />
					</button>
				</div>
				<div className="">
					<div className="d-flex mb-4">
						<Icon iconName="expert" size={48} className="mr-3" />
						<div>
							<p className="text-gray-950 font-weight-semi">Expert Assignment</p>
							<p className="text-gray-700">
								Within 24 hours of your purchase, a dedicated expert writer will be assigned to your resume project.
								They will carefully review your information and start crafting a tailored resume for your profile.
							</p>
						</div>
					</div>
					<div className="d-flex mb-4">
						<Icon iconName="expert" size={48} className="mr-3" />
						<div>
							<p className="text-gray-950 font-weight-semi">Initial Consultation</p>
							<p className="text-gray-700">
								If our expert requires additional information, they will reach out to you within 1-2 business days to
								schedule a convenient time for your first consultation and discuss your requirements.
							</p>
						</div>
					</div>
					<div className="d-flex mb-4">
						<Icon iconName="expert" size={48} className="mr-3" />
						<div>
							<p className="text-gray-950 font-weight-semi">Resume Drafting</p>
							<p className="text-gray-700">
								Using the insights gathered from your initial consultation and the information you provided during the
								onboarding process, your expert writer will carefully draft your new resume. x
							</p>
						</div>
					</div>
					<div className="d-flex mb-4">
						<Icon iconName="expert" size={48} className="mr-3" />
						<div>
							<p className="text-gray-950 font-weight-semi">Revisions and Feedback</p>
							<p className="text-gray-700">
								Once the initial draft of your resume is ready, your expert writer will send it to you for review.
								You&#39;ll have the opportunity to provide feedback and request revisions based on your package&#39;s
								revision policy.
							</p>
						</div>
					</div>
					<div className="d-flex mb-4">
						<Icon iconName="expert" size={48} className="mr-3" />
						<div>
							<p className="text-gray-950 font-weight-semi">Final Delivery</p>
							<p className="text-gray-700">
								After completing any necessary revisions, your expert writer will prepare the final version of your
								resume. You will receive your new, professionally crafted resume in the agreed-upon format (e.g., PDF,
								Word).
							</p>
						</div>
					</div>
					{showAlt && (
						<Link to="/expert-help/select-resume" className="btn btn-blue">
							Yes, I want Expert help
						</Link>
					)}
				</div>
			</div>
		</div>
	);
}

function Pricing() {
	const navigate = useNavigate();

	const [showDetailsModal, setShowDetailsModal] = useState(false);
	return (
		<section>
			<div className="expert-pricing-sidenav">
				<button onClick={() => navigate("/")} className="bg-gray-100 circle">
					<Icon iconName="close" size={20} />
				</button>
				<div className="resume-order-item">
					<p className="text-gray-950 font-weight-medium">James Smith</p>
					<p className="text-gray-400">james.smith@gmail.com</p>
					<p className="text-gray-400">255 712 502 400</p>

					<span className="w-fit-content mb-4 d-flex align-items-center mt-3 bg-gray-50 rounded-pill px-3 py-2 font-weight-medium text-gray-700">
						<Icon iconName="expert" size={16} className="mr-2" /> james-smith-resume.pdf
					</span>

					<hr className="w-100" />

					<Link to="/expert-help/personal-info" className="mt-3">
						<p className="text-blue font-weight-semi cursor-pointer">Edit your information</p>
					</Link>
				</div>
			</div>
			<div className="expert-pricing-content">
				<div className="container px-5">
					<div className="text-center mb-5">
						<h1 className="fs-28 font-weight-bold text-gray-950">Choose the package that best fits your goals</h1>
						<p className="text-gray-700 fs-16 font-weight-semi">
							Our expert writers will craft a compelling resume that showcases your unique strengths and helps you stand
							out in a competitive job market.
						</p>
					</div>
					<div className="row">
						<div className="col-md-4">
							<div className="expert-pricing-card">
								<p className="fs-16 text-gray-950">Starter</p>
								<p className="text-gray-700 mb-4">
									Perfect for entry-level professionals or those seeking a basic resume refresh.
								</p>
								<hr className="dashed" />
								<div className="my-4">
									<p className="text-gray-700">Tsh</p>
									<h2 className="text-gray-950 fs-40 font-weight-medium">1,950.00</h2>
								</div>
								<button className="btn btn-blue rounded-pill">Get Started</button>
								<ul className="mt-3 ul-circle-check">
									<li>Professional resume writing</li>
									<li>Keyword optimization</li>
									<li>1 round of revisions</li>
									<li>7-day turnaround</li>
								</ul>
							</div>
						</div>
						<div className="col-md-4">
							<div className="expert-pricing-card active">
								<p className="fs-16 text-gray-950">Career Boost</p>
								<p className="text-gray-700 mb-4">
									Ideal for job seekers looking to take their application to the next level.
								</p>
								<hr className="dashed" />
								<div className="my-4">
									<p className="text-gray-700">Tsh</p>
									<h2 className="text-gray-950 fs-40 font-weight-medium">5,850.00</h2>
								</div>
								<button className="btn btn-blue rounded-pill">Get Started</button>
								<ul className="mt-3 ul-circle-check">
									<li>Professional resume writing</li>
									<li>Keyword optimization</li>
									<li>Cover letter writing</li>
									<li>LinkedIn profile optimization</li>
									<li>2 rounds of revisions</li>
									<li>5-day turnarounds</li>
								</ul>
							</div>
						</div>
						<div className="col-md-4">
							<div className="expert-pricing-card">
								<p className="fs-16 text-gray-950">Executive Edge</p>
								<p className="text-gray-700 mb-4">Our premier package for senior-level professionals and executives.</p>
								<hr className="dashed" />
								<div className="my-4">
									<p className="text-gray-700">Tsh</p>
									<h2 className="text-gray-950 fs-40 font-weight-medium">51,500.00</h2>
								</div>
								<button className="btn btn-blue rounded-pill">Get Started</button>
								<ul className="mt-3 ul-circle-check">
									<li>Professional resume writing</li>
									<li>Keyword optimization</li>
									<li>Cover letter writing</li>
									<li>LinkedIn profile optimization</li>
									<li>Interview coaching session</li>
									<li>Unlimited revisions</li>
									<li>3-day turnarounds</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="mt-4 text-center">
						<p className="secondary-link" onClick={() => setShowDetailsModal(true)}>
							What to expect after you choose a package
						</p>
					</div>
				</div>
			</div>
			{showDetailsModal && <PricingDetailsModal setShowDetailsModal={setShowDetailsModal} />}
		</section>
	);
}

export default Pricing;
