const accomplishmentListSuggestions = [
	{
		id: 1,
		title: "Developed and implemented a new marketing strategy that increased sales by 20%.",
		added: false,
	},
	{
		id: 2,
		title: "Led a successful team of 10 people to complete a complex project on time and within budget.",
		added: false,
	},
	{
		id: 3,
		title: "Developed and launched a new product that generated $500,000 in revenue in its first year.",
		added: false,
	},
	{
		id: 4,
		title: "Negotiated a successful contract with a major client, resulting in a 50% increase in revenue.",
		added: false,
	},
	{
		id: 5,
		title: "Achieved a 95% customer satisfaction rating through improved customer service processes.",
		added: false,
	},
	{
		id: 6,
		title: "Successfully trained and mentored new employees, resulting in a 30% increase in productivity.",
		added: false,
	},
	{
		id: 7,
		title: "Implemented new cost-saving measures that reduced expenses by 10%.",
		added: false,
	},
	{
		id: 8,
		title: "Developed and maintained strong relationships with key clients, resulting in repeat business.",
		added: false,
	},
	{
		id: 9,
		title: "Managed a successful social media campaign that increased brand awareness by 30%.",
		added: false,
	},
	{
		id: 10,
		title: "Conducted market research and analysis to identify new opportunities for growth.",
		added: false,
	},
	{
		id: 11,
		title: "Received an award or recognition for exceptional performance or achievement.",
		added: false,
	},
	{
		id: 12,
		title: "Implemented new systems or processes that improved efficiency and productivity.",
		added: false,
	},
	{
		id: 13,
		title: "Developed and executed a successful fundraising campaign that exceeded targets.",
		added: false,
	},
	{
		id: 14,
		title: "Consistently met or exceeded sales targets over a period of time.",
		added: false,
	},
	{
		id: 15,
		title: "Improved customer retention rates through targeted loyalty programs or initiatives.",
		added: false,
	},
	{
		id: 16,
		title: "Developed and delivered successful training programs for employees or clients.",
		added: false,
	},
	{
		id: 17,
		title: "Led successful negotiations with suppliers or vendors, resulting in cost savings or improved quality.",
		added: false,
	},
	{
		id: 18,
		title: "Created a positive company culture through leadership or team-building initiatives.",
		added: false,
	},
	{
		id: 19,
		title: "Implemented successful environmental or sustainability initiatives.",
		added: false,
	},
	{
		id: 20,
		title: "Successfully launched a new website or digital platform.",
		added: false,
	},
	{
		id: 21,
		title: "Achieved a high level of accuracy or quality in work or project deliverables.",
		added: false,
	},
	{
		id: 22,
		title: "Developed and implemented successful diversity and inclusion initiatives.",
		added: false,
	},
	{
		id: 23,
		title: "Successfully managed a large-scale event or project with multiple stakeholders.",
		added: false,
	},
	{
		id: 24,
		title: "Developed and executed successful PR campaigns that generated positive media coverage.",
		added: false,
	},
	{
		id: 25,
		title: "Successfully resolved complex customer complaints or issues.",
		added: false,
	},
	{
		id: 26,
		title: "Achieved a high level of employee engagement or satisfaction through effective leadership.",
		added: false,
	},
	{
		id: 27,
		title: "Developed and executed successful crisis management plans or strategies.",
		added: false,
	},
	{
		id: 28,
		title: "Successfully implemented a new software system or technology.",
		added: false,
	},
	{
		id: 29,
		title: "Developed and executed successful influencer marketing campaigns.",
		added: false,
	},
	{
		id: 30,
		title: "Achieved a high level of safety or compliance in a hazardous or regulated industry.",
		added: false,
	},
	{
		id: 31,
		title: "Implemented successful supply chain management strategies.",
		added: false,
	},
	{
		id: 32,
		title: "Successfully managed and resolved conflicts between team members or stakeholders.",
		added: false,
	},
	{
		id: 33,
		title: "Achieved high levels of customer loyalty or retention through targeted initiatives.",
		added: false,
	},
	{
		id: 34,
		title: "Implemented successful data-driven marketing campaigns.",
		added: false,
	},
	{
		id: 35,
		title: "Developed and executed successful community engagement programs.",
		added: false,
	},
	{
		id: 36,
		title: "Achieved a high level of client satisfaction through effective communication and service delivery.",
		added: false,
	},
	{
		id: 37,
		title: "Successfully led a team through a major change or transition.",
		added: false,
	},
	{
		id: 38,
		title: "Developed and implemented successful talent acquisition or retention strategies.",
		added: false,
	},
	{
		id: 39,
		title: "Successfully managed a crisis or emergency situation.",
		added: false,
	},
	{
		id: 40,
		title: "Achieved a high level of employee retention through effective engagement and development programs.",
		added: false,
	},
	{
		id: 41,
		title: "Developed and executed successful email marketing campaigns.",
		added: false,
	},
	{
		id: 42,
		title: "Achieved a high level of process improvement through successful Six Sigma or Lean methodologies.",
		added: false,
	},
	{
		id: 43,
		title: "Successfully managed large-scale budgets or financial forecasts.",
		added: false,
	},
	{
		id: 44,
		title: "Implemented successful business continuity plans or strategies.",
		added: false,
	},
	{
		id: 45,
		title: "Achieved high levels of employee productivity through effective training and development.",
		added: false,
	},
	{
		id: 46,
		title: "Successfully managed international projects or teams.",
		added: false,
	},
	{
		id: 47,
		title: "Developed and executed successful B2B marketing strategies.",
		added: false,
	},
	{
		id: 48,
		title: "Achieved high levels of supplier or vendor performance through effective management and communication.",
		added: false,
	},
	{
		id: 49,
		title: "Successfully led cross-functional teams or initiatives.",
		added: false,
	},
	{
		id: 50,
		title:
			"Achieved high levels of customer advocacy or referral through effective communication and service delivery.",
		added: false,
	},
];

export default accomplishmentListSuggestions;
