import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import ExpertResumesLayout from "../../../components/ExpertResumesLayout/ExpertResumesLayout";
import Icon from "../../../components/Icon";
import Loader from "../../../components/Loader";
import Spinner from "../../../components/Spinner";
import StatusToast from "../../../components/StatusToast";
import InputField from "../../../components/UIComponents/InputField";
import ResumeContext from "../../../context/ResumeContext";
import { NavigationHandler } from "../../../handlers/navigationHandler";
import { getMyResumesRoutes } from "../../../handlers/navigationItems";
import { dateToDMY, logToConsole } from "../../../handlers/utilityFunctions";
import { resumeObjectToContextFormat } from "../../../handlers/valueTransformation";
import useMyResumesUtilities from "../../../hooks/use-MyResumesUtilities";
import useSetResumeIdCookie from "../../../hooks/use-SetResumeIdCookie";
import { StaticValue } from "../../../seed/constants";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import DownloadResumeModal from "../DownloadResumeModal";
import ResumeReviewModal from "../ResumeListItem/ResumeReviewModal";
import ResumeProgressItem from "./components/ResumeProgressItem";

function MyResumes() {
	const navigate = useNavigate();
	const staticValue = new StaticValue();
	const [, , removeCookie] = useCookies();

	const storedResumes = localStorage.getItem("resumeList");
	const storedProfile = localStorage.getItem("profileData");

	const resumeDataService = new ResumeDataService();
	const [resumeFeedbackRequests, setResumeFeedbackRequests] = useState([]);
	const [reviewFeedbackDetails, setReviewFeedbackDetails] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingFeedback, setLoadingFeedback] = useState(false);
	const [loadingResumeReview, setLoadingResumeReview] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const { resumeData, setResumeData } = useContext(ResumeContext);
	const {
		cookie: { resume_id: activeResumeId },
		setResumeIdCookie,
	} = useSetResumeIdCookie();
	const dispatch = useDispatch();
	const [reviews, setReviews] = useState([]);
	const [resume, setResume] = useState({});

	const navigationHandler = new NavigationHandler();
	const [reviewersCount, setReviewersCount] = useState({
		reviewersWithFeedBack: 0,
		totalReviewers: 0,
	});

	const dispatchAddExtras = (payload) => {
		dispatch({ type: "ADD_EXTRAS", payload: payload });
	};

	const [resumeList, setResumeList] = useState([]);

	function fetchResumeData(resumeId) {
		resumeDataService.getResumeLedgerById(
			resumeId,
			(resume) => {
				resumeObjectToContextFormat(resume.data, setResumeData, dispatchAddExtras);
				dispatch({ type: "RESUME_DATA", payload: resume.data });
				setLoading(false);
			},
			logToConsole(error),
		);
	}

	function onSuccess(response) {
		if ([...response.data].length === 0) {
			setResumeList([]);
		} else {
			const resumes = response.data;
			setResumeList(resumes);
			localStorage.setItem("resumeList", JSON.stringify(resumes));

			// sort by resume.journal.lastUpdatedOn
			const sortedByLastUpdated = [...resumes].sort((a, b) => {
				return new Date(b.journal.lastUpdatedOn) - new Date(a.journal.lastUpdatedOn);
			});

			const lastUpdatedResume = sortedByLastUpdated[0];

			setResumeIdCookie(lastUpdatedResume.id);
			fetchResumeData(lastUpdatedResume.id);
		}
	}

	const [error, setError] = useState("");

	function onError(error) {
		setError(error.message);
		setLoading(false);
	}

	const fetchResumeList = async () => {
		if (storedResumes) {
			const storedResumesData = JSON.parse(storedResumes);
			setResumeList(storedResumesData);

			const activeResume = activeResumeId
				? storedResumesData.find((resume) => resume.id === activeResumeId)
				: storedResumesData[0];

			setLoading(true);
			fetchResumeData(activeResume.id);
		} else {
			await resumeDataService.getUserResumes(onSuccess, onError);
		}
	};

	useEffect(() => {
		if (resumeList.length === 0) {
			setLoading(true);
			fetchResumeList();
		}
	}, [resumeList]);

	function onDuplicateError(error) {
		if (error.response.data.message[0]) {
			setError(error.response.data.message[0]);
		} else {
			setError("Failed to duplicate review something went wrong");
		}
		setLoading(false);
	}

	useEffect(() => {}, [error]);

	let currentLocation = window.location.href;
	const { downloadResumeModal, downloadResume, showResumePrintPopup, downloadLoading } = useMyResumesUtilities(resume);

	function getFeedbackRequests(requestsData) {
		setLoadingFeedback(true);

		let _resumeFeedbackRequests = [];

		//get all the requests that are published and are related with the current resume
		_resumeFeedbackRequests = requestsData.filter((requestData) => {
			return requestData.resumeLedgerReferenceId === resumeData.id && requestData.status === "PUBLISHED";
		});

		//if there are any requests
		if (_resumeFeedbackRequests.length > 0) {
			//get the latest request to be created
			const lastUpdatedRequest = _resumeFeedbackRequests.reduce((latestUpdatedRequest, _resumeFeedbackRequest) => {
				// Convert lastUpdatedOn strings to Date objects for comparison
				const currentUpdatedAt = new Date(_resumeFeedbackRequests.lastUpdatedOn);
				const maxUpdatedAt = new Date(latestUpdatedRequest.lastUpdatedOn);

				// Compare lastUpdatedOn values
				if (currentUpdatedAt > maxUpdatedAt) {
					return _resumeFeedbackRequest;
				} else {
					return latestUpdatedRequest;
				}
			}, _resumeFeedbackRequests[0]);

			//if the latest request has any reviewer details
			setActiveReviewFeedback(lastUpdatedRequest);
		} else {
			setReviewFeedbackDetails([]);
		}

		setResumeFeedbackRequests(_resumeFeedbackRequests);
		setLoadingFeedback(false);
	}

	function setActiveReviewFeedback(reviewFeedback) {
		let _reviewFeedbackDetails = [];

		if (reviewFeedback.reviewerReviewersDetail.length > 0) {
			//for every reviewer details
			reviewFeedback.reviewerReviewersDetail.forEach((reviewerReviewer) => {
				//if the reviewer has any reviews
				if (reviewerReviewer.reviewLog.length > 0) {
					//reduce the reviews to single review per section
					const logs = reviewerReviewer.reviewLog.reduce((accumulator, current) => {
						// Check if the section has already appeared in the accumulator
						const sectionAlreadyAppeared = accumulator.some((item) => item.section === current.section);

						// If the section hasn't appeared, add it to the accumulator
						if (!sectionAlreadyAppeared) {
							accumulator.push(current);
						}

						return accumulator;
					}, []);

					//if there is no any section in the results array
					if (_reviewFeedbackDetails.length === 0) {
						logs.forEach((log) => {
							_reviewFeedbackDetails.push({
								section: log.section,
								sectionCount: 1,
							});
						});

						return;
					}

					//if there are results in the results array
					logs.forEach((log) => {
						//find if a section is present by index
						const sectionIndex = _reviewFeedbackDetails.findIndex(
							(_reviewFeedbackDetail) => _reviewFeedbackDetail.section === log.section,
						);

						if (sectionIndex === -1) {
							//The section is not present
							_reviewFeedbackDetails.push({
								section: log.section,
								sectionCount: 1,
							});
						} else {
							//The section is present
							_reviewFeedbackDetails[sectionIndex]["sectionCount"] =
								_reviewFeedbackDetails[sectionIndex]["sectionCount"] + 1;
						}
					});
				}
			});
		}

		const _totalReviewers = reviewFeedback.reviewerReviewersDetail.length;
		const _reviewersWithComments = reviewFeedback.reviewerReviewersDetail.filter(
			(reviewerReviewer) => reviewerReviewer.reviewLog.length > 0,
		).length;

		setReviewersCount({
			reviewersWithFeedBack: _reviewersWithComments,
			totalReviewers: _totalReviewers,
		});
		setReviewFeedbackDetails(_reviewFeedbackDetails);
	}

	function deleteResumeReview(id) {
		setDeleteLoading(true);
		resumeDataService.deleteResumeReview(
			id,
			() => {
				setDeleteLoading(false);
				toast.success("Review deleted successfully");
				localStorage.removeItem("reviewsList");
				fetchReviews();
			},
			(data) => {
				setDeleteLoading(false);
				toast.error(data.response.data.message);
			},
		);
	}

	function fetchReviews() {
		setLoadingResumeReview(true);

		if (localStorage.getItem("reviewsList")) {
			const _data = JSON.parse(localStorage.getItem("reviewsList"));
			setReviews(_data);
			getFeedbackRequests(_data);
			setLoadingResumeReview(false);
		} else {
			resumeDataService.getResumeReview(
				(data) => {
					localStorage.removeItem("reviewsList");
					localStorage.setItem("reviewsList", JSON.stringify(data.data));
					setReviews(data.data);
					getFeedbackRequests(data.data);
					setLoadingResumeReview(false);
				},
				() => {
					setLoadingResumeReview(false);
					toast.error("Error in fetching resume resume reviews!");
				},
			);
		}
	}

	function handleOncloseReviewModal(data) {
		if (data > 1) fetchReviews();
		setShowReviewModal(false);
	}

	useEffect(() => {
		if (resumeData?.resumeTitle) {
			fetchReviews();
		}
	}, [resumeData]);

	const [newResumeModal, setNewResumeModal] = useState(false);
	const [selectedResumeID, setSelectedResumeID] = useState(null);
	const [resumeTitle, setResumeTitle] = useState("");

	const createNewResume = () => {
		removeCookie(`${staticValue.localStorageResumeId}`, { path: "/" });
		window.location.replace("/resume/select-experience");
	};

	const successDuplicate = (response) => {
		localStorage.removeItem("resumeList");
		fetchResumeList();
		setResumeIdCookie(response.data.id);
		setNewResumeModal(false);
		setLoading(false);
		window.location.replace("/my-resumes"); //we will have to re-use the resume that we&#39;ve received instead of calling again
	};

	const duplicateResume = (e) => {
		e.preventDefault();
		setLoading(true);
		if (!selectedResumeID) {
			alert("Please select a resume to duplicate");
			return;
		}

		resumeDataService.duplicateResume(
			{
				sourceJournalId: selectedResumeID,
				resumeTitle: resumeTitle,
			},
			successDuplicate,
			onDuplicateError,
		);
	};

	useEffect(() => {
		fetchResumeList();
	}, []);

	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [screenWidth]);

	const [, setShowReviews] = useState(() => {
		return currentLocation.includes("feedback=active");
	});
	//if ?feedback=active is in the url, show the reviews tab
	const urlParams = new URLSearchParams(window.location.search);

	function click(url) {
		localStorage.setItem("coming-from-review", "Y");
		navigationHandler.setFinalize(url);
	}

	const onSelectResume = (resumeId) => {
		setLoading(true);
		setResumeIdCookie(resumeId);
		fetchResumeData(resumeId);
	};

	const onDownloadResume = () => {
		const _resume = resumeList.find((resume) => resume._id === resumeData.id);
		setResume(_resume);
		downloadResume(_resume.id);
	};

	useEffect(() => {
		const feedback = urlParams.get("feedback");
		if (feedback === "active") {
			setShowReviews(true);
		} else {
			setShowReviews(false);
		}
	}, [urlParams]);

	const [showReviewModal, setShowReviewModal] = useState(false);
	const [startFeedbackRequest, setStartFeedbackRequest] = useState({
		selectedResume: "",
		showModal: false,
		review: null,
		initialCurrentStep: 1,
	});
	const [successMessage, setSuccessMessage] = useState();

	const [completionData, setCompletionData] = useState([]);
	useEffect(() => {
		const data = [
			{
				section: "contact-information",
				title: "Personal Information",
				progressStatus: resumeData.progressStatus.HEADING,
			},
			{
				section: "work-history-summary",
				title: "Work History",
				progressStatus: resumeData.progressStatus.EXPERIENCE,
			},
			{
				section: "education-summary",
				title: "Education",
				progressStatus: resumeData.progressStatus.EDUCATION,
			},
			{
				section: "skills",
				title: "Skills",
				progressStatus: resumeData.progressStatus.SKILLS,
			},
			{
				section: "brief-summary",
				title: "Professional Summary",
				progressStatus: resumeData.progressStatus.SUMMARY,
			},
			[...resumeData.extras.personalLinks].length > 0
				? {
						section: "personal-links",
						noClick: true,
						title: "Personal Links",
						progressStatus: "COMPLETED",
					}
				: null,
			resumeData.extras.accomplishments
				? {
						noClick: true,
						section: "accomplishments",
						title: "Accomplishments",
						progressStatus: "COMPLETED",
					}
				: null,
			resumeData.extras.affiliations
				? {
						noClick: true,
						section: "affiliations",
						title: "Affiliations",
						progressStatus: "COMPLETED",
					}
				: null,
			resumeData.extras.certifications
				? {
						section: "certifications",
						noClick: true,
						title: "Certifications",
						progressStatus: "COMPLETED",
					}
				: null,
			[...resumeData.extras.languages].length > 0
				? {
						noClick: true,
						section: "languages",
						title: "Languages",
						progressStatus: "COMPLETED",
					}
				: null,
			resumeData.extras.additionalInfo
				? {
						section: "additional-info",
						noClick: true,
						title: "Additional Information",
						progressStatus: "COMPLETED",
					}
				: null,
			[...resumeData.extras.references].length > 0
				? {
						section: "reference-summary",
						noClick: true,
						title: "References",
						progressStatus: "COMPLETED",
					}
				: null,
			resumeData.extras.customExtras.data !== ""
				? {
						section: "custom-extra/" + resumeData.extras.customExtras.name,
						title: resumeData.extras.customExtras.name,
						progressStatus: resumeData.extras.customExtras.data !== "" ? "COMPLETED" : "INCOMPLETE",
					}
				: null,
		];

		// remove null values
		const filteredData = data.filter((item) => item !== null);

		setCompletionData(filteredData);
	}, [resumeData]);

	useEffect(() => {}, [completionData, resumeData]);

	return (
		<div id="myResumes">
			<ExpertResumesLayout navItems={getMyResumesRoutes(dispatch)} headingTitle="My Resumes" showExpertDetails>
				<div className="font-inter mt-5" id="myResumes">
					{/* Download Resume modal*/}
					{showResumePrintPopup && downloadResumeModal()}

					<div className="container p-0">
						{loading ? (
							<div className="d-flex align-center justify-content-center">
								<Loader />
							</div>
						) : (
							<>
								{resumeList.length === 0 ? (
									<div
										style={{
											maxWidth: "644px",
										}}
										className="mx-auto"
									>
										<div
											className="d-flex align-items-center flex-column justify-content-center"
											style={{
												minHeight: "80vh",
											}}
										>
											<div className="mb-5">
												<img src="/images/empty-resumes.svg" alt="" />
											</div>
											<div className="row">
												<div className="col-4">
													<p className="m-0 font-weight-semi">Create resume</p>
													<p className="m-0">Build your resume on Dproz for a standout professional profile.</p>
												</div>
												<div className="col-4">
													<p className="m-0 font-weight-semi">Professional templates</p>
													<p className="m-0">Using ready templates, expertly crafted job-specific contents.</p>
												</div>
												<div className="col-4">
													<p className="m-0 font-weight-semi">Expert guidance</p>
													<p className="m-0">Eliminate guesswork by incorporating best practices by professionals.</p>
												</div>
											</div>
											<div className="">
												<Link to="/resume/select-experience" className="btn bg-primary text-white mt-5">
													Create your first resume
												</Link>
											</div>
										</div>
									</div>
								) : (
									<div className="pt-5">
										<div className="row">
											<div className="">
												<h2 className="mb-2">Welcome, {JSON.parse(storedProfile)?.firstName}</h2>
												<div className="dropdown align-items-start mb-4">
													<span
														className="border bg-white shadow-sm border-secondary dropdown-toggle px-3 py-2 rounded hide-toggle"
														data-toggle="dropdown"
														aria-expanded="false"
													>
														<div className="d-flex align-items-center">
															<Icon iconName="invoice" size={28} />
															<div className="mx-3">
																<p className="m-0 font-weight-semi">
																	{resumeData.journal.resumeTitle
																		? resumeData.journal.resumeTitle
																		: resumeData.resumeTitle}
																</p>
																<p className="m-0 fs-12 font-gray">
																	{`Last updated ${dateToDMY(resumeData.journal.lastUpdatedOn)}`}
																</p>
															</div>
															<i className="bi bi-code fs-12 text-secondary rotate-90" />
														</div>
													</span>
													<div
														className="dropdown-menu mt-2 shadow-sm rounded border-0 p-1"
														style={{ minWidth: "316px" }}
													>
														{resumeList.map((resume) => {
															return (
																<span
																	className="dropdown-item rounded px-3"
																	key={resume.id}
																	onClick={() => {
																		setResumeTitle(
																			resume.journal.resumeTitle
																				? resume.journal.resumeTitle
																				: resume.heading.resumeTitle,
																		);
																		onSelectResume(resume.id);
																	}}
																>
																	<p className="m-0 font-weight-semi">
																		{resume.journal.resumeTitle
																			? resume.journal.resumeTitle
																			: resume.heading.resumeTitle}
																	</p>
																	<p className="m-0 fs-12 font-gray">
																		{`Last updated ${dateToDMY(resume.journal.lastUpdatedOn)}`}
																	</p>
																</span>
															);
														})}
													</div>
												</div>
											</div>
										</div>
										{/* Header and dropdown */}

										{/* Reviews - Drafts */}
										{loadingResumeReview ? (
											<Spinner
												className="text-secondary d-flex"
												animation="border"
												role="status"
												size="lg"
												style={{ margin: "auto" }}
											/>
										) : (
											reviews.find((review) => review.status === "DRAFT") && (
												<div className="row rounded-md border p-4 bg-white mb-3">
													<div className="col-md-6 p-0">
														<p className="m-0 font-weight-semi fs-16">Feedback request in draft status</p>
													</div>
													<div className="resume-options col-md-6 p-0 d-flex justify-content-end align-items-center">
														{/* dropdown start */}
														<div>
															{deleteLoading ? (
																<Spinner className="text-secondary" animation="border" role="status" />
															) : (
																<button
																	className="border bg-transparent font-medium fs-14 lh-1 rounded font-inter btn-outline-danger text-danger"
																	type="button"
																	onClick={() =>
																		deleteResumeReview(reviews.find((review) => review.status === "DRAFT")._id)
																	}
																>
																	<span className="fa fa-trash-can text-danger mr-2" /> Delete
																</button>
															)}
														</div>
														{/* dropdown end */}
													</div>
													<div className="col-12 mt-3">
														<div className="row py-3 px-3 bg-lighter rounded mb-3">
															<div className="col-5 p-0 d-flex align-items-center">
																<div className="badge-circle badge-medium bg-success text-white mr-3">
																	<i className="fa fa-check" />
																</div>
																<p className="font-weight-semi m-0">Message</p>
															</div>
															<div className="col-7 p-0 d-flex justify-content-end align-items-center">
																<div className="mr-3">
																	<span className="badge badge-success text-white lh-1 fs-xs p-2">COMPLETE</span>
																</div>
																<div>
																	<button
																		className="border-0 bg-transparent py-0"
																		onClick={() => {
																			setStartFeedbackRequest({
																				selectedResume: resumeData,
																				showModal: true,
																				review: reviews.find((review) => review.status === "DRAFT"),
																				initialCurrentStep: 1,
																			});
																			setShowReviewModal(true);
																		}}
																	>
																		<i className="fa-solid fs-xs text-dark fa-chevron-right" />
																	</button>
																</div>
															</div>
														</div>
														<div className="row py-3 px-3 bg-lighter rounded mb-3">
															<div className="col-5 p-0">
																<div className="d-flex align-items-center">
																	<div className="badge-circle badge-medium bg-success text-white mr-3">
																		<i className="fa fa-check" />
																	</div>
																	<p className="font-weight-semi m-0">Reviewers</p>
																</div>
															</div>
															<div className="col-7 p-0 d-flex justify-content-end align-items-center">
																<div className="mr-3">
																	<span className="badge badge-success text-white lh-1 fs-xs p-2">COMPLETE</span>
																</div>
																<div>
																	<button
																		className="border-0 bg-transparent py-0"
																		onClick={() => {
																			setStartFeedbackRequest({
																				selectedResume: resumeData,
																				showModal: true,
																				review: reviews.find((review) => review.status === "DRAFT"),
																				initialCurrentStep: 2,
																			});
																			setShowReviewModal(true);
																		}}
																	>
																		<i className="fa-solid fs-xs text-dark fa-chevron-right" />
																	</button>
																</div>
															</div>
														</div>
														<div className="row py-3 px-3 bg-lighter rounded mb-3">
															<div className="col-5 p-0">
																<div className="d-flex align-items-center">
																	<div className="badge-circle badge-medium badge-light-blue text-dark fs-xs font-medium mr-3">
																		03
																	</div>
																	<p className="font-weight-semi m-0">Submit</p>
																</div>
															</div>
															<div className="col-7 p-0 d-flex justify-content-end align-items-center">
																<div className="mr-3">
																	<span className="badge badge-light lh-1 fs-xs p-2">NOT STARTED</span>
																</div>
																<div>
																	<button
																		className="border-0 bg-transparent py-0"
																		onClick={() => {
																			setStartFeedbackRequest({
																				selectedResume: resumeData,
																				showModal: true,
																				review: reviews.find((review) => review.status === "DRAFT"),
																				initialCurrentStep: 3,
																			});
																			setShowReviewModal(true);
																		}}
																	>
																		<i className="fa-solid fs-xs text-dark fa-chevron-right" />
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											)
										)}
										{/* Reviews - Drafts - End */}

										{/* Open Resume Details */}
										<div className={`row rounded-md border p-4 bg-white mb-3 ${loadingResumeReview ? "mt-5" : ""}`}>
											<div className="col-md-6 p-0">
												<p className="m-0 font-weight-semi fs-16">Your resume progress</p>
											</div>
											<div className="resume-options col-md-6 p-0 d-flex justify-content-end align-items-center flex-wrap">
												{/* Preview Button */}
												<button
													type="button"
													className="border border-secondary bg-transparent font-medium fs-14 lh-1 rounded font-inter mr-3"
													data-toggle="modal"
													data-target="#ResumePreview"
												>
													<Icon iconName="eye" className="mr-2" size={18} />
													Quick Preview
												</button>

												{/* Seek Feedback button*/}
												{resumeData.journal.currentStatus === "COMPLETED" && (
													<button
														type="button"
														className="btn bg-secondary rounded font-inter mr-3"
														data-toggle="modal"
														onClick={() => {
															setStartFeedbackRequest({
																selectedResume: resumeData,
																showModal: true,
																review: null,
																initialCurrentStep: 1,
															});
															setShowReviewModal(true);
														}}
													>
														Seek Feedback
													</button>
												)}

												<div className="d-flex">
													{/* Download Resume */}
													{resumeData.journal.currentStatus === "COMPLETED" && (
														<span
															title="Download"
															data-placement="top"
															data-toggle="modal"
															onClick={() => onDownloadResume()}
															className="d-flex px-2 mr-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
														>
															{downloadLoading ? (
																<Spinner
																	className="text-secondary"
																	animation="border"
																	role="status"
																	style={{ marginLeft: "2.9px" }}
																/>
															) : (
																<Icon iconName="download" className="mr-2" size={24} />
															)}
														</span>
													)}

													{/* Resume Dropdown */}
													<div className="dropdown">
														<button
															className="border-0 bg-transparent d-flex flex-column align-items-center"
															type="button"
															data-toggle="dropdown"
															aria-expanded="false"
														>
															<i className="text-secondary fs-20 fa fa-ellipsis-h" />
														</button>
														<div className="dropdown-menu rounded p-1 border-0 cursor-pointer dropdown-menu-right">
															<span onClick={() => navigate("/resume/review")} className="dropdown-item px-2">
																Detailed Preview
															</span>
															<span
																onClick={() =>
																	navigate(
																		`/resume/select-template?templateId=${resumeData.journal.templateId}&journalReferenceId=${resumeData.journal._id}`,
																	)
																}
																className="dropdown-item px-2"
															>
																Change Template
															</span>
															<span onClick={() => setNewResumeModal(true)} className="dropdown-item px-2">
																Duplicate Resume
															</span>
															{/* <span className="dropdown-item px-2">Remove Resume</span> */}
														</div>
													</div>
													{/* dropdown end */}
												</div>
											</div>
											<div className="col-12 p-0">
												<div className="mt-3 px-3 pb-0">
													{completionData.map((item, index) => (
														<ResumeProgressItem
															key={index}
															index={index + 1}
															click={click}
															progressStatus={item.progressStatus}
															section={item.section}
															title={item.title}
															noClick={item.noClick ? true : false}
														/>
													))}
												</div>
											</div>
										</div>
										{/* Open Resume Details */}

										{/* Reviews - Submitted requests */}
										{loadingFeedback ? (
											<Spinner
												className="text-secondary d-flex"
												animation="border"
												role="status"
												size="lg"
												style={{ margin: "auto" }}
											/>
										) : reviewFeedbackDetails.length > 0 ? (
											<div className="row rounded-md border p-4 bg-white mb-3">
												<div className="col-md-6 p-0">
													<p className="m-0 font-weight-semi fs-16">Review feedback.</p>
													<p className="m-0 text-secondary fs-small">
														{`${reviewersCount.reviewersWithFeedBack} of 
                                                        ${reviewersCount.totalReviewers} reviewers have given you feedback.`}
													</p>
												</div>
												<div className="resume-options col-md-6 p-0 d-flex justify-content-end align-items-center">
													{/* dropdown start */}
													<div className="dropdown">
														<button
															className="border border-secondary bg-transparent d-flex shadow-sm align-items-center rounded font-weight-semi fs-14 lh-1 font-inter"
															type="button"
															data-toggle="dropdown"
															aria-expanded="false"
														>
															Submitted requests
															<i className="text-secondary fs-16 ml-2 rotate-90 bi bi-code lh-1" />
														</button>
														<div className="dropdown-menu p-1 border-0 rounded dropdown-menu-right">
															{resumeFeedbackRequests.map((reviewFeedback) => (
																<span
																	className="dropdown-item px-2"
																	key={reviewFeedback._id}
																	onClick={() => setActiveReviewFeedback(reviewFeedback)}
																>
																	{`Submitted on ${dateToDMY(reviewFeedback.createdOn)}`}
																</span>
															))}
														</div>
													</div>
													{/* dropdown end */}
												</div>
												<div className="col-12 mt-3">
													{reviewFeedbackDetails.slice(0, 3).map((reviewFeedback, index, array) => {
														const isLastElement = index === array.length - 1;

														return (
															<Link to="/resume/review" key={index}>
																<div className={`row pt-2 pb-3 ${isLastElement ? "" : "border-bottom"} mb-3`}>
																	<div className="col-5 d-flex p-0">
																		<div>
																			<p className="font-weight-semi m-0">{`Improve ${reviewFeedback.section.toLowerCase()} section`}</p>
																		</div>
																	</div>
																	<div className="col-7 p-0 d-flex justify-content-end align-items-center">
																		<div className="mr-3">
																			<span className="badge badge-primary badge-pill lh-1 fs-xs p-2">
																				&#x2022; {`${reviewFeedback.sectionCount} new`}
																			</span>
																		</div>
																		<div>
																			<button className="border-0 bg-transparent py-0">
																				<i className="fa-solid fs-xs text-dark fa-chevron-right" />
																			</button>
																		</div>
																	</div>
																</div>
															</Link>
														);
													})}
												</div>
											</div>
										) : null}
										{/*Reviews - Submitted requests - End*/}
									</div>
								)}
							</>
						)}
					</div>
				</div>

				<DownloadResumeModal resume={resume} />

				{newResumeModal && (
					<div className="controlled-modal">
						<div
							className="modal-dialog"
							style={{
								maxWidth: "90vw",
								width: "700px",
							}}
						>
							{error !== "" && (
								<div className="alert alert-danger" role="alert">
									{error}
								</div>
							)}
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Creating a new resume made easy</h5>
									<button
										type="button"
										className="close"
										data-dismiss="modal"
										aria-label="Close"
										onClick={() => setNewResumeModal(false)}
									>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<p className="mb-2">Simply select any of your completed resumes to duplicate</p>
									<form>
										<div className="form-group">
											<select
												name="resume"
												className="form-control"
												onChange={(e) => {
													const value = e.target.value;
													const { resumeTitle, sourceJournalId } = JSON.parse(value);
													setResumeTitle(`Copy ${resumeTitle}`);
													setSelectedResumeID(sourceJournalId);
												}}
											>
												<option value="">Select a resume to duplicate</option>
												{resumeList.map((item, index) => {
													if (item.journal.currentStatus === "COMPLETED") {
														return (
															<option
																value={JSON.stringify({
																	resumeTitle: item.journal.resumeTitle
																		? item.journal.resumeTitle
																		: item.heading.resumeTitle,
																	sourceJournalId: item.journal._id,
																})}
																key={index}
															>
																{`${
																	item.journal.resumeTitle ? item.journal.resumeTitle : item.heading.resumeTitle
																} - Updated on 
															${dayjs(item.journal.lastUpdatedOn).format("DD MMMM, YYYY")}`}
															</option>
														);
													}
												})}
											</select>
										</div>
										<div className="form-group">
											<InputField
												type="text"
												id="resumeTitle"
												name="resumeTitle"
												placeholder="What do you want to call this resume?"
												value={resumeTitle}
												onChange={(e) => setResumeTitle(e.target.value)}
												label="Name your New Resume"
												error={null}
											/>
										</div>
									</form>
									<div className="d-flex justify-content-between">
										<div>
											{!selectedResumeID && (
												<button className="btn btn-secondary" onClick={createNewResume}>
													Create new resume from scratch
												</button>
											)}
										</div>
										<button
											className={selectedResumeID ? "btn btn-primary" : "btn btn-secondary disabled"}
											onClick={duplicateResume}
										>
											{loading ? <Spinner /> : "Copy & Create Resume"}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				{showReviewModal && (
					<div className="controlled-modal">
						{successMessage && <StatusToast message={successMessage} timeout={5000} />}

						{startFeedbackRequest.showModal && (
							<ResumeReviewModal
								resumeTitle={
									startFeedbackRequest.selectedResume.resumeTitle
										? startFeedbackRequest.selectedResume.resumeTitle
										: startFeedbackRequest.selectedResume.heading.resumeTitle
								}
								resumeID={startFeedbackRequest.selectedResume.id}
								setShowReviewModal={handleOncloseReviewModal}
								showReviewModal={() =>
									setStartFeedbackRequest({
										selectedResume: "",
										showModal: false,
										review: null,
										initialCurrentStep: 1,
									})
								}
								setSuccessMessage={setSuccessMessage}
								reviewData={startFeedbackRequest.review}
								initialCurrentStep={startFeedbackRequest.initialCurrentStep}
							/>
						)}
					</div>
				)}
			</ExpertResumesLayout>
		</div>
	);
}

export default MyResumes;
