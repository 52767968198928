import React from "react";
import { Modal } from "react-bootstrap";

import Accordion from "../../../components/Accordion";
import AutoSuggestion from "../../../components/AutoSuggestion";
import TextEditor from "../../../components/TextEditor";
import InputField from "../../../components/UIComponents/InputField";
import SelectField from "../../../components/UIComponents/SelectField";
import options from "../../../seed/dropDownOptions";
import mapping from "../../../seed/metadata/filemapping";

function CreateModal({
	showCreateCompanyDialog,
	handleCloseCreateCompanyDialog,
	setShowCreateCompanyDialog,
	errors,
	setCompanyDetails,
	companyDetails,
	submitPhase,
	address,
	whyWorkHere,
	setAddress,
	setWhyWorkHere,
	companyDescription,
	setCompanyContactInfo,
	setErrors,
	onSubmit,
}) {
	const locations = mapping["districtsRegions"][0].districtRegions;
	const country = "Tanzania";

	return (
		<Modal show={showCreateCompanyDialog} onHide={handleCloseCreateCompanyDialog} centered size="xl" backdrop="static">
			<Modal.Header>
				<Modal.Title>Create Company</Modal.Title>
				<button
					type="button"
					className="btn btn-secondary text-center"
					data-dismiss="modal"
					aria-label="Close"
					onClick={() => setShowCreateCompanyDialog(false)}
				>
					<i className="fa fa-close ml-2" />
				</button>
			</Modal.Header>
			<Modal.Body>
				<div>
					{errors.length > 0 && (
						<div className="alert alert-danger" role="alert">
							{errors}
						</div>
					)}
					<div className="form-group">
						<div className="mb-3">
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignContent: "flex-end",
								}}
							>
								<div style={{ width: "100%" }}>
									<label htmlFor="companyName" className="form-label">
										Company Name
									</label>

									<input
										type="text"
										className="form-control"
										onChange={(e) =>
											setCompanyDetails({
												...companyDetails,
												companyName: e.target.value,
											})
										}
										value={companyDetails.companyName}
										id="companyName"
									/>
									{errors.companyName && errors.companyName !== "" && (
										<div className="text-danger m-0">{errors.companyName}</div>
									)}
								</div>
								<div className="input-cross-tick-container">
									<i
										className={`fa text-input-cross-tick ${
											(submitPhase && !(errors.companyName && errors.companyName !== "")) ||
											(companyDetails.companyName && "fa-check text-success")
										}`}
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="mb-3  col-6">
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="companyIndustry" className="form-label">
											Company Industry
										</label>

										<input
											type="text"
											className="form-control"
											onChange={(e) =>
												setCompanyDetails({
													...companyDetails,
													companyIndustry: e.target.value,
												})
											}
											value={companyDetails.companyIndustry}
											id="companyIndustry"
										/>
										{errors.companyIndustry && errors.companyIndustry !== "" && (
											<div className="text-danger m-0">{errors.companyIndustry}</div>
										)}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.companyIndustry && errors.companyIndustry !== "")) ||
												(companyDetails.companyIndustry && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>

							<div className="mb-3 col">
								<label htmlFor="companySize" className="form-label">
									Company Size
								</label>
								<select
									className="form-select form-control"
									onChange={(e) =>
										setCompanyDetails({
											...companyDetails,
											companySize: e.target.value,
										})
									}
									value={companyDetails.companySize}
									id="companySize"
								>
									<option value="">Select Company Size</option>
									<option value="1-10">1 - 10</option>
									<option value="11-50">11 - 50</option>
									<option value="51-100">51 - 100</option>
									<option value="101-500">101 - 500</option>
									<option value="above-500">above 500</option>
								</select>
								{errors.companySize && errors.companySize !== "" && (
									<div className="text-danger m-0">{errors.companySize}</div>
								)}
							</div>
						</div>

						<div className="row">
							<div className="mb-3  col-6">
								{" "}
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="website" className="form-label">
											website
										</label>

										<input
											type="text"
											className="form-control"
											onChange={(e) =>
												setCompanyDetails({
													...companyDetails,
													companyWebsite: e.target.value,
												})
											}
											value={companyDetails.companyWebsite}
											id="companyWebsite"
										/>
										{errors.companyWebsite && errors.companyWebsite !== "" && (
											<div className="text-danger m-0">{errors.companyWebsite}</div>
										)}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.companyWebsite && errors.companyWebsite !== "")) ||
												(companyDetails.companyWebsite && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>

							<div className="mb-3  col-6">
								{" "}
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="phoneNumber" className="form-label">
											Phone Number
										</label>

										<input
											type="text"
											className="form-control"
											onChange={(e) =>
												setCompanyDetails({
													...companyDetails,
													phoneNumber: e.target.value,
												})
											}
											value={companyDetails.phoneNumber}
											id="phoneNumber"
										/>
										{errors.phoneNumber && errors.phoneNumber !== "" && (
											<div className="text-danger m-0">{errors.phoneNumber}</div>
										)}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.phoneNumber && errors.phoneNumber !== "")) ||
												(companyDetails.phoneNumber && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>
						</div>

						{/* location */}
						<div className="row  ">
							<div className="col">
								<div>
									<label htmlFor="companyName" className="form-label">
										Country
									</label>
									<div className="mx-n2">
										<SelectField
											name="country"
											onChange={(e) =>
												setCompanyDetails({
													...companyDetails,
													companyLocation: {
														...companyDetails.companyLocation,
														country: e.target.value,
													},
												})
											}
											value={companyDetails.companyLocation.country}
											id="country"
											options={options.phoneCountryCodeOptions}
											label="Country"
										/>
									</div>
									{errors.country !== "" && <div className="text-danger m-0">{errors.country}</div>}
								</div>
							</div>

							<div className="col-8">
								<div className="">
									{!(country === "Tanzania") && (
										<div>
											<label htmlFor="companyName" className="form-label">
												Address
											</label>
											<div className="mx-n2">
												<InputField
													type="text"
													label="Ex: City, Region"
													name="address"
													value={address}
													onChange={(e) => setAddress(e.target.value)}
													placeholder="Enter Address "
												/>
											</div>
											{errors.address !== "" && <div className="text-danger m-0">{errors.address}</div>}
										</div>
									)}
									{country === "Tanzania" && (
										<div>
											<label htmlFor="companyName" className="form-label">
												Address
											</label>

											<div className="mx-n2">
												<AutoSuggestion
													suggestions={locations}
													label="Eg: District, Region"
													type="text"
													name="address"
													userInput={address}
													setUserInput={setAddress}
													isForm={true}
												/>
											</div>
											{errors.address !== "" && <div className="text-danger m-0">{errors.address}</div>}
										</div>
									)}
								</div>
							</div>
						</div>

						<div className="mb-3">
							<Accordion label="Why work at our company" className="mb-3">
								<TextEditor
									value={whyWorkHere}
									id="whyWorkHere"
									setValue={setWhyWorkHere}
									placeholder="Add description that why they should Work here..."
								/>
								{errors.whyWorkAtCompany && errors.whyWorkAtCompany !== "" && (
									<div className="text-danger m-0">{errors.whyWorkAtCompany}</div>
								)}
							</Accordion>
						</div>

						<div className="mb-3">
							<Accordion label="Company Description">
								<TextEditor
									value={companyDescription}
									id="companyDescriptionEdit"
									setValue={setCompanyContactInfo}
									placeholder="Add Company Description here..."
								/>
								{errors.companyDescription && errors.companyDescription !== "" && (
									<div className="text-danger m-0">{errors.companyDescription}</div>
								)}
							</Accordion>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex justify-content-end">
					<button
						type="button"
						className="btn btn-secondary mx-1"
						data-dismiss="modal"
						onClick={() => {
							setShowCreateCompanyDialog(false);
							setErrors([]);
						}}
					>
						Cancel
					</button>

					<button
						type="button"
						className="btn btn-primary mx-1"
						disabled={
							!(
								errors.companyName === "" &&
								errors.companyIndustry === "" &&
								errors.companyDescription === "" &&
								errors.companySize === "" &&
								errors.region === "" &&
								errors.address === "" &&
								errors.companyWebsite === "" &&
								errors.phoneNumber === "" &&
								errors.whyWorkAtCompany === "" &&
								errors.country === ""
							)
						}
						onClick={onSubmit}
					>
						ADD
					</button>
				</div>
			</Modal.Footer>
		</Modal>
	);
}

export default CreateModal;
