import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ErrorToast from "../../../../components/ErrorToast";
import EditorListItems from "../../../../components/ResumeBuilder/EditorListItems";
import Spinner from "../../../../components/Spinner";
import TextEditor from "../../../../components/TextEditor";
import Tips from "../../../../components/Tips";
import ResumeContext from "../../../../context/ResumeContext";
import { convertArrayToUl } from "../../../../handlers/utilityFunctions";
import useHasChange from "../../../../hooks/use-HasChange";
import { Accomplishment } from "../../../../models/resume/accomplishment";
import { StaticValue } from "../../../../seed/constants";
import { ResumeDataService } from "../../../../services/create-resume/resume-data";
import accomplishmentListSuggestions from "./accomplishmentList";

const Accomplishments = () => {
	const navigate = useNavigate();
	const { hasChanges } = useHasChange();

	const staticValue = new StaticValue();
	const staticValues = staticValue.getDeafaulValues();
	const resumeDataService = new ResumeDataService();
	const [loading, setLoading] = useState(false);
	const [cookies] = useCookies(["resume"]);
	const [accomplishmentList, setAccomplishmentList] = useState([...accomplishmentListSuggestions]);

	const dispatch = useDispatch();

	//Declaring the accomplishments state

	const [errors, setErrors] = useState([]);

	useEffect(() => {
		errors.map((item) => {
			toast.error(item);
		});
		setErrors([]);
	}, [errors]);

	const { resumeData, setResumeData } = useContext(ResumeContext);
	const [serverErrors, setServerErrors] = useState([]);

	const [accomplishments, setAccomplishments] = useState(resumeData.extras.accomplishments);

	const [userInput, setUserInput] = useState(resumeData.contactInfo.resumeTitle);

	//submission handler
	const addAccomplishments = () => {
		if (accomplishments === "") {
			setErrors((prevState) => {
				return [...prevState, "Please add your accomplishments"];
			});
		}

		const payload = new Accomplishment(accomplishments);
		const request = payload.getAccomplishmentRequest();

		const resumeId = cookies[staticValues.localStorageResumeId];

		const section = "ACHIEVEMENTS";

		if (hasChanges("ACCOMPLISHMENTS", request)) {
			setLoading(true);
			resumeDataService.patchResumeLedger(
				resumeId,
				request,
				section,
				successPatchAccomplishment,
				errorPatchAccomplishment,
			);
		} else {
			navigate("/resume/extras");
		}
	};

	function successPatchAccomplishment(data) {
		setLoading(false);
		const resArray = data.data.accomplishments.data;

		const accomplishmentList = convertArrayToUl(resArray);

		//TODO update redux store with whole resume
		dispatch({
			type: "RESUME_DATA",
			payload: data.data,
		});

		setResumeData((prevState) => {
			return {
				...prevState,
				extras: {
					...prevState.extras,
					accomplishments: accomplishmentList,
				},
			};
		});

		navigate("/resume/extras");
	}

	function errorPatchAccomplishment(data) {
		setLoading(false);
		const serverResponse = data.response.data;
		Array.isArray(serverResponse.message)
			? setServerErrors((prev) => {
					return [...prev, ...serverResponse.message];
				})
			: setServerErrors((prev) => {
					return [...prev, serverResponse.message];
				});
	}

	useEffect(() => {
		setUserInput(resumeData.contactInfo.resumeTitle);
	}, [resumeData]);

	return (
		<>
			<div className="container min-vh-100">
				<div className="section">
					{serverErrors.map((item, index) => {
						return <ErrorToast error={item} key={index} />;
					})}
					<div className="common-recommendations-section accomplishments-recommendations-section">
						<div className="row">
							<div className="col-md-6">
								<div className="left">
									<p className="mb-3 text-medium">Tell us about your accomplishments?</p>

									<div className="search mb-4">
										<div className="form-group">
											<div className="input-group">
												<input type="text" className="form-control" placeholder={userInput} />
												<div className="input-group-append">
													<button className="btn btn-primary px-md-5" type="button">
														<span className="fa fa-search" />
													</button>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											<div className="recommendation-topics">
												<EditorListItems
													editorListItems={accomplishmentList}
													editorContent={accomplishments}
													setEditorContent={setAccomplishments}
													setEditorListItems={setAccomplishmentList}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6 d-flex flex-column mt-4 justify-content-end">
								<div className="editor-side">
									<div className="mb-1 d-flex justify-content-between">
										<div />
										<Tips
											content={() => (
												<div>
													<p className="mb-1">
														Most notable achievements, three to five bullet points. Professional Experience section
														include more details of the “highlights”
													</p>

													<ul>
														<li>
															Underneath the position information , list your most impressive accomplishments in bullets
															not paragraphs. Shoot for 3 to 5 bullets.
														</li>
														<li>
															If you only held one or two positions and require more bullets for each position to fill
															out your resume content → Just make sure the additional accomplishments you highlight are
															relevant to the job description and carry the momentum you&#39;ve established in previous
															bullets
														</li>
														<li>
															Describe your accomplishments in terms of results, not responsibilities
															<ul>
																<li>Relevant → to job you&#39;re applying for; start with most to least relevant</li>
																<li>Relative → want to compare you performance to established targets</li>
																<li>
																	Results-oriented → Most quantifiable the better; articulate into tangible value
																	statements [time/cost saved, increased speed/revenue etc.]
																</li>
															</ul>
														</li>
														<li>
															Recommend leaving off any work experience that took place 15+ years ago. Only exception is
															if the position is your current role OR the experience is more relevant to position
															you&#39;re applying to than your current role
														</li>
													</ul>
												</div>
											)}
										/>
									</div>

									<div className="editor-wrapper">
										<TextEditor
											value={accomplishments}
											setValue={setAccomplishments}
											placeholder="Write about your accomplishments here..."
										/>
									</div>
								</div>
							</div>
							<div className="col-12 d-flex align-items-center mt-4 justify-content-end">
								<button
									onClick={() => {
										navigate("/resume/extras");
									}}
									className="btn btn-dark rounded-pill btn-lg mr-2"
								>
									CANCEL
								</button>
								<button onClick={addAccomplishments} className="btn btn-primary">
									{loading ? (
										<>
											Loading <Spinner />
										</>
									) : (
										"save"
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Accomplishments;
