import React from "react";

const JobDescriptionModal = ({ details }) => {
	return (
		<>
			<div
				className="modal fade"
				id="jobDescriptionModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="jobDescriptionModalTitle"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title" id="jobDescriptionModal">
								Job Description
							</h3>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="job-desc py-3">
								{/* Job Specifications*/}
								<h5 className="font-weight-bold">Job Specifications</h5>
								<p>
									<span className="font-weight-semi">Estimated salary: </span>
									{` ${details.job.salary.payCurrency} 
                                                    ${details.job.salary.maxPay}/${details.job.salary.payFrequency}
                                            `}
								</p>

								<p>
									<span className="font-weight-semi">Number of opening: </span>
									{` ${details.job.numberOfOpening}`}
								</p>

								<p>
									<span className="font-weight-semi">Employment type: </span>
									{` ${details.job.employmentType}`}
								</p>

								<p>
									<span className="font-weight-semi">Support for remote work: </span>
									{` ${details.job.remoteWorkSupported}`}
								</p>

								{/* Job Descriptions and Responsibilities*/}
								<h5 className="font-weight-bold mt-4">Job Description and Responsibilities</h5>
								<p>{details.job.jobDescription.lookingToHire}</p>
								<ul>
									{details.job.jobDescription.responsibilities.map((responsibility, index) => (
										<li key={index}> {responsibility.value} </li>
									))}
								</ul>

								{/* Job Qualifications*/}
								<h5 className="font-weight-bold">Required Skills</h5>
								<ul>
									{details.job.jobDescription.qualifications.map((qualification, index) => (
										<li key={index}> {qualification.value} </li>
									))}
								</ul>

								{/* Company Info*/}
								<div className="widget company-info">
									<h5 className="widget_title">Company Info</h5>

									<span> {details.job.companyName} </span>
									{/*<p> {details.company.companyDescription} </p>*/}
									<ul className="tr-list">
										<li>
											<span>Address: </span>
											{`${details.job.jobStreetAddress}`}
										</li>
										{/*<li><span>Phone:</span> {details.company.phoneNumber} </li>*/}
										{/*<li>*/}
										{/*    <span>Website:</span>*/}
										{/*    /!*<a href={`https://${details.company.companyWebsite}`}>*!/*/}
										{/*    /!*    {details.company.companyWebsite}*!/*/}
										{/*    /!*</a>*!/*/}
										{/*</li>*/}
										{/*<li><span/></li>*/}
									</ul>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal">
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default JobDescriptionModal;
