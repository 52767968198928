const personalProfile = {
	content: [
		{
			bp: false,
			value:
				"Including a personal profile in your CV can help you to showcase your strengths, skills, and experience to potential employers.",
		},
		{
			bp: true,
			value:
				"Your personal profile should be no longer than two to three sentences. It should be a concise summary of your professional experience and skills.",
		},
		{
			bp: true,
			value:
				"Use your personal profile to highlight your key skills and strengths. These should be relevant to the job you are applying for and reflect your unique selling points as a candidate.",
		},
		{
			bp: true,
			value:
				"Customize your personal profile for the job you are applying for. Make sure to use keywords and phrases from the job description to highlight your relevant experience and skills.",
		},
		{
			bp: true,
			value:
				"Use an active voice when writing your personal profile. This will help to create a sense of energy and enthusiasm about your skills and experience.",
		},
	],
};

export default personalProfile;
