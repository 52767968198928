import React from "react";

function ShareJobs({ company, location, title, linkToShare, applicationDeadline }) {
	const shareString = `Check out this ${title} at ${company} in ${location}. Deadline in ${applicationDeadline} on ${linkToShare}`;

	return (
		<div className="d-flex flex-column justify-content-start align-items-center">
			<h6 className="m-0">Share with your network</h6>
			<div className="d-flex">
				<div className="mr-2">
					<a
						href={`https://www.facebook.com/sharer/sharer.php?u=${linkToShare}&quote=${shareString}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<i className="fab fa-facebook-square fa-2x" />
					</a>
				</div>
				<div className="mr-2">
					<a href={`https://twitter.com/intent/tweet?url=${shareString}`} target="_blank" rel="noopener noreferrer">
						<i className="fa-brands fa-x-twitter text-dark fa-2x" />
					</a>
				</div>
				<div className="mr-2">
					<a
						href={`https://www.linkedin.com/sharing/share-offsite/?url=${linkToShare}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<i className="fab fa-linkedin fa-2x" />
					</a>
				</div>
				<div className="mr-2">
					<a href={`https://wa.me/?text=${shareString}`} target="_blank" rel="noopener noreferrer">
						<i className="fab fa-whatsapp-square fa-2x text-success" />
					</a>
				</div>
			</div>
		</div>
	);
}

export default ShareJobs;
