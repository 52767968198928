import React from "react";

import ExpertOrderCard from "../../components/ExpertResumes/ExpertOrderCard";
import ExpertResumesLayout from "../../components/ExpertResumesLayout/ExpertResumesLayout";

const expertRoutes = [
	{ name: "My Resume Orders", route: "/expert-orders-overview" },
	{ name: "Profile Settings", route: "/" },
	{ name: "Billing and Payments", route: "/" },
	{ name: "Support & FAQs", route: "/" },
];

function ExpertOrdersOverview() {
	return (
		<ExpertResumesLayout navItems={expertRoutes} headingTitle="Orders" headingIcon="briefcase">
			<div className="expert-review-content-container">
				<div className="expert-review-heading-title">Welcome, James</div>
				<div className="expert-review-orders-container">
					<div className="expert-review-orders-header-container">
						<div className="expert-review-orders-header-title">Your resume orders</div>
						<i className="bi bi-plus" style={{ color: "#5D5D5D" }} />
					</div>
					<ExpertOrderCard />
				</div>
			</div>
		</ExpertResumesLayout>
	);
}

export default ExpertOrdersOverview;
