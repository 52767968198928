import React from "react";

import { convertDateToWords, convertUlToArray } from "../../../../handlers/utilityFunctions";

function ResumePremiumThree(props) {
	const { isReview, resumeData } = props;

	return (
		<div className="bg-white final-resume-preview-wrapper final-premium-template-3 final-resume-preview-new shadow-sm">
			<div className="bg-white  text-dark">
				<div className="container mt-2">
					{resumeData.contactInfo.firstName !== "" && (
						<div>
							<div className="d-flex">
								<div
									style={{
										flex: "0 0 50%",
										backgroundColor: "#5e6579",
										color: "#ffffff",
										borderRadius: "10px",
										padding: "15px",
									}}
								>
									<h1 className="mb-1">
										{resumeData.contactInfo.firstName} {isReview} {resumeData.contactInfo.middleName}{" "}
										{resumeData.contactInfo.lastName}
									</h1>
									<h3 style={{ color: "#f6913f", fontWeight: 400 }} className="mb-3">
										{resumeData.contactInfo.resumeTitle}
									</h3>
									<p
										dangerouslySetInnerHTML={{
											__html: resumeData.summary,
										}}
									/>
								</div>
								<div style={{ flex: "0 0 50%" }}>
									<p className="mb-2 mt-4 text-right d-flex align-items-center justify-content-end">
										{resumeData.contactInfo.email}
										<span style={{ position: "relative", marginLeft: "10px" }}>
											<span
												className="contact-icon"
												style={{
													display: "inline-block",
													backgroundColor: "#ced0d7",
													width: "25px",
													height: "25px",
													borderRadius: "5px 5px 5px 35px",
												}}
											/>
											<i
												className="ml-2 fa fa-envelope"
												aria-hidden="true"
												style={{
													fontSize: "20px",
													color: "#5e6579",
													position: "absolute",
													right: "3px",
													top: "0px",
												}}
											/>
										</span>
									</p>
									<p className="mb-2 text-right d-flex align-items-center justify-content-end">
										{resumeData.contactInfo.phoneNo}

										<span style={{ position: "relative", marginLeft: "10px" }}>
											<span
												className="contact-icon"
												style={{
													display: "inline-block",
													backgroundColor: "#ced0d7",
													width: "25px",
													height: "25px",
													borderRadius: "5px 5px 5px 35px",
												}}
											/>
											<i
												className="ml-2 fa fa-mobile"
												aria-hidden="true"
												style={{
													fontSize: "25px",
													color: "#5e6579",
													position: "absolute",
													right: "3px",
													top: "0px",
												}}
											/>
										</span>
									</p>
									<p className="mb-2 text-right d-flex align-items-center justify-content-end">
										{resumeData.contactInfo.city}, {resumeData.contactInfo.country}
										<span style={{ position: "relative", marginLeft: "10px" }}>
											<span
												className="contact-icon"
												style={{
													display: "inline-block",
													backgroundColor: "#ced0d7",
													width: "25px",
													height: "25px",
													borderRadius: "5px 5px 5px 35px",
												}}
											/>
											<i
												className="ml-2 fa fa-map-marker"
												aria-hidden="true"
												style={{
													fontSize: "20px",
													color: "#5e6579",
													position: "absolute",
													right: "3px",
													top: "0px",
												}}
											/>
										</span>
									</p>
									{[...resumeData.extras.personalLinks].map((link, index) => {
										return link.type !== "otherLink" ? (
											<p key={index} className="mb-2 text-right d-flex align-items-center justify-content-end">
												{link?.value?.replace("https://", "")?.replace("www.", "")}

												<span style={{ position: "relative", marginLeft: "10px" }}>
													<span
														className="contact-icon"
														style={{
															display: "inline-block",
															backgroundColor: "#ced0d7",
															width: "25px",
															height: "25px",
															borderRadius: "5px 5px 5px 35px",
														}}
													/>
													<i
														className="ml-2 fa fa-link"
														aria-hidden="true"
														style={{
															fontSize: "20px",
															color: "#5e6579",
															position: "absolute",
															right: "3px",
															top: "0px",
														}}
													/>
												</span>
											</p>
										) : null;
									})}
								</div>
							</div>
						</div>
					)}

					<div className="d-flex">
						<div style={{ padding: "20px 0", flex: "0 0 50%" }}>
							{resumeData.extras.accomplishments !== "" ? (
								<div className="mb-4">
									<h3 className="d-flex align-items-center mb-4">
										<span
											className="mr-3 title-icon"
											style={{
												width: "52px",
												display: "inline-block",
												position: "relative",
											}}
										>
											<span
												style={{
													position: "relative",
													zIndex: 1,
													backgroundColor: "#5e6579",
													width: "40px",
													height: "40px",
													lineHeight: "40px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
												}}
											>
												<span
													className="fa fa-check"
													style={{
														color: "#ffffff",
														transform: "rotate(-45deg)",
														fontSize: "20px",
													}}
												/>
											</span>
											<span
												style={{
													backgroundColor: "#ced0d7",
													width: "30px",
													height: "30px",
													lineHeight: "30px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
													position: "absolute",
													left: "20px",
													top: "4px",
												}}
											/>
										</span>
										<span style={{ color: "#5e6579" }}>ACCOMPLISHMENTS</span>
									</h3>

									{convertUlToArray(resumeData.extras.accomplishments).map((item, index) => {
										return (
											<div key={index} className="d-flex mb-2">
												<span className="fa fa-caret-right mr-2 mt-1" style={{ color: "#f6913f" }} />
												<span>{item.value}</span>
											</div>
										);
									})}
								</div>
							) : null}

							{resumeData.workHistory.length > 0 && resumeData.workHistory[0].jobTitle !== "" ? (
								<div className="mb-4">
									<h3 className="d-flex align-items-center mb-4">
										<span
											className="mr-3 title-icon"
											style={{
												width: "52px",
												display: "inline-block",
												position: "relative",
											}}
										>
											<span
												style={{
													position: "relative",
													zIndex: 1,
													backgroundColor: "#5e6579",
													width: "40px",
													height: "40px",
													lineHeight: "40px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
												}}
											>
												<span
													className="fa fa-briefcase"
													style={{
														color: "#ffffff",
														transform: "rotate(-45deg)",
														fontSize: "20px",
													}}
												/>
											</span>
											<span
												style={{
													backgroundColor: "#ced0d7",
													width: "30px",
													height: "30px",
													lineHeight: "30px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
													position: "absolute",
													left: "20px",
													top: "4px",
												}}
											/>
										</span>
										<span style={{ color: "#5e6579" }}>WORK EXPERIENCE</span>
									</h3>

									{[...resumeData.workHistory].map((job, index) => (
										<div key={index} className="mb-4">
											<div className="d-flex justify-content-between">
												<p className="mb-2">
													<strong>{job.employer}</strong>
													{" - "}
													{job.city}, {job.country}
													<br />
													<em>{job.jobTitle}</em>
												</p>
												<p className="mb-0" style={{ color: "#f6913f" }}>
													<strong>
														{convertDateToWords(job.startDate)} -{" "}
														{job.endDate !== "" ? convertDateToWords(job.endDate) : "Present"}
													</strong>
												</p>
											</div>
											{convertUlToArray(job.details).map((item, index) => {
												return (
													<div key={index} className="d-flex mb-2">
														<span className="fa fa-caret-right mr-2 mt-1" style={{ color: "#f6913f" }} />
														<span>{item.value}</span>
													</div>
												);
											})}
										</div>
									))}
								</div>
							) : null}

							{resumeData.education.length > 0 && (
								<div className="mb-4">
									<h3 className="d-flex align-items-center mb-4">
										<span
											className="mr-3 title-icon"
											style={{
												width: "52px",
												display: "inline-block",
												position: "relative",
											}}
										>
											<span
												style={{
													position: "relative",
													zIndex: 1,
													backgroundColor: "#5e6579",
													width: "40px",
													height: "40px",
													lineHeight: "40px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
												}}
											>
												<span
													className="fa fa-graduation-cap"
													style={{
														color: "#ffffff",
														transform: "rotate(-45deg)",
														fontSize: "20px",
													}}
												/>
											</span>
											<span
												style={{
													backgroundColor: "#ced0d7",
													width: "30px",
													height: "30px",
													lineHeight: "30px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
													position: "absolute",
													left: "20px",
													top: "4px",
												}}
											/>
										</span>
										<span style={{ color: "#5e6579" }}>EDUCATION</span>
									</h3>

									{[...resumeData.education].map((educationHistory, index) => (
										<React.Fragment key={index}>
											<div className="d-flex justify-content-between">
												<p>
													<strong>{educationHistory.schoolName}</strong>
													{" - "}
													{educationHistory.schoolCity}, <br />
													<em>{educationHistory.fieldOfStudy}</em>
												</p>
												<p className="mr-4" style={{ color: "#f6913f" }}>
													<strong>{educationHistory.gYear}</strong>
												</p>
											</div>
											<div
												className="pl-3"
												dangerouslySetInnerHTML={{
													__html: educationHistory.description,
												}}
											/>
										</React.Fragment>
									))}
								</div>
							)}

							{resumeData.extras.languages.length !== 0 ? (
								<div className="mb-4">
									<h3 className="d-flex align-items-center mb-4">
										<span
											className="mr-3 title-icon"
											style={{
												width: "52px",
												display: "inline-block",
												position: "relative",
											}}
										>
											<span
												style={{
													position: "relative",
													zIndex: 1,
													backgroundColor: "#5e6579",
													width: "40px",
													height: "40px",
													lineHeight: "40px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
												}}
											>
												<span
													className="fa fa-language"
													style={{
														color: "#ffffff",
														transform: "rotate(-45deg)",
														fontSize: "20px",
													}}
												/>
											</span>
											<span
												style={{
													backgroundColor: "#ced0d7",
													width: "30px",
													height: "30px",
													lineHeight: "30px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
													position: "absolute",
													left: "20px",
													top: "4px",
												}}
											/>
										</span>
										<span style={{ color: "#5e6579" }}>LANGUAGES</span>
									</h3>

									<div style={{ fontWeight: 500 }}>
										{resumeData.extras.languages.map((language, index) => (
											<div key={index}>
												<p className="mt-2 mb-0">{language.language}</p>
												<p className="font-italic">{language.proficiency}</p>
											</div>
										))}
									</div>
								</div>
							) : null}

							{resumeData.extras.personalProfile.dateOfBirth && (
								<div className="mb-4">
									<h3 className="d-flex align-items-center mb-4">
										<span
											className="mr-3 title-icon"
											style={{
												width: "52px",
												display: "inline-block",
												position: "relative",
											}}
										>
											<span
												style={{
													position: "relative",
													zIndex: 1,
													backgroundColor: "#5e6579",
													width: "40px",
													height: "40px",
													lineHeight: "40px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
												}}
											>
												<span
													className="fa fa-user"
													style={{
														color: "#ffffff",
														transform: "rotate(-45deg)",
														fontSize: "20px",
													}}
												/>
											</span>
											<span
												style={{
													backgroundColor: "#ced0d7",
													width: "30px",
													height: "30px",
													lineHeight: "30px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
													position: "absolute",
													left: "20px",
													top: "4px",
												}}
											/>
										</span>
										<span style={{ color: "#5e6579" }}>PERSONAL INFORMATION</span>
									</h3>

									<div>
										<div className="mb-3">
											<strong>Date of Birth:</strong>&nbsp;
											{resumeData.extras.personalProfile.dateOfBirth}
										</div>
										<div className="mb-3">
											<strong>Gender:</strong>&nbsp;
											{resumeData.extras.personalProfile.gender}
										</div>
										<div className="mb-3">
											<strong>Marital Status:</strong>&nbsp;
											{resumeData.extras.personalProfile.maritalStatus}
										</div>
									</div>
								</div>
							)}
						</div>

						<div
							style={{
								padding: "20px 0",
								flex: "0 0 50%",
								backgroundColor: "#ebebeb",
								borderRadius: "10px",
							}}
							className="p-2 p-md-3"
						>
							{resumeData.skills !== "" ? (
								<div className="mb-4">
									<h3 className="d-flex align-items-center mb-4">
										<span
											className="mr-3 title-icon"
											style={{
												width: "52px",
												display: "inline-block",
												position: "relative",
											}}
										>
											<span
												style={{
													position: "relative",
													zIndex: 1,
													backgroundColor: "#5e6579",
													width: "40px",
													height: "40px",
													lineHeight: "40px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
												}}
											>
												<span
													className="fa fa-cogs"
													style={{
														color: "#ffffff",
														transform: "rotate(-45deg)",
														fontSize: "20px",
													}}
												/>
											</span>
											<span
												style={{
													backgroundColor: "#ced0d7",
													width: "30px",
													height: "30px",
													lineHeight: "30px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
													position: "absolute",
													left: "20px",
													top: "4px",
												}}
											/>
										</span>
										<span style={{ color: "#5e6579" }}>SKILLS</span>
									</h3>

									{convertUlToArray(resumeData.skills).map((item, index) => {
										const splitArray = item.value.split(":");
										let wordBeforeColon = splitArray[0].trim();
										wordBeforeColon = splitArray.length > 1 ? <strong>{wordBeforeColon}</strong> : wordBeforeColon;
										const wordAfterColon = splitArray.length > 1 ? splitArray[1].trim() : "";

										return (
											<div key={index} className="mb-4">
												<div className="mb-4">
													<p className="mb-0">
														<strong>{wordBeforeColon}</strong>
													</p>
													<p className="mb-0">{wordAfterColon}</p>
												</div>
											</div>
										);
									})}
								</div>
							) : null}

							{resumeData.extras.certifications !== "" ? (
								<div className="mb-4">
									<h3 className="d-flex align-items-center mb-4">
										<span
											className="mr-3 title-icon"
											style={{
												width: "52px",
												display: "inline-block",
												position: "relative",
											}}
										>
											<span
												style={{
													position: "relative",
													zIndex: 1,
													backgroundColor: "#5e6579",
													width: "40px",
													height: "40px",
													lineHeight: "40px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
												}}
											>
												<span
													className="fa fa-certificate"
													style={{
														color: "#ffffff",
														transform: "rotate(-45deg)",
														fontSize: "20px",
													}}
												/>
											</span>
											<span
												style={{
													backgroundColor: "#ced0d7",
													width: "30px",
													height: "30px",
													lineHeight: "30px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
													position: "absolute",
													left: "20px",
													top: "4px",
												}}
											/>
										</span>
										<span style={{ color: "#5e6579" }}>CERTIFICATIONS</span>
									</h3>

									{convertUlToArray(resumeData.extras.certifications).map((item, index) => {
										return (
											<div key={index} className="d-flex mb-2">
												<span className="fa fa-caret-right mr-2 mt-1" style={{ color: "#f6913f" }} />
												<span>{item.value}</span>
											</div>
										);
									})}
								</div>
							) : null}

							{resumeData.extras.affiliations !== "" ? (
								<div className="mb-4">
									<h3 className="d-flex align-items-center mb-4">
										<span
											className="mr-3 title-icon"
											style={{
												width: "52px",
												display: "inline-block",
												position: "relative",
											}}
										>
											<span
												style={{
													position: "relative",
													zIndex: 1,
													backgroundColor: "#5e6579",
													width: "40px",
													height: "40px",
													lineHeight: "40px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
												}}
											>
												<span
													className="fa fa-handshake"
													style={{
														color: "#ffffff",
														transform: "rotate(-45deg)",
														fontSize: "20px",
													}}
												/>
											</span>
											<span
												style={{
													backgroundColor: "#ced0d7",
													width: "30px",
													height: "30px",
													lineHeight: "30px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
													position: "absolute",
													left: "20px",
													top: "4px",
												}}
											/>
										</span>
										<span style={{ color: "#5e6579" }}>AFFILIATIONS</span>
									</h3>

									{convertUlToArray(resumeData.extras.affiliations).map((item, index) => {
										return (
											<div key={index} className="d-flex mb-2">
												<span className="fa fa-caret-right mr-2 mt-1" style={{ color: "#f6913f" }} />
												<span>{item.value}</span>
											</div>
										);
									})}
								</div>
							) : null}

							{[...resumeData.extras.personalLinks].length > 0 && (
								<div className="mb-4">
									<h3 className="d-flex align-items-center mb-4">
										<span
											className="mr-3 title-icon"
											style={{
												width: "52px",
												display: "inline-block",
												position: "relative",
											}}
										>
											<span
												style={{
													position: "relative",
													zIndex: 1,
													backgroundColor: "#5e6579",
													width: "40px",
													height: "40px",
													lineHeight: "40px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
												}}
											>
												<span
													className="fa fa-link"
													style={{
														color: "#ffffff",
														transform: "rotate(-45deg)",
														fontSize: "20px",
													}}
												/>
											</span>
											<span
												style={{
													backgroundColor: "#ced0d7",
													width: "30px",
													height: "30px",
													lineHeight: "30px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
													position: "absolute",
													left: "20px",
													top: "4px",
												}}
											/>
										</span>
										<span style={{ color: "#5e6579" }}>WEBSITE, PORTFOLIO, PROFILES</span>
									</h3>

									{[...resumeData.extras.personalLinks].map((link, index) => {
										return link.type === "otherLink" ? (
											<div key={index} className="d-flex mb-3 align-items-center">
												<span className="fa fa-caret-right mr-2 mt-1" style={{ color: "#f6913f" }} />
												<span className="text-capitalize">{link.type}</span>: {link.value}
											</div>
										) : null;
									})}
								</div>
							)}

							{resumeData.extras.references.length > 0 ? (
								<div className="mb-4">
									<h3 className="d-flex align-items-center mb-4">
										<span
											className="mr-3 title-icon"
											style={{
												width: "52px",
												display: "inline-block",
												position: "relative",
											}}
										>
											<span
												style={{
													position: "relative",
													zIndex: 1,
													backgroundColor: "#5e6579",
													width: "40px",
													height: "40px",
													lineHeight: "40px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
												}}
											>
												<span
													className="fa fa-asterisk"
													style={{
														color: "#ffffff",
														transform: "rotate(-45deg)",
														fontSize: "20px",
													}}
												/>
											</span>
											<span
												style={{
													backgroundColor: "#ced0d7",
													width: "30px",
													height: "30px",
													lineHeight: "30px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
													position: "absolute",
													left: "20px",
													top: "4px",
												}}
											/>
										</span>
										<span style={{ color: "#5e6579" }}>REFERENCES</span>
									</h3>

									<div className="d-flex flex-wrap">
										{[...resumeData.extras.references].map((reference, index) => (
											<div key={index} className="mb-3">
												<div>
													<strong>{reference.name}</strong>
													{" - "}
													<em>{reference.designation}, </em>
													{reference.workPlace}
													<br />
													<p>
														{reference.email}, {reference.phoneNumber}
													</p>
												</div>
											</div>
										))}
									</div>
								</div>
							) : null}

							{resumeData.extras.additionalInfo !== "" ? (
								<div className="mb-4">
									<h3 className="d-flex align-items-center mb-4">
										<span
											className="mr-3 title-icon"
											style={{
												width: "52px",
												display: "inline-block",
												position: "relative",
											}}
										>
											<span
												style={{
													position: "relative",
													zIndex: 1,
													backgroundColor: "#5e6579",
													width: "40px",
													height: "40px",
													lineHeight: "40px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
												}}
											>
												<span
													className="fa fa-info"
													style={{
														color: "#ffffff",
														transform: "rotate(-45deg)",
														fontSize: "20px",
													}}
												/>
											</span>
											<span
												style={{
													backgroundColor: "#ced0d7",
													width: "30px",
													height: "30px",
													lineHeight: "30px",
													borderRadius: "5px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
													position: "absolute",
													left: "20px",
													top: "4px",
												}}
											/>
										</span>
										<span style={{ color: "#5e6579" }}>ADDITIONAL INFORMATION</span>
									</h3>

									{convertUlToArray(resumeData.extras.additionalInfo).map((item, index) => {
										return (
											<div key={index} className="d-flex mb-2">
												<span className="fa fa-caret-right mr-2 mt-1" style={{ color: "#f6913f" }} />
												<span>{item.value}</span>
											</div>
										);
									})}
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ResumePremiumThree;
