import { convertUlToArray } from "../../handlers/utilityFunctions";

export class Affiliation {
	constructor(afilliations) {
		this.afilliations = afilliations;
	}

	getAffiliationRequest() {
		const textAreaItems = convertUlToArray(this.afilliations);

		return {
			data: textAreaItems,
		};
	}
}
