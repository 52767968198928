import React, { useEffect, useState } from "react";

function ReviewComments({ reviewData }) {
	const [comment, setComment] = useState({
		section: "",
		rating: "",
		reasonsForRating: [],
		recommendations: [],
	});

	useEffect(() => {
		setComment(reviewData.review);
	}, [reviewData]);

	return (
		<div className=" p-4 pt-2 comment-text">
			<h3 className="text-uppercase comments-header">{comment.section} Section</h3>
			<h6 className="font-weight-bold mb-3">
				Section Rating:
				{comment.rating === "STRONG" ? <span style={{ color: "#28a745" }}> Strong</span> : null}
				{comment.rating === "MODERATE" ? <span style={{ color: "#ffc107" }}> Moderate</span> : null}
				{comment.rating === "WEAK" ? <span style={{ color: "#dc3545" }}> Weak</span> : null}
			</h6>

			<h6 className="font-weight-bold mb-3">Rating Reasons: </h6>
			<ul className="pl-3">
				{comment.reasonsForRating.map((reason, index) => (
					<li key={index}>{reason}</li>
				))}
			</ul>

			<h6 className="font-weight-bold mb-3">Recommendations: </h6>
			<ul className="pl-3">
				{comment.recommendations.map((recommendation, index) => (
					<li key={index}>{recommendation}</li>
				))}
			</ul>
			<hr />
		</div>
	);
}

export default ReviewComments;
