import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";

import Loader from "../../../../components/Loader";
import sub from "../../../../handlers/subscription";
import { formatAPIDate } from "../../../../handlers/utilityFunctions";
import { formatAmount } from "../../../../handlers/utilityFunctions";

function Subscription({ payments, accountId, settingsLoading, subscriptionLoading }) {
	const navigate = useNavigate();
	const { firstName } = JSON.parse(localStorage.getItem("profileData"))
		? JSON.parse(localStorage.getItem("profileData"))
		: { firstName: "" };

	let showUpgrade = true;
	let upgradeDetails = {};
	let expireDate = "---";
	let subscription = {
		_id: "",
		userReferenceId: "---",
		emailAddress: "---",
		package: "---",
		status: "---",
		payments: [],
		expireAt: "---",
		paymentStatus: "---",
	};

	let paymentInfo = (
		<tr>
			<th colSpan={5}>No Payment Details!</th>
		</tr>
	);

	if (payments.length !== 0) {
		const { isSubscribed, selectedSubscription } = sub.chooseSubscription(payments);

		if (isSubscribed) {
			subscription = selectedSubscription;
			expireDate = formatAPIDate("M-D-Y-T", subscription.expireAt);
		}

		paymentInfo =
			subscription.payments.length === 0 ? (
				<tr>
					<th colSpan={5}>No Payments done!</th>
				</tr>
			) : (
				subscription.payments.map((item, index) => (
					<tr key={index}>
						<th scope="row">{index + 1}</th>
						<td>{dayjs(item.paidAt).format("MMM DD, YYYY")}</td>
						<td>{item.confirmationCode}</td>
						<td>{item.paidFromPhoneNumber}</td>
						<td>{formatAmount(item.amount)}</td>
					</tr>
				))
			);

		showUpgrade = subscription.package != "PLATINUM";

		if (showUpgrade) {
			if (subscription.package === "BRONZE") {
				upgradeDetails = { packageName: "Full Access", amount: "" };
			}

			if (subscription.package !== "BRONZE") {
				const totalPaidAmount = () => {
					let total = 0;
					subscription.payments.forEach((payment) => {
						total += payment.amount;
					});

					return total;
				};

				const { packageName, amount } = sub.checkUpgradeDetails(totalPaidAmount());
				upgradeDetails = {
					packageName,
					amount: `by TSh. ${formatAmount(amount)}`,
				};
			}
		}
	}

	return (
		<>
			{settingsLoading ? (
				<Loader />
			) : (
				<>
					<div className="mb-3 bg-white p-3 rounded-sm shadow">
						<div className="row">
							<div className="col-md-6">
								<p className="mb-0">Account</p>
								<h5 className="text-dark font-weight-bold">{firstName ? `${firstName} (${accountId})` : ""}</h5>
							</div>
							<div className="col-md-6">
								<div className="d-flex justify-content-md-end align-items-center">
									<div className="mr-3">
										<p className="mb-0">Package: {subscription.package}</p>
										<h6 className="text-dark font-weight-bold">Expire on: {expireDate}</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
			<h2 className="mb-4">Complete Payments</h2>
			{!settingsLoading && (
				<>
					{subscriptionLoading ? (
						<Loader />
					) : (
						<>
							<div className="py-3 border-top">
								<div className="row">
									<div className="col-md-3">
										<h5 className="mb-0">
											<strong>Activation Status:</strong>
										</h5>
									</div>
									<div className="col-md-3">{subscription.status}</div>
									<div className="col-md-3">
										<h5 className="mb-0">
											<strong>Payment Status:</strong>
										</h5>
									</div>
									<div className="col-md-3">{subscription.paymentStatus}</div>
								</div>
							</div>

							<div className="table-responsive">
								<table className="table table-striped">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">Payment Date</th>
											<th scope="col">Confirmation Code</th>
											<th scope="col">Paid From</th>
											<th scope="col">Amount (Tsh)</th>
										</tr>
									</thead>
									<tbody>{paymentInfo}</tbody>
								</table>
							</div>
							<div className="py-3 border-top ">
								{showUpgrade && (
									<div className="row">
										<div className="col-md-12 fs-12">
											<button
												className="btn btn-primary"
												onClick={() => {
													dayjs(expireDate) > dayjs()
														? navigate("/payments", {
																state: {
																	amount: upgradeDetails.amount,
																	selectedPackage: upgradeDetails.packageName,
																},
															})
														: navigate("/payments");
												}}
											>
												{dayjs(expireDate) > dayjs()
													? `Upgrade to ${upgradeDetails.packageName} ${upgradeDetails.amount}`
													: "UPGRADE TO GAIN FULL ACCESS FOR 1950"}
											</button>
										</div>
									</div>
								)}
							</div>
						</>
					)}
				</>
			)}

			<div className="bg-light p-4 mb-4">
				<div className="row">
					<div className="col-md-7">
						<h5>Need help or want to change your subscription?</h5>
						{/* <p className="mb-0">+255 755 807 568</p> */}
						<p>
							<a href="mailto:customerservice@dproz.ac.tz">alpha@dproz.com</a>
						</p>
					</div>
					<div className="col-md-5 border-left">
						<div className="contactus-info px-3">
							<h5>Contact us 7 days a week</h5>
							<p className="mb-2">
								<strong>Monday-Friday:</strong> 8am to 8pm (Central),
							</p>
							<p className="mb-2">
								<strong>Saturday:</strong> 8am to 5pm (Central),
							</p>
							<p className="mb-2">
								<strong>Sunday:</strong> 10am to 6pm (Central)
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Subscription;
