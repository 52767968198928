import React from "react";

function OrderCardHeader({
	data,
	headerTitle,
	showActionTitleButton,
	actionTitleButtonText,
	expandCard,
	setExpandCard,
	showMarkAsDoneButton,
}) {
	return (
		<>
			<div
				className="d-flex align-items-center justify-content-between flex-wrap"
				style={{ padding: "12px 16px", gap: "2px", background: "#F7F8F8", width: "100%" }}
			>
				{headerTitle && (
					<div className="d-flex align-items-center flex-wrap p-0" style={{ gap: "12px" }}>
						<i className="bi bi-circle" style={{ fontSize: "18px" }} />
						<div className="resume-review-welcome-text" style={{ fontSize: "18px", color: "#121316" }}>
							{headerTitle}
						</div>
					</div>
				)}
				{data?.status && (
					<div
						className="d-flex align-items-center justify-content-center flex-wrap"
						style={{
							padding: "4px 8px",
							gap: "8px",
							background:
								data?.status === "Order Assigned"
									? "#E1F0FF"
									: data?.status === "Revisions Pending"
										? "#FFECC7"
										: "#DFF7EA",
							borderRadius: "6px",
						}}
					>
						<div
							style={{
								fontSize: "12px",
								lineHeight: "16px",
								color:
									data?.status === "Order Assigned"
										? "#1C4ED8"
										: data?.status === "Revisions Pending"
											? "#985508"
											: "#017336",
								letterSpacing: "0.0015em",
							}}
						>
							{data?.status}
						</div>
					</div>
				)}
				{showMarkAsDoneButton && (
					<div
						className="d-flex align-items-center justify-content-center flex-wrap cursor-pointer"
						style={{ padding: "9px 10px", gap: "8px", borderRadius: "6px", background: "#DFEEFF" }}
					>
						<div className="expert-review-navitem-text" style={{ color: "#00509D" }}>
							Mark as done
						</div>
					</div>
				)}
				{showActionTitleButton && actionTitleButtonText && (
					<div
						className="d-flex align-items-center flex-wrap cursor-pointer"
						style={{
							padding: "6px 8px 6px 12px",
							gap: "6px",
							background: "#FFFFFF",
							border: "1px solid #EDEEF1",
							boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 1px #E7E7E7",
							borderRadius: "57px",
						}}
						onClick={() => setExpandCard(!expandCard)}
					>
						{actionTitleButtonText && (
							<div className="expert-review-footer-text" style={{ color: "#121316" }}>
								{actionTitleButtonText}
							</div>
						)}
						<i className={`bi ${expandCard ? "bi-chevron-down" : "bi-chevron-right"}`} />
					</div>
				)}
			</div>
		</>
	);
}

export default OrderCardHeader;
