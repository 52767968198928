import React, { useState } from "react";

import { getSanitizedHtml } from "../../../handlers/utilityFunctions";
import Icon from "../../Icon";
import Tips from "../../Tips";

function InputField({
	onChange,
	value,
	id,
	name,
	type,
	placeholder,
	error,
	warn,
	disabled,
	label,
	onFocus,
	onKeyDown,
	onBlur,
	initialPasswordVisibilityStatus,
	onClick,
	iconLeft,
	disableCopyPaste, // New prop to disable copying and pasting
	tips = null,
}) {
	const [passwordVisible, setPasswordVisible] = useState(initialPasswordVisibilityStatus);

	const togglePasswordVisibility = () => {
		const newVisibility = !passwordVisible; // Toggle the password visibility
		setPasswordVisible(newVisibility);
		onClick(newVisibility); // Send the new visibility to the parent
	};

	const handleCopyPaste = (event) => {
		if (disableCopyPaste) {
			event.preventDefault();
		}
	};

	return (
		<div className="form-group position-relative">
			<div className="d-flex justify-content-between align-items-center">
				<label className="mb-1">{label}</label>
				{tips && <Tips content={tips} />}
			</div>

			<div className="input-group">
				{iconLeft && (
					<div className="input-group-prepend ">
						<span className="input-group-text border-r-0 border-1 bg-transparent pr-1" id="basic-addon1">
							<Icon iconName={iconLeft} size={16} />
						</span>
					</div>
				)}
				<input
					type={type === "password" && !passwordVisible ? "password" : "text"}
					className={`${iconLeft && "form-control-icon-left"} form-control border-1 bg-transparent ${type === "password" && "form-control-icon-right"}`}
					name={name}
					id={id}
					value={value}
					onChange={onChange}
					placeholder={placeholder}
					onFocus={onFocus}
					onBlur={onBlur}
					onKeyDown={onKeyDown}
					disabled={disabled}
					autoComplete="off" // just to make sure they dont put something without a purpose
					onCopy={handleCopyPaste} // Handle copy event
					onPaste={handleCopyPaste} // Handle paste event
				/>

				{type === "password" && (
					<div className="input-group-append">
						<span className="input-group-text border-l-0 bg-transparent" id="basic-addon2">
							<button type="button" className="bg-transparent border-0 m-0 p-0" onClick={togglePasswordVisibility}>
								<i className={`fa ${!passwordVisible ? "fa-eye" : "fa-eye-slash"} text-primary`} />
							</button>
						</span>
					</div>
				)}
			</div>

			{error && <div className="error lh-1 fs-14 font-weight-medium mt-1">{getSanitizedHtml(error)}</div>}
			{warn && <div className="fs-14 font-weight-medium mt-1 text-warning lh-1">{getSanitizedHtml(warn)}</div>}
		</div>
	);
}

export default InputField;
