import mapping from "./metadata/filemapping";
import functionAndSkills from "./metadata/functionAndTitles.json";

export function getMedatadata(categoryName, searchCriteria) {
	let categoryData = mapping[categoryName];
	return categoryData.filter((result) => result.title.includes(searchCriteria));
}

export function getJobFunctionFromTitle(searchCriteria) {
	searchCriteria = searchCriteria === undefined ? "" : searchCriteria;
	const search = searchCriteria.toLowerCase();
	return functionAndSkills
		.filter(
			(result) =>
				result.titles.filter((t) => {
					const data = t.toLowerCase();
					return data.includes(search);
				}).length > 0,
		)
		.map((r) => r.functions);
}

export function getJobFunctions() {
	return functionAndSkills.map((r) => r.functions);
}
