export class Language {
	constructor(languages) {
		this.languages = languages;
	}

	getLanguageRequest() {
		return {
			data: this.languages,
			applicable: true,
		};
	}
}
