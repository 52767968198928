import React from "react";

import ExpertResumesLayout from "../../components/ExpertResumesLayout/ExpertResumesLayout";
import AlertConfirmationModal from "../../components/TodayILearned/AlertConfirmationModal";
import CreateTILModal from "../../components/TodayILearned/CreateTILModal";
import TILCard from "../../components/TodayILearned/TILCard";

export const todayILearnedRoutes = [{ name: "Feed", route: "/today-i-learned", icon: "dashboard", iconSize: 24 }];

const tabsItems = ["All", "My Role's TILs", "New Hires"];
export const actions = ["Create", "Edit"];
const tilsData = [
	{
		isTILPrivate: true,
		title: "",
		creator: "Jacquiline Khalfan",
		profile:
			"https://images.unsplash.com/photo-1718938610048-843cfecefef6?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
		isCreator: true,
		learnedFrom: "",
		skillsLearned: "Learned that can created one from here",
		tags: ["TIL Input Form"],
		comments: [
			{
				fullName: "Jacquiline Khalfan",
				profile:
					"https://images.unsplash.com/photo-1718938610048-843cfecefef6?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
				comment: "Comments goes here",
			},
		],
	},
	{
		isTILPrivate: false,
		title: "Manifold CLI commands",
		creator: "Jacquiline Khalfan",
		profile:
			"https://images.unsplash.com/photo-1718938610048-843cfecefef6?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
		isCreator: false,
		learnedFrom: "Jacquiline Khalfan",
		skillsLearned: "Thanks @Jacquiline Khalfan! #til you an use spreads in spatch commands",
		tags: [],
		comments: [],
	},
];

function TodayILearned() {
	const [currentTab, setCurrentTab] = React.useState(0);
	const [showCreateTILModal, setShowCreateTILModal] = React.useState(false);
	const [showDeleteModal, setShowDeleteModal] = React.useState(false);
	const [selectedTILData, setSelectedTILData] = React.useState({});
	const [action, setAction] = React.useState(actions[0]);

	return (
		<ExpertResumesLayout
			navItems={todayILearnedRoutes}
			headingTitle="TIL (Today I Learned)"
			backgroundColor="#F1F2F6"
			showExpertDetails
		>
			<div className="container py-5">
				<div className="p-2 rounded bg-white">
					<div className="d-flex p-5" style={{ backgroundColor: "#EEFAFA" }}>
						<div>
							<h2>Welcome to #TIL</h2>
							<h6 className="py-2">
								Use #TIL (Today I Learned) in diffs, workplace posts and Workplace Chat to bookmark things you have
								learned and share your learning with others.
							</h6>
							<h6 className="py-2">
								You can also easily create #TILs here to keep track of all the things you learned in one place.
							</h6>
							<button
								data-toggle="modal"
								data-target="#createTILModal"
								className="btn btn-primary my-2"
								style={{ textTransform: "none" }}
								onClick={() => {
									setSelectedTILData({});
									setAction(actions[0]);
									setShowCreateTILModal(true);
								}}
							>
								Create a #TIL
							</button>
						</div>
						<div style={{ width: "100%", minWidth: 100, maxWidth: 200 }}>
							<img className="img-fluid" alt="TIL" src="/images/til.png" />
						</div>
					</div>
				</div>
				<div className="d-flex flex-column align-items-start p-0 mt-5" style={{ gap: "20px", width: "100%" }}>
					<div className="bg-white w-100 rounded p-4 d-flex flex-column" style={{ gap: "20px" }}>
						<div className="d-flex align-items-center flex-wrap" style={{ columnGap: "50px", rowGap: "15px" }}>
							{tabsItems.map((item, index) => {
								return (
									<div
										key={item}
										className="d-flex flex-column align-items-center justify-content-center cursor-pointer"
										onClick={() => setCurrentTab(index)}
									>
										<div className="font-weight-bold fs-18">{item}</div>
										<div
											className={`${currentTab === index && "bg-primary"} mt-2`}
											style={{ height: 6, width: "calc(100% + 20px)" }}
										/>
									</div>
								);
							})}
						</div>
						<div className="d-flex align-items-center border rounded p-3" style={{ gap: "16px", width: "100%" }}>
							<i className="bi bi-search" />
							<div className="d-flex align-items-center justify-content-between" style={{ width: "100%" }}>
								<input className="border-0 flex-fill" placeholder="Search..." style={{ minWidth: 50 }} />
								<div>150 Results</div>
							</div>
						</div>
					</div>
					{tilsData.map((item, index) => {
						return (
							<TILCard
								key={index}
								tilData={item}
								setShowCreateTILModal={setShowCreateTILModal}
								setShowDeleteModal={setShowDeleteModal}
								setSelectedTILData={setSelectedTILData}
								setAction={setAction}
							/>
						);
					})}
				</div>
			</div>
			{showCreateTILModal && (
				<CreateTILModal
					setShowCreateTILModal={setShowCreateTILModal}
					selectedTILData={selectedTILData}
					action={action}
				/>
			)}
			{showDeleteModal && (
				<AlertConfirmationModal
					title="Delete Alert"
					actionButtonTitle="Delete"
					subTitle="Are you sure you want to delete this #TIL?"
					setShowAlertConfirmationModal={setShowDeleteModal}
					handleAction={() => {
						const bodyEl = document.querySelector("body");
						const modal = document.getElementById("alertConfirmationModal");
						const modalBackDrop = document.querySelector(".modal-backdrop");

						modal.style.display = "none";
						modal.classList.remove("show");
						modal.setAttribute("aria-hidden", "true");

						bodyEl.classList.remove("modal-open");
						modalBackDrop.remove();
						setShowDeleteModal(false);
					}}
				/>
			)}
		</ExpertResumesLayout>
	);
}

export default TodayILearned;
