import { useDispatch, useSelector } from "react-redux";

import { NavigationFlow, NavigationList } from "../seed/navigation";
//import { NavigationStorage } from "../services/create-resume/resume-flow";

//TODO: revist this to set foundation for template use.
export class NavigationHandler {
	constructor() {
		this.dispatch = useDispatch();
		this.template = useSelector((state) => state.TemplateSelectionReducer);
		this.selectedTemplate = this.template.selectedTemplate;
		this.flow = new NavigationFlow();
		//this.navigationStorage = new NavigationStorage();
	}

	getResumeId() {
		let resumeId = localStorage.getItem("resume_id");

		if (resumeId) {
			return resumeId;
		}

		return "new-resume";
	}

	next(link, skipOne = 0) {
		let navigationFlow = this.getCurrent(this.template.navigationFlow, link);

		this.template.navigationFlow = navigationFlow;

		this.template.navigationFlow.current = this.template.navigationFlow.current.next;
		while (skipOne > 0) {
			this.template.navigationFlow.current = this.template.navigationFlow.current.next;
			skipOne--;
		}

		localStorage.setItem("currentNav", this.template.navigationFlow.current.value.url);
		this.dispatch({
			type: "CHANGE_TEMPLATE_DETAILS",
			payload: {
				selectedTemplate: {
					yearsOfExperience: this.selectedTemplate.yearsOfExperience,
					templateName: this.selectedTemplate.templateName,
				},
				navigationFlow: this.template.navigationFlow,
			},
		});
	}

	previous(link, skipOne = 0) {
		let navigationFlow = this.getCurrent(this.template.navigationFlow, link);
		this.template.navigationFlow = navigationFlow;
		this.template.navigationFlow.current = this.template.navigationFlow.current.previous;
		while (skipOne > 0) {
			this.template.navigationFlow.current = this.template.navigationFlow.current.previous;
			skipOne--;
		}

		localStorage.setItem("currentNav", this.template.navigationFlow.current.value.url);
		this.dispatch({
			type: "CHANGE_TEMPLATE_DETAILS",
			payload: {
				selectedTemplate: {
					yearsOfExperience: this.selectedTemplate.yearsOfExperience,
					templateName: this.selectedTemplate.templateName,
				},
				navigationFlow: this.template.navigationFlow,
			},
		});
	}

	getCurrent(navFlow) {
		let currentNode = navFlow;
		let yearsOfExperience = localStorage.getItem("yearsOfExperience");
		let templateName = localStorage.getItem("templateName");
		const navigationFlow = new NavigationFlow();
		const fresherFlow = navigationFlow.fresherFlow;

		if (!navFlow.current) {
			if (templateName == null || templateName == undefined) {
				templateName = "fresher_template";
				localStorage.setItem("templateName", templateName);
			}

			if (yearsOfExperience == null || yearsOfExperience == undefined) {
				yearsOfExperience = "No Experience";
				localStorage.setItem("yearsOfExperience", yearsOfExperience);
			}

			let currentNav = localStorage.getItem("currentNav");

			if (currentNav == null || currentNav == undefined || currentNav == "undefined" || currentNav == "heading") {
				currentNav = "contact-information";
				localStorage.setItem("currentNav", currentNav);
			}

			let navFlow = new NavigationList(fresherFlow, fresherFlow); // default to fresh flow

			let finalValue = navFlow.current.value.url;
			let nextValue = navFlow.current;

			while (finalValue !== currentNav) {
				nextValue = navFlow.current.next;
				navFlow.current = nextValue == null || nextValue == undefined ? navFlow.current : nextValue;
				finalValue = navFlow.current.value.url;
			}

			currentNode = navFlow;
		}

		return currentNode;
	}

	getNext(navigationFlow, link, isComplete = false, skipOne = 0) {
		if (isComplete && link !== "education-information" && link !== "work-history" && link !== "work-details") {
			return {
				value: {
					url: "review",
				},
			};
		}
		this.template.navigationFlow = navigationFlow;

		this.next(link, skipOne);
		return this.template.navigationFlow.current;
	}

	//TODO: Let's not hardcode completion status
	getPrevious(navigationFlow, link, isComplete = false, skipOne = 0) {
		if (isComplete) {
			return {
				value: {
					url: "review",
				},
			};
		}

		this.template.navigationFlow = navigationFlow;

		this.previous(link, skipOne);
		return this.template.navigationFlow.current;
	}

	getFirstIncompleteScreen(resumeObject) {
		let resumeProgress = resumeObject.journal.progressStatus;
		let progressArray = [
			resumeProgress.HEADING,
			resumeProgress.EDUCATION,
			resumeProgress.EXPERIENCE,
			resumeProgress.SKILLS,
			resumeProgress.SUMMARY,
		];
		let likArray = [
			"contact-information",
			"education-information",
			"work-history",
			"skills",
			"brief-summary",
			"review",
		];

		let isCompleteFound = true;
		let progressIndex = 0;

		while (isCompleteFound) {
			if (progressArray[progressIndex] == "COMPLETED") {
				progressIndex++;
			} else {
				isCompleteFound = false;
			}
		}

		let { education, experience } = resumeObject;

		if (likArray[progressIndex] == "education-information") {
			likArray[progressIndex] = education.length > 0 ? "education-summary" : "education-information";
		}
		if (likArray[progressIndex] == "work-history") {
			likArray[progressIndex] = experience.length > 0 ? "work-history-summary" : "work-history";
		}

		return likArray[progressIndex];
	}

	setFinalize(backLink) {
		backLink = backLink == "extras" ? "brief-summary" : backLink;
		let likArray = [
			"contact-information",
			"work-history",
			"education-summary",
			"skills",
			"work-details",
			"work-history-summary",
			"education-information",
			"education-summary",
			"brief-summary",
			"review",
		];
		if (!likArray.includes(backLink)) return;

		this.template.navigationFlow.current = undefined;
		localStorage.setItem("currentNav", backLink);
		let navigationFlow = this.getCurrent(this.template.navigationFlow, backLink);
		this.template.navigationFlow = navigationFlow;
		localStorage.setItem("currentNav", this.template.navigationFlow.current.value.url);
		this.dispatch({
			type: "CHANGE_TEMPLATE_DETAILS",
			payload: {
				selectedTemplate: {
					yearsOfExperience: this.selectedTemplate.yearsOfExperience,
					templateName: this.selectedTemplate.templateName,
				},
				navigationFlow: this.template.navigationFlow,
			},
		});
	}
}
