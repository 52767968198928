export const useReviewFeedbackInitalState = {
	section: {
		section: "",
		rating: "",
		reasonsForRating: [],
		recommendations: [],
	},
	showFeedBackModal: false,
};

export default function UseReviewFeedbackReducer(useReviewFeedback = useReviewFeedbackInitalState, action) {
	switch (action.type) {
		case "USE_REVIEW_FEEDBACK":
			return action.payload;

		default:
			return useReviewFeedback;
	}
}
