import React from "react";

import { resumeSteps } from "../../Views/ExpertOrderDetails/ExpertOrderDetails";

function StatusProgressBar({ data }) {
	return (
		<div className="d-flex flex-column align-items-start p-0" style={{ gap: "8px", flex: 1 }}>
			<div
				style={{
					width: "100%",
					height: "8px",
					background: data.title === resumeSteps[0].title ? "#1E8D52" : "#D7DAE0",
					borderRadius: "57px",
				}}
			/>
			<div className="d-flex flex-column align-items-start p-0">
				<div
					className="add-custom-skill-button-text"
					style={{ fontSize: "16px", color: "#121316", lineHeight: "24px" }}
				>
					{data.title}
				</div>
				<div
					className="expert-review-top-nav-avatar-text"
					style={{
						fontSize: "12px",
						lineHeight: "16px",
						color: data.title === resumeSteps[3].title ? "#985508" : "#6E7889",
					}}
				>
					{data.subTitle}
				</div>
			</div>
		</div>
	);
}

export default StatusProgressBar;
