import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { ResumeDataService } from "../../../services/create-resume/resume-data";

function PublishReviewRequest({ reviewData, fetchReviews, setShowReviewModal, setCurrentStep }) {
	const resumeDataService = new ResumeDataService();
	const navigate = useNavigate();

	if (!reviewData) return setCurrentStep(1);

	const [errors, setErrors] = useState([]);
	const [publishLoading, setPublishLoading] = useState(false);

	function successPublishResumeReview() {
		setPublishLoading(false);
		toast.success("Review published successfully");
		fetchReviews();
		setShowReviewModal(3);
	}

	function errorPublishResumeReview(data) {
		setPublishLoading(false);
		setErrors(data.response.data.message);
		if (data.response.status === 402) {
			setTimeout(() => {
				navigate("/payments");
			}, 3000);
		}
	}
	function publish(id) {
		setPublishLoading(true);
		resumeDataService.publishResumeReview(id, successPublishResumeReview, errorPublishResumeReview);
	}

	return (
		<>
			<h4 className="fs-14">Preview and submit.</h4>
			{errors.length > 0 && <div className="alert alert-danger">{errors}</div>}
			<p className="alert alert-warning mb-3">
				By submitting, each of your reviewers will receive the email with a link to review.
			</p>
			<p className="font-weight-medium">Reviewers</p>
			<div className="d-flex">
				{reviewData.reviewerEmails.map((reviewer, index) => (
					<div className="mr-1 badge badge-light" key={index}>
						{reviewer}
					</div>
				))}
			</div>
			<h4 className="fs-14 mt-3">Sample Email Reviewers See</h4>
			<div className="alert alert-primary">
				<p className="mb-2">Dear [REVIEWER_NAME],</p>
				<p className="mb-2">
					I value your guidance and advice in my career growth. That&#39;s why I&#39;m requesting your candid feedback
					to help me strengthen my CV for targeted opportunities.
				</p>
				<p className="mb-2">
					<strong>The Opportunity: </strong>
					{reviewData.messageToReviewers}
				</p>
				<p className="mb-2">
					<strong>The Ask: </strong>I was wondering if you&#39;d mind looking at my current CV( link below), and provide
					me with critiques, tips & recommendations to help me strengthen it & improve my chances.
				</p>
				<p className="mb-2">
					Thank You,
					<br />
					[Your Name]
				</p>
			</div>

			<div className="d-flex justify-content-end">
				<button className="btn rounded-sm btn-primary" onClick={() => publish(reviewData._id)}>
					{publishLoading ? (
						<div className="spinner-border text-white ml-2" role="status">
							<span className="sr-only">Loading</span>
						</div>
					) : (
						"Submit request"
					)}
				</button>
			</div>
		</>
	);
}

export default PublishReviewRequest;
