import React, { useState } from "react";

import CreateReviewRequest from "./CreateReviewRequest";
import PublishReviewRequest from "./PublishReviewRequest";

function ResumeReviewModal({
	setShowReviewModal,
	resumeTitle,
	resumeID,
	fetchReviews,
	reviewData,
	initialCurrentStep,
}) {
	const [currentStep, setCurrentStep] = useState(initialCurrentStep); // 1: Prepare Request, 2: Send Request
	const [currentReviewData, setCurrentReviewData] = useState(reviewData);

	return (
		<div className="review-screen container">
			<div className="card card-body p-3">
				<div className="row mx-0">
					<button className="modal-close" onClick={() => setShowReviewModal(currentStep)}>
						<i className="bi bi-x fs-24 text-secondary" />
					</button>
					<div className="col-md-5 p-0 bg-lighter rounded">
						{/* 1. Intro Message 3. Add recipients, 4. Send Request */}

						<div className="resume-review-sidenav-bar" />

						<div className="nav-item step-counter m-0">
							<button
								className={`${currentStep >= 1 && "active"} d-flex align-items-center`}
								onClick={() => setCurrentStep(1)}
							>
								<i
									className={`bi mr-2 fs-16 ${currentStep >= 1 ? "bi-check-circle-fill text-success" : "bi-circle text-light-blue"}`}
								/>
								Intro Message
							</button>
						</div>
						<div className="nav-item step-counter m-0">
							<button
								className={`${currentStep >= 2 && "active"} d-flex align-items-center`}
								onClick={() => setCurrentStep(2)}
							>
								<i
									className={`bi mr-2 fs-16 ${currentStep >= 2 ? "bi-check-circle-fill text-success" : "bi-circle text-light-blue"}`}
								/>
								Add Recipients
							</button>
						</div>
						<div className="nav-item step-counter m-0">
							<button
								className={`${currentStep === 3 && "active"} d-flex align-items-center`}
								onClick={() => setCurrentStep(3)}
							>
								<i className="bi fs-16 mr-2 bi-circle text-light-blue" /> Submit
							</button>
						</div>
					</div>

					<div className="col-md-7 p-0">
						<div className="px-3 review-content">
							{currentStep < 3 && (
								<CreateReviewRequest
									resumeID={resumeID}
									resumeTitle={resumeTitle}
									reviewData={currentReviewData}
									currentStep={currentStep}
									setCurrentStep={setCurrentStep}
									mode={currentReviewData ? "edit" : "create"}
									setCurrentReviewData={setCurrentReviewData}
								/>
							)}
							{currentStep === 3 && (
								<PublishReviewRequest
									fetchReviews={fetchReviews}
									reviewData={currentReviewData}
									setShowReviewModal={setShowReviewModal}
									setCurrentStep={setCurrentStep}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ResumeReviewModal;
