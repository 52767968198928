import React from "react";

function FelidsOfStudy({ relevantFieldOfStudies, setRelevantFieldOfStudies, errors }) {
	const [value, setValue] = React.useState(relevantFieldOfStudies);

	return (
		<div className="mb-3">
			<label htmlFor="relevantFieldOfStudies" className="form-label">
				Relevant Feilds of Study
			</label>
			{relevantFieldOfStudies.length > 0 ||
				(errors.educationQualification &&
					errors.educationQualification.relevantFieldOfStudies &&
					errors.educationQualification.relevantFieldOfStudies !== "" && (
						<div className="text-danger m-0">{errors.educationQualification.relevantFieldOfStudies}</div>
					))}
			{relevantFieldOfStudies.map((item, index) => (
				<li className="m-0 p-0" key={index}>
					{item}
					<button
						className="btn text-danger p-0 m-0"
						onClick={(e) => {
							e.preventDefault();
							setRelevantFieldOfStudies((prev) => {
								return prev.filter((item, i) => i !== index);
							});
						}}
					>
						<i className="bi bi-x" />
					</button>
				</li>
			))}
			<div className="d-flex">
				<select
					type="text"
					className={`${
						!(relevantFieldOfStudies.length > 0) ||
						(errors.educationQualification &&
							errors.educationQualification.relevantFieldOfStudies &&
							errors.educationQualification.relevantFieldOfStudies !== "" &&
							false &&
							"select-box-not-selected-error")
					} rounded-start form-control `}
					id="relevantFieldOfStudies"
					value={value}
					onChange={(e) => {
						e.preventDefault();

						setRelevantFieldOfStudies((prev) => {
							return [...prev, e.target.value];
						});
						setValue("");
					}}
					style={{
						borderTopRightRadius: 0,
						borderBottomRightRadius: 0,
					}}
				>
					<option value="">Select</option>
					{[
						"Secondary Education",
						"Agriculture",
						"Architecture and Planning",
						"Arts and Humanities",
						"Business",
						"Education",
						"Engineering",
						"Environmental Science",
						"Information and Communication Technology",
						"Journalism, Media Studies and Communication",
						"Law",
						"Library, Archive and Museum Studies",
						"Life Sciences",
						"Medicine and Health Sciences",
						"Mining and Earth Sciences",
						"Physical Sciences and Mathematics",
						"Social Sciences",
						"Tourism and Hospitality Studies",
					].map((item, index) => (
						<option value={item} key={index}>
							{item}
						</option>
					))}
				</select>
			</div>
		</div>
	);
}

export default FelidsOfStudy;
