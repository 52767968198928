import React from "react";
import { Link } from "react-router-dom";

function ResumeProgressItem({ click, progressStatus, title, index, section, noClick }) {
	return (
		<Link to={`/resume/${section}`} onClick={() => (noClick ? click(section) : null)}>
			<div className="row py-3 px-2 bg-lighter rounded mb-3">
				<div className="col-5 d-flex align-items-center">
					{progressStatus === "COMPLETED" ? (
						<div className="badge-circle badge-medium bg-success text-white mr-3">
							<i className="fa fa-check" />
						</div>
					) : (
						<div className="badge-circle badge-medium badge-light-blue text-dark fs-xs font-medium mr-3">{index}</div>
					)}
					<div>
						<p className="font-weight-semi m-0">{title}</p>
					</div>
				</div>
				<div className="col-7 d-flex justify-content-end align-items-center pr-0">
					<div className="mr-0 mr-sm-3">
						{progressStatus === "COMPLETED" ? (
							<span className="badge badge-success text-white lh-1 fs-xs p-2">COMPLETE</span>
						) : progressStatus === "PROGRESS" ? (
							<span className="badge badge-warning lh-1 fs-xs p-2">INCOMPLETE</span>
						) : (
							<span className="badge badge-light lh-1 fs-xs p-2">NOT STARTED</span>
						)}
					</div>
					<div>
						<button className="border-0 bg-transparent py-0">
							<i className="fa-solid fs-xs text-dark fa-chevron-right" />
						</button>
					</div>
				</div>
			</div>
		</Link>
	);
}

export default ResumeProgressItem;
