import Multiselect from "multiselect-react-dropdown";
import React from "react";

import dropDownOptions from "../../seed/dropDownOptions";
import { actions } from "../../Views/TodayILearned/TodayILearned";
import TextEditor from "../TextEditor";

const tagsCategoriesStyles = {
	searchBox: {
		height: "50px",
		borderColor: "#DADADA",
		borderRadius: "0.2rem",
		backgroundColor: "#ffffff",
	},
	inputField: {
		paddingBottom: "15px",
		paddingLeft: "15px",
	},
};

function CreateTILModal({ setShowCreateTILModal, selectedTILData, action }) {
	const expertiseOptions = dropDownOptions.tilTags;
	const [learnedFrom, setLearnedFrom] = React.useState(selectedTILData?.learnedFrom || "");
	const [isTILPrivate, setIsTILPrivate] = React.useState(selectedTILData?.isTILPrivate || false);
	const [skillsLearned, setSkillsLearned] = React.useState([]);
	const [selectedTags, setSelectedTags] = React.useState([]);

	const formattedOptions = (options) => {
		return options.map((option) => {
			return { key: option };
		});
	};

	const onSelectTag = (selectedItem) => {
		setSelectedTags(selectedItem);
	};

	const onRemoveTag = (selectedItem) => {
		setSelectedTags(selectedItem);
	};

	const onSelectedValueDecorator = (selectedValue) => {
		const trimmedText = selectedValue.length < 10 ? selectedValue : selectedValue.substring(0, 10);
		return trimmedText.concat("...");
	};

	return (
		<div
			className="modal fade"
			id="createTILModal"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="createTIL"
			aria-hidden="true"
			data-backdrop="static"
			data-keyboard="false"
		>
			<div className="modal-dialog modal-dialog-centered resume-feedback-modal" role="document">
				<div className="modal-content" style={{ padding: "20px 20px 24px" }}>
					<div className="d-flex align-items-center">
						<div className="resume-feedback-modal-title fs-20">{action === actions[0] ? "Create TIL" : "Edit"}</div>
						<div
							className="cursor-pointer close ml-auto"
							data-dismiss="modal"
							onClick={() => {
								setShowCreateTILModal(false);
							}}
						>
							<i className="fa fa-close" />
						</div>
					</div>
					<div className="d-flex flex-column align-items-start" style={{ gap: 16, width: "100%" }}>
						<div style={{ width: "100%" }}>
							<div className="mt-3 font-weight-bold fs-16">Learned from</div>
							<div>Sources you learned this from (optional)</div>
							<input
								value={learnedFrom}
								className="border rounded p-3"
								style={{ width: "100%" }}
								onChange={(event) => {
									setLearnedFrom(event.target.value);
								}}
							/>
						</div>
						<div className="d-flex align-items-center" style={{ gap: 8 }}>
							<input
								value={isTILPrivate}
								type="checkbox"
								style={{ width: 24, height: 24, transform: "scale(1.5)", cursor: "pointer" }}
								onChange={() => {
									setIsTILPrivate(!isTILPrivate);
								}}
							/>
							<div>
								<span className="font-weight-semi fs-14">Is Private</span>
								<br />
								<span>Make this TIL only visible to you</span>
							</div>
						</div>
						<div style={{ width: "100%" }}>
							<div className="font-weight-bold fs-16">Tags</div>
							<div>Add tags so that you can use them to easily search for the TIL later (optional)</div>
							<div style={{ width: "100%" }}>
								<Multiselect
									options={formattedOptions(expertiseOptions)}
									displayValue="key"
									placeholder=""
									hidePlaceholder={false}
									selectionLimit={4}
									closeOnSelect={false}
									style={tagsCategoriesStyles}
									selectedValues={selectedTags}
									onSelect={onSelectTag}
									onRemove={onRemoveTag}
									selectedValueDecorator={onSelectedValueDecorator}
									avoidHighlightFirstOption={true}
								/>
							</div>
						</div>
						<div style={{ width: "100%" }}>
							<div className="font-weight-bold fs-16">Today I learned</div>
							<TextEditor id="skillsLearned" value={skillsLearned} setValue={setSkillsLearned} placeholder="" />
						</div>
						<button className="btn btn-primary ml-auto">Save</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CreateTILModal;
