import React from "react";
import { useSelector } from "react-redux";

import { TemplateNameToResumeViewMapping } from "../../../components/ResumeBuilder/ResumePreview/templateSelector";

function Resume({ resumeData, isReview, hasFeedback, reviewData, activeSection, onSubmitFeedBack, loadingSubmission }) {
	const templateSelector = new TemplateNameToResumeViewMapping();
	const templates = templateSelector.templatesToSupportedResumes;
	const template = useSelector((state) => state.TemplateSelectionReducer);
	const selectedTemplate = template.selectedTemplate;

	let useTemplateName = selectedTemplate.templateName;

	if (resumeData.journal) {
		const temId = resumeData.journal.templateId;
		//if contains q substring from q_to_end
		const indexOfq = temId.indexOf("q");
		let intermediateName = resumeData.journal.templateId;

		if (indexOfq >= 0) {
			intermediateName = intermediateName.substring(indexOfq + 1);
		}

		//replace x with _
		intermediateName = intermediateName.replaceAll("x", "_");

		useTemplateName = intermediateName;
	}

	//TODO: Use templateName from resumeData
	const ResumeTemplateComponent = templates.find((t) => {
		return t.name === useTemplateName;
	}).review;

	return (
		<ResumeTemplateComponent
			resumeData={resumeData}
			isReview={isReview}
			activeSection={activeSection}
			hasFeedback={hasFeedback}
			reviewData={reviewData}
			onSubmitFeedBack={onSubmitFeedBack}
			loadingSubmission={loadingSubmission}
		/>
	);
}

export default Resume;
