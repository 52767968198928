import { convertUlToArray } from "../../handlers/utilityFunctions";

export class Certification {
	constructor(certifications) {
		this.certifications = certifications;
	}

	getCertificationRequest() {
		const itemsArray = convertUlToArray(this.certifications);

		return {
			data: itemsArray,
		};
	}
}
