import React, { useEffect } from "react";

function ProficiencyRange({ setLevel, value }) {
	const [isHovered, setIsHovered] = React.useState(false);

	const [proficiency, setValue] = React.useState(10);
	const onChange = (e) => {
		if (e.target.value <= 10) {
			setLevel("Elementary Proficiency");
			return setValue(10);
		}
		if (e.target.value > 10 && e.target.value <= 20) {
			setLevel("Limited Working Proficiency");
			return setValue(20);
		}
		if (e.target.value > 20 && e.target.value <= 30) {
			setLevel("Professional Working Proficiency");
			return setValue(30);
		}
		if (e.target.value > 30 && e.target.value <= 40) {
			setLevel("Full Professional Proficiency");
			return setValue(40);
		}
		if (e.target.value >= 50) {
			setLevel("Native / Bilingual Proficiency");
			return setValue(50);
		}
	};

	useEffect(() => {
		if (value === "Elementary Proficiency") setValue(10);
		if (value === "Limited working Proficiency") setValue(20);
		if (value === "Professional Working Proficiency") setValue(30);
		if (value === "Full Professional Proficiency") setValue(40);
		if (value === "Native / Bilingual Proficiency") setValue(50);
	}, [value]);

	return (
		<div>
			<div className="language-range-selector">
				<input
					type="range"
					name=""
					step={10}
					min={0}
					max={60}
					id=""
					onMouseOver={() => setIsHovered(true)}
					onMouseOut={() => setIsHovered(false)}
					style={{
						zIndex: isHovered ? 100 : "auto",
					}}
					onChange={onChange}
					value={proficiency}
				/>
				<div className="step-indicator-wrapper">
					{new Array(5).fill(0).map((_, index) => (
						<div className={`step-indicator ${proficiency == (index + 1) * 10 ? "border-0" : ""}`} key={index} />
					))}
				</div>
			</div>
			<div className="step-labels">
				<span>
					Elementary
					<br />
					Proficiency
				</span>
				<span>
					Limited
					<br />
					working
				</span>
				<span>
					Professional
					<br />
					working
				</span>
				<span>
					Full
					<br />
					Professional
				</span>
				<span>
					Native/
					<br />
					Bilingual
				</span>
			</div>
		</div>
	);
}

export default ProficiencyRange;
