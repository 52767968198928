import React from "react";

import { PricingDetailsModal } from "../../Views/ExpertHelp/Pricing";
import ExpertSidebar from "./ExpertSidebar";
import ExpertTopNav from "./ExpertTopNav";

function ExpertReviewLayout({ navItems, headingTitle, headingIcon, backgroundColor, showExpertDetails, children }) {
	const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
	const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
	const [showSideBar, setShowSideBar] = React.useState(false);
	const [showDetailsModal, setShowDetailsModal] = React.useState(false);

	React.useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
			setScreenHeight(window.innerHeight);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [screenWidth, screenHeight]);

	return (
		<div
			className="expert-review-layout-container"
			style={{ height: `${screenHeight}px`, ...(screenWidth > 768 && { display: "flex" }) }}
		>
			<ExpertSidebar
				navItems={navItems}
				screenWidth={screenWidth}
				screenHeight={screenHeight}
				showSideBar={showSideBar}
				setShowSideBar={setShowSideBar}
			/>
			<div
				className="expert-review-main-content-container"
				style={{
					height: `calc(${screenHeight}px - 24px)`,
					...(showExpertDetails && { marginRight: -12, marginLeft: -12 }),
				}}
			>
				{showExpertDetails && (
					<div className="bg-blue-gradient" style={{ width: "100%", marginTop: -12 }}>
						<div className="d-flex align-items-center justify-content-between px-4" style={{ minHeight: 56 }}>
							<p className="text-white">
								Boost Your resume with Expert help! Get guidance from a professional resume writer to increase your
								chances of landing a job.
							</p>
							<button
								className="btn btn-primary fs-14 font-weight-normal"
								onClick={() => {
									setShowDetailsModal(true);
								}}
							>
								Learn More
							</button>
						</div>
					</div>
				)}
				<ExpertTopNav
					screenWidth={screenWidth}
					headingIcon={headingIcon}
					headingTitle={headingTitle}
					setShowSideBar={setShowSideBar}
				/>
				<div
					style={{
						width: "100%",
						height: "100%",
						overflowY: "auto",
						...(backgroundColor && { backgroundColor: backgroundColor }),
						...(showExpertDetails && { paddingRight: 12, paddingLeft: 12 }),
					}}
				>
					{children}
				</div>
			</div>
			{showDetailsModal && <PricingDetailsModal setShowDetailsModal={setShowDetailsModal} />}
		</div>
	);
}

export default ExpertReviewLayout;
