import dayjs from "dayjs";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import StatusToast from "../../../components/StatusToast";
import {
	jobPreferencesTitles,
	jobSearchStatusOptions,
	jobTypes,
	waysToHelpOptions,
} from "../../../seed/job-preferences-data";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import Loader from "../../Loader";

function JobRecommended() {
	const navigate = useNavigate();
	const location = useLocation();
	const { pathname } = location;
	const dispatch = useDispatch();
	const [submitting, setSubmitting] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [resumeTitle, setResumeTitle] = React.useState("");
	const [resumeList, setResumeList] = React.useState([]);
	const [errors, setError] = React.useState([]);
	const [successMessage, setSuccessMessage] = React.useState("");
	const storedResumes = localStorage.getItem("resumeList");
	const storedJobPreferences = localStorage.getItem("jobPreferences");
	const resumeDataService = React.useMemo(() => new ResumeDataService(), []);
	const [jobPreferences, setJobPreferences] = React.useState({});
	const [completenessDetails, setCompletenessDetails] = React.useState([]);
	const [completenessPercentage, setCompletenessPercentage] = React.useState(0);
	const data = useSelector((state) => state.SettingsReducer);
	const [jobPreferencesBody, setJobPreferencesBody] = React.useState({});

	function onSuccess(response) {
		if ([...response.data].length === 0) {
			setResumeList([]);
		} else {
			setResumeList(response.data);
			localStorage.setItem("resumeList", JSON.stringify(response.data));
		}
	}

	function onError(error) {
		const errorMessages = error.response.data.message;
		setError(errorMessages);
	}

	const fetchResumeList = React.useCallback(async () => {
		if (storedResumes) {
			setResumeList(JSON.parse(storedResumes));
		} else {
			await resumeDataService.getUserResumes(onSuccess, onError);
		}
	}, [storedResumes, resumeDataService]);

	const onSuccessSettings = React.useCallback(
		(response) => {
			const general = { ...data.general, email: response?.data?.emailAddress };
			const primaryResume = resumeList.filter(
				(item) => item._id === response?.data?.jobPreferences?.primaryResumeReferenceId,
			);
			if (primaryResume.length === 1) {
				const resumeTitle = primaryResume[0]?.journal?.resumeTitle || primaryResume[0]?.heading?.resumeTitle;
				setResumeTitle(resumeTitle);
			}

			dispatch({ type: "EDIT_ GENERAL", payload: general });
			setJobPreferences(response?.data?.jobPreferences);
			setJobPreferencesBody({
				emailAddress: response?.data?.emailAddress,
				phoneNumber: data?.general?.phone,
				jobPreferences: {
					primaryResumeReferenceId: response?.data?.jobPreferences?.primaryResumeReferenceId,
					currentJobsearchStatus: response?.data?.jobPreferences?.currentJobsearchStatus,
					waysToHelpme: response?.data?.jobPreferences?.waysToHelpme,
					openForWorkTypes: response?.data?.jobPreferences?.openForWorkTypes,
					areasOfExpertise: response?.data?.jobPreferences?.areasOfExpertise,
					locationPreferenceType: response?.data?.jobPreferences?.locationPreferenceType,
					locationsPreferred: response?.data?.jobPreferences?.locationsPreferred,
				},
			});
			setCompletenessDetails(response?.data?.job_preference_progress_status?.completeness_details);
			setCompletenessPercentage(response?.data?.job_preference_progress_status?.completeness_pct);
			if (response.data) {
				localStorage.setItem("jobPreferences", JSON.stringify(response?.data));
			}
			setLoading(false);
		},
		[data, dispatch, resumeList],
	);

	const onErrorSettings = React.useCallback(() => {
		window.location.replace("/signin");
	}, []);

	const fetchUsersAccount = React.useCallback(() => {
		setLoading(true);
		if (storedJobPreferences) {
			const storedData = JSON.parse(storedJobPreferences);
			const general = { ...data.general, email: storedData?.emailAddress };
			const primaryResume = resumeList.filter(
				(item) => item._id === storedData?.jobPreferences?.primaryResumeReferenceId,
			);
			if (primaryResume.length === 1) {
				const resumeTitle = primaryResume[0]?.journal?.resumeTitle || primaryResume[0]?.heading?.resumeTitle;
				setResumeTitle(resumeTitle);
			}

			dispatch({ type: "EDIT_ GENERAL", payload: general });
			setJobPreferences(storedData?.jobPreferences);
			setJobPreferencesBody({
				emailAddress: storedData?.emailAddress,
				phoneNumber: data?.general?.phone,
				jobPreferences: {
					primaryResumeReferenceId: storedData?.jobPreferences?.primaryResumeReferenceId,
					currentJobsearchStatus: storedData?.jobPreferences?.currentJobsearchStatus,
					waysToHelpme: storedData?.jobPreferences?.waysToHelpme,
					openForWorkTypes: storedData?.jobPreferences?.openForWorkTypes,
					areasOfExpertise: storedData?.jobPreferences?.areasOfExpertise,
					locationPreferenceType: storedData?.jobPreferences?.locationPreferenceType,
					locationsPreferred: storedData?.jobPreferences?.locationsPreferred,
				},
			});
			setCompletenessDetails(storedData?.job_preference_progress_status?.completeness_details);
			setCompletenessPercentage(storedData?.job_preference_progress_status?.completeness_pct);
			setLoading(false);
		} else {
			resumeDataService.getUser(onSuccessSettings, onErrorSettings);
		}
	}, [resumeDataService, onSuccessSettings, onErrorSettings, dispatch, data, resumeList, storedJobPreferences]);

	const updateJobPreferences = (body) => {
		if (!submitting) {
			setSubmitting(true);
			resumeDataService.jobPreferences(
				body,
				(data) => {
					setJobPreferences(data?.data?.jobPreferences);
					setJobPreferencesBody(body);
					setCompletenessDetails(data?.data?.job_preference_progress_status?.completeness_details);
					setCompletenessPercentage(data?.data?.job_preference_progress_status?.completeness_pct);
					setSuccessMessage("Updated successfully");
					localStorage.setItem("jobPreferences", JSON.stringify(data?.data));
					setError([]);
					setSubmitting(false);
				},
				(error) => {
					const errorMessages = error.response.data.message;
					setError(errorMessages);
					setSubmitting(false);
				},
			);
		}
	};

	React.useEffect(() => {
		fetchResumeList();
	}, [fetchResumeList]);

	React.useEffect(() => {
		fetchUsersAccount();
	}, [fetchUsersAccount]);

	return (
		<div className="col-lg-3">
			{loading && <Loader />}
			{!loading && (
				<>
					<div className="d-flex p-2 mt-3 mt-lg-0" style={{ backgroundColor: "#e6f2ff" }}>
						<div className="pr-2">
							<div
								className="rounded-circle bg-light d-flex align-items-center justify-content-center"
								style={{ width: "20px", height: "20px" }}
							>
								<i className="fa fa-briefcase text-primary" />
							</div>
						</div>
						<div className="text-wrap">
							<p className="text-sm font-medium">
								Job-Preferences {completenessPercentage ? completenessPercentage : 0}% complete
							</p>
						</div>
					</div>
					{pathname !== "/jobs-preference" && (
						<>
							{errors.length > 0 && (
								<div className="alert alert-danger mb-2">
									<ul className="mb-0">
										{errors.map((error, index) => (
											<li key={index}>{error}</li>
										))}
									</ul>
								</div>
							)}
							{successMessage !== "" && <StatusToast message={successMessage} timeout={5000} />}
							<div className="row no-gutters">
								<label className="col-md-3" htmlFor="RecommendDropdown">
									Job Status
								</label>
								<div className="col-md-9 dropdown mb-3">
									<button
										className="btn btn-block text-dark bg-white border border-secondary rounded-sm px-3 py-2 d-flex justify-content-between"
										type="button"
										id="JobStatusDropdown"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<span>
											<span
												className={`${
													jobSearchStatusOptions[
														jobPreferences?.currentJobsearchStatus
															? jobSearchStatusOptions.findIndex(
																	(item) => item.value === jobPreferences?.currentJobsearchStatus,
																)
															: 0
													].classIcon
												} mr-3`}
											/>
											{jobPreferences?.currentJobsearchStatus ? jobPreferences?.currentJobsearchStatus : ""}
										</span>
										<span className="fa fa-chevron-down" />
										zx
									</button>
									<div className="dropdown-menu w-100" aria-labelledby="JobStatusDropdown">
										{jobSearchStatusOptions.map((item, index) => {
											return (
												<div
													key={index}
													className="dropdown-item cursor-pointer"
													onClick={() => {
														updateJobPreferences({
															...jobPreferencesBody,
															jobPreferences: {
																...jobPreferencesBody?.jobPreferences,
																currentJobsearchStatus: item.value,
															},
														});
														setJobPreferences({
															...jobPreferences,
															currentJobsearchStatus: item.value,
														});
													}}
												>
													<div className="d-flex">
														<div className="pr-2">
															<div
																className="rounded-circle bg-light d-flex align-items-center justify-content-center"
																style={{ width: "20px", height: "20px" }}
															>
																<i className={item.classIcon} />
															</div>
														</div>
														<div className="text-wrap">
															<p className="h5">{item.value}</p>
															<p>{item.description}</p>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>

							<div className="row no-gutters">
								<label className="col-md-3" htmlFor="RecommendDropdown">
									Ways To Help
								</label>
								<div className="col-md-9 dropdown mb-3">
									<button
										className="btn btn-block text-dark bg-white border border-secondary rounded-sm px-3 py-2 d-flex justify-content-between"
										type="button"
										id="RecommendDropdown"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<span>
											<span
												className={`${
													waysToHelpOptions[
														jobPreferences?.waysToHelpme
															? waysToHelpOptions.findIndex((item) => item.value === jobPreferences?.waysToHelpme)
															: 0
													].classIcon
												} mr-3`}
											/>
											{jobPreferences?.waysToHelpme ? jobPreferences?.waysToHelpme : ""}
										</span>
										<span className="fa fa-chevron-down" />
									</button>
									<div className="dropdown-menu w-100" aria-labelledby="RecommendDropdown">
										{waysToHelpOptions.map((item, index) => {
											return (
												<div
													key={index}
													className="dropdown-item cursor-pointer"
													onClick={() => {
														updateJobPreferences({
															...jobPreferencesBody,
															jobPreferences: {
																...jobPreferencesBody?.jobPreferences,
																waysToHelpme: item.value,
															},
														});
														setJobPreferences({
															...jobPreferences,
															waysToHelpme: item.value,
														});
													}}
												>
													<div className="d-flex">
														<div className="pr-2">
															<div
																className="rounded-circle bg-light d-flex align-items-center justify-content-center"
																style={{ width: "20px", height: "20px" }}
															>
																<i className={item.classIcon} />
															</div>
														</div>
														<div className="text-wrap">
															<p className="h5">{item.value}</p>
															<p>{item.description}</p>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>

							<div className="row no-gutters">
								<label className="col-md-3" htmlFor="RecommendDropdown">
									Primary Resume
								</label>
								<div className="col-md-9 dropdown mb-3">
									<button
										className="btn btn-block text-dark bg-white border border-secondary rounded-sm px-3 py-2 d-flex justify-content-between"
										type="button"
										id="ResumeDropdown"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<span>{resumeTitle}</span>
										<span className="fa fa-chevron-down" />
									</button>
									<div className="dropdown-menu w-100" aria-labelledby="ResumeDropdown">
										{resumeList.map((item, index) => {
											const resumeTitle = item.journal.resumeTitle || item.heading.resumeTitle;

											return (
												<div
													key={index}
													className="dropdown-item cursor-pointer"
													onClick={() => {
														const resumeTitle = item.journal.resumeTitle || item.heading.resumeTitle;
														const resumeId = item._id;
														setResumeTitle(`${resumeTitle}`);
														updateJobPreferences({
															...jobPreferencesBody,
															jobPreferences: {
																...jobPreferencesBody?.jobPreferences,
																primaryResumeReferenceId: resumeId,
															},
														});
														setJobPreferences({
															...jobPreferences,
															primaryResumeReferenceId: resumeId,
														});
													}}
												>
													<div className="pr-2">
														<p className="text-wrap">
															{`${resumeTitle} - Updated on ${dayjs(item.journal.lastUpdatedOn).format(
																"DD MMMM, YYYY",
															)}`}
														</p>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>

							<div className="row no-gutters">
								<label className="col-md-3" htmlFor="RecommendDropdown">
									Job Types
								</label>
								<div className="col-md-9 dropdown mb-3">
									<button
										className="btn btn-block text-dark bg-white border border-secondary rounded-sm px-3 py-2 d-flex justify-content-between"
										type="button"
										id="JobTypesDropdown"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<span>{jobPreferences?.openForWorkTypes ? jobPreferences?.openForWorkTypes?.join(", ") : ""}</span>
										<span className="fa fa-chevron-down" />
									</button>
									<div className="dropdown-menu w-100" aria-labelledby="JobTypesDropdown">
										{jobTypes.map((item, index) => {
											return (
												<div
													key={index}
													className="dropdown-item cursor-pointer"
													onClick={(event) => {
														event.stopPropagation();
														updateJobPreferences({
															...jobPreferencesBody,
															jobPreferences: {
																...jobPreferencesBody?.jobPreferences,
																openForWorkTypes: !jobPreferencesBody?.jobPreferences?.openForWorkTypes
																	? [item]
																	: jobPreferences?.openForWorkTypes?.includes(item)
																		? jobPreferencesBody?.jobPreferences?.openForWorkTypes.filter(
																				(jobType) => jobType !== item,
																			)
																		: [...jobPreferencesBody.jobPreferences.openForWorkTypes, item],
															},
														});
														setJobPreferences({
															...jobPreferences,
															openForWorkTypes: !jobPreferencesBody?.jobPreferences?.openForWorkTypes
																? [item]
																: jobPreferences?.openForWorkTypes?.includes(item)
																	? jobPreferencesBody?.jobPreferences?.openForWorkTypes.filter(
																			(jobType) => jobType !== item,
																		)
																	: [...jobPreferencesBody.jobPreferences.openForWorkTypes, item],
														});
													}}
												>
													<div key={index} className="form-check d-flex align-items-center mb-2">
														<input
															type="checkbox"
															className="form-check-input cursor-pointer"
															id={`job-type-${index}`}
															checked={jobPreferences?.openForWorkTypes?.includes(item)}
														/>
														<label className="form-check-label ml-2 fs-17 cursor-pointer" htmlFor={`job-type-${index}`}>
															{item}
														</label>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
							<button
								className="btn btn-block text-dark rounded-sm px-3 py-2 mt-3"
								style={{ backgroundColor: "#F0F0F0" }}
								onClick={() => navigate("/jobs-preference")}
							>
								<span className="fa fa-pen mr-3" />
								Edit Job Preferences
							</button>
						</>
					)}
					<div className="p-3" style={{ backgroundColor: "#F9F9F9" }}>
						<p className="text-sm mb-3">
							Complete your profile to get discovered by companies, developers, and easily apply to jobs
						</p>
						<button className="btn btn-primary mb-3 text-sm" onClick={() => navigate("/my-resumes")}>
							Complete Resume
						</button>
						<p className="text-sm font-medium mb-3">Recommended</p>

						{completenessDetails &&
							completenessDetails.map((item, index) => {
								return (
									<div key={index} className="d-flex mb-3">
										<p className="text-sm font-medium pr-3">{index + 1}</p>
										<div className="text-wrap">
											<p className="text-sm font-medium">
												{jobPreferencesTitles(item.key)}
												{item.isComplete ? (
													<i className="fa fa-check ml-2 text-success" />
												) : (
													<i className="fa fa-close ml-2 text-danger" />
												)}
											</p>
											<p className="text-sm">{item.message}</p>
										</div>
									</div>
								);
							})}
					</div>
				</>
			)}
		</div>
	);
}

export default JobRecommended;
