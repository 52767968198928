const sections = {
	HEADING: "HEADING",
	EXPERIENCE: "EXPERIENCE",
	EDUCATION: "EDUCATION",
	SKILLS: "SKILLS",
	SUMMARY: "SUMMARY",
	REFERENCE: "REFERENCE",
	CERTIFICATION: "CERTIFICATION",
	AFFILIATION: "AFFILIATION",
	ADDITIONAL_INFO: "ADDITIONAL_INFO",
	LANGUAGES: "LANGUAGES",
	ACHIEVEMENTS: "ACHIEVEMENTS",
	PERSONAL: "PERSONAL",
	LINKS: "LINKS",
	CUSTOM: "CUSTOM",
	ACCOMPLISHMENTS: "ACCOMPLISHMENTS",
};

const interactions = {
	VIEWED: "Viewed",
	SAVED_FOR_LATER: "Saved",
	APPLYING: "Applying",
	APPLIED: "Applied",
	WITHDRAW_APPLICATION: "Withdrew",
	ADD_NOTES: "Notes",
	NOT_RELEVANT: "Not Relevant",
};

export { interactions, sections };
