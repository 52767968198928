import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import Loader from "../../../components/Loader";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import JobNavLayout from "../JobMarketLayout/JobMarketNavLayout/Layout";

function Jobs() {
	const jobService = new ResumeDataService();

	const navigate = useNavigate();

	const [jobs, setJobs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState("");

	function getJobs() {
		jobService.getJobs(
			(response) => {
				setLoading(false);
				setJobs(response.data);
			},
			(error) => {
				setLoading(false);
				setErrors(error);
			},
		);
	}

	function publishJob(id) {
		jobService.publishJobPost(
			id,
			() => {
				setJobs([]);
				setLoading(true);
				getJobs();
			},
			(error) => {
				setErrors(error);
			},
		);
	}

	function deleteJob(id) {
		jobService.deleteJobById(
			id,
			() => {
				setJobs([]);
				setLoading(true);
				getJobs();
			},
			(error) => {
				setErrors(error);
			},
		);
	}

	useEffect(() => {
		getJobs();
	}, []);

	const [jobStatus, setJobStatus] = useState("Draft"); // Draft, Posted
	const [filteredJobs, setFilteredJobs] = useState([]);

	useEffect(() => {
		const data = jobs.filter((job) => job.jobStatus === jobStatus);
		setFilteredJobs(data);
	}, [jobStatus, jobs]);

	const [searchValue, setSearchValue] = useState("");
	const [searchResults, setSearchResults] = useState(filteredJobs);

	useEffect(() => {
		const results = filteredJobs.filter(
			(job) =>
				job.jobTitle.toLowerCase().includes(searchValue.toLowerCase()) ||
				job.companyName.toLowerCase().includes(searchValue.toLowerCase()),
		);
		setSearchResults(results);
	}, [jobs, filteredJobs, searchValue]);

	const today = dayjs();
	return (
		<JobNavLayout header="Post a Job">
			<div className="my-resumes-section">
				<div className="container">
					{loading ? (
						<Loader />
					) : (
						<>
							{errors && (
								<div className="alert alert-danger" role="alert">
									{errors}
								</div>
							)}
							<ul className="nav nav-tabs">
								<li className="nav-item">
									<div
										className={`nav-link ${jobStatus === "Draft" && "active"}`}
										onClick={() => setJobStatus("Draft")}
									>
										Draft
									</div>
								</li>
								<li className="nav-item">
									<div
										className={`nav-link ${jobStatus === "Posted" && "active"}`}
										onClick={() => setJobStatus("Posted")}
									>
										Posted
									</div>
								</li>
							</ul>
							<div className="my-3 border-light">
								<input
									type="text"
									placeholder="Search by job title"
									className="form-control"
									value={searchValue}
									onChange={(e) => setSearchValue(e.target.value)}
								/>
							</div>
							<div className="row px-3">
								{searchResults.length > 0 ? (
									<>
										{searchResults.map((job) => (
											<div
												// to={`/jobs/${job._id}`}
												className="card mb-3 col-12"
												key={job._id}
											>
												<div className="card-body row">
													<div className="d-flex align-items-center col-8">
														<div
															className="company-image mr-3 rounded-circle border d-flex align-items-center justify-content-center"
															style={{
																fontSize: "2.4rem",
																height: "5rem",
																width: "5rem",
															}}
														>
															<i className="bi bi-buildings" />
														</div>
														<div className="">
															<Link className="d-flex align-items-center align-middle mb-1" to={`job/${job._id}`}>
																<h5 className="card-title m-0">{job.jobTitle}</h5>
																<h4 className="text-secondary mx-2 lh-1"> - </h4>
																<h5 className="card-subtitle m-0 lh-1 fw-normal">
																	{job.jobLocation.city},{" "}
																	{job.jobLocation.city === job.jobLocation.region
																		? job.jobLocation.country
																		: job.jobLocation.region}
																</h5>
															</Link>
															<div className="d-flex align-items-center align-middle">
																<p className="m-0 card-text text-dark lh-1">{job.companyName}</p>
																<p className="m-0 text-secondary mx-1 lh-1"> - </p>
																<p className="m-0 card-text text-muted lh-1">
																	Created on: {dayjs(job.createdAt).format("MMMM D, YYYY")}
																</p>
															</div>
															{job.closeAt && ( //Check if it&#39;s in the past "Closed on", In the future, show days, if less than 1 day in hours
																<p className="badge bg-success mt-2">
																	{dayjs(job.closeAt).diff(today, "day") < 0 && (
																		<>Closed on: {dayjs(job.closeAt).format("MMMM D, YYYY")}</>
																	)}
																	{dayjs(job.closeAt).diff(today, "day") >= 1 &&
																		`Closes in ${dayjs(job.closeAt).diff(today, "day")} days`}
																	{dayjs(job.closeAt).diff(today, "hour") < 24 &&
																		dayjs(job.closeAt).diff(today, "hour") > 0 &&
																		`Closes in ${dayjs(job.closeAt).diff(today, "hour")} hours`}
																</p>
															)}
															<div className="d-flex mt-3">
																<Dropdown>
																	<Dropdown.Toggle
																		variant="outline-dark text-dark px-4 me-2"
																		id="dropdown-basic"
																		className="btn-sm rounded-pill"
																	>
																		Manage
																	</Dropdown.Toggle>
																	<Dropdown.Menu>
																		<Dropdown.Item onClick={() => navigate("/job-market/post/" + job._id)}>
																			Edit
																		</Dropdown.Item>
																		{job.jobStatus.toLowerCase() === "draft" && (
																			<Dropdown.Item onClick={() => deleteJob(job._id)}>Delete</Dropdown.Item>
																		)}
																	</Dropdown.Menu>
																</Dropdown>
																{job.jobStatus === "Draft" && (
																	<button
																		className="btn btn-sm ml-3 btn-primary rounded-pill p-0 px-4 lh-1 d-flex align-items-center justify-content-center"
																		style={{
																			fontSize: "0.9rem",
																		}}
																		onClick={() => publishJob(job._id)}
																	>
																		Post{" "}
																		<i
																			className="bi bi-send-fill ml-2 p-0"
																			style={{
																				fontSize: "1rem",
																			}}
																		/>
																	</button>
																)}
															</div>
														</div>
													</div>
													<div className="col-4 d-flex align-items-center justify-content-start">
														<h5
															className={`${
																job.jobStatus === "Draft" ? "text-warning" : "text-success"
															} text-uppercase`}
														>
															{job.jobStatus}
														</h5>
													</div>
												</div>
											</div>
										))}
									</>
								) : (
									<center>
										<h3>No {jobStatus} jobs found</h3>
									</center>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</JobNavLayout>
	);
}

export default Jobs;
