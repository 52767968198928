import React from "react";

import { convertDateToWords, convertUlToArray } from "../../../../handlers/utilityFunctions";

function ResumePremiumTwo(props) {
	const { isReview, resumeData } = props;

	return (
		<div className="bg-white final-resume-preview-wrapper final-premium-template-2 final-resume-preview-new shadow-sm">
			<div className="text-dark">
				<div className="container mt-3">
					{resumeData.contactInfo.firstName !== "" && (
						<div className="d-flex justify-content-between align-items-center  mb-3">
							<div className="info-wrapper" style={{ flex: "0 0 50%" }}>
								<div className="d-flex">
									<span className="mr-3 d-inline-block mt-2 border-icon" style={{ backgroundColor: "#000" }} />
									<div>
										<h1 className="mb-0" style={{ color: "#003366" }}>
											{resumeData.contactInfo.firstName} {isReview} {resumeData.contactInfo.middleName}{" "}
											{resumeData.contactInfo.lastName}
										</h1>
										<h3
											className="mb-2"
											style={{
												color: "#9b9b9b",
												fontWeight: 500,
											}}
										>
											{resumeData.contactInfo.resumeTitle}
										</h3>
										<p
											className="mb-2"
											dangerouslySetInnerHTML={{
												__html: resumeData.summary,
											}}
										/>
									</div>
								</div>
							</div>
							<div>
								<p className="mb-2 mt-4 text-right">
									{resumeData.contactInfo.email}
									<i className="ml-2 fa fa-envelope" aria-hidden="true" style={{ color: "#003366" }} />
								</p>
								<p className="mb-2 text-right">
									{resumeData.contactInfo.phoneNo}
									<i className="ml-2 fa fa-mobile" aria-hidden="true" />
								</p>
								<p className="mb-2 text-right">
									{resumeData.contactInfo.city}, {resumeData.contactInfo.country}
									<i className="ml-2 fa fa-map-marker" aria-hidden="true" />
								</p>
								{[...resumeData.extras.personalLinks].map((link, index) => {
									return link.type !== "otherLink" ? (
										<p key={index} className="mb-2 text-right">
											{link?.value?.replace("https://", "")?.replace("www.", "")}
											<i className="ml-2 fa fa-link" aria-hidden="true" />
										</p>
									) : null;
								})}
							</div>
						</div>
					)}
					<div className="row" style={{ borderTop: "3px solid #ababab" }}>
						<div className="mb-3 col-6">
							{resumeData.extras.accomplishments !== "" ? (
								<div className="mb-3">
									<h4 className="text-uppercase mt-3 font-weight-bold">ACCOMPLISHMENTS</h4>

									<div style={{ fontWeight: 500 }}>
										{convertUlToArray(resumeData.extras.accomplishments).map((item, index) => {
											return (
												<div key={index} className="mb-3">
													<p>{item.value}</p>
												</div>
											);
										})}
									</div>
								</div>
							) : null}

							{resumeData.workHistory.length > 0 && resumeData.workHistory[0].jobTitle !== "" ? (
								<>
									{[...resumeData.workHistory].map((job, index) => (
										<div key={index} className="d-flex">
											<span className="mr-4 d-inline-block mt-5 border-icon" style={{ backgroundColor: "#8e8e8e" }} />
											<div>
												{index === 0 && <h4 className="text-uppercase mt-3 font-weight-bold">WORK EXPERIENCE</h4>}
												<div className="d-flex justify-content-between">
													<p className="mb-2">
														<strong>{job.employer}</strong>
														{" - "}
														{job.city}, {job.country}
														<br />
														<em>{job.jobTitle}</em>
													</p>
													<p className="mb-0">
														<strong>
															{convertDateToWords(job.startDate)} -{" "}
															{job.endDate !== "" ? convertDateToWords(job.endDate) : "Present"}
														</strong>
													</p>
												</div>
												<ul className="px-3">
													{convertUlToArray(job.details).map((item, index) => {
														return (
															<li key={index} className="mb-2">
																{item.value}
															</li>
														);
													})}
												</ul>
											</div>
										</div>
									))}
								</>
							) : null}

							{resumeData.education.length > 0 && (
								<div>
									{[...resumeData.education].map((educationHistory, index) => (
										<div key={index} className="d-flex align-items-center">
											<span className="mr-4 d-inline-block border-icon" style={{ backgroundColor: "#8e8e8e" }} />
											<div>
												{index === 0 && <h4 className="text-uppercase mt-3 mb-0 font-weight-bold">EDUCATION</h4>}
												<div className="d-flex justify-content-between">
													<p>
														<strong>{educationHistory.schoolName}</strong>
														{" - "}
														{educationHistory.schoolCity}, <br />
														<em>{educationHistory.fieldOfStudy}</em>
													</p>
													<p className="mr-4">
														<strong>{educationHistory.gYear}</strong>
													</p>
												</div>
												<div
													className="pl-3"
													dangerouslySetInnerHTML={{
														__html: educationHistory.description,
													}}
												/>
											</div>
										</div>
									))}
								</div>
							)}

							{resumeData.extras.languages.length !== 0 ? (
								<div className="mb-3">
									<h4 className="text-uppercase mt-3 mb-0 font-weight-bold">LANGUAGES</h4>
									<div style={{ fontWeight: 500 }}>
										{resumeData.extras.languages.map((language, index) => (
											<div key={index}>
												<span className="mr-2">{language.language}</span>
												{" - "}
												<span className="mr-2">{language.proficiency}</span>
											</div>
										))}
									</div>
								</div>
							) : null}

							{resumeData.extras.personalProfile.dateOfBirth && (
								<div>
									<h4 className="text-uppercase mt-3 font-weight-bold">PERSONAL INFORMATION</h4>

									<div>
										<div className="mb-3">
											<strong>Date of Birth:</strong>&nbsp;
											{resumeData.extras.personalProfile.dateOfBirth}
										</div>
										<div className="mb-3">
											<strong>Gender:</strong>&nbsp;
											{resumeData.extras.personalProfile.gender}
										</div>
										<div className="mb-3">
											<strong>Marital Status:</strong>&nbsp;
											{resumeData.extras.personalProfile.maritalStatus}
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="mb-4 col-6 pl-3 pl-md-5">
							<div className="mb-3 p-0 row m-0">
								{resumeData.skills !== "" ? (
									<>
										<h4 className="text-uppercase mt-3 font-weight-bold">skills</h4>
										<div className="row px-0">
											{convertUlToArray(resumeData.skills).map((item, index) => {
												const splitArray = item.value.split(":");
												let wordBeforeColon = splitArray[0].trim();
												wordBeforeColon = splitArray.length > 1 ? <strong>{wordBeforeColon}</strong> : wordBeforeColon;
												const wordAfterColon = splitArray.length > 1 ? ": " + splitArray[1].trim() : "";

												return (
													<div key={index} className="col-6 mb-1">
														{wordBeforeColon}
														{wordAfterColon}
													</div>
												);
											})}
										</div>
									</>
								) : null}

								{resumeData.extras.certifications !== "" ? (
									<div className="mb-3">
										<h4 className="text-uppercase mt-3 font-weight-bold">CERTIFICATIONS</h4>

										<div style={{ fontWeight: 500 }}>
											{convertUlToArray(resumeData.extras.certifications).map((item, index) => {
												return (
													<div key={index} className="mb-3">
														<p>{item.value}</p>
													</div>
												);
											})}
										</div>
									</div>
								) : null}

								{resumeData.extras.affiliations !== "" ? (
									<div className="mb-3">
										<h4 className="text-uppercase mt-3 font-weight-bold">AFFILIATIONS</h4>

										<h6 style={{ fontWeight: 500 }}>
											{convertUlToArray(resumeData.extras.affiliations).map((item, index) => {
												return (
													<div key={index}>
														<p>{item.value}</p>
													</div>
												);
											})}
										</h6>
									</div>
								) : null}

								{[...resumeData.extras.personalLinks].length > 0 && (
									<div className="mb-3">
										<h4 className="text-uppercase mt-3 font-weight-bold">WEBSITE, PORTFOLIO, PROFILES</h4>

										<h6 style={{ fontWeight: 500 }}>
											{[...resumeData.extras.personalLinks].map((link, index) => {
												return link.type === "otherLink" ? (
													<div key={index}>
														<span className="text-capitalize">{link.type}</span>: {link.value}
													</div>
												) : null;
											})}
										</h6>
									</div>
								)}

								{resumeData.extras.references.length > 0 ? (
									<div className="mb-3">
										<h4 className="text-uppercase mt-3 font-weight-bold">REFERENCES</h4>

										<div className="d-flex flex-wrap">
											{[...resumeData.extras.references].map((reference, index) => (
												<div key={index} className="mb-3">
													<div>
														<strong>{reference.name}</strong>
														{" - "}
														<em>{reference.designation}, </em>
														{reference.workPlace}
														<br />
														<p style={{ color: "#bcbcbc" }}>
															{reference.email}, {reference.phoneNumber}
														</p>
													</div>
												</div>
											))}
										</div>
									</div>
								) : null}

								{resumeData.extras.additionalInfo !== "" ? (
									<div className="mb-3">
										<h4 className="text-uppercase mt-3 font-weight-bold">ADDITIONAL INFORMATION</h4>

										<h6 style={{ fontWeight: 500 }}>
											{convertUlToArray(resumeData.extras.additionalInfo).map((item, index) => {
												return (
													<div key={index}>
														<p>{item.value}</p>
													</div>
												);
											})}
										</h6>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ResumePremiumTwo;
