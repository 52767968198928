import "./Sidebar.css";

import { googleLogout } from "@react-oauth/google";
import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import CustomLink from "../../../../components/CustomLink";

const Sidebar = ({ onToggle, hideSidebar, isCollapsed }) => {
	const { firstName } = JSON.parse(localStorage.getItem("profileData"))
		? JSON.parse(localStorage.getItem("profileData"))
		: { firstName: "" };

	const storeNavigationFlow = (clear, shortUrl) => {
		var updatedNavFlowData = {
			clickedPage: shortUrl,
		};
		if (!clear) {
			updatedNavFlowData = {
				clickedPage: "",
			};
		}

		localStorage.setItem(`navFlowData${firstName}`, JSON.stringify(updatedNavFlowData));
	};

	return (
		<div
			className={` ${isCollapsed ? "collapsed-nav" : "nav-sidebar"} ${hideSidebar ? "collapsed-nav" : "nav-sidebar"}}`}
		>
			<Link
				className={` ${isCollapsed ? "navbar-brand-collapsed" : "navbar-brand"} ${
					hideSidebar ? "navbar-brand-collapsed " : "navbar-brand"
				}}`}
				to="/"
			>
				<img
					src="/images/logo.png"
					alt="Logo"
					className={` ${isCollapsed ? "navbar-brand-collapsed-img" : "navbar-brand-img"} ${
						hideSidebar ? "navbar-brand-collapsed-img" : "navbar-brand-img"
					}}`}
				/>
			</Link>

			{!(isCollapsed || hideSidebar) ? (
				<ul
					style={{
						marginLeft: "5px",
					}}
				>
					<CustomLink
						to="/job-market"
						onClick={() => {
							storeNavigationFlow(true, "dashboard", "");
						}}
					>
						<li data-tooltip-id="my-nice-tooltip" data-tooltip-content="Jobs" className="sidebar-li-style">
							<i className="fas fa-folder iconStyle" />
							<span className="linkText ">Jobs</span>
						</li>
					</CustomLink>

					<CustomLink
						to="/job-market/post"
						onClick={() => {
							storeNavigationFlow(true, "job-market/post", "");
						}}
					>
						<li data-tooltip-id="my-nice-tooltip" data-tooltip-content="Post Job" className="sidebar-li-style">
							<i className="fas fa-folder-plus iconStyle" />
							<span className="linkText ">Post Job without JD</span>
						</li>
					</CustomLink>

					<CustomLink
						to="/job-market/companies"
						onClick={() => {
							storeNavigationFlow(true, "job-market/companies", "");
						}}
					>
						<li data-tooltip-id="my-nice-tooltip" data-tooltip-content="Companies" className="sidebar-li-style">
							<i className="fa-regular fa-building iconStyle" />
							<span className="linkText ">Companies</span>
						</li>
					</CustomLink>
					<CustomLink to="/job-market/jds">
						<li data-tooltip-id="my-nice-tooltip" data-tooltip-content="Job Descriptions">
							<i className="fas fa-file-alt iconStyle" />
							<span className="linkText">Job Descriptions</span>
						</li>
					</CustomLink>
					<CustomLink to="/job-market/shared-leads">
						<li data-tooltip-id="my-nice-tooltip" data-tooltip-content="Shared Leads">
							<i className="fa-solid fa-users-between-lines iconStyle" />
							<span className="linkText">Shared Leads</span>
						</li>
					</CustomLink>
				</ul>
			) : (
				<ul>
					<CustomLink
						to="/job-market"
						onClick={() => {
							storeNavigationFlow(true, "dashboard", "");
						}}
					>
						<li data-tooltip-id="my-nice-tooltip" data-tooltip-content="Jobs" className="sidebar-li-style">
							<i className="fas fa-folder iconStyle" />
							<span className="linkText ">Jobs</span>
						</li>
					</CustomLink>

					<CustomLink
						to="/job-market/post"
						onClick={() => {
							storeNavigationFlow(true, "job-market/post", "");
						}}
					>
						<li data-tooltip-id="my-nice-tooltip" data-tooltip-content="Post Job" className="sidebar-li-style">
							<i className="fas fa-folder-plus iconStyle" />
							<span className="linkText ">Post Job</span>
						</li>
					</CustomLink>

					<CustomLink
						to="/job-market/companies"
						onClick={() => {
							storeNavigationFlow(true, "job-market/companies", "");
						}}
					>
						<li data-tooltip-id="my-nice-tooltip" data-tooltip-content="Companies" className="sidebar-li-style">
							<i className="fa-regular fa-building iconStyle" />
							<span className="linkText ">Companies</span>
						</li>
					</CustomLink>
					<CustomLink to="/job-market/jds">
						<li data-tooltip-id="my-nice-tooltip" data-tooltip-content="Job Descriptions">
							<i className="fas fa-file-alt iconStyle" />
							<span className="linkText">Job Descriptions</span>
						</li>
					</CustomLink>
				</ul>
			)}

			<ul className="bottom-nav">
				<ul
					className={` ${isCollapsed ? "bottom-nav-on-collapse-ul-icon" : "bottom-nav-collapse-ul"} ${
						hideSidebar ? "bottom-nav-on-collapse-ul-icon" : "bottom-nav-collapse-ul"
					}}`}
				>
					<CustomLink to="/signin" onClick={googleLogout()} className="sidebar-li-style">
						<li data-tooltip-id="my-nice-tooltip" data-tooltip-content="Log Out">
							<i className="fas fa-sign-out-alt iconStyle" />
							<span className="linkText">Log Out</span>
						</li>
					</CustomLink>
					<li
						data-tooltip-id="my-nice-tooltip"
						data-tooltip-content={isCollapsed ? "Expand" : "Collapse"}
						onClick={() => {
							onToggle();
						}}
						className="d-flex align-items-center justify-content-center"
					>
						<i
							className={`fas ${isCollapsed ? "fa-angle-double-right" : "fa-angle-double-left"}`}
							style={{ backgroundColor: "transparent" }}
						/>
					</li>
				</ul>
			</ul>
			<Tooltip
				id="my-nice-tooltip"
				style={{
					zIndex: 1000,
					position: "fixed",
				}}
			/>
		</div>
	);
};

export default Sidebar;
