import React from "react";

import { convertUlToArray } from "../../../handlers/utilityFunctions";

const EditorListItems = (props) => {
	const editorListHandler = (data) => {
		//remove the button text from the li text content
		const liContent = data;
		let newEditorContent = "";

		//check if the editor content has an existing list
		const hasUl = props.editorContent.indexOf("<ul>");

		//if it has an existing list already
		if (hasUl !== -1) {
			//if the clicked item is present in the list
			if (props.editorContent.indexOf(liContent) !== -1) {
				//remove the item from the editor&#39;s list
				newEditorContent = props.editorContent.replace(`<li>${liContent}</li>`, "");
			} else {
				//add the item to the editors list
				newEditorContent = props.editorContent
					.substring(0, props.editorContent.indexOf("</ul>"))
					.concat("<li>", liContent, "</li></ul>");
			}
		} else {
			//create a new list and add a li
			newEditorContent = props.editorContent.concat("<ul><li>", liContent, "</li></ul>");
		}

		props.setEditorContent(newEditorContent);
	};

	const arrayedContent = convertUlToArray(props.editorContent);

	const theList = props.editorListItems.map((editorListItem) => {
		const presentItem = arrayedContent.findIndex((listItem) => listItem.value === editorListItem.title.trim());
		if (presentItem !== -1) {
			editorListItem.added = true;
		} else {
			editorListItem.added = false;
		}

		return (
			<li
				onClick={() => editorListHandler(editorListItem.title)}
				key={editorListItem.id}
				id={editorListItem.id}
				className="d-flex align-items-center fs-14 py-2 justify-content-between"
			>
				<span>{editorListItem.title}</span>
				<div className="ml-2">
					<button
						id={editorListItem.id}
						className={`btn ${editorListItem.added ? "bg-success text-white" : "bg-light-blue text-dark"} `}
					>
						{editorListItem.added ? <i className="bi bi-check mr-0 lh-1" /> : <i className="bi bi-plus mr-0 lh-1" />}
					</button>
				</div>
			</li>
		);
	});
	return <ul className="list-unstyled">{theList}</ul>;
};

export default EditorListItems;
