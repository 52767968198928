import React from "react";
import { Link } from "react-router-dom";

function AltFooter() {
	return (
		<div className="footer">
			<span className="back-to-top fa fa-angle-up" />
			<div className="footer-top section-padding">
				<div className="container">
					<div className="row">
						<div className="col-sm-4">
							<div className="footer-widget">
								<h3>About Us</h3>
								<ul className="tr-list">
									<li>
										<Link to="/about-us">About Us</Link>
									</li>
									<li>
										<Link to="/terms-and-conditions">Terms & Conditions</Link>
									</li>
									<li>
										<Link to="privacy-policy">Privacy Policy</Link>
									</li>
									{/* <li>
										<a href="/">Contact Us</a>
									</li> */}
								</ul>
							</div>
						</div>
						<div className="col-sm-4">
							<div className="footer-widget">
								<h3>Job Seekers</h3>
								<ul className="tr-list">
									<li>
										<Link to="/create-resume">Start Here</Link>
									</li>
									<li>
										<Link to="/settings">My Account</Link>
									</li>
									<li>
										<Link to="/faq">FAQs</Link>
									</li>
									{/* <li>
										<a href="https://www.youtube.com/@dprozforcandidates1272" target={"_blank"}>Video Guides</a>
									</li> */}
								</ul>
							</div>
						</div>
						<div className="col-sm-4">
							<div className="footer-widget">
								<h3>Employers</h3>
								<ul className="tr-list">
									<li>
										<Link to="/employer-landing">Start Here</Link>
									</li>
									<li>
										<a href="mailto:info@dproz.co.tz" className="text-primary">
											info@dproz.co.tz
										</a>
									</li>
									{/* <li>
										<a href="#">Products/Service</a>
									</li>
									<li>
										<a href="#">Post a Job</a>
									</li>
									<li>
										<a href="#">FAQ</a>
									</li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AltFooter;
