import functions from "./metadata/functionAndTitles.json";

const Titles = [];

functions.map((item) => {
	const list = [...item.titles];
	return Titles.push(...list);
});

export default Titles;
