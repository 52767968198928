import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import ErrorToast from "../../../../components/ErrorToast";
import Spinner from "../../../../components/Spinner";
import TextEditor from "../../../../components/TextEditor";
import ResumeContext from "../../../../context/ResumeContext";
import { resumeObjectToContextFormat } from "../../../../handlers/valueTransformation";
import useExtrasnav from "../../../../hooks/use-Extrasnav";
import useHasChange from "../../../../hooks/use-HasChange";
import { CustomExtra } from "../../../../models/resume/customExtra";
import { StaticValue } from "../../../../seed/constants";
import { ResumeDataService } from "../../../../services/create-resume/resume-data";

const CustomExtras = () => {
	const [cookies] = useCookies(["resume"]);
	const params = useParams();
	const navigate = useNavigate();
	const navigateTo = useExtrasnav();

	const staticValue = new StaticValue();
	const { hasChanges } = useHasChange();
	const resumeDataService = new ResumeDataService();
	const staticValues = staticValue.getDeafaulValues();
	const [loading, setLoading] = useState(false);
	const [serverErrors, setServerErrors] = useState([]);

	//Declaring the additional information state
	const { resumeData, setResumeData } = useContext(ResumeContext);

	const [customExtras, setCustomExtras] = useState("");

	useEffect(() => {
		const { data } = resumeData.extras.customExtras;

		setCustomExtras(data);
	}, [resumeData]);

	//submition handler
	const customExtrasInfo = () => {
		if (customExtras === "") {
			// return setErrors((prevState) => {
			//     return [...prevState, "Please add your affiliations"];
			// });
		}

		const payload = new CustomExtra(customExtras);
		const request = payload.getCustomRequest(params.customExtra);

		const resumeId = cookies[staticValues.localStorageResumeId];

		const section = "CUSTOM";

		if (hasChanges(section, request)) {
			setLoading(true);
			resumeDataService.patchResumeLedger(resumeId, request, section, successPatchCustom, errorPatchCustom);
		} else {
			navigate(navigateTo);
		}
	};

	const dispatch = useDispatch();

	const dispatchAddExtras = (payload) => {
		dispatch({ type: "ADD_EXTRAS", payload: payload });
	};

	function successPatchCustom(data) {
		setLoading(false);

		resumeObjectToContextFormat(data.data, setResumeData, dispatchAddExtras);

		dispatch({ type: "RESUME_DATA", payload: data.data });

		navigate(navigateTo);
	}

	function errorPatchCustom(data) {
		setLoading(false);
		const serverResponse = data.response.data;
		Array.isArray(serverResponse.message)
			? setServerErrors((prev) => {
					return [...prev, ...serverResponse.message];
				})
			: setServerErrors((prev) => {
					return [...prev, serverResponse.message];
				});
	}

	return (
		<>
			<div className="container">
				<div className="section additional-information">
					<ul className="d-flex list-unstyled justify-content-end">
						<li className="text-primary cursor-pointer mr-3" data-toggle="modal" data-target="#ResumePreview">
							<span className="fa fa-eye mr-2"> </span> Preview
						</li>
						<li className="text-primary cursor-pointer" data-toggle="modal" data-target="#Tips">
							<span className="fa fa-lightbulb mr-2"> </span> Tips
						</li>
					</ul>
					{serverErrors.map((item, index) => {
						return <ErrorToast error={item} key={index} />;
					})}
					<div className="mb-4 text-dark">
						<h2 className="tr-title-big mb-3">{params.customExtra} Details </h2>
						<p className="mb-0">Add anything else you about {params.customExtra}.</p>
					</div>
					<div className="form-wrapper">
						<div className="row">
							<div className="col-md-8">
								<form action="">
									<div className="editor-wrapper">
										<TextEditor
											value={customExtras}
											setValue={setCustomExtras}
											placeholder="Add your details here..."
										/>
									</div>
								</form>
							</div>
							<div className="col-md-12 mt-5">
								<div className="action d-flex align-items-center justify-content-between">
									<button onClick={() => navigate(-1)} className="btn btn-dark rounded-pill btn-lg mr-2">
										BACK
									</button>
									<button onClick={customExtrasInfo} className="btn btn-primary mr-2">
										{loading ? (
											<>
												Loading <Spinner />
											</>
										) : (
											"next"
										)}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CustomExtras;
