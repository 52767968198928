import "../../NewNavLayout/dashboardStyle.css";

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import { ResumeDataService } from "../../../services/create-resume/resume-data";
import LoadingBars from "../../LoadingBars";

const RecommendedDashboard = () => {
	const [loading, setLoading] = useState(false);
	const [recommendedJobs, setRecommendedJobs] = useState([]);
	const resumeDataService = new ResumeDataService();

	useEffect(() => {
		setLoading(true);
		resumeDataService.getRecommendedJobs(
			{},
			(response) => {
				setRecommendedJobs(response.data);
				setLoading(false);
			},
			() => {
				setLoading(false);
				toast.error("Something went wrong on Fetching Recommended Jobs");
			},
		);
	}, []);

	return (
		<div className="segment-dashboard">
			{loading ? (
				<div className="d-flex justify-content-center">
					<LoadingBars />
				</div>
			) : recommendedJobs.length === 0 ? (
				<div className="d-flex p-4 align-center justify-content-center">
					<div className="float-right">
						<h2 style={{ fontFamily: "Varela Round", fontSize: "1.5rem" }}> No Recommended Jobs Found! </h2>
						<p>
							Complete a <Link to="/my-resumes"> resume </Link>
							and finish setting up your <Link to="/jobs-preference"> job preferences </Link>
							and we will recommend jobs tailored for you.
						</p>
					</div>
				</div>
			) : (
				<>
					<h2>Recommended Jobs</h2>
					<p>We think these positions might ignite an interest in you. </p>

					<div className="recommended-jobs-wrapper">
						<div className="recommended-jobs-container">
							{recommendedJobs.map((recommendedJob, index) => (
								<div className="post-item-in-dashboard" key={index}>
									<div className="post-item-in-dashboard-container-top">
										<h2>{recommendedJob.job.companyName}</h2>
										<p>
											{`${recommendedJob.job.jobLocation.city},
                                    ${recommendedJob.job.jobLocation.region}
                                    `}
										</p>
									</div>
									<div className="post-item-in-dashboard-container">
										<h2>{recommendedJob.job.jobTitle}</h2>
										<p>{`${recommendedJob.fit_score}% match`}</p>
									</div>
									<button className="open-button">Learn More</button>
								</div>
							))}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default RecommendedDashboard;
