import { convertUlToArray } from "../../handlers/utilityFunctions";

export class CustomExtra {
	constructor(custom) {
		this.custom = custom;
	}

	getCustomRequest(alias) {
		const textAreaItems = convertUlToArray(this.custom);

		return {
			data: textAreaItems,
			alias: alias,
			applicable: true,
		};
	}
}
