import reviewDetails from "../../seed/reviewDetails";

export default function ExpertReviewReducers(reviews = reviewDetails, action) {
	switch (action.type) {
		case "UPDATE_REVIEWS":
			return reviews;

		default:
			return reviews;
	}
}
