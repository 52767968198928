const initalState = {
	sex: "",
	dateOfBirth: "",
	maritalStatus: "",
};

export default function PersonalProfileReducer(profile = initalState, action) {
	switch (action.type) {
		case "SUBMIT_PROFILE":
			return action.payload;

		default:
			return profile;
	}
}
