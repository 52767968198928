import React, { useEffect } from "react";

const ResumePreview = ({ resumeData }) => {
	const { contactInfo, summary } = resumeData;

	useEffect(() => {}, [resumeData]);
	return (
		<div style={{ scale: "1.2", transformOrigin: "center" }}>
			<div className="small-resume-preview">
				<div className="final-resume-preview bg-white mt-2 fresher-resume text-dark">
					<div className="resume-content ">
						<div className="top  px-2 px-2 px-md-4 text-center ">
							<h1
								className="text-uppercase"
								style={{
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
									margin: "0",
								}}
							>
								{contactInfo.firstName} {contactInfo.middleName} {contactInfo.lastName}
							</h1>
							<address className="mb-0">
								{contactInfo.city}, {contactInfo.country}{" "}
							</address>
							<ul className="resume-user-quick-info border-bottom  d-flex list-unstyled justify-content-center d-flex align-items-center mt-1 mb-2">
								<li className="mr-1 text-nowrap">
									<span className="span text-primary mr-1 mr-md-2 fa fa-phone" />
									{contactInfo.phoneNo}
								</li>
								<li className="mr-1 text-nowrap">
									<span className="span text-primary mr-1 mr-md-2 fa fa-envelope" />
									{contactInfo.email}
								</li>
								<li className="mr-1 text-nowrap">
									<span className="span text-primary mr-1 mr-md-2 fa fa-linkedin-square" />
									linked In link
								</li>
								<li className="mr-1 text-nowrap">
									<span className="span text-primary mr-1 mr-md-2 fa fa-globe" />
									portfolio Url
								</li>
							</ul>
						</div>
						<div className="bottom pb-2 px-2 px-2 px-md-4">
							<div className="content-box">
								<h3 className="py-1 mb-1 bg-secondary bg-grey mb-0 px-2 text-uppercase text-center">Summary</h3>
								<p>{summary}</p>
							</div>
							<div className="content-box">
								<h3 className="py-1 mb-1 bg-secondary bg-grey mb-0 px-2 text-uppercase text-center">Education</h3>
								<ul className="pl-3 mb-1">
									<li>Post Graduation in Computer Sciences from ABC University, Dar es Salaam, Tanzania in 2021 </li>
									<li>Graduation in Computer Sciences from ABC University, Dar es Salaam, Tanzania in 2018</li>
									<li>High School from ABC School, Dar es Salaam, Tanzania in 2015 </li>
								</ul>
							</div>

							<div className="content-box">
								<h3 className="py-1 mb-1 bg-secondary bg-grey mb-0 px-2 text-uppercase text-center">Work History</h3>
								<p className="mb-0">August, 2019 - May, 2021</p>
								<p className="mb-1">
									<strong>Web Developer(Internship)</strong>, <em>Kellton Tech</em>, Dar es Salaam, Tanzania{" "}
								</p>
								<ul className="pl-3 mb-1">
									<li>
										Planned website development, converting mockups into usable web presence with HTML, JavaScript, AJAX
										and JSON coding.
									</li>
									<li>Provided front-end website development using WordPress, Hubspot and other editing software.</li>
								</ul>
							</div>
							<div className="content-box">
								<h3 className="py-1 mb-1 bg-secondary bg-grey mb-0 px-2 text-uppercase text-center">CERTIFICATIONS</h3>
								<ul className="pl-3 mb-1">
									<li>Certified Associate Webmaster</li>
									<li>MCPD - Microsoft Certified Professional Developer</li>
								</ul>
							</div>
							<div className="content-box skills">
								<h3 className="py-1 mb-1 bg-secondary bg-grey mb-0 px-2 text-uppercase text-center">SKILLS</h3>
								<ul className="pl-3 mb-1 d-flex flex-wrap">
									<li className="w-50">V flex-wrapersion Control Systems: Git, Github</li>
									<li className="w-50">C flex-wrapomplex Problem Solving</li>
								</ul>
							</div>

							<div className="content-box">
								<h3 className="py-1 mb-1 bg-secondary bg-grey mb-0 px-2 text-uppercase text-center">ACCOMPLISHMENTS</h3>
								<ul className="pl-3 mb-1">
									<li>
										Spearheaded a large-scale client project as a result of skill set and education and completed the
										project two weeks ahead of schedule.
									</li>
								</ul>
							</div>
							<div className="content-box">
								<h3 className="py-1 mb-1 bg-secondary bg-grey mb-0 px-2 text-uppercase text-center">Languages</h3>
								<ul className="pl-3 mb-1">
									<li>English - Expert Level</li>
									<li>German - Expert Level</li>
								</ul>
							</div>
							<div className="content-box">
								<h3 className="py-1 mb-1 bg-secondary bg-grey mb-0 px-2 text-uppercase text-center">
									ADDITONAL INFORMATION
								</h3>
								<p>
									Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
									industry&#39;s standard dummy text ever since the 1500s.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResumePreview;
