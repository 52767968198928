import React from "react";
import { Link } from "react-router-dom";

function GenerateResumePopup({
	setGenerateNewResume,
	setShowResumePrintPopup,
	resume,
	resumeGenerateSpinner,
	createResumeDownload,
	showMessage,
	resultMessage,
	extrasSections,
	handleCheck,
	evokeTooltip,
	initTooltip,
	target,
}) {
	return (
		<div role="document">
			<div className="modal-content p-1">
				<div className="modal-header mb-3">
					<div>
						<h5 className="modal-title" id="exampleModalLongTitle">
							Exercise extremely high standards on what to keep on your generated-resume.
						</h5>
					</div>
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => {
							setGenerateNewResume(false);
							setShowResumePrintPopup(false);
						}}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>

				<div
					style={{
						marginLeft: "10px",
						fontStyle: "italic",
					}}
				>
					As a candidate you&#39;re judged on your ability to be succinct{" "}
					<Link
						style={{
							cursor: "pointer",
							textDecoration: "underline",
						}}
						ref={target}
						onMouseEnter={() => initTooltip(!evokeTooltip)}
						onMouseLeave={() => initTooltip(!evokeTooltip)}
						to="#"
					>
						Learn more..
					</Link>
					.<br />
				</div>
				<div
					style={{
						marginBottom: "10px",
						marginLeft: "10px",
					}}
				>
					Please select sections you&#39;d rather exclude(not deleting) from the resume you&#39;re about to generate.
				</div>

				<table className="ml-3">
					<tbody>
						{extrasSections.map((section, i) => (
							<tr className="mb-2" key={i}>
								{/* <div className="checkbox checkbox-circle checkbox-color-scheme"> */}
								<label className="checkbox-checked">
									<input type="checkbox" id={section} onChange={() => handleCheck(section)} value={section} />
									<span className="label-text"> {section.toUpperCase()}</span>
								</label>
								{/* </div> */}
							</tr>
						))}
					</tbody>
				</table>
				<br />
				{resumeGenerateSpinner ? (
					<div className="col-lg-12 text-right text-lg-center col-7">
						<div className="spinner-border" id="generate-spinner" role="status">
							<span className="sr-only">Loading</span>
						</div>{" "}
					</div>
				) : (
					<button
						type="button"
						id="create-resume"
						className="btn btn-primary mb-3"
						onClick={() => createResumeDownload(resume.id)}
					>
						Generate New Resume
					</button>
				)}
				<label
					style={{
						display: showMessage ? "block" : "none",
						color: "red",
					}}
					id="result-message"
				>
					{resultMessage}
				</label>
				<Link
					style={{
						display: showMessage ? "block" : "none",
					}}
					to="/payments"
					// className="btn btn-primary ml-2"
				>
					Go to subscriptions
				</Link>
			</div>
		</div>
	);
}

export default GenerateResumePopup;
