import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import tips from "../../../../../assets/copy/tips";
import ReferenceSummaryBox from "../../../../../components/ResumeBuilder/ReferenceSummaryBox/ReferenceSummaryBox";
import Tips from "../../../../../components/Tips";
import ResumeContext from "../../../../../context/ResumeContext";

const ReferenceSummary = () => {
	const navigate = useNavigate();
	const { resumeData } = useContext(ResumeContext);
	const { references } = resumeData.extras;

	const addAnotherEduHandler = () => {
		navigate("/resume/reference-information");
	};

	useEffect(() => {}, [resumeData]);

	return (
		<>
			<div className="container min-vh-100">
				{/* resume summary section  */}
				<div className="section resume-summary-section">
					<div className="col-md-8">
						<div className="d-flex justify-content-end mb-2">
							<Tips content={tips.referees} />
						</div>
						{references.length === 0 ? (
							<div className="d-flex justify-content-center text-dark">
								<h5 className="tr-title-small m-3">No Referee Information Added</h5>
							</div>
						) : (
							references.map((reference, index) => {
								return <ReferenceSummaryBox key={index} item={reference} index={index} />;
							})
						)}

						<span className="text-primary font-weight-semi cursor-pointer" onClick={addAnotherEduHandler}>
							<span className="fa fa-plus mr-3" />
							Add another reference
						</span>

						<div className="w-100 d-flex align-items-center justify-content-end">
							<button
								onClick={() => navigate("/resume/extras")}
								className="text-primary p-3 cursor-pointer font-weight-semi mr-3"
							>
								Skip for now
							</button>
							<button
								onClick={() => {
									navigate("/resume/extras");
								}}
								className="btn btn-primary rounded-pill btn-lg"
							>
								Continue
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ReferenceSummary;
