import React from "react";

import FeedBackModal from "../../../../components/Reviews/FeedBackModal/FeedBackModal";
import { sections } from "../../../../handlers/enums";
import { convertDateToWords, convertUlToArray, removeHTTPAndDomainName } from "../../../../handlers/utilityFunctions";
import useReviewFeedBack from "../../../../hooks/use-ReviewFeedBack";

function ResumePremiumSix(props) {
	const { isReview, reviewData, resumeData, hasFeedback, activeSection, onSubmitFeedBack, loadingSubmission } = props;
	const { useReviewFeedback, feedBackButton } = useReviewFeedBack(reviewData);

	return (
		<div className="final-resume-preview-wrapper final-premium-template-5 final-resume-preview-new shadow-sm">
			<div className="container" style={{ maxWidth: "1000px" }} id="printResume">
				{resumeData.contactInfo.firstName !== "" && (
					<div
						className="resume-general-info mb-md-3 mb-2"
						style={{ border: "2px solid #1d3344", borderRadius: "10px", marginTop: "30px" }}
					>
						<div className="row  resume-general-inner" style={{ marginTop: "-27px" }}>
							<div className="col-12">
								<div className="text-center">
									<h1 className="mb-0 d-inline-block px-4 bg-white" style={{ color: "#1d3344" }}>
										{resumeData.contactInfo.firstName} {resumeData.contactInfo.lastName}
									</h1>
									<h4 className="mb-2 text-center font-weight-normal" style={{ color: "#f3b943" }}>
										{resumeData.contactInfo.resumeTitle}
									</h4>
								</div>
								<div
									className={`position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.SUMMARY
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.SUMMARY}
									style={{
										backgroundColor:
											useReviewFeedback.section.section === sections.SUMMARY ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.SUMMARY
										? feedBackButton(sections.SUMMARY, hasFeedback)
										: null}
									<p
										className="mb-2 px-3 px-md-5"
										dangerouslySetInnerHTML={{
											__html: resumeData.summary,
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				)}

				{resumeData.contactInfo.email !== "" && (
					<div
						className="p-2 p-md-4 mb-md-4 mb-3 resume-contact-sec"
						style={{
							color: "#ffffff",
							backgroundColor: "#1d3344",
							borderRadius: "5px",
							borderBottomLeftRadius: "50px",
							borderBottomRightRadius: "50px",
						}}
					>
						<div className="row ">
							<div className="col-6">
								<p className="mb-2">
									<span className="contact-icon mr-2">
										{" "}
										<i className=" fa fa-envelope " aria-hidden="true" />
									</span>
									{resumeData.contactInfo.email}
								</p>
							</div>
							<div className="col-6">
								<p className="mb-2">
									<span className="contact-mobile mr-2">
										{" "}
										<i className=" fa fa-phone " aria-hidden="true" />
									</span>
									{resumeData.contactInfo.phoneNo}
								</p>
							</div>
							<div className="col-6">
								<p className="mb-2">
									<span className="contact-icon mr-2">
										{" "}
										<i className=" fa fa-map-marker " aria-hidden="true" />
									</span>
									{resumeData.contactInfo.city}, {resumeData.contactInfo.country}
								</p>
							</div>
							{[...resumeData.extras.personalLinks].map((link, index) => {
								return link.type !== "otherLink" ? (
									<div key={index} className="col-6">
										<p className="mb-2">
											<span className="contact-icon mr-2">
												{" "}
												<i
													className={`${
														link.type === "linkedIn"
															? "bi bi-linkedin"
															: link.type === "twitter"
																? "bi bi-twitter"
																: link.type === "github"
																	? "bi bi-github"
																	: "fa fa-link"
													}`}
													aria-hidden="true"
												/>
											</span>
											<a href={link.value} target="_blank" rel="noreferrer" style={{ color: "#ffffff" }}>
												{removeHTTPAndDomainName(link)}
											</a>
										</p>
									</div>
								) : null;
							})}
						</div>
					</div>
				)}

				{resumeData.skills !== "" && convertUlToArray(resumeData.skills) ? (
					<div className="row mb-md-4 mb-3">
						<div className="col-12">
							<div
								className="title d-flex align-items-center mb-md-4 mb-2"
								style={{
									color: "#1d3344",
								}}
							>
								<span
									className="title-icon"
									style={{
										width: "40px",
										height: "40px",
										border: "1.5px solid #1d3344",
										borderRadius: "3px",
										marginRight: "10px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "25px",
									}}
								>
									<span className="fa fa-cogs" />
								</span>
								<h3 className="text-uppercase center font-weight-bold mb-0 mr-md-5 mr-3">skills</h3>
								<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
									<span style={{ flexGrow: 1, height: "2px", backgroundColor: "#1d3344" }} />
									<span style={{ display: "inline-block" }} className="fa fa-caret-left" />
								</span>
							</div>

							<ul
								className={`p-0 mb-0 position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.SKILLS
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.SKILLS}
								style={{
									listStyle: "none",
									backgroundColor:
										useReviewFeedback.section.section === sections.SKILLS ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.SKILLS ? feedBackButton(sections.SKILLS, hasFeedback) : null}
								{convertUlToArray(resumeData.skills).map((item, index) => {
									const splitArray = item.value.split(/:(.+)/);
									let wordBeforeColon = splitArray[0].trim();
									wordBeforeColon = splitArray.length > 1 ? <strong>{wordBeforeColon}</strong> : wordBeforeColon;
									const wordAfterColon = splitArray.length > 1 ? ": " + splitArray[1].trim() : "";

									return (
										<li key={index} className="d-flex align-items-center mb-2">
											{wordBeforeColon}
											{wordAfterColon}
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				) : null}

				{resumeData.extras.certifications !== "" ? (
					<div className="row mb-md-4 mb-3">
						<div className="col-12">
							<div
								className="title d-flex align-items-center mb-md-4 mb-2"
								style={{
									color: "#1d3344",
								}}
							>
								<span
									className="title-icon"
									style={{
										width: "40px",
										height: "40px",
										border: "1.5px solid #1d3344",
										borderRadius: "3px",
										marginRight: "10px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "25px",
									}}
								>
									<span className="fa fa-certificate" />
								</span>
								<h3 className="text-uppercase center font-weight-bold mb-0 mr-md-5 mr-3">certificates</h3>
								<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
									<span style={{ flexGrow: 1, height: "2px", backgroundColor: "#1d3344" }} />
									<span style={{ display: "inline-block" }} className="fa fa-caret-left" />
								</span>
							</div>

							<div
								className={`row position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.CERTIFICATION
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.CERTIFICATION}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.CERTIFICATION ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.CERTIFICATION
									? feedBackButton(sections.CERTIFICATION, hasFeedback)
									: null}
								{convertUlToArray(resumeData.extras.certifications).map((item, index) => {
									return (
										<p key={index} className="col-6 mb-2 pr-2">
											{item.value}
										</p>
									);
								})}
							</div>
						</div>
					</div>
				) : null}

				{resumeData.extras.accomplishments !== "" ? (
					<div className="row mb-md-4 mb-3">
						<div className="col-12">
							<div
								className="title d-flex align-items-center mb-md-4 mb-2"
								style={{
									color: "#1d3344",
								}}
							>
								<span
									className="title-icon"
									style={{
										width: "40px",
										height: "40px",
										border: "1.5px solid #1d3344",
										borderRadius: "3px",
										marginRight: "10px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "25px",
									}}
								>
									<span className="fa fa-check" />
								</span>
								<h3 className="text-uppercase center font-weight-bold mb-0 mr-md-5 mr-3">accomplishments</h3>
								<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
									<span style={{ flexGrow: 1, height: "2px", backgroundColor: "#1d3344" }} />
									<span style={{ display: "inline-block" }} className="fa fa-caret-left" />
								</span>
							</div>

							<div
								className={`row position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.ACCOMPLISHMENTS
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.ACCOMPLISHMENTS}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.ACCOMPLISHMENTS ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.ACCOMPLISHMENTS
									? feedBackButton(sections.ACCOMPLISHMENTS, hasFeedback)
									: null}
								{convertUlToArray(resumeData.extras.accomplishments).map((item, index) => {
									return (
										<p key={index} className="col-12 mb-2">
											{item.value}
										</p>
									);
								})}
							</div>
						</div>
					</div>
				) : null}

				{resumeData.workHistory.length > 0 && resumeData.workHistory[0].jobTitle !== "" ? (
					<div className="row mb-md-4 mb-3">
						<div className="col-12">
							<div
								className="title d-flex align-items-center mb-md-4 mb-2"
								style={{
									color: "#1d3344",
								}}
							>
								<span
									className="title-icon"
									style={{
										width: "40px",
										height: "40px",
										border: "1.5px solid #1d3344",
										borderRadius: "3px",
										marginRight: "10px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "25px",
									}}
								>
									<span className="fa fa-briefcase" />
								</span>
								<h3 className="text-uppercase center font-weight-bold mb-0 mr-md-5 mr-3">Work experience</h3>
								<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
									<span style={{ flexGrow: 1, height: "2px", backgroundColor: "#1d3344" }} />
									<span style={{ display: "inline-block" }} className="fa fa-caret-left" />
								</span>
							</div>
							<div
								className={`position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.EXPERIENCE
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.EXPERIENCE}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.EXPERIENCE ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.EXPERIENCE
									? feedBackButton(sections.EXPERIENCE, hasFeedback)
									: null}
								{[...resumeData.workHistory].map((job, index) => (
									<React.Fragment key={index}>
										<div className="mb-3 mb-md-4">
											<div className="d-flex justify-content-between">
												<p className="mb-2">
													<strong>{job.employer}</strong>
													{" - "}
													{job.city}, {job.country}
													<br />
													<em>{job.jobTitle}</em>
												</p>
												<p className="mb-0" style={{ color: "#f3b943" }}>
													<strong>
														{convertDateToWords(job.startDate)} -{" "}
														{job.endDate !== "" ? convertDateToWords(job.endDate) : "Present"}
													</strong>
												</p>
											</div>

											<ul className="p-0 mb-0" style={{ listStyle: "none" }}>
												{convertUlToArray(job.details).map((item, index) => {
													return (
														<li key={index} className="d-flex align-items-center">
															<span
																style={{
																	fontWeight: 500,
																	width: "20px",
																	fontSize: "5px",
																	color: "#f3b943",
																}}
															>
																<span className="fa fa-circle" />
															</span>
															<span className="w-100">{item.value}</span>
														</li>
													);
												})}
											</ul>
										</div>
									</React.Fragment>
								))}
							</div>
						</div>
					</div>
				) : null}

				{resumeData.education.length > 0 && (
					<div className="row mb-md-4 mb-3">
						<div className="col-12">
							<div
								className="title d-flex align-items-center mb-md-4 mb-2"
								style={{
									color: "#1d3344",
								}}
							>
								<span
									className="title-icon"
									style={{
										width: "40px",
										height: "40px",
										border: "1.5px solid #1d3344",
										borderRadius: "3px",
										marginRight: "10px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "25px",
									}}
								>
									<span className="fa fa-graduation-cap" />
								</span>
								<h3 className="text-uppercase center font-weight-bold mb-0 mr-md-5 mr-3">Education</h3>
								<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
									<span style={{ flexGrow: 1, height: "2px", backgroundColor: "#1d3344" }} />
									<span style={{ display: "inline-block" }} className="fa fa-caret-left" />
								</span>
							</div>

							<div className="row">
								<div
									className={`col-12 position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.EDUCATION
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.EDUCATION}
									style={{
										backgroundColor:
											useReviewFeedback.section.section === sections.EDUCATION ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.EDUCATION
										? feedBackButton(sections.EDUCATION, hasFeedback)
										: null}
									{[...resumeData.education].map((educationHistory, index) => (
										<div key={index} className="mb-3">
											<div className="d-flex justify-content-between">
												<p>
													<strong>{educationHistory.schoolName}</strong>
													{" - "}
													{educationHistory.schoolCity}, <br />
													<em>{educationHistory.fieldOfStudy}</em>
												</p>
												<p className="mr-4" style={{ color: "#f3b943" }}>
													<strong>{educationHistory.gYear}</strong>
												</p>
											</div>
											<ul className="p-0 mb-0" style={{ listStyle: "none" }}>
												{convertUlToArray(educationHistory.description).map((item, index) => {
													if (item.value === "") {
														return null;
													}
													return (
														<li key={index} className="d-flex align-items-center">
															<span
																style={{
																	fontWeight: 500,
																	width: "20px",
																	fontSize: "5px",
																	color: "#f3b943",
																}}
															>
																<span className="fa fa-circle" />
															</span>
															<span className="w-100">{item.value}</span>
														</li>
													);
												})}
											</ul>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				)}

				{resumeData.extras.languages.length !== 0 ? (
					<div className="row mb-md-4 mb-3">
						<div className="col-12">
							<div
								className="title d-flex align-items-center mb-md-4 mb-2"
								style={{
									color: "#1d3344",
								}}
							>
								<span
									className="title-icon"
									style={{
										width: "40px",
										height: "40px",
										border: "1.5px solid #1d3344",
										borderRadius: "3px",
										marginRight: "10px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "25px",
									}}
								>
									<span className="fa fa-language" />
								</span>
								<h3 className="text-uppercase center font-weight-bold mb-0 mr-md-5 mr-3">Languages</h3>
								<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
									<span style={{ flexGrow: 1, height: "2px", backgroundColor: "#1d3344" }} />
									<span style={{ display: "inline-block" }} className="fa fa-caret-left" />
								</span>
							</div>

							<div
								className={`row no-gutters position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.LANGUAGES
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.LANGUAGES}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.LANGUAGES ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.LANGUAGES
									? feedBackButton(sections.LANGUAGES, hasFeedback)
									: null}
								{resumeData.extras.languages.map((language, index) => (
									<div key={index} className="col-4 px-2">
										<div className="mb-2 mb-md-3">
											<h6> {language.language}</h6>
											<p className="mb-0" style={{ color: "#f3b943" }}>
												<em>{language.proficiency}</em>
											</p>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				) : null}

				{resumeData.extras.personalProfile.dateOfBirth && (
					<div className="row mb-md-4 mb-3">
						<div className="col-12">
							<div
								className="title d-flex align-items-center mb-md-4 mb-2"
								style={{
									color: "#1d3344",
								}}
							>
								<span
									className="title-icon"
									style={{
										width: "40px",
										height: "40px",
										border: "1.5px solid #1d3344",
										borderRadius: "3px",
										marginRight: "10px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "25px",
									}}
								>
									<span className="fa fa-user" />
								</span>
								<h3 className="text-uppercase center font-weight-bold mb-0 mr-md-5 mr-3">Personal Information</h3>
								<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
									<span style={{ flexGrow: 1, height: "2px", backgroundColor: "#1d3344" }} />
									<span style={{ display: "inline-block" }} className="fa fa-caret-left" />
								</span>
							</div>

							<div
								className={`row position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.PERSONAL
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.PERSONAL}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.PERSONAL ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.PERSONAL
									? feedBackButton(sections.PERSONAL, hasFeedback)
									: null}
								<div className="col-6">
									<div className="mb-2 mb-md-3">
										<strong>Date of Birth:</strong>&nbsp;
										{resumeData.extras.personalProfile.dateOfBirth}
									</div>
								</div>
								<div className="col-6">
									<div className="mb-2 mb-md-3">
										<strong>Gender:</strong>&nbsp;
										{resumeData.extras.personalProfile.gender}
									</div>
								</div>
								<div className="col-6">
									<div className="mb-2 mb-md-3">
										<strong>Marital Status:</strong>&nbsp;
										{resumeData.extras.personalProfile.maritalStatus}
									</div>
								</div>
							</div>
						</div>
					</div>
				)}

				{resumeData.extras.affiliations !== "" ? (
					<div className="row mb-md-4 mb-3">
						<div className="col-12">
							<div
								className="title d-flex align-items-center mb-md-4 mb-2"
								style={{
									color: "#1d3344",
								}}
							>
								<span
									className="title-icon"
									style={{
										width: "40px",
										height: "40px",
										border: "1.5px solid #1d3344",
										borderRadius: "3px",
										marginRight: "10px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "25px",
									}}
								>
									<span className="fa fa-handshake" />
								</span>
								<h3 className="text-uppercase center font-weight-bold mb-0 mr-md-5 mr-3">affiliations</h3>
								<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
									<span style={{ flexGrow: 1, height: "2px", backgroundColor: "#1d3344" }} />
									<span style={{ display: "inline-block" }} className="fa fa-caret-left" />
								</span>
							</div>

							<div
								className={`row position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.AFFILIATION
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.AFFILIATION}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.AFFILIATION ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.AFFILIATION
									? feedBackButton(sections.AFFILIATION, hasFeedback)
									: null}
								{convertUlToArray(resumeData.extras.affiliations).map((item, index) => (
									<p key={index} className="col-6 mb-2 pr-2">
										<span>{item.value}</span>
									</p>
								))}
							</div>
						</div>
					</div>
				) : null}

				{resumeData.extras.additionalInfo !== "" ? (
					<div className="row mb-md-4 mb-3">
						<div className="col-12">
							<div
								className="title d-flex align-items-center mb-md-4 mb-2"
								style={{
									color: "#1d3344",
								}}
							>
								<span
									className="title-icon"
									style={{
										width: "40px",
										height: "40px",
										border: "1.5px solid #1d3344",
										borderRadius: "3px",
										marginRight: "10px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "25px",
									}}
								>
									<span className="fa fa-info" />
								</span>
								<h3 className="text-uppercase center font-weight-bold mb-0 mr-md-5 mr-3">additional information</h3>
								<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
									<span style={{ flexGrow: 1, height: "2px", backgroundColor: "#1d3344" }} />
									<span style={{ display: "inline-block" }} className="fa fa-caret-left" />
								</span>
							</div>

							<div
								className={`row position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.ADDITIONAL_INFO
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.ADDITIONAL_INFO}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.ADDITIONAL_INFO ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.ADDITIONAL_INFO
									? feedBackButton(sections.ADDITIONAL_INFO, hasFeedback)
									: null}
								{convertUlToArray(resumeData.extras.additionalInfo).map((item, index) => (
									<div key={index} className="col-6 mb-2 pr-2">
										<span>{item.value}</span>
									</div>
								))}
							</div>
						</div>
					</div>
				) : null}

				{[...resumeData.extras.personalLinks].some((link) => link.type === "otherLink") && (
					<div className="row mb-md-4 mb-3">
						<div className="col-12">
							<div
								className="title d-flex align-items-center mb-md-4 mb-2"
								style={{
									color: "#1d3344",
								}}
							>
								<span
									className="title-icon"
									style={{
										width: "40px",
										height: "40px",
										border: "1.5px solid #1d3344",
										borderRadius: "3px",
										marginRight: "10px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "25px",
									}}
								>
									<span className="fa fa-link" />
								</span>
								<h3 className="text-uppercase center font-weight-bold mb-0 mr-md-5 mr-3">
									website, portfolio, profiles
								</h3>
								<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
									<span style={{ flexGrow: 1, height: "2px", backgroundColor: "#1d3344" }} />
									<span style={{ display: "inline-block" }} className="fa fa-caret-left" />
								</span>
							</div>

							<div
								className={`row position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.LINKS
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.LINKS}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.LINKS ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.LINKS ? feedBackButton(sections.LINKS, hasFeedback) : null}
								{[...resumeData.extras.personalLinks].map((link, index) => {
									return link.type === "otherLink" ? (
										<div key={index} className="col-12 d-flex align-items-center">
											<span>{link.value}</span>
										</div>
									) : null;
								})}
							</div>
						</div>
					</div>
				)}

				{resumeData.extras.references.length > 0 ? (
					<div className="row mb-md-4 mb-3">
						<div className="col-12">
							<div
								className="title d-flex align-items-center mb-md-4 mb-2"
								style={{
									color: "#1d3344",
								}}
							>
								<span
									className="title-icon"
									style={{
										width: "40px",
										height: "40px",
										border: "1.5px solid #1d3344",
										borderRadius: "3px",
										marginRight: "10px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "25px",
									}}
								>
									<span className="fa fa-asterisk" />
								</span>
								<h3 className="text-uppercase center font-weight-bold mb-0 mr-md-5 mr-3">references</h3>
								<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
									<span style={{ flexGrow: 1, height: "2px", backgroundColor: "#1d3344" }} />
									<span style={{ display: "inline-block" }} className="fa fa-caret-left" />
								</span>
							</div>

							<div
								className={`row position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.REFERENCE
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.REFERENCE}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.REFERENCE ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.REFERENCE
									? feedBackButton(sections.REFERENCE, hasFeedback)
									: null}
								{[...resumeData.extras.references].map((reference, index) => (
									<div key={index} className="col-6 d-flex mb-3">
										<div>
											<strong>{reference.name}</strong>
											{" - "}
											<em>{reference.designation}, </em>
											{reference.workPlace}
											<br />
											<p style={{ color: "#bcbcbc" }}>
												{reference.email}, {reference.phoneNumber}
											</p>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				) : null}

				{resumeData.extras.customExtras.name.length > 0 ? (
					<div className="row mb-md-4 mb-3">
						<div className="col-12">
							<div
								className="title d-flex align-items-center mb-md-4 mb-2"
								style={{
									color: "#1d3344",
								}}
							>
								<span
									className="title-icon"
									style={{
										width: "40px",
										height: "40px",
										border: "1.5px solid #1d3344",
										borderRadius: "3px",
										marginRight: "10px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "25px",
									}}
								>
									<span className="bi bi-journal-text" />
								</span>
								<h3 className="text-uppercase center font-weight-bold mb-0 mr-md-5 mr-3">
									{resumeData.extras.customExtras.name}
								</h3>
								<span className="title-line d-flex align-items-center" style={{ flexGrow: 1 }}>
									<span style={{ flexGrow: 1, height: "2px", backgroundColor: "#1d3344" }} />
									<span style={{ display: "inline-block" }} className="fa fa-caret-left" />
								</span>
							</div>

							<div
								className={`row position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.CUSTOM
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.CUSTOM}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.CUSTOM ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.CUSTOM ? feedBackButton(sections.CUSTOM, hasFeedback) : null}
								{convertUlToArray(resumeData.extras.customExtras.data).map((item, index) => (
									<div key={index} className="col-6 mb-2 pr-2">
										<span>{item.value}</span>
									</div>
								))}
							</div>
						</div>
					</div>
				) : null}
			</div>
			{useReviewFeedback.showFeedBackModal && (
				<FeedBackModal
					resumeData={resumeData}
					details={useReviewFeedback.section}
					onSubmitFeedBack={onSubmitFeedBack}
					loadingSubmission={loadingSubmission}
				/>
			)}
		</div>
	);
}

export default ResumePremiumSix;
