export default function EducationSummaryReducer(eduSummary = [], action) {
	switch (action.type) {
		case "ADD_EDUCATION":
			return action.payload;

		case "REMOVE_EDUCATION":
			return eduSummary.filter((item) => item.id !== action.payload);

		default:
			return eduSummary;
	}
}
