const { email, firstName, lastName, address, phoneNumber } = JSON.parse(localStorage.getItem("profileData"))
	? JSON.parse(localStorage.getItem("profileData"))
	: {
			email: "",
			userID: "",
			firstName: "",
			lastName: "",
			address: null,
			phoneNumber: "",
		};

const initalState = {
	general: {
		email: email,
		oldPassword: "",
		newPassword: "",
		firstName: firstName,
		lastName: lastName,
		...address,
		phone: phoneNumber,
	},
	communication: {
		jobSearchTips: false,
		jobAlerts: false,
		careerOpportunities: false,
		resumeNews: false,
		marketResearch: false,
		unsubscribe: false,
		jobSearchTips2: false,
		jobAlerts2: false,
		resumeApplication: false,
		phoneNumber: "",
	},
};
export default function SettingsReducer(settings = initalState, action) {
	switch (action.type) {
		case "EDIT_GENERAL":
			return { ...settings, general: action.payload };

		case "EDIT_COMMUNICATION":
			return { ...settings, communication: action.payload };

		default:
			return settings;
	}
}
