const Faqs = () => {
	return [
		{
			id: 1,
			question: "Will my Subscription account renew automatically?",
			answer:
				"No, our pricing model is pay as you go. Therefore, you " +
				"will not be charged automatically without you initiating the payment.",
		},
		{
			id: 2,
			question: "Will my resumes  be saved after the Subscription period expires?",
			answer:
				"All your resumes  will be saved and accessible to your account, " +
				"even when the subscription period expires.",
		},
		{
			id: 3,
			question: "Are there any hidden costs?",
			answer:
				"There are no hidden costs. After linking  your payment(s) the appropriate " +
				"package will be activated based on the total amount you paid. Price to activate a " +
				"package can be seen before making any payment details. (This excludes the charges " +
				"required for complete the transaction by your provider)",
		},
		{
			id: 4,
			question: "Is my payment information secure?",
			answer: "Yes, we are not storing any payment information.",
		},
		{
			id: 5,
			question: "Can I try before I buy?",
			answer: "Most of the Premium features can be enjoyed for free in the Basic account for 30 days.",
		},
		{
			id: 6,
			question: "What format will the documents be saved as?",
			answer:
				"For increased security, readability and overall visual aspect, " +
				"all the documents are saved as PDFs - which is a format that looks the same on all devices.",
		},
		{
			id: 7,
			question: "Have a particular question?",
			answer:
				"If you have any questions regarding our Premium account or services, please do not hesitate " +
				"to drop us an email and our team will be happy to assist.",
		},
	];
};

export default Faqs;
