//this is just a sample

export default function RouterReducer(routes = [], action) {
	switch (action.type) {
		case "SET_ROUTES":
			return action.payload;
		default:
			return routes;
	}
}
