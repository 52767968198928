import React, { useEffect, useState } from "react";

import { convertArrayToUl } from "../../handlers/utilityFunctions";

function Tips({ content }) {
	const [showTips, setShowTips] = useState(false);

	const tipsRef = React.createRef();

	useEffect(() => {
		// get position of the tips element
		if (tipsRef.current) {
			const tipsPosition = tipsRef.current.getBoundingClientRect();

			const screenWidth = window.innerWidth;

			// tip position should always be above the label unless there is not enough space above
			// then it should be below the label
			if (tipsPosition.top < tipsPosition.height) {
				tipsRef.current.style.top = "100%";
				tipsRef.current.style.bottom = "auto";
			} else {
				tipsRef.current.style.bottom = "110%";
				tipsRef.current.style.top = "auto";
			}

			// tip position should always be on the right side of the label
			// unless there is not enough space then it should be directly over the label aligned to the far right
			if (tipsPosition.right > screenWidth) {
				tipsRef.current.style.left = "auto";
				tipsRef.current.style.right = 0;
			} else {
				tipsRef.current.style.right = "auto";
				tipsRef.current.style.left = 0;
			}
		}
	}, [showTips]);
	return (
		<div className="tips position-relative">
			{showTips && (
				<div className="tips-card" ref={tipsRef}>
					{content.label && <p className="font-weight-bold">{content.label}</p>}
					<div
						dangerouslySetInnerHTML={{
							__html: convertArrayToUl(content.content),
						}}
					/>
				</div>
			)}

			<div
				className="tips-label cursor-pointer mb-1"
				onMouseOver={() => setShowTips(true)}
				onMouseOut={() => setShowTips(false)}
			>
				<p className="text-primary font-weight-medium">Tips</p>
			</div>
		</div>
	);
}

export default Tips;
