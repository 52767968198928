import React from "react";

function ErrorToast({ error, timeout = 500000 }) {
	const [show, setShow] = React.useState(true);

	React.useEffect(() => {
		setTimeout(() => {
			setShow(false);
		}, timeout);
	}, [timeout]);

	return (
		<>
			{show && (
				<div className="alert alert-danger alert-dismissible fade show" role="alert">
					<div className="toast-body">{error}</div>
					<button type="button" className="close" data-dismiss="alert" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
			)}
		</>
	);
}

export default ErrorToast;
