export default {
	packages: {
		bronze: { name: "BRONZE", amount: 0 },
		silver: { name: "SILVER", amount: 1950 },
		gold: { name: "GOLD", amount: 5850 },
		platinum: { name: "PLATINUM", amount: 51500 },
	},

	checkUpgradeDetails(totalPaidAmount) {
		if (totalPaidAmount < this.packages.silver.amount) {
			const additionalAmount = this.packages.silver.amount - totalPaidAmount;

			return {
				packageName: this.packages.silver.name,
				amount: additionalAmount,
			};
		}

		if (totalPaidAmount < this.packages.gold.amount) {
			const additionalAmount = this.packages.gold.amount - totalPaidAmount;

			return {
				packageName: this.packages.gold.name,
				amount: additionalAmount,
			};
		}

		if (totalPaidAmount < this.packages.platinum.amount) {
			const additionalAmount = this.packages.platinum.amount - totalPaidAmount;

			return {
				packageName: this.packages.platinum.name,
				amount: additionalAmount,
			};
		}
	},

	chooseSubscription(subscriptions) {
		if (subscriptions.length === 0) {
			return { isSubscribed: false, selectedSubscription: {} };
		}

		const latestSubscription = subscriptions.reduce((date1, date2) => {
			return new Date(date1.expireAt) > new Date(date2.expireAt) ? date1 : date2;
		});

		return { isSubscribed: true, selectedSubscription: latestSubscription };
	},
};
