import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "../../../components/Header";
import JobRecommended from "../../../components/Jobs/JobRecommended/JobRecommended";
import JobSearch from "../../../components/Jobs/JobSearch";
import MyJobs from "../../../components/Jobs/MyJobs";
import JobRecommendations from "../../../components/Jobs/Recommendations/JobRecommendations";
import sub from "../../../handlers/subscription";
import useIsLoggedIn from "../../../hooks/use-IsLoggedIn";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import { default as HomeHeader } from "../../StaticPages/Header";

function JobDetails() {
	const isLoggedIn = useIsLoggedIn();
	const dispatch = useDispatch();
	const resumeDataService = new ResumeDataService();
	const tabs = useSelector((state) => state.JobsTabsReducers);
	const [openPreference, setOpenPreference] = useState(true);
	const navigate = useNavigate();

	const handleTabs = (event) => {
		if (event.target.name === "search") dispatch({ type: "SET_SEARCH", payload: true });

		if (event.target.name === "myJobs" && isLoggedIn) dispatch({ type: "SET_MyJOBS", payload: true });

		if (event.target.name === "recommendations" && isLoggedIn)
			dispatch({
				type: "SET_RECOMMENDATION",
				payload: true,
			});
	};

	function successCallBack(response) {
		// Set the subscription status of the user
		if (response.data.length !== 0) {
			const { isSubscribed } = sub.chooseSubscription(response.data);
			const subscribedStatus = isSubscribed ? "SUBSCRIBED" : "NOT_SUBSCRIBED";
			localStorage.setItem("isSubscribed", subscribedStatus);
		} else {
			localStorage.setItem("isSubscribed", "NOT_SUBSCRIBED");
		}
	}

	function errorCallBack() {
		toast.error("Something went wrong, please try again");
	}

	useEffect(() => {
		if (isLoggedIn) {
			if (localStorage.getItem("profileData") === null) {
				navigate("/my-resumes");
				return;
			}

			//check if the user a subscriber is the localstorage
			if (
				localStorage.getItem("isSubscribed") === null ||
				(localStorage.getItem("isSubscribed") === "NOT_SUBSCRIBED" && localStorage.getItem("profileData") !== null)
			) {
				//If there is no subscription status, Call the payment API to get status.
				resumeDataService.getPayments(
					JSON.parse(localStorage.getItem("profileData")).userID,
					successCallBack,
					errorCallBack,
				);
			}
		}
	}, []);

	return (
		<>
			{isLoggedIn ? <Header /> : <HomeHeader activeLink="jobs" />}
			<div className="ml-5 mr-5 mt-20">
				<div className="row jobSearchPadding py-3">
					<div className={`${tabs.search && openPreference && isLoggedIn ? "col-lg-9" : "col-lg-12"}`}>
						<div className="job-search-wrapper">
							<div className="search-job-menu">
								<div className="">
									<div className="row no-gutters border-bottom">
										<div className="col-lg-12">
											<ul className="list-unstyled  d-flex align-items-center mb-0">
												<li>
													<a
														onClick={handleTabs}
														name="search"
														className={`py-3 d-inline-block position-relative ${tabs.search ? "active" : ""}`}
													>
														Search Jobs
													</a>
												</li>
												<li>
													<a
														onClick={handleTabs}
														name="recommendations"
														className={`py-3 d-inline-block position-relative ${tabs.recommendations ? "active" : ""}`}
													>
														Recommended Jobs
													</a>
												</li>
												<li>
													<a
														onClick={handleTabs}
														name="myJobs"
														className={`py-3 d-inline-block position-relative ${tabs.myJobs ? "active" : ""}`}
													>
														Saved Jobs
													</a>
												</li>
												{tabs.search && isLoggedIn && (
													<li onClick={() => setOpenPreference(!openPreference)}>
														<i
															className={`${
																openPreference ? "fas fa-close" : "fas fa-bars"
															} d-flex justify-content-end cursor-pointer`}
														/>
													</li>
												)}
											</ul>
										</div>
									</div>
								</div>
							</div>

							<JobSearch searchTab={tabs.search} />
							<MyJobs myJobsTab={tabs.myJobs} />
							<JobRecommendations recommendationsTab={tabs.recommendations} />
						</div>
					</div>
					{tabs.search && openPreference && isLoggedIn && <JobRecommended />}
				</div>
			</div>
		</>
	);
}

export default JobDetails;
