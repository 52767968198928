import React from "react";

function AlertConfirmationModal({ title, actionButtonTitle, subTitle, setShowAlertConfirmationModal, handleAction }) {
	return (
		<div
			className="modal fade"
			id="alertConfirmationModal"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="alertConfirmation"
			aria-hidden="true"
			data-backdrop="static"
			data-keyboard="false"
		>
			<div
				className="modal-dialog modal-dialog-centered resume-feedback-modal"
				role="document"
				style={{ maxWidth: 500 }}
			>
				<div className="modal-content" style={{ padding: "20px 20px 24px" }}>
					<div className="d-flex flex-column" style={{ gap: 16 }}>
						<div className="d-flex align-items-center">
							{title && <div className="resume-feedback-modal-title fs-20">{title}</div>}
							<div
								className="cursor-pointer close ml-auto"
								data-dismiss="modal"
								onClick={() => {
									setShowAlertConfirmationModal(false);
								}}
							>
								<i className="fa fa-close" />
							</div>
						</div>
						{subTitle && <div>{subTitle}</div>}
						<div className="d-flex justify-content-end">
							<button
								type="button"
								className="btn btn-secondary mx-2"
								data-dismiss="modal"
								onClick={() => {
									setShowAlertConfirmationModal(false);
								}}
							>
								Cancel
							</button>
							{actionButtonTitle && (
								<button type="button" className="btn btn-primary" onClick={handleAction}>
									{actionButtonTitle}
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AlertConfirmationModal;
