import "./index.css";

import React, { useState } from "react";

import InputField from "../UIComponents/InputField";

const AutoSuggestion = (props) => {
	const {
		suggestions,
		userInput,
		setUserInput,
		isForm,
		name,
		type,
		placeholder,
		error,
		label,
		icon,
		tips = null,
	} = props;

	let suggestionsListComponent;
	const [activeSuggestion, setActiveSuggestion] = useState(0);
	// The suggestions that match the user&#39;s input
	const [filteredSuggestions, setFilteredSuggestions] = useState([]);
	// Whether or not the suggestion list is shown
	const [showSuggestions, setShowSuggestions] = useState(false);
	// What the user has entered

	const onChange = (e) => {
		const userInput = e.currentTarget.value;

		setFilteredSuggestions(
			suggestions.filter((suggestion) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1),
		);

		setActiveSuggestion(0);

		setShowSuggestions(true);
		setUserInput(e.currentTarget.value);
	};

	const onBlur = () => {
		setTimeout(() => {
			setShowSuggestions(false);
		}, 1000);
	};

	const onClick = (e) => {
		setActiveSuggestion(0);
		setFilteredSuggestions([]);
		setShowSuggestions(false);
		setUserInput(e.currentTarget.innerText);
	};

	const onKeyDown = (e) => {
		// User pressed the enter key
		if (e.keyCode === 13) {
			setActiveSuggestion(0);
			setShowSuggestions(false);
			setUserInput(filteredSuggestions[activeSuggestion]);
		}
		// User pressed the up arrow
		else if (e.keyCode === 38) {
			if (activeSuggestion === 0) {
				return;
			}

			setActiveSuggestion(activeSuggestion - 1);
		}
		// User pressed the down arrow
		else if (e.keyCode === 40) {
			if (activeSuggestion - 1 === filteredSuggestions.length) {
				return;
			}

			setActiveSuggestion(activeSuggestion + 1);
		}
	};

	if (showSuggestions && userInput) {
		if (filteredSuggestions.length) {
			suggestionsListComponent = (
				<ul className="suggestions">
					{filteredSuggestions.map((suggestion, index) => {
						let className;

						// Flag the active suggestion with a class
						if (index === activeSuggestion) {
							className = "suggestion-active";
						}

						return (
							<li className={className} key={index} onClick={onClick}>
								{suggestion}
							</li>
						);
					})}
				</ul>
			);
		} else {
			suggestionsListComponent = (
				<ul className="no-suggestions">
					<em>No suggestions, you&#39;re on your own!</em>
				</ul>
			);
		}
	}

	return (
		<>
			{isForm ? (
				<InputField
					type={type}
					label={label}
					name={name}
					value={userInput}
					onChange={onChange}
					error={error}
					onBlur={onBlur}
					placeholder="Type to search"
					tips={tips}
				/>
			) : isForm === undefined ? (
				<div className="input-group mb-3 mb-lg-0 border rounded">
					<div className="input-group-prepend bg-white d-flex align-items-center p-2">
						<span className={`${icon} text-primary`} />
					</div>
					<input
						type={type}
						className="form-control  pl-0 border-0"
						placeholder={placeholder}
						name={name}
						value={userInput}
						onChange={onChange}
						onBlur={onBlur}
					/>
				</div>
			) : (
				<div className="input-group">
					<input
						type="text"
						value={userInput}
						onBlur={onBlur}
						onChange={onChange}
						onKeyDown={onKeyDown}
						placeholder="Search"
						className="form-control"
						aria-label="Recipient&#39;s username"
						aria-describedby="basic-addon2"
					/>
					<div className="input-group-append">
						<button className="btn btn-primary px-md-5" type="button">
							<span className="fa fa-search" />
						</button>
					</div>
				</div>
			)}

			{suggestionsListComponent}
		</>
	);
};

export default AutoSuggestion;
