const affiliationListSuggestions = [
	{
		id: 1,
		title: "Professional associations related to your industry or field.",
		added: false,
	},
	{
		id: 2,
		title: "Trade organizations related to your profession.",
		added: false,
	},
	{
		id: 3,
		title: "Networking groups related to your industry or profession.",
		added: false,
	},
	{
		id: 4,
		title: "Business or entrepreneurial groups.",
		added: false,
	},
	{
		id: 5,
		title: "Non-profit organizations related to your area of expertise.",
		added: false,
	},
	{
		id: 6,
		title: "Community or volunteer organizations you are involved in.",
		added: false,
	},
	{
		id: 7,
		title: "Industry-specific councils or committees.",
		added: false,
	},
	{
		id: 8,
		title: "Local or national chambers of commerce.",
		added: false,
	},
	{
		id: 9,
		title: "Diversity and inclusion organizations or groups.",
		added: false,
	},
	{
		id: 10,
		title: "Alumni associations for your educational institutions.",
		added: false,
	},
	{
		id: 11,
		title: "Charitable organizations you support or have worked with.",
		added: false,
	},
	{
		id: 12,
		title: "Cultural or arts organizations you are affiliated with.",
		added: false,
	},
	{
		id: 13,
		title: "Industry-specific think tanks or research groups.",
		added: false,
	},
	{
		id: 14,
		title: "Professional societies or organizations.",
		added: false,
	},
	{
		id: 15,
		title: "Women's or minority groups related to your field or industry.",
		added: false,
	},
	{
		id: 16,
		title: "Political or advocacy groups you support or volunteer for.",
		added: false,
	},
	{
		id: 17,
		title: "Mentorship organizations or programs.",
		added: false,
	},
	{
		id: 18,
		title: "Service clubs such as Rotary or Lions Club.",
		added: false,
	},
	{
		id: 19,
		title: "Sports or fitness clubs you are involved with.",
		added: false,
	},
	{
		id: 20,
		title: "Environmental or sustainability organizations.",
		added: false,
	},
	{
		id: 21,
		title: "Professional development organizations or programs.",
		added: false,
	},
	{
		id: 22,
		title: "Science, technology, engineering, or math (STEM) organizations or groups.",
		added: false,
	},
	{
		id: 23,
		title: "Historical or heritage societies.",
		added: false,
	},
	{
		id: 24,
		title: "Social justice or human rights organizations.",
		added: false,
	},
	{
		id: 25,
		title: "Local or national business councils or associations.",
		added: false,
	},
	{
		id: 26,
		title: "Education-focused groups or organizations.",
		added: false,
	},
	{
		id: 27,
		title: "Industry-specific foundations or charities.",
		added: false,
	},
	{
		id: 28,
		title: "Religious or faith-based organizations you are affiliated with.",
		added: false,
	},
	{
		id: 29,
		title: "National or international professional groups or organizations.",
		added: false,
	},
	{
		id: 30,
		title: "Industry-specific regulatory bodies or associations.",
		added: false,
	},
	{
		id: 31,
		title: "Literary or writing groups or organizations.",
		added: false,
	},
	{
		id: 32,
		title: "Health and wellness organizations or groups.",
		added: false,
	},
	{
		id: 33,
		title: "Professional fraternities or sororities.",
		added: false,
	},
	{
		id: 34,
		title: "Parent-teacher associations or groups.",
		added: false,
	},
	{
		id: 35,
		title: "Industry-specific forums or discussion groups.",
		added: false,
	},
	{
		id: 36,
		title: "Industry-specific certification or credentialing organizations.",
		added: false,
	},
	{
		id: 37,
		title: "Industry-specific publications or media outlets.",
		added: false,
	},
	{
		id: 38,
		title: "Film or television industry guilds or associations.",
		added: false,
	},
	{
		id: 39,
		title: "Music or entertainment industry associations.",
		added: false,
	},
	{
		id: 40,
		title: "Local or national arts councils.",
		added: false,
	},
	{
		id: 41,
		title: "Business or entrepreneurship accelerators or incubators.",
		added: false,
	},
	{
		id: 42,
		title: "Public relations or communications groups or organizations.",
		added: false,
	},
	{
		id: 43,
		title: "Social media or digital marketing organizations or groups.",
		added: false,
	},
	{
		id: 44,
		title: "Advertising or marketing industry organizations.",
		added: false,
	},
	{
		id: 45,
		title: "Legal or regulatory organizations related to your field.",
		added: false,
	},
	{
		id: 46,
		title: "Financial or accounting industry associations.",
		added: false,
	},
	{
		id: 47,
		title: "Hospitality or tourism industry organizations or groups.",
		added: false,
	},
	{
		id: 48,
		title: "Construction or building industry associations.",
		added: false,
	},
	{
		id: 49,
		title: "Real estate or property management industry groups or organizations.",
		added: false,
	},
	{
		id: 50,
		title: "Transportation or logistics industry groups or organizations.",
		added: false,
	},
];

export default affiliationListSuggestions;
