const affiliations = {
	content: [
		{
			bp: false,
			value:
				"Including your affiliations in your CV can be helpful in showing your involvement and dedication to your profession or field.",
		},
		{
			bp: true,
			value:
				"Only include affiliations that are relevant to your career or field of study. This can include professional associations, industry groups, or academic organizations.",
		},
		{
			bp: true,
			value:
				"If you have multiple affiliations, order them by importance. For example, if you are a member of a national association and a local chapter, list the national association first.",
		},
		{ bp: true, value: "Include the dates you joined and left each affiliation to show your level of involvement." },
		{
			bp: true,
			value:
				"Provide details on your involvement with each affiliation, such as any leadership positions held, committees served on, or events attended.",
		},
		{
			bp: true,
			value: "	If you received any awards or recognition from your affiliations, be sure to include them in your CV.",
		},
	],
};

export default affiliations;
