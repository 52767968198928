import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { isValidEmail } from "../../../handlers/validationFunctions";
import { ResumeDataService } from "../../../services/create-resume/resume-data";

function AddReviewers({ fetchReviewers }) {
	const resumeDataService = new ResumeDataService();

	const navigate = useNavigate();

	const [errors, setErrors] = useState([]);
	const [reviewersData, setReviewersData] = useState({
		fullName: "",
		emailAddress: "",
		relationship: "",
		phoneNumber: "",
	});

	const [reviewerErrors, setReviewerErrors] = useState();
	const [reviewerSuccess, setReviewerSuccess] = useState("");

	const [reviewerLoading, setReviewerLoading] = useState(false);
	function handleAddReviewer(e) {
		e.preventDefault();
		setErrors("");
		if (reviewersData.fullName === "") {
			setReviewerErrors("Full Name is required");
			return;
		}
		if (reviewersData.emailAddress === "") {
			setReviewerErrors("Email Address is required");
			return;
		}
		if (reviewersData.relationship === "") {
			setReviewerErrors("Relationship is required");
			return;
		}
		if (reviewersData.phoneNumber === "") {
			setReviewerErrors("Phone Number is required");
			return;
		}

		if (!isValidEmail(reviewersData.emailAddress)) {
			setReviewerErrors("Email Address is invalid");
			return;
		}

		setReviewerLoading(true);

		const data = {
			fullName: reviewersData.fullName,
			emailAddress: reviewersData.emailAddress,
			relationship: reviewersData.relationship,
			phoneNumber: reviewersData.phoneNumber,
		};

		resumeDataService.addReviewers(data, reviewSuccessCallBack, reviewErrorCallBack);
	}

	function reviewSuccessCallBack() {
		setReviewerLoading(false);
		setReviewerSuccess("Reviewer Added Successfully");
		setReviewersData({
			fullName: "",
			emailAddress: "",
			relationship: "",
			phoneNumber: "",
		});
		fetchReviewers();
	}
	function reviewErrorCallBack(data) {
		setReviewerLoading(false);
		setReviewerErrors(data.response.data.message);
		if (data.response.status === 402) {
			setTimeout(() => {
				navigate("/payments");
			}, 3000);
		}
	}

	function reviewerChangeHandler(e) {
		setReviewersData({
			...reviewersData,
			[e.target.name]: e.target.value,
		});
	}

	return (
		<form onSubmit={(e) => e.preventDefault()} className="mb-3">
			{errors.length > 0 && <div className="alert alert-danger">{errors}</div>}
			{reviewerErrors && <div className="alert alert-danger">{reviewerErrors}</div>}
			{reviewerSuccess && <div className="alert alert-success">{reviewerSuccess}</div>}
			<div className="form-group">
				<label htmlFor="fullName">Full Name</label>
				<input
					type="text"
					className="form-control"
					id="fullName"
					name="fullName"
					value={reviewersData.fullName}
					onChange={reviewerChangeHandler}
				/>
			</div>
			<div className="form-group">
				<label htmlFor="emailAddress">Email Address</label>
				<input
					type="email"
					className="form-control"
					id="emailAddress"
					name="emailAddress"
					value={reviewersData.emailAddress}
					onChange={reviewerChangeHandler}
				/>
			</div>
			<div className="form-group">
				<label htmlFor="relationship">Relationship</label>
				<select
					type="text"
					className="form-control"
					id="relationship"
					name="relationship"
					value={reviewersData.relationship}
					onChange={reviewerChangeHandler}
				>
					<option value="">Select Relationship</option>
					{["Mentor or Advisor", "Colleague", "Supervisor", "Referee", "Family", "Acquittance"].map(
						(relationship, index) => {
							return (
								<option key={index} value={relationship}>
									{relationship}
								</option>
							);
						},
					)}
				</select>
			</div>
			<div className="form-group">
				<label htmlFor="phoneNumber">Phone Number</label>
				<input
					type="text"
					className="form-control"
					id="phoneNumber"
					name="phoneNumber"
					value={reviewersData.phoneNumber}
					onChange={reviewerChangeHandler}
				/>
			</div>
			<div className="d-flex justify-content-end">
				<button
					type="submit"
					className="btn btn-primary rounded-sm"
					onClick={handleAddReviewer}
					disabled={reviewerLoading}
				>
					{reviewerLoading ? (
						<div className="spinner-border text-primary ml-2" role="status">
							<span className="sr-only">Loading</span>
						</div>
					) : (
						"Add reviewer"
					)}
				</button>
			</div>
		</form>
	);
}

export default AddReviewers;
