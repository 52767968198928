import React from "react";

function SingleSkillItem({ onSelectSingleSkill, singleSkill, groupedSkillIndex, singleSkillIndex }) {
	const [isHovered, setIsHovered] = React.useState(false);

	return (
		<div
			className="single-skill-item cursor-pointer"
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			onClick={() => onSelectSingleSkill(groupedSkillIndex, singleSkillIndex)}
		>
			<div className="single-skill-text">{singleSkill.skill}</div>
			{singleSkill.added && (
				<i className={`${isHovered ? "bi bi-trash3 text-danger" : "bi bi-check-circle-fill text-success"} fs-16`} />
			)}
			{!singleSkill.added && (
				<i
					className="fa fa-plus cursor-pointer"
					onClick={() => onSelectSingleSkill(groupedSkillIndex, singleSkillIndex)}
				/>
			)}
		</div>
	);
}

export default SingleSkillItem;
