import { convertUlToArray } from "../../handlers/utilityFunctions";
import { Address } from "./address";

export class Education {
	constructor(eduInfo) {
		this.schoolName = eduInfo.schoolName;
		this.educationLevel = eduInfo.educationLevel;
		this.fieldOfStudyCategory = eduInfo.fieldOfStudyCategory;
		this.fieldOfStudy = eduInfo.fieldOfStudy;
		this.month = parseInt(eduInfo.gMonth);
		this.year = parseInt(eduInfo.gYear);
		this.additionInfo = eduInfo.description;
		this.schoolLocation = new Address(eduInfo.city, eduInfo.region, eduInfo.country);
	}

	getEducationRequest() {
		const textAreaItems = convertUlToArray(this.additionInfo);

		const itemsArray = textAreaItems;

		return {
			schoolName: this.schoolName,
			schoolLocation: this.schoolLocation,
			educationLevel: this.educationLevel,
			fieldOfStudy: this.fieldOfStudy,
			fieldOfStudyCategory: this.fieldOfStudyCategory,
			graduation: {
				month: this.month,
				year: this.year,
			},
			additionInfo: itemsArray,
		};
	}
}

// export class EducationInstitution {

//     constructor() {
//         this.name;
//         this.level;
//         this.address;
//     }
// }
