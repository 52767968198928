import React from "react";

import JobLandPage from "./JobLandPage";

function JobLandingPage() {
	return (
		<>
			<JobLandPage />
		</>
	);
}

export default JobLandingPage;
