import React from "react";

import AvatarChip from "./AvatarChip";

const orderStatus = ["Not Started", "In Progress", "Due soon", "Overdue"];

const getOrderStatusBackgroundColor = (status) => {
	return status === orderStatus[3]
		? "#FFE1E1"
		: status === orderStatus[1]
			? "#DFF7EA"
			: status === orderStatus[2]
				? "#FFECC7"
				: "#F7F8F8";
};

const getOrderStatusColor = (status) => {
	return status === orderStatus[3]
		? "#CF0302"
		: status === orderStatus[1]
			? "#017336"
			: status === orderStatus[2]
				? "#985508"
				: "#484E5C";
};

function OrderTableRow({ data }) {
	const [isHovered, setIsHovered] = React.useState(false);
	return (
		<tr
			onMouseEnter={() => {
				setIsHovered(true);
			}}
			onMouseLeave={() => {
				setIsHovered(false);
			}}
		>
			<td>
				<div className="expert-review-footer-text" style={{ color: "#6E7889" }}>
					{data.orderID}
				</div>
			</td>
			<td>
				<div className="expert-review-top-nav-avatar-text" style={{ color: "#121316" }}>
					{data.candidateName}
					<br />
					<span className="expert-review-footer-text" style={{ color: "#6E7889" }}>
						{data.email}
					</span>
				</div>
			</td>
			<td>
				<div className="expert-review-footer-text" style={{ color: "#373B43" }}>
					{data.package}
				</div>
			</td>
			<td>
				<div className="expert-review-footer-text" style={{ color: "#373B43" }}>
					{data.date}
				</div>
			</td>
			<td>
				<div
					className="d-flex align-items-center justify-content-center"
					style={{
						gap: "8px",
						padding: "4px 8px",
						background: getOrderStatusBackgroundColor(data.status),
						borderRadius: "6px",
						width: "87px",
					}}
				>
					<div
						className="resume-feedback-modal-left-content-text"
						style={{
							color: getOrderStatusColor(data.status),
							fontWeight: 500,
							letterSpacing: "0.0015em",
						}}
					>
						{data.status}
					</div>
				</div>
			</td>
			<td style={{ width: "325px" }}>
				<div className="d-flex align-items-center p-0" style={{ gap: "8px" }}>
					<AvatarChip fullName={data.assignedBy} />
					{isHovered && (
						<div className="d-flex align-items-center p-0" style={{ gap: "8px", width: "146px", height: "32px" }}>
							<div
								className="d-flex flex-column align-items-center justify-content-center p-0 cursor-pointer"
								style={{
									gap: "8px",
									width: "70px",
									height: "32px",
									background: "#FFFFFF",
									border: "1px solid #B9DDFE",
									borderRadius: "6px",
								}}
							>
								<div className="expert-review-navitem-text" style={{ color: "#3E424E" }}>
									Decline
								</div>
							</div>
							<div
								className="d-flex flex-column align-items-center justify-content-center p-0 cursor-pointer"
								style={{
									gap: "8px",
									width: "68px",
									height: "32px",
									background: "#0069CB",
									borderRadius: "6px",
								}}
							>
								<div className="expert-review-navitem-text" style={{ color: "#FFFFFF" }}>
									Accept
								</div>
							</div>
						</div>
					)}
				</div>
			</td>
		</tr>
	);
}

export default OrderTableRow;
