/* eslint-disable no-case-declarations */
import { NavigationFlow, NavigationList } from "../../seed/navigation";

const navigationFlow = new NavigationFlow();

const fresherFlow = navigationFlow.fresherFlow;

let yearsOfExperience = localStorage.getItem("yearsOfExperience");
let templateName = localStorage.getItem("templateName");

if (templateName === null || templateName === undefined) {
	templateName = "fresher_template";
	localStorage.setItem("templateName", templateName);
}

if (yearsOfExperience === null || yearsOfExperience === undefined) {
	yearsOfExperience = "No Experience";
	localStorage.setItem("yearsOfExperience", yearsOfExperience);
}

let currentNav = localStorage.getItem("currentNav");
currentNav = "contact-information";
localStorage.setItem("currentNav", currentNav);
if (currentNav === null || currentNav === undefined || currentNav === "undefined" || currentNav === "heading") {
	currentNav = "contact-information";
	localStorage.setItem("currentNav", currentNav);
}

// Changing the flow to make all flow follow freshflow
//let navFlow =
// 	yearsOfExperience === "No Experience"
// 		? new NavigationList(fresherFlow, fresherFlow)
// 		: new NavigationList(experiencedFlow, experiencedFlow);

let navFlow = new NavigationList(fresherFlow, fresherFlow);

let finalValue = navFlow.current.value.url;
let nextValue = navFlow.current;

while (finalValue !== currentNav || finalValue === "review") {
	nextValue = navFlow.current.next;
	navFlow.current = nextValue === null || nextValue === undefined ? navFlow.current : nextValue;
	finalValue = navFlow.current.value.url;
}

export default function TemplateSelectionReducer(
	template = {
		selectedTemplate: {
			yearsOfExperience: yearsOfExperience,
			templateName: templateName,
		},
		navigationFlow: navFlow,
	},
	action,
) {
	switch (action.type) {
		// case "CHANGE_TEMPLATE_DETAILS":
		case "CHANGE_TEMPLATE_DETAILS":
			let newTemplate = template;
			newTemplate.selectedTemplate = action.payload.selectedTemplate;
			newTemplate.navigationFlow = action.payload.navigationFlow;

			return newTemplate;

		default:
			return template;
	}
}
// const noExperienceList = new NavigationList(fresherFlow, template.navigationFlow.current);
