const extras = {
	content: [
		{
			bp: false,
			value:
				"	Adding accomplishments, affiliations, websites, portfolio, profiles, additional information, certifications, languages, referee and personal profile can provide a more complete picture of your skills, experience, and personal interests.",
		},
	],
};

export default extras;
