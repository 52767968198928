import React from "react";

function CustomInputSkill({ groupedSkillIndex, singleSkillIndex, onSaveCustomSkillAdded }) {
	const [value, setValue] = React.useState("");

	return (
		<div className="custom-input-skill-container">
			<input
				type="text"
				value={value}
				className="custom-input-skill"
				onChange={(e) => {
					setValue(e.target.value);
				}}
			/>
			<div
				className="end-icon-custom-input-skill-container"
				onClick={() => {
					onSaveCustomSkillAdded(groupedSkillIndex, singleSkillIndex, value);
				}}
			>
				<span className="fa fa-check text-white" />
			</div>
		</div>
	);
}

export default CustomInputSkill;
