import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { NavigationHandler } from "../../../handlers/navigationHandler";

const ResumeProgress = ({ resumeSections }) => {
	const navigationHandler = new NavigationHandler();
	const navigate = new useNavigate();

	function navigateToStep(link) {
		navigationHandler.setFinalize(link);
		return navigate(`/resume/${link}`);
	}

	const currentSection = window.location.pathname.split("/")[2];

	const extrasSections = resumeSections.filter((item) => item.isExtraSection === true);

	const isExtraSection = extrasSections.find((item) => item.section === currentSection);

	const sidebarLinksRef = React.useRef();
	return (
		<section className="resume-progress bg-white">
			<div className="inner">
				<Link to="/" className="logo p-4">
					<img src="/images/logo.png" alt="" />
				</Link>

				<div className="progress-section" ref={sidebarLinksRef}>
					<div
						className="resume-progress-sidenav-bar"
						style={{
							height:
								(sidebarLinksRef.current ? sidebarLinksRef.current.clientHeight : "100%") -
									(isExtraSection || currentSection === "extras" ? 80 : 52.5) || 270,
						}}
					/>

					{resumeSections.map((item, index) => (
						<div onClick={() => navigateToStep(item.section)} key={index}>
							<div className={`step ${window.location.pathname.includes(item.section) ? "active" : ""}`}>
								<span className="step-text">
									<i
										className={`bi mr-2 fs-20 ${item.progressStatus === "COMPLETED" ? "bi-check-circle-fill text-success" : "bi-circle text-light-blue"}`}
									/>
									{item.title}
								</span>
							</div>
						</div>
					))}
					{(isExtraSection || currentSection === "extras") && (
						<div
							onClick={() => navigateToStep("extras")}
							className="px-4 py-2 text-primary font-weight-semi cursor-pointer"
						>
							<p>
								<i className="fa fa-plus mr-2" /> Add another section
							</p>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default ResumeProgress;
