import React from "react";

import FeedBackModal from "../../../../components/Reviews/FeedBackModal/FeedBackModal";
import { sections } from "../../../../handlers/enums";
import { convertDateToWords, convertUlToArray, removeHTTPAndDomainName } from "../../../../handlers/utilityFunctions";
import useReviewFeedBack from "../../../../hooks/use-ReviewFeedBack";

function ResumePremiumOne(props) {
	const { isReview, reviewData, resumeData, hasFeedback, activeSection, onSubmitFeedBack, loadingSubmission } = props;
	const { useReviewFeedback, feedBackButton } = useReviewFeedBack(reviewData);

	return (
		<div className="bg-white  final-resume-preview-wrapper final-premium-template-1 final-resume-preview-new shadow-sm">
			<div className="bg-white text-dark">
				<div className="p-1 p-md-3">
					<table
						className="w-100 mx-auto"
						style={{
							tableLayout: "fixed",
							width: "100%",
							border: 0,
						}}
						id="printResume"
					>
						<tbody>
							{resumeData.contactInfo.firstName !== "" && (
								<tr>
									<td className="pb-3">
										<div className="d-flex">
											<div style={{ width: "66.66%" }} className="d-inline-block">
												<div className="d-flex" style={{ height: "100%" }}>
													<span
														className="border-line"
														style={{
															backgroundColor: "#397ea8",
															flex: "0 0 10px",
															borderRadius: "5px",
														}}
													/>
													<div
														className="ml-md-3 ml-1 p-md-3 p-1"
														style={{
															backgroundColor: "#ebebeb",
															borderRadius: "10px",
															height: "100%",
														}}
													>
														<h3 style={{ color: "#397ea8" }}>
															{resumeData.contactInfo.firstName} {isReview} {resumeData.contactInfo.middleName}{" "}
															{resumeData.contactInfo.lastName}
														</h3>
														<h4 style={{ color: "#bcbcbc" }}>{resumeData.contactInfo.resumeTitle}</h4>
														{resumeData.summary.length > 0 && (
															<div
																className={`professional-summary-info mt-2 position-relative review-section ${
																	isReview
																		? "rs-highlight"
																		: hasFeedback
																			? activeSection === sections.SUMMARY
																				? "rs-feedback rs-feedback-active"
																				: "rs-feedback"
																			: ""
																}`}
																id={sections.SUMMARY}
																style={{
																	backgroundColor:
																		useReviewFeedback.section.section === sections.SUMMARY
																			? "rgba(27, 175, 245, 0.12)"
																			: "",
																}}
															>
																{isReview || activeSection === sections.SUMMARY
																	? feedBackButton(sections.SUMMARY, hasFeedback)
																	: null}
																<span
																	dangerouslySetInnerHTML={{
																		__html: resumeData.summary,
																	}}
																/>
															</div>
														)}
													</div>
												</div>
											</div>
											<div
												style={{
													width: "33.33 %",
													backgroundColor: "#397ea8",
													color: "#bcbcbc",
													borderRadius: "10px",
												}}
												className="d-inline-block ml-md-3 ml-1 p-md-3 p-1"
											>
												<div className="d-flex align-items-center mb-2">
													<div
														style={{
															border: "1px solid #bcbcbc",
															borderRadius: "50%",
															height: "35px",
															width: "35px",

															textAlign: "center",
															lineHeight: "35px",
															fontSize: "20px",
															marginRight: "10px",
															flex: "0 0 35px",
															color: "#bcbcbc",
														}}
														className="contact-icon"
													>
														<span className="fa fa-envelope" />
													</div>
													<span style={{ color: "#bcbcbc" }}>{resumeData.contactInfo.email}</span>
												</div>
												<div className="d-flex align-items-center mb-2">
													<div
														style={{
															border: "1px solid #bcbcbc",
															borderRadius: "50%",
															height: "35px",
															width: "35px",
															textAlign: "center",
															lineHeight: "35px",
															fontSize: "20px",
															marginRight: "10px",
															flex: "0 0 35px",
															color: "#bcbcbc",
														}}
														className="contact-icon"
													>
														<span className="fa fa-mobile" />
													</div>
													<span style={{ color: "#bcbcbc" }}>{resumeData.contactInfo.phoneNo}</span>
												</div>
												<div className="d-flex align-items-center mb-2">
													<div
														style={{
															border: "1px solid #bcbcbc",
															borderRadius: "50%",
															height: "35px",
															width: "35px",
															textAlign: "center",
															lineHeight: "35px",
															fontSize: "20px",
															marginRight: "10px",
															flex: "0 0 35px",
															color: "#bcbcbc",
														}}
														className="contact-icon"
													>
														<span className="fa fa-map-marker" />
													</div>
													<span style={{ color: "#bcbcbc" }}>
														{resumeData.contactInfo.city}, {resumeData.contactInfo.country}
													</span>
												</div>
												{[...resumeData.extras.personalLinks].map((link, index) => {
													return link.type !== "otherLink" ? (
														<div key={index} className="d-flex align-items-center mb-2">
															<div
																style={{
																	border: "1px solid #bcbcbc",
																	borderRadius: "50%",
																	height: "35px",
																	width: "35px",
																	textAlign: "center",
																	lineHeight: "35px",
																	fontSize: "20px",
																	marginRight: "10px",
																	flex: "0 0 35px",
																}}
																className="contact-icon"
															>
																<span
																	className={`${
																		link.type === "linkedIn"
																			? "bi bi-linkedin"
																			: link.type === "twitter"
																				? "bi bi-twitter"
																				: link.type === "github"
																					? "bi bi-github"
																					: "fa fa-link"
																	}`}
																	style={{ color: "#bcbcbc" }}
																/>
															</div>
															<span>
																<a href={link.value} target="_blank" rel="noreferrer" style={{ color: "#bcbcbc" }}>
																	{removeHTTPAndDomainName(link)}
																</a>
															</span>
														</div>
													) : null;
												})}
											</div>
										</div>
									</td>
								</tr>
							)}

							{resumeData.skills !== "" ? (
								<tr>
									<td className="pb-3">
										<div className="d-flex" style={{ height: "100%" }}>
											<span
												className="border-line"
												style={{
													backgroundColor: "#bcbcbc",
													flex: "0 0 10px",

													borderRadius: "5px",
													minHeight: "10px",
												}}
											/>
											<div className="ml-3" style={{ flexGrow: 1 }}>
												<h3 style={{ color: "#397ea8" }} className="mb-4">
													<span
														style={{
															border: "2px solid #397ea8",
															height: "45px",
															width: "45px",
															lineHeight: "45px",
															textAlign: "center",
															marginRight: "10px",
															borderRadius: "50%",
															color: "#397ea8",
															display: "inline-block",
															flex: "0 0 45px",
														}}
														className="title-icon"
													>
														<span className="fa fa-cogs" />
													</span>
													SKILLS
												</h3>
												<div
													className={`d-flex flex-wrap position-relative review-section ${
														isReview
															? "rs-highlight"
															: hasFeedback
																? activeSection === sections.SKILLS
																	? "rs-feedback rs-feedback-active"
																	: "rs-feedback"
																: ""
													}`}
													id={sections.SKILLS}
													style={{
														backgroundColor:
															useReviewFeedback.section.section === sections.SKILLS ? "rgba(27, 175, 245, 0.12)" : "",
													}}
												>
													{isReview || activeSection === sections.SKILLS
														? feedBackButton(sections.SKILLS, hasFeedback)
														: null}
													{convertUlToArray(resumeData.skills).map((item, index) => {
														const splitArray = item.value.split(/:(.+)/);
														let wordBeforeColon = splitArray[0].trim();
														wordBeforeColon =
															splitArray.length > 1 ? <strong>{wordBeforeColon}</strong> : wordBeforeColon;
														const wordAfterColon = splitArray.length > 1 ? ": " + splitArray[1].trim() : "";

														return (
															<div
																key={index}
																style={{ width: "100%" }}
																className="d-flex mb-0 mb-md-3 align-items-center"
															>
																<div>
																	{wordBeforeColon}
																	{wordAfterColon}
																</div>
															</div>
														);
													})}
												</div>
											</div>
										</div>
									</td>
								</tr>
							) : null}
							{resumeData.extras.certifications !== "" ? (
								<tr>
									<td className="pb-3">
										<div className="d-flex" style={{ height: "100%" }}>
											<span
												className="border-line"
												style={{
													backgroundColor: "#397ea8",
													flex: "0 0 10px",

													borderRadius: "5px",
													minHeight: "10px",
												}}
											/>
											<div className="ml-3" style={{ flexGrow: 1 }}>
												<h3 style={{ color: "#397ea8" }} className="mb-4">
													<span
														style={{
															border: "2px solid #397ea8",
															height: "45px",
															width: "45px",
															lineHeight: "45px",
															textAlign: "center",
															marginRight: "10px",
															borderRadius: "50%",
															color: "#397ea8",
															display: "inline-block",
															flex: "0 0 45px",
														}}
														className="title-icon"
													>
														<span className="fa fa-certificate" />
													</span>
													CERTIFICATIONS
												</h3>
												<div
													className={`d-flex flex-wrap position-relative review-section ${
														isReview
															? "rs-highlight"
															: hasFeedback
																? activeSection === sections.CERTIFICATION
																	? "rs-feedback rs-feedback-active"
																	: "rs-feedback"
																: ""
													}`}
													id={sections.CERTIFICATION}
													style={{
														backgroundColor:
															useReviewFeedback.section.section === sections.CERTIFICATION
																? "rgba(27, 175, 245, 0.12)"
																: "",
													}}
												>
													{isReview || activeSection === sections.CERTIFICATION
														? feedBackButton(sections.CERTIFICATION, hasFeedback)
														: null}
													{convertUlToArray(resumeData.extras.certifications).map((item, index) => {
														return (
															<div
																key={index}
																style={{ width: "50%" }}
																className="d-flex mb-0 mb-md-3 align-items-center"
															>
																{item.value}
															</div>
														);
													})}
												</div>
											</div>
										</div>
									</td>
								</tr>
							) : null}
							{resumeData.extras.accomplishments !== "" ? (
								<tr>
									<td className="pb-3">
										<div className="d-flex" style={{ height: "100%" }}>
											<span
												className="border-line"
												style={{
													backgroundColor: "#bcbcbc",
													flex: "0 0 10px",

													borderRadius: "5px",
													minHeight: "10px",
												}}
											/>
											<div className="ml-3" style={{ flexGrow: 1 }}>
												<h3 style={{ color: "#397ea8" }} className="mb-4">
													<span
														style={{
															border: "2px solid #397ea8",
															height: "45px",
															width: "45px",
															lineHeight: "45px",
															textAlign: "center",
															marginRight: "10px",
															borderRadius: "50%",
															color: "#397ea8",
															display: "inline-block",
															flex: "0 0 45px",
														}}
														className="title-icon"
													>
														<span className="fa fa-check" />
													</span>
													ACCOMPLISHMENTS
												</h3>
												<div
													className={`d-flex flex-wrap flex-column position-relative review-section ${
														isReview
															? "rs-highlight"
															: hasFeedback
																? activeSection === sections.ACCOMPLISHMENTS
																	? "rs-feedback rs-feedback-active"
																	: "rs-feedback"
																: ""
													}`}
													id={sections.ACCOMPLISHMENTS}
													style={{
														backgroundColor:
															useReviewFeedback.section.section === sections.ACCOMPLISHMENTS
																? "rgba(27, 175, 245, 0.12)"
																: "",
													}}
												>
													{isReview || activeSection === sections.ACCOMPLISHMENTS
														? feedBackButton(sections.ACCOMPLISHMENTS, hasFeedback)
														: null}
													{convertUlToArray(resumeData.extras.accomplishments).map((item, index) => {
														return (
															<div key={index} className="d-flex mb-0 mb-md-3 align-items-center">
																{item.value}
															</div>
														);
													})}
												</div>
											</div>
										</div>
									</td>
								</tr>
							) : null}
							{resumeData.workHistory.length > 0 && resumeData.workHistory[0].jobTitle !== "" ? (
								<tr>
									<td className="pb-3">
										<div className="d-flex" style={{ height: "100%" }}>
											<span
												className="border-line"
												style={{
													backgroundColor: "#397ea8",
													flex: "0 0 10px",

													borderRadius: "5px",
													minHeight: "10px",
												}}
											/>
											<div className="ml-3" style={{ flexGrow: 1 }}>
												<h3 style={{ color: "#397ea8" }} className="mb-4">
													<span
														style={{
															border: "2px solid #397ea8",
															height: "45px",
															width: "45px",
															lineHeight: "45px",
															textAlign: "center",
															marginRight: "10px",
															borderRadius: "50%",
															color: "#397ea8",
															display: "inline-block",
															flex: "0 0 45px",
														}}
														className="title-icon"
													>
														<span className="fa fa-briefcase" />
													</span>
													WORK EXPERIENCE
												</h3>
												<div
													className={`position-relative review-section ${
														isReview
															? "rs-highlight"
															: hasFeedback
																? activeSection === sections.EXPERIENCE
																	? "rs-feedback rs-feedback-active"
																	: "rs-feedback"
																: ""
													}`}
													id={sections.EXPERIENCE}
													style={{
														backgroundColor:
															useReviewFeedback.section.section === sections.EXPERIENCE
																? "rgba(27, 175, 245, 0.12)"
																: "",
													}}
												>
													{isReview || activeSection === sections.EXPERIENCE
														? feedBackButton(sections.EXPERIENCE, hasFeedback)
														: null}
													{[...resumeData.workHistory].map((job) => (
														<>
															<div className="d-flex justify-content-between">
																<p className="mb-2">
																	<strong>{job.employer}</strong>
																	{" - "}
																	{job.city}, {job.country}
																	<br />
																	<em>{job.jobTitle}</em>
																</p>
																<p className="mb-0">
																	<strong>
																		{convertDateToWords(job.startDate)} -{" "}
																		{job.endDate !== "" ? convertDateToWords(job.endDate) : "Present"}
																	</strong>
																</p>
															</div>
															{convertUlToArray(job.details).map((item, index) => {
																return (
																	<div key={index} className="d-flex mb-0 mb-md-3 align-items-center">
																		<span
																			className="fa fa-circle"
																			style={{
																				fontSize: "5px",
																				marginRight: "10px",
																				color: "#bcbcbc",
																			}}
																		/>
																		{item.value}
																	</div>
																);
															})}
														</>
													))}
												</div>
											</div>
										</div>
									</td>
								</tr>
							) : null}
							{resumeData.education.length > 0 && (
								<tr>
									<td className="pb-3">
										<div className="d-flex" style={{ height: "100%" }}>
											<span
												className="border-line"
												style={{
													backgroundColor: "#bcbcbc",
													flex: "0 0 10px",

													borderRadius: "5px",
													minHeight: "10px",
												}}
											/>
											<div className="ml-3" style={{ flexGrow: 1 }}>
												<h3 style={{ color: "#397ea8" }} className="mb-4">
													<span
														style={{
															border: "2px solid #397ea8",
															height: "45px",
															width: "45px",
															lineHeight: "45px",
															textAlign: "center",
															marginRight: "10px",
															borderRadius: "50%",
															color: "#397ea8",
															display: "inline-block",
															flex: "0 0 45px",
														}}
														className="title-icon"
													>
														<span className="fa fa-graduation-cap" />
													</span>
													EDUCATION
												</h3>
												<div
													className={`professional-summary-info mt-2 position-relative review-section ${
														isReview
															? "rs-highlight"
															: hasFeedback
																? activeSection === sections.EDUCATION
																	? "rs-feedback rs-feedback-active"
																	: "rs-feedback"
																: ""
													}`}
													id={sections.EDUCATION}
													style={{
														backgroundColor:
															useReviewFeedback.section.section === sections.EDUCATION
																? "rgba(27, 175, 245, 0.12)"
																: "",
													}}
												>
													{isReview || activeSection === sections.EDUCATION
														? feedBackButton(sections.EDUCATION, hasFeedback)
														: null}
													{[...resumeData.education].map((educationHistory, index) => (
														<div key={index} className="mb-3">
															<div className="d-flex justify-content-between">
																<p>
																	<strong>{educationHistory.schoolName}</strong>
																	{" - "}
																	{educationHistory.schoolCity}, <br />
																	<em>{educationHistory.fieldOfStudy}</em>
																</p>
																<p className="mr-4">
																	<strong>{educationHistory.gYear}</strong>
																</p>
															</div>
															{convertUlToArray(educationHistory.description).map((item, index) => {
																if (item.value === "") {
																	return null;
																}
																return (
																	<div key={index} className="d-flex mb-0 mb-md-1 align-items-center">
																		<span
																			className="fa fa-circle"
																			style={{
																				fontSize: "5px",
																				marginRight: "10px",
																				color: "#bcbcbc",
																			}}
																		/>
																		{item.value}
																	</div>
																);
															})}
														</div>
													))}
												</div>
											</div>
										</div>
									</td>
								</tr>
							)}
							{resumeData.extras.languages.length !== 0 ? (
								<tr>
									<td className="pb-3">
										<div className="d-flex" style={{ height: "100%" }}>
											<span
												className="border-line"
												style={{
													backgroundColor: "#397ea8",
													flex: "0 0 10px",

													borderRadius: "5px",
													minHeight: "10px",
												}}
											/>
											<div className="ml-3" style={{ flexGrow: 1 }}>
												<h3 style={{ color: "#397ea8" }} className="mb-4">
													<span
														style={{
															border: "2px solid #397ea8",
															height: "45px",
															width: "45px",
															lineHeight: "45px",
															textAlign: "center",
															marginRight: "10px",
															borderRadius: "50%",
															color: "#397ea8",
															display: "inline-block",
															flex: "0 0 45px",
														}}
														className="title-icon"
													>
														<span className="fa fa-language" />
													</span>
													LANGUAGES
												</h3>
												<div
													className={`d-flex flex-wrap position-relative review-section ${
														isReview
															? "rs-highlight"
															: hasFeedback
																? activeSection === sections.LANGUAGES
																	? "rs-feedback rs-feedback-active"
																	: "rs-feedback"
																: ""
													}`}
													id={sections.LANGUAGES}
													style={{
														backgroundColor:
															useReviewFeedback.section.section === sections.LANGUAGES
																? "rgba(27, 175, 245, 0.12)"
																: "",
													}}
												>
													{isReview || activeSection === sections.LANGUAGES
														? feedBackButton(sections.LANGUAGES, hasFeedback)
														: null}
													{resumeData.extras.languages.map((language, index) => (
														<div
															key={index}
															style={{ width: "50%" }}
															className="d-flex flex-wrap mb-0 mb-md-3 align-items-center"
														>
															<div>
																<span className="mr-2">{language.language}</span>
																{" - "}
																<span className="mr-2">{language.proficiency}</span>
															</div>
														</div>
													))}
												</div>
											</div>
										</div>
									</td>
								</tr>
							) : null}
							{resumeData.extras.personalProfile.dateOfBirth && (
								<tr>
									<td className="pb-3">
										<div className="d-flex" style={{ height: "100%" }}>
											<span
												className="border-line"
												style={{
													backgroundColor: "#bcbcbc",
													flex: "0 0 10px",

													borderRadius: "5px",
													minHeight: "10px",
												}}
											/>
											<div className="ml-3" style={{ flexGrow: 1 }}>
												<h3 style={{ color: "#397ea8" }} className="mb-4">
													<span
														style={{
															border: "2px solid #397ea8",
															height: "45px",
															width: "45px",
															lineHeight: "45px",
															textAlign: "center",
															marginRight: "10px",
															borderRadius: "50%",
															color: "#397ea8",
															display: "inline-block",
															flex: "0 0 45px",
														}}
														className="title-icon"
													>
														<span className="fa fa-user" />
													</span>
													PERSONAL INFORMATION
												</h3>
												<div
													className={`d-flex flex-wrap professional-summary-info mt-2 position-relative review-section ${
														isReview
															? "rs-highlight"
															: hasFeedback
																? activeSection === sections.PERSONAL
																	? "rs-feedback rs-feedback-active"
																	: "rs-feedback"
																: ""
													}`}
													id={sections.PERSONAL}
													style={{
														backgroundColor:
															useReviewFeedback.section.section === sections.PERSONAL ? "rgba(27, 175, 245, 0.12)" : "",
													}}
												>
													{isReview || activeSection === sections.PERSONAL
														? feedBackButton(sections.PERSONAL, hasFeedback)
														: null}
													<div style={{ width: "50%" }} className="d-flex mb-0 mb-md-3 align-items-center">
														<strong>Date of Birth:</strong>&nbsp;
														{resumeData.extras.personalProfile.dateOfBirth}
													</div>
													<div style={{ width: "50%" }} className="d-flex mb-0 mb-md-3 align-items-center">
														<strong>Gender:</strong>&nbsp;
														{resumeData.extras.personalProfile.gender}
													</div>
													<div style={{ width: "50%" }} className="d-flex mb-0 mb-md-3 align-items-center">
														<strong>Marital Status:</strong>&nbsp;
														{resumeData.extras.personalProfile.maritalStatus}
													</div>
												</div>
											</div>
										</div>
									</td>
								</tr>
							)}
							{resumeData.extras.affiliations !== "" ? (
								<tr>
									<td className="pb-3">
										<div className="d-flex" style={{ height: "100%" }}>
											<span
												className="border-line"
												style={{
													backgroundColor: "#397ea8",
													flex: "0 0 10px",

													borderRadius: "5px",
													minHeight: "10px",
												}}
											/>
											<div className="ml-3" style={{ flexGrow: 1 }}>
												<h3 style={{ color: "#397ea8" }} className="mb-4">
													<span
														style={{
															border: "2px solid #397ea8",
															height: "45px",
															width: "45px",
															lineHeight: "45px",
															textAlign: "center",
															marginRight: "10px",
															borderRadius: "50%",
															color: "#397ea8",
															display: "inline-block",
															flex: "0 0 45px",
														}}
														className="title-icon"
													>
														<span className="fa fa-handshake" />
													</span>
													AFFILIATIONS
												</h3>
												<div
													className={`d-flex flex-wrap position-relative review-section ${
														isReview
															? "rs-highlight"
															: hasFeedback
																? activeSection === sections.AFFILIATION
																	? "rs-feedback rs-feedback-active"
																	: "rs-feedback"
																: ""
													}`}
													id={sections.AFFILIATION}
													style={{
														backgroundColor:
															useReviewFeedback.section.section === sections.AFFILIATION
																? "rgba(27, 175, 245, 0.12)"
																: "",
													}}
												>
													{isReview || activeSection === sections.AFFILIATION
														? feedBackButton(sections.AFFILIATION, hasFeedback)
														: null}
													{convertUlToArray(resumeData.extras.affiliations).map((item, index) => {
														return (
															<div
																key={index}
																style={{ width: "50%" }}
																className="d-flex mb-0 mb-md-3 align-items-center"
															>
																{item.value}
															</div>
														);
													})}
												</div>
											</div>
										</div>
									</td>
								</tr>
							) : null}
							{resumeData.extras.additionalInfo !== "" ? (
								<tr>
									<td className="pb-3">
										<div className="d-flex" style={{ height: "100%" }}>
											<span
												className="border-line"
												style={{
													backgroundColor: "#bcbcbc",
													flex: "0 0 10px",

													borderRadius: "5px",
													minHeight: "10px",
												}}
											/>
											<div className="ml-3" style={{ flexGrow: 1 }}>
												<h3 style={{ color: "#397ea8" }} className="mb-4">
													<span
														style={{
															border: "2px solid #397ea8",
															height: "45px",
															width: "45px",
															lineHeight: "45px",
															textAlign: "center",
															marginRight: "10px",
															borderRadius: "50%",
															color: "#397ea8",
															display: "inline-block",
															flex: "0 0 45px",
														}}
														className="title-icon"
													>
														<span className="fa fa-info" />
													</span>
													ADDITIONAL INFORMATION
												</h3>
												<div
													className={`d-flex flex-wrap position-relative review-section ${
														isReview
															? "rs-highlight"
															: hasFeedback
																? activeSection === sections.ADDITIONAL_INFO
																	? "rs-feedback rs-feedback-active"
																	: "rs-feedback"
																: ""
													}`}
													id={sections.ADDITIONAL_INFO}
													style={{
														backgroundColor:
															useReviewFeedback.section.section === sections.ADDITIONAL_INFO
																? "rgba(27, 175, 245, 0.12)"
																: "",
													}}
												>
													{isReview || activeSection === sections.ADDITIONAL_INFO
														? feedBackButton(sections.ADDITIONAL_INFO, hasFeedback)
														: null}
													{convertUlToArray(resumeData.extras.additionalInfo).map((item, index) => {
														return (
															<div
																key={index}
																style={{ width: "50%" }}
																className="d-flex mb-0 mb-md-3 align-items-center"
															>
																{item.value}
															</div>
														);
													})}
												</div>
											</div>
										</div>
									</td>
								</tr>
							) : null}
							{[...resumeData.extras.personalLinks].some((link) => link.type === "otherLink") && (
								<tr>
									<td className="pb-3">
										<div className="d-flex" style={{ height: "100%" }}>
											<span
												className="border-line"
												style={{
													backgroundColor: "#397ea8",
													flex: "0 0 10px",

													borderRadius: "5px",
													minHeight: "10px",
												}}
											/>
											<div className="ml-3" style={{ flexGrow: 1 }}>
												<h3 style={{ color: "#397ea8" }} className="mb-4">
													<span
														style={{
															border: "2px solid #397ea8",
															height: "45px",
															width: "45px",
															lineHeight: "45px",
															textAlign: "center",
															marginRight: "10px",
															borderRadius: "50%",
															color: "#397ea8",
															display: "inline-block",
															flex: "0 0 45px",
														}}
														className="title-icon"
													>
														<span className="fa fa-link" />
													</span>
													WEBSITE, PORTFOLIO, PROFILES
												</h3>
												<div
													className={`d-flex flex-wrap position-relative review-section ${
														isReview
															? "rs-highlight"
															: hasFeedback
																? activeSection === sections.LINKS
																	? "rs-feedback rs-feedback-active"
																	: "rs-feedback"
																: ""
													}`}
													id={sections.LINKS}
													style={{
														backgroundColor:
															useReviewFeedback.section.section === sections.LINKS ? "rgba(27, 175, 245, 0.12)" : "",
													}}
												>
													{isReview || activeSection === sections.LINKS
														? feedBackButton(sections.LINKS, hasFeedback)
														: null}
													{[...resumeData.extras.personalLinks].map((link, index) => {
														return link.type === "otherLink" ? (
															<div key={index} className="d-flex mb-0 mb-md-3 align-items-center">
																<span className="text-capitalize">{link.type}</span>: {link.value}
															</div>
														) : null;
													})}
												</div>
											</div>
										</div>
									</td>
								</tr>
							)}
							{resumeData.extras.references.length > 0 ? (
								<tr>
									<td className="pb-3">
										<div className="d-flex" style={{ height: "100%" }}>
											<span
												className="border-line"
												style={{
													backgroundColor: "#bcbcbc",
													flex: "0 0 10px",

													borderRadius: "5px",
													minHeight: "10px",
												}}
											/>
											<div className="ml-3" style={{ flexGrow: 1 }}>
												<h3 style={{ color: "#397ea8" }} className="mb-4">
													<span
														style={{
															border: "2px solid #397ea8",
															height: "45px",
															width: "45px",
															lineHeight: "45px",
															textAlign: "center",
															marginRight: "10px",
															borderRadius: "50%",
															color: "#397ea8",
															display: "inline-block",
															flex: "0 0 45px",
														}}
														className="title-icon"
													>
														<span className="fa fa-asterisk" />
													</span>
													REFERENCES
												</h3>
												<div
													className={`d-flex flex-wrap position-relative review-section ${
														isReview
															? "rs-highlight"
															: hasFeedback
																? activeSection === sections.REFERENCE
																	? "rs-feedback rs-feedback-active"
																	: "rs-feedback"
																: ""
													}`}
													id={sections.REFERENCE}
													style={{
														backgroundColor:
															useReviewFeedback.section.section === sections.REFERENCE
																? "rgba(27, 175, 245, 0.12)"
																: "",
													}}
												>
													{isReview || activeSection === sections.REFERENCE
														? feedBackButton(sections.REFERENCE, hasFeedback)
														: null}
													{[...resumeData.extras.references].map((reference, index) => (
														<div key={index} style={{ width: "50%" }} className="d-flex mb-0 mb-md-3">
															<div>
																<strong>{reference.name}</strong>
																{" - "}
																<em>{reference.designation}, </em>
																{reference.workPlace}
																<br />
																<p style={{ color: "#bcbcbc" }}>
																	{reference.email}, {reference.phoneNumber}
																</p>
															</div>
														</div>
													))}
												</div>
											</div>
										</div>
									</td>
								</tr>
							) : null}
							{resumeData.extras.customExtras.name.length > 0 ? (
								<tr>
									<td className="pb-3">
										<div className="d-flex" style={{ height: "100%" }}>
											<span
												className="border-line"
												style={{
													backgroundColor: "#397ea8",
													flex: "0 0 10px",

													borderRadius: "5px",
													minHeight: "10px",
												}}
											/>
											<div className="ml-3" style={{ flexGrow: 1 }}>
												<h3 style={{ color: "#397ea8" }} className="mb-4">
													<span
														style={{
															border: "2px solid #397ea8",
															height: "45px",
															width: "45px",
															lineHeight: "45px",
															textAlign: "center",
															marginRight: "10px",
															borderRadius: "50%",
															color: "#397ea8",
															display: "inline-block",
															flex: "0 0 45px",
														}}
														className="title-icon"
													>
														<span className="bi bi-journal-text" />
													</span>
													{resumeData.extras.customExtras.name}
												</h3>
												<div
													className={`d-flex flex-wrap position-relative review-section ${
														isReview
															? "rs-highlight"
															: hasFeedback
																? activeSection === sections.CUSTOM
																	? "rs-feedback rs-feedback-active"
																	: "rs-feedback"
																: ""
													}`}
													id={sections.CUSTOM}
													style={{
														backgroundColor:
															useReviewFeedback.section.section === sections.CUSTOM ? "rgba(27, 175, 245, 0.12)" : "",
													}}
												>
													{isReview || activeSection === sections.PERSONAL
														? feedBackButton(sections.PERSONAL, hasFeedback)
														: null}
													{convertUlToArray(resumeData.extras.customExtras.data).map((item, index) => {
														return (
															<div
																key={index}
																style={{ width: "50%" }}
																className="d-flex mb-0 mb-md-3 align-items-center"
															>
																{item.value}
															</div>
														);
													})}
												</div>
											</div>
										</div>
									</td>
								</tr>
							) : null}
						</tbody>
					</table>
				</div>
			</div>
			{useReviewFeedback.showFeedBackModal && (
				<FeedBackModal
					resumeData={resumeData}
					details={useReviewFeedback.section}
					onSubmitFeedBack={onSubmitFeedBack}
					loadingSubmission={loadingSubmission}
				/>
			)}
		</div>
	);
}

export default ResumePremiumOne;
