import React, { useRef } from "react";

function ExtrasCheckbox({ section, extras, extrasChoicesHandler, navigate }) {
	const checkBoxRef = useRef(null);

	const isChecked = extras.extrasList[section.id];

	return (
		<div
			className={`d-flex align-items-center justify-content-between flex-grow-1 border px-3 py-2 rounded-sm border-dashed ${isChecked && "bg-light-blue"} mb-3`}
			onClick={() => {
				checkBoxRef.current.click();
			}}
		>
			<p className="m-0 font-weight-semi">{section.label}</p>
			<input
				type="checkbox"
				className="d-none"
				ref={checkBoxRef}
				id={section.name}
				name={section.name}
				checked={isChecked}
				onChange={extrasChoicesHandler}
			/>
			{isChecked ? (
				<span
					onClick={() => navigate(section.link)}
					className="text-primary font-weight-semi cursor-pointer"
					data-toggle="tooltip"
					data-placement="right"
					title="Click to Add/Edit"
				>
					Update
				</span>
			) : (
				<span>
					<i className="fa fa-plus" />
				</span>
			)}
		</div>
	);
}

export default ExtrasCheckbox;
