import { match } from "../handlers/utilityFunctions";

export default function (section) {
	return match(section, routes);
}

const routes = {
	HEADING: "/resume/contact-information",
	EXPERIENCE: "/resume/work-history-summary",
	EDUCATION: "/resume/education-summary",
	SKILLS: "/resume/skills",
	SUMMARY: "/resume/brief-summary",
	REFERENCE: "/resume/reference-summary",
	CERTIFICATION: "/resume/certifications",
	AFFILIATION: "/resume/affiliations",
	ADDITIONAL_INFO: "/resume/additional-information",
	LANGUAGES: "/resume/languages",
	// ACHIEVEMENTS: 'ACHIEVEMENTS',
	PERSONAL: "/resume/personal-profile",
	LINKS: "/resume/personal-links",
	// CUSTOM: 'CUSTOM',
	ACCOMPLISHMENTS: "/resume/accomplishments",
	default: "#",
};
