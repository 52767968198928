import { Address } from "./address";
export class Reference {
	constructor(refInfo) {
		this.name = refInfo.name;
		this.email = refInfo.email;
		this.workPlace = refInfo.workPlace;
		this.designation = refInfo.designation;
		this.phoneNumber = refInfo.phoneNumber;
		this.workplaceLocation = new Address(refInfo.city, refInfo.region, refInfo.country);
	}

	getReferenceRequest() {
		return {
			fullName: this.name,
			emailAddress: this.email,
			workPlace: this.workPlace,
			phoneNumber: this.phoneNumber,
			designation: this.designation,
			workplaceLocation: this.workplaceLocation,
		};
	}
}
