import React, { useState } from "react";

const SmartApplyConfirmationModal = ({ submitApplication, title, fitScore }) => {
	const [hasNotApproved, setHasNotApproved] = useState(true);

	const submitJobApplication = () => {
		return submitApplication();
	};

	return (
		<>
			<div
				className="modal fade"
				id="confirmSmartApplyModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="confirmSmartApplyModalTitle"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title" id="confirmSmartApplyModal">
								Job Smart Apply!
							</h3>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{fitScore > 50 ? (
								<div className="p-3">
									<h6 className="mb-4">
										You are about to apply for the
										<span className="font-weight-bold pl-1">{title}</span> job using your primary resume per your job
										preference.
									</h6>

									<div className="border-top mt-4 py-3">
										<div className="custom-control custom-checkbox mr-5">
											<input
												type="checkbox"
												className="custom-control-input"
												id="awareOfIrelevance"
												name="awareOfIrelevance"
												onChange={() => setHasNotApproved(!hasNotApproved)}
											/>
											<label className="custom-control-label" htmlFor="awareOfIrelevance">
												By checking here, you understand that your application details will be submitted to the employer
												and you can not change your application after that.
											</label>
										</div>
									</div>
								</div>
							) : (
								<div className="p-3">
									<p className="mb-4">
										Chances of getting shortlisted with the fit_score of less than 50% are slim to none. To increase the
										likelihood of getting shortlisted, we recommend focusing on jobs where fit_score is at least 60%.
									</p>

									<div className="border-top mt-4 py-3">
										<div className="custom-control custom-checkbox mr-5">
											<input
												type="checkbox"
												className="custom-control-input"
												id="awareOfIrelevance"
												name="awareOfIrelevance"
												onChange={() => setHasNotApproved(!hasNotApproved)}
											/>
											<label className="custom-control-label" htmlFor="awareOfIrelevance">
												By checking here, I understand that the fit_score is less than 50% with a slimmer likelihood of
												getting shortlisted but i choose to proceed anyway!
											</label>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal">
								Cancel
							</button>
							<button
								type="button"
								className="btn btn-primary"
								disabled={hasNotApproved}
								onClick={submitJobApplication}
								data-dismiss="modal"
							>
								Submit
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SmartApplyConfirmationModal;
