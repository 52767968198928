const contactInformation = {
	contact: {
		name: {
			label: "Name",
			content: [
				{
					bp: false,
					value:
						"Name and contact information will be the first thing a recruiter sees on your resume, so it's important to organize this information in a clean manner to make a positive first impression. Include the following in this section",
				},
				{ bp: true, value: "Should match the name on your LinkedIn profile" },
				{ bp: true, value: "Generally best to use your full birth name as opposed to nicknames" },
				{ bp: true, value: "It's your choice whether or not to include your middle name or middle initial." },
			],
		},
	},
	email: {
		label: "Email Address",
		content: [
			{ bp: true, value: "Keep it professional, make sure it's from a modern provider such as Gmail" },
			{ bp: true, value: "Make sure not to tie it to the email address of your current employer" },
			{
				bp: true,
				value:
					"76% of resumes are discarded for having unprofessional email address, So keep born2Bw1ld@ or QTpie89 between friends only.",
			},
		],
	},
	address: {
		label: "Address",
		content: [
			{
				bp: true,
				value:
					"Standard to include City/Town, Region(State), and Country of the address you'll be spending majority of your time during the interview process",
			},
			{ bp: true, value: "Don't put more than one address on the resume" },
		],
	},
};

export default contactInformation;
