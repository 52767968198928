export class Address {
	constructor(city, region, country) {
		//zipcode will contructed to contain country, city and zipcode number to reduce number of parameterse in one function
		this.city = city;
		this.region = region;
		this.country = country;
	}
}

export class ZipCode {
	constructor(country, city, zipcode) {
		this.country = country;
		this.city = city;
		this.zipcode = zipcode;
	}
}
