import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { sections } from "../../../handlers/enums";
import {
	convertArrayToUl,
	convertDateToWords,
	convertUlToArray,
	getSectionName,
	isNotEmptyCustomReasonsOrRecommendations,
	isNotEmptyValue,
} from "../../../handlers/utilityFunctions";
import { sectionName } from "../../../handlers/valueTransformation";
import options from "../../../seed/ratingOptions";
import Spinner from "../../Spinner";
import TextEditor from "../../TextEditor";

const STRONG = "STRONG";
const MODERATE = "MODERATE";
const WEAK = "WEAK";

function FeedBackModal({ resumeData, details, onSubmitFeedBack, loadingSubmission }) {
	const useReviewFeedback = useSelector((state) => state.UseReviewFeedbackReducer);
	const dispatch = useDispatch();
	const [rating, setRating] = useState("");
	const [ratingReasons, setRatingReasons] = useState([]);
	const [customRatingReasons, setCustomRatingReasons] = useState([]);
	const [ratingRecommendations, setRatingRecommendations] = useState([]);
	const [customRatingRecommendations, setCustomRatingRecommendations] = useState([]);
	const [selectedReasons, setSelectedReasons] = useState([]);
	const [selectedRecommendations, setSelectedRecommendations] = useState([]);
	const [isMoreExpanded, setIsMoreExpanded] = useState(false);
	const [isLessExpanded, setIsLessExpanded] = useState(true);
	const lessContentRef = useRef(null);
	const moreContentRef = useRef(null);

	const toggleExpansion = () => {
		setIsMoreExpanded((prev) => !prev);
		setIsLessExpanded((prev) => !prev);

		// Get the actual content height and set max-height accordingly
		const lessContentElement = lessContentRef.current;
		const moreContentElement = moreContentRef.current;

		if (lessContentElement && moreContentElement) {
			const lessHeight = isLessExpanded ? 0 : lessContentElement.scrollHeight + "px";
			const moreHeight = isMoreExpanded ? 0 : moreContentElement.scrollHeight + "px";
			lessContentElement.style.maxHeight = lessHeight;
			moreContentElement.style.maxHeight = moreHeight;
		}
	};

	const onOptionChange = (value) => setRating(value);
	// const onSelectedValueDecorator = (selectedValue) => {
	// 	const trimmedText = selectedValue.length < 10 ? selectedValue : selectedValue.substring(0, 10);
	// 	return trimmedText.concat("...");
	// };
	const onSelectReason = (selectedItem) => setSelectedReasons([...selectedReasons, { key: selectedItem }]);
	const onRemoveReason = (selectedItem) =>
		setSelectedReasons(selectedReasons.filter((item) => item.key !== selectedItem));

	const onRemoveRecommendation = (selectedItem) =>
		setSelectedRecommendations(selectedRecommendations.filter((item) => item.key !== selectedItem));
	const onSelectRecommendation = (selectedItem) =>
		setSelectedRecommendations([...selectedRecommendations, { key: selectedItem }]);

	const handleSubmit = () => {
		if (
			!(isNotEmptyValue(selectedReasons) || isNotEmptyCustomReasonsOrRecommendations(customRatingReasons)) ||
			(rating !== STRONG &&
				!(
					isNotEmptyValue(selectedRecommendations) ||
					isNotEmptyCustomReasonsOrRecommendations(customRatingRecommendations)
				))
		)
			return;
		const reasons = selectedReasons.map((selectedReason) => {
			return selectedReason.key;
		});

		const customReasons = convertUlToArray(customRatingReasons).map((reason) => {
			return reason.value;
		});

		const recommendations = selectedRecommendations.map((selectedRecommendation) => {
			return selectedRecommendation.key;
		});

		const customRecommendations = convertUlToArray(customRatingRecommendations).map((recommendation) => {
			return recommendation.value;
		});

		const results = {
			section: details.section,
			rating: rating,
			reasonsForRating: reasons.concat(customReasons),
			recommendations: recommendations.concat(customRecommendations),
		};

		onSubmitFeedBack(results);
	};

	useEffect(() => {
		const selectedOptions = options(details.section, rating);
		setRatingReasons(selectedOptions.reasons);
		setRatingRecommendations(selectedOptions.recommendations);
	}, [rating]);

	useEffect(() => {
		const selectedOptions = options(details.section, details.rating);

		setRating(details.rating);

		setSelectedReasons(() => {
			return details.reasonsForRating
				.map((reason) => {
					const selected = selectedOptions.reasons.find((item) => item.key === reason);
					if (selected !== undefined) return { key: reason };
					return null;
				})
				.filter(Boolean);
		});

		setCustomRatingReasons(() => {
			const newRatingReasons = details.reasonsForRating
				.map((reason) => {
					const selected = selectedOptions.reasons.find((item) => item.key === reason);
					if (selected === undefined) return { value: reason };
					return null;
				})
				.filter(Boolean);
			if (newRatingReasons.length > 0) {
				return convertArrayToUl(newRatingReasons);
			}
			return [];
		});

		setSelectedRecommendations(() => {
			return details.recommendations
				.map((recommendation) => {
					const selected = selectedOptions.recommendations.find((item) => item.key === recommendation);
					if (selected !== undefined) return { key: recommendation };
					return null;
				})
				.filter(Boolean);
		});

		setCustomRatingRecommendations(() => {
			const newRatingRecommendations = details.recommendations
				.map((recommendation) => {
					const selected = selectedOptions.recommendations.find((item) => item.key === recommendation);
					if (selected === undefined) return { value: recommendation };
					return null;
				})
				.filter(Boolean);
			if (newRatingRecommendations.length > 0) {
				return convertArrayToUl(newRatingRecommendations);
			}
			return [];
		});
	}, [details]);

	return (
		<>
			<div
				className="modal fade"
				id="reviewFeedbackModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="reviewFeedbacks"
				aria-hidden="true"
				data-backdrop="static"
				data-keyboard="false"
			>
				<div className="modal-dialog modal-dialog-centered resume-feedback-modal" role="document">
					<div className="modal-content" style={{ padding: "20px 20px 24px" }}>
						<div className="resume-feedback-modal-container">
							<div className="resume-feedback-modal-left-content">
								<div
									ref={lessContentRef}
									className={`${isLessExpanded ? "expanded-resume-section" : ""} feedback-content resume-feedback-modal-left-content-text`}
								>
									{details.section === sections.EXPERIENCE ? (
										<>
											{[...resumeData.workHistory].length > 0 && (
												<div className="d-flex justify-content-between">
													<span>
														<strong>{resumeData.workHistory[0].employer}</strong> |{" "}
														<em>{resumeData.workHistory[0].jobTitle}</em> | {resumeData.workHistory[0].city},{" "}
														{resumeData.workHistory[0].region}, {resumeData.workHistory[0].country}
													</span>
													<span className="font-weight-bold">
														{convertDateToWords(resumeData.workHistory[0].startDate)} -{" "}
														{resumeData.workHistory[0].endDate !== ""
															? convertDateToWords(resumeData.workHistory[0].endDate)
															: "Present"}
													</span>
												</div>
											)}
										</>
									) : details.section === sections.EDUCATION ? (
										<>
											{[...resumeData.education].length > 0 && (
												<div className="d-flex justify-content-between">
													<p>
														<strong>{resumeData.education[0].schoolName}</strong>
														{" - "}
														{resumeData.education[0].schoolCity}, <em>{resumeData.education[0].fieldOfStudy}</em>
													</p>
													<p className="mr-4">
														<strong>{resumeData.education[0].gYear}</strong>
													</p>
												</div>
											)}
										</>
									) : details.section === sections.LANGUAGES ? (
										<>
											{resumeData.extras.languages.length > 0 && (
												<li>
													<span className="mr-2">{resumeData.extras.languages[0].language}</span>
													{" - "}
													<span className="mr-2">{resumeData.extras.languages[0].proficiency}</span>
												</li>
											)}
										</>
									) : details.section === sections.PERSONAL ? (
										<>
											<div>
												<strong>Date of Birth:</strong> {resumeData.extras.personalProfile.dateOfBirth}
											</div>
										</>
									) : details.section === "LINKS" ? (
										<>
											{[...resumeData.extras.personalLinks].length > 0 && (
												<li>
													<span className="text-capitalize">{resumeData.extras.personalLinks[0].type}</span>:{" "}
													{resumeData.extras.personalLinks[0].value}
												</li>
											)}
										</>
									) : details.section === sections.REFERENCE ? (
										<>
											{[...resumeData.extras.references].length > 0 && (
												<li className="">
													<span>
														{resumeData.extras.references[0].name}
														{" - "}
														{resumeData.extras.references[0].designation}, {resumeData.extras.references[0].workPlace}
													</span>
													<br />
													<span>
														{resumeData.extras.references[0].email}, {resumeData.extras.references[0].phoneNumber}
													</span>
												</li>
											)}
										</>
									) : (
										<span
											dangerouslySetInnerHTML={{
												__html: getSectionName(resumeData, details.section).substring(0, 83),
											}}
										/>
									)}
									<span>...</span>
									<span className="text-primary font-weight-semi cursor-pointer" onClick={() => toggleExpansion()}>
										more
									</span>
								</div>
								<div
									ref={moreContentRef}
									className={`${isMoreExpanded ? "expanded-resume-section" : ""} feedback-content resume-feedback-modal-left-content-text`}
								>
									{details.section === sections.EXPERIENCE ? (
										<>
											{[...resumeData.workHistory].map((job, index) => (
												<span key={index}>
													<div className="d-flex justify-content-between">
														<span>
															<strong>{job.employer}</strong> | <em>{job.jobTitle}</em> | {job.city}, {job.region},{" "}
															{job.country}
														</span>
														<span className="font-weight-bold">
															{convertDateToWords(job.startDate)} -{" "}
															{job.endDate !== "" ? convertDateToWords(job.endDate) : "Present"}
														</span>
													</div>
													<div
														dangerouslySetInnerHTML={{
															__html: job.details,
														}}
													/>
												</span>
											))}
										</>
									) : details.section === sections.EDUCATION ? (
										<>
											{[...resumeData.education].map((educationHistory, index) => (
												<div key={index}>
													<div className="d-flex justify-content-between">
														<p>
															<strong>{educationHistory.schoolName}</strong>
															{" - "}
															{educationHistory.schoolCity}, <em>{educationHistory.fieldOfStudy}</em>
														</p>
														<p className="mr-4">
															<strong>{educationHistory.gYear}</strong>
														</p>
													</div>
													<div
														className="pl-3"
														dangerouslySetInnerHTML={{
															__html: educationHistory.description,
														}}
													/>
												</div>
											))}
										</>
									) : details.section === sections.LANGUAGES ? (
										<>
											<ul className="pl-3">
												{resumeData.extras.languages.map((language, index) => (
													<li key={index}>
														<span className="mr-2">{language.language}</span>
														{" - "}
														<span className="mr-2">{language.proficiency}</span>
													</li>
												))}
											</ul>
										</>
									) : details.section === sections.PERSONAL ? (
										<>
											<div>
												<strong>Date of Birth:</strong> {resumeData.extras.personalProfile.dateOfBirth}
											</div>
											<div>
												<strong>Gender:</strong> {resumeData.extras.personalProfile.gender}
											</div>
											<div>
												<strong>Marital Status:</strong> {resumeData.extras.personalProfile.maritalStatus}
											</div>
										</>
									) : details.section === sections.LINKS ? (
										<>
											{[...resumeData.extras.personalLinks].map((link, index) => (
												<li key={index}>
													<span className="text-capitalize">{link.type}</span>: {link.value}
												</li>
											))}
										</>
									) : details.section === sections.REFERENCE ? (
										<>
											{[...resumeData.extras.references].map((reference, index) => (
												<li className="" key={index}>
													<span>
														{reference.name}
														{" - "}
														{reference.designation}, {reference.workPlace}
													</span>
													<br />
													<span>
														{reference.email}, {reference.phoneNumber}
													</span>
												</li>
											))}
										</>
									) : (
										<span
											dangerouslySetInnerHTML={{
												__html: getSectionName(resumeData, details.section),
											}}
										/>
									)}
									<br />
									<span className="text-primary font-weight-semi cursor-pointer" onClick={() => toggleExpansion()}>
										less
									</span>
								</div>
							</div>
							<div className="resume-feedback-modal-right-content">
								<div className="d-flex align-items-center">
									<div className="resume-feedback-modal-title">Provide Feedback</div>
									<div
										className="cursor-pointer close ml-auto"
										data-dismiss="modal"
										onClick={() => {
											dispatch({
												type: "USE_REVIEW_FEEDBACK",
												payload: {
													...useReviewFeedback,
													showFeedBackModal: false,
												},
											});
										}}
									>
										<i className="fa fa-close" />
									</div>
								</div>
								<div className="resume-feedback-modal-sub-title">
									How would you rate {resumeData.contactInfo.firstName}’s {sectionName(details.section)}?
								</div>
								<div
									className="mt-3"
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "flex-start",
										padding: 0,
										gap: "10px",
										flexWrap: "wrap",
										maxWidth: 500,
										flex: 1,
									}}
								>
									<div
										className="resume-feedback-rate-pills"
										style={{ background: rating === STRONG || rating === "" ? "#dafee9" : "#EDEDED" }}
										onClick={() => onOptionChange(STRONG)}
									>
										<div className="resume-feedback-rate-pills-text">Strong</div>
									</div>
									<div
										className="resume-feedback-rate-pills"
										style={{ background: rating === MODERATE || rating === "" ? "#FAE38D" : "#EDEDED" }}
										onClick={() => onOptionChange(MODERATE)}
									>
										<div className="resume-feedback-rate-pills-text">Moderate</div>
									</div>
									<div
										className="resume-feedback-rate-pills"
										style={{ background: rating === WEAK || rating === "" ? "#FFCACF" : "#EDEDED" }}
										onClick={() => onOptionChange(WEAK)}
									>
										<div className="resume-feedback-rate-pills-text">Weak</div>
									</div>
								</div>
								{rating !== "" && (
									<>
										<div className="resume-feedback-modal-sub-title mt-4">
											Select reasons that make {resumeData.contactInfo.firstName}’s {sectionName(details.section)}{" "}
											{rating.toLowerCase()}.
										</div>
										<div className="mt-2">
											{ratingReasons.map((item) => {
												const selected = selectedReasons.find((reason) => reason.key === item.key);
												return (
													<div key={item.key} className="d-flex align-items-center">
														{selected === undefined && (
															<img
																style={{ cursor: "pointer" }}
																src="/images/checkbox-unchecked.svg"
																alt="Checkbox"
																onClick={() => onSelectReason(item.key)}
															/>
														)}
														{selected !== undefined && (
															<img
																style={{ cursor: "pointer" }}
																src="/images/checkbox-checked.svg"
																alt="Checkbox"
																onClick={() => onRemoveReason(item.key)}
															/>
														)}
														<div className="resume-feedback-modal-left-content-text mb-1 ml-3">{item.key}</div>
													</div>
												);
											})}
										</div>
										<div className="editor-wrapper mt-2" style={{ maxWidth: "533px" }}>
											<label className="add-custom-skills-category-button-text font-weight-bold">
												Add more details(Optional)
											</label>
											<TextEditor
												id="ratingReasons"
												value={customRatingReasons}
												setValue={setCustomRatingReasons}
												placeholder="Add more details..."
												className="custom-quill"
											/>
										</div>
									</>
								)}
								{(isNotEmptyValue(selectedReasons) ||
									isNotEmptyCustomReasonsOrRecommendations(customRatingReasons)) && (
									<>
										<div className="resume-feedback-modal-sub-title mt-4">
											Suggest ways to improve {resumeData.contactInfo.firstName}’s {sectionName(details.section)}.
										</div>
										<div className="mt-2">
											{ratingRecommendations.map((item) => {
												const selected = selectedRecommendations.find((reason) => reason.key === item.key);
												return (
													<div key={item.key} className="d-flex align-items-center">
														{selected === undefined && (
															<img
																style={{ cursor: "pointer" }}
																src="/images/checkbox-unchecked.svg"
																alt="Checkbox"
																onClick={() => onSelectRecommendation(item.key)}
															/>
														)}
														{selected !== undefined && (
															<img
																style={{ cursor: "pointer" }}
																src="/images/checkbox-checked.svg"
																alt="Checkbox"
																onClick={() => onRemoveRecommendation(item.key)}
															/>
														)}
														<div className="resume-feedback-modal-left-content-text mb-1 ml-3">{item.key}</div>
													</div>
												);
											})}
										</div>
										<div className="editor-wrapper mt-2" style={{ maxWidth: "533px" }}>
											<label className="add-custom-skills-category-button-text font-weight-bold">
												Add more details(Optional)
											</label>
											<TextEditor
												id="ratingRecommendations"
												value={customRatingRecommendations}
												setValue={setCustomRatingRecommendations}
												placeholder="Add more details..."
												className="custom-quill"
											/>
										</div>
									</>
								)}
								{rating !== "" && (
									<div
										style={{
											...((!(
												isNotEmptyValue(selectedReasons) ||
												isNotEmptyCustomReasonsOrRecommendations(customRatingReasons)
											) ||
												(rating !== STRONG &&
													!(
														isNotEmptyValue(selectedRecommendations) ||
														isNotEmptyCustomReasonsOrRecommendations(customRatingRecommendations)
													))) && {
												background: "#EDEDED",
												cursor: "not-allowed",
											}),
										}}
										className="save-review-button mt-4"
										onClick={handleSubmit}
									>
										{loadingSubmission ? (
											<>
												<div className="resume-review-message-title-text" style={{ color: "#ffffff" }}>
													Save review
												</div>
												<Spinner />
											</>
										) : (
											<div
												className="resume-review-message-title-text"
												style={{
													color:
														!(
															isNotEmptyValue(selectedReasons) ||
															isNotEmptyCustomReasonsOrRecommendations(customRatingReasons)
														) ||
														(rating !== STRONG &&
															!(
																isNotEmptyValue(selectedRecommendations) ||
																isNotEmptyCustomReasonsOrRecommendations(customRatingRecommendations)
															))
															? "#a5a8ad"
															: "#ffffff",
												}}
											>
												Save review
											</div>
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default FeedBackModal;
