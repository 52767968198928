import React from "react";

function TemplatePremiumFour() {
	return (
		<div style={{ scale: "1.2", transformOrigin: "center" }}>
			<div className="small-resume-preview small-resume-preview-premium-template-4">
				<div className="final-resume-preview bg-white text-dark">
					<div className="py-2">
						<div className="container">
							<div className="d-flex justify-content-between flex-wrap align-items-start">
								<div className="info-wrapper" style={{ flex: "0 0 50%" }}>
									<div className="d-flex">
										<div>
											<h1 className="mb-0">JACQUILINE KHALFAN</h1>
											<h3 style={{ color: "#174777", fontWeight: "normal" }}>Business Development Manager</h3>
										</div>
									</div>
								</div>
								<div>
									<p className="mb-1 text-right">
										jacquiline.khalfan@dproz.tz
										<i className="ml-1 fa fa-envelope" aria-hidden="true" style={{ color: "#174777" }} />
									</p>
									<p className="mb-1 text-right">
										202-555-0166
										<i className="ml-1 fa fa-mobile" style={{ color: "#174777" }} aria-hidden="true" />
									</p>
									<p className="mb-1 text-right">
										New York, USA
										<i className="ml-1 fa fa-map-marker" style={{ color: "#174777" }} aria-hidden="true" />
									</p>
									<p className="mb-1 text-right">
										linkedin.com/in/jacquiline.khalfan
										<i className="ml-1 fa fa-linkedin" style={{ color: "#174777" }} aria-hidden="true" />
									</p>
									<p className="mb-1 text-right">
										jacquiline.khalfan
										<i className="ml-1 fa fa-skype" style={{ color: "#174777" }} aria-hidden="true" />
									</p>
								</div>
								<p className="mb-1" style={{ flexGrow: 1 }}>
									Professional Business Developer with four year of experience in the business Development processes.
									Involved in product testing, management and development of new business opportunities.
								</p>
							</div>
						</div>
					</div>

					<div className="mb-2">
						<div className="container">
							<h2 style={{ borderBottom: "1px solid #174777" }} className="mb-1">
								SKILLS
							</h2>
							<div className="d-flex align-items-center flex-wrap">
								<span
									style={{
										display: "inline-block",
										backgroundColor: "#174777",
										color: "#ffffff",
										padding: "2px 5px",
										borderRadius: "5px",
										margin: "2px 0",
										marginRight: "5px",
									}}
								>
									SEO
								</span>

								<span
									style={{
										display: "inline-block",
										backgroundColor: "#174777",
										color: "#ffffff",
										padding: "2px 5px",
										borderRadius: "5px",
										margin: "2px 0",
										marginRight: "5px",
									}}
								>
									Research and Strategy
								</span>
								<span
									style={{
										display: "inline-block",
										backgroundColor: "#174777",
										color: "#ffffff",
										padding: "2px 5px",
										borderRadius: "5px",
										margin: "2px 0",
										marginRight: "5px",
									}}
								>
									Emotional Intelligence
								</span>

								<span
									style={{
										display: "inline-block",
										backgroundColor: "#174777",
										color: "#ffffff",
										padding: "2px 5px",
										borderRadius: "5px",
										margin: "2px 0",
										marginRight: "5px",
									}}
								>
									Outbound Marketing
								</span>

								<span
									style={{
										display: "inline-block",
										backgroundColor: "#174777",
										color: "#ffffff",
										padding: "2px 5px",
										borderRadius: "5px",
										margin: "2px 0",
										marginRight: "5px",
									}}
								>
									Email Marketing
								</span>

								<span
									style={{
										display: "inline-block",
										backgroundColor: "#174777",
										color: "#ffffff",
										padding: "2px 5px",
										borderRadius: "5px",
										margin: "2px 0",
										marginRight: "5px",
									}}
								>
									Google Analytics
								</span>

								<span
									style={{
										display: "inline-block",
										backgroundColor: "#174777",
										color: "#ffffff",
										padding: "2px 5px",
										borderRadius: "5px",
										margin: "2px 0",
										marginRight: "5px",
									}}
								>
									Sales &amp; Marketing
								</span>

								<span
									style={{
										display: "inline-block",
										backgroundColor: "#174777",
										color: "#ffffff",
										padding: "2px 5px",
										borderRadius: "5px",
										margin: "2px 0",
										marginRight: "5px",
									}}
								>
									Social Media Advertising
								</span>
							</div>
						</div>
					</div>
					<div className="mb-2">
						<div className="container">
							<h2 style={{ borderBottom: "1px solid #174777" }} className="mb-1">
								WORK EXPERIENCE
							</h2>
							<div className="d-flex mb-2">
								<div
									style={{
										flex: "0 0 15px",
										height: "15px",
										color: "#174777",
										marginTop: "2px",
									}}
								>
									<span className="fa fa-circle-o" />
								</div>
								<div style={{ flexGrow: 1 }}>
									<h4>Business Development Manager</h4>
									<h5>AirState Solutions</h5>
									<p style={{ color: "#174777" }} className="d-flex align-items-center justify-content-between">
										<em className="mr-2">09/2014 - 06/2017 </em>
										<em>New York, USA </em>
									</p>
									<div className="d-flex">
										<span
											style={{
												flex: "0 0 5px",
												border: "1px solid #174777",
												borderRight: 0,
											}}
										/>
										<div style={{ flexGrow: 1 }}>
											<div className="d-flex" style={{ marginLeft: "-5px", marginBottom: "0px" }}>
												<span style={{ color: "#174777", marginRight: "10px" }}>-</span>
												<span>
													Successfully managed $2 - 3 million budget projects and successfully achieved the project
													scheduled goals.
												</span>
											</div>
										</div>
										<span
											style={{
												flex: "0 0 5px",
												border: "1px solid #174777",
												borderLeft: 0,
											}}
										/>
									</div>
								</div>
							</div>
							<div className="d-flex mb-2">
								<div
									style={{
										flex: "0 0 15px",
										height: "15px",
										color: "#174777",
										marginTop: "2px",
									}}
								>
									<span className="fa fa-circle-o" />
								</div>
								<div style={{ flexGrow: 1 }}>
									<h4>Business Development Assistant</h4>
									<h5>AirState Solutions</h5>
									<p style={{ color: "#174777" }} className="d-flex align-items-center justify-content-between">
										<em className="mr-2">08/2012 - 09/2014 </em>
										<em>Chicago, USA </em>
									</p>
									<div className="d-flex">
										<span
											style={{
												flex: "0 0 5px",
												border: "1px solid #174777",
												borderRight: 0,
											}}
										/>
										<div style={{ flexGrow: 1 }}>
											<div className="d-flex" style={{ marginLeft: "-5px", marginBottom: "0px" }}>
												<span style={{ color: "#174777", marginRight: "10px" }}>-</span>
												<span>
													Improved the communication with the Marketing department to better understand the competitive
													position.
												</span>
											</div>
										</div>
										<span
											style={{
												flex: "0 0 5px",
												border: "1px solid #174777",
												borderLeft: 0,
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="mb-2">
						<div className="container">
							<h2 style={{ borderBottom: "1px solid #174777" }} className="mb-2">
								EDUCATION
							</h2>
							<div className="d-flex mb-1">
								<div
									style={{
										flex: "0 0 25px",
										height: "25px",
										color: "#174777",
										marginTop: "2px",
									}}
								>
									<span className="fa fa-circle-o" />
								</div>
								<div style={{ flexGrow: 1 }}>
									<h4>MSc in Economics and Business Administration</h4>
									<h5>The University of Chicago</h5>
									<p style={{ color: "#174777" }} className="d-flex align-items-center justify-content-between">
										<em className="mr-2">09/2008 - 06/2010 </em>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="mb-2">
						<div className="container">
							<h2 style={{ borderBottom: "1px solid #174777" }} className="mb-1">
								ORGANIZATIONS
							</h2>
							<div className="d-flex mb-1 flex-wrap">
								<div style={{ flex: "0 0 50%" }}>
									<strong>American Management Association (2015 - Present)</strong>
								</div>

								<div style={{ flex: "0 0 50%" }}>
									<strong>Association of Private Enterprise Education (2014 - Present)</strong>
								</div>

								<div style={{ flex: "0 0 50" }}>
									<strong>eBusiness Association (eBA) (2013 - Present)</strong>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TemplatePremiumFour;
