import React from "react";

import Resume from "../Review/Resume";

function ResumePreviewModal({ resumeData }) {
	return (
		<div className="modal fade" id="ResumePreview" tabIndex="-1" role="dialog" aria-hidden="true">
			<div className="modal-dialog" role="document">
				<div className="modal-content border-0">
					<div className="modal-body pb-5 resume-preview relative rounded-md">
						<button
							type="button"
							className="close absolute top-4 right-4 text-dark z-100"
							data-dismiss="modal"
							aria-label="Close"
						>
							<i className="fas fa-times" />
						</button>
						<Resume resumeData={resumeData} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default ResumePreviewModal;
