export default function (msgName, param) {
	let msg;

	switch (msgName) {
		case "UP_SALE":
			msg = `Get Score fit and see your rating compared to the requirements and specifications of the
                ${param.title} at ${param.company}. Tired of scanning the jobs from the search results?
                 Become a DProz subscriber and have access to Job recommendations and score
                 fit that matches your preferences and score fit! Subscribe to Dproz today!`;
			break;

		case "EXHAUSTED_TRAILS":
			msg = `You exhausted your job score free trial on ${param}. Subscribe below to
                                          access job recommendations and their score fit as per your job preferences!`;
			break;

		case "MISSING_JOB_PREFERENCE":
			msg = "You are missing a Job-Preference resume i.e. Primary resume. Complete Job Preferences to Continue";
			break;
	}

	return msg;
}
