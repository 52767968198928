import React from "react";

import { convertArrayToUl } from "../../../handlers/utilityFunctions";
import { convertDateObjectToString } from "../../../handlers/valueTransformation";

function Preview({ job }) {
	return (
		<div className="col-md-12 col-lg-10">
			<div className="job-summary">
				<div className="p-3 lh-1">
					<div className="job-summary-top">
						<h3 className="job-title">{job.jobTitle}</h3>
						<div className="job-company-location">
							<p className="company-name">
								<span className="bi bi-building fa" />
								<span>{job.company.companyName}</span>
							</p>
							<p className="company-location">
								<span className="fa fa-map-marker" />
								<span>
									{job.jobStreetAddress} {job.jobLocation.city}, {job.jobLocation.region}
								</span>
							</p>
						</div>
					</div>
					<div className="job-quick-info">
						<div className="job-quick-info-row">
							<div className="job-quick-info-col">Salary Information</div>
							<div className="job-quick-info-col">
								<strong>
									{`${job.salary.payCurrency} ${job.salary.minPay}`} to{" "}
									{`${job.salary.payCurrency} ${job.salary.maxPay}`} {job.salary.payFrequency}
								</strong>
							</div>
						</div>
						<div className="job-quick-info-row">
							<div className="job-quick-info-col">Work Experience</div>
							<div className="job-quick-info-col">
								<strong>{job.experienceQualification.minimumLevelOfExperience}</strong>
							</div>
						</div>
						<div className="job-quick-info-row">
							<div className="job-quick-info-col">Job Type</div>
							<div className="job-quick-info-col">
								<strong>{job.employmentType}</strong>
							</div>
						</div>
						<div className="job-quick-info-row">
							<div className="job-quick-info-col">No. of Openings</div>
							<div className="job-quick-info-col">
								<strong>{job.numberOfOpening}</strong>
							</div>
						</div>
						<div className="job-quick-info-row">
							<div className="job-quick-info-col">Last Date to Apply</div>
							<div className="job-quick-info-col">
								<strong>{convertDateObjectToString(job.deadlineDate)}</strong>
							</div>
						</div>
						<div className="job-quick-info-row">
							<div className="job-quick-info-col">Contact Information</div>
							<div className="job-quick-info-col">
								<strong>{job.company.phoneNumber}</strong>
							</div>
						</div>
					</div>
					<div className="job-complete-description">
						<h5 className="mt-3">Job Description and Responibilities</h5>
						<p
							dangerouslySetInnerHTML={{
								__html: job.jobDescription.lookingToHire,
							}}
						/>
						<h5 className="mb-0">Required Skills</h5>
						<span
							dangerouslySetInnerHTML={{
								__html: convertArrayToUl(job.jobDescription.responsibilities),
							}}
						/>
						<h5 className="mb-0">Qualifications</h5>
						<span
							dangerouslySetInnerHTML={{
								__html: convertArrayToUl(job.jobDescription.qualifications),
							}}
						/>
						<h5 className="mb-0">Additional Information</h5>
						<p>{job.additionalInformation}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Preview;
