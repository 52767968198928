import React from "react";

function Input({ label, type, placeholder, value, name, onChange, error, disabled = false }) {
	return (
		<div className="group w-full">
			<label htmlFor={label.split(" ").join("-")} className="block text-sm font-semibold text-gray-400">
				{label}
			</label>
			<input
				type={type}
				name={name}
				id={label.split(" ").join("-")}
				className="mt-1 block w-full rounded px-2 py-1 border border-gray-100 bg-transparent text-base placeholder:text-base placeholder:text-gray-300 hover:border-gray-300 focus:border-gray-300 focus:bg-transparent focus:ring-0 disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400 disabled:placeholder:text-gray-400 sm:text-sm"
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				disabled={disabled}
			/>
			{error && <p className="mt-1 text-base text-error-default">{error}</p>}
		</div>
	);
}

export default Input;
