import React from "react";
import { Link, useLocation } from "react-router-dom";

function ViewPdf() {
	const locationState = useLocation().state;
	const pdfUrl = locationState && locationState.pdfUrl;
	const pdfTitle = locationState && locationState.pdfTitle;
	const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
	const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

	React.useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
			setScreenHeight(window.innerHeight);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [screenWidth, screenHeight]);

	return (
		<>
			<header className="tr-header">
				<nav className="navbar navbar-expand-lg">
					<div className="navbar-header">
						<Link className="navbar-brand" to="/">
							<img className="img-fluid" src="/images/logo.png" alt="Logo" />
						</Link>
					</div>
					{screenWidth < 768 && (
						<div className="mark-review-complete-button ml-auto mr-2 mr-md-5 cursor-pointer">
							<div className="mark-review-complete-text-button">
								<a href={pdfUrl} download>
									Click to download
								</a>
							</div>
						</div>
					)}
				</nav>
			</header>
			<div className="font-inter pt-5" style={{ backgroundColor: "#F5F5F5", minHeight: "100vh" }}>
				<div className="row justify-content-center">
					<div className="col-md-7 pt-5">
						<div className="bg-white fresher-resume text-dark">
							<iframe src={pdfUrl} title={pdfTitle} width="100%" height={screenHeight - 50} frameBorder="0" />
						</div>
					</div>
					{screenWidth > 768 && (
						<div className="col-md-3 pt-5">
							<div className="resume-reviewer-message-wrapper">
								<div>
									{navigator.userAgent.toLowerCase().includes("chrome") ? (
										<>
											<div className="resume-review-message-title-text">
												How to download your resume using Chrome browser on a computer
											</div>
											<div className="resume-review-message-body-text">
												Downloading your resume using Chrome browser on a computer is simple. Follow these steps:
											</div>
											<ul className="mb-3">
												<li>Click the dropdown icon on the right handside on the toolbar</li>
												<li>Save dialog will be visible where you can change the filename and location</li>
												<li>Click save button to save your resume</li>
											</ul>
										</>
									) : (
										<>
											<div className="resume-review-message-title-text">
												How to download your resume using Safari browser on a computer
											</div>
											<div className="resume-review-message-body-text">
												Downloading your resume using Safari browser on a computer is straight forward. Follow these
												steps:
											</div>
											<ul>
												<li>Move your cursor to the bottom center on your resume where toolbar will be visible</li>
												<li>Click the dropdown icon on the right handside on the toolbar</li>
												<li>Your resume will be saved on your downloads folder</li>
											</ul>
										</>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default ViewPdf;
