import React from "react";

function Icon({ className = "", iconName, size = 24, fill = "" }) {
	return (
		<svg className={" " + className} width={size} height={size} fill={fill} viewBox={`0 0 ${size} ${size}`}>
			<use xlinkHref={`/images/icon.svg#${iconName}`} width={size} height={size} />
		</svg>
	);
}

export default Icon;
