import React from "react";

import OrderConsultationCard from "../../components/ExpertResumes/OrderConsultationCard";
import StatusProgressBar from "../../components/ExpertResumes/StatusProgressBar";
import ExpertResumesLayout from "../../components/ExpertResumesLayout/ExpertResumesLayout";
import { expertRoutes } from "../ExpertOrdersManagement/ExpertOrdersManagement";

export const resumeSteps = [
	{ title: "Consultation", subTitle: "Not Started" },
	{ title: "Resume Draft", subTitle: "Not Started" },
	{ title: "Revisions", subTitle: "Not Started" },
	{ title: "Resume Delivery", subTitle: "Due July 05, 2024" },
];

function ExpertOrderDetails() {
	const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

	React.useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [screenWidth]);

	return (
		<ExpertResumesLayout navItems={expertRoutes} headingTitle="Resume Order" headingIcon="arrow-left">
			<div
				className="d-flex flex-column align-items-start"
				style={{
					padding: screenWidth > 768 ? "24px 107px" : "24px 10px",
					borderBottom: "1px solid #EDEEF1",
					width: "100%",
				}}
			>
				<div style={{ height: "72px" }}>
					<div className="d-flex flex-column align-items-start p-0" style={{ gap: "4px" }}>
						<div className="resume-review-welcome-text" style={{ fontSize: "22px", color: "#121316" }}>
							James Smith
						</div>
						<div className="expert-review-footer-text" style={{ color: "#484E5C" }}>
							james.smith@hotmail.com · 255 22 334 2321 · Project Manager
						</div>
					</div>
				</div>
				<div className="d-flex align-items-start flex-wrap p-0" style={{ gap: "16px", width: "100%" }}>
					{resumeSteps.map((item) => {
						return <StatusProgressBar key={item.title} data={item} />;
					})}
				</div>
			</div>
			<div
				className="d-flex flex-column align-items-start"
				style={{ padding: screenWidth > 768 ? "24px 107px" : "24px 10px", gap: "0px" }}
			>
				<OrderConsultationCard
					headerTitle="Schedule Initial Consultation"
					showAddNotes={true}
					addNotesLabel="Add notes"
					addNotesPlaceholder="Add notes, ideas, or questions pior to the consultation..."
					showDateTimePicker={true}
					data={{
						candidateInfo: {
							title: "Candidate info",
							data: [
								{ icon: "person", iconSize: 16, title: "James Smith" },
								{ icon: "telephone", iconSize: 16, title: "255 22 334 2321" },
								{ icon: "envelope", iconSize: 16, title: "james.smith@gmail.com" },
								{ icon: "file", iconSize: 16, title: "james-smith-CV.pdf" },
							],
						},
					}}
					tipsData={{
						tipsIntroduction: "Reach out to the candidate to schedule the initial consultation over phone/WhatsApp.",
						tipsTitle: "Here are a few tips to get you started.",
						tips: [
							"Offer multiple time slots to increase the chances of finding a time for both you and the candidate.",
							"Key areas to cover: career objectives, work history highlights, skills, achievements.",
							"Don't forget to discuss the candidate's preferred resume style and format.",
						],
					}}
					showWhatsAppButton={true}
					showMarkAsDoneButton={true}
				/>
				<OrderConsultationCard
					showSeparator
					headerTitle="Conduct Initial Consultation"
					showAddNotes={true}
					addNotesLabel="Add notes"
					addNotesPlaceholder="Take thorough notes during the consultation..."
					data={{
						candidateInfo: {
							title: "Scheduled for",
							data: [
								{ icon: "person", iconSize: 16, title: "James Smith" },
								{ icon: "telephone", iconSize: 16, title: "255 22 334 2321" },
								{ icon: "calendar", iconSize: 16, title: "Tuesday, Jun 25, 2024" },
								{ icon: "clock", iconSize: 16, title: "11AM" },
							],
						},
					}}
					showWhatsAppButton={true}
					showMarkAsDoneButton={true}
				/>
				<OrderConsultationCard
					showSeparator
					headerTitle="Create Resume"
					tipsData={{
						tipsTitle: "Here are a few tips to get you started.",
						tips: [
							"Refer back to your notes to incorporate all requirements as outlined by the candidate.",
							"Focus on quantifiable achievements and relevant skills.",
							"Keep the candidate informed about your progress throughout the process.",
						],
					}}
					showStartResumeButton={true}
				/>
				<OrderConsultationCard
					showSeparator
					headerTitle="Submit Draft"
					showAddNotes={true}
					addNotesLabel="Add notes"
					addNotesPlaceholder="Add any notes for the candidate..."
					showListTile={true}
					data={{
						title: "Project Management Resume",
						subTitle: "Last edited Jun 27, 2024, 12PM",
					}}
					leadingIcon="documentv1"
					leadingIconSize={36}
					trailingButtons={[
						{ type: "iconButton" },
						{ width: 135, background: "#FFFFFF", color: "#3E424E", text: "Continue editing" },
					]}
					addNotesActionButtons={[{ width: 203, background: "#0069CB", color: "#FFFFFF", text: "Submit for review" }]}
				/>
				<OrderConsultationCard
					showSeparator
					headerTitle="Revision Requested"
					showListTile={true}
					data={{
						title: "Project Management Resume",
						subTitle: "Last edited Jun 27, 2024, 12PM",
						candidateNotes: {
							title: "Notes added by the candidate (James Smith)",
							data: [
								{ parent: "Summary: Great start, but can we emphasize my leadership skills more?" },
								{
									parent: "Work Experience:",
									children: [
										"ABC Financial role: Please add the size of the budget I managed ($5M annually)",
										"XYZ Bank role: Can we mention the award I received for process improvement?",
									],
								},
								{ parent: "Skills: Add 'Data Analysis' to the list - I use SQL and Tableau regularly:" },
								{ parent: "Achievements: The conference presentation date is incorrect - it was 2021, not 2020" },
							],
						},
					}}
					leadingIcon="documentv1"
					leadingIconSize={36}
					tipsData={{
						tipsTitle: "Useful tips",
						tips: [
							"Address all points in the candidate's feedback.",
							"If you disagree with any suggestions, explain your reasoning professionally.",
							"Use this opportunity to polish and perfect the resume.",
						],
					}}
					trailingButtons={[
						{ type: "iconButton" },
						{ width: 124, background: "#0069CB", color: "#FFFFFF", text: "Make revisions" },
					]}
				/>
				<OrderConsultationCard
					showSeparator
					headerTitle="Submit Revised Draft"
					showAddNotes={true}
					addNotesLabel="Add notes"
					addNotesPlaceholder="Add any notes for the candidate..."
					showListTile={true}
					data={{
						title: "Project Management Resume",
						subTitle: "Last edited Jun 27, 2024, 12PM",
					}}
					leadingIcon="documentv1"
					leadingIconSize={36}
					trailingButtons={[
						{ type: "iconButton" },
						{ width: 135, background: "#FFFFFF", color: "#3E424E", text: "Continue editing" },
					]}
					addNotesActionButtons={[{ width: 203, background: "#0069CB", color: "#FFFFFF", text: "Submit for review" }]}
				/>
				<OrderConsultationCard
					showSeparator
					showListTile={true}
					showAddNotes={true}
					addNotesLabel="Additional comments"
					addNotesPlaceholder="Any other feedback or suggestions..."
					showExpertProfileOnRating
					leadingIcon="documentv1"
					leadingIconSize={36}
					trailingButtons={[
						{ type: "iconButton" },
						{ icon: "file-download", width: 119, background: "#0069CB", color: "#FFFFFF", text: "Download" },
					]}
					data={{
						title: "Project Management Resume",
						subTitle: "Final resume was accepted by candidate.",
						date: "May 30, 2024, 11AM",
						status: "Order Complete",
						ratings: {
							title: "How would you rate your expert on the following aspects?",
							data: [
								{ title: "Professionalism", value: 4 },
								{ title: "Quality of the resume", value: 5 },
								{ title: "Timeliness", value: 4 },
								{ title: "Communication", value: 4 },
								{ title: "Responsiveness", value: 3 },
							],
							expertDetails: {
								fullName: "Joanne Rosenbaum",
								profileUrl:
									"https://images.unsplash.com/photo-1718938610048-843cfecefef6?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
							},
						},
					}}
					showOrderRatings
					enableRating
					addNotesActionButtons={[{ width: 203, background: "#0069CB", color: "#FFFFFF", text: "Submit for review" }]}
				/>
				<OrderConsultationCard
					showSeparator
					data={{
						date: "May 30, 2024, 11AM",
						status: "Feedback Received",
						ratings: {
							title: "Your feedback and ratings",
							data: [
								{ title: "Professionalism", value: 4 },
								{ title: "Quality of the resume", value: 5 },
								{ title: "Timeliness", value: 4 },
								{ title: "Communication", value: 4 },
								{ title: "Responsiveness", value: 3 },
							],
						},
					}}
					showOrderRatings
				/>
				<OrderConsultationCard
					showSeparator
					data={{
						date: "May 30, 2024, 11AM",
						status: "Order Complete",
						title: "Project Management Resume",
						subTitle: "Final resume was accepted by candidate.",
					}}
					leadingIcon="documentv1"
					leadingIconSize={36}
					trailingButtons={[
						{ type: "iconButton" },
						{ icon: "file-download", width: 119, background: "#0069CB", color: "#FFFFFF", text: "Download" },
					]}
					showListTile={true}
				/>
				<OrderConsultationCard
					showSeparator
					data={{
						date: "May 30, 2024, 11AM",
						status: "Resume Submitted",
						title: "Project Management Resume",
						subTitle: "Draft submitted to candidate, awaiting response.",
					}}
					leadingIcon="documentv1"
					leadingIconSize={36}
					showListTile={true}
				/>
				<OrderConsultationCard
					showSeparator
					data={{
						date: "May 30, 2024, 11AM",
						status: "Revisions Pending",
						title: "Project Management Resume",
						subTitle: "Candidate requested some revisions to the resume draft. See notes below.",
					}}
					leadingIcon="documentv1"
					leadingIconSize={36}
					showActionTitleButton={true}
					actionTitleButtonText="View feedback"
					trailingButtons={[{ type: "iconButton" }]}
					showListTile={true}
				/>
				<OrderConsultationCard
					showSeparator
					data={{
						date: "May 30, 2024, 11AM",
						status: "Draft Submitted",
						title: "Project Management Resume",
						subTitle: "Draft submitted to candidate, waiting for response/feedback.",
					}}
					leadingIcon="documentv1"
					leadingIconSize={36}
					trailingButtons={[{ type: "iconButton" }]}
					showListTile={true}
				/>
				<OrderConsultationCard
					showSeparator
					data={{
						date: "May 30, 2024, 11AM",
						status: "Consultation Complete",
						title: "Consultation Notes",
						subTitle: "Career Objective...",
						moreDetails: [
							{
								parent: "Career Objective:",
								children: [
									"Seeking a senior project manager role in the IT sector",
									"Interested in companies focusing on cloud technologies",
									"Aims to transition from financial services to tech industry",
								],
							},
							{
								parent: "Key Skills:",
								children: [
									"Project Management (PMP certified)",
									"Agile methodologies (Scrum Master certified)",
									"Stakeholder management",
									"Risk assessment and mitigation",
									"Budgeting and resource allocation",
								],
							},
							{ parent: "Work History:" },
							{
								parent: "Current Role: Project Manager at ABC Financial, 2018-present",
								children: [
									"Led a team of 15 in implementing a new CRM system",
									"Completed project 2 months ahead of schedule, saving $500K",
									"Improved customer satisfaction scores by 25% post-implementation",
								],
							},
							{
								parent: "Previous Role: Assistant Project Manager at XYZ Bank, 2015-2018",
								children: [
									"Managed 5 simultaneous projects with budgets up to $1M each",
									"Introduced Agile methodologies, increasing team efficiency by 30%",
								],
							},
							{
								parent: "Education:",
								children: ["MBA, Finance, University of [City], 2015", "BS, Computer Science, State University, 2010"],
							},
							{
								parent: "Achievements:",
								children: [
									"Awarded 'Project Manager of the Year' in 2021 by ABC Financial",
									"Speaker at Regional Project Management Conference, 2020",
									"Volunteered as a mentor for junior project managers, guided 5 to PMP certification",
								],
							},
							{
								parent: "Target Companies:",
								children: ["Google Cloud", "Amazon Web Services", "Microsoft Azure"],
							},
							{
								parent: "Resume Preferences:",
								children: [
									"Prefers a modern, clean design",
									"Wants to highlight transferable skills from finance to tech",
									"Interested in including a brief personal statement",
								],
							},
						],
					}}
					showSeeMoreText={true}
					showActionTitleButton={true}
					actionTitleButtonText="Details"
					showListTile={true}
				/>
				<OrderConsultationCard
					showSeparator
					data={{
						date: "May 30, 2024, 11AM",
						status: "Consultation Scheduled",
						title: "Consultation Scheduled by Expert",
						subTitle: "Scheduled for Jun 25, 11AM",
					}}
					leadingIcon="big-calendar"
					leadingIconSize={56}
					showListTile={true}
				/>
				<OrderConsultationCard
					showSeparator
					data={{
						date: "May 30, 2024, 11AM",
						status: "Order Accepted",
						title: "Assignment accepted by Expert",
						coordinator: "Joanne Rosenbaum",
						coordinatorProfileUrl:
							"https://images.unsplash.com/photo-1718938610048-843cfecefef6?q=80&w=3687&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
					}}
					showListTile={true}
				/>
				<OrderConsultationCard
					showSeparator
					data={{
						date: "May 30, 2024, 11AM",
						status: "Order Assigned",
						title: "Order assignment received by Coordinator",
						coordinator: "John Schinner",
					}}
					showListTile={true}
					trailingButtons={[
						{ width: 74, background: "#FFFFFF", color: "#3E424E", text: "Decline" },
						{ width: 72, background: "#0069CB", color: "#FFFFFF", text: "Accept" },
					]}
				/>
			</div>
		</ExpertResumesLayout>
	);
}

export default ExpertOrderDetails;
