import { match } from "../handlers/utilityFunctions";

export default function (route) {
	return match(route, sections);
}

const sections = {
	"/resume/contact-information": "HEADING",
	"/resume/work-history-summary": "EXPERIENCE",
	"/resume/education-summary": "EDUCATION",
	"/resume/skills": "SKILLS",
	"/resume/brief-summary": "SUMMARY",
	"/resume/reference-summary": "REFERENCE",
	"/resume/certifications": "CERTIFICATION",
	"/resume/affiliations": "AFFILIATION",
	"/resume/additional-information": "ADDITIONAL_INFO",
	"/resume/languages": "LANGUAGES",
	"/resume/personal-profile": "PERSONAL",
	"/resume/personal-links": "LINKS",
	"/resume/accomplishments": "ACCOMPLISHMENTS",
	default: "#",
};
