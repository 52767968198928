import React from "react";

function TemplatePremiumEight() {
	return (
		<div style={{ scale: "1.2", transformOrigin: "center" }}>
			<div className="small-resume-preview small-resume-preview-premium-template-5 small-resume-preview-premium-template-8">
				<div className="final-resume-preview bg-white resume-type-1 py-2 text-dark">
					<div className="container">
						<div className="mb-2">
							<div className="info-wrapper">
								<div>
									<h1>JACQUILINE KHALFAN</h1>
									<h5 className="mb-1">Business Development Manager</h5>
									<p className="mb-2">
										Professional Business Developer with four year of experience in the business Development processes.
										Involved in product testing, management and development of new business opportunities.
									</p>
								</div>
							</div>
							<div
								className="row p-2 mx-0 no-gutters text-white flex-wrap"
								style={{ backgroundColor: "#282828", borderRadius: "10px" }}
							>
								<div className="left col-6">
									<p className="mb-1 contact-icon">
										<i className=" mr-2 fa fa-envelope" aria-hidden="true" />
										jacquiline.khalfan@dproz.tz
									</p>
									<p className="mb-1 contact-icon">
										<i className="mr-2 fa fa-map-marker" aria-hidden="true" />
										New York, USA
									</p>
									<p className="mb-1 contact-icon">
										<i className="mr-2 fa fa-twitter" aria-hidden="true" />
										@jacquiline.khalfan
									</p>
								</div>
								<div className="right col-6">
									<p className="mb-1 contact-icon">
										<i className="mr-2 fa fa-mobile" aria-hidden="true" />
										202-555-0166
									</p>
									<p className="mb-1 contact-icon">
										<i className="mr-2 fa fa-linkedin" aria-hidden="true" />
										linkedin.com/in/jacquiline.khalfan
									</p>
									<p className="mb-1 contact-icon">
										<i className="mr-2 fa fa-skype" aria-hidden="true" />
										jacquiline.khalfan
									</p>
								</div>
							</div>
						</div>
						<div className="mb-2">
							<h4 className="d-flex align-items-center text-uppercase mb-2 font-weight-bold">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#191919",
										flex: "0 0 20px",
										height: "20px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "10px",
									}}
								>
									<i className="fa fa-cogs" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								skills and competencies
							</h4>

							<div className="row no-gutters">
								<div className="col-6 pr-1 mb-1">
									<div className="row no-gutters align-items-center">
										<div className="col-6 pr-1">
											<p className="mb-0">SEO</p>
										</div>
										<div className="col-6 pr-1">
											<p className="mb-0 d-flex justify-content-end align-items-center">
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
											</p>
										</div>
									</div>
								</div>
								<div className="col-6 pr-1 mb-1">
									<div className="row no-gutters align-items-center">
										<div className="col-6 pr-1">
											<p className="mb-0">Public Speaking</p>
										</div>
										<div className="col-6 pr-1">
											<p className="mb-0 d-flex justify-content-end align-items-center">
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
											</p>
										</div>
									</div>
								</div>
								<div className="col-6 pr-1 mb-1">
									<div className="row no-gutters align-items-center">
										<div className="col-6 pr-1">
											<p className="mb-0">Negotiation</p>
										</div>
										<div className="col-6 pr-1">
											<p className="mb-0 d-flex justify-content-end align-items-center">
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
											</p>
										</div>
									</div>
								</div>
								<div className="col-6 pr-1 mb-1">
									<div className="row no-gutters align-items-center">
										<div className="col-6 pr-1">
											<p className="mb-0">Teamwork</p>
										</div>
										<div className="col-6 pr-1">
											<p className="mb-0 d-flex justify-content-end align-items-center">
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
											</p>
										</div>
									</div>
								</div>
								<div className="col-6 pr-1 mb-1">
									<div className="row no-gutters align-items-center">
										<div className="col-6 pr-1">
											<p className="mb-0">Decision Making</p>
										</div>
										<div className="col-6 pr-1">
											<p className="mb-0 d-flex justify-content-end align-items-center">
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
											</p>
										</div>
									</div>
								</div>
								<div className="col-6 pr-1 mb-1">
									<div className="row no-gutters align-items-center">
										<div className="col-6 pr-1">
											<p className="mb-0">Google Analytics</p>
										</div>
										<div className="col-6 pr-1">
											<p className="mb-0 d-flex justify-content-end align-items-center">
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
											</p>
										</div>
									</div>
								</div>
								<div className="col-6 pr-1 mb-1">
									<div className="row no-gutters align-items-center">
										<div className="col-6 pr-1">
											<p className="mb-0">Research and Strategy</p>
										</div>
										<div className="col-6 pr-1">
											<p className="mb-0 d-flex justify-content-end align-items-center">
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
											</p>
										</div>
									</div>
								</div>
								<div className="col-6 pr-1 mb-1">
									<div className="row no-gutters align-items-center">
										<div className="col-6 pr-1">
											<p className="mb-0">Emotional Intelligence</p>
										</div>
										<div className="col-6 pr-1">
											<p className="mb-0 d-flex justify-content-end align-items-center">
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
											</p>
										</div>
									</div>
								</div>
								<div className="col-6 pr-1 mb-1">
									<div className="row no-gutters align-items-center">
										<div className="col-6 pr-1">
											<p className="mb-0">Sales & Marketing</p>
										</div>
										<div className="col-6 pr-1">
											<p className="mb-0 d-flex justify-content-end align-items-center">
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
											</p>
										</div>
									</div>
								</div>
								<div className="col-6 pr-1 mb-1">
									<div className="row no-gutters align-items-center">
										<div className="col-6 pr-1">
											<p className="mb-0">Social Media Advertising</p>
										</div>
										<div className="col-6 pr-1">
											<p className="mb-0 d-flex justify-content-end align-items-center">
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
												<i className="square-box mr-1 fa fa-square" aria-hidden="true" />
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="separator" style={{ backgroundColor: "#8c8c8c", width: "100%", borderRadius: "100px" }}>
							<div style={{ backgroundColor: "#191919", width: "70%", borderRadius: "100px", padding: "1px 0" }} />
						</div>

						<div className="mb-3">
							<h4 className="d-flex align-items-center  mt-2 font-weight-bold mb-0">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#191919",
										flex: "0 0 20px",
										height: "20px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "10px",
									}}
								>
									<i className="fa fa-briefcase" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								WORK EXPERIENCE
							</h4>

							<div className="d-flex">
								<div
									className="left-border"
									style={{
										width: "1px",
										background: "#191919",
										margin: "0 10px",
									}}
								/>
								<div className="content">
									<h4 className="font-weight-bold mb-1 mt-2 d-flex align-items-center">
										<span
											className="dot-mark"
											style={{
												background: "#ffffff",
												height: "10px",
												width: "10px",
												marginLeft: "-13px",
												marginRight: "4px",
												display: "flex",
												alignItems: "center",
											}}
										>
											<span
												style={{
													backgroundColor: "#191919",
													height: "5px",
													width: "5px",
													display: "inline-block",
													borderRadius: "50%",
												}}
											/>
										</span>
										Business Development Manager
									</h4>
									<h5 className="mb-1" style={{ fontWeight: 500 }}>
										AirState Solutions
									</h5>
									<h6
										className="d-flex align-items-center justify-content-between font-italic"
										style={{ color: "#4c4c4c", fontWeight: 500 }}
									>
										<p className="mb-0">09/2014 - 06/2017</p>
										<p className="mb-0">New York, USA</p>
									</h6>
									<ul className="px-3  mb-1" style={{ listStyle: "disc" }}>
										<li className="mb-1">
											Successfully managed $2 - 3 million budget projects and successfully achieved the project
											scheduled goals.
										</li>
										<li className="mb-1">
											Developed and implemented new marketing and sales plans and defined the strategy for the next 5
											years.
										</li>
										<li className="mb-1">
											Reviewed constantly the customer feedback and then suggested ways to improve the processes and
											customer service levels which increased the satisfaction rate from 81% to 95%.
										</li>
										<li className="mb-1">
											Ensured that new clients will grow into a loyal customer base in a specialist niche market by
											implementing a new loyalty program.
										</li>
									</ul>
								</div>
							</div>

							<div className="d-flex">
								<div
									className="left-border"
									style={{
										width: "1px",
										background: "#191919",
										margin: "0 10px",
									}}
								/>
								<div className="content">
									<h4 className="font-weight-bold mb-1 mt-2 d-flex align-items-center">
										<span
											className="dot-mark"
											style={{
												background: "#ffffff",
												height: "10px",
												width: "10px",
												marginLeft: "-13px",
												marginRight: "4px",
												display: "flex",
												alignItems: "center",
											}}
										>
											<span
												style={{
													backgroundColor: "#191919",
													height: "5px",
													width: "5px",
													display: "inline-block",
													borderRadius: "50%",
												}}
											/>
										</span>
										Research Data Analyst
									</h4>
									<h5 className="mb-1" style={{ fontWeight: 500 }}>
										JezRAH Company
									</h5>
									<h6
										className="d-flex align-items-center justify-content-between font-italic"
										style={{ color: "#4c4c4c", fontWeight: 500 }}
									>
										<p className="mb-0">08/2012 - 09/2014</p>
										<p className="mb-0">New York, USA</p>
									</h6>
									<ul className="px-3 mb-1" style={{ listStyle: "disc" }}>
										<li className="mb-1">
											Increased the customer satisfaction rate by 25% by improving the customer service.
										</li>
										<li className="mb-1">
											Planned, supervised, and coordinated daily activity of 3 junior business analysts.
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="separator" style={{ backgroundColor: "#8c8c8c", width: "100%", borderRadius: "100px" }}>
							<div style={{ backgroundColor: "#191919", width: "70%", borderRadius: "100px", padding: "1px 0" }} />
						</div>

						<div className="mb-2">
							<h4 className="text-uppercase mt-2 mb-0 font-weight-bold d-flex align-items-center">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#191919",
										flex: "0 0 20px",
										height: "20px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "10px",
									}}
								>
									<i className="fa fa-graduation-cap" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								EDUCATION
							</h4>

							<div className="row education-list">
								<div className="col-6">
									<h5 className="font-weight-bold mt-2 mb-0">MSc in Economics and Business Administration</h5>
									<h6 className="font-weight-500 mb-0" style={{ fontWeight: 500 }}>
										The University of Chicago
									</h6>
									<p className="font-italic" style={{ color: "#777777", fontWeight: 500 }}>
										09/2008 - 06/2010
									</p>
								</div>
								<div className="col-6">
									<h5 className="font-weight-bold mt-2 mb-0">MSc in Economics and Business Administration</h5>
									<h6 className="font-weight-500 mb-0" style={{ fontWeight: 500 }}>
										The University of Chicago
									</h6>
									<p className="font-italic" style={{ color: "#777777", fontWeight: 500 }}>
										09/2008 - 06/2010
									</p>
								</div>
							</div>
						</div>

						<div className="separator" style={{ backgroundColor: "#8c8c8c", width: "100%", borderRadius: "100px" }}>
							<div style={{ backgroundColor: "#191919", width: "70%", borderRadius: "100px", padding: "1px 0" }} />
						</div>

						<div className="mb-2">
							<h4 className="text-uppercase mt-2 font-weight-bold d-flex align-items-center">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#191919",
										flex: "0 0 20px",
										height: "20px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "10px",
									}}
								>
									<i className="fa fa-leanpub" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								CONFERENCES & COURSES
							</h4>
							<div className="row">
								<div className="col-6">
									<h5 className="mb-0">Leading People and Teams Specialization (03/2016 - 10/2016)</h5>
									<h6 className="font-italic mb-3 mb-md-0" style={{ color: "#888888" }}>
										Online course on Coursera.org
									</h6>
									<h5 className="mb-0">eMetrics Summit (09/2016)</h5>
									<h6 className="font-italic mb-3 mb-md-0" style={{ color: "#888888" }}>
										Rising Media, Inc. and eMetrics Summit
									</h6>
								</div>
								<div className="col-6">
									<h5 className="mb-0">Project Management Principles and Practices (01/2015 - 09/2015)</h5>
									<h6 className="font-italic mb-3 mb-md-0" style={{ color: "#888888" }}>
										Online course on Coursera.org
									</h6>
								</div>
							</div>
						</div>

						<div className="separator" style={{ backgroundColor: "#8c8c8c", width: "100%", borderRadius: "100px" }}>
							<div style={{ backgroundColor: "#191919", width: "70%", borderRadius: "100px", padding: "1px 0" }} />
						</div>
						<div className="mb-2">
							<h4 className="text-uppercase mt-2 font-weight-bold d-flex align-items-center">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#191919",
										flex: "0 0 20px",
										height: "20px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "10px",
									}}
								>
									<i className="fa fa-superpowers" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								INTERESTS
							</h4>
							<ul className="mt-2 mb-2 p-0 text-white">
								<li
									className="rounded px-1 mr-1 py-1 d-inline-block mb-1 skill-tag"
									style={{ backgroundColor: "#8c8c8c" }}
								>
									Artificial Intelligence
								</li>
								<li
									className="rounded px-1 mr-1 py-1 d-inline-block mb-1 skill-tag"
									style={{ backgroundColor: "#8c8c8c" }}
								>
									Economics
								</li>
								<li
									className="rounded px-1 mr-1 py-1 d-inline-block mb-1 skill-tag"
									style={{ backgroundColor: "#8c8c8c" }}
								>
									History
								</li>
								<li
									className="rounded px-1 mr-1 py-1 d-inline-block mb-1 skill-tag"
									style={{ backgroundColor: "#8c8c8c" }}
								>
									Neuromarketing
								</li>
								<li
									className="rounded px-1 mr-1 py-1 d-inline-block mb-1 skill-tag"
									style={{ backgroundColor: "#8c8c8c" }}
								>
									Consumer
								</li>
								<li
									className="rounded px-1 mr-1 py-1 d-inline-block mb-1 skill-tag"
									style={{ backgroundColor: "#8c8c8c" }}
								>
									Behaviour
								</li>
							</ul>
						</div>

						<div className="separator" style={{ backgroundColor: "#8c8c8c", width: "100%", borderRadius: "100px" }}>
							<div style={{ backgroundColor: "#191919", width: "70%", borderRadius: "100px", padding: "1px 0" }} />
						</div>

						<div className="mb-2">
							<h4 className="text-uppercase mt-2 font-weight-bold d-flex align-items-center">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#191919",
										flex: "0 0 20px",
										height: "20px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "10px",
									}}
								>
									<i className="fa fa-users" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								ORGANIZATIONS
							</h4>

							<div className="row" style={{ fontWeight: 500 }}>
								<div className="col-6">
									<h6 className="mb-0">American Management Association</h6>
									<h6 className="mb-1">(2015 - Present)</h6>
									<h6 className="mb-0">Association of Private Enterprise Education</h6>
									<h6 className="mb-1">(2014 - Present)</h6>
								</div>
								<div className="col-6">
									<h6 className="mb-0">eBusiness Association (eBA)</h6>
									<h6 className="mb-1">(2013 - Present)</h6>
								</div>
							</div>
						</div>

						<div className="separator" style={{ backgroundColor: "#8c8c8c", width: "100%", borderRadius: "100px" }}>
							<div style={{ backgroundColor: "#191919", width: "70%", borderRadius: "100px", padding: "1px 0" }} />
						</div>
						<div className="mb-2">
							<h4 className="text-uppercase mt-2 font-weight-bold d-flex align-items-center">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#191919",
										flex: "0 0 20px",
										height: "20px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "10px",
									}}
								>
									<i className="fa fa-trophy" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								HONOURS AND AWARDS
							</h4>
							<div className="row">
								<div className="col-6">
									<h5 className="mb-0">Jury Member, Venture Cup Entrepreneurship Competition (2016)</h5>
									<h6 className="font-italic mb-1" style={{ color: "#888888" }}>
										Venture Cup USA
									</h6>
									<h5 className="mb-0">Sales Individual & Business Development Award (2015)</h5>
									<h6 className="font-italic mb-1" style={{ color: "#888888" }}>
										AirState Business Awards
									</h6>
								</div>
								<div className="col-6">
									<h5 className="mb-0">Excellence in Customer Partnering Award</h5>
									<h6 className="font-italic mb-1" style={{ color: "#888888" }}>
										IES - Institute of Excellence in Sales
									</h6>
								</div>
							</div>
						</div>

						<div className="separator" style={{ backgroundColor: "#8c8c8c", width: "100%", borderRadius: "100px" }}>
							<div style={{ backgroundColor: "#191919", width: "70%", borderRadius: "100px", padding: "1px 0" }} />
						</div>
						<div className="mb-2">
							<h4 className="text-uppercase mt-2 mb-2 font-weight-bold d-flex align-items-center">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#191919",
										flex: "0 0 20px",
										height: "20px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "10px",
									}}
								>
									<i className="fa fa-language" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								LANGUAGES
							</h4>
							<div className="row">
								<div className="col-6">
									<h6 className="mt-1 mb-0 font-weight-bold">
										English
										<p className="font-italic mb-0" style={{ color: "#777777", fontWeight: 500 }}>
											Limited Working Proficiency
										</p>
									</h6>
								</div>
								<div className="col-6">
									<h6 className="mt-1 mb-0 font-weight-bold">
										Spanish
										<p className="font-italic mb-0" style={{ color: "#777777", fontWeight: 500 }}>
											Limited Working Proficiency
										</p>
									</h6>
								</div>
								<div className="col-6">
									<h6 className="mt-1 mb-0 font-weight-bold">
										French
										<p className="font-italic" style={{ color: "#777777", fontWeight: 500 }}>
											Limited Working Proficiency
										</p>
									</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="preview text-center mt-4 mb-4">
				<button
					className="btn text-primary btn-sm btn-outline-primary"
					data-toggle="modal"
					data-target="#ResumePreview"
				>
					<span className="fa fa-eye" /> Preview
				</button>
			</div>
		</div>
	);
}

export default TemplatePremiumEight;
