/* eslint-disable no-useless-escape */
export function isEmailDomainValid(email) {
	const popularDomains = [
		"gmail.com",
		"yahoo.com",
		"outlook.com",
		"hotmail.com",
		"aol.com",
		"icloud.com",
		"mail.com",
		"gmx.com",
		"zoho.com",
		"protonmail.com",
		"yandex.com",
		"office365.com",
		"fastmail.com",
		"tutanota.com",
		"hushmail.com",
		"inbox.com",
	];
	function calculateSimilarity(str1, str2) {
		const len1 = str1.length;
		const len2 = str2.length;
		const maxLen = Math.max(len1, len2);

		let commonChars = 0;

		for (let i = 0; i < len1; i++) {
			for (let j = 0; j < len2; j++) {
				if (str1[i] === str2[j]) {
					commonChars++;
					break;
				}
			}
		}

		return commonChars / maxLen;
	}
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	if (!emailRegex.test(email)) {
		return ""; // Invalid email format
	}

	const [, domain] = email.split("@");
	const lowercaseDomain = domain.toLowerCase();

	const modifiedDomain = lowercaseDomain.replace(".", "");

	let suggestedDomain = "";
	let maxSimilarity = 0;

	for (const popularDomain of popularDomains) {
		const similarity = calculateSimilarity(modifiedDomain, popularDomain);
		if (similarity > maxSimilarity) {
			maxSimilarity = similarity;
			suggestedDomain = popularDomain;
		}
	}

	return suggestedDomain || "";
}

export function stripHTML(input) {
	return input.replace(/(<([^>]+)>)/gi, "");
}

export function extractList(input) {
	input = input
		.replaceAll("<ul>", "")
		.replaceAll("</ul>", "")
		.replaceAll("</li><li>", "1Splitter0")
		.replaceAll("</li>", "")
		.replaceAll("<li>", "");

	return input.split("1Splitter0");
}

export function isValidEmail(email) {
	const re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function isValidPassword(password) {
	return (
		password.length >= 8 &&
		/[A-Z]/.test(password) &&
		/\d/.test(password) &&
		/[!@#$%^&*()_+\-=\[\]/{};':"\\|,.<>\/?]/.test(password)
	);
}

export function isValidPhoneNumber(phoneNumber) {
	// new regrex expression to allow all country codes
	const re = /^(\+)?[0-9]{1,3}-?[0-9]{9,15}$/;
	return re.test(String(phoneNumber));
}

export function isValidPhoneNumberWithTZCode(phoneNumber) {
	//begin with +255 and contains 12 digits
	const re = /^255\d{9}$/gm;
	return re.test(String(phoneNumber));
}

export function isValidName(name) {
	const re = /^[\p{Lu}\p{Ll}\p{Lt}\p{Lm}\p{Lo}\p{Nl}'\.\s]{2,}$/u;
	return re.test(String(name));
}
export function isInstitutionName(name) {
	const re = /^[a-zA-Z ]{2,30}$/;
	return re.test(String(name));
}
export function isValidURL(url) {
	const re =
		/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/;
	return re.test(String(url));
}

export function isValidLocation(location) {
	const re = /^(?!.*(?:[,.!?]){4})[\w\s.,!?-]+$/i;
	return re.test(String(location));
}

export function isValidLocationInHeaderView(location) {
	const re = /^\s*(?!.*(?:[,.!?]){4})[\w\s.,!?-]+\s*$/i;
	return re.test(String(location));
}

export function isValidWorkLocation(location) {
	const re = /\b[^.!?]+,(.\b[^.!?]+|\b[^.!?]+)/gm;
	return re.test(String(location));
}

export function isValidInput(input) {
	//contains only letters, no numbers and special chars
	const re = /\s|^[A-Za-z]*$/gm;
	return re.test(String(input));
}

export function isValidInputNotEmpty(input) {
	const newValue = stripHTML(input).trim();
	return newValue !== "";
}

export function isValidCompanyName(input) {
	//matches a string that starts with an uppercase letter, followed by zero or more occurrences of lowercase letters, uppercase letters, spaces, periods, single quotes, or parentheses.
	const re = /^(?:[A-Z][a-zA-Z\s.'()]*|[^a-z\s]+)$/gm;
	return re.test(String(input));
}

export function isEmptyTextEditor(input) {
	if (input === "<p><br></p>" || input === "") {
		return true;
	}
	return false;
}
