import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import tips from "../../../../assets/copy/tips";
import ErrorToast from "../../../../components/ErrorToast";
import EditorListItems from "../../../../components/ResumeBuilder/EditorListItems";
import Spinner from "../../../../components/Spinner";
import TextEditor from "../../../../components/TextEditor";
import Tips from "../../../../components/Tips";
import ResumeContext from "../../../../context/ResumeContext";
import useHasChange from "../../../../hooks/use-HasChange";
import { Certification } from "../../../../models/resume/certification";
import { StaticValue } from "../../../../seed/constants";
import { ResumeDataService } from "../../../../services/create-resume/resume-data";
import certificationListSuggestions from "./certificationList";

const Certifications = () => {
	const [cookies] = useCookies(["resume"]);
	const navigate = useNavigate();
	const { hasChanges } = useHasChange();

	const staticValue = new StaticValue();
	const resumeDataService = new ResumeDataService();
	const staticValues = staticValue.getDeafaulValues();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [serverErrors, setServerErrors] = useState([]);
	const [certificationList, setCertificationList] = useState([...certificationListSuggestions]);

	//Declaring the certifications state
	const { resumeData, setResumeData } = useContext(ResumeContext);

	const [certifications, setCertifications] = useState(resumeData.extras.certifications);

	const [userInput, setUserInput] = useState(resumeData.contactInfo.resumeTitle);

	useEffect(() => {
		setCertifications(resumeData.extras.certifications);
	}, [resumeData]);

	//submition handler
	const addCertifications = () => {
		setResumeData((prevState) => {
			return {
				...prevState,
				extras: {
					...prevState.extras,
					certifications: certifications,
				},
			};
		});

		const payload = new Certification(certifications);
		const request = payload.getCertificationRequest();

		const resumeId = cookies[staticValues.localStorageResumeId];

		const section = "CERTIFICATION";

		if (hasChanges("CERTIFICATION", request)) {
			setLoading(true);
			resumeDataService.patchResumeLedger(resumeId, request, section, onSuccess, onError);
		} else {
			navigate("/resume/extras");
		}
	};

	function onSuccess(data) {
		setLoading(false);
		const response = data.data;
		dispatch({ type: "RESUME_DATA", payload: response });

		navigate("/resume/extras");
	}

	function onError(error) {
		setLoading(false);
		const serverResponse = error.response.data;
		Array.isArray(serverResponse.message)
			? setServerErrors((prev) => {
					return [...prev, ...serverResponse.message];
				})
			: setServerErrors((prev) => {
					return [...prev, serverResponse.message];
				});
	}

	useEffect(() => {
		setUserInput(resumeData.contactInfo.resumeTitle);
	}, [resumeData]);

	return (
		<>
			<>
				<div className="container min-vh-100">
					<div className="section additional-information">
						{serverErrors.map((item, index) => {
							return <ErrorToast error={item} key={index} />;
						})}
						<div className="common-recommendations-section accomplishments-recommendations-section">
							<div className="row">
								<div className="col-md-6">
									<div className="left">
										<div className="search mb-4">
											<div className="d-flex justify-content-between">
												<p className="mb-3 text-medium">Add details about your proffesional Certifications.</p>
												<Tips content={tips.certifications} />
											</div>
											<div className="form-group">
												<div className="input-group">
													<input type="text" className="form-control" placeholder={userInput} />
													<div className="input-group-append">
														<button className="btn btn-primary px-md-5" type="button">
															<span className="fa fa-search" />
														</button>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12">
												<div className="recommendation-topics">
													<EditorListItems
														editorListItems={certificationList}
														editorContent={certifications}
														setEditorContent={setCertifications}
														setEditorListItems={setCertificationList}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6 d-flex flex-column mt-4 justify-content-end">
									<div className="editor-side">
										<form action="">
											<div className="editor-wrapper">
												<TextEditor
													value={certifications}
													setValue={setCertifications}
													placeholder="Add your certifications here..."
												/>
											</div>
										</form>
									</div>
								</div>
								<div className="mt-4 col-12 d-flex justify-content-end">
									<button
										onClick={() => {
											navigate("/resume/extras");
										}}
										className="btn btn-dark rounded-pill btn-lg mr-2"
									>
										CANCEL
									</button>
									<button onClick={addCertifications} className="btn btn-primary mr-2">
										{loading ? (
											<>
												Loading <Spinner />
											</>
										) : (
											"save"
										)}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
};

export default Certifications;
