const initialState = {
	feedBacks: [],
	hasFeedback: false,
	openFeedback: false,
	feedBackSearched: false,
};

export default function FeedbackReducers(feedback = initialState, action) {
	switch (action.type) {
		case "UPDATE_FEEDBACK":
			return {
				...feedback,
				[action.payload.name]: action.payload.value,
			};

		default:
			return feedback;
	}
}
