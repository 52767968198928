const referees = {
	content: [
		{
			bp: false,
			value:
				"Including referees in your CV can provide potential employers with a way to verify your experience and qualifications.",
		},
		{
			bp: true,
			value:
				"Choose referees who know you and your work well, and who are willing to speak positively about you to potential employers. Your referees should be individuals who have worked with you in a professional capacity, such as former supervisors or colleagues.",
		},
		{
			bp: true,
			value:
				"Always ask for permission before including someone as a referee in your CV. Let them know what kind of jobs you are applying for and provide them with a copy of your CV.",
		},
		{
			bp: true,
			value:
				"Include the full name, job title, organization, and contact information (email and phone number) for each referee. Make sure to double-check that the contact information is up to date.",
		},
		{
			bp: true,
			value:
				"If you don't have any suitable professional referees, you can consider listing personal referees, such as a teacher or mentor, who can speak to your character and work ethic.",
		},
	],
};

export default referees;
