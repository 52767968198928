import React from "react";

import CandidateFitnessItem from "../CandidateFitnessItem";

const JobScoreFit = ({ details }) => {
	return (
		<>
			<div className="row">
				<div className="col-lg-12 mb-4 parameter-box-outer">
					<CandidateFitnessItem data={details.skills_matched_result} type="skills" />
				</div>
				<div className="col-lg-12 mb-4 parameter-box-outer">
					<CandidateFitnessItem data={details.experience_matched_result} type="experience" />
				</div>
				<div className="col-lg-12 mb-4 parameter-box-outer">
					<CandidateFitnessItem data={details.education_matched_result} type="education" />
				</div>
			</div>
			<div className="d-flex flex-row-reverse mb-4">
				<div className="d-flex">
					<span className="me-3 text-success d-flex align-items-center justify-content-center">
						<i className="h3 bi bi-check-circle-fill m-0 me-2" />
						<p className="ml-1 mr-3">High Fit</p>
					</span>
					<span className="me-3 text-warning d-flex align-items-center">
						<i className="h3 bi bi-clock-fill m-0 me-2" />
						<p className="ml-1 mr-3">Moderate Fit</p>
					</span>
					<span className="me-3 text-danger d-flex align-items-center">
						<i className="h3 bi bi-info-circle-fill m-0 me-2" />
						<p className="ml-1 mr-3">Low Fit</p>
					</span>
				</div>
			</div>
		</>
	);
};

export default JobScoreFit;
