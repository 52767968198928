import React from "react";

function CustomRadioField({ name, value, onChange, checked, label }) {
	const inputRef = React.useRef(null);

	return (
		<>
			<input
				type="radio"
				name={name}
				value={value}
				ref={inputRef}
				onChange={onChange}
				checked={checked}
				style={{
					display: "none",
				}}
			/>
			<div className={checked ? "custom-radio selected" : "custom-radio"} onClick={() => inputRef.current.click()}>
				<label className="m-0" htmlFor={name}>
					{label}
				</label>
			</div>
		</>
	);
}

export default CustomRadioField;
