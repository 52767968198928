import React, { useState } from "react";

function RelevantMajors({ relevantMajors, setRelevantMajors, errors }) {
	const [value, setValue] = useState("");
	return (
		<div className="mb-3">
			<label htmlFor="relevantMajors" className="form-label">
				Relevant Majors
			</label>
			{errors.educationQualification &&
				errors.educationQualification.relevantMajors &&
				errors.educationQualification.relevantMajors !== "" && (
					<div className="text-danger m-0">{errors.educationQualification.relevantMajors}</div>
				)}
			{relevantMajors.map((item, index) => (
				<li className="m-0 p-0" key={index}>
					{item}
					<button
						className="btn text-danger p-0 m-0"
						onClick={(e) => {
							e.preventDefault();
							setRelevantMajors((prev) => {
								return prev.filter((item, i) => i !== index);
							});
						}}
					>
						<i className="bi bi-x" />
					</button>
				</li>
			))}
			<div className="d-flex">
				<input
					type="text"
					className={`${
						!(value.replace(/\s/g, "").length > 0) ||
						(errors.educationQualification &&
							errors.educationQualification.relevantMajors &&
							errors.educationQualification.relevantMajors !== "" &&
							"select-box-not-selected-error")
					} rounded-start form-control `}
					id="relevantMajors"
					value={value}
					onChange={(e) => setValue(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							e.preventDefault();
							setRelevantMajors((prev) => {
								return [...prev, value];
							});
							setValue("");
						}
					}}
					style={{
						borderTopRightRadius: 0,
						borderBottomRightRadius: 0,
					}}
				/>
				<button
					className="border-0 text-white rounded-end border-start-0 bg-primary"
					onClick={(e) => {
						e.preventDefault();
						setRelevantMajors((prev) => {
							return [...prev, value];
						});
						setValue("");
					}}
				>
					Add
				</button>
			</div>
		</div>
	);
}

export default RelevantMajors;
