import React from "react";

function LoadingBars() {
	return (
		<div className="loading-wave">
			<div className="loading-bar" />
			<div className="loading-bar" />
			<div className="loading-bar" />
			<div className="loading-bar" />
		</div>
	);
}

export default LoadingBars;
