import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import tips from "../../../../assets/copy/tips";
import Tips from "../../../../components/Tips";
import { logToConsole } from "../../../../handlers/utilityFunctions";
import JobSeekerRegistrationForm from "../../../Auth/RegisterScreen/JobSeekerRegistrationForm";
import ExtrasCheckbox from "./components/ExtrasCheckbox";

const Extras = () => {
	const [cookies] = useCookies(["resume"]);
	const navigate = useNavigate();
	const [showLoginPopup, setShowLoginPopup] = useState(cookies["Authorization"] ? false : true);
	const extras = useSelector((state) => state.ExtrasReducers);
	const dispatch = useDispatch();
	const [customExtra, setCustomExtra] = useState(extras.extrasList.customExtra);

	const extrasSections = [
		{ id: "accomplishments", label: "Accomplishments", name: "Accomplishments", link: "/resume/accomplishments" },
		{ id: "affiliations", label: "Affiliations", name: "Affiliations", link: "/resume/affiliations" },
		{ id: "websites", label: "Websites, Portfolios, Profiles", name: "Websites", link: "/resume/personal-links" },
		{
			id: "additionalInformation",
			label: "Additional Information",
			name: "AdditionalInformation",
			link: "/resume/additional-information",
		},
		{ id: "certifications", label: "Certifications", name: "Certifications", link: "/resume/certifications" },
		{ id: "languages", label: "Languages", name: "Languages", link: "/resume/languages" },
		{ id: "reference", label: "Referees", name: "Reference", link: "/resume/reference-summary" },
		{ id: "profile", label: "Personal Profile", name: "Profile", link: "/resume/personal-profile" },
	];

	const handleCustomExtra = (event) => {
		setCustomExtra(event.target.value);

		dispatch({
			type: "REMOVE_EXTRAS",
			payload: {
				path: "/resume/custom-extra/",
				item: "customExtra",
				checkedType: event.target.value,
			},
		});

		if (event.target.value !== "") {
			dispatchAddExtras({
				path: `/resume/custom-extra/${event.target.value}`,
				item: "customExtra",
				checkedType: event.target.value,
			});
		}
	};

	const dispatchAddExtras = (payload) => {
		dispatch({ type: "ADD_EXTRAS", payload: payload });
	};

	const extrasChoicesHandler = (event) => {
		switch (event.target.id) {
			case "Accomplishments":
				event.target.checked
					? dispatchAddExtras({
							path: "/resume/accomplishments",
							item: "accomplishments",
							checkedType: true,
						})
					: dispatch({
							type: "REMOVE_EXTRAS",
							payload: {
								path: "/resume/accomplishments",
								item: "accomplishments",
								checkedType: false,
							},
						});
				break;

			case "Affiliations":
				event.target.checked
					? dispatchAddExtras({
							path: "/resume/affiliations",
							item: "affiliations",
							checkedType: true,
						})
					: dispatch({
							type: "REMOVE_EXTRAS",
							payload: {
								path: "/resume/affiliations",
								item: "affiliations",
								checkedType: false,
							},
						});
				break;
			case "Websites":
				event.target.checked
					? dispatchAddExtras({
							path: "/resume/personal-links",
							item: "websites",
							checkedType: true,
						})
					: dispatch({
							type: "REMOVE_EXTRAS",
							payload: {
								path: "/resume/personal-links",
								item: "websites",
								checkedType: false,
							},
						});
				break;
			case "AdditionalInformation":
				event.target.checked
					? dispatchAddExtras({
							path: "/resume/additional-information",
							item: "additionalInformation",
							checkedType: true,
						})
					: dispatch({
							type: "REMOVE_EXTRAS",
							payload: {
								path: "/resume/additional-information",
								item: "additionalInformation",
								checkedType: false,
							},
						});

				break;

			case "Certifications":
				event.target.checked
					? dispatchAddExtras({
							path: "/resume/certifications",
							item: "certifications",
							checkedType: true,
						})
					: dispatch({
							type: "REMOVE_EXTRAS",
							payload: {
								path: "/resume/certifications",
								item: "certifications",
								checkedType: false,
							},
						});

				break;

			case "Languages":
				event.target.checked
					? dispatchAddExtras({
							path: "/resume/languages",
							item: "languages",
							checkedType: true,
						})
					: dispatch({
							type: "REMOVE_EXTRAS",
							payload: {
								path: "/resume/languages",
								item: "languages",
								checkedType: false,
							},
						});

				break;
			case "Profile":
				event.target.checked
					? dispatchAddExtras({
							path: "/resume/personal-profile",
							item: "profile",
							checkedType: true,
						})
					: dispatch({
							type: "REMOVE_EXTRAS",
							payload: {
								path: "/resume/personal-profile",
								item: "profile",
								checkedType: false,
							},
						});

				break;
			case "Reference":
				event.target.checked
					? dispatchAddExtras({
							path: "/resume/reference-summary",
							item: "reference",
							checkedType: true,
						})
					: dispatch({
							type: "REMOVE_EXTRAS",
							payload: {
								path: "/resume/reference-summary",
								item: "reference",
								checkedType: false,
							},
						});

				break;

			default:
				logToConsole("Not Accomplishments");
		}
	};

	useEffect(() => {}, [showLoginPopup]);

	return (
		<>
			{showLoginPopup && (
				<div
					className="modal"
					role="document"
					style={{
						zIndex: 2000,
						backgroundColor: "#0000006e",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<div
						className="modal-content"
						style={{
							width: "fit-content",
						}}
					>
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLongTitle">
								Register to save progress
							</h5>
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close"
								onClick={() => setShowLoginPopup(false)}
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							Please sign up to save your resume progress and continue editing on other devices.
							<JobSeekerRegistrationForm />
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								data-dismiss="modal"
								onClick={() => setShowLoginPopup(false)}
							>
								Stay
							</button>
							<Link to="/register">
								<button type="button" className="btn btn-primary" data-dismiss="modal">
									Register
								</button>
							</Link>
						</div>
					</div>
				</div>
			)}

			<div className="container min-vh-100">
				<div className="section">
					<div className="col-md-6">
						<div className="d-flex justify-content-end mb-2">
							<Tips content={tips.extras} />
						</div>
						<p className="fs-14 mb-3 text-medium">Do you have anything else to add?</p>

						{extrasSections.map((section, index) => {
							return (
								<ExtrasCheckbox
									extras={extras}
									section={section}
									extrasChoicesHandler={extrasChoicesHandler}
									navigate={navigate}
									key={index}
								/>
							);
						})}

						<div className="form-group">
							<label htmlFor="">Add Your Own</label>
							<div className="d-flex align-items-center justify-content-between flex-grow-1">
								<input
									type="text"
									className="form-control position-relative "
									id="CustomExtra"
									name="CustomExtra"
									value={customExtra}
									onChange={handleCustomExtra}
									placeholder="Hobbies"
								/>
								{extras.extrasList.customExtra !== "" && (
									<span
										onClick={() => navigate(`/resume/custom-extra/${customExtra}`)}
										className="text-primary font-weight-semi cursor-pointer position-absolute"
										data-toggle="tooltip"
										data-placement="right"
										title="Click to Add/Edit"
										style={{
											right: "30px",
										}}
									>
										Update
									</span>
								)}
							</div>
						</div>
						<div className="d-flex align-items-center justify-content-end">
							<button
								name="back"
								onClick={() => {
									navigate("/my-resumes");
								}}
								className="text-primary font-weight-semi mr-2 pl-0"
							>
								Skip for now
							</button>
							<button
								onClick={() => {
									navigate("/resume/reviews");
								}}
								className="btn btn-primary"
							>
								Review
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Extras;
