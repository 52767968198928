/* eslint-disable no-case-declarations */
let initalState = {
	extrasItems: [],
	extrasList: {
		accomplishments: false,
		affiliations: false,
		websites: false,
		additionalInformation: false,
		certifications: false,
		languages: false,
		profile: false,
		reference: false,
		customExtra: "",
	},
};

var existingValue = localStorage.getItem("ExtrasReducers");

if (!(existingValue === undefined || existingValue === null)) {
	initalState = JSON.parse(existingValue);
}

export default function ExtrasReducers(extras = initalState, action) {
	switch (action.type) {
		case "ADD_EXTRAS":
			let updatedPaths = [];
			//check is the path is already present
			if (extras.extrasItems.length === 0) {
				updatedPaths = extras.extrasItems.concat(action.payload.path);
			} else {
				const isPathPresent = extras.extrasItems.find((item) => item === action.payload.path);

				if (isPathPresent) {
					updatedPaths = extras.extrasItems;
				} else {
					updatedPaths = extras.extrasItems.concat(action.payload.path);
				}
			}

			//else just update
			let value = {
				extrasItems: updatedPaths,
				extrasList: {
					...extras.extrasList,
					[action.payload.item]: action.payload.checkedType,
				},
			};

			localStorage.setItem("ExtrasReducers", JSON.stringify(value));

			return value;

		case "REMOVE_EXTRAS":
			// const itemToRemove = extras.extrasItems.find(item => item === action.payload.path);

			return {
				extrasItems: extras.extrasItems.filter((item) => !item.includes(action.payload.path)),
				extrasList: {
					...extras.extrasList,
					[action.payload.item]: action.payload.checkedType,
				},
			};
		default:
			return extras;
	}
}
