import React from "react";

import Icon from "./Icon";

function CloseButton({ action = () => null }) {
	return (
		<button onClick={action} className="bg-gray circle">
			<Icon iconName="close" size={20} />
		</button>
	);
}

export default CloseButton;
