export class ResumeVersion {
	constructor(versionId, versionName) {
		this.versionId = versionId;
		this.versionName = versionName;
	}
}

export class Heading {
	constructor(firstName, middleName, lastName, phoneNumber) {
		this.firstName = firstName;
		this.middleName = middleName;
		this.lastName = lastName;
		this.phoneNumber = phoneNumber;

		//tobecompleted
	}
}
