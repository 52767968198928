import React from "react";

import Icon from "../Icon";

function CandidateInfo({ title, data }) {
	const fileIcon = "file";

	return (
		<div
			className="d-flex flex-column align-items-stretch"
			style={{
				padding: "0px 24px",
				gap: "12px",
				borderLeft: "1px solid #EDEEF1",
				maxWidth: "268px",
				flex: 1,
			}}
		>
			{title && (
				<div className="d-flex align-items-center" style={{ padding: "12px 0px", gap: "10px" }}>
					<div className="grouped-skill-text-title" style={{ fontSize: "16px", lineHeight: "24px", color: "#3E424E" }}>
						{title}
					</div>
				</div>
			)}
			<div className="d-flex flex-column align-items-start p-0" style={{ gap: "12px" }}>
				{data &&
					data.map((item) => {
						return (
							<div
								key={item.title}
								className="d-flex align-items-center p-0"
								style={{ gap: "8px", ...(item.icon === fileIcon && { cursor: "pointer" }) }}
							>
								{item?.icon && <Icon iconName={item?.icon} size={item?.iconSize} />}
								{item.title && (
									<div
										className="top-text-on-skill-section"
										style={{ color: item.icon === fileIcon ? "#0069CB" : "#3E424E" }}
									>
										{item.title}
									</div>
								)}
							</div>
						);
					})}
			</div>
		</div>
	);
}

export default CandidateInfo;
