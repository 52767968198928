//this is just a sample

import { Resume } from "../../models/resume/resume";

export default function ResumeReducer(resume = new Resume(), action) {
	switch (action.type) {
		case "SAMPLE_DATA":
			return action.payload;
		default:
			return resume;
	}
}
