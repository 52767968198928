import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import Reaptcha from "reaptcha";

import { isValidEmail } from "../../handlers/validationFunctions";
import Spinner from "../Spinner";
import TextEditor from "../TextEditor";

const getHeaderTitle = (keyword, firstName) => {
	const headerTitles = [
		{
			keyword: "contact-information",
			message: `Hey ${firstName}, we can see you are trying to add your contacts infromation. What seems to be the issue ?`,
		},
		{
			keyword: "education-summary",
			message: `${firstName}, we see you are trying to add education background. What seems to be the issue ?`,
		},
		{
			keyword: "work-history-summary",
			message: `${firstName}, we see you are trying to add your previous and current work information. if there is any problem tell us here!`,
		},
		{
			keyword: "skills",
			message: `Hi ${firstName}, You can tell us what seems to be the issue when you add your skills right here !`,
		},
		{
			keyword: "brief-summary",
			message: `${firstName}, please tell us what is stopping you from adding background about yourself ?`,
		},
		{
			keyword: "education-information",
			message: `${firstName}, you can tell us what is stopping you from adding or editing your education information ?`,
		},
		{
			keyword: "work-history",
			message: `Hello ${firstName}, please tell us what hinders you from adding your most recent job?`,
		},
		{
			keyword: "work-details",
			message: `${firstName}, describing what you did at work can be challenging. What is the problem in adding your contribution at work?`,
		},
		{
			keyword: "education-information",
			message: `${firstName}, we understand you are trying to add some education information. What is giving you some troubles?`,
		},
	];
	//customising Support modal header
	const headerInfo = headerTitles.find((msg) => msg.keyword === keyword);
	const headerTitle = headerInfo ? headerInfo.message : "Hellow " + firstName + ", what seems to be the issue ";

	return headerTitle;
};

function CandidateSupportModal(props) {
	// get User data and other important data from the storage
	const user = JSON.parse(localStorage.getItem("profileData"));
	const templateName = localStorage.getItem("templateName");
	const location = useLocation();
	// const currentLink = location.pathname.split("/")[2];
	const currentLink = location.pathname.split("/")[2];
	const [firstName, setFirstName] = useState("Welcome");
	const [message, setMessage] = useState("");

	//assigining user data to the message object
	const [contactData, setContactData] = useState({
		date: dayjs().format("YYYY-MM-DD"),
		name: "",
		email: "",
		inquiry: "page activity: " + currentLink + " , template Name: " + templateName,
		message: "",
		phone: "",
	});

	//This updates the object with the just new typed message
	const handleChange = (e) => {
		const { name, value } = e.target;
		setContactData({ ...contactData, [name]: value });
	};

	//preparing data to the contact Data
	//This helps us to handle the situation which user is not loged in

	useEffect(() => {
		if (user) {
			setFirstName(user.firstName);
			setContactData((prev) => ({
				...prev,
				name: user.firstName + " " + user.lastName,
				email: user.email,
				phone: user.phone,
				message: message,
			}));
		} else {
			setContactData((prev) => ({
				...prev,
				message: message,
			}));
		}
	}, [user, message, contactData]);

	//get customized header title ::: where currentLink is the keyword
	const headerTitle = getHeaderTitle(currentLink, firstName);

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [success, setSuccess] = useState(false);

	//API function for contacting support
	function submitContactUs(contactData, successCallback, failureCallback) {
		let data = JSON.stringify(contactData);

		let URL =
			"https://script.google.com/macros/s/AKfycbykjUQVTGiD-ICieXK23MOZPAIF_YPVq-ukmhVJjfRxlRMD1DQt6zLzSS8aBCPQczco/exec";

		fetch(URL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
			},
			body: data,
			mode: "no-cors",
		})
			.then((response) => {
				successCallback(response.data);
			})
			.catch((error) => {
				failureCallback(error);
			});
	}

	const [captchaToken, setCaptchaToken] = useState(null);
	const captchaRef = useRef(null);

	const verify = () => {
		captchaRef.current.getResponse().then((res) => {
			setCaptchaToken(res);
		});
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		const error = [];

		if (contactData.name === "") {
			error.push("   Name is required");
		}

		if (contactData.email === "") {
			error.push("  Email is required");
		}

		if (!isValidEmail(contactData.email)) {
			error.push("  Email is invalid");
		}

		if (contactData.inquiry === "") {
			error.push("Something Went wrong ... Inquiry is required");
		}

		if (contactData.message === "") {
			error.push("Your Message is required");
		}

		if (contactData.phone === "") {
			error.push(" Phone is required");
		}

		if (error.length > 0) {
			setErrors(error);
			return;
		}

		//

		setLoading(true);

		if (window.location.hostname === "localhost") {
			// SKIP RECAPTCHA if in localhost
		} else {
			if (captchaToken === null) {
				setLoading(false);
				setErrors(["Please verify that you are not a robot."]);
				return;
			}
		}

		submitContactUs(
			contactData,
			() => {
				setSuccess(true);
				setLoading(false);
				setContactData({
					date: dayjs().format("YYYY-MM-DD"),
					name: "",
					email: "",
					inquiry: "",
					message: "",
					phone: "",
				});
				props.onCloseNeedHelpModal();
			},
			() => {
				setLoading(false);
				setErrors(["Something went wrong! Please try again later."]);
				toast.error("Something went wrong! Please try again later.");
				props.onCloseNeedHelpModal();
			},
		);
	};

	return (
		<div className="controlled-modal">
			<div
				className="modal-dialog"
				style={{
					maxWidth: "90vw",
					width: "700px",
				}}
			>
				{errors.length > 0 && (
					<div className="alert alert-danger m-0">
						<ul className="mb-0">
							{errors.map((error, index) => (
								<li key={index}>{error}</li>
							))}
						</ul>
					</div>
				)}
				{success && <div className="alert alert-success">Your message has been sent successfully!</div>}
				<div className="modal-content">
					<div className="modal-header border-0 pb-0">
						<h5 className="modal-title">{headerTitle}</h5>
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
							onClick={props.onCloseNeedHelpModal}
						>
							<i className="mr-0 bi bi-x" />
						</button>
					</div>
					<div className="modal-body pt-0">
						<form>
							<div className="form-group" />
							<div className="form-group">
								{/* Render name, email and phone input when user is not logged in */}
								{!user && (
									<>
										<div className="form-group">
											<label htmlFor="name">Full Name</label>
											<input
												type="text"
												className="form-control home-form-control"
												id="name"
												value={contactData.name}
												onChange={handleChange}
												name="name"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="email">Email Address</label>
											<input
												type="email"
												className="form-control home-form-control"
												id="email"
												value={contactData.email}
												onChange={handleChange}
												name="email"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="phone">Phone Number</label>
											<input
												type="text"
												className="form-control home-form-control"
												id="phone"
												value={contactData.phone}
												onChange={handleChange}
												name="phone"
											/>
										</div>
									</>
								)}
								{/* This is The text input for message */}
								<div className="editor-wrapper">
									<p className="mb-2">You can tell us about any issue you experienced.</p>
									<TextEditor
										id="supportMessageTextEditor"
										value={message}
										setValue={setMessage}
										placeholder="Tell us ..."
									/>
								</div>
							</div>
						</form>
						<div className="d-flex justify-content-between">
							<div>
								<Reaptcha sitekey="6LcCqZAkAAAAABvxLKxrPXAUlaSOx4kQnr-gyzOy" ref={captchaRef} onVerify={verify} />
								{true && (
									<button className="btn btn-primary mt-1" onClick={handleSubmit}>
										{loading ? <Spinner /> : "Submit"}
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CandidateSupportModal;
