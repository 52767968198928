export class Resume {
	constructor(resumeVersion, heading) {
		this.resumeVersion = resumeVersion;
		this.heading = heading;
		this.jobList = [];
		this.educationList = [];
	}

	setheading(heading) {
		this.heading = heading;
	}
	addJob(job) {
		this.jobList.push(job);
	}
	addEducation(education) {
		this.educationList.push(education);
	}

	removeJob() {
		//this.jobList.Add(job);
	}
	removeEducation() {
		//this.jobList.Add(job);
	}

	setEducationList(educationList) {
		this.educationList = educationList;
	}

	// setEducationList(jobList) {
	//     this.jobList = jobList;
	// }

	getResume() {
		return {
			heading: { isRequired: 1, heading: this.heading },
			jobHistory: { isRequred: 1, jobList: this.jobList },
			educationHistory: { isRequired: 1, educationList: this.educationList },

			///
		};
	}
}
