import { convertUlToArray } from "../../handlers/utilityFunctions";

export class AdditionalInfo {
	constructor(additionalInfo) {
		this.additionalInfo = additionalInfo;
	}

	getAdditionalInfoRequest() {
		const textAreaItems = convertUlToArray(this.additionalInfo);

		return {
			data: textAreaItems,
		};
	}
}
