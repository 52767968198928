import React from "react";

import { getInitials } from "../../handlers/utilityFunctions";

function AvatarChip({ fullName, userProfileUrl }) {
	return (
		<div className="d-flex align-items-center avatar-chip-container">
			<div className="d-flex flex-column align-items-center justify-content-center p-0 avatar-image-container">
				{userProfileUrl ? <img alt={fullName} src={userProfileUrl} /> : getInitials(fullName)}
			</div>
			<div className="expert-review-footer-text" style={{ color: "#121316" }}>
				{fullName}
			</div>
		</div>
	);
}

export default AvatarChip;
