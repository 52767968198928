import React from "react";
import { Modal } from "react-bootstrap";

import { ResumeDataService } from "../../../services/create-resume/resume-data";

function MappingColumnModal({
	showColumnMappingDialog,
	handleCloseColumnMappingDialog,
	companiesData,
	setCompaniesData,
}) {
	const jobService = new ResumeDataService();
	const [mappingComplete, setMappingComplete] = React.useState(false);
	const [isSubmitting, setSubmitting] = React.useState(false);

	const onSuccess = (data) => data;

	const onError = (data) => data;

	const uploadCompanies = async () => {
		setSubmitting(true);
		let newCompanies = companiesData.rows.map(async (item) => {
			const newItem = {};
			companiesData.mappedColumns.forEach((column) => {
				if (column.originalColumn !== "") {
					if (column.supportedColumn === "location") {
						const location = item[column.originalColumn]?.toString()?.trim()?.split(",");
						const companyLocation = {
							city: location[0]?.toString()?.trim() || "",
							region: location[1]?.toString()?.trim() || "",
							country: location[2]?.toString()?.trim() || "",
						};
						newItem[column.supportedColumn] = companyLocation;
					} else {
						newItem[column.supportedColumn] = item[column.originalColumn]?.toString()?.trim();
					}
				}
			});
			return await jobService.createCompany(newItem, onSuccess, onError);
		});

		await Promise.all(newCompanies);
		setSubmitting(false);
		handleCloseColumnMappingDialog();
	};

	React.useEffect(() => {
		const isMappingComplete = companiesData.mappedColumns.slice(0, 4).every((column) => column.originalColumn !== "");
		setMappingComplete(isMappingComplete);
	}, [companiesData]);

	return (
		<Modal show={showColumnMappingDialog} onHide={handleCloseColumnMappingDialog} centered size="xl" backdrop="static">
			<Modal.Header>
				<Modal.Title>Add Companies</Modal.Title>
				<button
					type="button"
					className="btn btn-secondary text-center"
					data-dismiss="modal"
					aria-label="Close"
					onClick={() => handleCloseColumnMappingDialog()}
				>
					<i className="fa fa-close ml-2" />
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="row border-bottom">
					{companiesData?.mappedColumns.map((column, index) => {
						return (
							<React.Fragment key={index}>
								<div className="col-md-6">
									<div className="form-group d-flex flex-row">
										<input
											type="text"
											disabled={true}
											className="form-control bg-transparent"
											id={column.supportedColumn}
											value={column.supportedColumn}
										/>
										<label htmlFor={column.supportedColumn}>Supported Column</label>
										<div
											className="position-absolute bg-transparent border-0 p-2 mt-2"
											style={{
												left: "98%",
												transform: "translateX(-90%)",
												marginTop: "0%",
											}}
										>
											<i
												className={`fa ${
													column.originalColumn !== "" ? "fa-check text-success" : "fa-times text-danger"
												}`}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<select
											id={`originalColumn${index}`}
											className="form-control"
											name={`originalColumn${index}`}
											value={column.originalColumn}
											onChange={(event) => {
												const newColumns = companiesData?.mappedColumns;
												newColumns[index].originalColumn = event.target.value;
												const newOrdersData = {
													...companiesData,
													mappedColumns: newColumns,
												};
												setCompaniesData(newOrdersData);
											}}
										>
											{companiesData?.originalColumns.map((originalColumn) => {
												let mappedTo = "";
												const originalColumnIndex = companiesData?.mappedColumns.findIndex(
													(item) => item.originalColumn === originalColumn,
												);
												if (originalColumnIndex !== -1) {
													mappedTo = companiesData?.mappedColumns[originalColumnIndex].supportedColumn;
												}

												return (
													<option key={originalColumn} value={originalColumn} disabled={mappedTo !== ""}>
														{originalColumn}
														{mappedTo !== "" && ` mapped to (${mappedTo})`}
													</option>
												);
											})}
											<option value="">Reset Mapping</option>
										</select>
										<label htmlFor={`originalColumn${index}`}>Your data feed file</label>
									</div>
								</div>
							</React.Fragment>
						);
					})}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex justify-content-end">
					<button
						type="button"
						className="btn btn-secondary mx-1"
						data-dismiss="modal"
						onClick={() => {
							handleCloseColumnMappingDialog();
						}}
					>
						Cancel
					</button>

					{mappingComplete && (
						<button type="button" disabled={isSubmitting} className="btn btn-primary mx-1" onClick={uploadCompanies}>
							{isSubmitting ? "Loading" : "Add"}
						</button>
					)}
				</div>
			</Modal.Footer>
		</Modal>
	);
}

export default MappingColumnModal;
