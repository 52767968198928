const workHistory = {
	content: [
		{
			bp: false,
			value:
				"Detail your work experience. Job titles, employers/clients, locations, and dates. Briefly summarize your areas of responsibility, and importantly highlight the successes and achievements of each position. This is the most substantial section of the resume, because experience is typically your strongest qualification. Make sure readers understand what you've done and how valuable it has been.",
		},
		{
			bp: false,
			value:
				"The professional experience section of your resume is one that the recruiter will be paying the most attention to. Use strong, energetic language to highlight your unique capabilities, and sell yourself to the role.",
		},
		{
			bp: true,
			value:
				"Your professional experience should be listed in reverse chronological order with your most recent place of employment at the top. Include company name, your title, location, and dates of employment in that position. Shouldn't take up more than two lines",
		},
		{ bp: true, value: "Accent the company names and dates of employment in bold and role titles in italics" },
		{
			bp: true,
			value:
				"Don't include a brief description of each company, take-up unnecessary space: use that space for accomplishments instead",
		},
	],
};

export default workHistory;
