import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import tips from "../../../../assets/copy/tips";
import ErrorToast from "../../../../components/ErrorToast";
import Spinner from "../../../../components/Spinner";
import Tips from "../../../../components/Tips/Tips";
import ResumeContext from "../../../../context/ResumeContext";
import { FormValidation } from "../../../../handlers/formValidation";
import { dateObjToYMD } from "../../../../handlers/utilityFunctions";
import { PersonalInfo } from "../../../../models/resume/personalInfo";
import { StaticValue } from "../../../../seed/constants";
import { ResumeDataService } from "../../../../services/create-resume/resume-data";

const PersonalProfile = () => {
	const [cookies] = useCookies(["resume"]);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const staticValue = new StaticValue();
	const resumeDataService = new ResumeDataService();
	const staticValues = staticValue.getDeafaulValues();
	const formValidation = new FormValidation();
	const [serverErrors, setServerErrors] = useState([]);

	//Declaring the form states
	const [profile, setProfile] = useState({
		gender: "",
		dateOfBirth: "",
		maritalStatus: "",
	});

	const [errors, setErrors] = useState({
		sex: "",
		dateOfBirth: "",
		maritalStatus: "",
	});

	const [loading, setLoading] = useState(false);

	// The form event handler
	const onChangeInput = (e) => {
		setProfile((prevState) => {
			return {
				...prevState,
				[e.target.name]: e.target.value,
			};
		});
	};

	// const [errors, setErrors] = useState([]);

	const { resumeData, setResumeData } = useContext(ResumeContext);
	const { personalProfile } = resumeData.extras;

	useEffect(() => {
		setProfile((prev) => {
			return { ...prev, ...personalProfile };
		});
	}, [resumeData]);

	//form submission handler
	const addProfile = () => {
		let validations = formValidation.validatePersonalProfile(errors, profile);

		if (validations.status) {
			setErrors(validations.errors);
		} else {
			return setErrors(validations.errors);
		}

		const payload = new PersonalInfo(profile);
		const request = payload.getPersonalInfoRequest();

		const resumeId = cookies[staticValues.localStorageResumeId];

		const section = "PERSONAL";
		setLoading(true);
		resumeDataService.patchResumeLedger(resumeId, request, section, onSuccess, onError);
	};

	function onSuccess(data) {
		setLoading(false);
		const response = data.data.personlPrivateInfo;
		const date = dateObjToYMD(response.dateOfBirth);

		const profileData = {
			dateOfBirth: date,
			sex: response.gender,
			maritalStatus: response.maritalStatus,
		};

		setResumeData((prevState) => {
			return {
				...prevState,
				extras: {
					...prevState.extras,
					personalProfile: profileData,
				},
			};
		});

		dispatch({ type: "RESUME_DATA", payload: data.data });

		navigate("/resume/extras");
	}

	function onError(data) {
		setLoading(false);
		const serverResponse = data.response.data;
		Array.isArray(serverResponse.message)
			? setServerErrors((prev) => {
					return [...prev, ...serverResponse.message];
				})
			: setServerErrors((prev) => {
					return [...prev, serverResponse.message];
				});
	}

	return (
		<>
			<div className="container">
				<div className="section education-information">
					<div className="col-md-6">
						{serverErrors.map((item, index) => {
							return <ErrorToast error={item} key={index} />;
						})}
						<div className="d-flex justify-content-end mb-2">
							<Tips content={tips.personalProfile} />
						</div>
						<div className="mb-3 text-dark">
							<p>Add additional personal details.</p>
						</div>

						<div className="row m-0">
							<div className="form-group col-6 pl-0">
								<label>Gender</label>
								<select
									type="text"
									className="form-control"
									name="gender"
									value={profile.gender}
									onChange={onChangeInput}
								>
									<option disabled value="">
										Select Option
									</option>
									<option value="MALE">Male</option>
									<option value="FEMALE">Female</option>
								</select>
							</div>
							<div className="form-group col-6 p-0">
								<label>Maritial Status</label>
								<select
									className="form-control"
									name="maritalStatus"
									value={profile.maritalStatus}
									onChange={onChangeInput}
								>
									<option disabled value="">
										Select Option
									</option>
									<option value="SINGLE">Single</option>
									<option value="MARRIED">Married</option>
									<option value="SEPARATED">Separated</option>
								</select>
							</div>
						</div>

						<div className="form-group">
							<label>Date Of Birth</label>
							<input
								type="date"
								className="form-control"
								name="dateOfBirth"
								value={profile.dateOfBirth}
								onChange={onChangeInput}
							/>
						</div>

						<div className="w-100 mt-4 d-flex align-items-center justify-content-end">
							<button
								onClick={() => {
									navigate("/resume/extras");
								}}
								className="text-primary font-weight-semi mr-2"
							>
								Skip for now
							</button>
							<button onClick={addProfile} className="btn btn-primary mr-2">
								{loading ? (
									<>
										Loading <Spinner />
									</>
								) : (
									"Continue"
								)}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PersonalProfile;
