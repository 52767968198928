import {
	isEmptyTextEditor,
	isValidCompanyName,
	isValidEmail,
	isValidInput,
	isValidInputNotEmpty,
	isValidLocation,
	isValidName,
	isValidPhoneNumber,
	isValidURL,
} from "./validationFunctions";
export class FormValidation {
	isFormComplete(inputs) {
		let complete = true;
		for (const input in inputs) {
			if (inputs[input] === "") {
				complete = false;
				break;
			}
		}

		return complete;
	}

	valdiateWorkInformation(errors, workData) {
		let workDetails = workData.details;
		let status = true;
		//additional implementation is needed for other values
		if (workDetails === "<p><br></p>" || workDetails === "") {
			errors = "Please enter work details";
			status = false;
		}

		return { errors, status };
	}

	validateWorkHistoryInformation(errors, data) {
		let status = true;

		errors = {
			jobTitle: "",
			employer: "",
			city: "",
			country: "",
			startDate: "",
			endDate: "",
		};

		if (!isValidInput(data.jobTitle)) {
			status = false;
			errors = { ...errors, jobTitle: "Invalid Job Title format" };
		}

		if (!isValidName(data.employer)) {
			status = false;
			errors = { ...errors, employer: "Invalid Employer Name format" };
		}

		if (!isValidLocation(data.address)) {
			status = false;
			errors = { ...errors, address: "Invalid address format, e.g. city, region" };
		}

		return { errors, status };
	}

	validateSkillsDetails(errors, skills) {
		let status = true;
		if (skills === "<p><br></p>" || skills === "") {
			errors.push("Please add your skills");
			status = false;
		}

		return { errors, status };
	}

	validateBriefDetails(brief) {
		let status = true;
		if (isEmptyTextEditor(brief)) {
			status = false;
		}
		return status;
	}

	validateCertificationsDetails(error, certifications) {
		let status = true;
		if (isEmptyTextEditor(certifications)) {
			error = "Please add your Certifications details";
			status = false;
		}

		return { error, status };
	}

	validateReferenceInfo(errors, data) {
		let status = true;

		errors = {
			name: "",
			designation: "",
			workPlace: "",
			address: "",
			phoneNumber: "",
			email: "",
		};

		if (!isValidName(data.name)) {
			status = false;
			errors = { ...errors, name: "Invalid name format." };
		}

		if (!isValidInputNotEmpty(data.designation)) {
			status = false;
			errors = { ...errors, designation: "Invalid designation format, Start with capital letter eg Manager" };
		}

		if (!isValidCompanyName(data.workPlace)) {
			status = false;
			errors = { ...errors, workPlace: "Invalid work place format. Should start with Capital Letter" };
		}

		if (!isValidLocation(data.address)) {
			status = false;
			errors = { ...errors, address: "Invalid address format, e.g. city, region" };
		}

		if (!isValidPhoneNumber(data.phoneNumber)) {
			status = false;
			errors = { ...errors, phoneNumber: "Invalid Phone number format." };
		}

		if (!isValidEmail(data.email)) {
			status = false;
			errors = { ...errors, email: "Invalid email format" };
		}

		return { errors, status };
	}

	validatePersonalProfile(errors, data) {
		let status = true;

		errors = { gender: "", maritalStatus: "", dateOfBirth: "" };

		if (!isValidInputNotEmpty(data.gender)) {
			status = false;
			errors = { ...errors, gender: "Select your gender!" };
		}

		if (!isValidInputNotEmpty(data.maritalStatus)) {
			status = false;
			errors = { ...errors, maritalStatus: "Select your marital status!" };
		}

		// if(!isValidInputNotEmpty(data.dateOfBirth)){
		//     status = false;
		//     errors = { ...errors, dateOfBirth: "Invalid date of birth format!" };
		// }

		return { errors, status };
	}

	validatePersonalLinks(errors, data) {
		let status = true;

		errors = { linkedIn: "", twitter: "", github: "", otherLink: "" };

		if (data.linkedIn !== "") {
			if (!isValidURL(data.linkedIn)) {
				status = false;
				errors = { ...errors, linkedIn: "This is not a valid URL!" };
			}
		}

		if (data.twitter !== "") {
			if (!isValidURL(data.twitter)) {
				status = false;
				errors = { ...errors, twitter: "This is not a valid URL!" };
			}
		}

		if (data.github !== "") {
			if (!isValidURL(data.github)) {
				status = false;
				errors = { ...errors, github: "This is not a valid URL!" };
			}
		}

		if (data.otherLink !== "") {
			if (!isValidURL(data.otherLink)) {
				status = false;
				errors = { ...errors, otherLink: "This is not a valid URL!" };
			}
		}

		return { errors, status };
	}
}
