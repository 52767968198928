import React from "react";

import AddNotes from "./AddNotes";
import CandidateInfo from "./CandidateInfo";
import CandidateNotes from "./CandidateNotes";
import ConnectOnWhatsApp from "./ConnectOnWhatsApp";
import DateTimePicker from "./DateTimePicker";
import OrderCardHeader from "./OrderCardHeader";
import OrderListTile from "./OrderListTile";
import OrderRatings from "./OrderRatings";
import OrderTips from "./OrderTips";

function OrderConsultationCard({
	data,
	leadingIcon,
	leadingIconSize,
	showSeeMoreText,
	showActionTitleButton,
	actionTitleButtonText,
	headerTitle,
	showAddNotes,
	addNotesLabel,
	addNotesPlaceholder,
	addNotesActionButtons,
	showDateTimePicker,
	tipsData,
	showWhatsAppButton,
	showStartResumeButton,
	showListTile,
	trailingButtons,
	showMarkAsDoneButton,
	showSeparator,
	showOrderRatings,
	showExpertProfileOnRating,
	enableRating,
}) {
	const [expandCard, setExpandCard] = React.useState(false);

	return (
		<div className="d-flex flex-column align-items-start p-0" style={{ gap: "8px", width: "100%" }}>
			{showSeparator && (
				<div className="d-flex align-items-start justify-content-center p-0" style={{ gap: 10, width: 33, height: 24 }}>
					<div style={{ width: 1, height: 24, background: "#D7DAE0" }} />
				</div>
			)}
			<div className="d-flex flex-column align-items-start p-0" style={{ gap: "8px", width: "100%" }}>
				{data?.date && (
					<div
						style={{
							fontSize: "12px",
							lineHeight: "16px",
							color: "#6E7889",
							letterSpacing: "0.0015em",
						}}
					>
						{data?.date}
					</div>
				)}
				<div
					className="d-flex flex-column align-items-start p-0"
					style={{ width: "100%", border: "1px solid #D7DAE0", borderRadius: "12px" }}
				>
					{/*
        -------------------------------------------------------------------
        COMPONENT TO RENDER HEADING OF A CARD
        -------------------------------------------------------------------
        */}
					<OrderCardHeader
						data={data}
						headerTitle={headerTitle}
						showActionTitleButton={showActionTitleButton}
						actionTitleButtonText={actionTitleButtonText}
						expandCard={expandCard}
						setExpandCard={setExpandCard}
						showMarkAsDoneButton={showMarkAsDoneButton}
					/>
					<div className="d-flex flex-column align-items-start" style={{ padding: "16px", gap: "8px", width: "100%" }}>
						<div className="d-flex align-items-stretch flex-wrap p-0" style={{ gap: "8px", width: "100%" }}>
							<div
								className="d-flex flex-column align-items-start"
								style={{ padding: "0px 8px 0px 0px", gap: "24px", flex: 1 }}
							>
								{/*
							-------------------------------------------------------------------
							COMPONENT TO RENDER A LEADING ICON WITH TITLE & SUB TITLE
							-------------------------------------------------------------------
              */}
								{showListTile && (
									<OrderListTile
										data={data}
										leadingIcon={leadingIcon}
										leadingIconSize={leadingIconSize}
										showSeeMoreText={showSeeMoreText}
										trailingButtons={trailingButtons}
										expandCard={expandCard}
										setExpandCard={setExpandCard}
									/>
								)}
								{/*
							-------------------------------------------------------------------
							COMPONENT TO SHOW ORDER RATINGS
							-------------------------------------------------------------------
              */}
								{showOrderRatings && (
									<OrderRatings
										data={data}
										showExpertProfileOnRating={showExpertProfileOnRating}
										enableRating={enableRating}
									/>
								)}
								{/*
							-------------------------------------------------------------------
							COMPONENT TO SHOW ORDER TIPS
							-------------------------------------------------------------------
              */}
								{tipsData && (
									<OrderTips
										tipsIntroduction={tipsData.tipsIntroduction}
										tipsTitle={tipsData.tipsTitle}
										tips={tipsData.tips}
									/>
								)}
								<div className="d-flex flex-column align-items-start p-0" style={{ gap: "16px", width: "100%" }}>
									{/*
								-------------------------------------------------------------------
								COMPONENT TO ADD NOTES
								-------------------------------------------------------------------
              	*/}
									{showAddNotes && (
										<AddNotes
											data={data}
											addNotesLabel={addNotesLabel}
											addNotesPlaceholder={addNotesPlaceholder}
											addNotesActionButtons={addNotesActionButtons}
										/>
									)}
									{/*
								-------------------------------------------------------------------
								COMPONENT TO SELECT DATE & TIME
								-------------------------------------------------------------------
              	*/}
									{showDateTimePicker && <DateTimePicker />}
									{/*
								-------------------------------------------------------------------
								COMPONENT TO RENDER WHATSAPP BUTTON
								-------------------------------------------------------------------
              	*/}
									{showWhatsAppButton && <ConnectOnWhatsApp />}
									{/*
								-------------------------------------------------------------------
								CONDITIONALLY SHOW BUTTON FOR START CREATING RESUME
								-------------------------------------------------------------------
              	*/}
									{showStartResumeButton && (
										<div
											className="d-flex align-items-center justify-content-center flex-wrap"
											style={{
												padding: "10px 12px",
												background: "#0069CB",
												borderRadius: "6px",
												gap: "8px",
												width: "203px",
											}}
										>
											<div className="mark-review-complete-text-button" style={{ color: "#FFFFFF" }}>
												Start Resume
											</div>
										</div>
									)}
								</div>
							</div>
							{data?.candidateInfo && (
								<CandidateInfo title={data?.candidateInfo?.title} data={data?.candidateInfo?.data} />
							)}
						</div>
						{/*
					-------------------------------------------------------------------
					CONDITIONALLY SHOW HELP TEXT FOR START CREATING RESUME
					-------------------------------------------------------------------
          */}
						{showStartResumeButton && (
							<div
								className="expert-review-navitem-text"
								style={{ fontSize: "12px", lineHeight: "16px", color: "#585F71", letterSpacing: "0.0015em" }}
							>
								(Opens Dproz resume creation tool in a new window).
							</div>
						)}
						{/*
					-------------------------------------------------------------------
					CONDITIONALLY SHOW CANDIDATE NOTES
					-------------------------------------------------------------------
          */}
						{data?.candidateNotes && <CandidateNotes candidateNotes={data?.candidateNotes} />}
					</div>
				</div>
			</div>
		</div>
	);
}

export default OrderConsultationCard;
