import React from "react";
import { Link } from "react-router-dom";

function ResumeReviewsHeader() {
	return (
		<header id="my-resumes" className="tr-header">
			<nav className="navbar navbar-expand-lg">
				<div className="container">
					<div className="navbar-header">
						<button
							className="navbar-toggler"
							type="button"
							data-toggle="collapse"
							data-target="#navbarNav"
							aria-controls="navbarNav"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="navbar-toggler-icon">
								<i className="fa fa-align-justify" />
							</span>
						</button>
						<Link className="navbar-brand" to="/">
							<img className="img-fluid" src="/images/logo.png" alt="Logo" />
						</Link>
					</div>

					<div className="collapse navbar-collapse" id="navbarNav">
						{/* <ul className="nav navbar-nav">
                            <li className="d-lg-inline"><a href="control-panel ">Control Panel</a></li>
                            <li className="d-lg-inline"><a href="my-resumes ">Resumes</a></li>
                            <li className="d-inline"><a href="recommended-jobs "> Job Search </a></li>
                            <li className="d-inline"><a href="preparation-center ">Preparation Center</a></li>
                            <li className="d-inline"><a href="resume-review-dashboard ">Reviews</a></li>
                        </ul> */}
					</div>
					<div className="navbar-right">
						<ul className="d-flex align-items-center fs-20 mb-0 list-unstyled">
							<li className="mr-3">
								<Link to="#" className=" text-primary">
									<span className="fa fa-comments" />
								</Link>
							</li>
							<li className="mr-3">
								<Link className=" text-primary" to="#">
									<span className="fa fa-bell" />
								</Link>
							</li>
						</ul>
						<div className="header-user order-lg-3 order-3">
							<div className="dropdown tr-change-dropdown d-inline-block ">
								<a
									data-toggle="dropdown"
									className="img-holder text-dark d-flex align-items-center font-weight-bold"
									href="#"
									aria-expanded="true"
								>
									<span className="fa fa-user mr-2 fs-20 text-primary" />
									Hi, Jacquiline <span className="fa fa-angle-down" />
								</a>
								<ul className="dropdown-menu tr-change tr-list ">
									{/* <li><a href="settings ">My Settings</a></li>
                                    <li><a href="index ">Log Out</a></li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</header>
	);
}

export default ResumeReviewsHeader;
