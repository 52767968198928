import dayjs from "dayjs";
import React from "react";

import ListItem from "./ListItem";

function ExperienceScoreItem({ data }) {
	return (
		<div>
			{data.match_result.map((experience, index) => (
				<ListItem pct={experience.category_match_pct} key={index}>
					<div className="ml-2">
						<p className="m-0 fw-bold">
							{experience.role}, {experience.company}
						</p>
						<p className="m-0">
							{experience.location.city}, {experience.location.country}
						</p>
						<p className="m-0">
							{dayjs(experience.start_date.year + "-" + experience.start_date.month + "-27").format("MMMM YYYY")}
							{" - "}

							{experience.end_date
								? dayjs(experience.end_date.year + "-" + experience.end_date.month + "-27").format("MMMM YYYY")
								: "Present"}
						</p>
					</div>
				</ListItem>
			))}
			<li className="d-flex justify-content-between p-2 border-bottom">
				<div className="">
					<p className="m-0">Total Years of Experience: {data.years_of_experience.total}</p>
					<p className="m-0">Relevant Years of Experience: {data.years_of_experience.relevant}</p>
				</div>
			</li>
		</div>
	);
}

export default ExperienceScoreItem;
