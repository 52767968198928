const initialState = {
	start: 0,
	end: 0,
	pageNumber: 0,
};

export default function JobsCountReducers(jobsCount = initialState, action) {
	switch (action.type) {
		case "UPDATE_COUNT":
			return action.payload;

		default:
			return jobsCount;
	}
}
