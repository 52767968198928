import React from "react";

function TemplatePremiumNine() {
	return (
		<div style={{ scale: "1.2", transformOrigin: "center" }}>
			<div className="small-resume-preview small-resume-preview-premium-template-9 small-resume-preview-premium-template-5">
				<div className="final-resume-preview bg-white resume-type-1 text-dark">
					<div className="container" style={{ maxWidth: "1000px" }} id="printResume">
						<div className="d-flex mb-2 mt-2">
							<div
								className="border-block"
								style={{
									backgroundColor: "#183042",
									height: "30px",
									flex: "0  0 20px",
									marginRight: "10px",
									marginTop: "5px",
								}}
							>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<div className="row no-gutters">
									<div className="col-6 pr-3">
										<h1 className="mb-0 text-capitalize">Jacquiline Khalfan</h1>
										<h4 className="mb-1" style={{ color: "#458b67", fontWeight: 500 }}>
											Business Development Manager
										</h4>
										<p className="mb-1">
											Professional Business Developer with four year of experience in the business Development
											processes. Involved in product testing, management and development of new business opportunities.
											Professional Business Developer with four year of experience in the business Development
											processes.
										</p>
									</div>
									<div className="col-6">
										<div>
											<p className="mb-1  text-right">
												jacquiline.khalfan@dproz.tz
												<i
													className="ml-2 fa fa-envelope contact-icon"
													style={{ color: "#183042" }}
													aria-hidden="true"
												/>
											</p>
											<p className="mb-1 text-right">
												202-555-0166{" "}
												<i className="ml-2 fa fa-mobile contact-icon" style={{ color: "#183042" }} aria-hidden="true" />
											</p>
											<p className="mb-1 text-right">
												New York, USA
												<i
													className="ml-2 fa fa-map-marker contact-icon"
													style={{ color: "#183042" }}
													aria-hidden="true"
												/>
											</p>
											<p className="mb-1 text-right">
												linkedin.com/in/jacquiline.khalfan
												<i
													className="ml-2 fa fa-linkedin contact-icon"
													style={{ color: "#183042" }}
													aria-hidden="true"
												/>
											</p>
											<p className="mb-1 text-right">
												jacquiline.khalfan
												<i className="ml-2 fa fa-skype contact-icon" style={{ color: "#183042" }} aria-hidden="true" />
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<hr className="mb-2 border-line w-100" style={{ borderTop: "1px solid #458b67" }} />

						<div className="d-flex mb-2">
							<div className="border-block" style={{ flex: "0  0 20px", marginRight: "10px", marginTop: "5px" }}>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<h3 className="text-uppercase">areas of expertise</h3>
								<ul className="text-white mt-1 mb-0 pl-0">
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#458b67" }}
									>
										SEO
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#458b67" }}
									>
										Public Speaking
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#458b67" }}
									>
										Negotiation
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#458b67" }}
									>
										Teamwork
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#458b67" }}
									>
										Decission Making
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#458b67" }}
									>
										Research and Strategy
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#458b67" }}
									>
										Emotional Intelligence
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#458b67" }}
									>
										Outbound Marketingn
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#458b67" }}
									>
										Email Marketing
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#458b67" }}
									>
										Google Analytics
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#458b67" }}
									>
										Sales &amp; Marketing
									</li>
									<li
										className="rounded px-1 mr-1 py-1 d-inline-block font-weight-medium mb-1 skill-tag"
										style={{ backgroundColor: "#458b67" }}
									>
										Social Media Advertising
									</li>
								</ul>
							</div>
						</div>

						<div className="mb-2">
							<div className="d-flex">
								<div className="border-block-space" style={{ flex: "0 0 20px", marginRight: "10px", marginTop: "5px" }}>
									&nbsp;
								</div>
								<div style={{ flexGrow: 1 }}>
									<h3 className="text-uppercase">WORK EXPERIENCE</h3>
								</div>
							</div>
							<div className="d-flex">
								<div
									className="border-block"
									style={{
										backgroundColor: "#458b67",
										height: "30px",
										flex: "0 0 20px",
										marginRight: "10px",
										marginTop: "5px",
									}}
								>
									&nbsp;
								</div>
								<div style={{ flexGrow: 1 }}>
									<div className="mb-2">
										<h4 className="mb-1">Business Analyst</h4>
										<h5 className="mb-1">Proxi Solutions</h5>
										<div
											className="d-flex align-items-center justify-content-between font-italic"
											style={{ color: "#458b67" }}
										>
											<p className="mb-1">09/2017 - Present</p>
											<p className="mb-1">New York, USA</p>
										</div>
										<div
											className="d-flex align-items-center justify-content-between font-italic"
											style={{ color: "#458b67" }}
										>
											<p className="mb-2">Achievements</p>
										</div>
										<ul className="p-0 mb-0" style={{ listStyle: "none" }}>
											<li className="d-flex align-items-start">
												<span
													style={{
														fontWeight: 500,
														flex: "0 0 15px",
														fontSize: "5px",
														marginTop: "5px",
														color: "#458b67",
													}}
												>
													<span className="fa fa-circle" />
												</span>
												Successfully managed $2 - 3 million budget projects and successfully achieved the project
												scheduled goals.
											</li>
											<li className="d-flex align-items-start">
												<span
													style={{
														fontWeight: 500,
														flex: "0 0 15px",
														fontSize: "5px",
														marginTop: "5px",
														color: "#458b67",
													}}
												>
													<span className="fa fa-circle" />
												</span>
												Developed and implemented new marketing and sales plans and defined the strategy for the next 5
												years.
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						<div className="d-flex mb-2">
							<div className="border-block" style={{ flex: "0 0 20px", marginRight: "10px", marginTop: "5px" }}>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<h3 className="text-uppercase">CERTIFICATES </h3>
								<div className="row no-gutters">
									<div className="col-6 mb-1 px-1">
										<h5 style={{ fontWeight: 400 }}>Microsoft Certified Solutions Developer </h5>
									</div>
									<div className="col-6 mb-1  px-1">
										<h5 style={{ fontWeight: 400 }}> Google Cloud Certified - Associate </h5>
									</div>
									<div className="col-6 mb-1 px-1">
										<h5 style={{ fontWeight: 400 }}> AWS Certified (Architecture) </h5>
									</div>
									<div className="col-6 mb-1 px-1">
										<h5 style={{ fontWeight: 400 }}>AWS Certified SysOps Admin - Associate </h5>
									</div>
								</div>
							</div>
						</div>

						<div className="d-flex mb-2">
							<div className="border-block" style={{ flex: "0 0 20px", marginRight: "10px", marginTop: "5px" }}>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<h3 className="text-uppercase">AWARDS </h3>
								<div className="row no-gutters">
									<div className="col-6 px-1 mb-2">
										<h5 style={{ fontWeight: 400 }}>Jury Member, Venture Cup Entrepreneurship Competition (2016)</h5>
										<p className="mb-0" style={{ color: "#bcbcbc" }}>
											<em>Venture Cup USA</em>
										</p>
									</div>
									<div className="col-6 px-1 mb-2">
										<h5 style={{ fontWeight: 400 }}>Sales Individual &amp; Business Development Award (2015)</h5>
										<p className="mb-0" style={{ color: "#bcbcbc" }}>
											<em>AirState Business Awards</em>
										</p>
									</div>
								</div>
							</div>
						</div>

						<div className="d-flex mb-2">
							<div className="border-block" style={{ flex: "0 0 20px", marginRight: "10px", marginTop: "5px" }}>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<h3 className="text-uppercase">PROFESSIONAL ORGANIZATIONS</h3>
								<div className="row no-gutters">
									<div className="col-6 mb-1">
										<h5 style={{ fontWeight: 400 }}>The Business Analyst Club of New York (2018 - Present)</h5>
									</div>
									<div className="col-6 mb-1">
										<h5 style={{ fontWeight: 400 }}> The Association of Work Process Improvement (2016- Present)</h5>
									</div>
								</div>
							</div>
						</div>

						<div className="d-flex mb-2">
							<div className="border-block" style={{ flex: "0 0 20px", marginRight: "10px", marginTop: "5px" }}>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<h3 className="text-uppercase">CONFERENCES &amp; COURSES</h3>
								<div className="row no-gutters">
									<div className="col-6 mb-1">
										<h5 style={{ fontWeight: 400 }}>Leading People and Teams Specialization (03/2016 - 10/2016)</h5>
										<p className="mb-0" style={{ color: "#bcbcbc" }}>
											<em>Online course on Coursera.org </em>
										</p>
									</div>
									<div className="col-6 mb-1">
										<h5 style={{ fontWeight: 400 }}>eMetrics Summit (09/2016)</h5>
										<p className="mb-0" style={{ color: "#bcbcbc" }}>
											<em>Rising Media, Inc. and eMetrics Summit</em>
										</p>
									</div>
								</div>
							</div>
						</div>

						<div className=" mb-2">
							<div className="d-flex">
								<div className="border-block-space" style={{ flex: "0 0 20px", marginRight: "10px", marginTop: "5px" }}>
									&nbsp;
								</div>
								<div style={{ flexGrow: 1 }}>
									<h3 className="text-uppercase">EDUCATION</h3>
								</div>
							</div>
							<div className="d-flex">
								<div
									className="border-block"
									style={{
										backgroundColor: "#458b67",
										height: "30px",
										flex: "0 0 20px",
										marginRight: "10px",
										marginTop: "5px",
									}}
								>
									&nbsp;
								</div>
								<div style={{ flexGrow: 1 }}>
									<div className="mb-2">
										<h4 className=" mb-2">MSc in Economics and Business Administration</h4>
										<h5 className="mb-1">The University of Chicago</h5>
										<div
											className="d-flex align-items-center justify-content-between font-italic"
											style={{ color: "#458b67" }}
										>
											<p className="mb-1">09/2008 - 06/2010 </p>
											<p className="mb-1">New York, USA</p>
										</div>
										<div
											className="d-flex align-items-center justify-content-between font-italic"
											style={{ color: "#458b67" }}
										>
											<p className="mb-2">Thesis:</p>
										</div>
										<ul className="p-0 mb-0" style={{ listStyle: "none" }}>
											<li className="d-flex align-items-start">
												<span
													style={{
														fontWeight: 500,
														flex: "0 0 20px",
														fontSize: "5px",
														marginTop: "5px",
														color: "#458b67",
													}}
												>
													<span className="fa fa-circle" />
												</span>{" "}
												How have direct marketing theories been changed by digital and online marketing mediums and how
												has the later revolutionized the present marketing industry?
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						<div className="d-flex mb-2">
							<div className="border-block-space" style={{ flex: "0 0 20px", marginRight: "10px", marginTop: "5px" }}>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<h3 className="text-uppercase">LANGUAGES </h3>

								<ul
									className="px-2 px-3 d-flex align-items-center justify-content-around"
									style={{ listStyle: "none" }}
								>
									<li className="text-center px-2">
										<span
											className="language-indicator"
											style={{
												display: "inline-block",
												width: "20px",
												height: "30px",
												backgroundColor: "#458b67",
												lineHeight: 1,
											}}
										>
											<span className="w-100 d-inline-block" style={{ height: "100%", backgroundColor: "#78c49d" }} />
										</span>
										<span className="language-name d-block">English</span>
									</li>
									<li className="text-center px-2">
										<span
											className="language-indicator"
											style={{
												display: "inline-block",
												width: "20px",
												height: "30px",
												backgroundColor: "#458b67",
												lineHeight: 1,
											}}
										>
											<span className="w-100 d-inline-block" style={{ height: "100%", backgroundColor: "#78c49d" }} />
										</span>
										<span className="language-name d-block">Mandarin</span>
									</li>
									<li className="text-center px-2">
										<span
											className="language-indicator"
											style={{
												display: "inline-block",
												width: "20px",
												height: "30px",
												backgroundColor: "#458b67",
												lineHeight: 1,
											}}
										>
											<span className="w-100 d-inline-block" style={{ height: "50%", backgroundColor: "#78c49d" }} />
										</span>
										<span className="language-name d-block">Spanish</span>
									</li>
									<li className="text-center px-2">
										<span
											className="language-indicator"
											style={{
												display: "inline-block",
												width: "20px",
												height: "30px",
												backgroundColor: "#458b67",
												lineHeight: 1,
											}}
										>
											<span className="w-100 d-inline-block" style={{ height: "50%", backgroundColor: "#78c49d" }} />
										</span>
										<span className="language-name d-block">German</span>
									</li>
									<li className="text-center px-2">
										<span
											className="language-indicator"
											style={{
												display: "inline-block",
												width: "20px",
												height: "30px",
												backgroundColor: "#458b67",
												lineHeight: 1,
											}}
										>
											<span className="w-100 d-inline-block" style={{ height: "50%", backgroundColor: "#78c49d" }} />
										</span>
										<span className="language-name d-block">French</span>
									</li>
								</ul>
							</div>
						</div>

						<div className="d-flex mb-3 mb-lg-4">
							<div className="border-block-space" style={{ flex: "0 0 20px", marginRight: "15px", marginTop: "10px" }}>
								&nbsp;
							</div>
							<div style={{ flexGrow: 1 }}>
								<h3 className="text-uppercase">Interests </h3>

								<ul className="px-0 d-flex align-items-center justify-content-around" style={{ listStyle: "none" }}>
									<li className="px-2">Typography</li>
									<li className="px-2">Archery</li>
									<li className="px-2">chess</li>
									<li className="px-2">Sustainability</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="preview text-center mt-4 mb-4">
				<button
					className="btn text-primary btn-sm btn-outline-primary"
					data-toggle="modal"
					data-target="#ResumePreview"
				>
					<span className="fa fa-eye" /> Preview
				</button>
			</div>
		</div>
	);
}

export default TemplatePremiumNine;
