const preview_resume_data = {
	selectedTemplate: "",
	// THIS IS THE INITIAL OBJECT THAT IS LOADED TO THE
	// CONTEXT BEFORE UPDATING IT BY SERVER FETCHING
	// DONT CHANGE IT::: IF YOU CHANGE IT YOU HAVE ALSO TO CHANGE
	// OTHER CODES LIKE::: IN HEADING>> SUBMITTING DATA FUNCTION
	// THAT PART CHECKS IF ITS THIS OBJECT SO AS TO OUTOFILL CONFIRMATION
	// ON USEEFFECT
	contactInfo: {
		firstName: "",
		middleName: "",
		lastName: "",
		phoneNo: "",
		email: "",
		city: "",
		country: "Tanzania",
		region: "",
		resumeTitle: "",
		resumeFunction: "",
		legalNameToSAcknowledged: false,
	},
	workHistory: [
		{
			jobTitle: "",
			employer: "",
			city: "",
			country: "",
			startDate: "",
			endDate: "",
			details: "",
			currentEmployer: false,
			relevantSkills: "",
		},
	],
	activeWorkHistoryIndex: 0,
	education: [],
	activeEducationIndex: 0,
	skills: "",
	summary: "",
	extras: {
		accomplishments: "",
		affiliations: "",
		personalLinks: [],
		certifications: "",
		additionalInfo: "",
		languages: [],
		references: [],
		activeReferencesIndex: 0,
		personalProfile: {
			gender: "",
			maritalStatus: "",
			dateOfBirth: "",
		},
		customExtras: {
			name: "",
			data: "",
		},
	},
	progressStatus: {
		EDUCATION: "",
		EXPERIENCE: "",
		HEADING: "",
		SKILLS: "",
		SUMMARY: "",
	},
};

export default preview_resume_data;
