const initialState = {
	search: true,
	myJobs: false,
	recommendations: false,
};

export default function JobsTabsReducers(tabsState = initialState, action) {
	switch (action.type) {
		case "SET_SEARCH":
			return {
				myJobs: !action.payload,
				search: action.payload,
				recommendations: !action.payload,
			};

		case "SET_MyJOBS":
			return {
				myJobs: action.payload,
				search: !action.payload,
				recommendations: !action.payload,
			};

		case "SET_RECOMMENDATION":
			return {
				myJobs: !action.payload,
				search: !action.payload,
				recommendations: action.payload,
			};

		default:
			return tabsState;
	}
}
