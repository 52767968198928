import ResumeOne from "../../../Views/ResumeBuilder/Review/ResumeOne";
import ResumePremiumEight from "../../../Views/ResumeBuilder/Review/ResumePremiumEight/ResumePremiumEight";
import ResumePremiumFive from "../../../Views/ResumeBuilder/Review/ResumePremiumFive/ResumePremiumFive";
import ResumePremiumFour from "../../../Views/ResumeBuilder/Review/ResumePremiumFour/ResumePremiumFour";
import ResumePremiumNine from "../../../Views/ResumeBuilder/Review/ResumePremiumNine/ResumePremiumNine";
import ResumePremiumOne from "../../../Views/ResumeBuilder/Review/ResumePremiumOne/ResumePremiumOne";
import ResumePremiumSeven from "../../../Views/ResumeBuilder/Review/ResumePremiumSeven/ResumePremiumSeven";
import ResumePremiumSix from "../../../Views/ResumeBuilder/Review/ResumePremiumSix/ResumePremiumSix";
import ResumePremiumTen from "../../../Views/ResumeBuilder/Review/ResumePremiumTen/ResumePremiumTen";
import ResumePremiumThree from "../../../Views/ResumeBuilder/Review/ResumePremiumThree";
import ResumePremiumTwo from "../../../Views/ResumeBuilder/Review/ResumePremiumTwo/ResumePremiumTwo";
import ResumeThree from "../../../Views/ResumeBuilder/Review/ResumeThree";
import ResumeTwo from "../../../Views/ResumeBuilder/Review/ResumeTwo";
import TemplateOne from "./TemplateOne/TemplateOne";
import TemplatePremiumEight from "./TemplatePremiumEight/TemplatePremiumEight";
import TemplatePremiumFive from "./TemplatePremiumFive/TemplatePremiumFive";
import TemplatePremiumFour from "./TemplatePremiumFour/TemplatePremiumFour";
import TemplatePremiumNine from "./TemplatePremiumNine/TemplatePremiumNine";
import TemplatePremiumOne from "./TemplatePremiumOne/TemplatePremiumOne";
import TemplatePremiumSeven from "./TemplatePremiumSeven/TemplatePremiumSeven";
import TemplatePremiumSix from "./TemplatePremiumSix/TemplatePremiumSix";
import TemplatePremiumTen from "./TemplatePremiumTen/TemplatePremiumTen";
import TemplatePremiumThree from "./TemplatePremiumThree/TemplatePremiumThree";
import TemplatePremiumTwo from "./TemplatePremiumTwo/TemplatePremiumTwo";
import TemplateThree from "./TemplateThree/TemplateThree";
import TemplateTwo from "./TemplateTwo/TemplateTwo";

export class TemplateNameToResumeViewMapping {
	constructor() {
		this.templatesToSupportedResumes = [];
		this.initializeMapping();
	}

	initializeMapping() {
		this.templatesToSupportedResumes.push({
			name: "",
			template: TemplateOne,
			review: ResumeOne,
		});

		this.templatesToSupportedResumes.push({
			name: "fresher_template",
			template: TemplateOne,
			review: ResumeOne,
		});
		this.templatesToSupportedResumes.push({
			name: "fresher_template_1",
			template: TemplateTwo,
			review: ResumeTwo,
		});
		this.templatesToSupportedResumes.push({
			name: "template_1",
			template: TemplateThree,
			review: ResumeThree,
		});
		this.templatesToSupportedResumes.push({
			name: "experienced",
			template: TemplateOne,
			resume: ResumeOne,
		});
		this.templatesToSupportedResumes.push({
			name: "premium_template_1",
			template: TemplatePremiumOne,
			review: ResumePremiumOne,
		});
		this.templatesToSupportedResumes.push({
			name: "premium_template_2",
			template: TemplatePremiumTwo,
			review: ResumePremiumTwo,
		});
		this.templatesToSupportedResumes.push({
			name: "premium_template_3",
			template: TemplatePremiumThree,
			review: ResumePremiumThree,
		});
		this.templatesToSupportedResumes.push({
			name: "premium_template_4",
			template: TemplatePremiumFour,
			review: ResumePremiumFour,
		});
		this.templatesToSupportedResumes.push({
			name: "premium_template_5",
			template: TemplatePremiumFive,
			review: ResumePremiumFive,
		});
		this.templatesToSupportedResumes.push({
			name: "premium_template_6",
			template: TemplatePremiumSix,
			review: ResumePremiumSix,
		});
		this.templatesToSupportedResumes.push({
			name: "premium_template_7",
			template: TemplatePremiumSeven,
			review: ResumePremiumSeven,
		});
		this.templatesToSupportedResumes.push({
			name: "premium_template_8",
			template: TemplatePremiumEight,
			review: ResumePremiumEight,
		});
		this.templatesToSupportedResumes.push({
			name: "premium_template_9",
			template: TemplatePremiumNine,
			review: ResumePremiumNine,
		});
		this.templatesToSupportedResumes.push({
			name: "premium_template_10",
			template: TemplatePremiumTen,
			review: ResumePremiumTen,
		});
	}
}
