import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Spinner from "../../components/Spinner";
import { getTemplateName } from "../../handlers/utilityFunctions";
import { LevelsOfExperience } from "../../models/resume/template";
import mapping from "../../seed/metadata/filemapping";
import { NavigationFlow, NavigationList } from "../../seed/navigation";
import { ResumeDataService } from "../../services/create-resume/resume-data";

function SelectResumeTemplate() {
	const premiumTemplatesSettings = mapping["Premium Templates Configurations"][0];
	const [showPremiumTemplate] = React.useState(premiumTemplatesSettings.showPremiumTemplates);
	const [selectedTemplateImage, setSelectedTemplateImage] = React.useState("");
	const [showSelectedTemplateImage, setShowSelectedTemplateImage] = React.useState(false);
	const urlParams = new URLSearchParams(window.location.search);
	let templateId = urlParams.get("templateId");
	let journalReferenceId = urlParams.get("journalReferenceId");
	let templateName = "";
	const navigate = useNavigate();
	const template = useSelector((state) => state.TemplateSelectionReducer);
	const leveOfExperience = template.selectedTemplate;
	const levelsObject = new LevelsOfExperience();
	const listOfExperience = levelsObject.listOfExperience;

	const selectedExperience = listOfExperience.find((e) => {
		return e.name === leveOfExperience.yearsOfExperience;
	});

	const uniqueListOfExperiences = [...new Set(listOfExperience.flatMap((ex) => ex.templateList))];

	const premiumTemplatesList = [...new Set(listOfExperience.flatMap((ex) => ex.premiumTemplateList))];

	const navigationFlow = new NavigationFlow();

	const fresherFlow = navigationFlow.fresherFlow;
	const experiencedFlow = navigationFlow.experiencedFlow;
	const noExperienceList = new NavigationList(fresherFlow, fresherFlow);
	const experiencedList = new NavigationList(experiencedFlow, experiencedFlow);
	const templateNavigation =
		leveOfExperience.yearsOfExperience === "No Experience" ? noExperienceList : experiencedList;

	const dispatch = useDispatch();

	if (selectedExperience.templateList.length === 0) {
		//to be implemented somewhere else
	}

	if (templateId) {
		templateName = getTemplateName(templateId);
	}

	function swapTabs(activeTab, activeContent) {
		document.getElementById("all-select").classList.remove("active");
		document.getElementById("recommended-select").classList.remove("active");
		document.getElementById("premium-select").classList.remove("active");
		document.getElementById("All").classList.remove("active");
		document.getElementById("Recommended").classList.remove("active");
		document.getElementById("Premium").classList.remove("active");

		document.getElementById(activeTab).classList.add("active");
		document.getElementById(activeContent).classList.add("active");
	}

	function onSelectTemplate(selectedTemplateName) {
		localStorage.setItem(
			"templateName",
			selectedTemplateName === "experienced" ? "fresher_template" : selectedTemplateName,
		);
		dispatch({
			type: "CHANGE_TEMPLATE_DETAILS",
			payload: {
				selectedTemplate: {
					yearsOfExperience: leveOfExperience.yearsOfExperience,
					templateName: selectedTemplateName === "experienced" ? "fresher_template" : selectedTemplateName,
				},
				navigationFlow: templateNavigation,
			},
		});
		navigate("/resume/contact-information");
		// reload
		window.location.reload();
	}

	const onViewTemplate = (event, selectedTemplateName) => {
		event.stopPropagation();
		setSelectedTemplateImage(selectedTemplateName);
		setShowSelectedTemplateImage(true);
	};

	const [isLoading, setIsLoading] = React.useState(false);
	function onChangeTemplate(selectedTemplateName) {
		setIsLoading(true);
		const resumeDataService = new ResumeDataService();
		resumeDataService.changeTemplateName(
			{
				journaReferenceId: journalReferenceId,
				templateId: selectedTemplateName,
			},
			() => {
				setIsLoading(false);
				navigate("/resume/review");
				window.location.reload();
			},
			(error) => {
				setIsLoading(false);
				toast.error(error.response.data.message);
			},
		);
	}

	function tabClick(selectId, contentId) {
		//re-implementation to suit single page application
		//this function was implaemented in Jquery and was applied to index page but these Dom elements were not leaded yet, so it will only work on refresh and not normal route
		swapTabs(selectId, contentId, false);
	}

	return (
		<div className="select-template py-4 py-md-5">
			<div className="container">
				<div className="section-title text-center mb-4 text-dark">
					<h2 className="text-uppercase">Choose from our resume templates</h2>
				</div>

				<div className="custom-tab-container">
					<ul className="tabs-list">
						<li id="all-select" data-target="#All" onClick={() => tabClick("all-select", "All")}>
							All
						</li>
						<li
							id="recommended-select"
							className="active"
							data-target="#Recommended"
							onClick={() => tabClick("recommended-select", "Recommended")}
						>
							Recommended
						</li>
						<li id="premium-select" data-target="#Premium" onClick={() => tabClick("premium-select", "Premium")}>
							Premium
						</li>
					</ul>

					<div className="tab-content" id="All">
						<div className="select-template-inner position-relative">
							<div className="templates-list">
								<div className="row justify-content-center">
									{uniqueListOfExperiences.map((recommended, index) => (
										<div
											key={index}
											className="col-md-4 col-sm-6"
											onClick={() => {
												templateId
													? onChangeTemplate(recommended.templateName)
													: onSelectTemplate(recommended.templateName);
											}}
										>
											<div
												className={
													templateName === recommended.templateName
														? "template my-3 position-relative border border-primary"
														: "template my-3 position-relative"
												}
											>
												<img
													// make path configurable
													src={"../images/resume-template/" + recommended.templateIcon}
													alt=""
												/>

												<div className="overlay position-absolute d-flex align-items-center justify-content-center">
													<button
														className="btn-zoom"
														onClick={(event) => {
															onViewTemplate(event, recommended.templateIcon);
														}}
													>
														<span className="fa fa-search-plus" />
													</button>
													<button className="btn btn-primary">
														{isLoading ? <Spinner /> : <>{templateId ? "Use Template" : "Select Template"}</>}
													</button>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>

					<div className="tab-content active" id="Recommended">
						<div className="select-template-inner position-relative">
							<div className="templates-list">
								<div className="row justify-content-center">
									{selectedExperience.templateList.map((recommended, index) => (
										<div
											key={index}
											className="col-md-4 col-sm-6"
											onClick={() => {
												templateId
													? onChangeTemplate(recommended.templateName)
													: onSelectTemplate(recommended.templateName);
											}}
										>
											<div
												className={
													templateName === recommended.templateName
														? "template my-3 position-relative border border-primary"
														: "template my-3 position-relative"
												}
											>
												<img
													// make path configurable
													src={"../images/resume-template/" + recommended.templateIcon}
													alt=""
												/>

												<div className="overlay position-absolute d-flex align-items-center justify-content-center">
													<button
														className="btn-zoom"
														onClick={(event) => {
															onViewTemplate(event, recommended.templateIcon);
														}}
													>
														<span className="fa fa-search-plus" />
													</button>
													<button className="btn btn-primary">
														{isLoading ? <Spinner /> : <>{templateId ? "Use Template" : "Select Template"}</>}
													</button>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>

					<div className="tab-content" id="Premium">
						{showPremiumTemplate ? (
							<div className="select-template-inner position-relative">
								<div className="templates-list">
									<div className="row justify-content-center">
										{premiumTemplatesList.map((premium, index) => (
											<div
												key={index}
												className="col-md-4 col-sm-6"
												onClick={() => {
													templateId ? onChangeTemplate(premium.templateName) : onSelectTemplate(premium.templateName);
												}}
											>
												<div
													className={
														templateName === premium.templateName
															? "template my-3 position-relative border border-primary"
															: "template my-3 position-relative"
													}
												>
													<img
														// make path configurable
														src={"../images/resume-template/" + premium.templateIcon}
														alt=""
													/>

													<div className="overlay position-absolute d-flex align-items-center justify-content-center">
														<button
															className="btn-zoom"
															onClick={(event) => {
																onViewTemplate(event, premium.templateIcon);
															}}
														>
															<span className="fa fa-search-plus" />
														</button>
														<button className="btn btn-primary">
															{isLoading ? <Spinner /> : <>{templateId ? "Use Template" : "Select Template"}</>}
														</button>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						) : (
							<p className="text-center h4">Coming soon</p>
						)}
					</div>
				</div>
			</div>

			{/* Modal */}
			{showSelectedTemplateImage && (
				<div
					className="modal fade"
					style={{
						display: "grid",
						opacity: "1",
						backgroundColor: "rgb(0 0 0 / 60%)",
						overflow: "scroll",
						placeItems: "center",
					}}
				>
					<div className="modal-dialog modal-lg pt-5">
						<div className="modal-content">
							<div className="modal-header">
								<button
									type="button"
									className="close text-primary"
									data-dismiss="modal"
									aria-label="Close"
									onClick={() => setShowSelectedTemplateImage(false)}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<img src={"../images/resume-template/" + selectedTemplateImage} alt="" />
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default SelectResumeTemplate;
