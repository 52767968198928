const Jobs = () => {
	return {
		jobsCount: 2,
		pagesCount: 4,
		pageNumber: 1,
		jobs: [
			{
				_id: "641e573dd8a362c3ff5fed20",
				jobTitle: "Strategy Consultant",
				startingDate: {
					day: 1,
					month: 3,
					year: 2023,
				},
				deadlineDate: {
					day: 5,
					month: 4,
					year: 2023,
				},
				numberOfOpening: 1,
				jobStreetAddress: "Mirambo Road, Posta 2nd Floor, Unit #308",
				jobLocation: {
					city: "Dar",
					region: "Dar",
					country: "Tanzania",
				},
				remoteWorkSupported: "Yes",
				employmentType: "Full-Time",
				jobExpertise: "Information Technology",
				jobDescription: {
					lookingToHire: "Background info goes here",
					responsibilities: [
						{
							value: "responsibility #1",
							bp: true,
						},
						{
							value: "responsibility #2",
							bp: true,
						},
					],
					qualifications: [
						{
							value: "qualifications 1",
							bp: true,
						},
						{
							value: "qualifications 2",
							bp: true,
						},
					],
				},
				salary: {
					payOption: "Range",
					minPay: 900,
					maxPay: 10000,
					payCurrency: "TZS",
					payFrequency: "Monthly",
				},
				jobStatus: "Posted",
				lastUpdatedAt: "2023-04-01T23:57:05.596Z",
				company: {
					_id: "64164dddb7c82aa7bc803133",
					companyName: "TE Ventures Inc.",
					companyWebsite: "www.dproz.com",
					companyDescription: "Connecting professionals with the right opportunities in Tanzania",
					whyWorkAtCompany: "Great CEO, Challenging but exciting opportunity for career growth",
					phoneNumber: "+255755807568",
					location: {
						city: "Dar",
						region: "Dar",
						country: "Tanzania",
					},
					createdAt: "2023-03-18T23:48:45.283Z",
				},
			},
			{
				_id: "6428ad1b12a7170f7a23bd6b",
				jobTitle: "Software Engineer",
				startingDate: {
					day: 1,
					month: 3,
					year: 2023,
				},
				deadlineDate: {
					day: 5,
					month: 4,
					year: 2023,
				},
				numberOfOpening: 1,
				jobStreetAddress: "Tegata Road, Posta 2nd Floor, Unit #308",
				jobLocation: {
					city: "Dar",
					region: "Dar",
					country: "Tanzania",
				},
				remoteWorkSupported: "Yes",
				employmentType: "Full-Time",
				jobExpertise: "Information Technology",
				jobDescription: {
					lookingToHire: "background info goes here",
					responsibilities: [
						{
							value: "responsibility #1",
							bp: true,
						},
						{
							value: "responsibility #2",
							bp: true,
						},
					],
					qualifications: [
						{
							value: "qualifications 1",
							bp: true,
						},
						{
							value: "qualifications 2",
							bp: true,
						},
					],
				},
				salary: {
					payOption: "Range",
					minPay: 900,
					maxPay: 10000,
					payCurrency: "TZS",
					payFrequency: "Monthly",
				},
				jobStatus: "Posted",
				lastUpdatedAt: "2023-04-01T23:58:42.265Z",
				company: {
					_id: "64164dddb7c82aa7bc803133",
					companyName: "TE Ventures Inc.",
					companyWebsite: "www.dproz.com",
					companyDescription: "Connecting professionals with the right opportunities in Tanzania",
					whyWorkAtCompany: "Great CEO, Challenging but exciting opportunity for career growth",
					phoneNumber: "+255755807568",
					location: {
						city: "Dar",
						region: "Dar",
						country: "Tanzania",
					},
					createdAt: "2023-03-18T23:48:45.283Z",
				},
			},
		],
	};
};

export default Jobs();
