import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import tips from "../../../../assets/copy/tips";
import ErrorToast from "../../../../components/ErrorToast";
// import LanguageList from "../../../../seed/langauages";
import Spinner from "../../../../components/Spinner";
import Tips from "../../../../components/Tips";
import ResumeContext from "../../../../context/ResumeContext";
import useHasChange from "../../../../hooks/use-HasChange";
import { Language } from "../../../../models/resume/language";
import { StaticValue } from "../../../../seed/constants";
import mapping from "../../../../seed/metadata/filemapping";
import { ResumeDataService } from "../../../../services/create-resume/resume-data";
import LanguageItem from "./components/LanguageItem";

const Languages = () => {
	const [cookies] = useCookies(["resume"]);
	const navigate = useNavigate();
	const languages = mapping["languages"][0].languages;
	const dispatch = useDispatch();

	const staticValue = new StaticValue();
	const { hasChanges } = useHasChange();
	const staticValues = staticValue.getDeafaulValues();
	const resumeDataService = new ResumeDataService();

	const { resumeData, setResumeData } = useContext(ResumeContext);
	const [loading, setLoading] = useState(false);
	const [serverErrors, setServerErrors] = useState([]);

	const [showUnsavedChangesWarning, setShowUnsavedChangesWarning] = useState(false);

	const [userLanguages, setUserLanguages] = useState([
		{
			language: "",
			proficiency: "",
		},
	]);

	const updateLanguagesData = (index, data) => {
		const languages = userLanguages;
		languages[index] = data;
		setUserLanguages(languages);
	};

	useEffect(() => {
		setUserLanguages(resumeData.extras.languages);
	}, [resumeData]);

	const onPrev = () => {
		const languages = resumeData.extras.languages.filter(
			(item1) => !userLanguages.some((item2) => Object.keys(item1).every((key) => item2[key] === item1[key])),
		);
		setResumeData((prevState) => {
			return {
				...prevState,
				extras: {
					...prevState.extras,
					languages: languages,
				},
			};
		});

		navigate("/resume/extras");
	};

	const onNext = () => {
		const langPayload = new Language(userLanguages);
		const langReq = langPayload.getLanguageRequest();

		const resumeId = cookies[staticValues.localStorageResumeId];

		const section = "LANGUAGES";

		if (hasChanges(section, langReq)) {
			setLoading(true);
			resumeDataService.patchResumeLedger(resumeId, langReq, section, successPatchLanguage, errorPatchLanguage);
		} else {
			navigate("/resume/extras");
		}
	};

	function successPatchLanguage(data) {
		setLoading(false);
		const response = data.data;

		dispatch({ type: "RESUME_DATA", payload: response });

		navigate("/resume/extras");
	}

	function errorPatchLanguage(data) {
		setLoading(false);
		const serverResponse = data.response.data;
		Array.isArray(serverResponse.message)
			? setServerErrors((prev) => {
					return [...prev, ...serverResponse.message];
				})
			: setServerErrors((prev) => {
					return [...prev, serverResponse.message];
				});
	}

	const deleteLangHandler = (index) => {
		const languages = userLanguages.filter((lang, i) => i !== index);
		setResumeData((prevState) => {
			return {
				...prevState,
				extras: {
					...prevState.extras,
					languages: languages,
				},
			};
		});

		setUserLanguages(languages);
	};

	return (
		<>
			{showUnsavedChangesWarning && (
				<div
					className="modal fade"
					style={{
						display: "grid",
						opacity: "1",
						backgroundColor: "rgb(0 0 0 / 60%)",
						overflow: "visible",
						placeItems: "center",
					}}
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title tr-title mb-0">You have unsaved changes</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
									onClick={() => setShowUnsavedChangesWarning(false)}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body pb-5">
								<p>Are you sure you want to exit without save new changes?</p>

								<div className="d-flex justify-content-end">
									<button
										type="button"
										className="btn btn-secondary mr-2"
										data-dismiss="modal"
										onClick={() => setShowUnsavedChangesWarning(false)}
									>
										Cancel
									</button>

									<button type="button" className="btn btn-primary" onClick={onPrev}>
										Exit
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			<div className="container min-vh-100">
				<div className="section languages-information">
					{serverErrors.map((item, index) => {
						return <ErrorToast error={item} key={index} />;
					})}

					<p className="mb-3 text-medium fs-14">List the languages you speak and your proficiency level in each.</p>
					<div className="form-wrapper">
						<div className="row">
							<div className="col-lg-8">
								<div className="d-flex mb-3 justify-content-end">
									<Tips content={tips.languages} />
								</div>
								{userLanguages.map((item, index) => (
									<LanguageItem
										item={item}
										key={index}
										languages={languages}
										index={index}
										updateLanguagesData={updateLanguagesData}
										deleteLanguage={deleteLangHandler}
									/>
								))}

								<button
									className="text-primary font-weight-semi mr-2 mb-4 pl-0"
									onClick={() => {
										setUserLanguages([...userLanguages, { language: "", proficiency: "" }]);
									}}
								>
									<i className="fa fa-plus" /> Add another language
								</button>

								<div className="action d-flex align-items-center justify-content-end">
									<button
										name="back"
										onClick={() => {
											userLanguages.length === 0 ? onNext() : setShowUnsavedChangesWarning(true);
										}}
										className="text-primary font-weight-semi mr-2 pl-0"
									>
										Skip for now
									</button>
									{userLanguages.length > 0 && (
										<button onClick={onNext} name="next" className="btn btn-primary mr-2">
											{loading ? (
												<>
													Loading <Spinner />
												</>
											) : (
												"Continue"
											)}
										</button>
									)}
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-12 mt-5" />
					</div>
				</div>
			</div>
		</>
	);
};

export default Languages;
