import React, { useState } from "react";

import Input from "../../../../components/Input";
import { getInitials } from "../../../../handlers/utilityFunctions";

function Profile({ data, submitData }) {
	const [email, setEmail] = useState(data.email);
	const [password, setPassword] = useState({
		oldPassword: data.oldPassword,
		newPassword: data.newPassword,
	});
	const [contact, setContact] = useState({
		firstName: data.firstName,
		lastName: data.lastName,
		city: data.city,
		country: data.country,
		pincode: data.pincode,
		phone: data.phone,
	});

	const onChangeHandler = (event) => {
		if (event.target.name === "email") {
			setEmail(event.target.value);
		} else if (["oldPassword", "newPassword"].includes(event.target.name)) {
			setPassword((prevState) => {
				return {
					...prevState,
					[event.target.name]: event.target.value,
				};
			});
		} else {
			setContact((prevState) => {
				return {
					...prevState,
					[event.target.name]: event.target.value,
				};
			});
		}
	};

	const onSubmitHandler = () => {
		const payload = {
			...password,
			...contact,
			email,
		};
		submitData(payload);
	};

	return (
		<>
			<div className="text-lg font-bold text-gray-600">Picture</div>
			<div className="mt-4 flex space-x-6" style={{ maxWidth: 515 }}>
				<div className="flex h-16 w-16 items-center justify-center rounded bg-blue-600 text-2xl text-white">
					{getInitials(`${contact.firstName} ${contact.lastName}`)}
				</div>
				<div className="flex flex-col justify-between flex-1">
					<div className="flex space-x-4 w-full">
						<div className="flex h-8 w-full cursor-pointer items-center rounded border border-gray-200 px-4 hover:bg-gray-100">
							<i className="bi bi-upload mr-3" />
							<span className="text-sm font-medium text-gray-500">Upload</span>
						</div>
						<div className="flex h-8 w-full cursor-pointer items-center rounded border border-gray-200 px-4 hover:bg-gray-100">
							<i className="bi bi-trash mr-3" />
							<span className="text-sm font-medium text-gray-500">Remove</span>
						</div>
					</div>
					<div className="text-sm text-gray-400">We support your best PNGs, JPEGs and GIFs portraits under 10MB</div>
				</div>
			</div>
			<div className="mt-4 flex flex-col space-y-2">
				<div className="text-lg font-bold text-gray-600">Name</div>
				<div className="text-sm text-gray-400">Your name as it will be displayed</div>
				<div className="flex space-x-8" style={{ maxWidth: 515 }}>
					<Input label="First Name" placeholder={contact.firstName} error="" />
					<Input label="Last Name" placeholder={contact.lastName} error="" />
				</div>
			</div>
			<div className="mt-4 flex flex-col space-y-2">
				<div className="text-lg font-bold text-gray-600">Email</div>
				<div className="text-sm text-gray-400">The email associated to your account</div>
				<div className="flex space-x-8" style={{ maxWidth: 515 }}>
					<Input disabled label="" placeholder={email} error="" />
				</div>
			</div>
			<div className="mt-4 flex flex-col space-y-2">
				<div className="text-lg font-bold text-gray-600">Change Password</div>
				<div className="text-sm text-gray-400">Receive an email containing password update link</div>
				<div
					className="flex w-32 cursor-pointer items-center justify-center rounded border border-gray-300 py-1 text-sm text-gray-700 hover:bg-gray-200"
					data-toggle="modal"
					data-target="#ChangePasswordModal"
				>
					Change Password
				</div>
			</div>
			<div className="mt-4 flex flex-col space-y-2">
				<div className="text-lg font-bold text-gray-600">Danger zone</div>
				<div className="text-sm text-gray-400">Delete account and all the associated data</div>
				<div className="flex w-32 cursor-pointer items-center justify-center rounded border border-error-default py-1 text-sm text-error-default hover:bg-data-viz-1">
					Delete account
				</div>
			</div>

			{/* Edit Password Modal */}
			<div
				className="modal fade"
				id="ChangePasswordModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="ChangePasswordModal"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "375px" }} role="document">
					<div className="modal-content px-4">
						<div className="modal-header border-0">
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body pb-0">
							<p className="text-primary mb-4 text-lg">Change Password </p>
							<form action="">
								<div className="form-group">
									<input
										type="password"
										name="oldPassword"
										value={password.oldPassword}
										onChange={onChangeHandler}
										className="form-control mb-0"
										placeholder="Enter Old Password"
									/>
								</div>
								<div className="form-group ">
									<input
										type="password"
										name="newPassword"
										value={password.newPassword}
										onChange={onChangeHandler}
										className="form-control mb-0"
										placeholder="Enter New Password"
									/>
								</div>
							</form>
						</div>
						<div className="modal-footer border-0 d-flex justify-content-center pt-0">
							<button
								type="button"
								name="password"
								onClick={onSubmitHandler}
								data-dismiss="modal"
								aria-label="Close"
								className="btn btn-primary  px-5"
							>
								save
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Profile;
