import React from "react";
import { useNavigate } from "react-router-dom";

import {
	capitalizeFirstLetterOfWords,
	currencyFormat,
	formatAPIDate,
	getRewardPointTextColor,
} from "../../handlers/utilityFunctions";
import CircularProgressWithLabel from "./CircularProgressWithLabel";

function RewardsTableBody({
	rewards,
	percentageFilter,
	setPercentageFilter,
	rewardStatus,
	setRewardStatus,
	activityType,
	setActivityType,
	handleFilter,
	page,
	rowsPerPage,
	claimPaymentReward,
	submitting,
	shareString,
}) {
	const navigate = useNavigate();

	return (
		<tbody>
			{rewards.length === 0 ? (
				<tr>
					<th colSpan={6} className="text-center">
						No Rewards!
					</th>
				</tr>
			) : (
				<>
					<tr>
						<td colSpan={2}>
							<div className="d-lg-flex">
								<div
									className="my-lg-auto border rounded"
									style={{
										width: "300px",
									}}
								>
									<div className="dropdown">
										<button
											className="btn btn-block btn-sm text-dark bg-white border rounded-sm px-3 py-2 d-flex justify-content-between w-100 rounded"
											type="button"
											id="JobStatusDropdown"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											<span>Completion Percentage</span>
											<span className="fa fa-chevron-down" />
										</button>
										<div className="dropdown-menu w-100" aria-labelledby="JobStatusDropdown">
											<div className="d-flex">
												<div className="form-group d-flex flex-row w-100 mx-2">
													<input
														type="number"
														className="form-control border-1 bg-transparent"
														value={percentageFilter}
														onChange={(event) => {
															if (event.target.value >= 0 && event.target.value <= 100)
																setPercentageFilter(event.target.value);
														}}
													/>
													<label>Percentage</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</td>
						<td>
							<div className="d-lg-flex">
								<div
									className="my-lg-auto border rounded"
									style={{
										width: "220px",
									}}
								>
									<div className="dropdown">
										<button
											className="btn btn-block btn-sm text-dark bg-white border rounded-sm px-3 py-2 d-flex justify-content-between w-100 rounded"
											type="button"
											id="JobStatusDropdown"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											<span>Reward Status</span>
											<span className="fa fa-chevron-down" />
										</button>
										<div className="dropdown-menu w-100" aria-labelledby="JobStatusDropdown">
											{Object.entries(rewardStatus).map(([key, value]) => {
												return (
													<div
														key={key}
														className="dropdown-item cursor-pointer"
														onClick={(event) => {
															event.stopPropagation();
															setRewardStatus({
																...rewardStatus,
																[key]: !value,
															});
														}}
													>
														<div className="d-flex align-items-center">
															<input
																type="checkbox"
																checked={rewardStatus[key]}
																className="cursor-pointer"
																onChange={(event) => {
																	event.stopPropagation();
																	setRewardStatus({
																		...rewardStatus,
																		[key]: !value,
																	});
																}}
															/>
															<p className="ml-3 my-auto">{key}</p>
														</div>
													</div>
												);
											})}
										</div>
									</div>
								</div>
							</div>
						</td>
						<td colSpan={2}>
							<div className="d-lg-flex">
								<div
									className="my-lg-auto border rounded"
									style={{
										width: "100%",
									}}
								>
									<div className="dropdown">
										<button
											className="btn btn-block btn-sm text-dark bg-white border rounded-sm px-3 py-2 d-flex justify-content-between w-100 rounded"
											type="button"
											id="JobStatusDropdown"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											<span>Activity Type</span>
											<span className="fa fa-chevron-down" />
										</button>
										<div className="dropdown-menu w-100" aria-labelledby="JobStatusDropdown">
											{Object.entries(activityType).map(([key, value]) => {
												return (
													<div
														key={key}
														className="dropdown-item cursor-pointer"
														onClick={(event) => {
															event.stopPropagation();
															setActivityType({
																...activityType,
																[key]: !value,
															});
														}}
													>
														<div className="d-flex align-items-center">
															<input
																type="checkbox"
																checked={activityType[key]}
																className="cursor-pointer"
																onChange={(event) => {
																	event.stopPropagation();
																	setActivityType({
																		...activityType,
																		[key]: !value,
																	});
																}}
															/>
															<p className="ml-3 my-auto">{key}</p>
														</div>
													</div>
												);
											})}
										</div>
									</div>
								</div>
							</div>
						</td>
					</tr>
					{rewards
						.filter(handleFilter)
						.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
						.map((item, index) => (
							<tr key={index}>
								<td className="align-middle">
									<div className="d-flex">
										<CircularProgressWithLabel
											value={item?.resumeCompletionPct == undefined ? 0 : item?.resumeCompletionPct}
											rewardPoints={item.rewardPoints}
										/>
									</div>
								</td>
								<td className="align-middle">
									{item.activityType === "REFERRAL" && (
										<>
											<p className="fs-16">
												<i className="bi bi-person mr-2 text-primary fs-20 font-weight-bold" />
												<span>
													{capitalizeFirstLetterOfWords(item?.referredFullName)}
													<br />
												</span>
												<i className="bi bi-envelope-at mr-2 text-primary fs-20 font-weight-bold" />{" "}
												{`${item?.referredEmailAddress}${item?.referredPhoneNumber && ", +" + item?.referredPhoneNumber}`}
											</p>
										</>
									)}
									{item.activityType === "RESUME_COMPLETION" && (
										<>
											<p className="fs-16">
												<span className="bi bi-file-earmark-check mr-2 text-primary fs-20 font-weight-bold" />
												Resume Completion <br />
												<span className="bi bi-file-earmark-person mr-2 text-primary fs-20 font-weight-bold" />
												{`${item?.activityDetail}`}
											</p>
										</>
									)}
									{item.activityType === "JOB_LEAD" && (
										<>
											<p className="fs-16">
												<span className="bi bi-share mr-2 text-primary fs-20 font-weight-bold" />
												{capitalizeFirstLetterOfWords(item?.jobTitle)}
												<br />
												<span className="bi bi-buildings mr-2 text-primary fs-24 font-weight-bold" />{" "}
												{item?.jobCompanyName}
											</p>
										</>
									)}
								</td>
								<td className="align-middle">
									{item.rewardStatus}
									<p className="fs-12">{formatAPIDate("M-D-Y-T", item.initiatedDate)}</p>
									<p className="fs-12">
										{item.claimedDate != null && "Claimed at " + formatAPIDate("M-D-Y-T", item.claimedDate)}
									</p>
									<p className="fs-12">
										{item.redeemedDate != null && "Redeemed at" + formatAPIDate("M-D-Y-T", item.redeemedDate)}
									</p>
								</td>
								<td className="align-middle">
									<p className={`h2 ${getRewardPointTextColor(item.rewardStatus)}`}>
										{currencyFormat(item.rewardPoints)}
									</p>
								</td>
								<td className="align-middle">
									{item.rewardStatus !== "REDEEMED" && (
										<>
											<div className="d-flex">
												<span
													className="text-medium d-flex mb-0 px-2 flex-column text-center justify-content-center cursor-pointer align-items-center"
													onClick={() => {
														if (item.rewardStatus === "PENDING" && !submitting) {
															claimPaymentReward(item._id);
														}
													}}
												>
													<span
														className={`${
															item.rewardStatus === "PENDING" ? "text-primary" : "text-muted"
														} fs-24 mb-2 bi bi-collection`}
													/>
													Collect
												</span>
												<span
													onClick={() => {
														if (item?.resumeCompletionPct >= 80) {
															navigate("/payments");
														}
													}}
													data-toggle="modal"
													className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
												>
													<span
														className={`${
															item.rewardStatus === "CLAIMED" ? "text-primary" : "text-muted"
														} fs-24 bi mb-2 bi-wallet2`}
													/>
													<span>Redeem</span>
												</span>
												{item.activityType === "REFERRAL" && (
													<a
														href={`https://wa.me/${item?.referredPhoneNumber}?text=${shareString(item)}`}
														target="_blank"
														rel="noopener noreferrer"
													>
														<span
															data-toggle="modal"
															className="d-flex px-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
														>
															<span className="text-success fs-24 bi mb-2 bi-whatsapp" />
															<span className="text-dark">Remind</span>
														</span>
													</a>
												)}
											</div>
										</>
									)}
								</td>
							</tr>
						))}
				</>
			)}
		</tbody>
	);
}

export default RewardsTableBody;
