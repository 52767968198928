import React from "react";
import { Dropdown } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";

function EllipsisPagination({
	totalPages,
	currentPage,
	onPageChange,
	rowsPerPage,
	setRowsPerPage,
	activePage,
	length,
	dataName,
}) {
	const maxDisplayedPages = 5;

	const generateDisplayedPages = () => {
		const pages = [];

		if (totalPages <= maxDisplayedPages) {
			for (let page = 1; page <= totalPages; page++) {
				pages.push(page);
			}
		} else {
			let startPage = currentPage - Math.floor(maxDisplayedPages / 2);
			if (startPage < 1) {
				startPage = 1;
			}
			const endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

			if (startPage > 1) {
				pages.push("...");
			}
			for (let page = startPage; page <= endPage; page++) {
				pages.push(page);
			}
			if (endPage < totalPages) {
				pages.push("...");
			}
		}

		return pages;
	};

	const handlePageChange = (page) => {
		if (page === "...") {
			return;
		}
		onPageChange(page);
	};

	const pages = generateDisplayedPages();

	return (
		<div className="d-flex align-items-center flex-wrap my-5">
			<p className="">
				Displaying {(activePage - 1) * rowsPerPage + (length === 0 ? 0 : 1)} to{" "}
				{Math.min(activePage * rowsPerPage, length)} of {length} {dataName}
			</p>
			<div className="d-flex align-items-center ml-auto flex-wrap">
				<Dropdown className="mr-3">
					<Dropdown.Toggle
						variant="outline-light text-primary"
						id="dropdown-basic"
						className="btn btn-white btn-sm text-primary fw-bold border-white rounded pl-0"
					>
						{dataName} Per Page: <span className="ml-3">{rowsPerPage}</span>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{[10, 25, 50].map((item, index) => {
							return (
								<Dropdown.Item
									key={index}
									onClick={() => {
										setRowsPerPage(item);
									}}
								>
									{item}
								</Dropdown.Item>
							);
						})}
					</Dropdown.Menu>
				</Dropdown>
				<Pagination>
					<Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
					{pages.map((page, index) => (
						<Pagination.Item key={index} active={page === currentPage} onClick={() => handlePageChange(page)}>
							{page}
						</Pagination.Item>
					))}
					<Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
				</Pagination>
			</div>
		</div>
	);
}

export default EllipsisPagination;
