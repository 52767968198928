import { dateToMYDObject } from "../../handlers/utilityFunctions";

export class PersonalInfo {
	constructor(profile) {
		this.gender = profile.gender;
		this.maritalStatus = profile.maritalStatus;
		this.date = dateToMYDObject(profile.dateOfBirth);
	}

	getPersonalInfoRequest() {
		return {
			gender: this.gender,
			dateOfBirth: this.date,
			maritalStatus: this.maritalStatus,
		};
	}
}
