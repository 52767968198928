import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import { interactions } from "../../../handlers/enums";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import Loader from "../../Loader";
import MyJobsDetails from "./MyJobsDetails";
import MyJobsListItem from "./MyJobsListItem";

const MyJobs = ({ myJobsTab }) => {
	const [myJobs, setMyJobs] = useState([]);
	const [interactionStatus, setInteractionStatus] = useState("");
	const [pagesCount, setPagesCount] = useState(1);
	const [activeMyJob, setActiveMyJob] = useState({});
	const [loading, setLoading] = useState(false);
	const [loadJobDetails, setLoadJobDetails] = useState(false);
	const [muteNext, setMuteNext] = useState(true);
	const [mutePrevious, setMutePrevious] = useState(true);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [, setStartCount] = useState(0);
	const [, setEndCount] = useState(0);
	const [searchedJobs] = useState({});
	const dispatch = useDispatch();

	const [pageNumber, setPageNumber] = useState(1);
	const [, setPaginateReq] = useState(false);

	const resumeDataService = new ResumeDataService();
	const counts = useSelector((state) => state.JobsCountReducers);

	function successInGetSelectedJob(response) {
		setLoadJobDetails(false);
		setActiveMyJob(response.data);
	}

	function errorInGetSelectedJob() {
		setLoadJobDetails(false);
		toast.error("Something went wrong");
	}

	const jobOnclick = (recommrefernceId) => {
		setLoadJobDetails(true);
		const params = { recommReferenceId: recommrefernceId };
		resumeDataService.getRecommendedJobs(params, successInGetSelectedJob, errorInGetSelectedJob);
	};

	const handleStatusChange = (interactionStatus) => {
		setInteractionStatus(interactionStatus);
	};

	const jobListBox = myJobs.map((jobItem, index) => (
		<MyJobsListItem
			key={jobItem._id}
			job={jobItem}
			index={index}
			isSmallScreen={screenWidth < 768}
			jobOnClick={jobOnclick}
		/>
	));

	const onFilterJobs = () => {
		setLoading(true);
		resumeDataService.getRecommendedJobs(
			{
				jobsInteractedWith: "Yes",
				interactionStatus: interactionStatus,
			},
			successCallBack,
			errorCallBack,
		);
	};

	const handlePagination = (event) => {
		const paginate = event.target.id === "prev" ? -1 : 1;

		if (event.target.id === "prev" && !mutePrevious) {
			onPagination(paginate);
		}

		if (event.target.id === "next" && !muteNext) {
			onPagination(paginate);
		}
	};

	const setPaginationCounts = () => {
		let _startCount;
		let _endCount;

		if (searchedJobs.pageNumber > counts.pageNumber) {
			_startCount = counts.end + 1;
			_endCount = counts.end + searchedJobs.jobs.length;
		} else {
			_startCount = counts.start - searchedJobs.jobs.length;
			_endCount = counts.start - 1;
		}

		setEndCount(_endCount);
		setStartCount(_startCount);

		dispatch({
			type: "UPDATE_COUNT",
			payload: { start: _startCount, end: _endCount, pageNumber: searchedJobs.pageNumber },
		});
	};

	function successCallBack(response) {
		setLoading(false);
		setMyJobs(response.data);
	}

	function errorCallBack() {
		setLoading(false);
	}

	const onPagination = (_paginate) => {
		const _pageNumber = pageNumber + _paginate;
		setLoading(true);
		setPageNumber(_pageNumber);
		setPaginateReq(true);
	};

	useEffect(() => {
		if (Object.keys(searchedJobs).length !== 0) {
			const _jobs = [];
			for (let i = 0; i < searchedJobs.jobs.length; i++) {
				if (i === 0) {
					_jobs.push({ ...searchedJobs.jobs[i], active: true });
					setActiveMyJob(searchedJobs.jobs[i]);
				} else {
					_jobs.push({ ...searchedJobs.jobs[i], active: false });
				}
			}

			if (searchedJobs.pagesCount !== 1) {
				if (searchedJobs.pageNumber === 1) {
					setMuteNext(false);
					setMutePrevious(true);
				} else if (searchedJobs.pageNumber === searchedJobs.pagesCount) {
					setMuteNext(true);
					setMutePrevious(false);
				} else {
					setMuteNext(false);
					setMutePrevious(false);
				}
			}

			setPaginationCounts();

			setMyJobs(_jobs);
			setPagesCount(searchedJobs.pagesCount);
		}
	}, [searchedJobs]);

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [screenWidth]);

	useEffect(() => {
		if (myJobsTab) {
			setLoading(true);
			resumeDataService.getRecommendedJobs({ jobsInteractedWith: "Yes" }, successCallBack, errorCallBack);
		}
	}, [myJobsTab]);

	if (!myJobsTab) return null;

	return (
		<>
			<div className="employee-dashboard-main">
				<div className="">
					<div className="job-search-bar py-3 px-3 mt-3 mb-3 bg-light" style={{ borderRadius: "10px" }}>
						<div className="container-fluid">
							<div className="row d-flex justify-content-end">
								{/* Expertise Category*/}
								<div className="col-lg-2 dropdown">
									<div className="dropdown">
										<button
											className="btn btn-block text-dark bg-white border border-secondary rounded-sm px-3 py-2 d-flex justify-content-between"
											type="button"
											id="filtersDropdown"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											{`${interactionStatus !== "" ? interactionStatus : "Interaction Status"}`}
											<span className="fa fa-chevron-down" />
										</button>
										<div className="dropdown-menu w-100 py-0" aria-labelledby="filtersDropdown">
											<div
												className="col-lg py-3 my-jobs-items"
												onClick={() => handleStatusChange(interactions.VIEWED)}
											>
												Viewed
											</div>
											<div
												className="col-lg py-3 my-jobs-items"
												onClick={() => handleStatusChange(interactions.SAVED_FOR_LATER)}
											>
												Saved
											</div>
											<div
												className="col-lg py-3 my-jobs-items"
												onClick={() => handleStatusChange(interactions.APPLIED)}
											>
												Applied
											</div>
										</div>
									</div>
								</div>

								<div className="col-lg-2">
									<a href="#" className="btn btn-primary h-100 w-100" onClick={onFilterJobs}>
										Filter Jobs{" "}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				{loading ? (
					<div className="" style={{ height: "400px" }}>
						<div className="d-flex p-4 align-center h-75 justify-content-center">
							<div className="position-relative" style={{ top: "35%" }}>
								<Loader />
							</div>
						</div>
					</div>
				) : myJobs.length === 0 ? (
					<div className="" style={{ height: "500px" }}>
						<div className="d-flex p-4 align-center justify-content-center position-relative" style={{ top: "35%" }}>
							<div className="float-right">
								<p style={{ fontSize: "1.5rem" }}>No Jobs Found!</p>
							</div>
						</div>
					</div>
				) : (
					<div className="">
						<div className="tab-content">
							{/*active job dashboard*/}
							<div role="tabpanel" className="tab-pane active" id="job-dashboard">
								{/*posted job dashboard*/}
								<div className="posted-job-dashboard employer-common-dashboard ">
									<div className="dashboard-main" style={{ borderRadius: "10px" }}>
										<div className="employee-dashboard">
											<div className="row no-gutters">
												{/*job list*/}
												<div className="col-md-3">
													<div className="employee-dashboard-left">
														<ul className="nav nav-tabs applied-job-list border-0" role="tablist">
															{/*job item*/}
															{/*<li*/}
															{/*    className="d-flex border-bottom bg-white align-items-center justify-content-between py-4 px-3">*/}
															{/*    {startCount === endCount ?*/}
															{/*        <span*/}
															{/*            className="text-muted">Showing {startCount} of {searchedJobs.jobsCount} jobs found</span>*/}
															{/*        :*/}
															{/*        <span*/}
															{/*            className="text-muted">Showing {startCount}-{endCount} of {searchedJobs.jobsCount} jobs found</span>*/}
															{/*    }*/}
															{/*</li>*/}
															{jobListBox}
															{pagesCount > 1 ? (
																<li className="d-flex border-bottom align-items-center justify-content-between py-4 px-3">
																	<div
																		style={{ cursor: "pointer" }}
																		className={`${mutePrevious ? "text-muted" : ""}`}
																		onClick={handlePagination}
																		id="prev"
																	>
																		<span
																			id="prev"
																			className={`fa fa-long-arrow-left text-primary ${
																				mutePrevious ? "text-muted" : ""
																			}`}
																		/>{" "}
																		Prev
																	</div>
																	<div
																		style={{ cursor: "pointer" }}
																		className={`${muteNext ? "text-muted" : ""}`}
																		onClick={handlePagination}
																		id="next"
																	>
																		Next{" "}
																		<span
																			id="next"
																			className={`fa fa-long-arrow-right text-primary ${muteNext ? "text-muted" : ""}`}
																		/>
																	</div>
																</li>
															) : null}
														</ul>
													</div>
												</div>
												{/*job list*/}

												{/*job right section*/}
												{screenWidth > 768 ? (
													loadJobDetails ? (
														<div className="col-md-9">
															<div className="employee-dashboard-right bg-white">
																<div className="tab-content">
																	<div style={{ height: "400px" }}>
																		<div className="d-flex p-4 align-center h-75 justify-content-center">
																			<div className="position-relative" style={{ top: "35%" }}>
																				<Loader />
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													) : (
														<MyJobsDetails details={activeMyJob} />
													)
												) : null}
												{/*job right section*/}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>

			{/*ApplicationResumePreviewModal*/}
			<div
				className="modal fade"
				id="ApplicationResumePreviewModal"
				data-backdrop="static"
				data-keyboard="false"
				tabIndex="-1"
				aria-labelledby="ApplicationResumePreviewModal"
				aria-hidden="true"
			>
				<div className="bg-white top py-3 w-100">
					<div className="container">
						<div className="d-flex align-items-center justify-content-between">
							<span className="font-weight-bold">JacquilineKhalfanResume</span>
							<span className="d-inline-block text-white bg-primary py-1 px-3 rounded-pill fs-12">
								<b>50 </b>Job Matches
							</span>
							<div className="d-flex align-items-center ">
								<div className="d-flex align-items-center text-primary mr-2 cursor-pointer">
									<span className="fa fa-download mr-2" />
									Download
								</div>
								<div className="d-flex align-items-center text-primary mr-2 cursor-pointer">
									<span className="fa fa-print mr-2" />
									Print
								</div>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-dialog modal-dialog-centered mt-5 py-5" style={{ maxWidth: "600px" }}>
					<div className="modal-content">
						<div id="resumePreviewSlider" className="carousel slide" data-ride="carousel">
							<div className="carousel-inner">
								<div className="carousel-item active">
									<img className="d-block w-100" src="images/resume-template/1.png" alt="First slide" />
								</div>
								<div className="carousel-item">
									<img className="d-block w-100" src="images/resume-template/2.png" alt="Second slide" />
								</div>
							</div>
							<a className="carousel-control-prev" href="#resumePreviewSlider" role="button" data-slide="prev">
								<span className="carousel-control-prev-icon" aria-hidden="true" />
								<span className="sr-only">Previous</span>
							</a>
							<a className="carousel-control-next" href="#resumePreviewSlider" role="button" data-slide="next">
								<span className="carousel-control-next-icon" aria-hidden="true" />
								<span className="sr-only">Next</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			{/*ApplicationResumePreviewModal*/}

			<div
				className="modal modal-fullscreen-xl"
				id="modal-fullscreen-xl"
				tabIndex="-1"
				role="dialog"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Apply Job</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{myJobs.length !== 0 ? (
								loadJobDetails ? (
									<div className="col-md-8">
										<div className="employee-dashboard-right bg-white">
											<div className="tab-content">
												<div style={{ height: "400px" }}>
													<div className="d-flex p-4 align-center h-75 justify-content-center">
														<div className="position-relative" style={{ top: "35%" }}>
															<Loader />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								) : (
									<MyJobsDetails details={activeMyJob} />
								)
							) : null}
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal">
								Close
							</button>
							<button type="button" className="btn btn-primary">
								Save changes
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MyJobs;
