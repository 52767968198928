import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import ExpertResumesLayout from "../../../components/ExpertResumesLayout/ExpertResumesLayout";
import Faqs from "../../../components/FAQ";
import { getSettingssRoutes } from "../../../handlers/navigationItems";
import useIsLoggedIn from "../../../hooks/use-IsLoggedIn";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import Subscription from "../Settings/Subscription";

function SubscriptionPage() {
	const [payments, setPayments] = useState([]);
	const [accountId, setAccountId] = useState("");
	const [settingsLoading, setSettingsLoading] = useState(true);
	const [subscriptionLoading, setSubscriptionLoading] = useState(true);

	const resumeDataService = new ResumeDataService();

	const onSuccessSettings = (response) => {
		setSettingsLoading(false);
		setAccountId(response.data._id);

		resumeDataService.getPayments(response.data._id, onSuccessPayment, onErrorPayment);
	};

	const isLoggedIn = useIsLoggedIn();

	if (!isLoggedIn) {
		window.location.replace("/signin");
	}

	const onErrorSettings = () => {
		setSettingsLoading(false);
		toast.error("Something went wrong, please try again");
		window.location.replace("/signin");
	};

	const onSuccessPayment = (response) => {
		setSubscriptionLoading(false);
		setPayments(response.data);
	};

	const onErrorPayment = () => {
		setSubscriptionLoading(false);
		toast.error("Something went wrong, please try again");
	};

	useEffect(() => {
		resumeDataService.getUser(onSuccessSettings, onErrorSettings);
	}, []);

	return (
		<ExpertResumesLayout navItems={getSettingssRoutes()} headingTitle="Subscriptions" showExpertDetails>
			{/* <MyResumesHeader /> */}
			<div className="my-resumes-section">
				<div className="container">
					<h2 className="mb-4">Subscription</h2>
					<Subscription
						payments={payments}
						accountId={accountId}
						settingsLoading={settingsLoading}
						subscriptionLoading={subscriptionLoading}
					/>
					<Faqs />
				</div>
			</div>
		</ExpertResumesLayout>
	);
}

export default SubscriptionPage;
