import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { ResumeDataService } from "../../../services/create-resume/resume-data";

function ReviewReasonCheckbox({ review, item, setReview }) {
	const checkboxRef = React.useRef(null);
	return (
		<label className="form-check d-flex align-items-center p-0" htmlFor="legalNameToSAcknowledged">
			<input
				type="checkbox"
				ref={checkboxRef}
				name="reviewDetails"
				value={item}
				checked={review.reviewDetails === item}
				onChange={(e) => setReview({ ...review, reviewDetails: e.target.value })}
			/>
			<span
				className="checkmark"
				onClick={() => {
					checkboxRef.current.click();
				}}
			/>
			<div className="ml-4 pl-2">
				<p className="fs-14 font-weight-medium m-0 text-black">{item}</p>
			</div>
		</label>
	);
}

function ReviewModal({ jdID, setShowReviewModal }) {
	const apiService = new ResumeDataService();

	const navigate = useNavigate();

	const [review, setReview] = useState({
		reviewStatus: "", //APPROVED, REJECTED, NEED_ADDITIONAL_INFORMATION
		reviewDetails: "",
	});

	const checkboxRef = React.useRef(null);

	function handleSubmit(e) {
		e.preventDefault();
		if (!review.reviewStatus) {
			toast.error("Please select review status!");
			return;
		}
		if (!review.reviewDetails) {
			toast.error("Please enter review details!");
			return;
		}
		apiService.reviewJobDescription(
			jdID,
			review,
			() => {
				toast.success("Job Description Reviewed Successfully!");
				setShowReviewModal(false);
				navigate("/job-market/jds/");
			},
			(e) => {
				const error = e.response.data.message;
				toast.error(error);
			},
		);
	}
	const reviewReasons = {
		REJECTED: [
			"Spam: The content appears to be unsolicited, irrelevant, or repetitive, resembling typical spam.",
			"Inappropriate Content: The submission contains material that is offensive, violates community guidelines, or is otherwise inappropriate.",
			"Misleading Information: The provided content is deceptive, inaccurate, or likely to lead to confusion.",
			"Lack of Relevance: The submission does not align with the specified context or purpose and lacks relevance to the intended topic or discussion.",
			"Missing Mandatory Information: Essential details such as company information or point of contact details are absent, hindering the completion of the submission.",
		],
		NEED_ADDITIONAL_INFORMATION: [
			"Incomplete Information: While the submission meets most criteria for approval, it lacks some essential details.Specifically, additional information such as company details or a point of contact is required for a comprehensive review.This information is crucial to ensure a complete understanding and evaluation.",
		],
		APPROVED: [
			"Complete Information: The submission is comprehensive and contains all relevant information, including company details and point of contact information.",
			"Adherence to Guidelines: The content aligns with established guidelines and does not violate any standards or policies.",
			"Relevance: The submission is pertinent to the context or purpose, contributing meaningfully to the discussion or intended outcome.",
			"Clarity: The information is presented clearly and concisely, making it easy to understand and evaluate.",
		],
	};
	return (
		<div
			className="modal"
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				position: "absolute",
				overflow: "hidden",
				zIndex: 9999,
				backgroundColor: "rgba(0,0,0,0.5)",
				height: "100vh",
				width: "100vw",
			}}
		>
			<div
				className="modal-content p-3"
				style={{
					backgroundColor: "transparent",
					border: "none",
					width: "100%",
					height: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					maxWidth: "800px",
				}}
			>
				<form method="post" className="bg-white px-5 py-3 rounded w-100" onSubmit={handleSubmit}>
					<div className="d-flex justify-content-between align-items-center">
						<h3>Review Job Description</h3>
						<button type="button" className="close" aria-label="Close" onClick={() => setShowReviewModal(false)}>
							<i className="bi bi-x-lg" />
						</button>
					</div>
					<div className="form-group">
						<label htmlFor="reviewStatus">Review Status</label>
						{[
							{ value: "APPROVED", label: "Approved" },
							{ value: "REJECTED", label: "Rejected" },
							{ value: "NEED_ADDITIONAL_INFORMATION", label: "Need Additional Information" },
						].map((item, index) => (
							<label className="form-check d-flex align-items-center p-0" htmlFor={item.value} key={index}>
								<input
									type="checkbox"
									ref={checkboxRef}
									name="reviewStatus"
									id={item.value}
									value={item.value}
									checked={review.reviewStatus === item.value}
									onChange={(e) => setReview({ ...review, reviewStatus: e.target.value })}
								/>
								<span
									className="checkmark"
									onClick={() => {
										checkboxRef.current.click();
									}}
								/>
								<div className="ml-4 pl-2">
									<p className="fs-14 font-weight-medium m-0 text-black">{item.label}</p>
								</div>
							</label>
						))}
					</div>
					{review.reviewStatus && (
						<div className="mb-3 form-group">
							<label htmlFor="">Review Reason</label>
							{reviewReasons[review.reviewStatus].map((item, index) => (
								<ReviewReasonCheckbox review={review} item={item} key={index} setReview={setReview} />
							))}
						</div>
					)}
					<div className="form-group">
						<button type="submit" className="btn btn-primary" onClick={handleSubmit}>
							Submit
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default ReviewModal;
