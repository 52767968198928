import React from "react";

import JobNavLayout from "../JobMarket/JobMarketLayout/JobMarketNavLayout/Layout";
import LeadsList from "./LeadsList";

function SharedLeads() {
	return (
		<JobNavLayout header="Shared Leads">
			<div
				className="container px-5"
				style={{
					margin: 0,
					maxWidth: "100vw",
				}}
			>
				<div className="row">
					<div className="col left container">
						<div className="d-flex justify-content-between mb-4 align-items-center">
							<h3 className="mt-4">Shared Leads</h3>
						</div>

						<LeadsList />
					</div>
				</div>
			</div>
		</JobNavLayout>
	);
}

export default SharedLeads;
