import React from "react";

import ListItem from "./ListItem";

function SkillScoreItem({ data }) {
	return (
		<div>
			{data.match_result.map((skill, index) => (
				<ListItem pct={skill.category_match_pct} key={index}>
					<div className="ml-2">
						<p className="m-0 fw-bold">{skill.category}</p>
						{skill.matched_skills.length > 0 && (
							<p className="m-0">Matched Skills: {skill.matched_skills.join(", ")}</p>
						)}
						{skill.missing_skills.length > 0 && (
							<p className="m-0">Missing Skills: {skill.missing_skills.join(", ")}</p>
						)}
					</div>
				</ListItem>
			))}
		</div>
	);
}

export default SkillScoreItem;
