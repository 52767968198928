import React from "react";

function Loader() {
	return (
		<div className="d-block w-100 mb-5">
			<div className="d-flex align-items-center justify-content-center">
				<div className="spinner-border text-primary" role="status" style={{ width: 82, height: 82 }}>
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		</div>
	);
}

export default Loader;
