import "../../NewNavLayout/dashboardStyle.css";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { jobPreferencesTitles } from "../../../seed/job-preferences-data";

const JobPreferenceDashboard = ({ storedJobPreferences }) => {
	const [preferenceDetails, setPreferenceDetails] = useState([]);
	const [completenessPercentage, setCompletenessPercentage] = useState(0);

	useEffect(() => {
		if (storedJobPreferences) {
			const storedData = JSON.parse(storedJobPreferences);
			setCompletenessDetails(storedData?.job_preference_progress_status?.completeness_details);
			setCompletenessPercentage(storedData?.job_preference_progress_status?.completeness_pct);
		}
	}, []);

	function setCompletenessDetails(completenessDetails) {
		const _details = [];

		const sortedArray = completenessDetails.sort((a, b) => a.isComplete - b.isComplete);

		let counter = 0;

		while (_details.length < 3) {
			if (sortedArray[counter].key !== "currentJobsearchStatus") {
				_details.push(sortedArray[counter]);
			}

			if (sortedArray[counter].key === "currentJobsearchStatus") {
				_details.unshift(sortedArray[counter]);
			}

			counter = counter + 1;
		}

		setPreferenceDetails(_details);
	}

	return (
		<div className="segment-dashboard">
			{preferenceDetails.length === 0 ? (
				<div className="d-flex p-4 align-center justify-content-center">
					<div className="float-right">
						<h2 style={{ fontFamily: "Varela Round", fontSize: "1.5rem" }}> No Preference Set! </h2>
						<p>
							Set your<Link to="/jobs-preference"> job preferences </Link>
							to receive job recommendations tailored for you.
						</p>
					</div>
				</div>
			) : (
				<>
					<h2>Job Preferences</h2>
					<div className="d-flex p-2 mt-3 mt-lg-3" style={{ backgroundColor: "#e6f2ff" }}>
						<div className="pr-2">
							<div
								className="rounded-circle bg-light d-flex align-items-center justify-content-center"
								style={{ width: "20px", height: "20px" }}
							>
								<i className="fa fa-briefcase text-primary" />
							</div>
						</div>
						<div className="text-wrap">
							<p className="h6 font-weight-bold">
								Job-Preferences {completenessPercentage ? completenessPercentage : 0}% complete
							</p>
						</div>
					</div>

					<div className="mt-4">Preference Status:</div>

					<ul className="top-segment-dashboard-left-list">
						{preferenceDetails.map((item, index) => (
							<li key={index}>
								<div className="top-segment-dashboard-left-container">
									<div className="top-segment-dashboard-left-container-head">
										<div className="top-segment-dashboard-left-image">
											<i className="fa fa-info-circle mr-2 text-info" aria-hidden="true" />
										</div>
										<div className="top-segment-dashboard-left-name d-flex justify-content-between">
											<h3>{jobPreferencesTitles(item.key)}</h3>
											{item.isComplete ? (
												<i className="fa fa-check ml-2 text-success pt-3" />
											) : (
												<i className="fa fa-close ml-2 text-danger pt-3" />
											)}
										</div>
									</div>
								</div>
							</li>
						))}
					</ul>
					<Link to="/jobs-preference">
						<button className="view-all-button">More details</button>
					</Link>
				</>
			)}
		</div>
	);
};

export default JobPreferenceDashboard;
