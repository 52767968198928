import React from "react";

import { calculateDateToToday } from "../../../handlers/utilityFunctions";

const JobListItem = ({ job, index, jobOnClick, isSmallScreen }) => {
	const handleOnClick = () => {
		jobOnClick(index);
	};
	const daysSincePosted = calculateDateToToday(job.lastUpdatedAt);

	return (
		<li
			role="presentation"
			onClick={handleOnClick}
			data-toggle="modal"
			data-target={isSmallScreen ? "#modal-fullscreen-xl" : ""}
		>
			<a
				className={`${job.active ? "active" : ""}`}
				aria-controls="job-1"
				role="tab"
				data-toggle="tab"
				aria-selected="false"
			>
				<div className="row no-gutters">
					<div className="col-2 pr-2">
						<img className="img-fluid company-logo" src="/images/icons/default_company_logo.svg" alt="" />
					</div>
					<div className="col-10">
						<span className="d-flex align-items-center justify-content-between">
							<span style={{ fontSize: "1.2em" }}>{job.jobTitle}</span>
						</span>
						<span>{job.companyName}</span>
						<br />
						<span className="">
							{`
                        ${job.jobLocation.city},
                        ${job.jobLocation.region},
                        ${job.jobLocation.country}
                    `}
						</span>
						<span className="d-flex w-100 fs-12 justify-content-between">
							<span>
								<span className="fa fa-clock text-muted mr-1" />
								<span className="text-muted ">{daysSincePosted}</span>
							</span>
							<span>
								<span className="fa fa-briefcase text-muted mr-1" />
								<span className="text-muted ">{job.employmentType}</span>
							</span>
						</span>
					</div>
				</div>
			</a>
		</li>
	);
};

export default JobListItem;
