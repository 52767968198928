import React from "react";

import Icon from "../Icon";

function DateTimePicker() {
	return (
		<div className="d-flex align-items-start p-0" style={{ gap: "16px" }}>
			<div className="d-flex flex-column align-items-start p-0" style={{ gap: "4px" }}>
				<div className="expert-review-orders-header-title" style={{ color: "#3E424E" }}>
					Scheduled for
				</div>
				<div
					className="d-flex align-items-center cursor-pointer"
					style={{
						padding: "8px 32px 8px 12px",
						gap: "12px",
						border: "1px solid #D7DAE0",
						boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 1px #E7E7E7",
						borderRadius: "6px",
					}}
				>
					<div className="d-flex align-items-center p-0" style={{ gap: "8px" }}>
						<Icon iconName="calendar" size={20} />
						<div className="expert-review-footer-text" style={{ color: "#121316" }}>
							<input type="date" style={{ border: "none" }} />
						</div>
					</div>
				</div>
			</div>
			<div className="d-flex flex-column align-items-start p-0" style={{ gap: "4px" }}>
				<div className="expert-review-orders-header-title" style={{ color: "#3E424E" }}>
					Select time
				</div>
				<div
					className="d-flex align-items-center cursor-pointer"
					style={{
						padding: "8px 8px 8px 12px",
						gap: "12px",
						border: "1px solid #D7DAE0",
						boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 1px #E7E7E7",
						borderRadius: "6px",
					}}
				>
					<div className="d-flex align-items-center p-0" style={{ gap: "8px" }}>
						<Icon iconName="clock" size={20} />
						<div className="expert-review-footer-text" style={{ color: "#121316" }}>
							<input type="time" style={{ border: "none" }} />
						</div>
					</div>
					<i className="bi bi-chevron-down" />
				</div>
			</div>
		</div>
	);
}

export default DateTimePicker;
