import React, { useEffect, useState } from "react";

import { isValidURL } from "../../../../../handlers/validationFunctions";

function LinkInput({ setData, data, linkOptions, deleteLink, index }) {
	const [link, setLink] = useState({
		type: "",
		value: "",
	});

	useEffect(() => {
		if (data) {
			setLink(data);
		}
	}, [data]);

	const onChangeType = (e) => {
		setLink({
			...link,
			type: e.target.value,
		});
	};

	const onChangeInput = (e) => {
		setLink({
			...link,
			value: e.target.value,
		});
	};

	function isValid() {
		if (link.type === "" || link.value === "") {
			return false;
		}
		if (!isValidURL(link.value)) {
			return false;
		}
		return true;
	}

	useEffect(() => {
		if (isValid()) {
			setData(index, link);
		}
	}, [link]);

	return (
		<div className="row mb-3" id="addDescription">
			<div className="col-4">
				<div className="form-group mb-0">
					<label>Link Type</label>
					<select className="form-control" value={link.type} onChange={onChangeType} id="linkType">
						<option value="">Select Link Type</option>
						{linkOptions.map((option, index) => (
							<option key={index} value={option}>
								{option}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className="col-8 p-0">
				<div className="form-group mb-0">
					<label>Link URL</label>

					<div className="d-flex gap-2">
						<input
							type="url"
							className="form-control"
							id="linkInput"
							value={link.value}
							onChange={onChangeInput}
							placeholder="Enter Url"
						/>
						{link.value.length > 0 && (
							<button onClick={deleteLink} className="button-secondary ml-2 text-danger">
								<i className="bi bi-trash3 fs-16 lh-1" />
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default LinkInput;
