/* eslint-disable no-case-declarations */
import { useSelector } from "react-redux";

const useHasChange = () => {
	const resume = useSelector((state) => state.ResumeFromApiReducer);

	const hasChanges = (section, payload) => {
		if (Object.keys(resume).length !== 0) {
			switch (section) {
				case "EXPERIENCE":
					const experience = [...resume.experience];
					const presentIndex = experience.findIndex((item) => item.referenceId === payload.referenceId);

					if (presentIndex) {
						experience[presentIndex] = payload;
						const updatedResume = {
							...resume,
							experience: experience,
						};

						if (JSON.stringify(resume) === JSON.stringify(updatedResume)) {
							return false;
						}
					}

					break;

				case "EDUCATION":
					const educationSection = section.toLowerCase();

					if (!changesInList(educationSection, payload)) {
						return false;
					}

					break;

				case "SKILLS":
					const selectedSection = section.toLowerCase();

					if (!changesInTArea(selectedSection, payload)) {
						return false;
					}

					break;

				case "SUMMARY":
					const summarySection = "professionalSummary";

					if (JSON.stringify(resume[summarySection][0]) === JSON.stringify(payload)) {
						return false;
					}

					break;

				case "ACCOMPLISHMENTS":
					if (resume.accomplishments && JSON.stringify(resume.accomplishments.data) === JSON.stringify(payload.data)) {
						return false;
					}

					break;

				case "AFFILIATION":
					if (resume.affiliations && JSON.stringify(resume.affiliations.data) === JSON.stringify(payload.data)) {
						return false;
					}

					break;

				case "ADDITIONAL_INFO":
					if (resume.additionInfo && JSON.stringify(resume.additionInfo.data) === JSON.stringify(payload.data)) {
						return false;
					}

					break;

				case "CERTIFICATION":
					if (resume.certifications && JSON.stringify(resume.certifications.data) === JSON.stringify(payload.data)) {
						return false;
					}

					break;

				case "LINKS":
					if (resume.personalLinks && JSON.stringify(resume.personalLinks.data) === JSON.stringify(payload.data)) {
						return false;
					}

					break;

				case "LANGUAGES":
					if (resume.languages && JSON.stringify(resume.languages.data) === JSON.stringify(payload.data)) {
						return false;
					}

					break;

				case "REFERENCE":
					if (resume.reference) {
						const reference = [...resume.reference.data];
						const indexPresent = reference.findIndex((item) => item.referenceId === payload.referenceId);

						if (indexPresent >= 0) {
							reference[indexPresent] = payload;
							const updatedResume = {
								...resume,
								reference: {
									applicable: true,
									data: reference,
								},
							};

							if (JSON.stringify(resume) === JSON.stringify(updatedResume)) {
								return false;
							}
						}
					}

					break;

				case "CUSTOM":
					if (resume.custom && JSON.stringify(resume.custom.data) === JSON.stringify(payload.data)) {
						return false;
					}

					break;

				default:
					return false;
			}
		}

		return true;
	};

	const changesInList = (section, payload) => {
		const listArray = [...resume[section]];

		const indexPresent = listArray.findIndex((item) => item.referenceId === payload.referenceId);

		if (indexPresent >= 0) {
			listArray[indexPresent] = payload;

			const updatedResume = { ...resume };
			updatedResume[section] = listArray;

			if (JSON.stringify(resume) === JSON.stringify(updatedResume)) {
				return false;
			}
		}

		return true;
	};

	const changesInTArea = (section, payload) => {
		if (JSON.stringify(resume[section]) === JSON.stringify(payload)) {
			return false;
		}

		return true;
	};

	return {
		hasChanges,
	};
};

export default useHasChange;
