import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import tips from "../../../../assets/copy/tips";
import ErrorToast from "../../../../components/ErrorToast";
import Spinner from "../../../../components/Spinner";
import Tips from "../../../../components/Tips";
import ResumeContext from "../../../../context/ResumeContext";
import useHasChange from "../../../../hooks/use-HasChange";
import { StaticValue } from "../../../../seed/constants";
import { ResumeDataService } from "../../../../services/create-resume/resume-data";
import LinkInput from "./components/LinkInput";

const LINK_CATEGORIES = ["Website", "LinkedIn", "GitHub", "Twitter", "Portfolio", "Blog"];

const PersonalLinks = () => {
	const [cookies] = useCookies(["resume"]);

	const navigate = useNavigate();

	const dispatch = useDispatch();
	const staticValue = new StaticValue();
	const resumeDataService = new ResumeDataService();
	const staticValues = staticValue.getDeafaulValues();
	const { hasChanges } = useHasChange();
	const [serverErrors, setServerErrors] = useState([]);

	const { resumeData } = useContext(ResumeContext);

	const [loading, setLoading] = useState(false);

	const [linkData, setLinkData] = useState([]);

	//form submition handler
	const addPersonalLinks = () => {
		const section = "LINKS";

		if (hasChanges("LINKS", linkData)) {
			setLoading(true);
			const resumeId = cookies[staticValues.localStorageResumeId];

			resumeDataService.patchResumeLedger(resumeId, { data: linkData, applicable: true }, section, onSuccess, onError);
		} else {
			navigate("/resume/extras");
		}
	};

	function onSuccess(data) {
		setLoading(false);
		let response = data.data;

		dispatch({
			type: "RESUME_DATA",
			payload: response,
		});

		navigate("/resume/extras");
	}

	function onError(error) {
		setLoading(false);
		const serverResponse = error.response.data;
		Array.isArray(serverResponse.message)
			? setServerErrors((prev) => {
					return [...prev, ...serverResponse.message];
				})
			: setServerErrors((prev) => {
					return [...prev, serverResponse.message];
				});
	}

	const setLinks = (index, data) => {
		const newLinks = linkData.map((item, i) => (i === index ? data : item));
		setLinkData(newLinks);
	};

	useEffect(() => {}, [linkData]);

	useEffect(() => {
		const links = resumeData.extras.personalLinks;
		setLinkData(links);
	}, [resumeData.extras.personalLinks]);

	const addNewLink = () => {
		const newLink = {
			type: "",
			value: "",
		};
		setLinkData([...linkData, newLink]);
	};

	return (
		<>
			<div className="container min-vh-100">
				<div className="section">
					{serverErrors.map((item, index) => {
						return <ErrorToast error={item} key={index} />;
					})}
					<div className="col-md-7">
						<div className="mb-2 text-medium">
							<h2 className="tr-title-big mb-3">What do you want to link to?</h2>
							<p>Add your website, portfolio or professional profiles.</p>
						</div>
						<div className="d-flex justify-content-end mb-3">
							<Tips content={tips.personalLinks} />
						</div>

						{linkData.length > 0 &&
							linkData.map((link, index) => (
								<LinkInput
									key={index}
									index={index}
									data={link}
									setData={setLinks}
									linkOptions={LINK_CATEGORIES}
									deleteLink={() => {
										const newLinks = linkData.filter((item, i) => i !== index);
										setLinkData(newLinks);
									}}
								/>
							))}
						<p
							className="text-primary bg-transparent border-0 font-weight-semi cursor-pointer mb-3"
							onClick={addNewLink}
						>
							<span className="fa fa-plus mr-2" /> Add Website
						</p>

						<div className="row d-flex align-items-center justify-content-end">
							<button
								onClick={() => {
									navigate("/resume/extras");
								}}
								className="btn text-primary rounded-pill btn-lg mr-2"
							>
								Skip for now
							</button>
							<button onClick={addPersonalLinks} className="btn btn-primary rounded-pill btn-lg">
								{loading ? (
									<>
										Loading <Spinner />
									</>
								) : (
									"save"
								)}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PersonalLinks;
