import React from "react";

import CustomInputSkill from "./CustomInputSkill";
import SingleSkillItem from "./SingleSkillItem";

function GroupedSkillsItem({
	item,
	collapseGroupedSkills,
	groupedSkillIndex,
	removeGroupedSkills,
	onSaveCustomSkillAdded,
	onSelectSingleSkill,
	onAddCustomSkill,
}) {
	return (
		<div
			className="skills-items-wrapper mb-3"
			style={{ ...(item.collapsed && { backgroundColor: "#EFF9FF", cursor: "pointer" }) }}
			onClick={() => item.collapsed && collapseGroupedSkills(groupedSkillIndex)}
		>
			<div className="skills-items-wrapper-container">
				<div className="grouped-skill-text-title-container">
					<div className="grouped-skill-text-title mr-auto">{item.title}</div>
					<i
						className={`fa ${item.collapsed ? "fa-plus" : "fa-close"} cursor-pointer`}
						onClick={() =>
							item.collapsed ? collapseGroupedSkills(groupedSkillIndex) : removeGroupedSkills(item.title)
						}
					/>
				</div>
				{!item.collapsed && (
					<>
						<div className="grouped-skills-container">
							{item.skills.map((singleSkill, singleSkillIndex) => {
								if (singleSkill.skill === "") {
									return (
										<CustomInputSkill
											key={singleSkillIndex}
											groupedSkillIndex={groupedSkillIndex}
											singleSkillIndex={singleSkillIndex}
											onSaveCustomSkillAdded={onSaveCustomSkillAdded}
										/>
									);
								}
								return (
									<SingleSkillItem
										key={singleSkill.skill}
										onSelectSingleSkill={onSelectSingleSkill}
										singleSkill={singleSkill}
										groupedSkillIndex={groupedSkillIndex}
										singleSkillIndex={singleSkillIndex}
									/>
								);
							})}
						</div>
						<div
							className="cursor-pointer"
							onClick={() => {
								onAddCustomSkill(groupedSkillIndex);
							}}
						>
							<span className="fa fa-plus mr-2" style={{ color: "#019ce0" }} />
							<span className="add-custom-skill-button-text">Add custom</span>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default GroupedSkillsItem;
