import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Icon from "../../Icon";
import { TemplateNameToResumeViewMapping } from "./templateSelector";

const ResumePreview = ({ resumeData }) => {
	const templateSelector = new TemplateNameToResumeViewMapping();
	const templates = templateSelector.templatesToSupportedResumes;

	//TODO: Get templateName from resumeData.
	const template = useSelector((state) => state.TemplateSelectionReducer);
	const selectedTemplate = template.selectedTemplate; // currently getting template_name from redux. change this, get template_name from resumeData

	let useTemplateName = selectedTemplate.templateName;

	if (resumeData.journal) {
		//if contains q substring from q_to_end
		const indexOfq = resumeData.journal.templateId.indexOf("q");
		let intermediateName = resumeData.journal.templateId;

		if (indexOfq >= 0) {
			intermediateName = intermediateName.substring(indexOfq + 1);
		}

		//replace x with _
		intermediateName = intermediateName.replaceAll("x", "_");

		useTemplateName = intermediateName;
	}

	const ResumeTemplateComponent = templates.find((t) => {
		return t.name === useTemplateName;
	}).template;

	useEffect(() => {}, [resumeData]);

	const [showPreviewButton, setShowPreviewButton] = useState(false);
	return (
		<div
			className="position-relative"
			onMouseOver={() => setShowPreviewButton(true)}
			onMouseOut={() => setShowPreviewButton(false)}
		>
			<ResumeTemplateComponent resumeData={resumeData} />
			{showPreviewButton && (
				<div
					className="resume-preview-overlay jj"
					style={{
						scale: useTemplateName === "fresher_template_1" ? "1.4" : "1.2",
					}}
				>
					<button
						className="border border-secondary bg-white font-medium fs-14 lh-1 rounded font-inter mr-3"
						data-toggle="modal"
						data-target="#ResumePreview"
						style={{
							scale: useTemplateName === "fresher_template_1" ? "0.7" : "0.8",
						}}
					>
						<Icon iconName="eye" />
						Preview
					</button>
				</div>
			)}
		</div>
	);
};

export default ResumePreview;
