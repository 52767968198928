import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";

import Icon from "../../../components/Icon";
import Loader from "../../../components/Loader";
import { dateToDMY } from "../../../handlers/utilityFunctions";
import { resumeObjectToContextFormat } from "../../../handlers/valueTransformation";
import resumeInitialData from "../../../seed/resumeInitialData";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import Resume from "../../ResumeBuilder/Review/Resume";
import MarkReviewCompleteModal from "./MarkReviewCompleteModal";
import ReviewCard from "./ReviewCard";

function ExpertReview() {
	const useReviewFeedback = useSelector((state) => state.UseReviewFeedbackReducer);
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const resumeDataService = new ResumeDataService();
	const reviewToken = searchParams.get("review-token");
	const [loading, setLoading] = useState(true);
	const [loadingSubmission, setLoadingSubmission] = useState(false);
	const [reviewData, setReviewData] = useState([]);
	const [_resumeData, _setResumeData] = useState(resumeInitialData);
	const [loadingError, setLoadingError] = useState("");
	const [isLinkError, setIsLinkError] = useState(false);
	const [candidateMsg, setCandidateMsg] = useState("");
	const [reviewerName, setReviewerName] = useState("");
	const [candidateFullName, setCandidateFullName] = useState("");
	const [showMarkCompleteButton, setShowMarkCompleteButton] = useState(true);

	const onSuccessLoad = (response) => {
		setLoading(false);

		//Format and set the resume data
		resumeObjectToContextFormat(response.data.resumeLedger, _setResumeData, () => {});

		setCandidateFullName(() => {
			return `${response.data.resumeLedger.heading.firstName} ${response.data.resumeLedger.heading.lastName}`;
		});

		setReviewData(response.data.reviews);
		setCandidateMsg(response.data.message);
		setReviewerName(response.data.reviewerFullName);
	};

	const onErrorLoad = (error) => {
		setLoading(false);

		if (error.response.status === 400) {
			const errorMsg =
				error.response.data.name === "TokenExpiredError"
					? "Oops, this link is expired"
					: error.response.data.name === "JsonWebTokenError"
						? "Oops, this link is Malformed"
						: "Something is Wrong!";

			setLoadingError(errorMsg);
			setIsLinkError(true);
		}
	};

	const onSuccessFeedBack = (response) => {
		setReviewData(response.data);
		setLoadingSubmission(false);

		const bodyEl = document.querySelector("body");
		const modal = document.getElementById("reviewFeedbackModal");
		const modalBackDrop = document.querySelector(".modal-backdrop");

		modal.style.display = "none";
		modal.classList.remove("show");
		modal.setAttribute("aria-hidden", "true");

		bodyEl.classList.remove("modal-open");
		modalBackDrop.remove();

		dispatch({
			type: "USE_REVIEW_FEEDBACK",
			payload: {
				...useReviewFeedback,
				showFeedBackModal: false,
			},
		});
	};

	const errorOnFeedback = () => {
		setLoadingSubmission(false);
		toast.error("Something went wrong!");
	};

	const handleFeedBack = (feedBack) => {
		setLoadingSubmission(true);
		resumeDataService.patchReviewResume(reviewToken, feedBack, onSuccessFeedBack, errorOnFeedback);
	};

	useEffect(() => {
		resumeDataService.getReviewResume(reviewToken, onSuccessLoad, onErrorLoad);
	}, []);

	return (
		<>
			<header className="tr-header">
				<nav className="navbar navbar-expand-lg">
					<div className="navbar-header">
						<Link className="navbar-brand" to="/">
							<img className="img-fluid" src="/images/logo.png" alt="Logo" />
						</Link>
					</div>
					{showMarkCompleteButton && (
						<div
							data-toggle="modal"
							data-target="#markReviewCompleteModal"
							className="mark-review-complete-button ml-auto mr-2 mr-md-5 cursor-pointer"
							onClick={() => setShowMarkCompleteButton(false)}
						>
							<i className="bi bi-check-circle-fill text-success" />
							<div className="mark-review-complete-text-button">Mark as complete</div>
						</div>
					)}
				</nav>
			</header>

			<div id="expertReview" style={{ minHeight: "80vh" }}>
				<div className="container-fluid px-0 h-100">
					{loading ? (
						<div className="d-flex p-4 align-center h-75 justify-content-center center-loader">
							<Loader />
						</div>
					) : loadingError !== "" ? (
						<div className="d-flex p-4 align-center h-75 justify-content-center center-loader">
							<div className="float-right">
								<h1 style={{ fontFamily: "Varela Round" }}> {loadingError} </h1>
								{isLinkError ? <p style={{ fontSize: "1.5rem" }}>This URL is not valid anymore.</p> : null}
								<Link to="/" style={{ textDecoration: "underline" }}>
									Go to dproz.com
								</Link>
							</div>
						</div>
					) : (
						<>
							<div className="row px-4 mt-5">
								<div className="col-md-3 mt-5">
									<div className="dropdown align-items-start mb-4">
										<span
											className="border bg-white shadow-sm border-secondary dropdown-toggle px-3 py-2 rounded hide-toggle"
											data-toggle="dropdown"
											aria-expanded="false"
										>
											<div className="d-flex align-items-center">
												<Icon iconName="invoice" size={28} />
												<div className="mx-3 text-wrap">
													<p className="m-0 font-weight-semi">
														{_resumeData.contactInfo.firstName} {_resumeData.contactInfo.middleName}{" "}
														{_resumeData.contactInfo.lastName}
													</p>
													<p className="m-0 fs-12 font-gray">
														{`Feedback requested ${dateToDMY(_resumeData.journal.lastUpdatedOn)}`}
													</p>
												</div>
												<i className="bi bi-code fs-12 text-secondary rotate-90" />
											</div>
										</span>
										<div className="dropdown-menu mt-2 shadow-sm rounded border-0 p-1" style={{ minWidth: "316px" }}>
											{[].map((resume) => {
												return (
													<span className="dropdown-item rounded px-3" key={resume._id} onClick={() => {}}>
														<p className="m-0 font-weight-semi">
															{_resumeData.contactInfo.firstName} {_resumeData.contactInfo.middleName}{" "}
															{_resumeData.contactInfo.lastName}
														</p>
														<p className="m-0 fs-12 font-gray">
															{`Feedback requested ${dateToDMY(_resumeData.journal.lastUpdatedOn)}`}
														</p>
													</span>
												);
											})}
										</div>
									</div>
								</div>
								<div className="col-md-6 mt-0 mt-md-5">
									<div className="resume-review-wrapper">
										<div className="resume-reviewer-message-wrapper">
											<div>
												<div className="resume-review-message-title-text">Message from {candidateFullName}</div>
												<div className="resume-review-message-body-text mb-3">
													Dear {reviewerName}, I value your guidance and advice in my career growth. That&#39;s why
													I&#39;m requesting your candid feedback to help me strengthen my CV below for targeted
													opportunities.
												</div>
												<div className="resume-review-message-title-text">The Opportunity</div>
												<div className="resume-review-message-body-text">{candidateMsg}</div>
											</div>
										</div>
										<div className="resume-review-ask-wrapper">
											<div>
												<div className="resume-review-message-title-text">The Ask</div>
												<div className="resume-review-message-body-text">
													Review my CV below, just click any of the section to add your feedback. Thanks!
												</div>
											</div>
										</div>
										<div className="resume-review-resume-wrapper">
											<Resume
												resumeData={_resumeData}
												isReview={true}
												reviewData={reviewData}
												loadingSubmission={loadingSubmission}
												onSubmitFeedBack={handleFeedBack}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-3 mt-3 mt-md-5">
									{reviewData.map((data, index) => (
										<ReviewCard
											reviewData={reviewData}
											data={data}
											templateId={_resumeData.journal.templateId}
											key={index}
										/>
									))}
								</div>
							</div>
						</>
					)}
				</div>
			</div>
			<MarkReviewCompleteModal resumeData={_resumeData} setShowMarkCompleteButton={setShowMarkCompleteButton} />
		</>
	);
}

export default ExpertReview;
