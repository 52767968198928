import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { interactions } from "../../../../handlers/enums";
import mapping from "../../../../seed/metadata/filemapping";
import { ResumeDataService } from "../../../../services/create-resume/resume-data";
import AutoSuggestion from "../../../AutoSuggestion";
import Loader from "../../../Loader";
import RecommendationDetails from "../RecommendationDetails";
import RecommendationsListItem from "../RecommendationsListItem";

const JobRecommendations = ({ recommendationsTab }) => {
	const [title, setTitle] = useState("");
	const [company, setCompany] = useState("");
	const [location, setLocation] = useState("");
	const [loading, setLoading] = useState(false);
	const addresses = mapping["districtsRegions"][0].districtRegions;
	const resumeDataService = new ResumeDataService();
	const [unFilteredJobs, setUnFilteredJobs] = useState([]);
	const [recommendedJobs, setRecommendedJobs] = useState([]);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [loadJobDetails, setLoadJobDetails] = useState(false);
	const [activeRecommendedJob, setActiveRecommendedJob] = useState({});

	function successInGetSelectedJob(response) {
		setLoadJobDetails(false);
		setActiveRecommendedJob(response.data);
	}

	function errorInGetSelectedJob() {
		setLoadJobDetails(false);
		toast.error("Something went wrong");
	}

	function interactionSuccessCallBack(response) {
		const { interaction_status } = response.data;
		toast.success(`${interaction_status} successfully`);
	}

	function interactionErrorCallBack() {
		toast.error("Something went wrong");
	}

	const jobOnclick = (recommrefernceId, interactionStatus) => {
		setLoadJobDetails(true);
		const params = { recommReferenceId: recommrefernceId };

		if (interactionStatus !== interactions.VIEWED) {
			const interactionData = {
				payload: {
					interactionType: interactions.VIEWED,
					notes: [],
				},
				jobId: recommrefernceId,
			};
			resumeDataService.setInteraction(
				"recommended-jobs/",
				interactionData,
				interactionSuccessCallBack,
				interactionErrorCallBack,
			);
		}

		resumeDataService.getRecommendedJobs(params, successInGetSelectedJob, errorInGetSelectedJob);
	};

	const handleOnChange = (event) => {
		if (event.target.name === "title") {
			setTitle(event.target.value);
		}

		if (event.target.name === "company") {
			setCompany(event.target.value);
		}
	};

	const jobListBox = recommendedJobs.map((jobItem, index) => (
		<RecommendationsListItem
			key={jobItem._id}
			job={jobItem}
			index={index}
			isSmallScreen={screenWidth < 768}
			jobOnClick={jobOnclick}
		/>
	));

	const onFilterJobs = () => {
		const jobsToFilter = unFilteredJobs.length > 0 ? unFilteredJobs : recommendedJobs;

		const filteredJobs = jobsToFilter.filter(function (recommendedJob) {
			const cityRegion = `${recommendedJob.job.jobLocation.city},${recommendedJob.job.jobLocation.region}`;
			return (
				cityRegion.toLowerCase().includes(location.toLowerCase()) &&
				recommendedJob.job.jobTitle.toLowerCase().includes(title.toLowerCase()) &&
				recommendedJob.job.companyName.toLowerCase().includes(company.toLowerCase())
			);
		});

		if (filteredJobs.length > 0) {
			setRecommendedJobs(filteredJobs);

			if (unFilteredJobs.length === 0) {
				setUnFilteredJobs(recommendedJobs);
			}
		}

		if (filteredJobs.length === 0) {
			setRecommendedJobs(unFilteredJobs);
		}
	};

	const onClearFilter = () => {
		setTitle("");
		setCompany("");
		setLocation("");
		setRecommendedJobs(unFilteredJobs);
		setUnFilteredJobs([]);
	};

	function successCallBack(response) {
		setLoading(false);
		setRecommendedJobs(response.data);
	}

	function errorCallBack(error) {
		if (error.status === 500) {
			resumeDataService.getRecommendedJobs({}, successCallBack, errorCallBack2);
		}

		setLoading(false);
		toast.error("Something went wrong");
	}

	function errorCallBack2() {
		setLoading(false);
		toast.error("Something went wrong");
	}

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [screenWidth]);

	useEffect(() => {
		if (recommendationsTab) {
			setLoading(true);
			setActiveRecommendedJob({});
			resumeDataService.getRecommendedJobs({}, successCallBack, errorCallBack);
		}
	}, [recommendationsTab]);

	useEffect(() => {
		if (company !== "" || title !== "" || location !== "") {
			setTimeout(() => {
				onFilterJobs();
			}, 1000);
		}
	}, [location, title, company]);

	if (!recommendationsTab) return null;

	return (
		<>
			<div className="employee-dashboard-main">
				<div className="">
					<div className="job-search-bar py-3 px-3 mt-3 mb-3 bg-light" style={{ borderRadius: "10px" }}>
						<div className="row align-items-center">
							{/* Location */}
							<div className="col-lg">
								<AutoSuggestion
									suggestions={addresses}
									label=""
									type="text"
									name="location"
									userInput={location}
									setUserInput={setLocation}
									placeholder="Eg: District, Region"
									icon="fa fa-location-arrow"
								/>
							</div>

							{/* Job Title */}
							<div className="col-lg">
								<div className="input-group mb-3 mb-lg-0 border rounded">
									<div className="input-group-prepend bg-white d-flex align-items-center p-2">
										<span className="fa fa-user text-primary" />
									</div>
									<input
										type="text"
										className="form-control pl-0 border-0"
										value={title}
										onChange={handleOnChange}
										name="title"
										placeholder="Job Title"
									/>
								</div>
							</div>

							{/* Company Name */}
							<div className="col-lg">
								<div className="input-group mb-3 mb-lg-0 border rounded">
									<div className="input-group-prepend bg-white d-flex align-items-center p-2">
										<span className="fa fa-building text-primary" />
									</div>
									<input
										type="text"
										className="form-control pl-0 border-0"
										value={company}
										onChange={handleOnChange}
										name="company"
										placeholder="Company"
									/>
								</div>
							</div>

							{unFilteredJobs.length > 0 ? (
								<div className="col-lg-2 d-flex justify-content-end">
									<a href="#" className="pr-3 cursor-pointer" onClick={onClearFilter}>
										Clear Filter....
									</a>
								</div>
							) : null}
						</div>
					</div>
				</div>
				{loading ? (
					<div className="" style={{ height: "400px" }}>
						<div className="d-flex p-4 align-center h-75 justify-content-center">
							<div className="position-relative" style={{ top: "35%" }}>
								<Loader />
							</div>
						</div>
					</div>
				) : recommendedJobs.length === 0 ? (
					<div className="" style={{ height: "500px" }}>
						<div className="d-flex p-4 align-center justify-content-center position-relative" style={{ top: "35%" }}>
							<div className="float-right">
								<p style={{ fontSize: "1.5rem" }}>No Jobs Found!</p>
							</div>
						</div>
					</div>
				) : (
					<div className="">
						<div className="tab-content">
							{/*active job dashboard*/}
							<div role="tabpanel" className="tab-pane active" id="job-dashboard">
								{/*posted job dashboard*/}
								<div className="posted-job-dashboard employer-common-dashboard ">
									<div className="dashboard-main" style={{ borderRadius: "10px" }}>
										<div className="employee-dashboard">
											<div className="row no-gutters">
												{/*job list*/}
												<div className="col-md-3">
													<div className="employee-dashboard-left">
														<ul className="nav nav-tabs applied-job-list border-0" role="tablist">
															{jobListBox}
														</ul>
													</div>
												</div>
												{/*job list*/}

												{/*job right section*/}
												{screenWidth > 768 ? (
													loadJobDetails ? (
														<div className="col-md-9">
															<div className="employee-dashboard-right bg-white">
																<div className="tab-content">
																	<div style={{ height: "400px" }}>
																		<div className="d-flex p-4 align-center h-75 justify-content-center">
																			<div className="position-relative" style={{ top: "35%" }}>
																				<Loader />
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													) : (
														<RecommendationDetails details={activeRecommendedJob} />
													)
												) : null}
												{/*job right section*/}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>

			{/*ApplicationResumePreviewModal*/}
			<div
				className="modal fade"
				id="ApplicationResumePreviewModal"
				data-backdrop="static"
				data-keyboard="false"
				tabIndex="-1"
				aria-labelledby="ApplicationResumePreviewModal"
				aria-hidden="true"
			>
				<div className="bg-white top py-3 w-100">
					<div className="container">
						<div className="d-flex align-items-center justify-content-between">
							<span className="font-weight-bold">Jacquiline Khalfan Resume</span>
							<span className="d-inline-block text-white bg-primary py-1 px-3 rounded-pill fs-12">
								<b>50 </b>Job Matches
							</span>
							<div className="d-flex align-items-center ">
								<div className="d-flex align-items-center text-primary mr-2 cursor-pointer">
									<span className="fa fa-download mr-2" />
									Download
								</div>
								<div className="d-flex align-items-center text-primary mr-2 cursor-pointer">
									<span className="fa fa-print mr-2" />
									Print
								</div>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-dialog modal-dialog-centered mt-5 py-5" style={{ maxWidth: "600px" }}>
					<div className="modal-content">
						<div id="resumePreviewSlider" className="carousel slide" data-ride="carousel">
							<div className="carousel-inner">
								<div className="carousel-item active">
									<img className="d-block w-100" src="images/resume-template/1.png" alt="First slide" />
								</div>
								<div className="carousel-item">
									<img className="d-block w-100" src="images/resume-template/2.png" alt="Second slide" />
								</div>
							</div>
							<a className="carousel-control-prev" href="#resumePreviewSlider" role="button" data-slide="prev">
								<span className="carousel-control-prev-icon" aria-hidden="true" />
								<span className="sr-only">Previous</span>
							</a>
							<a className="carousel-control-next" href="#resumePreviewSlider" role="button" data-slide="next">
								<span className="carousel-control-next-icon" aria-hidden="true" />
								<span className="sr-only">Next</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			{/*ApplicationResumePreviewModal*/}

			<div
				className="modal modal-fullscreen-xl"
				id="modal-fullscreen-xl"
				tabIndex="-1"
				role="dialog"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Apply Job</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{recommendedJobs.length !== 0 ? (
								loadJobDetails ? (
									<div className="col-md-8">
										<div className="employee-dashboard-right bg-white">
											<div className="tab-content">
												<div style={{ height: "400px" }}>
													<div className="d-flex p-4 align-center h-75 justify-content-center">
														<div className="position-relative" style={{ top: "35%" }}>
															<Loader />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								) : (
									<RecommendationDetails details={activeRecommendedJob} />
								)
							) : null}
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal">
								Close
							</button>
							<button type="button" className="btn btn-primary">
								Save changes
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default JobRecommendations;
