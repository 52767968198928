export class NavigatorNode {
	constructor(value, previous, next) {
		this.value = value;
		this.previous = previous;
		this.next = next;
	}

	setNext(next) {
		this.next = next;
	}
	setPrevious(previous) {
		this.previous = previous;
	}
}

export class NavigationProperty {
	constructor(name, url, description) {
		this.url = url;
		this.name = name;
		this.description = description;
		this.isComplete = false;
	}
}

export class NavigationList {
	constructor(header, current) {
		this.header = header;
		this.current = current;
	}
}

//TODO: Refactor to Include Button Names, descriptions etc.
export class NavigationFlow {
	constructor() {
		this.fresherFlow = null;
		this.experiencedFlow = null;
		this.contactInformation = new NavigationProperty("heading", "contact-information", "Contact information");
		this.workHistory = new NavigationProperty("workhistory", "work-history", "Position");
		this.workDetails = new NavigationProperty("workhistory", "work-details", "Position details");
		this.worksummary = new NavigationProperty("workhistory", "work-history-summary", "Work history");
		this.educationInformation = new NavigationProperty("education", "education-information", "Education Information");
		this.educationSummary = new NavigationProperty("education", "education-summary", "Education Summary");
		this.skills = new NavigationProperty("skills", "skills", "skills");
		this.briefSummary = new NavigationProperty("summary", "brief-summary", "Brief Summary");
		this.review = new NavigationProperty("finalize", "review", "Review");

		this.constructFresherFlow();
		this.constructExperiencedFlow();
	}

	constructFresherFlow() {
		// --629c41c8b524c0e502e6635b
		const header = new NavigatorNode(this.contactInformation, null, null);

		const education = new NavigatorNode(this.educationInformation, header, null);
		header.setNext(education);

		const educationSummary = new NavigatorNode(this.educationSummary, education, null);
		education.setNext(educationSummary);

		const workHistory = new NavigatorNode(this.workHistory, educationSummary, null);
		educationSummary.setNext(workHistory);

		const workDetails = new NavigatorNode(this.workDetails, workHistory, null);
		workHistory.setNext(workDetails);

		const worksummary = new NavigatorNode(this.worksummary, workDetails, null);
		workDetails.setNext(worksummary);

		const skills = new NavigatorNode(this.skills, worksummary, null);
		worksummary.setNext(skills);

		const briefSummary = new NavigatorNode(this.briefSummary, skills, null);
		skills.setNext(briefSummary);

		const review = new NavigatorNode(this.review, briefSummary, null);
		briefSummary.setNext(review);

		this.fresherFlow = header;
	}

	//Duplicate flow. Fresher flow should cover all cases
	constructExperiencedFlow() {
		const header = new NavigatorNode(this.contactInformation, null, null);

		const education = new NavigatorNode(this.educationInformation, header, null);
		header.setNext(education);

		const educationSummary = new NavigatorNode(this.educationSummary, education, null);
		education.setNext(educationSummary);

		const workHistory = new NavigatorNode(this.workHistory, educationSummary, null);
		educationSummary.setNext(workHistory);

		const workDetails = new NavigatorNode(this.workDetails, workHistory, null);
		workHistory.setNext(workDetails);

		const worksummary = new NavigatorNode(this.worksummary, workDetails, null);
		workDetails.setNext(worksummary);

		const skills = new NavigatorNode(this.skills, worksummary, null);
		worksummary.setNext(skills);

		const briefSummary = new NavigatorNode(this.briefSummary, skills, null);
		skills.setNext(briefSummary);

		const review = new NavigatorNode(this.review, briefSummary, null);
		briefSummary.setNext(review);

		this.fresherFlow = header;
	}
}

// contact-information
// work-history
// work-details
// work-history-summary
// education-information
// education-summary
// skills
// brief-summary
// extras
// accomplishments
// affiliations
// personal-links
// additional-information
// certifications
// languages
// reference-information
// reference-summary
// personal-profile
// custom-extra/13123123
// review
