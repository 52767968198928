import React from "react";

import OrderTableRow from "../../components/ExpertResumes/OrderTableRow";
import SearchFilterToolbar from "../../components/ExpertResumes/SearchFilterToolbar";
import ExpertResumesLayout from "../../components/ExpertResumesLayout/ExpertResumesLayout";

export const expertRoutes = [
	{ name: "Dashboard", route: "/", icon: "dashboard", iconSize: 24 },
	{ name: "Orders", route: "/expert-order-details", icon: "briefcase", iconSize: 24 },
	{ name: "Profile Settings", route: "/", icon: "profile", iconSize: 24 },
	{ name: "Profile Settings", route: "/", icon: "help", iconSize: 24 },
];

export const orderStatus = [
	{ status: "All orders", count: 0 },
	{ status: "Pending Acceptance", count: 2 },
	{ status: "In Progress", count: 0 },
	{ status: "Revision Requested", count: 0 },
	{ status: "Completed", count: 0 },
];

function ExpertOrdersManagement() {
	const [selectedStatus, setSelectedStatus] = React.useState(orderStatus[0]);

	return (
		<ExpertResumesLayout navItems={expertRoutes} headingTitle="Orders">
			<div className="expert-review-content-container">
				<div className="expert-review-heading-title" style={{ fontSize: "22px", lineHeight: "24px", color: "#121316" }}>
					Manage your orders
					<br />
					<span className="expert-review-footer-text" style={{ color: "#484E5C" }}>
						Review resume orders assigned to you.
					</span>
				</div>
				<div className="d-flex flex-column align-items-start p-0" style={{ gap: "16px", width: "100%" }}>
					<SearchFilterToolbar
						status={orderStatus}
						selectedStatus={selectedStatus}
						setSelectedStatus={setSelectedStatus}
					/>
					<div className="table-responsive">
						<table className="table table-hover table-borderless">
							<thead style={{ padding: "8px 20px", background: "#F7F8F8" }}>
								<tr>
									<th className="text-uppercase" style={{ borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" }}>
										Order ID
									</th>
									<th className="text-uppercase">Candidate Name</th>
									<th className="text-uppercase">Package</th>
									<th className="text-uppercase">Deadline</th>
									<th className="text-uppercase">Status</th>
									<th
										className="text-uppercase"
										style={{ borderTopRightRadius: "6px", borderBottomRightRadius: "6px" }}
									>
										Assigned By
									</th>
								</tr>
							</thead>
							<tbody>
								<OrderTableRow
									data={{
										orderID: "#614",
										candidateName: "James Smith",
										email: "Jame.smith@gmail.com",
										package: "Starter",
										date: "03/13/2024",
										status: "Not Started",
										assignedBy: "Clara Schinner",
									}}
								/>
								<OrderTableRow
									data={{
										orderID: "#985",
										candidateName: "Faye Jaskolski",
										email: "Faye51@hotmail.com",
										package: "Starter",
										date: "03/13/2024",
										status: "In Progress",
										assignedBy: "Clara Schinner",
									}}
								/>
								<OrderTableRow
									data={{
										orderID: "#555",
										candidateName: "Alfonso Sipes",
										email: "Alfonso.sipes12@hotmail.com",
										package: "Starter",
										date: "03/13/2024",
										status: "Due soon",
										assignedBy: "John Schinner",
									}}
								/>
								<OrderTableRow
									data={{
										orderID: "#555",
										candidateName: "Alfonso Sipes",
										email: "Alfonso.sipes12@hotmail.com",
										package: "Starter",
										date: "03/13/2024",
										status: "Overdue",
										assignedBy: "John Schinner",
									}}
								/>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</ExpertResumesLayout>
	);
}

export default ExpertOrdersManagement;
