import React, { useState } from "react";

import { convertUlToArray } from "../../../handlers/utilityFunctions";
import TextEditor from "../../TextEditor";

const NotRelevantModal = ({ setJobNotRelevant }) => {
	const [reason, setReason] = useState("");
	const [isNotAware, setIsNotAware] = useState(true);

	const submitJobNotRelevant = () => {
		return setJobNotRelevant(convertUlToArray(reason));
	};

	return (
		<>
			<div
				className="modal fade"
				id="notRelevantModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="notRelevantModalTitle"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title" id="notRelevantModal">
								Marking a Job as Not Relevant!
							</h3>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="p-3">
								<h5 className="mb-4">What makes this job Not Relevant to you?</h5>
								<div className="editor-wrapper">
									<TextEditor value={reason} setValue={setReason} placeholder="Type youre notes here..." />
								</div>

								<div className="border-top mt-4 py-3">
									<div className="custom-control custom-checkbox mr-5">
										<input
											type="checkbox"
											className="custom-control-input"
											id="notRelevant"
											name="awareOfIrelevance"
											onChange={() => setIsNotAware(!isNotAware)}
										/>
										<label className="custom-control-label" htmlFor="notRelevant">
											By Clicking, You are aware that once this job is marked as Not Relevant, it is NEVER going to be
											shown to you afterward.
										</label>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal">
								Cancel
							</button>
							<button
								type="button"
								className="btn btn-primary"
								disabled={isNotAware}
								onClick={submitJobNotRelevant}
								data-dismiss="modal"
							>
								Submit
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default NotRelevantModal;
