import { logToConsole } from "../../handlers/utilityFunctions";

export default function ReferenceSummaryReducer(refSummary = [], action) {
	switch (action.type) {
		case "ADD_REFERENCE":
			logToConsole(action.payload);
			return action.payload;

		case "REMOVE_REFERENCE":
			return refSummary.filter((item) => item.id !== action.payload.id);

		default:
			return refSummary;
	}
}
