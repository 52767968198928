const languages = {
	content: [
		{
			bp: false,
			value:
				"Including your language skills in your CV can demonstrate your ability to communicate effectively in different contexts and with diverse groups of people.",
		},
		{
			bp: true,
			value: "Only include languages that you are proficient in and can comfortably use in a professional context.",
		},
	],
};

export default languages;
