import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import tips from "../../../../assets/copy/tips";
import AutoSuggestion from "../../../../components/AutoSuggestion";
import ErrorToast from "../../../../components/ErrorToast";
import Modal from "../../../../components/Modal/Modal";
import EditorListItems from "../../../../components/ResumeBuilder/EditorListItems";
import Spinner from "../../../../components/Spinner";
import TextEditor from "../../../../components/TextEditor";
import Tips from "../../../../components/Tips";
import InputField from "../../../../components/UIComponents/InputField";
import SelectField from "../../../../components/UIComponents/SelectField";
import ResumeContext from "../../../../context/ResumeContext";
import { FormValidation } from "../../../../handlers/formValidation";
import { NavigationHandler } from "../../../../handlers/navigationHandler";
import { logToConsole } from "../../../../handlers/utilityFunctions";
import { isValidInputNotEmpty, isValidLocation } from "../../../../handlers/validationFunctions";
import { resumeObjectToContextFormat } from "../../../../handlers/valueTransformation";
import useHasChange from "../../../../hooks/use-HasChange";
import { Education } from "../../../../models/resume/education";
import { StaticValue } from "../../../../seed/constants";
import options from "../../../../seed/dropDownOptions";
import mapping from "../../../../seed/metadata/filemapping";
import { ResumeDataService } from "../../../../services/create-resume/resume-data";

const ResumeEducationInformation = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const eduInfo = useSelector((state) => state.EducationInfoReducer);
	const eduInfoList = eduInfo.eduInfoList;
	const [isInputCompleted, setIsInputCompleted] = useState(false);
	const navigationHandler = new NavigationHandler();
	const link = useLocation().pathname.split("/")[2];
	const resumeDataService = new ResumeDataService();
	const staticValue = new StaticValue();
	const formValidation = new FormValidation();
	const { hasChanges } = useHasChange();
	const staticValues = staticValue.getDeafaulValues();
	const [cookies] = useCookies(["resume"]);
	const [country, setCountry] = useState("Tanzania");

	const locations = mapping["districtsRegions"][0].districtRegions;

	const { resumeData, setResumeData } = useContext(ResumeContext);
	let { journal } = resumeData;

	let resumeCurrentStatus = journal ? journal.currentStatus : false;

	let isComplete = resumeCurrentStatus == "COMPLETED";

	//Declaring the form states
	const [educationInfo, setEducationInfo] = useState({
		schoolName: "",
		educationLevel: "",
		fieldOfStudyCategory: "",
		fieldOfStudy: "",
		gMonth: "",
		gYear: "",
	});
	const [schoolLocation, setSchoolLocation] = useState("");

	const [editorListItems, setEditorListItems] = useState(eduInfoList);

	const [description, setDescription] = useState(eduInfo.description);
	const navigationState = useLocation().state;

	useEffect(() => {
		if (navigationState) {
			const currentEducation = resumeData.education.filter(
				(item) => item.referenceId === navigationState.referenceId,
			)[0];
			if (navigationState) {
				if (resumeData.education.length > 0) {
					setEducationInfo({
						schoolName: currentEducation.schoolName,
						educationLevel: currentEducation.educationLevel,
						fieldOfStudy: currentEducation.fieldOfStudy,
						gMonth: currentEducation.gMonth,
						gYear: currentEducation.gYear,
						referenceId: currentEducation.referenceId,
						fieldOfStudyCategory: currentEducation.fieldOfStudyCategory,
					});
					setDescription(currentEducation.description);
					const address = currentEducation.schoolCity.split(",");
					setSchoolLocation(`${address?.[0] || ""}, ${address?.[1] || ""}`);
					setCountry(address?.[2] || "");
				}
			}
		}
	}, [resumeData]);

	//The form event handler
	const onChangeInput = (e) => {
		if (e.target.name === "schoolLocation") {
			setSchoolLocation(e.target.value);
		} else if (e.target.name === "country") {
			setCountry(e.target.value);
		} else {
			setEducationInfo((prevState) => {
				return {
					...prevState,
					[e.target.name]: e.target.value,
				};
			});
		}
	};

	const [errors, setErrors] = useState([]);

	let formInputs = {
		...educationInfo,
		description,
		schoolLocation,
	};

	useEffect(() => {
		const { schoolName, schoolLocation, educationLevel, fieldOfStudy, gMonth, gYear, fieldOfStudyCategory } =
			formInputs;
		const inputsToValidate = {
			schoolName,
			schoolLocation,
			educationLevel,
			fieldOfStudy,
			gMonth,
			gYear,
			fieldOfStudyCategory,
		};

		const isFormComplete = formValidation.isFormComplete(inputsToValidate);

		if (!isFormComplete) {
			setIsInputCompleted(false);
		}

		if (isFormComplete) {
			setIsInputCompleted(true);
		}
	}, [formInputs]);

	const [loading, setLoading] = useState(false);

	const skipSection = () => {
		navigationHandler.getNext(navigationHandler.template.navigationFlow, link, isComplete);
		navigate("/resume/education-summary");
	};

	const [serverErrors, setServerErrors] = useState([]);

	function patchSection() {
		const {
			schoolName,
			schoolLocation,
			educationLevel,
			fieldOfStudy,
			fieldOfStudyCategory,
			gMonth,
			gYear,
			description,
		} = formInputs;

		const address = schoolLocation.split(",");

		const payload = {
			city: address[0] || "",
			region: address[1] || "",
			country: country,
			schoolName,
			schoolLocation,
			educationLevel,
			fieldOfStudy,
			fieldOfStudyCategory,
			gMonth,
			gYear,
			description,
		};

		if (errors.length === 0) {
			const section = "EDUCATION";
			const eduPayload = new Education(payload);
			const eduReq = eduPayload.getEducationRequest();

			if (educationInfo.referenceId !== "") {
				eduReq.referenceId = educationInfo.referenceId;
			}

			if (hasChanges(section, eduReq)) {
				const resumeId = cookies[staticValues.localStorageResumeId];

				setLoading(true);
				resumeDataService.patchResumeLedger(resumeId, eduReq, section, successPatchEducation, errorPatchEducation);
			} else {
				skipSection();
			}
		} else {
			setServerErrors(errors);
		}
	}

	useEffect(() => {}, [errors]);

	const [showDetailsModal, setShowDetailsModal] = useState(false);

	function nextButton(e) {
		e.preventDefault();
		setErrors([]);
		setServerErrors([]);
		const formErrors = [];
		if (isInputCompleted) {
			//TODO set navigation state complete
			formInputs = {
				...formInputs,
				schoolLocation: `${formInputs.schoolLocation}, ${country}`,
			};

			const { schoolName, schoolLocation, educationLevel, fieldOfStudy, fieldOfStudyCategory, gMonth, gYear } =
				formInputs;

			if (!isValidInputNotEmpty(schoolName)) {
				formErrors.push("Please enter a school name");
			}

			if (!isValidLocation(schoolLocation)) {
				formErrors.push("Please enter a school location, it should follow the format City, Region");
			}

			if (!isValidInputNotEmpty(educationLevel)) {
				formErrors.push("Please enter a certification");
			}
			if (!isValidInputNotEmpty(fieldOfStudy)) {
				formErrors.push("Please enter what you studied");
			}
			if (fieldOfStudyCategory === "") {
				formErrors.push("Please select a field of study");
			}
			if (gMonth === "") {
				formErrors.push("Please select graduation month");
			}
			if (gYear === "") {
				formErrors.push("Please enter graduation year");
			}

			if (formErrors.length === 0) {
				return setShowDetailsModal(true);
				// return patchSection();
			}
			return setServerErrors(formErrors);
		}
	}

	const dispatchAddExtras = (payload) => {
		dispatch({ type: "ADD_EXTRAS", payload: payload });
	};

	function successPatchEducation(data) {
		setLoading(false);
		logToConsole("step 2", data);
		let response = data.data;

		dispatch({
			type: "RESUME_DATA",
			payload: response,
		});

		resumeObjectToContextFormat(response, setResumeData, dispatchAddExtras);

		navigate("/resume/education-summary");
	}

	function errorPatchEducation(data) {
		setLoading(false);

		const serverResponse = data.response.data;
		Array.isArray(serverResponse.message)
			? setServerErrors((prev) => {
					return [...prev, ...serverResponse.message];
				})
			: setServerErrors((prev) => {
					return [...prev, serverResponse.message];
				});
	}

	useEffect(() => {
		if (educationInfo.educationLevel === "O-Level" || educationInfo.educationLevel === "A-Level") {
			setEducationInfo((prev) => {
				return {
					...prev,
					fieldOfStudyCategory: "Secondary Education",
				};
			});
		}
	}, [educationInfo.educationLevel]);

	return (
		<>
			<div
				className="modal fade"
				id="skipSectionModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="skipSectionModalTitle"
				aria-hidden="false"
			>
				<Modal skip={skipSection} />
			</div>

			<div className="container min-vh-100">
				<div className="section education-information">
					{serverErrors.map((item, index) => {
						return <ErrorToast error={item} key={index} />;
					})}
					<div className="mb-3 text-medium">
						<p>List your academic credentials, starting with the most recent degree.</p>
					</div>
					<div className="col-md-8">
						<div className="d-flex justify-content-end mb-2">
							<Tips content={tips.educationHistory} />
						</div>
					</div>
					<div className="form-wrapper">
						<div className="row">
							<div className="col-md-8">
								<form action="">
									<div className="row">
										<div className="col-12">
											<SelectField
												name="educationLevel"
												value={educationInfo.educationLevel}
												onChange={onChangeInput}
												options={options.educationLevels}
												label="Education Levels"
											/>
										</div>

										<div className="col-12">
											<SelectField
												name="fieldOfStudyCategory"
												value={educationInfo.fieldOfStudyCategory}
												onChange={onChangeInput}
												options={options.fieldsOfStudy}
												label="Field of Study"
											/>
										</div>

										<div className="col-12">
											<InputField
												type="text"
												label="Degree/Certificate Major/Combination"
												name="fieldOfStudy"
												value={educationInfo.fieldOfStudy}
												onChange={onChangeInput}
												placeholder="Ex: BSc, Computer Science"
											/>
										</div>

										<div className="col-12">
											<InputField
												type="text"
												label="School Name"
												name="schoolName"
												value={educationInfo.schoolName}
												onChange={onChangeInput}
												placeholder="Enter School Name"
											/>
										</div>

										<div className="col-md-6">
											<SelectField
												name="country"
												value={country}
												onChange={onChangeInput}
												options={options.phoneCountryCodeOptions}
												label="Country"
											/>
										</div>

										<div className="col-md-6">
											{!(country === "Tanzania") && (
												<InputField
													type="text"
													label="Ex: City, Region"
													name="schoolLocation"
													value={schoolLocation}
													onChange={onChangeInput}
													placeholder="City, Region"
												/>
											)}
											{country === "Tanzania" && (
												<AutoSuggestion
													suggestions={locations}
													label="Eg: District, Region"
													type="text"
													name="schoolLocation"
													userInput={schoolLocation}
													setUserInput={setSchoolLocation}
													isForm={true}
												/>
											)}
										</div>

										<div className="col-md-6 ">
											<SelectField
												name="gMonth"
												value={educationInfo.gMonth}
												onChange={onChangeInput}
												options={options.months}
												label="Graduation Month"
											/>
										</div>

										<div className="col-lg-6">
											<SelectField
												name="gYear"
												value={educationInfo.gYear}
												onChange={onChangeInput}
												options={options.years(40)}
												label="Graduation Year"
											/>
										</div>
									</div>
									<div className="col-12 p-0 mt-4 d-flex align-items-center justify-content-end">
										<button
											className="font-weight-semi pl-0 fs-14 text-primary mr-3"
											onClick={() => navigate("/resumes/skills")}
										>
											Skip for now
										</button>
										<button
											data-toggle="modal"
											data-target={!isInputCompleted ? "#skipSectionModal" : ""}
											onClick={nextButton}
											className="btn btn-primary rounded-pill btn-lg"
										>
											{loading ? (
												<>
													Loading <Spinner />
												</>
											) : (
												"Continue"
											)}
										</button>
									</div>
								</form>
							</div>

							{showDetailsModal && (
								<div
									className="modal"
									style={{
										zIndex: 2000,
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										backgroundColor: "rgba(0, 0, 0, 0.5)",
									}}
								>
									<div
										className="modal-content"
										style={{
											maxWidth: "80vw",
											maxHeight: "80vh",
											overflowY: "auto",
										}}
									>
										<div className="px-4">
											<div className="pt-3 mb-2 d-flex align-items-center justify-content-between">
												<p className="font-weight-bold">Activities or Relevant Course Work</p>

												<button
													type="button"
													className="close"
													data-dismiss="modal"
													aria-label="Close"
													onClick={() => setShowDetailsModal(false)}
												>
													<span className="bi bi-x" />
												</button>
											</div>
											{serverErrors.map((item, index) => {
												return <ErrorToast error={item} key={index} />;
											})}
											<div className="mb-3">
												<div className="common-recommendations-section ">
													<div className="row">
														<div className="col-md-6">
															<div>
																<p className="text-dark mb-0 text-light">
																	<span>{formInputs.educationLevel}</span>, <span>{formInputs.fieldOfStudy}</span>
																</p>
																<p className="text-light mb-3">
																	{formInputs.schoolName} • {formInputs.gYear}
																</p>
															</div>
															<div className="recommendation-topics">
																<EditorListItems
																	editorListItems={editorListItems}
																	editorContent={description}
																	setEditorContent={setDescription}
																	setEditorListItems={setEditorListItems}
																/>
															</div>
														</div>
														<div className="col-md-6 d-flex align-items-center flex-column mt-4 justify-content-end">
															<div className="editor-side w-100">
																<div className="editor-wrapper">
																	<TextEditor
																		value={description}
																		setValue={setDescription}
																		placeholder="Add a description."
																	/>
																</div>
															</div>
														</div>
														<div className="col-12 px-3 my-4 d-flex align-items-center justify-content-end">
															<button
																className="font-weight-semi pl-0 fs-14 text-primary mr-3"
																onClick={() => patchSection()}
															>
																Skip for now
															</button>
															<button
																data-toggle="modal"
																data-target={!isInputCompleted ? "#skipSectionModal" : ""}
																onClick={() => {
																	const { description } = formInputs;
																	const formErrors = [];

																	if (description.includes("{") || description.includes("}")) {
																		formErrors.push("Please edit the suggested entries on the description");
																	}

																	if (formErrors.length === 0) {
																		return patchSection();
																	}
																	return setServerErrors(formErrors);
																}}
																className="btn btn-primary rounded-pill btn-lg"
															>
																{loading ? (
																	<>
																		Loading <Spinner />
																	</>
																) : (
																	"Continue"
																)}
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ResumeEducationInformation;
