import React from "react";

import FeedBackModal from "../../../../components/Reviews/FeedBackModal/FeedBackModal";
import { sections } from "../../../../handlers/enums";
import { convertDateToWords, convertUlToArray, removeHTTPAndDomainName } from "../../../../handlers/utilityFunctions";
import useReviewFeedBack from "../../../../hooks/use-ReviewFeedBack";

function ResumePremiumSeven(props) {
	const { isReview, reviewData, resumeData, hasFeedback, activeSection, onSubmitFeedBack, loadingSubmission } = props;
	const { useReviewFeedback, feedBackButton } = useReviewFeedBack(reviewData);

	return (
		<div className="final-resume-preview-wrapper final-premium-template-5 final-premium-template-7 final-resume-preview-new shadow-sm">
			<div className=" text-dark py-2">
				<div className="container">
					{resumeData.contactInfo.firstName !== "" && (
						<div className="mb-2 mb-md-3">
							<div className="info-wrapper px-3 px-md-4">
								<div>
									<h1 className="mb-1">
										{resumeData.contactInfo.firstName} {resumeData.contactInfo.lastName}
									</h1>
									<h4 className="mb-3" style={{ color: "#48969b" }}>
										{resumeData.contactInfo.resumeTitle}
									</h4>
								</div>
							</div>
							<div
								className={`position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.SUMMARY
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.SUMMARY}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.SUMMARY ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.SUMMARY ? feedBackButton(sections.SUMMARY, hasFeedback) : null}
								<p
									className="mb-0 p-3 text-white"
									style={{ backgroundColor: "#313c4e", borderTopRightRadius: "10px", borderTopTeftRadius: "10px" }}
									dangerouslySetInnerHTML={{
										__html: resumeData.summary,
									}}
								/>
							</div>
							<div
								className="px-md-3  py-md-2 p-2 mb-4 d-flex align-items-center justify-content-between text-white flex-wrap"
								style={{ backgroundColor: "#449399", borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px" }}
							>
								<p className="mb-2">
									<i className="contact-icon mr-1 mr-md-2 fa fa-envelope" aria-hidden="true" />
									{resumeData.contactInfo.email}
								</p>
								<p className="mb-2 contact-icon">
									<i className="mr-md-2 mr-1 fa fa-mobile" aria-hidden="true" />
									{resumeData.contactInfo.phoneNo}
								</p>
								<p className="mb-2 contact-icon">
									<i className="mr-md-2 mr-1 fa fa-map-marker" aria-hidden="true" />
									{resumeData.contactInfo.city}, {resumeData.contactInfo.country}
								</p>
								{[...resumeData.extras.personalLinks].map((link, index) => {
									return link.type === "linkedIn" ? (
										<p key={index} className="mb-2 contact-icon">
											<i className="mr-md-2 mr-1 bi bi-linkedin" aria-hidden="true" />
											<a href={link.value} target="_blank" rel="noreferrer" style={{ color: "#ffffff" }}>
												{removeHTTPAndDomainName(link)}
											</a>
										</p>
									) : null;
								})}
							</div>
						</div>
					)}

					{resumeData.skills !== "" && convertUlToArray(resumeData.skills) ? (
						<div className="mb-2 mb-md-3 px-3 px-md-4">
							<div className="title-heading d-flex align-items-center mb-2 mb-md-3">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 48px",
										height: "48px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "20px",
									}}
								>
									<i className="fa fa-cogs" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									skills
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "6px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>

							<ul
								className={`p-0 mb-0 position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.SKILLS
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.SKILLS}
								style={{
									listStyle: "none",
									backgroundColor:
										useReviewFeedback.section.section === sections.SKILLS ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.SKILLS ? feedBackButton(sections.SKILLS, hasFeedback) : null}
								{convertUlToArray(resumeData.skills).map((item, index) => {
									const splitArray = item.value.split(/:(.+)/);
									let wordBeforeColon = splitArray[0].trim();
									wordBeforeColon = splitArray.length > 1 ? <strong>{wordBeforeColon}</strong> : wordBeforeColon;
									const wordAfterColon = splitArray.length > 1 ? ": " + splitArray[1].trim() : "";

									return (
										<li key={index} className="mb-2">
											{wordBeforeColon}
											{wordAfterColon}
										</li>
									);
								})}
							</ul>
						</div>
					) : null}

					{resumeData.extras.certifications !== "" ? (
						<div className="mb-2 mb-md-3 px-3 px-md-4">
							<div className="title-heading d-flex align-items-center mb-2 mb-md-3">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 48px",
										height: "48px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "20px",
									}}
								>
									<i className="fa fa-certificate" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									certificates
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "6px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>

							<div
								className={`row position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.CERTIFICATION
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.CERTIFICATION}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.CERTIFICATION ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.CERTIFICATION
									? feedBackButton(sections.CERTIFICATION, hasFeedback)
									: null}
								{convertUlToArray(resumeData.extras.certifications).map((item, index) => {
									return (
										<p key={index} className="col-6 mb-2 pr-2">
											{item.value}
										</p>
									);
								})}
							</div>
						</div>
					) : null}

					{resumeData.extras.accomplishments !== "" ? (
						<div className="mb-2 mb-md-3 px-3 px-md-4">
							<div className="title-heading d-flex align-items-center mb-2 mb-md-3">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 48px",
										height: "48px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "20px",
									}}
								>
									<i className="fa fa-check" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									accomplishments
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "6px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>

							<ul
								className={`p-0 mb-0 position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.ACCOMPLISHMENTS
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.ACCOMPLISHMENTS}
								style={{
									listStyle: "none",
									backgroundColor:
										useReviewFeedback.section.section === sections.ACCOMPLISHMENTS ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.ACCOMPLISHMENTS
									? feedBackButton(sections.ACCOMPLISHMENTS, hasFeedback)
									: null}
								{convertUlToArray(resumeData.extras.accomplishments).map((item, index) => {
									return (
										<li key={index} className="mb-2">
											{item.value}
										</li>
									);
								})}
							</ul>
						</div>
					) : null}

					{resumeData.workHistory.length > 0 && resumeData.workHistory[0].jobTitle !== "" ? (
						<div className="mb-2 mb-md-3 px-3 px-md-4">
							<div className="title-heading d-flex align-items-center mb-2 mb-md-3">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 48px",
										height: "48px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "20px",
									}}
								>
									<i className="fa fa-briefcase" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									WORK EXPERIENCE{" "}
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "6px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>
							<ul
								className={`pl-0 mb-0 pb-2 position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.EXPERIENCE
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.EXPERIENCE}
								style={{
									listStyle: "none",
									backgroundColor:
										useReviewFeedback.section.section === sections.EXPERIENCE ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.EXPERIENCE
									? feedBackButton(sections.EXPERIENCE, hasFeedback)
									: null}
								{[...resumeData.workHistory].map((job, index) => (
									<React.Fragment key={index}>
										<li className="position-relative">
											<div className="d-flex justify-content-between">
												<p className="mb-2">
													<strong>{job.employer}</strong>
													{" - "}
													{job.city}, {job.country}
													<br />
													<em>{job.jobTitle}</em>
												</p>
												<p className="mb-0" style={{ color: "#449399" }}>
													<strong>
														{convertDateToWords(job.startDate)} -{" "}
														{job.endDate !== "" ? convertDateToWords(job.endDate) : "Present"}
													</strong>
												</p>
											</div>
											<ul className="px-3 list" style={{ listStyle: "disc" }}>
												{convertUlToArray(job.details).map((item, index) => {
													return (
														<li key={index} className="mb-2">
															{item.value}
														</li>
													);
												})}
											</ul>
										</li>
									</React.Fragment>
								))}
							</ul>
						</div>
					) : null}

					{resumeData.education.length > 0 && (
						<div className="mb-2 mb-md-3 px-3 px-md-4">
							<div className="title-heading d-flex align-items-center mb-2 mb-md-3">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 48px",
										height: "48px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "20px",
									}}
								>
									<i className="fa fa-graduation-cap" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									EDUCATION
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "6px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>
							<div className="row education-list">
								<div
									className={`col-12 position-relative review-section ${
										isReview
											? "rs-highlight"
											: hasFeedback
												? activeSection === sections.EDUCATION
													? "rs-feedback rs-feedback-active"
													: "rs-feedback"
												: ""
									}`}
									id={sections.EDUCATION}
									style={{
										backgroundColor:
											useReviewFeedback.section.section === sections.EDUCATION ? "rgba(27, 175, 245, 0.12)" : "",
									}}
								>
									{isReview || activeSection === sections.EDUCATION
										? feedBackButton(sections.EDUCATION, hasFeedback)
										: null}
									{[...resumeData.education].map((educationHistory, index) => (
										<div key={index} className="mb-3">
											<div className="d-flex justify-content-between">
												<p>
													<strong>{educationHistory.schoolName}</strong>
													{" - "}
													{educationHistory.schoolCity}, <br />
													<em>{educationHistory.fieldOfStudy}</em>
												</p>
												<p className="mr-4" style={{ color: "#449399" }}>
													<strong>{educationHistory.gYear}</strong>
												</p>
											</div>
											<ul className="px-3 list" style={{ listStyle: "disc" }}>
												{convertUlToArray(educationHistory.description).map((item, index) => {
													if (item.value === "") {
														return null;
													}
													return (
														<li key={index} className="mb-2">
															{item.value}
														</li>
													);
												})}
											</ul>
										</div>
									))}
								</div>
							</div>
						</div>
					)}

					{resumeData.extras.languages.length !== 0 ? (
						<div className="mb-2 mb-md-3 px-3 px-md-4">
							<div className="title-heading d-flex align-items-center mb-2 mb-md-3">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 48px",
										height: "48px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "20px",
									}}
								>
									<i className="fa fa-language" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									LANGUAGES
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "6px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>
							<div
								className={`row no-gutters position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.LANGUAGES
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.LANGUAGES}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.LANGUAGES ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.LANGUAGES
									? feedBackButton(sections.LANGUAGES, hasFeedback)
									: null}
								{resumeData.extras.languages.map((language, index) => (
									<div key={index} className="col-4 px-1">
										<h5 className="mt-2 mb-0">{language.language}</h5>
										<h6 className="font-italic mb-0" style={{ color: "#364152" }}>
											{language.proficiency}
										</h6>
									</div>
								))}
							</div>
						</div>
					) : null}

					{resumeData.extras.personalProfile.dateOfBirth && (
						<div className="mb-2 mb-md-3 px-3 px-md-4">
							<div className="title-heading d-flex align-items-center mb-2 mb-md-3">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 48px",
										height: "48px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "20px",
									}}
								>
									<i className="fa fa-user" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									Personal Information
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "6px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>

							<div
								className={`row position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.PERSONAL
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.PERSONAL}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.PERSONAL ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.PERSONAL
									? feedBackButton(sections.PERSONAL, hasFeedback)
									: null}
								<div className="col-6">
									<div className="mb-2 mb-md-3">
										<strong>Date of Birth:</strong>&nbsp;
										{resumeData.extras.personalProfile.dateOfBirth}
									</div>
								</div>
								<div className="col-6">
									<div className="mb-2 mb-md-3">
										<strong>Gender:</strong>&nbsp;
										{resumeData.extras.personalProfile.gender}
									</div>
								</div>
								<div className="col-6">
									<div className="mb-2 mb-md-3">
										<strong>Marital Status:</strong>&nbsp;
										{resumeData.extras.personalProfile.maritalStatus}
									</div>
								</div>
							</div>
						</div>
					)}

					{resumeData.extras.affiliations !== "" ? (
						<div className="mb-2 mb-md-3 px-3 px-md-4">
							<div className="title-heading d-flex align-items-center mb-2 mb-md-3">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 48px",
										height: "48px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "20px",
									}}
								>
									<i className="fa fa-handshake" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									affiliations
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "6px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>

							<div
								className={`row position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.AFFILIATION
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.AFFILIATION}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.AFFILIATION ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.AFFILIATION
									? feedBackButton(sections.AFFILIATION, hasFeedback)
									: null}
								{convertUlToArray(resumeData.extras.affiliations).map((item, index) => {
									return (
										<p key={index} className="col-6 mb-2 pr-2">
											{item.value}
										</p>
									);
								})}
							</div>
						</div>
					) : null}

					{resumeData.extras.additionalInfo !== "" ? (
						<div className="mb-2 mb-md-3 px-3 px-md-4">
							<div className="title-heading d-flex align-items-center mb-2 mb-md-3">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 48px",
										height: "48px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "20px",
									}}
								>
									<i className="fa fa-info" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									additional information
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "6px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>

							<div
								className={`row position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.ADDITIONAL_INFO
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.ADDITIONAL_INFO}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.ADDITIONAL_INFO ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.ADDITIONAL_INFO
									? feedBackButton(sections.ADDITIONAL_INFO, hasFeedback)
									: null}
								{convertUlToArray(resumeData.extras.additionalInfo).map((item, index) => {
									return (
										<p key={index} className="col-6 mb-2 pr-2">
											{item.value}
										</p>
									);
								})}
							</div>
						</div>
					) : null}

					{[...resumeData.extras.personalLinks].some((link) => link.type !== "linkedIn") && (
						<div className="mb-2 mb-md-3 px-3 px-md-4">
							<div className="title-heading d-flex align-items-center mb-2 mb-md-3">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 48px",
										height: "48px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "20px",
									}}
								>
									<i className="fa fa-link" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									website, portfolio, profiles
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "6px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>

							<ul
								className={`p-0 mb-0 position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.LINKS
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.LINKS}
								style={{
									listStyle: "none",
									backgroundColor:
										useReviewFeedback.section.section === sections.LINKS ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.LINKS ? feedBackButton(sections.LINKS, hasFeedback) : null}
								{[...resumeData.extras.personalLinks].map((link, index) => {
									return link.type !== "linkedIn" ? (
										<li key={index} className="mb-2">
											{link.value}
										</li>
									) : null;
								})}
							</ul>
						</div>
					)}

					{resumeData.extras.references.length > 0 ? (
						<div className="mb-2 mb-md-3 px-3 px-md-4">
							<div className="title-heading d-flex align-items-center mb-2 mb-md-3">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 48px",
										height: "48px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "20px",
									}}
								>
									<i className="fa fa-asterisk" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									references
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "6px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>

							<div
								className={`row position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.REFERENCE
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.REFERENCE}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.REFERENCE ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.REFERENCE
									? feedBackButton(sections.REFERENCE, hasFeedback)
									: null}
								{[...resumeData.extras.references].map((reference, index) => (
									<div key={index} className="col-6 d-flex mb-3">
										<div>
											<strong>{reference.name}</strong>
											{" - "}
											<em>{reference.designation}, </em>
											{reference.workPlace}
											<br />
											<p style={{ color: "#bcbcbc" }}>
												{reference.email}, {reference.phoneNumber}
											</p>
										</div>
									</div>
								))}
							</div>
						</div>
					) : null}

					{resumeData.extras.customExtras.name.length > 0 ? (
						<div className="mb-2 mb-md-3 px-3 px-md-4">
							<div className="title-heading d-flex align-items-center mb-2 mb-md-3">
								<span
									className="title-icon rounded-circle mr-2"
									style={{
										backgroundColor: "#313c4e",
										flex: "0 0 48px",
										height: "48px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "20px",
									}}
								>
									<i className="bi bi-journal-text" aria-hidden="true" style={{ color: "#fff" }} />
								</span>
								<h4 className="d-flex align-items-center text-uppercase mb-0 font-weight-bold mr-3 text-nowrap">
									{resumeData.extras.customExtras.name}
								</h4>
								<span
									className="separator d-inline-block"
									style={{
										height: "6px",
										borderRadius: "100px",
										flexGrow: 1,
										background:
											"linear-gradient(90deg, rgba(68,147,153,1) 0%, rgba(217,233,234,1) 42%, rgba(255,255,255,1) 100%)",
									}}
								/>
							</div>

							<div
								className={`row position-relative review-section ${
									isReview
										? "rs-highlight"
										: hasFeedback
											? activeSection === sections.CUSTOM
												? "rs-feedback rs-feedback-active"
												: "rs-feedback"
											: ""
								}`}
								id={sections.CUSTOM}
								style={{
									backgroundColor:
										useReviewFeedback.section.section === sections.CUSTOM ? "rgba(27, 175, 245, 0.12)" : "",
								}}
							>
								{isReview || activeSection === sections.CUSTOM ? feedBackButton(sections.CUSTOM, hasFeedback) : null}
								{convertUlToArray(resumeData.extras.customExtras.data).map((item, index) => {
									return (
										<p key={index} className="col-6 mb-2 pr-2">
											{item.value}
										</p>
									);
								})}
							</div>
						</div>
					) : null}
				</div>
			</div>
			{useReviewFeedback.showFeedBackModal && (
				<FeedBackModal
					resumeData={resumeData}
					details={useReviewFeedback.section}
					onSubmitFeedBack={onSubmitFeedBack}
					loadingSubmission={loadingSubmission}
				/>
			)}
		</div>
	);
}

export default ResumePremiumSeven;
