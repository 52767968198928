import React from "react";
import { Link, useNavigate } from "react-router-dom";

const JobPayWall = ({ isLoggedOut, isResumeNotCompleted }) => {
	const navigate = useNavigate();

	return (
		<>
			{isLoggedOut && (
				<div className="paywall">
					<div className="job-quick-info-col">
						<p>
							Have an Account?
							<Link to="/signin">
								<strong> Log in</strong>
							</Link>
						</p>
						<hr style={{ marginTop: "20px", marginBottom: "40px" }} />
					</div>
					<h3 className="job-title" style={{ textAlign: "center" }}>
						<span>
							Access the job details by creating a free <br /> account or logging in.
						</span>
					</h3>
					<button className="btn btn-primary" style={{ marginTop: "20px" }} onClick={() => navigate("/register")}>
						Continue
					</button>
				</div>
			)}

			{isResumeNotCompleted && (
				<div className="paywall">
					<div className="m-auto text-center">
						<h3 className="job-title">
							<span>
								Access the job details by building and <br /> completing a resume.
							</span>
						</h3>
						<button className="btn btn-primary" style={{ marginTop: "20px" }} onClick={() => navigate("/my-resumes")}>
							Continue
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default JobPayWall;
