import React from "react";

function SearchFilterToolbar({ status, selectedStatus, setSelectedStatus }) {
	return (
		<>
			<div className="d-flex align-items-start flex-wrap p-0" style={{ gap: "12px" }}>
				<div
					className="d-flex align-items-center"
					style={{
						width: "270px",
						height: "32px",
						padding: "6px 12px",
						gap: "12px",
						border: "1px solid #D7DAE0",
						borderRadius: "48px",
					}}
				>
					<i className="bi bi-search" style={{ color: "#484E5C" }} />
					<input className="border-0" placeholder="Search by candidate name..." />
				</div>
				<div className="d-flex align-items-start p-0" style={{ gap: "8px" }}>
					<div
						className="d-flex align-items-center justify-content-center cursor-pointer"
						style={{ padding: "9px 10px", gap: "8px", width: "82px", height: "32px" }}
					>
						<i className="bi bi-filter" style={{ color: "#484E5C", fontSize: "20px" }} />
						<div className="expert-review-footer-text" style={{ color: "#484E5C" }}>
							Filter
						</div>
					</div>
					<div
						className="d-flex align-items-center justify-content-center cursor-pointer"
						style={{ padding: "9px 10px", gap: "8px", width: "82px", height: "32px" }}
					>
						<i className="bi bi-arrow-down-up" style={{ color: "#484E5C", fontSize: "16px" }} />
						<div className="expert-review-footer-text" style={{ color: "#484E5C" }}>
							Sort
						</div>
					</div>
				</div>
			</div>
			<div className="d-flex align-items-start flex-wrap p-0" style={{ gap: "12px", width: "100%" }}>
				{status.map((item) => {
					return (
						<div
							key={item.status}
							className="d-flex align-items-center justify-content-between cursor-pointer "
							style={{
								padding: "18px 16px",
								gap: "12px",
								background: "#FFFFFF",
								boxShadow: "inset 0px 0px 0px 1px #E7E7E7",
								borderRadius: "8px",
								flex: 1,
								height: "64px",
								...(selectedStatus.status === item.status && {
									boxShadow: "inset 0px 0px 0px 2px #0A88ED",
								}),
							}}
							onClick={() => setSelectedStatus(item)}
						>
							<div className="expert-review-footer-text" style={{ color: "#484E5C" }}>
								{item.status}
							</div>
							{item.count > 0 && (
								<div
									className="d-flex flex-column align-items-center justify-content-center"
									style={{
										padding: "10px",
										gap: "10px",
										width: "24px",
										height: "24px",
										background: "#0069CB",
										borderRadius: "66px",
									}}
								>
									<div className="expert-review-footer-text" style={{ color: "#F0F7FF", fontSize: "10px" }}>
										02
									</div>
								</div>
							)}
						</div>
					);
				})}
			</div>
		</>
	);
}

export default SearchFilterToolbar;
