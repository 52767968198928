import React from "react";

function TemplatePremiumThree() {
	return (
		<div style={{ scale: "1.2", transformOrigin: "center" }}>
			<div className="small-resume-preview" style={{ maxWidth: "400px" }}>
				<div className="final-resume-preview bg-white small-resume-preview-premium-template-4 text-dark">
					<div className="p-2">
						<div>
							<div className="d-flex">
								<div
									style={{
										flex: "0 0 50%",
										backgroundColor: "#5e6579",
										color: "#ffffff",
										borderRadius: "5px",
										padding: "5px",
									}}
								>
									<h1 className="mb-1">JACQUILINE KHALFAN</h1>
									<h3 style={{ color: "#f6913f", fontWeight: 400 }} className="mb-2">
										Business Development Manager
									</h3>
									<p>
										Professional Business Developer with four year of experience in the business Development processes.
										Involved in product testing, management and development of new business opportunities.
									</p>
								</div>
								<div style={{ flex: "0 0 50%" }}>
									<p className="mb-1 mt-2 text-right d-flex align-items-center justify-content-end">
										jacquiline.khalfan@dproz.tz
										<span style={{ position: "relative", marginLeft: "10px" }}>
											<span
												style={{
													display: "inline-block",
													backgroundColor: "#ced0d7",
													width: "15px",
													height: "15px",
													borderRadius: "5px 5px 5px 35px",
												}}
											/>
											<i
												className="ml-2 fa fa-linkedin"
												aria-hidden="true"
												style={{
													fontSize: "8px",
													color: "#5e6579",
													position: "absolute",
													right: "3px",
													top: "2px",
												}}
											/>
										</span>
									</p>
									<p className="mb-1 text-right d-flex align-items-center justify-content-end">
										202-555-0166
										<span style={{ position: "relative", marginLeft: "10px" }}>
											<span
												style={{
													display: "inline-block",
													backgroundColor: "#ced0d7",
													width: "15px",
													height: "15px",
													borderRadius: "5px 5px 5px 35px",
												}}
											/>
											<i
												className="ml-2 fa fa-mobile"
												aria-hidden="true"
												style={{
													fontSize: "10px",
													color: "#5e6579",
													position: "absolute",
													right: "3px",
													top: "2px",
												}}
											/>
										</span>
									</p>
									<p className="mb-1 text-right d-flex align-items-center justify-content-end">
										New York, USA
										<span style={{ position: "relative", marginLeft: "10px" }}>
											<span
												style={{
													display: "inline-block",
													backgroundColor: "#ced0d7",
													width: "15px",
													height: "15px",
													borderRadius: "5px 5px 5px 35px",
												}}
											/>
											<i
												className="ml-2 fa fa-map-marker"
												aria-hidden="true"
												style={{
													fontSize: "8px",
													color: "#5e6579",
													position: "absolute",
													right: "3px",
													top: "2px",
												}}
											/>
										</span>
									</p>
									<p className="mb-1 text-right d-flex align-items-center justify-content-end">
										linkedin.com/in/jacquiline.khalfan
										<span style={{ position: "relative", marginLeft: "10px" }}>
											<span
												style={{
													display: "inline-block",
													backgroundColor: "#ced0d7",
													width: "15px",
													height: "15px",
													borderRadius: "5px 5px 5px 35px",
												}}
											/>
											<i
												className="ml-2 fa fa-linkedin"
												aria-hidden="true"
												style={{
													fontSize: "8px",
													color: "#5e6579",
													position: "absolute",
													right: "3px",
													top: "2px",
												}}
											/>
										</span>
									</p>
									<p className="mb-1 text-right d-flex align-items-center justify-content-end">
										jacquiline.khalfan
										<span style={{ position: "relative", marginLeft: "10px" }}>
											<span
												style={{
													display: "inline-block",
													backgroundColor: "#ced0d7",
													width: "15px",
													height: "15px",
													borderRadius: "5px 5px 5px 35px",
												}}
											/>
											<i
												className="ml-2 fa fa-skype"
												aria-hidden="true"
												style={{
													fontSize: "8px",
													color: "#5e6579",
													position: "absolute",
													right: "3px",
													top: "2px",
												}}
											/>
										</span>
									</p>
								</div>
							</div>
						</div>

						<div className="d-flex">
							<div style={{ padding: "10px 0", flex: "0 0 50%" }}>
								<div className="mb-2">
									<h3 className="mb-2">
										<span
											className="mr-2"
											style={{
												width: "30px",
												display: "inline-block",
												position: "relative",
											}}
										>
											<span
												style={{
													position: "relative",
													zIndex: 1,
													backgroundColor: "#5e6579",
													width: "20px",
													height: "20px",
													lineHeight: "20px",
													borderRadius: "4px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
												}}
											>
												<span
													className="fa fa-briefcase"
													style={{
														color: "#ffffff",
														transform: "rotate(-45deg)",
														fontSize: "10px",
													}}
												/>
											</span>
											<span
												style={{
													backgroundColor: "#ced0d7",
													width: "15px",
													height: "15px",
													lineHeight: "15px",
													borderRadius: "2px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
													position: "absolute",
													left: "10px",
													top: "2px",
												}}
											/>
										</span>
										<span style={{ color: "#5e6579" }}>WORK EXPERIENCE</span>
									</h3>

									<div className="mb-2">
										<h4>Business Development Manager</h4>
										<h5>AirState Solutions</h5>
										<p style={{ color: "#f6913f" }}>
											<em>09/2014 - 06/2017 </em>
										</p>
										<p style={{ color: "#f6913f" }}>
											<em>Achievements</em>
										</p>

										<div className="d-flex mb-1">
											<span className="fa fa-caret-right mr-2 mt-1" style={{ color: "#f6913f" }} />
											<span>
												Reviewed constantly the customer feedback and then suggested ways to improve the processes and
												customer service levels which increased the satisfaction rate from 81% to 95%.
											</span>
										</div>
									</div>
									<div className="mb-2">
										<h4>Business Development Assistant</h4>
										<h5>AirState Solutions</h5>
										<p style={{ color: "#f6913f" }}>
											<em>08/2012 - 09/2014 </em>
										</p>
										<p style={{ color: "#f6913f" }}>
											<em>Achievements</em>
										</p>

										<div className="d-flex mb-1">
											<span className="fa fa-caret-right mr-2 mt-1" style={{ color: "#f6913f" }} />
											<span>
												Directed the creation and implementation of a Business Continuity Plan, and the management of
												audit programs.
											</span>
										</div>
									</div>
								</div>

								<div className="mb-2">
									<h3 className="mb-2">
										<span
											className="mr-2"
											style={{
												width: "30px",
												display: "inline-block",
												position: "relative",
											}}
										>
											<span
												style={{
													position: "relative",
													zIndex: 1,
													backgroundColor: "#5e6579",
													width: "20px",
													height: "20px",
													lineHeight: "20px",
													borderRadius: "4px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
												}}
											>
												<span
													className="fa fa-map-marker"
													style={{
														color: "#ffffff",
														transform: "rotate(-45deg)",
														fontSize: "10px",
													}}
												/>
											</span>
											<span
												style={{
													backgroundColor: "#ced0d7",
													width: "15px",
													height: "15px",
													lineHeight: "15px",
													borderRadius: "2px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
													position: "absolute",
													left: "10px",
													top: "2px",
												}}
											/>
										</span>

										<span style={{ color: "#5e6579" }}>EDUCATION</span>
									</h3>

									<div className="mb-2">
										<h4>MSc in Economics and Business Administration</h4>
										<h5>The University of Chicago</h5>
										<p style={{ color: "#f6913f" }}>
											<em>09/2008 - 06/2010 </em>
										</p>
									</div>
								</div>
							</div>
							<div
								style={{
									flex: "0 0 50%",
									backgroundColor: "#ebebeb",
									borderRadius: "5px",
									padding: "10px",
								}}
							>
								<div className="mb-2">
									<h3 className="mb-2">
										<span
											className="mr-2"
											style={{
												width: "30px",
												display: "inline-block",
												position: "relative",
											}}
										>
											<span
												style={{
													position: "relative",
													zIndex: 1,
													backgroundColor: "#5e6579",
													width: "20px",
													height: "20px",
													lineHeight: "20px",
													borderRadius: "4px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
												}}
											>
												<span
													className="fa fa-cogs"
													style={{
														color: "#ffffff",
														transform: "rotate(-45deg)",
														fontSize: "10px",
													}}
												/>
											</span>
											<span
												style={{
													backgroundColor: "#ced0d7",
													width: "15px",
													height: "15px",
													lineHeight: "15px",
													borderRadius: "2px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
													position: "absolute",
													left: "10px",
													top: "2px",
												}}
											/>
										</span>

										<span style={{ color: "#5e6579" }}>TECHNICAL SKILLS</span>
									</h3>

									<div className="mb-2">
										<div className="mb-2">
											<p className="mb-0">
												<strong> Front-end Technology </strong>
											</p>
											<p className="mb-0">
												HTML5, CSS3, JavaScript, jQuery, HTML5, CSS3, JavaScript, jQuery,Angular and ReactJS
											</p>
										</div>
										<div className="mb-2">
											<p className="mb-0">
												<strong> Development Languages </strong>
											</p>
											<p className="mb-0">Java,JavaScript, C#, C, C++,Python, Ruby and .Net, Java,JavaScript, C#, C,</p>
										</div>
										<div className="mb-2">
											<p className="mb-0">
												<strong> Database and Cache </strong>
											</p>
											<p className="mb-0">
												MySQL, MongoDB,Oracle, SQLServer, Memcached and Redis., MySQL, MongoDB,Oracle, SQLServer,
												Memcached and Redis.
											</p>
										</div>
										<div className="mb-2">
											<p className="mb-0">
												<strong> Soft Skills </strong>
											</p>
											<p className="mb-0">
												Teamwork, Time Management, Adaptability and Work Ethic. Teamwork, Time Management, Adaptability
												and Work Ethic.
											</p>
										</div>
									</div>
								</div>

								<div className="mb-2">
									<h3 className="mb-2">
										<span
											className="mr-2"
											style={{
												width: "30px",
												display: "inline-block",
												position: "relative",
											}}
										>
											<span
												style={{
													position: "relative",
													zIndex: 1,
													backgroundColor: "#5e6579",
													width: "20px",
													height: "20px",
													lineHeight: "20px",
													borderRadius: "4px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
												}}
											>
												<span
													className="fa fa-cog"
													style={{
														color: "#ffffff",
														transform: "rotate(-45deg)",
														fontSize: "10px",
													}}
												/>
											</span>
											<span
												style={{
													backgroundColor: "#ced0d7",
													width: "15px",
													height: "15px",
													lineHeight: "15px",
													borderRadius: "2px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
													position: "absolute",
													left: "10px",
													top: "2px",
												}}
											/>
										</span>

										<span style={{ color: "#5e6579" }}>SOFT SKILLS</span>
									</h3>

									<div className="mb-2">
										<div className="d-flex flex-wrap">
											<span
												style={{
													display: "inline-block",
													padding: "2px 5px",
													backgroundColor: "#aeb2bc",
													color: "#ffffff",
													borderRadius: "5px",
													margin: "2px 0",
													marginRight: "10px",
												}}
											>
												Verbal &amp; Written communication
											</span>
											<span
												style={{
													display: "inline-block",
													padding: "2px 5px",
													backgroundColor: "#aeb2bc",
													color: "#ffffff",
													borderRadius: "5px",
													margin: "2px 0",
													marginRight: "10px",
												}}
											>
												Flexibility
											</span>
											<span
												style={{
													display: "inline-block",
													padding: "2px 5px",
													backgroundColor: "#aeb2bc",
													color: "#ffffff",
													borderRadius: "5px",
													margin: "2px 0",
													marginRight: "10px",
												}}
											>
												Time Management
											</span>
											<span
												style={{
													display: "inline-block",
													padding: "2px 5px",
													backgroundColor: "#aeb2bc",
													color: "#ffffff",
													borderRadius: "5px",
													margin: "2px 0",
													marginRight: "10px",
												}}
											>
												Attention to detail
											</span>
										</div>
									</div>
								</div>
								<div className="mb-2">
									<h3 className="mb-2">
										<span
											className="mr-2"
											style={{
												width: "30px",
												display: "inline-block",
												position: "relative",
											}}
										>
											<span
												style={{
													position: "relative",
													zIndex: 1,
													backgroundColor: "#5e6579",
													width: "20px",
													height: "20px",
													lineHeight: "20px",
													borderRadius: "4px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
												}}
											>
												<span
													className="fa fa-certificate"
													style={{
														color: "#ffffff",
														transform: "rotate(-45deg)",
														fontSize: "10px",
													}}
												/>
											</span>
											<span
												style={{
													backgroundColor: "#ced0d7",
													width: "15px",
													height: "15px",
													lineHeight: "15px",
													borderRadius: "2px",
													display: "inline-block",
													textAlign: "center",
													transform: "rotate(45deg)",
													position: "absolute",
													left: "10px",
													top: "2px",
												}}
											/>
										</span>

										<span style={{ color: "#5e6579" }}>CERTIFICATES</span>
									</h3>

									<div className="mb-4">
										<p>
											Microsoft Certified Solutions Developer
											<a
												className="fa fa-share-square-o ml-2"
												aria-hidden="true"
												target="_blank"
												style={{ color: "#f6913f" }}
											/>
										</p>
										<p>
											Google Cloud Certified - Associate Cloud Engineer
											<a
												className="fa fa-share-square-o ml-2"
												aria-hidden="true"
												style={{ color: "#f6913f" }}
												target="_blank"
											/>
										</p>
										<p>
											AWS Certified (Architecture and Development)
											<a
												className="fa fa-share-square-o ml-2"
												aria-hidden="true"
												target="_blank"
												style={{ color: "#f6913f" }}
											/>
										</p>
										<p>
											AWS Certified SysOps Admin - Associate
											<a
												className="fa fa-share-square-o ml-2"
												aria-hidden="true"
												target="_blank"
												style={{ color: "#f6913f" }}
											/>
										</p>
										<p>
											Course in Advanced Operations on AWS
											<a
												className="fa fa-share-square-o ml-2"
												aria-hidden="true"
												target="_blank"
												style={{ color: "#f6913f" }}
											/>
										</p>
										<p>
											Course in MySQL for Database Administrators
											<a
												className="fa fa-share-square-o ml-2"
												aria-hidden="true"
												target="_blank"
												style={{ color: "#f6913f" }}
											/>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="preview text-center mt-4 mb-4">
				<button
					className="btn text-primary btn-sm btn-outline-primary"
					data-toggle="modal"
					data-target="#ResumePreview"
				>
					<span className="fa fa-eye" /> Preview
				</button>
			</div>
		</div>
	);
}

export default TemplatePremiumThree;
