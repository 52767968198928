import React from "react";
import { Link } from "react-router-dom";

function CreateResumeOptions() {
	return (
		<section className="resume-main-section  py-5">
			<div className="bg-white create-resume-options-section">
				<div className="container">
					<div className="section">
						<div className="mb-5 text-meduim">
							<h2 className="tr-title-big mb-3">How do you want to build your resume?</h2>
							{/* <!-- <p>We suggest including an email and phone number.</p> --> */}
						</div>
						<div className="create-resume-options">
							<div className="row justify-content-center">
								<div className="col-md-5 mb-4 mb-md-0">
									<Link
										to="/resume/select-template"
										className="text-dark resume-option d-block p-4 p-lg-5 bg-white text-center border"
									>
										<span className="d-block fa fa-file-text text-primary mb-4" />
										<span className="d-block font-weight-bold lead mb-2 text-uppercase text-dark">
											Create a new Resume
										</span>
										<span>
											We will help you create a resume
											<br /> step-by-step.
										</span>
									</Link>
								</div>
								{/* <div className="col-md-5">
									<a
										data-toggle="modal"
										data-target="#UploadResumeModal"
										className="text-dark resume-option d-block p-4 p-lg-5 bg-white text-center border"
									>
										<span className="d-block fa fa-cloud-upload mb-4 text-primary"/>
										<span className="d-block text-uppercase text-dark  font-weight-bold  lead mb-2">
											I ALREADY HAVE A RESUME
										</span>
										<span>
											We&#39;ll re-format it and fill in your information
											<br /> so you don&#39;t have to.
										</span>
									</Link>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- Upload Resume Modal  --> */}

			{/* <div
				className="modal fade"
				id="UploadResumeModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="photoSelectorLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-body">
							<div className="row">
								<div className="col-6">
									<div className="upload-resume">
										<label className="upload-resume-input" for="UploadResume">
											<div>
												<span className="d-block fa fa-cloud-upload mb-3 text-primary icon"/>
												<span className="btn btn-primary">Upload Resume</span>
												<p className="text-dark small mt-4">
													Files we can read: DOC, DOCX, PDF, HTML, RTF, TXT
												</p>
												<input
													type="file"
													name="myResume"
													accept=".png,.gif,.jpg"
													placeholder="Image"
													id="UploadResume"
													className="input-image d-none mr-4"
												/>
											</div>
										</label>
									</div>
								</div>
								<div className="col-5">
									<div className="photo-tips-wrap py-5 text-dark">
										<div className="mb-3">
											<h5 className="text-center">OR</h5>
										</div>
										<ul className="list-square px-0 list-unstyled small">
											<ul className="list-unstyled">
												<li className="mb-3">
													<a
														to="#"
														className="d-block py-3 px-4 border rounded-pill shadow-sm text-dark"
													>
														<img
															className="mr-3"
															src="images/icons/google.png"
															alt=""
														/>
														Upload from Google Drive
													</Link>
												</li>
												<li>
													<a
														to="#"
														className="d-block py-3 px-4 border rounded-pill shadow-sm text-dark"
													>
														<img
															className="mr-3"
															src="images/icons/dropbox.png"
															alt=""
														/>
														Upload from Dropbox
													</Link>
												</li>
											</ul>
											<Link to="#" className="btn"></Link>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-dark"
								data-dismiss="modal"
							>
								Cancel
							</button>
							<a
								to="resume-contact-information "
								type="button"
								className="btn btn-primary"
							>
								Next
							</Link>
						</div>
					</div>
				</div>
			</div> */}
		</section>
	);
}

export default CreateResumeOptions;
